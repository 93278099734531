import * as React from 'react'
import {MenuItem, Typography, Select, Grid, CircularProgress, Box} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {IDealership} from '../../../utils'

export interface TeamDetailsBlockProps {
  listDealerships: IDealership[]
  dealership: IDealership
  setDealership: ( dealership: IDealership ) => void
  isLoading: boolean
  isEditing: boolean
}

export const TeamDetailsBlock: React.FC<TeamDetailsBlockProps> = ({
  listDealerships,
  dealership,
  setDealership,
  isEditing,
  isLoading
}) => {

  return (
    isLoading ? (
      <Box sx={{ width: '100%', textAlign: 'center' }}>
        <CircularProgress/>
      </Box>
    ) : (
      <Grid container columnSpacing={8} rowSpacing={4}>
        {/*TODO: need more spacing between captions and 'content' to match the table spaces for neater look*/}
        <Grid item>
          <Typography variant="caption">Team Name</Typography>
          <Typography>
            {dealership?.location || 'N/A'}
            {/*@TODO: need city name here, see note below*/}
          </Typography>
        </Grid>
        <Grid item>
          <Grid container item direction="column">
            <Typography variant="caption">Location</Typography>
            {isEditing ? (
              <Select
                autoWidth={false}
                className="compactSelect"
                IconComponent={ExpandMoreIcon}
                value={dealership?.id}
                onChange={ (e) => setDealership( listDealerships.find( dealer => dealer.id === e.target.value) )}>
                {listDealerships?.map( ({id, location}) =>
                  <MenuItem
                    key={id}
                    value={id}>
                    {location}
                  </MenuItem>
                )}
              </Select>
            ):(
              <Typography>
                {dealership?.location || 'N/A'}
                {/* @TODO: {`${dealership?.city}, ${dealership?.state}` || 'N/A'} (BE returns undefined for address at this moment of writing)*/}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
    )
  )
}
