// source: domain.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.domain.ActivityState', null, global);
goog.exportSymbol('proto.domain.Address', null, global);
goog.exportSymbol('proto.domain.Address.AddressType', null, global);
goog.exportSymbol('proto.domain.Appointment', null, global);
goog.exportSymbol('proto.domain.Appointment.Status', null, global);
goog.exportSymbol('proto.domain.Appointment.Type', null, global);
goog.exportSymbol('proto.domain.AppointmentVehicle', null, global);
goog.exportSymbol('proto.domain.AutonomyUser', null, global);
goog.exportSymbol('proto.domain.AutonomyUser.UserType', null, global);
goog.exportSymbol('proto.domain.Customer', null, global);
goog.exportSymbol('proto.domain.CustomerActivity', null, global);
goog.exportSymbol('proto.domain.CustomerErrorState', null, global);
goog.exportSymbol('proto.domain.CustomerFailureState', null, global);
goog.exportSymbol('proto.domain.CustomerInfo', null, global);
goog.exportSymbol('proto.domain.CustomerState', null, global);
goog.exportSymbol('proto.domain.DealerUser', null, global);
goog.exportSymbol('proto.domain.Dealership', null, global);
goog.exportSymbol('proto.domain.Dealership.Activity', null, global);
goog.exportSymbol('proto.domain.Dealership.ActivityState', null, global);
goog.exportSymbol('proto.domain.Dealership.ErrorState', null, global);
goog.exportSymbol('proto.domain.Dealership.State', null, global);
goog.exportSymbol('proto.domain.DisqualificationReason', null, global);
goog.exportSymbol('proto.domain.Domain', null, global);
goog.exportSymbol('proto.domain.Gateway', null, global);
goog.exportSymbol('proto.domain.GatewayToken', null, global);
goog.exportSymbol('proto.domain.Image', null, global);
goog.exportSymbol('proto.domain.ImageType', null, global);
goog.exportSymbol('proto.domain.InsurancePolicy', null, global);
goog.exportSymbol('proto.domain.InsurancePolicy.InsuranceStatus', null, global);
goog.exportSymbol('proto.domain.LineItem', null, global);
goog.exportSymbol('proto.domain.LineItem.LineItemType', null, global);
goog.exportSymbol('proto.domain.Note', null, global);
goog.exportSymbol('proto.domain.OdometerUpdate', null, global);
goog.exportSymbol('proto.domain.OdometerUpdate.Type', null, global);
goog.exportSymbol('proto.domain.PaymentMethod', null, global);
goog.exportSymbol('proto.domain.PaymentMethod.PaymentType', null, global);
goog.exportSymbol('proto.domain.Product', null, global);
goog.exportSymbol('proto.domain.Product.ProductType', null, global);
goog.exportSymbol('proto.domain.QualificationOrigin', null, global);
goog.exportSymbol('proto.domain.QualificationTier', null, global);
goog.exportSymbol('proto.domain.Score', null, global);
goog.exportSymbol('proto.domain.Statement', null, global);
goog.exportSymbol('proto.domain.StatementAction', null, global);
goog.exportSymbol('proto.domain.StatementAction.ActionReason', null, global);
goog.exportSymbol('proto.domain.StatementAction.ActionType', null, global);
goog.exportSymbol('proto.domain.StatementAction.UserType', null, global);
goog.exportSymbol('proto.domain.StatementStatus', null, global);
goog.exportSymbol('proto.domain.Subscription', null, global);
goog.exportSymbol('proto.domain.Subscription.Status', null, global);
goog.exportSymbol('proto.domain.Subscription.SubscriptionAction', null, global);
goog.exportSymbol('proto.domain.Subscription.SubscriptionAction.ActionReason', null, global);
goog.exportSymbol('proto.domain.Subscription.SubscriptionAction.ActionType', null, global);
goog.exportSymbol('proto.domain.Subscription.SubscriptionAction.UserType', null, global);
goog.exportSymbol('proto.domain.TeamMember', null, global);
goog.exportSymbol('proto.domain.Transaction', null, global);
goog.exportSymbol('proto.domain.Transaction.TransactionType', null, global);
goog.exportSymbol('proto.domain.VendorAccount', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.domain.Score = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.domain.Score.repeatedFields_, null);
};
goog.inherits(proto.domain.Score, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.domain.Score.displayName = 'proto.domain.Score';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.domain.Address = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.domain.Address, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.domain.Address.displayName = 'proto.domain.Address';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.domain.Image = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.domain.Image, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.domain.Image.displayName = 'proto.domain.Image';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.domain.Customer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.domain.Customer.repeatedFields_, null);
};
goog.inherits(proto.domain.Customer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.domain.Customer.displayName = 'proto.domain.Customer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.domain.CustomerInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.domain.CustomerInfo.repeatedFields_, null);
};
goog.inherits(proto.domain.CustomerInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.domain.CustomerInfo.displayName = 'proto.domain.CustomerInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.domain.Subscription = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.domain.Subscription.repeatedFields_, null);
};
goog.inherits(proto.domain.Subscription, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.domain.Subscription.displayName = 'proto.domain.Subscription';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.domain.Subscription.SubscriptionAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.domain.Subscription.SubscriptionAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.domain.Subscription.SubscriptionAction.displayName = 'proto.domain.Subscription.SubscriptionAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.domain.OdometerUpdate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.domain.OdometerUpdate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.domain.OdometerUpdate.displayName = 'proto.domain.OdometerUpdate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.domain.InsurancePolicy = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.domain.InsurancePolicy.repeatedFields_, null);
};
goog.inherits(proto.domain.InsurancePolicy, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.domain.InsurancePolicy.displayName = 'proto.domain.InsurancePolicy';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.domain.VendorAccount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.domain.VendorAccount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.domain.VendorAccount.displayName = 'proto.domain.VendorAccount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.domain.Statement = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.domain.Statement.repeatedFields_, null);
};
goog.inherits(proto.domain.Statement, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.domain.Statement.displayName = 'proto.domain.Statement';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.domain.StatementAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.domain.StatementAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.domain.StatementAction.displayName = 'proto.domain.StatementAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.domain.LineItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.domain.LineItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.domain.LineItem.displayName = 'proto.domain.LineItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.domain.Transaction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.domain.Transaction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.domain.Transaction.displayName = 'proto.domain.Transaction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.domain.PaymentMethod = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.domain.PaymentMethod, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.domain.PaymentMethod.displayName = 'proto.domain.PaymentMethod';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.domain.Note = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.domain.Note, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.domain.Note.displayName = 'proto.domain.Note';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.domain.Gateway = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.domain.Gateway, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.domain.Gateway.displayName = 'proto.domain.Gateway';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.domain.GatewayToken = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.domain.GatewayToken, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.domain.GatewayToken.displayName = 'proto.domain.GatewayToken';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.domain.Product = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.domain.Product, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.domain.Product.displayName = 'proto.domain.Product';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.domain.Dealership = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.domain.Dealership, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.domain.Dealership.displayName = 'proto.domain.Dealership';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.domain.TeamMember = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.domain.TeamMember, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.domain.TeamMember.displayName = 'proto.domain.TeamMember';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.domain.DealerUser = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.domain.DealerUser.repeatedFields_, null);
};
goog.inherits(proto.domain.DealerUser, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.domain.DealerUser.displayName = 'proto.domain.DealerUser';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.domain.Appointment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.domain.Appointment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.domain.Appointment.displayName = 'proto.domain.Appointment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.domain.AutonomyUser = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.domain.AutonomyUser, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.domain.AutonomyUser.displayName = 'proto.domain.AutonomyUser';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.domain.AppointmentVehicle = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.domain.AppointmentVehicle, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.domain.AppointmentVehicle.displayName = 'proto.domain.AppointmentVehicle';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.domain.Score.repeatedFields_ = [11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.domain.Score.prototype.toObject = function(opt_includeInstance) {
  return proto.domain.Score.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.domain.Score} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.domain.Score.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    timestamp: jspb.Message.getFieldWithDefault(msg, 2, 0),
    vendorName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    scoreName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    scoreValue: jspb.Message.getFieldWithDefault(msg, 5, ""),
    scoreNumeric: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    sourceData: jspb.Message.getFieldWithDefault(msg, 7, ""),
    isPassing: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    vendorId: jspb.Message.getFieldWithDefault(msg, 9, ""),
    isDisqualifiedScore: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    disqualifiedReasonsList: (f = jspb.Message.getRepeatedField(msg, 11)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.domain.Score}
 */
proto.domain.Score.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.domain.Score;
  return proto.domain.Score.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.domain.Score} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.domain.Score}
 */
proto.domain.Score.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestamp(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setVendorName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setScoreName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setScoreValue(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setScoreNumeric(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setSourceData(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsPassing(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setVendorId(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDisqualifiedScore(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.addDisqualifiedReasons(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.domain.Score.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.domain.Score.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.domain.Score} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.domain.Score.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getVendorName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getScoreName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getScoreValue();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getScoreNumeric();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getSourceData();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getIsPassing();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getVendorId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getIsDisqualifiedScore();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getDisqualifiedReasonsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      11,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.domain.Score.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.Score} returns this
 */
proto.domain.Score.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 timestamp = 2;
 * @return {number}
 */
proto.domain.Score.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.domain.Score} returns this
 */
proto.domain.Score.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string vendor_name = 3;
 * @return {string}
 */
proto.domain.Score.prototype.getVendorName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.Score} returns this
 */
proto.domain.Score.prototype.setVendorName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string score_name = 4;
 * @return {string}
 */
proto.domain.Score.prototype.getScoreName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.Score} returns this
 */
proto.domain.Score.prototype.setScoreName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string score_value = 5;
 * @return {string}
 */
proto.domain.Score.prototype.getScoreValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.Score} returns this
 */
proto.domain.Score.prototype.setScoreValue = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional float score_numeric = 6;
 * @return {number}
 */
proto.domain.Score.prototype.getScoreNumeric = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.domain.Score} returns this
 */
proto.domain.Score.prototype.setScoreNumeric = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional string source_data = 7;
 * @return {string}
 */
proto.domain.Score.prototype.getSourceData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.Score} returns this
 */
proto.domain.Score.prototype.setSourceData = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional bool is_passing = 8;
 * @return {boolean}
 */
proto.domain.Score.prototype.getIsPassing = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.domain.Score} returns this
 */
proto.domain.Score.prototype.setIsPassing = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional string vendor_id = 9;
 * @return {string}
 */
proto.domain.Score.prototype.getVendorId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.Score} returns this
 */
proto.domain.Score.prototype.setVendorId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional bool is_disqualified_score = 10;
 * @return {boolean}
 */
proto.domain.Score.prototype.getIsDisqualifiedScore = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.domain.Score} returns this
 */
proto.domain.Score.prototype.setIsDisqualifiedScore = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * repeated string disqualified_reasons = 11;
 * @return {!Array<string>}
 */
proto.domain.Score.prototype.getDisqualifiedReasonsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 11));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.domain.Score} returns this
 */
proto.domain.Score.prototype.setDisqualifiedReasonsList = function(value) {
  return jspb.Message.setField(this, 11, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.domain.Score} returns this
 */
proto.domain.Score.prototype.addDisqualifiedReasons = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 11, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.domain.Score} returns this
 */
proto.domain.Score.prototype.clearDisqualifiedReasonsList = function() {
  return this.setDisqualifiedReasonsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.domain.Address.prototype.toObject = function(opt_includeInstance) {
  return proto.domain.Address.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.domain.Address} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.domain.Address.toObject = function(includeInstance, msg) {
  var f, obj = {
    addressType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    street: jspb.Message.getFieldWithDefault(msg, 2, ""),
    street2: jspb.Message.getFieldWithDefault(msg, 3, ""),
    city: jspb.Message.getFieldWithDefault(msg, 4, ""),
    state: jspb.Message.getFieldWithDefault(msg, 5, ""),
    postal: jspb.Message.getFieldWithDefault(msg, 6, ""),
    id: jspb.Message.getFieldWithDefault(msg, 7, ""),
    deleted: jspb.Message.getBooleanFieldWithDefault(msg, 8, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.domain.Address}
 */
proto.domain.Address.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.domain.Address;
  return proto.domain.Address.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.domain.Address} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.domain.Address}
 */
proto.domain.Address.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.domain.Address.AddressType} */ (reader.readEnum());
      msg.setAddressType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStreet(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStreet2(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostal(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDeleted(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.domain.Address.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.domain.Address.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.domain.Address} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.domain.Address.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddressType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getStreet();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStreet2();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPostal();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getDeleted();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.domain.Address.AddressType = {
  LICENSE: 0,
  PARKING: 1,
  CONTACT: 2,
  DATA_ENRICHMENT: 10
};

/**
 * optional AddressType address_type = 1;
 * @return {!proto.domain.Address.AddressType}
 */
proto.domain.Address.prototype.getAddressType = function() {
  return /** @type {!proto.domain.Address.AddressType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.domain.Address.AddressType} value
 * @return {!proto.domain.Address} returns this
 */
proto.domain.Address.prototype.setAddressType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string street = 2;
 * @return {string}
 */
proto.domain.Address.prototype.getStreet = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.Address} returns this
 */
proto.domain.Address.prototype.setStreet = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string street2 = 3;
 * @return {string}
 */
proto.domain.Address.prototype.getStreet2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.Address} returns this
 */
proto.domain.Address.prototype.setStreet2 = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string city = 4;
 * @return {string}
 */
proto.domain.Address.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.Address} returns this
 */
proto.domain.Address.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string state = 5;
 * @return {string}
 */
proto.domain.Address.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.Address} returns this
 */
proto.domain.Address.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string postal = 6;
 * @return {string}
 */
proto.domain.Address.prototype.getPostal = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.Address} returns this
 */
proto.domain.Address.prototype.setPostal = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string id = 7;
 * @return {string}
 */
proto.domain.Address.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.Address} returns this
 */
proto.domain.Address.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional bool deleted = 8;
 * @return {boolean}
 */
proto.domain.Address.prototype.getDeleted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.domain.Address} returns this
 */
proto.domain.Address.prototype.setDeleted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.domain.Image.prototype.toObject = function(opt_includeInstance) {
  return proto.domain.Image.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.domain.Image} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.domain.Image.toObject = function(includeInstance, msg) {
  var f, obj = {
    imageType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    id: jspb.Message.getFieldWithDefault(msg, 3, ""),
    uri: jspb.Message.getFieldWithDefault(msg, 4, ""),
    presignedUri: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.domain.Image}
 */
proto.domain.Image.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.domain.Image;
  return proto.domain.Image.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.domain.Image} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.domain.Image}
 */
proto.domain.Image.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.domain.ImageType} */ (reader.readEnum());
      msg.setImageType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUri(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPresignedUri(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.domain.Image.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.domain.Image.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.domain.Image} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.domain.Image.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getImageType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUri();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPresignedUri();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional ImageType image_type = 1;
 * @return {!proto.domain.ImageType}
 */
proto.domain.Image.prototype.getImageType = function() {
  return /** @type {!proto.domain.ImageType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.domain.ImageType} value
 * @return {!proto.domain.Image} returns this
 */
proto.domain.Image.prototype.setImageType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.domain.Image.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.Image} returns this
 */
proto.domain.Image.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string id = 3;
 * @return {string}
 */
proto.domain.Image.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.Image} returns this
 */
proto.domain.Image.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string uri = 4;
 * @return {string}
 */
proto.domain.Image.prototype.getUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.Image} returns this
 */
proto.domain.Image.prototype.setUri = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string presigned_uri = 5;
 * @return {string}
 */
proto.domain.Image.prototype.getPresignedUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.Image} returns this
 */
proto.domain.Image.prototype.setPresignedUri = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.domain.Customer.repeatedFields_ = [9,10,11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.domain.Customer.prototype.toObject = function(opt_includeInstance) {
  return proto.domain.Customer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.domain.Customer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.domain.Customer.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    accountNumber: jspb.Message.getFieldWithDefault(msg, 2, ""),
    customerInfo: (f = msg.getCustomerInfo()) && proto.domain.CustomerInfo.toObject(includeInstance, f),
    state: jspb.Message.getFieldWithDefault(msg, 4, 0),
    failureState: jspb.Message.getFieldWithDefault(msg, 5, 0),
    activity: jspb.Message.getFieldWithDefault(msg, 6, 0),
    activityState: jspb.Message.getFieldWithDefault(msg, 7, 0),
    version: jspb.Message.getFieldWithDefault(msg, 8, 0),
    subscriptionsList: jspb.Message.toObjectList(msg.getSubscriptionsList(),
    proto.domain.Subscription.toObject, includeInstance),
    notesList: jspb.Message.toObjectList(msg.getNotesList(),
    proto.domain.Note.toObject, includeInstance),
    vendorAccountsList: jspb.Message.toObjectList(msg.getVendorAccountsList(),
    proto.domain.VendorAccount.toObject, includeInstance),
    lastSeen: jspb.Message.getFieldWithDefault(msg, 12, 0),
    errorState: jspb.Message.getFieldWithDefault(msg, 13, 0),
    clientId: jspb.Message.getFieldWithDefault(msg, 14, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.domain.Customer}
 */
proto.domain.Customer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.domain.Customer;
  return proto.domain.Customer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.domain.Customer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.domain.Customer}
 */
proto.domain.Customer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNumber(value);
      break;
    case 3:
      var value = new proto.domain.CustomerInfo;
      reader.readMessage(value,proto.domain.CustomerInfo.deserializeBinaryFromReader);
      msg.setCustomerInfo(value);
      break;
    case 4:
      var value = /** @type {!proto.domain.CustomerState} */ (reader.readEnum());
      msg.setState(value);
      break;
    case 5:
      var value = /** @type {!proto.domain.CustomerFailureState} */ (reader.readEnum());
      msg.setFailureState(value);
      break;
    case 6:
      var value = /** @type {!proto.domain.CustomerActivity} */ (reader.readEnum());
      msg.setActivity(value);
      break;
    case 7:
      var value = /** @type {!proto.domain.ActivityState} */ (reader.readEnum());
      msg.setActivityState(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setVersion(value);
      break;
    case 9:
      var value = new proto.domain.Subscription;
      reader.readMessage(value,proto.domain.Subscription.deserializeBinaryFromReader);
      msg.addSubscriptions(value);
      break;
    case 10:
      var value = new proto.domain.Note;
      reader.readMessage(value,proto.domain.Note.deserializeBinaryFromReader);
      msg.addNotes(value);
      break;
    case 11:
      var value = new proto.domain.VendorAccount;
      reader.readMessage(value,proto.domain.VendorAccount.deserializeBinaryFromReader);
      msg.addVendorAccounts(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastSeen(value);
      break;
    case 13:
      var value = /** @type {!proto.domain.CustomerErrorState} */ (reader.readEnum());
      msg.setErrorState(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.domain.Customer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.domain.Customer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.domain.Customer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.domain.Customer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccountNumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCustomerInfo();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.domain.CustomerInfo.serializeBinaryToWriter
    );
  }
  f = message.getState();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getFailureState();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getActivity();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getActivityState();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getVersion();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getSubscriptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.domain.Subscription.serializeBinaryToWriter
    );
  }
  f = message.getNotesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.domain.Note.serializeBinaryToWriter
    );
  }
  f = message.getVendorAccountsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.domain.VendorAccount.serializeBinaryToWriter
    );
  }
  f = message.getLastSeen();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getErrorState();
  if (f !== 0.0) {
    writer.writeEnum(
      13,
      f
    );
  }
  f = message.getClientId();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.domain.Customer.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.Customer} returns this
 */
proto.domain.Customer.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string account_number = 2;
 * @return {string}
 */
proto.domain.Customer.prototype.getAccountNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.Customer} returns this
 */
proto.domain.Customer.prototype.setAccountNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional CustomerInfo customer_info = 3;
 * @return {?proto.domain.CustomerInfo}
 */
proto.domain.Customer.prototype.getCustomerInfo = function() {
  return /** @type{?proto.domain.CustomerInfo} */ (
    jspb.Message.getWrapperField(this, proto.domain.CustomerInfo, 3));
};


/**
 * @param {?proto.domain.CustomerInfo|undefined} value
 * @return {!proto.domain.Customer} returns this
*/
proto.domain.Customer.prototype.setCustomerInfo = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.domain.Customer} returns this
 */
proto.domain.Customer.prototype.clearCustomerInfo = function() {
  return this.setCustomerInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.domain.Customer.prototype.hasCustomerInfo = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional CustomerState state = 4;
 * @return {!proto.domain.CustomerState}
 */
proto.domain.Customer.prototype.getState = function() {
  return /** @type {!proto.domain.CustomerState} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.domain.CustomerState} value
 * @return {!proto.domain.Customer} returns this
 */
proto.domain.Customer.prototype.setState = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional CustomerFailureState failure_state = 5;
 * @return {!proto.domain.CustomerFailureState}
 */
proto.domain.Customer.prototype.getFailureState = function() {
  return /** @type {!proto.domain.CustomerFailureState} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.domain.CustomerFailureState} value
 * @return {!proto.domain.Customer} returns this
 */
proto.domain.Customer.prototype.setFailureState = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional CustomerActivity activity = 6;
 * @return {!proto.domain.CustomerActivity}
 */
proto.domain.Customer.prototype.getActivity = function() {
  return /** @type {!proto.domain.CustomerActivity} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.domain.CustomerActivity} value
 * @return {!proto.domain.Customer} returns this
 */
proto.domain.Customer.prototype.setActivity = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional ActivityState activity_state = 7;
 * @return {!proto.domain.ActivityState}
 */
proto.domain.Customer.prototype.getActivityState = function() {
  return /** @type {!proto.domain.ActivityState} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.domain.ActivityState} value
 * @return {!proto.domain.Customer} returns this
 */
proto.domain.Customer.prototype.setActivityState = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional int64 version = 8;
 * @return {number}
 */
proto.domain.Customer.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.domain.Customer} returns this
 */
proto.domain.Customer.prototype.setVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * repeated Subscription subscriptions = 9;
 * @return {!Array<!proto.domain.Subscription>}
 */
proto.domain.Customer.prototype.getSubscriptionsList = function() {
  return /** @type{!Array<!proto.domain.Subscription>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.domain.Subscription, 9));
};


/**
 * @param {!Array<!proto.domain.Subscription>} value
 * @return {!proto.domain.Customer} returns this
*/
proto.domain.Customer.prototype.setSubscriptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.domain.Subscription=} opt_value
 * @param {number=} opt_index
 * @return {!proto.domain.Subscription}
 */
proto.domain.Customer.prototype.addSubscriptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.domain.Subscription, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.domain.Customer} returns this
 */
proto.domain.Customer.prototype.clearSubscriptionsList = function() {
  return this.setSubscriptionsList([]);
};


/**
 * repeated Note notes = 10;
 * @return {!Array<!proto.domain.Note>}
 */
proto.domain.Customer.prototype.getNotesList = function() {
  return /** @type{!Array<!proto.domain.Note>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.domain.Note, 10));
};


/**
 * @param {!Array<!proto.domain.Note>} value
 * @return {!proto.domain.Customer} returns this
*/
proto.domain.Customer.prototype.setNotesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.domain.Note=} opt_value
 * @param {number=} opt_index
 * @return {!proto.domain.Note}
 */
proto.domain.Customer.prototype.addNotes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.domain.Note, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.domain.Customer} returns this
 */
proto.domain.Customer.prototype.clearNotesList = function() {
  return this.setNotesList([]);
};


/**
 * repeated VendorAccount vendor_accounts = 11;
 * @return {!Array<!proto.domain.VendorAccount>}
 */
proto.domain.Customer.prototype.getVendorAccountsList = function() {
  return /** @type{!Array<!proto.domain.VendorAccount>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.domain.VendorAccount, 11));
};


/**
 * @param {!Array<!proto.domain.VendorAccount>} value
 * @return {!proto.domain.Customer} returns this
*/
proto.domain.Customer.prototype.setVendorAccountsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.domain.VendorAccount=} opt_value
 * @param {number=} opt_index
 * @return {!proto.domain.VendorAccount}
 */
proto.domain.Customer.prototype.addVendorAccounts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.domain.VendorAccount, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.domain.Customer} returns this
 */
proto.domain.Customer.prototype.clearVendorAccountsList = function() {
  return this.setVendorAccountsList([]);
};


/**
 * optional int64 last_seen = 12;
 * @return {number}
 */
proto.domain.Customer.prototype.getLastSeen = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.domain.Customer} returns this
 */
proto.domain.Customer.prototype.setLastSeen = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional CustomerErrorState error_state = 13;
 * @return {!proto.domain.CustomerErrorState}
 */
proto.domain.Customer.prototype.getErrorState = function() {
  return /** @type {!proto.domain.CustomerErrorState} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {!proto.domain.CustomerErrorState} value
 * @return {!proto.domain.Customer} returns this
 */
proto.domain.Customer.prototype.setErrorState = function(value) {
  return jspb.Message.setProto3EnumField(this, 13, value);
};


/**
 * optional string client_id = 14;
 * @return {string}
 */
proto.domain.Customer.prototype.getClientId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.Customer} returns this
 */
proto.domain.Customer.prototype.setClientId = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.domain.CustomerInfo.repeatedFields_ = [6,9,10,12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.domain.CustomerInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.domain.CustomerInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.domain.CustomerInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.domain.CustomerInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    firstName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    language: jspb.Message.getFieldWithDefault(msg, 3, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 4, ""),
    email: jspb.Message.getFieldWithDefault(msg, 5, ""),
    addressesList: jspb.Message.toObjectList(msg.getAddressesList(),
    proto.domain.Address.toObject, includeInstance),
    licenseNumber: jspb.Message.getFieldWithDefault(msg, 7, ""),
    password: jspb.Message.getFieldWithDefault(msg, 8, ""),
    imagesList: jspb.Message.toObjectList(msg.getImagesList(),
    proto.domain.Image.toObject, includeInstance),
    scoresList: jspb.Message.toObjectList(msg.getScoresList(),
    proto.domain.Score.toObject, includeInstance),
    paymentGatewayId: jspb.Message.getFieldWithDefault(msg, 11, ""),
    insurancePoliciesList: jspb.Message.toObjectList(msg.getInsurancePoliciesList(),
    proto.domain.InsurancePolicy.toObject, includeInstance),
    licenseExpirationDate: jspb.Message.getFieldWithDefault(msg, 13, 0),
    dateOfBirth: jspb.Message.getFieldWithDefault(msg, 14, 0),
    qualificationTier: jspb.Message.getFieldWithDefault(msg, 15, 0),
    paymentMethodsRequired: jspb.Message.getFieldWithDefault(msg, 16, 0),
    customerId: jspb.Message.getFieldWithDefault(msg, 17, ""),
    previousTier: jspb.Message.getFieldWithDefault(msg, 18, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.domain.CustomerInfo}
 */
proto.domain.CustomerInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.domain.CustomerInfo;
  return proto.domain.CustomerInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.domain.CustomerInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.domain.CustomerInfo}
 */
proto.domain.CustomerInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLanguage(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 6:
      var value = new proto.domain.Address;
      reader.readMessage(value,proto.domain.Address.deserializeBinaryFromReader);
      msg.addAddresses(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setLicenseNumber(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 9:
      var value = new proto.domain.Image;
      reader.readMessage(value,proto.domain.Image.deserializeBinaryFromReader);
      msg.addImages(value);
      break;
    case 10:
      var value = new proto.domain.Score;
      reader.readMessage(value,proto.domain.Score.deserializeBinaryFromReader);
      msg.addScores(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymentGatewayId(value);
      break;
    case 12:
      var value = new proto.domain.InsurancePolicy;
      reader.readMessage(value,proto.domain.InsurancePolicy.deserializeBinaryFromReader);
      msg.addInsurancePolicies(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLicenseExpirationDate(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDateOfBirth(value);
      break;
    case 15:
      var value = /** @type {!proto.domain.QualificationTier} */ (reader.readEnum());
      msg.setQualificationTier(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPaymentMethodsRequired(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 18:
      var value = /** @type {!proto.domain.QualificationTier} */ (reader.readEnum());
      msg.setPreviousTier(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.domain.CustomerInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.domain.CustomerInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.domain.CustomerInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.domain.CustomerInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLanguage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAddressesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.domain.Address.serializeBinaryToWriter
    );
  }
  f = message.getLicenseNumber();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getImagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.domain.Image.serializeBinaryToWriter
    );
  }
  f = message.getScoresList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.domain.Score.serializeBinaryToWriter
    );
  }
  f = message.getPaymentGatewayId();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getInsurancePoliciesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      proto.domain.InsurancePolicy.serializeBinaryToWriter
    );
  }
  f = message.getLicenseExpirationDate();
  if (f !== 0) {
    writer.writeInt64(
      13,
      f
    );
  }
  f = message.getDateOfBirth();
  if (f !== 0) {
    writer.writeInt64(
      14,
      f
    );
  }
  f = message.getQualificationTier();
  if (f !== 0.0) {
    writer.writeEnum(
      15,
      f
    );
  }
  f = message.getPaymentMethodsRequired();
  if (f !== 0) {
    writer.writeInt32(
      16,
      f
    );
  }
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getPreviousTier();
  if (f !== 0.0) {
    writer.writeEnum(
      18,
      f
    );
  }
};


/**
 * optional string first_name = 1;
 * @return {string}
 */
proto.domain.CustomerInfo.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.CustomerInfo} returns this
 */
proto.domain.CustomerInfo.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string last_name = 2;
 * @return {string}
 */
proto.domain.CustomerInfo.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.CustomerInfo} returns this
 */
proto.domain.CustomerInfo.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string language = 3;
 * @return {string}
 */
proto.domain.CustomerInfo.prototype.getLanguage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.CustomerInfo} returns this
 */
proto.domain.CustomerInfo.prototype.setLanguage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string phone = 4;
 * @return {string}
 */
proto.domain.CustomerInfo.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.CustomerInfo} returns this
 */
proto.domain.CustomerInfo.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string email = 5;
 * @return {string}
 */
proto.domain.CustomerInfo.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.CustomerInfo} returns this
 */
proto.domain.CustomerInfo.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated Address addresses = 6;
 * @return {!Array<!proto.domain.Address>}
 */
proto.domain.CustomerInfo.prototype.getAddressesList = function() {
  return /** @type{!Array<!proto.domain.Address>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.domain.Address, 6));
};


/**
 * @param {!Array<!proto.domain.Address>} value
 * @return {!proto.domain.CustomerInfo} returns this
*/
proto.domain.CustomerInfo.prototype.setAddressesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.domain.Address=} opt_value
 * @param {number=} opt_index
 * @return {!proto.domain.Address}
 */
proto.domain.CustomerInfo.prototype.addAddresses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.domain.Address, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.domain.CustomerInfo} returns this
 */
proto.domain.CustomerInfo.prototype.clearAddressesList = function() {
  return this.setAddressesList([]);
};


/**
 * optional string license_number = 7;
 * @return {string}
 */
proto.domain.CustomerInfo.prototype.getLicenseNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.CustomerInfo} returns this
 */
proto.domain.CustomerInfo.prototype.setLicenseNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string password = 8;
 * @return {string}
 */
proto.domain.CustomerInfo.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.CustomerInfo} returns this
 */
proto.domain.CustomerInfo.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * repeated Image images = 9;
 * @return {!Array<!proto.domain.Image>}
 */
proto.domain.CustomerInfo.prototype.getImagesList = function() {
  return /** @type{!Array<!proto.domain.Image>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.domain.Image, 9));
};


/**
 * @param {!Array<!proto.domain.Image>} value
 * @return {!proto.domain.CustomerInfo} returns this
*/
proto.domain.CustomerInfo.prototype.setImagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.domain.Image=} opt_value
 * @param {number=} opt_index
 * @return {!proto.domain.Image}
 */
proto.domain.CustomerInfo.prototype.addImages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.domain.Image, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.domain.CustomerInfo} returns this
 */
proto.domain.CustomerInfo.prototype.clearImagesList = function() {
  return this.setImagesList([]);
};


/**
 * repeated Score scores = 10;
 * @return {!Array<!proto.domain.Score>}
 */
proto.domain.CustomerInfo.prototype.getScoresList = function() {
  return /** @type{!Array<!proto.domain.Score>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.domain.Score, 10));
};


/**
 * @param {!Array<!proto.domain.Score>} value
 * @return {!proto.domain.CustomerInfo} returns this
*/
proto.domain.CustomerInfo.prototype.setScoresList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.domain.Score=} opt_value
 * @param {number=} opt_index
 * @return {!proto.domain.Score}
 */
proto.domain.CustomerInfo.prototype.addScores = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.domain.Score, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.domain.CustomerInfo} returns this
 */
proto.domain.CustomerInfo.prototype.clearScoresList = function() {
  return this.setScoresList([]);
};


/**
 * optional string payment_gateway_id = 11;
 * @return {string}
 */
proto.domain.CustomerInfo.prototype.getPaymentGatewayId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.CustomerInfo} returns this
 */
proto.domain.CustomerInfo.prototype.setPaymentGatewayId = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * repeated InsurancePolicy insurance_policies = 12;
 * @return {!Array<!proto.domain.InsurancePolicy>}
 */
proto.domain.CustomerInfo.prototype.getInsurancePoliciesList = function() {
  return /** @type{!Array<!proto.domain.InsurancePolicy>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.domain.InsurancePolicy, 12));
};


/**
 * @param {!Array<!proto.domain.InsurancePolicy>} value
 * @return {!proto.domain.CustomerInfo} returns this
*/
proto.domain.CustomerInfo.prototype.setInsurancePoliciesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.domain.InsurancePolicy=} opt_value
 * @param {number=} opt_index
 * @return {!proto.domain.InsurancePolicy}
 */
proto.domain.CustomerInfo.prototype.addInsurancePolicies = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.domain.InsurancePolicy, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.domain.CustomerInfo} returns this
 */
proto.domain.CustomerInfo.prototype.clearInsurancePoliciesList = function() {
  return this.setInsurancePoliciesList([]);
};


/**
 * optional int64 license_expiration_date = 13;
 * @return {number}
 */
proto.domain.CustomerInfo.prototype.getLicenseExpirationDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.domain.CustomerInfo} returns this
 */
proto.domain.CustomerInfo.prototype.setLicenseExpirationDate = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional int64 date_of_birth = 14;
 * @return {number}
 */
proto.domain.CustomerInfo.prototype.getDateOfBirth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.domain.CustomerInfo} returns this
 */
proto.domain.CustomerInfo.prototype.setDateOfBirth = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional QualificationTier qualification_tier = 15;
 * @return {!proto.domain.QualificationTier}
 */
proto.domain.CustomerInfo.prototype.getQualificationTier = function() {
  return /** @type {!proto.domain.QualificationTier} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {!proto.domain.QualificationTier} value
 * @return {!proto.domain.CustomerInfo} returns this
 */
proto.domain.CustomerInfo.prototype.setQualificationTier = function(value) {
  return jspb.Message.setProto3EnumField(this, 15, value);
};


/**
 * optional int32 payment_methods_required = 16;
 * @return {number}
 */
proto.domain.CustomerInfo.prototype.getPaymentMethodsRequired = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.domain.CustomerInfo} returns this
 */
proto.domain.CustomerInfo.prototype.setPaymentMethodsRequired = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional string customer_id = 17;
 * @return {string}
 */
proto.domain.CustomerInfo.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.CustomerInfo} returns this
 */
proto.domain.CustomerInfo.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional QualificationTier previous_tier = 18;
 * @return {!proto.domain.QualificationTier}
 */
proto.domain.CustomerInfo.prototype.getPreviousTier = function() {
  return /** @type {!proto.domain.QualificationTier} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {!proto.domain.QualificationTier} value
 * @return {!proto.domain.CustomerInfo} returns this
 */
proto.domain.CustomerInfo.prototype.setPreviousTier = function(value) {
  return jspb.Message.setProto3EnumField(this, 18, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.domain.Subscription.repeatedFields_ = [5,6,12,15];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.domain.Subscription.prototype.toObject = function(opt_includeInstance) {
  return proto.domain.Subscription.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.domain.Subscription} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.domain.Subscription.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, 0),
    rate: jspb.Message.getFieldWithDefault(msg, 3, 0),
    vehicleId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    statementsList: jspb.Message.toObjectList(msg.getStatementsList(),
    proto.domain.Statement.toObject, includeInstance),
    paymentMethodsList: jspb.Message.toObjectList(msg.getPaymentMethodsList(),
    proto.domain.PaymentMethod.toObject, includeInstance),
    pickupDate: jspb.Message.getFieldWithDefault(msg, 7, 0),
    dealerId: jspb.Message.getFieldWithDefault(msg, 8, ""),
    activationDate: jspb.Message.getFieldWithDefault(msg, 9, 0),
    addressId: jspb.Message.getFieldWithDefault(msg, 10, ""),
    contractSignedDate: jspb.Message.getFieldWithDefault(msg, 11, 0),
    actionsList: jspb.Message.toObjectList(msg.getActionsList(),
    proto.domain.Subscription.SubscriptionAction.toObject, includeInstance),
    trackingId: jspb.Message.getFieldWithDefault(msg, 13, ""),
    customerId: jspb.Message.getFieldWithDefault(msg, 14, ""),
    odometerUpdatesList: jspb.Message.toObjectList(msg.getOdometerUpdatesList(),
    proto.domain.OdometerUpdate.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.domain.Subscription}
 */
proto.domain.Subscription.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.domain.Subscription;
  return proto.domain.Subscription.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.domain.Subscription} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.domain.Subscription}
 */
proto.domain.Subscription.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.domain.Subscription.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRate(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 5:
      var value = new proto.domain.Statement;
      reader.readMessage(value,proto.domain.Statement.deserializeBinaryFromReader);
      msg.addStatements(value);
      break;
    case 6:
      var value = new proto.domain.PaymentMethod;
      reader.readMessage(value,proto.domain.PaymentMethod.deserializeBinaryFromReader);
      msg.addPaymentMethods(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPickupDate(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealerId(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setActivationDate(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddressId(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setContractSignedDate(value);
      break;
    case 12:
      var value = new proto.domain.Subscription.SubscriptionAction;
      reader.readMessage(value,proto.domain.Subscription.SubscriptionAction.deserializeBinaryFromReader);
      msg.addActions(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrackingId(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 15:
      var value = new proto.domain.OdometerUpdate;
      reader.readMessage(value,proto.domain.OdometerUpdate.deserializeBinaryFromReader);
      msg.addOdometerUpdates(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.domain.Subscription.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.domain.Subscription.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.domain.Subscription} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.domain.Subscription.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getRate();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getStatementsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.domain.Statement.serializeBinaryToWriter
    );
  }
  f = message.getPaymentMethodsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.domain.PaymentMethod.serializeBinaryToWriter
    );
  }
  f = message.getPickupDate();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getDealerId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getActivationDate();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getAddressId();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getContractSignedDate();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getActionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      proto.domain.Subscription.SubscriptionAction.serializeBinaryToWriter
    );
  }
  f = message.getTrackingId();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getOdometerUpdatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      15,
      f,
      proto.domain.OdometerUpdate.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.domain.Subscription.Status = {
  PENDING: 0,
  ACTIVE: 1,
  CLOSED: 2,
  RETURNING: 3
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.domain.Subscription.SubscriptionAction.prototype.toObject = function(opt_includeInstance) {
  return proto.domain.Subscription.SubscriptionAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.domain.Subscription.SubscriptionAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.domain.Subscription.SubscriptionAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    timestamp: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    userEmail: jspb.Message.getFieldWithDefault(msg, 3, ""),
    userType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    actionType: jspb.Message.getFieldWithDefault(msg, 5, 0),
    actionReason: jspb.Message.getFieldWithDefault(msg, 6, 0),
    notes: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.domain.Subscription.SubscriptionAction}
 */
proto.domain.Subscription.SubscriptionAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.domain.Subscription.SubscriptionAction;
  return proto.domain.Subscription.SubscriptionAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.domain.Subscription.SubscriptionAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.domain.Subscription.SubscriptionAction}
 */
proto.domain.Subscription.SubscriptionAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestamp(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserEmail(value);
      break;
    case 4:
      var value = /** @type {!proto.domain.Subscription.SubscriptionAction.UserType} */ (reader.readEnum());
      msg.setUserType(value);
      break;
    case 5:
      var value = /** @type {!proto.domain.Subscription.SubscriptionAction.ActionType} */ (reader.readEnum());
      msg.setActionType(value);
      break;
    case 6:
      var value = /** @type {!proto.domain.Subscription.SubscriptionAction.ActionReason} */ (reader.readEnum());
      msg.setActionReason(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setNotes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.domain.Subscription.SubscriptionAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.domain.Subscription.SubscriptionAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.domain.Subscription.SubscriptionAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.domain.Subscription.SubscriptionAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUserEmail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUserType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getActionType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getActionReason();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getNotes();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.domain.Subscription.SubscriptionAction.UserType = {
  CUSTOMER: 0,
  DEALER: 1,
  AUTONOMY: 2
};

/**
 * @enum {number}
 */
proto.domain.Subscription.SubscriptionAction.ActionType = {
  CREATE: 0,
  UPDATE: 1,
  CANCEL: 2
};

/**
 * @enum {number}
 */
proto.domain.Subscription.SubscriptionAction.ActionReason = {
  DEALER_VEHICLE_NOT_AVAILABLE: 0,
  DEALER_VEHICLE_SELLING_PRICE_REJECTED: 1,
  DEALER_VEHICLE_SOLD: 2,
  CUSTOMER_VEHICLE_TOO_EXPENSIVE: 3,
  CUSTOMER_VEHICLE_DRIVE_NOT_AS_EXPECTED: 4,
  LINE_ITEM_ADDED: 5,
  TRANSACTION_ADDED: 6,
  STATEMENT_ADDED: 7,
  VEHICLE_PICKUP: 8,
  SUBSCRIPTION_ACTIVATED: 9,
  CONTRACT_SIGNED: 10,
  PAYMENT_METHOD_ADDED: 11,
  ANDROID_OS_UNSUPPORTED: 12,
  CUSTOMER_VEHICLE_DOES_NOT_WANT_VEHICLE: 13,
  ODOMETER_READING_UPDATE: 14
};

/**
 * optional int64 timestamp = 1;
 * @return {number}
 */
proto.domain.Subscription.SubscriptionAction.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.domain.Subscription.SubscriptionAction} returns this
 */
proto.domain.Subscription.SubscriptionAction.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string user_id = 2;
 * @return {string}
 */
proto.domain.Subscription.SubscriptionAction.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.Subscription.SubscriptionAction} returns this
 */
proto.domain.Subscription.SubscriptionAction.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string user_email = 3;
 * @return {string}
 */
proto.domain.Subscription.SubscriptionAction.prototype.getUserEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.Subscription.SubscriptionAction} returns this
 */
proto.domain.Subscription.SubscriptionAction.prototype.setUserEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional UserType user_type = 4;
 * @return {!proto.domain.Subscription.SubscriptionAction.UserType}
 */
proto.domain.Subscription.SubscriptionAction.prototype.getUserType = function() {
  return /** @type {!proto.domain.Subscription.SubscriptionAction.UserType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.domain.Subscription.SubscriptionAction.UserType} value
 * @return {!proto.domain.Subscription.SubscriptionAction} returns this
 */
proto.domain.Subscription.SubscriptionAction.prototype.setUserType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional ActionType action_type = 5;
 * @return {!proto.domain.Subscription.SubscriptionAction.ActionType}
 */
proto.domain.Subscription.SubscriptionAction.prototype.getActionType = function() {
  return /** @type {!proto.domain.Subscription.SubscriptionAction.ActionType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.domain.Subscription.SubscriptionAction.ActionType} value
 * @return {!proto.domain.Subscription.SubscriptionAction} returns this
 */
proto.domain.Subscription.SubscriptionAction.prototype.setActionType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional ActionReason action_reason = 6;
 * @return {!proto.domain.Subscription.SubscriptionAction.ActionReason}
 */
proto.domain.Subscription.SubscriptionAction.prototype.getActionReason = function() {
  return /** @type {!proto.domain.Subscription.SubscriptionAction.ActionReason} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.domain.Subscription.SubscriptionAction.ActionReason} value
 * @return {!proto.domain.Subscription.SubscriptionAction} returns this
 */
proto.domain.Subscription.SubscriptionAction.prototype.setActionReason = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional string notes = 7;
 * @return {string}
 */
proto.domain.Subscription.SubscriptionAction.prototype.getNotes = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.Subscription.SubscriptionAction} returns this
 */
proto.domain.Subscription.SubscriptionAction.prototype.setNotes = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.domain.Subscription.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.Subscription} returns this
 */
proto.domain.Subscription.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Status status = 2;
 * @return {!proto.domain.Subscription.Status}
 */
proto.domain.Subscription.prototype.getStatus = function() {
  return /** @type {!proto.domain.Subscription.Status} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.domain.Subscription.Status} value
 * @return {!proto.domain.Subscription} returns this
 */
proto.domain.Subscription.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional int32 rate = 3;
 * @return {number}
 */
proto.domain.Subscription.prototype.getRate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.domain.Subscription} returns this
 */
proto.domain.Subscription.prototype.setRate = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string vehicle_id = 4;
 * @return {string}
 */
proto.domain.Subscription.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.Subscription} returns this
 */
proto.domain.Subscription.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated Statement statements = 5;
 * @return {!Array<!proto.domain.Statement>}
 */
proto.domain.Subscription.prototype.getStatementsList = function() {
  return /** @type{!Array<!proto.domain.Statement>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.domain.Statement, 5));
};


/**
 * @param {!Array<!proto.domain.Statement>} value
 * @return {!proto.domain.Subscription} returns this
*/
proto.domain.Subscription.prototype.setStatementsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.domain.Statement=} opt_value
 * @param {number=} opt_index
 * @return {!proto.domain.Statement}
 */
proto.domain.Subscription.prototype.addStatements = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.domain.Statement, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.domain.Subscription} returns this
 */
proto.domain.Subscription.prototype.clearStatementsList = function() {
  return this.setStatementsList([]);
};


/**
 * repeated PaymentMethod payment_methods = 6;
 * @return {!Array<!proto.domain.PaymentMethod>}
 */
proto.domain.Subscription.prototype.getPaymentMethodsList = function() {
  return /** @type{!Array<!proto.domain.PaymentMethod>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.domain.PaymentMethod, 6));
};


/**
 * @param {!Array<!proto.domain.PaymentMethod>} value
 * @return {!proto.domain.Subscription} returns this
*/
proto.domain.Subscription.prototype.setPaymentMethodsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.domain.PaymentMethod=} opt_value
 * @param {number=} opt_index
 * @return {!proto.domain.PaymentMethod}
 */
proto.domain.Subscription.prototype.addPaymentMethods = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.domain.PaymentMethod, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.domain.Subscription} returns this
 */
proto.domain.Subscription.prototype.clearPaymentMethodsList = function() {
  return this.setPaymentMethodsList([]);
};


/**
 * optional int64 pickup_date = 7;
 * @return {number}
 */
proto.domain.Subscription.prototype.getPickupDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.domain.Subscription} returns this
 */
proto.domain.Subscription.prototype.setPickupDate = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string dealer_id = 8;
 * @return {string}
 */
proto.domain.Subscription.prototype.getDealerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.Subscription} returns this
 */
proto.domain.Subscription.prototype.setDealerId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional int64 activation_date = 9;
 * @return {number}
 */
proto.domain.Subscription.prototype.getActivationDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.domain.Subscription} returns this
 */
proto.domain.Subscription.prototype.setActivationDate = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string address_id = 10;
 * @return {string}
 */
proto.domain.Subscription.prototype.getAddressId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.Subscription} returns this
 */
proto.domain.Subscription.prototype.setAddressId = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional int64 contract_signed_date = 11;
 * @return {number}
 */
proto.domain.Subscription.prototype.getContractSignedDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.domain.Subscription} returns this
 */
proto.domain.Subscription.prototype.setContractSignedDate = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * repeated SubscriptionAction actions = 12;
 * @return {!Array<!proto.domain.Subscription.SubscriptionAction>}
 */
proto.domain.Subscription.prototype.getActionsList = function() {
  return /** @type{!Array<!proto.domain.Subscription.SubscriptionAction>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.domain.Subscription.SubscriptionAction, 12));
};


/**
 * @param {!Array<!proto.domain.Subscription.SubscriptionAction>} value
 * @return {!proto.domain.Subscription} returns this
*/
proto.domain.Subscription.prototype.setActionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.domain.Subscription.SubscriptionAction=} opt_value
 * @param {number=} opt_index
 * @return {!proto.domain.Subscription.SubscriptionAction}
 */
proto.domain.Subscription.prototype.addActions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.domain.Subscription.SubscriptionAction, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.domain.Subscription} returns this
 */
proto.domain.Subscription.prototype.clearActionsList = function() {
  return this.setActionsList([]);
};


/**
 * optional string tracking_id = 13;
 * @return {string}
 */
proto.domain.Subscription.prototype.getTrackingId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.Subscription} returns this
 */
proto.domain.Subscription.prototype.setTrackingId = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string customer_id = 14;
 * @return {string}
 */
proto.domain.Subscription.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.Subscription} returns this
 */
proto.domain.Subscription.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * repeated OdometerUpdate odometer_updates = 15;
 * @return {!Array<!proto.domain.OdometerUpdate>}
 */
proto.domain.Subscription.prototype.getOdometerUpdatesList = function() {
  return /** @type{!Array<!proto.domain.OdometerUpdate>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.domain.OdometerUpdate, 15));
};


/**
 * @param {!Array<!proto.domain.OdometerUpdate>} value
 * @return {!proto.domain.Subscription} returns this
*/
proto.domain.Subscription.prototype.setOdometerUpdatesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 15, value);
};


/**
 * @param {!proto.domain.OdometerUpdate=} opt_value
 * @param {number=} opt_index
 * @return {!proto.domain.OdometerUpdate}
 */
proto.domain.Subscription.prototype.addOdometerUpdates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 15, opt_value, proto.domain.OdometerUpdate, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.domain.Subscription} returns this
 */
proto.domain.Subscription.prototype.clearOdometerUpdatesList = function() {
  return this.setOdometerUpdatesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.domain.OdometerUpdate.prototype.toObject = function(opt_includeInstance) {
  return proto.domain.OdometerUpdate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.domain.OdometerUpdate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.domain.OdometerUpdate.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    timestamp: jspb.Message.getFieldWithDefault(msg, 2, 0),
    type: jspb.Message.getFieldWithDefault(msg, 3, 0),
    reading: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.domain.OdometerUpdate}
 */
proto.domain.OdometerUpdate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.domain.OdometerUpdate;
  return proto.domain.OdometerUpdate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.domain.OdometerUpdate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.domain.OdometerUpdate}
 */
proto.domain.OdometerUpdate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestamp(value);
      break;
    case 3:
      var value = /** @type {!proto.domain.OdometerUpdate.Type} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setReading(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.domain.OdometerUpdate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.domain.OdometerUpdate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.domain.OdometerUpdate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.domain.OdometerUpdate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getReading();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.domain.OdometerUpdate.Type = {
  TYPE_UNSPECIFIED: 0,
  TYPE_SUBSCRIPTION_START: 1,
  TYPE_SUBSCRIPTION_ACCRUAL: 2,
  TYPE_SUBSCRIPTION_END: 3
};

/**
 * optional string id = 1;
 * @return {string}
 */
proto.domain.OdometerUpdate.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.OdometerUpdate} returns this
 */
proto.domain.OdometerUpdate.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 timestamp = 2;
 * @return {number}
 */
proto.domain.OdometerUpdate.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.domain.OdometerUpdate} returns this
 */
proto.domain.OdometerUpdate.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional Type type = 3;
 * @return {!proto.domain.OdometerUpdate.Type}
 */
proto.domain.OdometerUpdate.prototype.getType = function() {
  return /** @type {!proto.domain.OdometerUpdate.Type} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.domain.OdometerUpdate.Type} value
 * @return {!proto.domain.OdometerUpdate} returns this
 */
proto.domain.OdometerUpdate.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional int64 reading = 4;
 * @return {number}
 */
proto.domain.OdometerUpdate.prototype.getReading = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.domain.OdometerUpdate} returns this
 */
proto.domain.OdometerUpdate.prototype.setReading = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.domain.InsurancePolicy.repeatedFields_ = [11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.domain.InsurancePolicy.prototype.toObject = function(opt_includeInstance) {
  return proto.domain.InsurancePolicy.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.domain.InsurancePolicy} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.domain.InsurancePolicy.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    providerName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    providerId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    policyNumber: jspb.Message.getFieldWithDefault(msg, 4, ""),
    expirationDateString: jspb.Message.getFieldWithDefault(msg, 5, ""),
    expirationDate: jspb.Message.getFieldWithDefault(msg, 6, 0),
    effectiveDateString: jspb.Message.getFieldWithDefault(msg, 7, ""),
    effectiveDate: jspb.Message.getFieldWithDefault(msg, 8, 0),
    status: jspb.Message.getFieldWithDefault(msg, 9, 0),
    version: jspb.Message.getFieldWithDefault(msg, 10, 0),
    imagesList: jspb.Message.toObjectList(msg.getImagesList(),
    proto.domain.Image.toObject, includeInstance),
    customerId: jspb.Message.getFieldWithDefault(msg, 12, ""),
    premium: jspb.Message.getFieldWithDefault(msg, 13, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.domain.InsurancePolicy}
 */
proto.domain.InsurancePolicy.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.domain.InsurancePolicy;
  return proto.domain.InsurancePolicy.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.domain.InsurancePolicy} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.domain.InsurancePolicy}
 */
proto.domain.InsurancePolicy.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProviderName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProviderId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPolicyNumber(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setExpirationDateString(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setExpirationDate(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setEffectiveDateString(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEffectiveDate(value);
      break;
    case 9:
      var value = /** @type {!proto.domain.InsurancePolicy.InsuranceStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setVersion(value);
      break;
    case 11:
      var value = new proto.domain.Image;
      reader.readMessage(value,proto.domain.Image.deserializeBinaryFromReader);
      msg.addImages(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPremium(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.domain.InsurancePolicy.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.domain.InsurancePolicy.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.domain.InsurancePolicy} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.domain.InsurancePolicy.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProviderName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProviderId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPolicyNumber();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getExpirationDateString();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getExpirationDate();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getEffectiveDateString();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getEffectiveDate();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getVersion();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getImagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.domain.Image.serializeBinaryToWriter
    );
  }
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getPremium();
  if (f !== 0) {
    writer.writeInt64(
      13,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.domain.InsurancePolicy.InsuranceStatus = {
  UNKNOWN: 0,
  VALID: 1,
  EXPIRED: 2
};

/**
 * optional string id = 1;
 * @return {string}
 */
proto.domain.InsurancePolicy.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.InsurancePolicy} returns this
 */
proto.domain.InsurancePolicy.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string provider_name = 2;
 * @return {string}
 */
proto.domain.InsurancePolicy.prototype.getProviderName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.InsurancePolicy} returns this
 */
proto.domain.InsurancePolicy.prototype.setProviderName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string provider_id = 3;
 * @return {string}
 */
proto.domain.InsurancePolicy.prototype.getProviderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.InsurancePolicy} returns this
 */
proto.domain.InsurancePolicy.prototype.setProviderId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string policy_number = 4;
 * @return {string}
 */
proto.domain.InsurancePolicy.prototype.getPolicyNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.InsurancePolicy} returns this
 */
proto.domain.InsurancePolicy.prototype.setPolicyNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string expiration_date_string = 5;
 * @return {string}
 */
proto.domain.InsurancePolicy.prototype.getExpirationDateString = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.InsurancePolicy} returns this
 */
proto.domain.InsurancePolicy.prototype.setExpirationDateString = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int64 expiration_date = 6;
 * @return {number}
 */
proto.domain.InsurancePolicy.prototype.getExpirationDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.domain.InsurancePolicy} returns this
 */
proto.domain.InsurancePolicy.prototype.setExpirationDate = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string effective_date_string = 7;
 * @return {string}
 */
proto.domain.InsurancePolicy.prototype.getEffectiveDateString = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.InsurancePolicy} returns this
 */
proto.domain.InsurancePolicy.prototype.setEffectiveDateString = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int64 effective_date = 8;
 * @return {number}
 */
proto.domain.InsurancePolicy.prototype.getEffectiveDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.domain.InsurancePolicy} returns this
 */
proto.domain.InsurancePolicy.prototype.setEffectiveDate = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional InsuranceStatus status = 9;
 * @return {!proto.domain.InsurancePolicy.InsuranceStatus}
 */
proto.domain.InsurancePolicy.prototype.getStatus = function() {
  return /** @type {!proto.domain.InsurancePolicy.InsuranceStatus} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.domain.InsurancePolicy.InsuranceStatus} value
 * @return {!proto.domain.InsurancePolicy} returns this
 */
proto.domain.InsurancePolicy.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional int64 version = 10;
 * @return {number}
 */
proto.domain.InsurancePolicy.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.domain.InsurancePolicy} returns this
 */
proto.domain.InsurancePolicy.prototype.setVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * repeated Image images = 11;
 * @return {!Array<!proto.domain.Image>}
 */
proto.domain.InsurancePolicy.prototype.getImagesList = function() {
  return /** @type{!Array<!proto.domain.Image>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.domain.Image, 11));
};


/**
 * @param {!Array<!proto.domain.Image>} value
 * @return {!proto.domain.InsurancePolicy} returns this
*/
proto.domain.InsurancePolicy.prototype.setImagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.domain.Image=} opt_value
 * @param {number=} opt_index
 * @return {!proto.domain.Image}
 */
proto.domain.InsurancePolicy.prototype.addImages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.domain.Image, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.domain.InsurancePolicy} returns this
 */
proto.domain.InsurancePolicy.prototype.clearImagesList = function() {
  return this.setImagesList([]);
};


/**
 * optional string customer_id = 12;
 * @return {string}
 */
proto.domain.InsurancePolicy.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.InsurancePolicy} returns this
 */
proto.domain.InsurancePolicy.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional int64 premium = 13;
 * @return {number}
 */
proto.domain.InsurancePolicy.prototype.getPremium = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.domain.InsurancePolicy} returns this
 */
proto.domain.InsurancePolicy.prototype.setPremium = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.domain.VendorAccount.prototype.toObject = function(opt_includeInstance) {
  return proto.domain.VendorAccount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.domain.VendorAccount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.domain.VendorAccount.toObject = function(includeInstance, msg) {
  var f, obj = {
    vendorName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    accountId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    status: jspb.Message.getFieldWithDefault(msg, 3, ""),
    alternateAccountId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.domain.VendorAccount}
 */
proto.domain.VendorAccount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.domain.VendorAccount;
  return proto.domain.VendorAccount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.domain.VendorAccount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.domain.VendorAccount}
 */
proto.domain.VendorAccount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVendorName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAlternateAccountId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.domain.VendorAccount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.domain.VendorAccount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.domain.VendorAccount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.domain.VendorAccount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVendorName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAlternateAccountId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string vendor_name = 1;
 * @return {string}
 */
proto.domain.VendorAccount.prototype.getVendorName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.VendorAccount} returns this
 */
proto.domain.VendorAccount.prototype.setVendorName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string account_id = 2;
 * @return {string}
 */
proto.domain.VendorAccount.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.VendorAccount} returns this
 */
proto.domain.VendorAccount.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string status = 3;
 * @return {string}
 */
proto.domain.VendorAccount.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.VendorAccount} returns this
 */
proto.domain.VendorAccount.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string alternate_account_id = 4;
 * @return {string}
 */
proto.domain.VendorAccount.prototype.getAlternateAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.VendorAccount} returns this
 */
proto.domain.VendorAccount.prototype.setAlternateAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.domain.Statement.repeatedFields_ = [8,9,11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.domain.Statement.prototype.toObject = function(opt_includeInstance) {
  return proto.domain.Statement.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.domain.Statement} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.domain.Statement.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    date: jspb.Message.getFieldWithDefault(msg, 3, 0),
    dueDate: jspb.Message.getFieldWithDefault(msg, 4, 0),
    paymentDate: jspb.Message.getFieldWithDefault(msg, 5, 0),
    invoiceNumber: jspb.Message.getFieldWithDefault(msg, 6, ""),
    status: jspb.Message.getFieldWithDefault(msg, 7, 0),
    lineItemsList: jspb.Message.toObjectList(msg.getLineItemsList(),
    proto.domain.LineItem.toObject, includeInstance),
    transactionsList: jspb.Message.toObjectList(msg.getTransactionsList(),
    proto.domain.Transaction.toObject, includeInstance),
    orderNumber: jspb.Message.getFieldWithDefault(msg, 10, ""),
    actionsList: jspb.Message.toObjectList(msg.getActionsList(),
    proto.domain.StatementAction.toObject, includeInstance),
    preferredPaymentMethodId: jspb.Message.getFieldWithDefault(msg, 12, ""),
    orderId: jspb.Message.getFieldWithDefault(msg, 13, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.domain.Statement}
 */
proto.domain.Statement.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.domain.Statement;
  return proto.domain.Statement.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.domain.Statement} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.domain.Statement}
 */
proto.domain.Statement.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDate(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDueDate(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPaymentDate(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setInvoiceNumber(value);
      break;
    case 7:
      var value = /** @type {!proto.domain.StatementStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 8:
      var value = new proto.domain.LineItem;
      reader.readMessage(value,proto.domain.LineItem.deserializeBinaryFromReader);
      msg.addLineItems(value);
      break;
    case 9:
      var value = new proto.domain.Transaction;
      reader.readMessage(value,proto.domain.Transaction.deserializeBinaryFromReader);
      msg.addTransactions(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderNumber(value);
      break;
    case 11:
      var value = new proto.domain.StatementAction;
      reader.readMessage(value,proto.domain.StatementAction.deserializeBinaryFromReader);
      msg.addActions(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setPreferredPaymentMethodId(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.domain.Statement.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.domain.Statement.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.domain.Statement} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.domain.Statement.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDate();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getDueDate();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getPaymentDate();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getInvoiceNumber();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getLineItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.domain.LineItem.serializeBinaryToWriter
    );
  }
  f = message.getTransactionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.domain.Transaction.serializeBinaryToWriter
    );
  }
  f = message.getOrderNumber();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getActionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.domain.StatementAction.serializeBinaryToWriter
    );
  }
  f = message.getPreferredPaymentMethodId();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getOrderId();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.domain.Statement.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.Statement} returns this
 */
proto.domain.Statement.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string subscription_id = 2;
 * @return {string}
 */
proto.domain.Statement.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.Statement} returns this
 */
proto.domain.Statement.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 date = 3;
 * @return {number}
 */
proto.domain.Statement.prototype.getDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.domain.Statement} returns this
 */
proto.domain.Statement.prototype.setDate = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 due_date = 4;
 * @return {number}
 */
proto.domain.Statement.prototype.getDueDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.domain.Statement} returns this
 */
proto.domain.Statement.prototype.setDueDate = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 payment_date = 5;
 * @return {number}
 */
proto.domain.Statement.prototype.getPaymentDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.domain.Statement} returns this
 */
proto.domain.Statement.prototype.setPaymentDate = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string invoice_number = 6;
 * @return {string}
 */
proto.domain.Statement.prototype.getInvoiceNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.Statement} returns this
 */
proto.domain.Statement.prototype.setInvoiceNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional StatementStatus status = 7;
 * @return {!proto.domain.StatementStatus}
 */
proto.domain.Statement.prototype.getStatus = function() {
  return /** @type {!proto.domain.StatementStatus} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.domain.StatementStatus} value
 * @return {!proto.domain.Statement} returns this
 */
proto.domain.Statement.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * repeated LineItem line_items = 8;
 * @return {!Array<!proto.domain.LineItem>}
 */
proto.domain.Statement.prototype.getLineItemsList = function() {
  return /** @type{!Array<!proto.domain.LineItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.domain.LineItem, 8));
};


/**
 * @param {!Array<!proto.domain.LineItem>} value
 * @return {!proto.domain.Statement} returns this
*/
proto.domain.Statement.prototype.setLineItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.domain.LineItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.domain.LineItem}
 */
proto.domain.Statement.prototype.addLineItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.domain.LineItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.domain.Statement} returns this
 */
proto.domain.Statement.prototype.clearLineItemsList = function() {
  return this.setLineItemsList([]);
};


/**
 * repeated Transaction transactions = 9;
 * @return {!Array<!proto.domain.Transaction>}
 */
proto.domain.Statement.prototype.getTransactionsList = function() {
  return /** @type{!Array<!proto.domain.Transaction>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.domain.Transaction, 9));
};


/**
 * @param {!Array<!proto.domain.Transaction>} value
 * @return {!proto.domain.Statement} returns this
*/
proto.domain.Statement.prototype.setTransactionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.domain.Transaction=} opt_value
 * @param {number=} opt_index
 * @return {!proto.domain.Transaction}
 */
proto.domain.Statement.prototype.addTransactions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.domain.Transaction, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.domain.Statement} returns this
 */
proto.domain.Statement.prototype.clearTransactionsList = function() {
  return this.setTransactionsList([]);
};


/**
 * optional string order_number = 10;
 * @return {string}
 */
proto.domain.Statement.prototype.getOrderNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.Statement} returns this
 */
proto.domain.Statement.prototype.setOrderNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * repeated StatementAction actions = 11;
 * @return {!Array<!proto.domain.StatementAction>}
 */
proto.domain.Statement.prototype.getActionsList = function() {
  return /** @type{!Array<!proto.domain.StatementAction>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.domain.StatementAction, 11));
};


/**
 * @param {!Array<!proto.domain.StatementAction>} value
 * @return {!proto.domain.Statement} returns this
*/
proto.domain.Statement.prototype.setActionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.domain.StatementAction=} opt_value
 * @param {number=} opt_index
 * @return {!proto.domain.StatementAction}
 */
proto.domain.Statement.prototype.addActions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.domain.StatementAction, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.domain.Statement} returns this
 */
proto.domain.Statement.prototype.clearActionsList = function() {
  return this.setActionsList([]);
};


/**
 * optional string preferred_payment_method_id = 12;
 * @return {string}
 */
proto.domain.Statement.prototype.getPreferredPaymentMethodId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.Statement} returns this
 */
proto.domain.Statement.prototype.setPreferredPaymentMethodId = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string order_id = 13;
 * @return {string}
 */
proto.domain.Statement.prototype.getOrderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.Statement} returns this
 */
proto.domain.Statement.prototype.setOrderId = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.domain.StatementAction.prototype.toObject = function(opt_includeInstance) {
  return proto.domain.StatementAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.domain.StatementAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.domain.StatementAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    date: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    userType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    actionType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    actionReason: jspb.Message.getFieldWithDefault(msg, 5, 0),
    notes: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.domain.StatementAction}
 */
proto.domain.StatementAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.domain.StatementAction;
  return proto.domain.StatementAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.domain.StatementAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.domain.StatementAction}
 */
proto.domain.StatementAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDate(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 3:
      var value = /** @type {!proto.domain.StatementAction.UserType} */ (reader.readEnum());
      msg.setUserType(value);
      break;
    case 4:
      var value = /** @type {!proto.domain.StatementAction.ActionType} */ (reader.readEnum());
      msg.setActionType(value);
      break;
    case 5:
      var value = /** @type {!proto.domain.StatementAction.ActionReason} */ (reader.readEnum());
      msg.setActionReason(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setNotes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.domain.StatementAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.domain.StatementAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.domain.StatementAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.domain.StatementAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDate();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUserType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getActionType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getActionReason();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getNotes();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.domain.StatementAction.UserType = {
  CUSTOMER: 0,
  DEALER: 1
};

/**
 * @enum {number}
 */
proto.domain.StatementAction.ActionType = {
  CREATE: 0,
  UPDATE: 1,
  CANCEL: 2
};

/**
 * @enum {number}
 */
proto.domain.StatementAction.ActionReason = {
  DEALER_VEHICLE_NOT_AVAILABLE: 0,
  DEALER_VEHICLE_SELLING_PRICE_REJECTED: 1,
  DEALER_VEHICLE_SOLD: 2,
  CUSTOMER_VEHICLE_TOO_EXPENSIVE: 3,
  CUSTOMER_VEHICLE_DRIVE_NOT_AS_EXPECTED: 4,
  LINE_ITEM_ADDED: 5,
  TRANSACTION_ADDED: 6,
  ACTIVATION: 7
};

/**
 * optional string date = 1;
 * @return {string}
 */
proto.domain.StatementAction.prototype.getDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.StatementAction} returns this
 */
proto.domain.StatementAction.prototype.setDate = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_id = 2;
 * @return {string}
 */
proto.domain.StatementAction.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.StatementAction} returns this
 */
proto.domain.StatementAction.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional UserType user_type = 3;
 * @return {!proto.domain.StatementAction.UserType}
 */
proto.domain.StatementAction.prototype.getUserType = function() {
  return /** @type {!proto.domain.StatementAction.UserType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.domain.StatementAction.UserType} value
 * @return {!proto.domain.StatementAction} returns this
 */
proto.domain.StatementAction.prototype.setUserType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional ActionType action_type = 4;
 * @return {!proto.domain.StatementAction.ActionType}
 */
proto.domain.StatementAction.prototype.getActionType = function() {
  return /** @type {!proto.domain.StatementAction.ActionType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.domain.StatementAction.ActionType} value
 * @return {!proto.domain.StatementAction} returns this
 */
proto.domain.StatementAction.prototype.setActionType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional ActionReason action_reason = 5;
 * @return {!proto.domain.StatementAction.ActionReason}
 */
proto.domain.StatementAction.prototype.getActionReason = function() {
  return /** @type {!proto.domain.StatementAction.ActionReason} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.domain.StatementAction.ActionReason} value
 * @return {!proto.domain.StatementAction} returns this
 */
proto.domain.StatementAction.prototype.setActionReason = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional string notes = 6;
 * @return {string}
 */
proto.domain.StatementAction.prototype.getNotes = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.StatementAction} returns this
 */
proto.domain.StatementAction.prototype.setNotes = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.domain.LineItem.prototype.toObject = function(opt_includeInstance) {
  return proto.domain.LineItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.domain.LineItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.domain.LineItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    date: jspb.Message.getFieldWithDefault(msg, 2, 0),
    title: jspb.Message.getFieldWithDefault(msg, 3, ""),
    amount: jspb.Message.getFieldWithDefault(msg, 4, 0),
    balance: jspb.Message.getFieldWithDefault(msg, 5, 0),
    currency: jspb.Message.getFieldWithDefault(msg, 6, ""),
    itemType: jspb.Message.getFieldWithDefault(msg, 7, 0),
    product: (f = msg.getProduct()) && proto.domain.Product.toObject(includeInstance, f),
    promotionId: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.domain.LineItem}
 */
proto.domain.LineItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.domain.LineItem;
  return proto.domain.LineItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.domain.LineItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.domain.LineItem}
 */
proto.domain.LineItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDate(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAmount(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBalance(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 7:
      var value = /** @type {!proto.domain.LineItem.LineItemType} */ (reader.readEnum());
      msg.setItemType(value);
      break;
    case 8:
      var value = new proto.domain.Product;
      reader.readMessage(value,proto.domain.Product.deserializeBinaryFromReader);
      msg.setProduct(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setPromotionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.domain.LineItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.domain.LineItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.domain.LineItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.domain.LineItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDate();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getBalance();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getItemType();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getProduct();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.domain.Product.serializeBinaryToWriter
    );
  }
  f = message.getPromotionId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.domain.LineItem.LineItemType = {
  PRODUCT: 0,
  START_PAYMENT: 1,
  TAX: 2,
  PAST_DUE: 3
};

/**
 * optional string id = 1;
 * @return {string}
 */
proto.domain.LineItem.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.LineItem} returns this
 */
proto.domain.LineItem.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 date = 2;
 * @return {number}
 */
proto.domain.LineItem.prototype.getDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.domain.LineItem} returns this
 */
proto.domain.LineItem.prototype.setDate = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string title = 3;
 * @return {string}
 */
proto.domain.LineItem.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.LineItem} returns this
 */
proto.domain.LineItem.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 amount = 4;
 * @return {number}
 */
proto.domain.LineItem.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.domain.LineItem} returns this
 */
proto.domain.LineItem.prototype.setAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 balance = 5;
 * @return {number}
 */
proto.domain.LineItem.prototype.getBalance = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.domain.LineItem} returns this
 */
proto.domain.LineItem.prototype.setBalance = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string currency = 6;
 * @return {string}
 */
proto.domain.LineItem.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.LineItem} returns this
 */
proto.domain.LineItem.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional LineItemType item_type = 7;
 * @return {!proto.domain.LineItem.LineItemType}
 */
proto.domain.LineItem.prototype.getItemType = function() {
  return /** @type {!proto.domain.LineItem.LineItemType} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.domain.LineItem.LineItemType} value
 * @return {!proto.domain.LineItem} returns this
 */
proto.domain.LineItem.prototype.setItemType = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional Product product = 8;
 * @return {?proto.domain.Product}
 */
proto.domain.LineItem.prototype.getProduct = function() {
  return /** @type{?proto.domain.Product} */ (
    jspb.Message.getWrapperField(this, proto.domain.Product, 8));
};


/**
 * @param {?proto.domain.Product|undefined} value
 * @return {!proto.domain.LineItem} returns this
*/
proto.domain.LineItem.prototype.setProduct = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.domain.LineItem} returns this
 */
proto.domain.LineItem.prototype.clearProduct = function() {
  return this.setProduct(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.domain.LineItem.prototype.hasProduct = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string promotion_id = 9;
 * @return {string}
 */
proto.domain.LineItem.prototype.getPromotionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.LineItem} returns this
 */
proto.domain.LineItem.prototype.setPromotionId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.domain.Transaction.prototype.toObject = function(opt_includeInstance) {
  return proto.domain.Transaction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.domain.Transaction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.domain.Transaction.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    date: jspb.Message.getFieldWithDefault(msg, 2, 0),
    title: jspb.Message.getFieldWithDefault(msg, 3, ""),
    amount: jspb.Message.getFieldWithDefault(msg, 4, 0),
    currency: jspb.Message.getFieldWithDefault(msg, 5, ""),
    paymentMethodId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    gatewayTransactionId: jspb.Message.getFieldWithDefault(msg, 8, ""),
    transactionType: jspb.Message.getFieldWithDefault(msg, 9, 0),
    paymentMethodName: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.domain.Transaction}
 */
proto.domain.Transaction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.domain.Transaction;
  return proto.domain.Transaction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.domain.Transaction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.domain.Transaction}
 */
proto.domain.Transaction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDate(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAmount(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymentMethodId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setGatewayTransactionId(value);
      break;
    case 9:
      var value = /** @type {!proto.domain.Transaction.TransactionType} */ (reader.readEnum());
      msg.setTransactionType(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymentMethodName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.domain.Transaction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.domain.Transaction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.domain.Transaction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.domain.Transaction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDate();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPaymentMethodId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getGatewayTransactionId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getTransactionType();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getPaymentMethodName();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.domain.Transaction.TransactionType = {
  CAPTURE: 0,
  AUTH: 1,
  AUTH_RELEASE: 2,
  FAILED_AFTER_SETTLEMENT: 3
};

/**
 * optional string id = 1;
 * @return {string}
 */
proto.domain.Transaction.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.Transaction} returns this
 */
proto.domain.Transaction.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 date = 2;
 * @return {number}
 */
proto.domain.Transaction.prototype.getDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.domain.Transaction} returns this
 */
proto.domain.Transaction.prototype.setDate = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string title = 3;
 * @return {string}
 */
proto.domain.Transaction.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.Transaction} returns this
 */
proto.domain.Transaction.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 amount = 4;
 * @return {number}
 */
proto.domain.Transaction.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.domain.Transaction} returns this
 */
proto.domain.Transaction.prototype.setAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string currency = 5;
 * @return {string}
 */
proto.domain.Transaction.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.Transaction} returns this
 */
proto.domain.Transaction.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string payment_method_id = 6;
 * @return {string}
 */
proto.domain.Transaction.prototype.getPaymentMethodId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.Transaction} returns this
 */
proto.domain.Transaction.prototype.setPaymentMethodId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string subscription_id = 7;
 * @return {string}
 */
proto.domain.Transaction.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.Transaction} returns this
 */
proto.domain.Transaction.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string gateway_transaction_id = 8;
 * @return {string}
 */
proto.domain.Transaction.prototype.getGatewayTransactionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.Transaction} returns this
 */
proto.domain.Transaction.prototype.setGatewayTransactionId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional TransactionType transaction_type = 9;
 * @return {!proto.domain.Transaction.TransactionType}
 */
proto.domain.Transaction.prototype.getTransactionType = function() {
  return /** @type {!proto.domain.Transaction.TransactionType} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.domain.Transaction.TransactionType} value
 * @return {!proto.domain.Transaction} returns this
 */
proto.domain.Transaction.prototype.setTransactionType = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional string payment_method_name = 10;
 * @return {string}
 */
proto.domain.Transaction.prototype.getPaymentMethodName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.Transaction} returns this
 */
proto.domain.Transaction.prototype.setPaymentMethodName = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.domain.PaymentMethod.prototype.toObject = function(opt_includeInstance) {
  return proto.domain.PaymentMethod.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.domain.PaymentMethod} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.domain.PaymentMethod.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    paymentType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    gatewayId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    token: jspb.Message.getFieldWithDefault(msg, 6, ""),
    primary: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    secondary: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    gatewayCustomerId: jspb.Message.getFieldWithDefault(msg, 9, ""),
    last4: jspb.Message.getFieldWithDefault(msg, 10, ""),
    brandCode: jspb.Message.getFieldWithDefault(msg, 11, ""),
    oneTimeUse: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    isDeleted: jspb.Message.getBooleanFieldWithDefault(msg, 13, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.domain.PaymentMethod}
 */
proto.domain.PaymentMethod.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.domain.PaymentMethod;
  return proto.domain.PaymentMethod.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.domain.PaymentMethod} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.domain.PaymentMethod}
 */
proto.domain.PaymentMethod.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {!proto.domain.PaymentMethod.PaymentType} */ (reader.readEnum());
      msg.setPaymentType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setGatewayId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPrimary(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSecondary(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setGatewayCustomerId(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setLast4(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setBrandCode(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOneTimeUse(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDeleted(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.domain.PaymentMethod.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.domain.PaymentMethod.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.domain.PaymentMethod} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.domain.PaymentMethod.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPaymentType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getGatewayId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPrimary();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getSecondary();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getGatewayCustomerId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getLast4();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getBrandCode();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getOneTimeUse();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getIsDeleted();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.domain.PaymentMethod.PaymentType = {
  ACH: 0,
  CREDIT_CARD: 1,
  DEBIT_CARD: 2
};

/**
 * optional string id = 1;
 * @return {string}
 */
proto.domain.PaymentMethod.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.PaymentMethod} returns this
 */
proto.domain.PaymentMethod.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.domain.PaymentMethod.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.PaymentMethod} returns this
 */
proto.domain.PaymentMethod.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional PaymentType payment_type = 3;
 * @return {!proto.domain.PaymentMethod.PaymentType}
 */
proto.domain.PaymentMethod.prototype.getPaymentType = function() {
  return /** @type {!proto.domain.PaymentMethod.PaymentType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.domain.PaymentMethod.PaymentType} value
 * @return {!proto.domain.PaymentMethod} returns this
 */
proto.domain.PaymentMethod.prototype.setPaymentType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string gateway_id = 4;
 * @return {string}
 */
proto.domain.PaymentMethod.prototype.getGatewayId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.PaymentMethod} returns this
 */
proto.domain.PaymentMethod.prototype.setGatewayId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string subscription_id = 5;
 * @return {string}
 */
proto.domain.PaymentMethod.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.PaymentMethod} returns this
 */
proto.domain.PaymentMethod.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string token = 6;
 * @return {string}
 */
proto.domain.PaymentMethod.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.PaymentMethod} returns this
 */
proto.domain.PaymentMethod.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional bool primary = 7;
 * @return {boolean}
 */
proto.domain.PaymentMethod.prototype.getPrimary = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.domain.PaymentMethod} returns this
 */
proto.domain.PaymentMethod.prototype.setPrimary = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool secondary = 8;
 * @return {boolean}
 */
proto.domain.PaymentMethod.prototype.getSecondary = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.domain.PaymentMethod} returns this
 */
proto.domain.PaymentMethod.prototype.setSecondary = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional string gateway_customer_id = 9;
 * @return {string}
 */
proto.domain.PaymentMethod.prototype.getGatewayCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.PaymentMethod} returns this
 */
proto.domain.PaymentMethod.prototype.setGatewayCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string last4 = 10;
 * @return {string}
 */
proto.domain.PaymentMethod.prototype.getLast4 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.PaymentMethod} returns this
 */
proto.domain.PaymentMethod.prototype.setLast4 = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string brand_code = 11;
 * @return {string}
 */
proto.domain.PaymentMethod.prototype.getBrandCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.PaymentMethod} returns this
 */
proto.domain.PaymentMethod.prototype.setBrandCode = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional bool one_time_use = 12;
 * @return {boolean}
 */
proto.domain.PaymentMethod.prototype.getOneTimeUse = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.domain.PaymentMethod} returns this
 */
proto.domain.PaymentMethod.prototype.setOneTimeUse = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional bool is_deleted = 13;
 * @return {boolean}
 */
proto.domain.PaymentMethod.prototype.getIsDeleted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.domain.PaymentMethod} returns this
 */
proto.domain.PaymentMethod.prototype.setIsDeleted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.domain.Note.prototype.toObject = function(opt_includeInstance) {
  return proto.domain.Note.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.domain.Note} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.domain.Note.toObject = function(includeInstance, msg) {
  var f, obj = {
    date: jspb.Message.getFieldWithDefault(msg, 1, 0),
    body: jspb.Message.getFieldWithDefault(msg, 2, ""),
    user: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.domain.Note}
 */
proto.domain.Note.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.domain.Note;
  return proto.domain.Note.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.domain.Note} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.domain.Note}
 */
proto.domain.Note.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDate(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBody(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.domain.Note.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.domain.Note.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.domain.Note} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.domain.Note.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDate();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getBody();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUser();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int64 date = 1;
 * @return {number}
 */
proto.domain.Note.prototype.getDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.domain.Note} returns this
 */
proto.domain.Note.prototype.setDate = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string body = 2;
 * @return {string}
 */
proto.domain.Note.prototype.getBody = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.Note} returns this
 */
proto.domain.Note.prototype.setBody = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string user = 3;
 * @return {string}
 */
proto.domain.Note.prototype.getUser = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.Note} returns this
 */
proto.domain.Note.prototype.setUser = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.domain.Gateway.prototype.toObject = function(opt_includeInstance) {
  return proto.domain.Gateway.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.domain.Gateway} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.domain.Gateway.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    url: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.domain.Gateway}
 */
proto.domain.Gateway.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.domain.Gateway;
  return proto.domain.Gateway.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.domain.Gateway} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.domain.Gateway}
 */
proto.domain.Gateway.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.domain.Gateway.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.domain.Gateway.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.domain.Gateway} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.domain.Gateway.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.domain.Gateway.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.Gateway} returns this
 */
proto.domain.Gateway.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.domain.Gateway.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.Gateway} returns this
 */
proto.domain.Gateway.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string url = 3;
 * @return {string}
 */
proto.domain.Gateway.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.Gateway} returns this
 */
proto.domain.Gateway.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.domain.GatewayToken.prototype.toObject = function(opt_includeInstance) {
  return proto.domain.GatewayToken.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.domain.GatewayToken} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.domain.GatewayToken.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    gatewayId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    token: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.domain.GatewayToken}
 */
proto.domain.GatewayToken.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.domain.GatewayToken;
  return proto.domain.GatewayToken.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.domain.GatewayToken} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.domain.GatewayToken}
 */
proto.domain.GatewayToken.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setGatewayId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.domain.GatewayToken.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.domain.GatewayToken.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.domain.GatewayToken} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.domain.GatewayToken.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getGatewayId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.domain.GatewayToken.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.GatewayToken} returns this
 */
proto.domain.GatewayToken.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string gateway_id = 2;
 * @return {string}
 */
proto.domain.GatewayToken.prototype.getGatewayId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.GatewayToken} returns this
 */
proto.domain.GatewayToken.prototype.setGatewayId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string token = 3;
 * @return {string}
 */
proto.domain.GatewayToken.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.GatewayToken} returns this
 */
proto.domain.GatewayToken.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.domain.Product.prototype.toObject = function(opt_includeInstance) {
  return proto.domain.Product.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.domain.Product} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.domain.Product.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    amount: jspb.Message.getFieldWithDefault(msg, 4, 0),
    currency: jspb.Message.getFieldWithDefault(msg, 5, ""),
    productType: jspb.Message.getFieldWithDefault(msg, 6, 0),
    recurring: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    addOn: jspb.Message.getBooleanFieldWithDefault(msg, 8, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.domain.Product}
 */
proto.domain.Product.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.domain.Product;
  return proto.domain.Product.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.domain.Product} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.domain.Product}
 */
proto.domain.Product.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAmount(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 6:
      var value = /** @type {!proto.domain.Product.ProductType} */ (reader.readEnum());
      msg.setProductType(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRecurring(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAddOn(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.domain.Product.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.domain.Product.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.domain.Product} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.domain.Product.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getProductType();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getRecurring();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getAddOn();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.domain.Product.ProductType = {
  NOTYPE: 0,
  RENT: 1,
  DEPOSIT: 2,
  TITLE_REG_DOC: 3,
  COL_AND_LI: 4,
  COL_AND_LI_DEDUCT: 5,
  EXM: 6,
  EWT: 7,
  MILEAGE: 8,
  WAITLIST_DEPOSIT: 9,
  LATE: 10,
  TOLL: 11,
  CITATION: 12,
  ADMINISTRATIVE: 13,
  DELIVERY: 14,
  PICKUP: 15,
  REPOSESSION: 16,
  DISPOSITION: 17,
  FLIP: 18,
  NSF: 19,
  IMPOUND: 20,
  SUBSCRIPTION_PRORATION: 21,
  START_FEE: 22,
  RECURRING_MILEAGE: 23,
  ONE_TIME_MILEAGE: 24
};

/**
 * optional string id = 1;
 * @return {string}
 */
proto.domain.Product.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.Product} returns this
 */
proto.domain.Product.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.domain.Product.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.Product} returns this
 */
proto.domain.Product.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.domain.Product.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.Product} returns this
 */
proto.domain.Product.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 amount = 4;
 * @return {number}
 */
proto.domain.Product.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.domain.Product} returns this
 */
proto.domain.Product.prototype.setAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string currency = 5;
 * @return {string}
 */
proto.domain.Product.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.Product} returns this
 */
proto.domain.Product.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional ProductType product_type = 6;
 * @return {!proto.domain.Product.ProductType}
 */
proto.domain.Product.prototype.getProductType = function() {
  return /** @type {!proto.domain.Product.ProductType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.domain.Product.ProductType} value
 * @return {!proto.domain.Product} returns this
 */
proto.domain.Product.prototype.setProductType = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional bool recurring = 7;
 * @return {boolean}
 */
proto.domain.Product.prototype.getRecurring = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.domain.Product} returns this
 */
proto.domain.Product.prototype.setRecurring = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool add_on = 8;
 * @return {boolean}
 */
proto.domain.Product.prototype.getAddOn = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.domain.Product} returns this
 */
proto.domain.Product.prototype.setAddOn = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.domain.Dealership.prototype.toObject = function(opt_includeInstance) {
  return proto.domain.Dealership.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.domain.Dealership} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.domain.Dealership.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    street: jspb.Message.getFieldWithDefault(msg, 3, ""),
    street2: jspb.Message.getFieldWithDefault(msg, 4, ""),
    city: jspb.Message.getFieldWithDefault(msg, 5, ""),
    state: jspb.Message.getFieldWithDefault(msg, 6, ""),
    postal: jspb.Message.getFieldWithDefault(msg, 7, ""),
    phoneNumber: jspb.Message.getFieldWithDefault(msg, 8, ""),
    latitude: jspb.Message.getFieldWithDefault(msg, 9, ""),
    longitude: jspb.Message.getFieldWithDefault(msg, 10, ""),
    vehicleCount: jspb.Message.getFieldWithDefault(msg, 11, 0),
    dealershipstate: jspb.Message.getFieldWithDefault(msg, 12, 0),
    dealershipactivity: jspb.Message.getFieldWithDefault(msg, 13, 0),
    dealershipactivitystate: jspb.Message.getFieldWithDefault(msg, 14, 0),
    dealershiperrorstate: jspb.Message.getFieldWithDefault(msg, 15, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.domain.Dealership}
 */
proto.domain.Dealership.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.domain.Dealership;
  return proto.domain.Dealership.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.domain.Dealership} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.domain.Dealership}
 */
proto.domain.Dealership.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStreet(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStreet2(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostal(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumber(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setLatitude(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setLongitude(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVehicleCount(value);
      break;
    case 12:
      var value = /** @type {!proto.domain.Dealership.State} */ (reader.readEnum());
      msg.setDealershipstate(value);
      break;
    case 13:
      var value = /** @type {!proto.domain.Dealership.Activity} */ (reader.readEnum());
      msg.setDealershipactivity(value);
      break;
    case 14:
      var value = /** @type {!proto.domain.Dealership.ActivityState} */ (reader.readEnum());
      msg.setDealershipactivitystate(value);
      break;
    case 15:
      var value = /** @type {!proto.domain.Dealership.ErrorState} */ (reader.readEnum());
      msg.setDealershiperrorstate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.domain.Dealership.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.domain.Dealership.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.domain.Dealership} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.domain.Dealership.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStreet();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStreet2();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPostal();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getLatitude();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getLongitude();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getVehicleCount();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getDealershipstate();
  if (f !== 0.0) {
    writer.writeEnum(
      12,
      f
    );
  }
  f = message.getDealershipactivity();
  if (f !== 0.0) {
    writer.writeEnum(
      13,
      f
    );
  }
  f = message.getDealershipactivitystate();
  if (f !== 0.0) {
    writer.writeEnum(
      14,
      f
    );
  }
  f = message.getDealershiperrorstate();
  if (f !== 0.0) {
    writer.writeEnum(
      15,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.domain.Dealership.State = {
  INACTIVE: 0,
  INVITED: 1,
  ONBOARDING: 2
};

/**
 * @enum {number}
 */
proto.domain.Dealership.Activity = {
  INVITE_DEALER: 0,
  PHONE_NUMBER_ENTRY: 1,
  USER_INFO_ENTRY: 2,
  ROLE_SELECTION: 3,
  ACH_INFO_ENTRY: 4,
  TEAM_MEMBERS_INVITE: 5,
  FINISH_ONBOARDING: 6
};

/**
 * @enum {number}
 */
proto.domain.Dealership.ActivityState = {
  DEALER_INACTIVE: 0,
  INVITATION_SENT: 1,
  PHONE_NUMBER_ENTERED: 2,
  PHONE_NUMBER_VERIFIED: 3,
  ACCOUNT_CREATED: 4,
  USER_INFO_ENTERED: 5,
  ROLE_SELECTED: 6,
  ACH_INFO_SUBMITTED: 7,
  TEAM_MEMBERS_INVITED: 8,
  ONBOARDING_COMPLETED: 9
};

/**
 * @enum {number}
 */
proto.domain.Dealership.ErrorState = {
  DEALER_NULL: 0,
  INVITATION_FAILED: 1,
  PHONE_VERIFICATION_FAILED: 2,
  ACCOUNT_CREATION_FAILED: 3,
  USER_INFO_SUBMISSION_FAILED: 4,
  ROLE_SELECTION_FAILED: 5,
  ACH_INFO_SUBMISSION_FAILED: 6,
  INVITE_TEAM_MEMBERS_FAILED: 7,
  ONBOARDING_COMPLETION_FAILED: 8
};

/**
 * optional string id = 1;
 * @return {string}
 */
proto.domain.Dealership.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.Dealership} returns this
 */
proto.domain.Dealership.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.domain.Dealership.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.Dealership} returns this
 */
proto.domain.Dealership.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string street = 3;
 * @return {string}
 */
proto.domain.Dealership.prototype.getStreet = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.Dealership} returns this
 */
proto.domain.Dealership.prototype.setStreet = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string street2 = 4;
 * @return {string}
 */
proto.domain.Dealership.prototype.getStreet2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.Dealership} returns this
 */
proto.domain.Dealership.prototype.setStreet2 = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string city = 5;
 * @return {string}
 */
proto.domain.Dealership.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.Dealership} returns this
 */
proto.domain.Dealership.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string state = 6;
 * @return {string}
 */
proto.domain.Dealership.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.Dealership} returns this
 */
proto.domain.Dealership.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string postal = 7;
 * @return {string}
 */
proto.domain.Dealership.prototype.getPostal = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.Dealership} returns this
 */
proto.domain.Dealership.prototype.setPostal = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string phone_number = 8;
 * @return {string}
 */
proto.domain.Dealership.prototype.getPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.Dealership} returns this
 */
proto.domain.Dealership.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string latitude = 9;
 * @return {string}
 */
proto.domain.Dealership.prototype.getLatitude = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.Dealership} returns this
 */
proto.domain.Dealership.prototype.setLatitude = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string longitude = 10;
 * @return {string}
 */
proto.domain.Dealership.prototype.getLongitude = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.Dealership} returns this
 */
proto.domain.Dealership.prototype.setLongitude = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional int32 vehicle_count = 11;
 * @return {number}
 */
proto.domain.Dealership.prototype.getVehicleCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.domain.Dealership} returns this
 */
proto.domain.Dealership.prototype.setVehicleCount = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional State dealershipState = 12;
 * @return {!proto.domain.Dealership.State}
 */
proto.domain.Dealership.prototype.getDealershipstate = function() {
  return /** @type {!proto.domain.Dealership.State} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {!proto.domain.Dealership.State} value
 * @return {!proto.domain.Dealership} returns this
 */
proto.domain.Dealership.prototype.setDealershipstate = function(value) {
  return jspb.Message.setProto3EnumField(this, 12, value);
};


/**
 * optional Activity dealershipActivity = 13;
 * @return {!proto.domain.Dealership.Activity}
 */
proto.domain.Dealership.prototype.getDealershipactivity = function() {
  return /** @type {!proto.domain.Dealership.Activity} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {!proto.domain.Dealership.Activity} value
 * @return {!proto.domain.Dealership} returns this
 */
proto.domain.Dealership.prototype.setDealershipactivity = function(value) {
  return jspb.Message.setProto3EnumField(this, 13, value);
};


/**
 * optional ActivityState dealershipActivityState = 14;
 * @return {!proto.domain.Dealership.ActivityState}
 */
proto.domain.Dealership.prototype.getDealershipactivitystate = function() {
  return /** @type {!proto.domain.Dealership.ActivityState} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {!proto.domain.Dealership.ActivityState} value
 * @return {!proto.domain.Dealership} returns this
 */
proto.domain.Dealership.prototype.setDealershipactivitystate = function(value) {
  return jspb.Message.setProto3EnumField(this, 14, value);
};


/**
 * optional ErrorState dealershipErrorState = 15;
 * @return {!proto.domain.Dealership.ErrorState}
 */
proto.domain.Dealership.prototype.getDealershiperrorstate = function() {
  return /** @type {!proto.domain.Dealership.ErrorState} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {!proto.domain.Dealership.ErrorState} value
 * @return {!proto.domain.Dealership} returns this
 */
proto.domain.Dealership.prototype.setDealershiperrorstate = function(value) {
  return jspb.Message.setProto3EnumField(this, 15, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.domain.TeamMember.prototype.toObject = function(opt_includeInstance) {
  return proto.domain.TeamMember.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.domain.TeamMember} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.domain.TeamMember.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    firstName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    cellPhone: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.domain.TeamMember}
 */
proto.domain.TeamMember.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.domain.TeamMember;
  return proto.domain.TeamMember.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.domain.TeamMember} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.domain.TeamMember}
 */
proto.domain.TeamMember.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCellPhone(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.domain.TeamMember.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.domain.TeamMember.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.domain.TeamMember} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.domain.TeamMember.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCellPhone();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.domain.TeamMember.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.TeamMember} returns this
 */
proto.domain.TeamMember.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string first_name = 2;
 * @return {string}
 */
proto.domain.TeamMember.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.TeamMember} returns this
 */
proto.domain.TeamMember.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string last_name = 3;
 * @return {string}
 */
proto.domain.TeamMember.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.TeamMember} returns this
 */
proto.domain.TeamMember.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string cell_phone = 4;
 * @return {string}
 */
proto.domain.TeamMember.prototype.getCellPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.TeamMember} returns this
 */
proto.domain.TeamMember.prototype.setCellPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.domain.DealerUser.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.domain.DealerUser.prototype.toObject = function(opt_includeInstance) {
  return proto.domain.DealerUser.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.domain.DealerUser} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.domain.DealerUser.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealershipId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    firstName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    cellPhone: jspb.Message.getFieldWithDefault(msg, 4, ""),
    workEmail: jspb.Message.getFieldWithDefault(msg, 5, ""),
    responsibilityList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    nxcrInvitation: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    dealerAppInvitation: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    id: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.domain.DealerUser}
 */
proto.domain.DealerUser.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.domain.DealerUser;
  return proto.domain.DealerUser.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.domain.DealerUser} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.domain.DealerUser}
 */
proto.domain.DealerUser.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealershipId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCellPhone(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setWorkEmail(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addResponsibility(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNxcrInvitation(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDealerAppInvitation(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.domain.DealerUser.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.domain.DealerUser.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.domain.DealerUser} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.domain.DealerUser.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealershipId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCellPhone();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getWorkEmail();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getResponsibilityList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
  f = message.getNxcrInvitation();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getDealerAppInvitation();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string dealership_id = 1;
 * @return {string}
 */
proto.domain.DealerUser.prototype.getDealershipId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.DealerUser} returns this
 */
proto.domain.DealerUser.prototype.setDealershipId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string first_name = 2;
 * @return {string}
 */
proto.domain.DealerUser.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.DealerUser} returns this
 */
proto.domain.DealerUser.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string last_name = 3;
 * @return {string}
 */
proto.domain.DealerUser.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.DealerUser} returns this
 */
proto.domain.DealerUser.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string cell_phone = 4;
 * @return {string}
 */
proto.domain.DealerUser.prototype.getCellPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.DealerUser} returns this
 */
proto.domain.DealerUser.prototype.setCellPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string work_email = 5;
 * @return {string}
 */
proto.domain.DealerUser.prototype.getWorkEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.DealerUser} returns this
 */
proto.domain.DealerUser.prototype.setWorkEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated string responsibility = 6;
 * @return {!Array<string>}
 */
proto.domain.DealerUser.prototype.getResponsibilityList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.domain.DealerUser} returns this
 */
proto.domain.DealerUser.prototype.setResponsibilityList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.domain.DealerUser} returns this
 */
proto.domain.DealerUser.prototype.addResponsibility = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.domain.DealerUser} returns this
 */
proto.domain.DealerUser.prototype.clearResponsibilityList = function() {
  return this.setResponsibilityList([]);
};


/**
 * optional bool nxcr_invitation = 7;
 * @return {boolean}
 */
proto.domain.DealerUser.prototype.getNxcrInvitation = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.domain.DealerUser} returns this
 */
proto.domain.DealerUser.prototype.setNxcrInvitation = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool dealer_app_invitation = 8;
 * @return {boolean}
 */
proto.domain.DealerUser.prototype.getDealerAppInvitation = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.domain.DealerUser} returns this
 */
proto.domain.DealerUser.prototype.setDealerAppInvitation = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional string id = 9;
 * @return {string}
 */
proto.domain.DealerUser.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.DealerUser} returns this
 */
proto.domain.DealerUser.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.domain.Appointment.prototype.toObject = function(opt_includeInstance) {
  return proto.domain.Appointment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.domain.Appointment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.domain.Appointment.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    scheduledFor: jspb.Message.getFieldWithDefault(msg, 2, ""),
    stockNumber: jspb.Message.getFieldWithDefault(msg, 3, ""),
    id: jspb.Message.getFieldWithDefault(msg, 4, ""),
    dealStatus: jspb.Message.getFieldWithDefault(msg, 5, 0),
    insuranceLinked: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    licenseScanned: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    appointmentType: jspb.Message.getFieldWithDefault(msg, 8, 0),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 9, ""),
    customerId: jspb.Message.getFieldWithDefault(msg, 10, ""),
    vehicleId: jspb.Message.getFieldWithDefault(msg, 11, ""),
    inspectionComplete: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    contractSigned: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    contractSubmitted: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    subscriptionActivated: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
    received: jspb.Message.getFieldWithDefault(msg, 16, ""),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 17, ""),
    pickupConfirmed: jspb.Message.getBooleanFieldWithDefault(msg, 18, false),
    paymentComplete: jspb.Message.getBooleanFieldWithDefault(msg, 19, false),
    accountNumber: jspb.Message.getFieldWithDefault(msg, 20, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.domain.Appointment}
 */
proto.domain.Appointment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.domain.Appointment;
  return proto.domain.Appointment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.domain.Appointment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.domain.Appointment}
 */
proto.domain.Appointment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setScheduledFor(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStockNumber(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 5:
      var value = /** @type {!proto.domain.Appointment.Status} */ (reader.readEnum());
      msg.setDealStatus(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInsuranceLinked(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLicenseScanned(value);
      break;
    case 8:
      var value = /** @type {!proto.domain.Appointment.Type} */ (reader.readEnum());
      msg.setAppointmentType(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInspectionComplete(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setContractSigned(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setContractSubmitted(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSubscriptionActivated(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setReceived(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedAt(value);
      break;
    case 18:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPickupConfirmed(value);
      break;
    case 19:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPaymentComplete(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.domain.Appointment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.domain.Appointment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.domain.Appointment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.domain.Appointment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getScheduledFor();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStockNumber();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDealStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getInsuranceLinked();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getLicenseScanned();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getAppointmentType();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getInspectionComplete();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getContractSigned();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getContractSubmitted();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getSubscriptionActivated();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getReceived();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getPickupConfirmed();
  if (f) {
    writer.writeBool(
      18,
      f
    );
  }
  f = message.getPaymentComplete();
  if (f) {
    writer.writeBool(
      19,
      f
    );
  }
  f = message.getAccountNumber();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.domain.Appointment.Status = {
  ACTIVE: 0,
  IN_PROGRESS: 1,
  COMPLETED: 2,
  CANCELED: 3
};

/**
 * @enum {number}
 */
proto.domain.Appointment.Type = {
  PICKUP: 0,
  TRANSPORT_DELIVERY: 1,
  DIRECT_DELIVERY: 2,
  DROP_OFF: 3
};

/**
 * optional string name = 1;
 * @return {string}
 */
proto.domain.Appointment.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.Appointment} returns this
 */
proto.domain.Appointment.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string scheduled_for = 2;
 * @return {string}
 */
proto.domain.Appointment.prototype.getScheduledFor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.Appointment} returns this
 */
proto.domain.Appointment.prototype.setScheduledFor = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string stock_number = 3;
 * @return {string}
 */
proto.domain.Appointment.prototype.getStockNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.Appointment} returns this
 */
proto.domain.Appointment.prototype.setStockNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string id = 4;
 * @return {string}
 */
proto.domain.Appointment.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.Appointment} returns this
 */
proto.domain.Appointment.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional Status deal_status = 5;
 * @return {!proto.domain.Appointment.Status}
 */
proto.domain.Appointment.prototype.getDealStatus = function() {
  return /** @type {!proto.domain.Appointment.Status} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.domain.Appointment.Status} value
 * @return {!proto.domain.Appointment} returns this
 */
proto.domain.Appointment.prototype.setDealStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional bool insurance_linked = 6;
 * @return {boolean}
 */
proto.domain.Appointment.prototype.getInsuranceLinked = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.domain.Appointment} returns this
 */
proto.domain.Appointment.prototype.setInsuranceLinked = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool license_scanned = 7;
 * @return {boolean}
 */
proto.domain.Appointment.prototype.getLicenseScanned = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.domain.Appointment} returns this
 */
proto.domain.Appointment.prototype.setLicenseScanned = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional Type appointment_type = 8;
 * @return {!proto.domain.Appointment.Type}
 */
proto.domain.Appointment.prototype.getAppointmentType = function() {
  return /** @type {!proto.domain.Appointment.Type} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.domain.Appointment.Type} value
 * @return {!proto.domain.Appointment} returns this
 */
proto.domain.Appointment.prototype.setAppointmentType = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional string subscription_id = 9;
 * @return {string}
 */
proto.domain.Appointment.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.Appointment} returns this
 */
proto.domain.Appointment.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string customer_id = 10;
 * @return {string}
 */
proto.domain.Appointment.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.Appointment} returns this
 */
proto.domain.Appointment.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string vehicle_id = 11;
 * @return {string}
 */
proto.domain.Appointment.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.Appointment} returns this
 */
proto.domain.Appointment.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional bool inspection_complete = 12;
 * @return {boolean}
 */
proto.domain.Appointment.prototype.getInspectionComplete = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.domain.Appointment} returns this
 */
proto.domain.Appointment.prototype.setInspectionComplete = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional bool contract_signed = 13;
 * @return {boolean}
 */
proto.domain.Appointment.prototype.getContractSigned = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.domain.Appointment} returns this
 */
proto.domain.Appointment.prototype.setContractSigned = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional bool contract_submitted = 14;
 * @return {boolean}
 */
proto.domain.Appointment.prototype.getContractSubmitted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.domain.Appointment} returns this
 */
proto.domain.Appointment.prototype.setContractSubmitted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional bool subscription_activated = 15;
 * @return {boolean}
 */
proto.domain.Appointment.prototype.getSubscriptionActivated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.domain.Appointment} returns this
 */
proto.domain.Appointment.prototype.setSubscriptionActivated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * optional string received = 16;
 * @return {string}
 */
proto.domain.Appointment.prototype.getReceived = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.Appointment} returns this
 */
proto.domain.Appointment.prototype.setReceived = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string updated_at = 17;
 * @return {string}
 */
proto.domain.Appointment.prototype.getUpdatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.Appointment} returns this
 */
proto.domain.Appointment.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional bool pickup_confirmed = 18;
 * @return {boolean}
 */
proto.domain.Appointment.prototype.getPickupConfirmed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 18, false));
};


/**
 * @param {boolean} value
 * @return {!proto.domain.Appointment} returns this
 */
proto.domain.Appointment.prototype.setPickupConfirmed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 18, value);
};


/**
 * optional bool payment_complete = 19;
 * @return {boolean}
 */
proto.domain.Appointment.prototype.getPaymentComplete = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 19, false));
};


/**
 * @param {boolean} value
 * @return {!proto.domain.Appointment} returns this
 */
proto.domain.Appointment.prototype.setPaymentComplete = function(value) {
  return jspb.Message.setProto3BooleanField(this, 19, value);
};


/**
 * optional string account_number = 20;
 * @return {string}
 */
proto.domain.Appointment.prototype.getAccountNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.Appointment} returns this
 */
proto.domain.Appointment.prototype.setAccountNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.domain.AutonomyUser.prototype.toObject = function(opt_includeInstance) {
  return proto.domain.AutonomyUser.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.domain.AutonomyUser} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.domain.AutonomyUser.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userEmail: jspb.Message.getFieldWithDefault(msg, 2, ""),
    userType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    userPhone: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.domain.AutonomyUser}
 */
proto.domain.AutonomyUser.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.domain.AutonomyUser;
  return proto.domain.AutonomyUser.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.domain.AutonomyUser} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.domain.AutonomyUser}
 */
proto.domain.AutonomyUser.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserEmail(value);
      break;
    case 3:
      var value = /** @type {!proto.domain.AutonomyUser.UserType} */ (reader.readEnum());
      msg.setUserType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserPhone(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.domain.AutonomyUser.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.domain.AutonomyUser.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.domain.AutonomyUser} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.domain.AutonomyUser.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserEmail();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUserType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getUserPhone();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.domain.AutonomyUser.UserType = {
  NEXUS: 0,
  CUSTOMER: 1,
  DEALER: 2,
  SYSTEM: 3,
  VENDOR: 4
};

/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.domain.AutonomyUser.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.AutonomyUser} returns this
 */
proto.domain.AutonomyUser.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_email = 2;
 * @return {string}
 */
proto.domain.AutonomyUser.prototype.getUserEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.AutonomyUser} returns this
 */
proto.domain.AutonomyUser.prototype.setUserEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional UserType user_type = 3;
 * @return {!proto.domain.AutonomyUser.UserType}
 */
proto.domain.AutonomyUser.prototype.getUserType = function() {
  return /** @type {!proto.domain.AutonomyUser.UserType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.domain.AutonomyUser.UserType} value
 * @return {!proto.domain.AutonomyUser} returns this
 */
proto.domain.AutonomyUser.prototype.setUserType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string user_phone = 4;
 * @return {string}
 */
proto.domain.AutonomyUser.prototype.getUserPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.AutonomyUser} returns this
 */
proto.domain.AutonomyUser.prototype.setUserPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.domain.AppointmentVehicle.prototype.toObject = function(opt_includeInstance) {
  return proto.domain.AppointmentVehicle.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.domain.AppointmentVehicle} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.domain.AppointmentVehicle.toObject = function(includeInstance, msg) {
  var f, obj = {
    vin: jspb.Message.getFieldWithDefault(msg, 1, ""),
    make: jspb.Message.getFieldWithDefault(msg, 2, ""),
    model: jspb.Message.getFieldWithDefault(msg, 3, ""),
    year: jspb.Message.getFieldWithDefault(msg, 4, ""),
    trim: jspb.Message.getFieldWithDefault(msg, 5, ""),
    transmission: jspb.Message.getFieldWithDefault(msg, 6, ""),
    bodyType: jspb.Message.getFieldWithDefault(msg, 7, ""),
    exteriorColor: jspb.Message.getFieldWithDefault(msg, 8, ""),
    interiorColor: jspb.Message.getFieldWithDefault(msg, 9, ""),
    drivetrain: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.domain.AppointmentVehicle}
 */
proto.domain.AppointmentVehicle.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.domain.AppointmentVehicle;
  return proto.domain.AppointmentVehicle.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.domain.AppointmentVehicle} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.domain.AppointmentVehicle}
 */
proto.domain.AppointmentVehicle.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVin(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMake(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setModel(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setYear(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrim(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransmission(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setBodyType(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setExteriorColor(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setInteriorColor(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setDrivetrain(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.domain.AppointmentVehicle.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.domain.AppointmentVehicle.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.domain.AppointmentVehicle} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.domain.AppointmentVehicle.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVin();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMake();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getModel();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getYear();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTrim();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getTransmission();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getBodyType();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getExteriorColor();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getInteriorColor();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getDrivetrain();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional string vin = 1;
 * @return {string}
 */
proto.domain.AppointmentVehicle.prototype.getVin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.AppointmentVehicle} returns this
 */
proto.domain.AppointmentVehicle.prototype.setVin = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string make = 2;
 * @return {string}
 */
proto.domain.AppointmentVehicle.prototype.getMake = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.AppointmentVehicle} returns this
 */
proto.domain.AppointmentVehicle.prototype.setMake = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string model = 3;
 * @return {string}
 */
proto.domain.AppointmentVehicle.prototype.getModel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.AppointmentVehicle} returns this
 */
proto.domain.AppointmentVehicle.prototype.setModel = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string year = 4;
 * @return {string}
 */
proto.domain.AppointmentVehicle.prototype.getYear = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.AppointmentVehicle} returns this
 */
proto.domain.AppointmentVehicle.prototype.setYear = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string trim = 5;
 * @return {string}
 */
proto.domain.AppointmentVehicle.prototype.getTrim = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.AppointmentVehicle} returns this
 */
proto.domain.AppointmentVehicle.prototype.setTrim = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string transmission = 6;
 * @return {string}
 */
proto.domain.AppointmentVehicle.prototype.getTransmission = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.AppointmentVehicle} returns this
 */
proto.domain.AppointmentVehicle.prototype.setTransmission = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string body_type = 7;
 * @return {string}
 */
proto.domain.AppointmentVehicle.prototype.getBodyType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.AppointmentVehicle} returns this
 */
proto.domain.AppointmentVehicle.prototype.setBodyType = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string exterior_color = 8;
 * @return {string}
 */
proto.domain.AppointmentVehicle.prototype.getExteriorColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.AppointmentVehicle} returns this
 */
proto.domain.AppointmentVehicle.prototype.setExteriorColor = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string interior_color = 9;
 * @return {string}
 */
proto.domain.AppointmentVehicle.prototype.getInteriorColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.AppointmentVehicle} returns this
 */
proto.domain.AppointmentVehicle.prototype.setInteriorColor = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string drivetrain = 10;
 * @return {string}
 */
proto.domain.AppointmentVehicle.prototype.getDrivetrain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.domain.AppointmentVehicle} returns this
 */
proto.domain.AppointmentVehicle.prototype.setDrivetrain = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * @enum {number}
 */
proto.domain.Domain = {
  SYSTEM: 0,
  VENDOR: 1,
  CUSTOMER: 2,
  SUBSCRIPTION: 3,
  FLEET: 4,
  INSURANCE: 5,
  DEALER: 6,
  NOTIFICATION: 7,
  RESERVATION: 8,
  PROGRAM: 9,
  PROMOTION: 10,
  APPOINTMENT: 11
};

/**
 * @enum {number}
 */
proto.domain.CustomerState = {
  ACCOUNT_PENDING: 0,
  ACCOUNT_CREATED: 1,
  PENDING_SECURITY_DEPOSIT: 2,
  SECURITY_DEPOSIT_COMPLETE: 3,
  PICKUP_SCHEDULED: 4,
  PENDING_PAYMENT_METHODS: 5,
  PENDING_INSURANCE: 6,
  PENDING_PICKUP: 7,
  PENDING_INSPECTION: 8,
  PENDING_SIGNATURE: 9,
  SUBSCRIPTION_ACTIVATION: 10
};

/**
 * @enum {number}
 */
proto.domain.CustomerErrorState = {
  NO_ERROR: 0,
  OTP_FAIL: 1,
  LICENSE_SCAN_FAILURE: 2,
  GARAGING_ADDRESS_FAILURE: 3,
  PAYMENT_ADDITION_FAILURE: 4,
  SECURITY_DEPOSIT_FAILURE: 5,
  PICKUP_DATE_UNAVAILABLE: 6,
  ADD_ONS_FAILURE: 7,
  INSURANCE_LINK_FAILURE: 8,
  INSURANCE_SELECTION_FAILURE: 9,
  QR_CODE_FAILURE: 10,
  INSPECTION_FAILURE: 11,
  PICKUP_REJECTION: 12,
  ACTIVATION_FAILURE: 13,
  VEHICLE_TRANSFER_FAILURE: 14,
  CONTRACT_FAILURE: 15
};

/**
 * @enum {number}
 */
proto.domain.CustomerFailureState = {
  NONE: 0,
  FRAUDULENT_PHONE: 1,
  FRAUDULENT_LICENSE: 2,
  FRAUDULENT_IDENTITY: 3,
  FRAUDULENT_EMAIL: 4,
  INELIGIBLE_USER: 5
};

/**
 * @enum {number}
 */
proto.domain.CustomerActivity = {
  PHONE_NUMBER_ENTRY: 0,
  OTP_ENTRY: 1,
  LICENSE_SCAN: 2,
  GARAGING_ADDRESS: 3,
  SELFIE_DEPRECATED: 4,
  EMAIL_ENTRY: 5,
  APPROVAL: 6,
  SECURITY_DEPOSIT: 7,
  SCHEDULE_PICKUP: 8,
  ADD_RECURRING_PAYMENT: 9,
  ADD_BACKUP_PAYMENT: 10,
  SELECT_ADD_ONS: 11,
  SELECT_INSURANCE: 12,
  GENERATE_QR_CODE: 13,
  CONDUCT_INSPECTION: 14,
  CONFIRM_PICKUP: 15,
  DIGITAL_SIGNATURE: 16,
  SUBMIT_CONTRACTS: 17
};

/**
 * @enum {number}
 */
proto.domain.ActivityState = {
  NEW: 0,
  PHONE_ENTERED: 1,
  OTP_SENT: 2,
  OTP_SUCCESS: 3,
  LICENSE_SCANNED: 4,
  GARAGING_ADDRESS_ADDED: 5,
  GARAGING_ADDRESS_CONFIRMED: 6,
  SELFIE_CAPTURED: 7,
  EMAIL_ENTERED: 8,
  EMAIL_VERIFIED: 9,
  ELIGIBLE_USER: 10,
  SECURITY_PAYMENT_METHOD_ADDED: 11,
  SECURITY_DEPOSIT_RECEIVED: 12,
  PENDING_PICKUP_DATE: 13,
  PICKUP_DATE_SELECTED: 14,
  PICKUP_DATE_CONFIRMED: 15,
  ACH_LINKED: 16,
  AUTOPAY_ENABLED: 17,
  BACKUP_PAYMENT_LINKED: 18,
  ADD_ONS_SELECTED: 19,
  ADD_ONS_CONFIRMED: 20,
  INSURANCE_SELECTED: 21,
  INSURANCE_LINKED: 22,
  QR_CODE_GENERATED: 23,
  INSPECTION_IN_PROGRESS: 24,
  INSPECTION_COMPLETE: 25,
  PICKUP_CONFIRMED: 26,
  CONTRACTS_SIGNED: 27,
  CONTRACTS_SUBMITTED: 28,
  START_PAYMENT_SUCCESS: 39,
  RECURRING_PAYMENT_SUCCESS: 30,
  SUBSCRIPTION_ACTIVATED: 31,
  VEHICLE_IN_FLEET: 32,
  SUBSCRIPTION_CANCELLED: 33,
  RESERVATION_CANCELLED: 34
};

/**
 * @enum {number}
 */
proto.domain.QualificationTier = {
  QUALIFICATION_PENDING: 0,
  DISQUALIFIED: 1,
  TIER_1: 2,
  TIER_2: 3,
  TIER_3: 4,
  THIN_FILE: 5,
  BORDERLINE_FICO: 6,
  NO_HIT: 7,
  LIQUIDITY_GENERIC: 8,
  ERROR: 10,
  NEEDS_REVIEW: 11,
  DISQUALIFIED_MANUAL: 12,
  TIER_1_MANUAL: 13,
  TIER_2_MANUAL: 14,
  TIER_3_MANUAL: 15
};

/**
 * @enum {number}
 */
proto.domain.DisqualificationReason = {
  IDENTITY: 0,
  FRAUD: 1,
  BACKGROUND: 2,
  FICO: 3,
  MVR: 4,
  LIQUIDITY: 5
};

/**
 * @enum {number}
 */
proto.domain.QualificationOrigin = {
  ORIGINAL: 0,
  VALIDATION: 1,
  OVERRIDE: 2
};

/**
 * @enum {number}
 */
proto.domain.ImageType = {
  LICENSE_FRONT: 0,
  LICENSE_BACK: 1,
  SELFIE: 2,
  INSURANCE_CARD: 3,
  VEHICLE_INSPECTION: 4
};

/**
 * @enum {number}
 */
proto.domain.StatementStatus = {
  PENDING: 0,
  DUE: 1,
  PAID: 2,
  OVERDUE: 3,
  CANCELLED: 4,
  FAILED: 5
};

goog.object.extend(exports, proto.domain);
