import React, { FC } from 'react'
import { UserClaims } from '@okta/okta-auth-js'
import { Link } from 'react-router-dom'
import { AppBar, Button, Toolbar, ThemeProvider, Box, IconButton, Icon } from '@mui/material'
import SearchBar from '../../apps/fleet-management/components/search-bar'
import theme from '../../global-styles'
import AutonomyLogo from '../assets/autonomy-logo'
import { autonomyColor } from '../../global-styles/autonomyColor'

export const TopNav: FC<{
  logout: () => void
  toggleSidebar: () => void
  isSidebarOpen: boolean
  user: UserClaims & { initials: string }
}> = ({ user, logout, toggleSidebar, isSidebarOpen }) => {
  
  return (
    <ThemeProvider theme={theme}>
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: autonomyColor.navigation }}
      >
        <Toolbar>
          <Box>
            <IconButton 
              aria-label="toggle sidebar" 
              onClick={toggleSidebar}
              sx={{
                marginRight: '10px'
              }} 
            >
              <Icon>{isSidebarOpen ? 'menu_open' : 'menu'}</Icon>
            </IconButton>
            <Link to="/">
              <AutonomyLogo />
            </Link>
          </Box>
          <div style={{ flex: 0.5 }} />
          <SearchBar />
          <div style={{ flex: 0.5 }} />
          <Button
            title="Log Out"
            variant="outlined"
            className="round"
            onClick={() => logout()}
          >
            {user.initials}
          </Button>
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  )
}