// source: nexus_service.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var appointment_pb = require('./appointment_pb.js');
goog.object.extend(proto, appointment_pb);
var billing_service_pb = require('./billing_service_pb.js');
goog.object.extend(proto, billing_service_pb);
var customer_service_pb = require('./customer_service_pb.js');
goog.object.extend(proto, customer_service_pb);
var dealer_pb = require('./dealer_pb.js');
goog.object.extend(proto, dealer_pb);
var domain_pb = require('./domain_pb.js');
goog.object.extend(proto, domain_pb);
var fleet_service_pb = require('./fleet_service_pb.js');
goog.object.extend(proto, fleet_service_pb);
var insurance_service_pb = require('./insurance_service_pb.js');
goog.object.extend(proto, insurance_service_pb);
var subscription_service_pb = require('./subscription_service_pb.js');
goog.object.extend(proto, subscription_service_pb);
var web_pb = require('./web_pb.js');
goog.object.extend(proto, web_pb);
goog.exportSymbol('proto.nexus_service.AddCustomerNoteRequest', null, global);
goog.exportSymbol('proto.nexus_service.AddCustomerNoteResponse', null, global);
goog.exportSymbol('proto.nexus_service.AppointmentDay', null, global);
goog.exportSymbol('proto.nexus_service.AppointmentKeyHandOffRequest', null, global);
goog.exportSymbol('proto.nexus_service.AppointmentKeyHandOffResponse', null, global);
goog.exportSymbol('proto.nexus_service.CancelAppointmentRequest', null, global);
goog.exportSymbol('proto.nexus_service.CancelAppointmentResponse', null, global);
goog.exportSymbol('proto.nexus_service.CreateAppointmentRequest', null, global);
goog.exportSymbol('proto.nexus_service.CreateAppointmentResponse', null, global);
goog.exportSymbol('proto.nexus_service.Customer', null, global);
goog.exportSymbol('proto.nexus_service.CustomerItem', null, global);
goog.exportSymbol('proto.nexus_service.GetAppointmentCheckoutStatusRequest', null, global);
goog.exportSymbol('proto.nexus_service.GetAppointmentCheckoutStatusResponse', null, global);
goog.exportSymbol('proto.nexus_service.GetAppointmentKeyHandOffRequest', null, global);
goog.exportSymbol('proto.nexus_service.GetAppointmentKeyHandOffResponse', null, global);
goog.exportSymbol('proto.nexus_service.GetCustomerListRequest', null, global);
goog.exportSymbol('proto.nexus_service.GetCustomerListRequest.ListType', null, global);
goog.exportSymbol('proto.nexus_service.GetCustomerListRequest.SortDirection', null, global);
goog.exportSymbol('proto.nexus_service.GetCustomerListResponse', null, global);
goog.exportSymbol('proto.nexus_service.GetCustomerNotesRequest', null, global);
goog.exportSymbol('proto.nexus_service.GetCustomerNotesResponse', null, global);
goog.exportSymbol('proto.nexus_service.GetCustomerNotesResponse.Note', null, global);
goog.exportSymbol('proto.nexus_service.GetCustomerRequest', null, global);
goog.exportSymbol('proto.nexus_service.GetCustomerResponse', null, global);
goog.exportSymbol('proto.nexus_service.GetLatestCustodyRequest', null, global);
goog.exportSymbol('proto.nexus_service.GetLatestCustodyRequest.CustodyType', null, global);
goog.exportSymbol('proto.nexus_service.GetLatestCustodyResponse', null, global);
goog.exportSymbol('proto.nexus_service.GetLatestCustodyResponse.CustodianCase', null, global);
goog.exportSymbol('proto.nexus_service.GetScheduledDateForStatementRequest', null, global);
goog.exportSymbol('proto.nexus_service.GetScheduledDateForStatementResponse', null, global);
goog.exportSymbol('proto.nexus_service.GetVehicleMapRequest', null, global);
goog.exportSymbol('proto.nexus_service.GetVehicleMapResponse', null, global);
goog.exportSymbol('proto.nexus_service.ListAppointmentTypeConfigsRequest', null, global);
goog.exportSymbol('proto.nexus_service.ListAppointmentTypeConfigsResponse', null, global);
goog.exportSymbol('proto.nexus_service.ListAppointmentsRequest', null, global);
goog.exportSymbol('proto.nexus_service.ListAppointmentsResponse', null, global);
goog.exportSymbol('proto.nexus_service.ListCompletedAppointmentsRequest', null, global);
goog.exportSymbol('proto.nexus_service.ListCompletedAppointmentsResponse', null, global);
goog.exportSymbol('proto.nexus_service.ListDealershipUsersRequest', null, global);
goog.exportSymbol('proto.nexus_service.ListDealershipUsersResponse', null, global);
goog.exportSymbol('proto.nexus_service.ListDealershipsRequest', null, global);
goog.exportSymbol('proto.nexus_service.ListDealershipsResponse', null, global);
goog.exportSymbol('proto.nexus_service.MapVehicle', null, global);
goog.exportSymbol('proto.nexus_service.SetAppointmentTypeConfigsRequest', null, global);
goog.exportSymbol('proto.nexus_service.SetAppointmentTypeConfigsResponse', null, global);
goog.exportSymbol('proto.nexus_service.SetReservationProgramRequest', null, global);
goog.exportSymbol('proto.nexus_service.SetReservationProgramResponse', null, global);
goog.exportSymbol('proto.nexus_service.Subscription', null, global);
goog.exportSymbol('proto.nexus_service.SubscriptionPickupDateRequest', null, global);
goog.exportSymbol('proto.nexus_service.SubscriptionPickupDateResponse', null, global);
goog.exportSymbol('proto.nexus_service.UpdateAppointmentRequest', null, global);
goog.exportSymbol('proto.nexus_service.UpdateAppointmentResponse', null, global);
goog.exportSymbol('proto.nexus_service.UpdateVehicleCustodyRequest', null, global);
goog.exportSymbol('proto.nexus_service.UpdateVehicleCustodyResponse', null, global);
goog.exportSymbol('proto.nexus_service.Vehicle', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nexus_service.AddCustomerNoteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.nexus_service.AddCustomerNoteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.nexus_service.AddCustomerNoteRequest.displayName = 'proto.nexus_service.AddCustomerNoteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nexus_service.AddCustomerNoteResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.nexus_service.AddCustomerNoteResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.nexus_service.AddCustomerNoteResponse.displayName = 'proto.nexus_service.AddCustomerNoteResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nexus_service.GetCustomerNotesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.nexus_service.GetCustomerNotesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.nexus_service.GetCustomerNotesRequest.displayName = 'proto.nexus_service.GetCustomerNotesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nexus_service.GetCustomerNotesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.nexus_service.GetCustomerNotesResponse.repeatedFields_, null);
};
goog.inherits(proto.nexus_service.GetCustomerNotesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.nexus_service.GetCustomerNotesResponse.displayName = 'proto.nexus_service.GetCustomerNotesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nexus_service.GetCustomerNotesResponse.Note = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.nexus_service.GetCustomerNotesResponse.Note, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.nexus_service.GetCustomerNotesResponse.Note.displayName = 'proto.nexus_service.GetCustomerNotesResponse.Note';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nexus_service.Customer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.nexus_service.Customer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.nexus_service.Customer.displayName = 'proto.nexus_service.Customer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nexus_service.Vehicle = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.nexus_service.Vehicle, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.nexus_service.Vehicle.displayName = 'proto.nexus_service.Vehicle';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nexus_service.Subscription = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.nexus_service.Subscription, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.nexus_service.Subscription.displayName = 'proto.nexus_service.Subscription';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nexus_service.CustomerItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.nexus_service.CustomerItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.nexus_service.CustomerItem.displayName = 'proto.nexus_service.CustomerItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nexus_service.GetCustomerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.nexus_service.GetCustomerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.nexus_service.GetCustomerRequest.displayName = 'proto.nexus_service.GetCustomerRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nexus_service.GetCustomerResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.nexus_service.GetCustomerResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.nexus_service.GetCustomerResponse.displayName = 'proto.nexus_service.GetCustomerResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nexus_service.GetCustomerListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.nexus_service.GetCustomerListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.nexus_service.GetCustomerListRequest.displayName = 'proto.nexus_service.GetCustomerListRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nexus_service.GetCustomerListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.nexus_service.GetCustomerListResponse.repeatedFields_, null);
};
goog.inherits(proto.nexus_service.GetCustomerListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.nexus_service.GetCustomerListResponse.displayName = 'proto.nexus_service.GetCustomerListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nexus_service.GetScheduledDateForStatementRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.nexus_service.GetScheduledDateForStatementRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.nexus_service.GetScheduledDateForStatementRequest.displayName = 'proto.nexus_service.GetScheduledDateForStatementRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nexus_service.GetScheduledDateForStatementResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.nexus_service.GetScheduledDateForStatementResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.nexus_service.GetScheduledDateForStatementResponse.displayName = 'proto.nexus_service.GetScheduledDateForStatementResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nexus_service.SubscriptionPickupDateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.nexus_service.SubscriptionPickupDateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.nexus_service.SubscriptionPickupDateRequest.displayName = 'proto.nexus_service.SubscriptionPickupDateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nexus_service.SubscriptionPickupDateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.nexus_service.SubscriptionPickupDateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.nexus_service.SubscriptionPickupDateResponse.displayName = 'proto.nexus_service.SubscriptionPickupDateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nexus_service.CreateAppointmentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.nexus_service.CreateAppointmentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.nexus_service.CreateAppointmentRequest.displayName = 'proto.nexus_service.CreateAppointmentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nexus_service.CreateAppointmentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.nexus_service.CreateAppointmentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.nexus_service.CreateAppointmentResponse.displayName = 'proto.nexus_service.CreateAppointmentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nexus_service.CancelAppointmentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.nexus_service.CancelAppointmentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.nexus_service.CancelAppointmentRequest.displayName = 'proto.nexus_service.CancelAppointmentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nexus_service.CancelAppointmentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.nexus_service.CancelAppointmentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.nexus_service.CancelAppointmentResponse.displayName = 'proto.nexus_service.CancelAppointmentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nexus_service.ListAppointmentsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.nexus_service.ListAppointmentsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.nexus_service.ListAppointmentsRequest.displayName = 'proto.nexus_service.ListAppointmentsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nexus_service.AppointmentDay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.nexus_service.AppointmentDay.repeatedFields_, null);
};
goog.inherits(proto.nexus_service.AppointmentDay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.nexus_service.AppointmentDay.displayName = 'proto.nexus_service.AppointmentDay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nexus_service.ListAppointmentsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.nexus_service.ListAppointmentsResponse.repeatedFields_, null);
};
goog.inherits(proto.nexus_service.ListAppointmentsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.nexus_service.ListAppointmentsResponse.displayName = 'proto.nexus_service.ListAppointmentsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nexus_service.SetReservationProgramRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.nexus_service.SetReservationProgramRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.nexus_service.SetReservationProgramRequest.displayName = 'proto.nexus_service.SetReservationProgramRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nexus_service.SetReservationProgramResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.nexus_service.SetReservationProgramResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.nexus_service.SetReservationProgramResponse.displayName = 'proto.nexus_service.SetReservationProgramResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nexus_service.ListAppointmentTypeConfigsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.nexus_service.ListAppointmentTypeConfigsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.nexus_service.ListAppointmentTypeConfigsRequest.displayName = 'proto.nexus_service.ListAppointmentTypeConfigsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nexus_service.ListAppointmentTypeConfigsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.nexus_service.ListAppointmentTypeConfigsResponse.repeatedFields_, null);
};
goog.inherits(proto.nexus_service.ListAppointmentTypeConfigsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.nexus_service.ListAppointmentTypeConfigsResponse.displayName = 'proto.nexus_service.ListAppointmentTypeConfigsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nexus_service.SetAppointmentTypeConfigsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.nexus_service.SetAppointmentTypeConfigsRequest.repeatedFields_, null);
};
goog.inherits(proto.nexus_service.SetAppointmentTypeConfigsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.nexus_service.SetAppointmentTypeConfigsRequest.displayName = 'proto.nexus_service.SetAppointmentTypeConfigsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nexus_service.SetAppointmentTypeConfigsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.nexus_service.SetAppointmentTypeConfigsResponse.repeatedFields_, null);
};
goog.inherits(proto.nexus_service.SetAppointmentTypeConfigsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.nexus_service.SetAppointmentTypeConfigsResponse.displayName = 'proto.nexus_service.SetAppointmentTypeConfigsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nexus_service.GetAppointmentCheckoutStatusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.nexus_service.GetAppointmentCheckoutStatusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.nexus_service.GetAppointmentCheckoutStatusRequest.displayName = 'proto.nexus_service.GetAppointmentCheckoutStatusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nexus_service.GetAppointmentCheckoutStatusResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.nexus_service.GetAppointmentCheckoutStatusResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.nexus_service.GetAppointmentCheckoutStatusResponse.displayName = 'proto.nexus_service.GetAppointmentCheckoutStatusResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nexus_service.AppointmentKeyHandOffRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.nexus_service.AppointmentKeyHandOffRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.nexus_service.AppointmentKeyHandOffRequest.displayName = 'proto.nexus_service.AppointmentKeyHandOffRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nexus_service.AppointmentKeyHandOffResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.nexus_service.AppointmentKeyHandOffResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.nexus_service.AppointmentKeyHandOffResponse.displayName = 'proto.nexus_service.AppointmentKeyHandOffResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nexus_service.GetAppointmentKeyHandOffRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.nexus_service.GetAppointmentKeyHandOffRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.nexus_service.GetAppointmentKeyHandOffRequest.displayName = 'proto.nexus_service.GetAppointmentKeyHandOffRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nexus_service.GetAppointmentKeyHandOffResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.nexus_service.GetAppointmentKeyHandOffResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.nexus_service.GetAppointmentKeyHandOffResponse.displayName = 'proto.nexus_service.GetAppointmentKeyHandOffResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nexus_service.ListCompletedAppointmentsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.nexus_service.ListCompletedAppointmentsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.nexus_service.ListCompletedAppointmentsRequest.displayName = 'proto.nexus_service.ListCompletedAppointmentsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nexus_service.ListCompletedAppointmentsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.nexus_service.ListCompletedAppointmentsResponse.repeatedFields_, null);
};
goog.inherits(proto.nexus_service.ListCompletedAppointmentsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.nexus_service.ListCompletedAppointmentsResponse.displayName = 'proto.nexus_service.ListCompletedAppointmentsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nexus_service.ListDealershipsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.nexus_service.ListDealershipsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.nexus_service.ListDealershipsRequest.displayName = 'proto.nexus_service.ListDealershipsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nexus_service.ListDealershipsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.nexus_service.ListDealershipsResponse.repeatedFields_, null);
};
goog.inherits(proto.nexus_service.ListDealershipsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.nexus_service.ListDealershipsResponse.displayName = 'proto.nexus_service.ListDealershipsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nexus_service.UpdateAppointmentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.nexus_service.UpdateAppointmentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.nexus_service.UpdateAppointmentRequest.displayName = 'proto.nexus_service.UpdateAppointmentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nexus_service.UpdateAppointmentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.nexus_service.UpdateAppointmentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.nexus_service.UpdateAppointmentResponse.displayName = 'proto.nexus_service.UpdateAppointmentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nexus_service.ListDealershipUsersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.nexus_service.ListDealershipUsersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.nexus_service.ListDealershipUsersRequest.displayName = 'proto.nexus_service.ListDealershipUsersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nexus_service.ListDealershipUsersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.nexus_service.ListDealershipUsersResponse.repeatedFields_, null);
};
goog.inherits(proto.nexus_service.ListDealershipUsersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.nexus_service.ListDealershipUsersResponse.displayName = 'proto.nexus_service.ListDealershipUsersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nexus_service.GetVehicleMapRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.nexus_service.GetVehicleMapRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.nexus_service.GetVehicleMapRequest.displayName = 'proto.nexus_service.GetVehicleMapRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nexus_service.GetVehicleMapResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.nexus_service.GetVehicleMapResponse.repeatedFields_, null);
};
goog.inherits(proto.nexus_service.GetVehicleMapResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.nexus_service.GetVehicleMapResponse.displayName = 'proto.nexus_service.GetVehicleMapResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nexus_service.MapVehicle = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.nexus_service.MapVehicle, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.nexus_service.MapVehicle.displayName = 'proto.nexus_service.MapVehicle';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nexus_service.UpdateVehicleCustodyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.nexus_service.UpdateVehicleCustodyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.nexus_service.UpdateVehicleCustodyRequest.displayName = 'proto.nexus_service.UpdateVehicleCustodyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nexus_service.UpdateVehicleCustodyResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.nexus_service.UpdateVehicleCustodyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.nexus_service.UpdateVehicleCustodyResponse.displayName = 'proto.nexus_service.UpdateVehicleCustodyResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nexus_service.GetLatestCustodyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.nexus_service.GetLatestCustodyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.nexus_service.GetLatestCustodyRequest.displayName = 'proto.nexus_service.GetLatestCustodyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.nexus_service.GetLatestCustodyResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.nexus_service.GetLatestCustodyResponse.oneofGroups_);
};
goog.inherits(proto.nexus_service.GetLatestCustodyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.nexus_service.GetLatestCustodyResponse.displayName = 'proto.nexus_service.GetLatestCustodyResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.nexus_service.AddCustomerNoteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.nexus_service.AddCustomerNoteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.nexus_service.AddCustomerNoteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.AddCustomerNoteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    noteText: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nexus_service.AddCustomerNoteRequest}
 */
proto.nexus_service.AddCustomerNoteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.nexus_service.AddCustomerNoteRequest;
  return proto.nexus_service.AddCustomerNoteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nexus_service.AddCustomerNoteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nexus_service.AddCustomerNoteRequest}
 */
proto.nexus_service.AddCustomerNoteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNoteText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nexus_service.AddCustomerNoteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.nexus_service.AddCustomerNoteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nexus_service.AddCustomerNoteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.AddCustomerNoteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNoteText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.nexus_service.AddCustomerNoteRequest.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.nexus_service.AddCustomerNoteRequest} returns this
 */
proto.nexus_service.AddCustomerNoteRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string note_text = 2;
 * @return {string}
 */
proto.nexus_service.AddCustomerNoteRequest.prototype.getNoteText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.nexus_service.AddCustomerNoteRequest} returns this
 */
proto.nexus_service.AddCustomerNoteRequest.prototype.setNoteText = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.nexus_service.AddCustomerNoteResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.nexus_service.AddCustomerNoteResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.nexus_service.AddCustomerNoteResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.AddCustomerNoteResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nexus_service.AddCustomerNoteResponse}
 */
proto.nexus_service.AddCustomerNoteResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.nexus_service.AddCustomerNoteResponse;
  return proto.nexus_service.AddCustomerNoteResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nexus_service.AddCustomerNoteResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nexus_service.AddCustomerNoteResponse}
 */
proto.nexus_service.AddCustomerNoteResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nexus_service.AddCustomerNoteResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.nexus_service.AddCustomerNoteResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nexus_service.AddCustomerNoteResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.AddCustomerNoteResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.nexus_service.GetCustomerNotesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.nexus_service.GetCustomerNotesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.nexus_service.GetCustomerNotesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.GetCustomerNotesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nexus_service.GetCustomerNotesRequest}
 */
proto.nexus_service.GetCustomerNotesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.nexus_service.GetCustomerNotesRequest;
  return proto.nexus_service.GetCustomerNotesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nexus_service.GetCustomerNotesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nexus_service.GetCustomerNotesRequest}
 */
proto.nexus_service.GetCustomerNotesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nexus_service.GetCustomerNotesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.nexus_service.GetCustomerNotesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nexus_service.GetCustomerNotesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.GetCustomerNotesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.nexus_service.GetCustomerNotesRequest.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.nexus_service.GetCustomerNotesRequest} returns this
 */
proto.nexus_service.GetCustomerNotesRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.nexus_service.GetCustomerNotesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.nexus_service.GetCustomerNotesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.nexus_service.GetCustomerNotesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.nexus_service.GetCustomerNotesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.GetCustomerNotesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    notesList: jspb.Message.toObjectList(msg.getNotesList(),
    proto.nexus_service.GetCustomerNotesResponse.Note.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nexus_service.GetCustomerNotesResponse}
 */
proto.nexus_service.GetCustomerNotesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.nexus_service.GetCustomerNotesResponse;
  return proto.nexus_service.GetCustomerNotesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nexus_service.GetCustomerNotesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nexus_service.GetCustomerNotesResponse}
 */
proto.nexus_service.GetCustomerNotesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.nexus_service.GetCustomerNotesResponse.Note;
      reader.readMessage(value,proto.nexus_service.GetCustomerNotesResponse.Note.deserializeBinaryFromReader);
      msg.addNotes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nexus_service.GetCustomerNotesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.nexus_service.GetCustomerNotesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nexus_service.GetCustomerNotesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.GetCustomerNotesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNotesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.nexus_service.GetCustomerNotesResponse.Note.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.nexus_service.GetCustomerNotesResponse.Note.prototype.toObject = function(opt_includeInstance) {
  return proto.nexus_service.GetCustomerNotesResponse.Note.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.nexus_service.GetCustomerNotesResponse.Note} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.GetCustomerNotesResponse.Note.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, ""),
    timestamp: jspb.Message.getFieldWithDefault(msg, 3, 0),
    authorEmail: jspb.Message.getFieldWithDefault(msg, 4, ""),
    noteText: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nexus_service.GetCustomerNotesResponse.Note}
 */
proto.nexus_service.GetCustomerNotesResponse.Note.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.nexus_service.GetCustomerNotesResponse.Note;
  return proto.nexus_service.GetCustomerNotesResponse.Note.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nexus_service.GetCustomerNotesResponse.Note} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nexus_service.GetCustomerNotesResponse.Note}
 */
proto.nexus_service.GetCustomerNotesResponse.Note.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestamp(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthorEmail(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setNoteText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nexus_service.GetCustomerNotesResponse.Note.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.nexus_service.GetCustomerNotesResponse.Note.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nexus_service.GetCustomerNotesResponse.Note} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.GetCustomerNotesResponse.Note.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getAuthorEmail();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getNoteText();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.nexus_service.GetCustomerNotesResponse.Note.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.nexus_service.GetCustomerNotesResponse.Note} returns this
 */
proto.nexus_service.GetCustomerNotesResponse.Note.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string id = 2;
 * @return {string}
 */
proto.nexus_service.GetCustomerNotesResponse.Note.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.nexus_service.GetCustomerNotesResponse.Note} returns this
 */
proto.nexus_service.GetCustomerNotesResponse.Note.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 timestamp = 3;
 * @return {number}
 */
proto.nexus_service.GetCustomerNotesResponse.Note.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.nexus_service.GetCustomerNotesResponse.Note} returns this
 */
proto.nexus_service.GetCustomerNotesResponse.Note.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string author_email = 4;
 * @return {string}
 */
proto.nexus_service.GetCustomerNotesResponse.Note.prototype.getAuthorEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.nexus_service.GetCustomerNotesResponse.Note} returns this
 */
proto.nexus_service.GetCustomerNotesResponse.Note.prototype.setAuthorEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string note_text = 5;
 * @return {string}
 */
proto.nexus_service.GetCustomerNotesResponse.Note.prototype.getNoteText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.nexus_service.GetCustomerNotesResponse.Note} returns this
 */
proto.nexus_service.GetCustomerNotesResponse.Note.prototype.setNoteText = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated Note notes = 1;
 * @return {!Array<!proto.nexus_service.GetCustomerNotesResponse.Note>}
 */
proto.nexus_service.GetCustomerNotesResponse.prototype.getNotesList = function() {
  return /** @type{!Array<!proto.nexus_service.GetCustomerNotesResponse.Note>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.nexus_service.GetCustomerNotesResponse.Note, 1));
};


/**
 * @param {!Array<!proto.nexus_service.GetCustomerNotesResponse.Note>} value
 * @return {!proto.nexus_service.GetCustomerNotesResponse} returns this
*/
proto.nexus_service.GetCustomerNotesResponse.prototype.setNotesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.nexus_service.GetCustomerNotesResponse.Note=} opt_value
 * @param {number=} opt_index
 * @return {!proto.nexus_service.GetCustomerNotesResponse.Note}
 */
proto.nexus_service.GetCustomerNotesResponse.prototype.addNotes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.nexus_service.GetCustomerNotesResponse.Note, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.nexus_service.GetCustomerNotesResponse} returns this
 */
proto.nexus_service.GetCustomerNotesResponse.prototype.clearNotesList = function() {
  return this.setNotesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.nexus_service.Customer.prototype.toObject = function(opt_includeInstance) {
  return proto.nexus_service.Customer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.nexus_service.Customer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.Customer.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    accountNumber: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nexus_service.Customer}
 */
proto.nexus_service.Customer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.nexus_service.Customer;
  return proto.nexus_service.Customer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nexus_service.Customer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nexus_service.Customer}
 */
proto.nexus_service.Customer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nexus_service.Customer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.nexus_service.Customer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nexus_service.Customer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.Customer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAccountNumber();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.nexus_service.Customer.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.nexus_service.Customer} returns this
 */
proto.nexus_service.Customer.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.nexus_service.Customer.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.nexus_service.Customer} returns this
 */
proto.nexus_service.Customer.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string account_number = 3;
 * @return {string}
 */
proto.nexus_service.Customer.prototype.getAccountNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.nexus_service.Customer} returns this
 */
proto.nexus_service.Customer.prototype.setAccountNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.nexus_service.Vehicle.prototype.toObject = function(opt_includeInstance) {
  return proto.nexus_service.Vehicle.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.nexus_service.Vehicle} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.Vehicle.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    vin: jspb.Message.getFieldWithDefault(msg, 2, ""),
    make: jspb.Message.getFieldWithDefault(msg, 3, ""),
    model: jspb.Message.getFieldWithDefault(msg, 4, ""),
    year: jspb.Message.getFieldWithDefault(msg, 5, ""),
    color: jspb.Message.getFieldWithDefault(msg, 6, ""),
    odometerReading: jspb.Message.getFieldWithDefault(msg, 7, 0),
    odometerUpdatedAt: jspb.Message.getFieldWithDefault(msg, 8, 0),
    status: jspb.Message.getFieldWithDefault(msg, 9, ""),
    locationLat: jspb.Message.getFieldWithDefault(msg, 10, ""),
    locationLng: jspb.Message.getFieldWithDefault(msg, 11, ""),
    locationUpdatedAt: jspb.Message.getFieldWithDefault(msg, 12, 0),
    address: (f = msg.getAddress()) && domain_pb.Address.toObject(includeInstance, f),
    trim: jspb.Message.getFieldWithDefault(msg, 14, ""),
    mediaUrl: jspb.Message.getFieldWithDefault(msg, 15, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nexus_service.Vehicle}
 */
proto.nexus_service.Vehicle.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.nexus_service.Vehicle;
  return proto.nexus_service.Vehicle.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nexus_service.Vehicle} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nexus_service.Vehicle}
 */
proto.nexus_service.Vehicle.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVin(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMake(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setModel(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setYear(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setColor(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOdometerReading(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOdometerUpdatedAt(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocationLat(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocationLng(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLocationUpdatedAt(value);
      break;
    case 13:
      var value = new domain_pb.Address;
      reader.readMessage(value,domain_pb.Address.deserializeBinaryFromReader);
      msg.setAddress(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrim(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setMediaUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nexus_service.Vehicle.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.nexus_service.Vehicle.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nexus_service.Vehicle} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.Vehicle.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVin();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMake();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getModel();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getYear();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getColor();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getOdometerReading();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getOdometerUpdatedAt();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getLocationLat();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getLocationLng();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getLocationUpdatedAt();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getAddress();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      domain_pb.Address.serializeBinaryToWriter
    );
  }
  f = message.getTrim();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getMediaUrl();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.nexus_service.Vehicle.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.nexus_service.Vehicle} returns this
 */
proto.nexus_service.Vehicle.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string vin = 2;
 * @return {string}
 */
proto.nexus_service.Vehicle.prototype.getVin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.nexus_service.Vehicle} returns this
 */
proto.nexus_service.Vehicle.prototype.setVin = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string make = 3;
 * @return {string}
 */
proto.nexus_service.Vehicle.prototype.getMake = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.nexus_service.Vehicle} returns this
 */
proto.nexus_service.Vehicle.prototype.setMake = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string model = 4;
 * @return {string}
 */
proto.nexus_service.Vehicle.prototype.getModel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.nexus_service.Vehicle} returns this
 */
proto.nexus_service.Vehicle.prototype.setModel = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string year = 5;
 * @return {string}
 */
proto.nexus_service.Vehicle.prototype.getYear = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.nexus_service.Vehicle} returns this
 */
proto.nexus_service.Vehicle.prototype.setYear = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string color = 6;
 * @return {string}
 */
proto.nexus_service.Vehicle.prototype.getColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.nexus_service.Vehicle} returns this
 */
proto.nexus_service.Vehicle.prototype.setColor = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int32 odometer_reading = 7;
 * @return {number}
 */
proto.nexus_service.Vehicle.prototype.getOdometerReading = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.nexus_service.Vehicle} returns this
 */
proto.nexus_service.Vehicle.prototype.setOdometerReading = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 odometer_updated_at = 8;
 * @return {number}
 */
proto.nexus_service.Vehicle.prototype.getOdometerUpdatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.nexus_service.Vehicle} returns this
 */
proto.nexus_service.Vehicle.prototype.setOdometerUpdatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string status = 9;
 * @return {string}
 */
proto.nexus_service.Vehicle.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.nexus_service.Vehicle} returns this
 */
proto.nexus_service.Vehicle.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string location_lat = 10;
 * @return {string}
 */
proto.nexus_service.Vehicle.prototype.getLocationLat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.nexus_service.Vehicle} returns this
 */
proto.nexus_service.Vehicle.prototype.setLocationLat = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string location_lng = 11;
 * @return {string}
 */
proto.nexus_service.Vehicle.prototype.getLocationLng = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.nexus_service.Vehicle} returns this
 */
proto.nexus_service.Vehicle.prototype.setLocationLng = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional int64 location_updated_at = 12;
 * @return {number}
 */
proto.nexus_service.Vehicle.prototype.getLocationUpdatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.nexus_service.Vehicle} returns this
 */
proto.nexus_service.Vehicle.prototype.setLocationUpdatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional domain.Address address = 13;
 * @return {?proto.domain.Address}
 */
proto.nexus_service.Vehicle.prototype.getAddress = function() {
  return /** @type{?proto.domain.Address} */ (
    jspb.Message.getWrapperField(this, domain_pb.Address, 13));
};


/**
 * @param {?proto.domain.Address|undefined} value
 * @return {!proto.nexus_service.Vehicle} returns this
*/
proto.nexus_service.Vehicle.prototype.setAddress = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.nexus_service.Vehicle} returns this
 */
proto.nexus_service.Vehicle.prototype.clearAddress = function() {
  return this.setAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.nexus_service.Vehicle.prototype.hasAddress = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional string trim = 14;
 * @return {string}
 */
proto.nexus_service.Vehicle.prototype.getTrim = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.nexus_service.Vehicle} returns this
 */
proto.nexus_service.Vehicle.prototype.setTrim = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string media_url = 15;
 * @return {string}
 */
proto.nexus_service.Vehicle.prototype.getMediaUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.nexus_service.Vehicle} returns this
 */
proto.nexus_service.Vehicle.prototype.setMediaUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.nexus_service.Subscription.prototype.toObject = function(opt_includeInstance) {
  return proto.nexus_service.Subscription.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.nexus_service.Subscription} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.Subscription.toObject = function(includeInstance, msg) {
  var f, obj = {
    createdAt: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nexus_service.Subscription}
 */
proto.nexus_service.Subscription.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.nexus_service.Subscription;
  return proto.nexus_service.Subscription.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nexus_service.Subscription} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nexus_service.Subscription}
 */
proto.nexus_service.Subscription.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nexus_service.Subscription.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.nexus_service.Subscription.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nexus_service.Subscription} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.Subscription.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 created_at = 1;
 * @return {number}
 */
proto.nexus_service.Subscription.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.nexus_service.Subscription} returns this
 */
proto.nexus_service.Subscription.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.nexus_service.CustomerItem.prototype.toObject = function(opt_includeInstance) {
  return proto.nexus_service.CustomerItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.nexus_service.CustomerItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.CustomerItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    reservationId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    vehicleId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    programId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    programName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    accountNumber: jspb.Message.getFieldWithDefault(msg, 7, ""),
    customerName: jspb.Message.getFieldWithDefault(msg, 8, ""),
    dateCreated: jspb.Message.getFieldWithDefault(msg, 9, ""),
    model: jspb.Message.getFieldWithDefault(msg, 10, ""),
    trim: jspb.Message.getFieldWithDefault(msg, 11, ""),
    accountStatus: jspb.Message.getFieldWithDefault(msg, 12, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nexus_service.CustomerItem}
 */
proto.nexus_service.CustomerItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.nexus_service.CustomerItem;
  return proto.nexus_service.CustomerItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nexus_service.CustomerItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nexus_service.CustomerItem}
 */
proto.nexus_service.CustomerItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setReservationId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setProgramId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setProgramName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNumber(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerName(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateCreated(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setModel(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrim(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nexus_service.CustomerItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.nexus_service.CustomerItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nexus_service.CustomerItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.CustomerItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getReservationId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getProgramId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getProgramName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAccountNumber();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCustomerName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getDateCreated();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getModel();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getTrim();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getAccountStatus();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.nexus_service.CustomerItem.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.nexus_service.CustomerItem} returns this
 */
proto.nexus_service.CustomerItem.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string reservation_id = 2;
 * @return {string}
 */
proto.nexus_service.CustomerItem.prototype.getReservationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.nexus_service.CustomerItem} returns this
 */
proto.nexus_service.CustomerItem.prototype.setReservationId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string subscription_id = 3;
 * @return {string}
 */
proto.nexus_service.CustomerItem.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.nexus_service.CustomerItem} returns this
 */
proto.nexus_service.CustomerItem.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string vehicle_id = 4;
 * @return {string}
 */
proto.nexus_service.CustomerItem.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.nexus_service.CustomerItem} returns this
 */
proto.nexus_service.CustomerItem.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string program_id = 5;
 * @return {string}
 */
proto.nexus_service.CustomerItem.prototype.getProgramId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.nexus_service.CustomerItem} returns this
 */
proto.nexus_service.CustomerItem.prototype.setProgramId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string program_name = 6;
 * @return {string}
 */
proto.nexus_service.CustomerItem.prototype.getProgramName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.nexus_service.CustomerItem} returns this
 */
proto.nexus_service.CustomerItem.prototype.setProgramName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string account_number = 7;
 * @return {string}
 */
proto.nexus_service.CustomerItem.prototype.getAccountNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.nexus_service.CustomerItem} returns this
 */
proto.nexus_service.CustomerItem.prototype.setAccountNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string customer_name = 8;
 * @return {string}
 */
proto.nexus_service.CustomerItem.prototype.getCustomerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.nexus_service.CustomerItem} returns this
 */
proto.nexus_service.CustomerItem.prototype.setCustomerName = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string date_created = 9;
 * @return {string}
 */
proto.nexus_service.CustomerItem.prototype.getDateCreated = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.nexus_service.CustomerItem} returns this
 */
proto.nexus_service.CustomerItem.prototype.setDateCreated = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string model = 10;
 * @return {string}
 */
proto.nexus_service.CustomerItem.prototype.getModel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.nexus_service.CustomerItem} returns this
 */
proto.nexus_service.CustomerItem.prototype.setModel = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string trim = 11;
 * @return {string}
 */
proto.nexus_service.CustomerItem.prototype.getTrim = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.nexus_service.CustomerItem} returns this
 */
proto.nexus_service.CustomerItem.prototype.setTrim = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string account_status = 12;
 * @return {string}
 */
proto.nexus_service.CustomerItem.prototype.getAccountStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.nexus_service.CustomerItem} returns this
 */
proto.nexus_service.CustomerItem.prototype.setAccountStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.nexus_service.GetCustomerRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.nexus_service.GetCustomerRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.nexus_service.GetCustomerRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.GetCustomerRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nexus_service.GetCustomerRequest}
 */
proto.nexus_service.GetCustomerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.nexus_service.GetCustomerRequest;
  return proto.nexus_service.GetCustomerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nexus_service.GetCustomerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nexus_service.GetCustomerRequest}
 */
proto.nexus_service.GetCustomerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nexus_service.GetCustomerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.nexus_service.GetCustomerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nexus_service.GetCustomerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.GetCustomerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.nexus_service.GetCustomerRequest.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.nexus_service.GetCustomerRequest} returns this
 */
proto.nexus_service.GetCustomerRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.nexus_service.GetCustomerResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.nexus_service.GetCustomerResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.nexus_service.GetCustomerResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.GetCustomerResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    customer: (f = msg.getCustomer()) && domain_pb.Customer.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nexus_service.GetCustomerResponse}
 */
proto.nexus_service.GetCustomerResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.nexus_service.GetCustomerResponse;
  return proto.nexus_service.GetCustomerResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nexus_service.GetCustomerResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nexus_service.GetCustomerResponse}
 */
proto.nexus_service.GetCustomerResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new domain_pb.Customer;
      reader.readMessage(value,domain_pb.Customer.deserializeBinaryFromReader);
      msg.setCustomer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nexus_service.GetCustomerResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.nexus_service.GetCustomerResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nexus_service.GetCustomerResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.GetCustomerResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomer();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      domain_pb.Customer.serializeBinaryToWriter
    );
  }
};


/**
 * optional domain.Customer customer = 1;
 * @return {?proto.domain.Customer}
 */
proto.nexus_service.GetCustomerResponse.prototype.getCustomer = function() {
  return /** @type{?proto.domain.Customer} */ (
    jspb.Message.getWrapperField(this, domain_pb.Customer, 1));
};


/**
 * @param {?proto.domain.Customer|undefined} value
 * @return {!proto.nexus_service.GetCustomerResponse} returns this
*/
proto.nexus_service.GetCustomerResponse.prototype.setCustomer = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.nexus_service.GetCustomerResponse} returns this
 */
proto.nexus_service.GetCustomerResponse.prototype.clearCustomer = function() {
  return this.setCustomer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.nexus_service.GetCustomerResponse.prototype.hasCustomer = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.nexus_service.GetCustomerListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.nexus_service.GetCustomerListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.nexus_service.GetCustomerListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.GetCustomerListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    programId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    listType: jspb.Message.getFieldWithDefault(msg, 2, ""),
    filterColumn: jspb.Message.getFieldWithDefault(msg, 3, ""),
    filterValue: jspb.Message.getFieldWithDefault(msg, 4, ""),
    sortColumn: jspb.Message.getFieldWithDefault(msg, 5, ""),
    sortDirection: jspb.Message.getFieldWithDefault(msg, 6, 0),
    offset: jspb.Message.getFieldWithDefault(msg, 7, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nexus_service.GetCustomerListRequest}
 */
proto.nexus_service.GetCustomerListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.nexus_service.GetCustomerListRequest;
  return proto.nexus_service.GetCustomerListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nexus_service.GetCustomerListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nexus_service.GetCustomerListRequest}
 */
proto.nexus_service.GetCustomerListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProgramId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setListType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFilterColumn(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFilterValue(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSortColumn(value);
      break;
    case 6:
      var value = /** @type {!proto.nexus_service.GetCustomerListRequest.SortDirection} */ (reader.readEnum());
      msg.setSortDirection(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setOffset(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLimit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nexus_service.GetCustomerListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.nexus_service.GetCustomerListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nexus_service.GetCustomerListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.GetCustomerListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProgramId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getListType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFilterColumn();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFilterValue();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSortColumn();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSortDirection();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getOffset();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.nexus_service.GetCustomerListRequest.ListType = {
  RESERVATIONS: 0,
  ACTIVE: 1
};

/**
 * @enum {number}
 */
proto.nexus_service.GetCustomerListRequest.SortDirection = {
  ASC: 0,
  DESC: 1
};

/**
 * optional string program_id = 1;
 * @return {string}
 */
proto.nexus_service.GetCustomerListRequest.prototype.getProgramId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.nexus_service.GetCustomerListRequest} returns this
 */
proto.nexus_service.GetCustomerListRequest.prototype.setProgramId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string list_type = 2;
 * @return {string}
 */
proto.nexus_service.GetCustomerListRequest.prototype.getListType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.nexus_service.GetCustomerListRequest} returns this
 */
proto.nexus_service.GetCustomerListRequest.prototype.setListType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string filter_column = 3;
 * @return {string}
 */
proto.nexus_service.GetCustomerListRequest.prototype.getFilterColumn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.nexus_service.GetCustomerListRequest} returns this
 */
proto.nexus_service.GetCustomerListRequest.prototype.setFilterColumn = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string filter_value = 4;
 * @return {string}
 */
proto.nexus_service.GetCustomerListRequest.prototype.getFilterValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.nexus_service.GetCustomerListRequest} returns this
 */
proto.nexus_service.GetCustomerListRequest.prototype.setFilterValue = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string sort_column = 5;
 * @return {string}
 */
proto.nexus_service.GetCustomerListRequest.prototype.getSortColumn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.nexus_service.GetCustomerListRequest} returns this
 */
proto.nexus_service.GetCustomerListRequest.prototype.setSortColumn = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional SortDirection sort_direction = 6;
 * @return {!proto.nexus_service.GetCustomerListRequest.SortDirection}
 */
proto.nexus_service.GetCustomerListRequest.prototype.getSortDirection = function() {
  return /** @type {!proto.nexus_service.GetCustomerListRequest.SortDirection} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.nexus_service.GetCustomerListRequest.SortDirection} value
 * @return {!proto.nexus_service.GetCustomerListRequest} returns this
 */
proto.nexus_service.GetCustomerListRequest.prototype.setSortDirection = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional uint32 offset = 7;
 * @return {number}
 */
proto.nexus_service.GetCustomerListRequest.prototype.getOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.nexus_service.GetCustomerListRequest} returns this
 */
proto.nexus_service.GetCustomerListRequest.prototype.setOffset = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional uint32 limit = 8;
 * @return {number}
 */
proto.nexus_service.GetCustomerListRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.nexus_service.GetCustomerListRequest} returns this
 */
proto.nexus_service.GetCustomerListRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.nexus_service.GetCustomerListResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.nexus_service.GetCustomerListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.nexus_service.GetCustomerListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.nexus_service.GetCustomerListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.GetCustomerListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    customersList: jspb.Message.toObjectList(msg.getCustomersList(),
    proto.nexus_service.CustomerItem.toObject, includeInstance),
    total: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nexus_service.GetCustomerListResponse}
 */
proto.nexus_service.GetCustomerListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.nexus_service.GetCustomerListResponse;
  return proto.nexus_service.GetCustomerListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nexus_service.GetCustomerListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nexus_service.GetCustomerListResponse}
 */
proto.nexus_service.GetCustomerListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.nexus_service.CustomerItem;
      reader.readMessage(value,proto.nexus_service.CustomerItem.deserializeBinaryFromReader);
      msg.addCustomers(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nexus_service.GetCustomerListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.nexus_service.GetCustomerListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nexus_service.GetCustomerListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.GetCustomerListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.nexus_service.CustomerItem.serializeBinaryToWriter
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * repeated CustomerItem customers = 1;
 * @return {!Array<!proto.nexus_service.CustomerItem>}
 */
proto.nexus_service.GetCustomerListResponse.prototype.getCustomersList = function() {
  return /** @type{!Array<!proto.nexus_service.CustomerItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.nexus_service.CustomerItem, 1));
};


/**
 * @param {!Array<!proto.nexus_service.CustomerItem>} value
 * @return {!proto.nexus_service.GetCustomerListResponse} returns this
*/
proto.nexus_service.GetCustomerListResponse.prototype.setCustomersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.nexus_service.CustomerItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.nexus_service.CustomerItem}
 */
proto.nexus_service.GetCustomerListResponse.prototype.addCustomers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.nexus_service.CustomerItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.nexus_service.GetCustomerListResponse} returns this
 */
proto.nexus_service.GetCustomerListResponse.prototype.clearCustomersList = function() {
  return this.setCustomersList([]);
};


/**
 * optional uint32 total = 2;
 * @return {number}
 */
proto.nexus_service.GetCustomerListResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.nexus_service.GetCustomerListResponse} returns this
 */
proto.nexus_service.GetCustomerListResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.nexus_service.GetScheduledDateForStatementRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.nexus_service.GetScheduledDateForStatementRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.nexus_service.GetScheduledDateForStatementRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.GetScheduledDateForStatementRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    statementId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nexus_service.GetScheduledDateForStatementRequest}
 */
proto.nexus_service.GetScheduledDateForStatementRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.nexus_service.GetScheduledDateForStatementRequest;
  return proto.nexus_service.GetScheduledDateForStatementRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nexus_service.GetScheduledDateForStatementRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nexus_service.GetScheduledDateForStatementRequest}
 */
proto.nexus_service.GetScheduledDateForStatementRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatementId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nexus_service.GetScheduledDateForStatementRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.nexus_service.GetScheduledDateForStatementRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nexus_service.GetScheduledDateForStatementRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.GetScheduledDateForStatementRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStatementId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.nexus_service.GetScheduledDateForStatementRequest.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.nexus_service.GetScheduledDateForStatementRequest} returns this
 */
proto.nexus_service.GetScheduledDateForStatementRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string subscription_id = 2;
 * @return {string}
 */
proto.nexus_service.GetScheduledDateForStatementRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.nexus_service.GetScheduledDateForStatementRequest} returns this
 */
proto.nexus_service.GetScheduledDateForStatementRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string statement_id = 3;
 * @return {string}
 */
proto.nexus_service.GetScheduledDateForStatementRequest.prototype.getStatementId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.nexus_service.GetScheduledDateForStatementRequest} returns this
 */
proto.nexus_service.GetScheduledDateForStatementRequest.prototype.setStatementId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.nexus_service.GetScheduledDateForStatementResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.nexus_service.GetScheduledDateForStatementResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.nexus_service.GetScheduledDateForStatementResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.GetScheduledDateForStatementResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    scheduledDate: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nexus_service.GetScheduledDateForStatementResponse}
 */
proto.nexus_service.GetScheduledDateForStatementResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.nexus_service.GetScheduledDateForStatementResponse;
  return proto.nexus_service.GetScheduledDateForStatementResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nexus_service.GetScheduledDateForStatementResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nexus_service.GetScheduledDateForStatementResponse}
 */
proto.nexus_service.GetScheduledDateForStatementResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setScheduledDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nexus_service.GetScheduledDateForStatementResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.nexus_service.GetScheduledDateForStatementResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nexus_service.GetScheduledDateForStatementResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.GetScheduledDateForStatementResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScheduledDate();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 scheduled_date = 1;
 * @return {number}
 */
proto.nexus_service.GetScheduledDateForStatementResponse.prototype.getScheduledDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.nexus_service.GetScheduledDateForStatementResponse} returns this
 */
proto.nexus_service.GetScheduledDateForStatementResponse.prototype.setScheduledDate = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.nexus_service.SubscriptionPickupDateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.nexus_service.SubscriptionPickupDateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.nexus_service.SubscriptionPickupDateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.SubscriptionPickupDateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    pickupDateUnix: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nexus_service.SubscriptionPickupDateRequest}
 */
proto.nexus_service.SubscriptionPickupDateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.nexus_service.SubscriptionPickupDateRequest;
  return proto.nexus_service.SubscriptionPickupDateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nexus_service.SubscriptionPickupDateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nexus_service.SubscriptionPickupDateRequest}
 */
proto.nexus_service.SubscriptionPickupDateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPickupDateUnix(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nexus_service.SubscriptionPickupDateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.nexus_service.SubscriptionPickupDateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nexus_service.SubscriptionPickupDateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.SubscriptionPickupDateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPickupDateUnix();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.nexus_service.SubscriptionPickupDateRequest.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.nexus_service.SubscriptionPickupDateRequest} returns this
 */
proto.nexus_service.SubscriptionPickupDateRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string subscription_id = 2;
 * @return {string}
 */
proto.nexus_service.SubscriptionPickupDateRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.nexus_service.SubscriptionPickupDateRequest} returns this
 */
proto.nexus_service.SubscriptionPickupDateRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 pickup_date_unix = 3;
 * @return {number}
 */
proto.nexus_service.SubscriptionPickupDateRequest.prototype.getPickupDateUnix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.nexus_service.SubscriptionPickupDateRequest} returns this
 */
proto.nexus_service.SubscriptionPickupDateRequest.prototype.setPickupDateUnix = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.nexus_service.SubscriptionPickupDateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.nexus_service.SubscriptionPickupDateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.nexus_service.SubscriptionPickupDateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.SubscriptionPickupDateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nexus_service.SubscriptionPickupDateResponse}
 */
proto.nexus_service.SubscriptionPickupDateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.nexus_service.SubscriptionPickupDateResponse;
  return proto.nexus_service.SubscriptionPickupDateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nexus_service.SubscriptionPickupDateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nexus_service.SubscriptionPickupDateResponse}
 */
proto.nexus_service.SubscriptionPickupDateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nexus_service.SubscriptionPickupDateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.nexus_service.SubscriptionPickupDateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nexus_service.SubscriptionPickupDateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.SubscriptionPickupDateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.nexus_service.CreateAppointmentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.nexus_service.CreateAppointmentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.nexus_service.CreateAppointmentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.CreateAppointmentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    employeeId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    customerId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    startTime: jspb.Message.getFieldWithDefault(msg, 4, 0),
    duration: jspb.Message.getFieldWithDefault(msg, 5, 0),
    appointmentType: jspb.Message.getFieldWithDefault(msg, 6, 0),
    timeZone: jspb.Message.getFieldWithDefault(msg, 7, ""),
    note: jspb.Message.getFieldWithDefault(msg, 8, ""),
    noteAuthor: jspb.Message.getFieldWithDefault(msg, 9, ""),
    reservationId: jspb.Message.getFieldWithDefault(msg, 10, ""),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 11, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nexus_service.CreateAppointmentRequest}
 */
proto.nexus_service.CreateAppointmentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.nexus_service.CreateAppointmentRequest;
  return proto.nexus_service.CreateAppointmentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nexus_service.CreateAppointmentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nexus_service.CreateAppointmentRequest}
 */
proto.nexus_service.CreateAppointmentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmployeeId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartTime(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDuration(value);
      break;
    case 6:
      var value = /** @type {!proto.appointment.AppointmentType} */ (reader.readEnum());
      msg.setAppointmentType(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimeZone(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setNoteAuthor(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setReservationId(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nexus_service.CreateAppointmentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.nexus_service.CreateAppointmentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nexus_service.CreateAppointmentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.CreateAppointmentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEmployeeId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStartTime();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getDuration();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getAppointmentType();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getTimeZone();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getNote();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getNoteAuthor();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getReservationId();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
};


/**
 * optional string dealer_id = 1;
 * @return {string}
 */
proto.nexus_service.CreateAppointmentRequest.prototype.getDealerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.nexus_service.CreateAppointmentRequest} returns this
 */
proto.nexus_service.CreateAppointmentRequest.prototype.setDealerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string employee_id = 2;
 * @return {string}
 */
proto.nexus_service.CreateAppointmentRequest.prototype.getEmployeeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.nexus_service.CreateAppointmentRequest} returns this
 */
proto.nexus_service.CreateAppointmentRequest.prototype.setEmployeeId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string customer_id = 3;
 * @return {string}
 */
proto.nexus_service.CreateAppointmentRequest.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.nexus_service.CreateAppointmentRequest} returns this
 */
proto.nexus_service.CreateAppointmentRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 start_time = 4;
 * @return {number}
 */
proto.nexus_service.CreateAppointmentRequest.prototype.getStartTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.nexus_service.CreateAppointmentRequest} returns this
 */
proto.nexus_service.CreateAppointmentRequest.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 duration = 5;
 * @return {number}
 */
proto.nexus_service.CreateAppointmentRequest.prototype.getDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.nexus_service.CreateAppointmentRequest} returns this
 */
proto.nexus_service.CreateAppointmentRequest.prototype.setDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional appointment.AppointmentType appointment_type = 6;
 * @return {!proto.appointment.AppointmentType}
 */
proto.nexus_service.CreateAppointmentRequest.prototype.getAppointmentType = function() {
  return /** @type {!proto.appointment.AppointmentType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.appointment.AppointmentType} value
 * @return {!proto.nexus_service.CreateAppointmentRequest} returns this
 */
proto.nexus_service.CreateAppointmentRequest.prototype.setAppointmentType = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional string time_zone = 7;
 * @return {string}
 */
proto.nexus_service.CreateAppointmentRequest.prototype.getTimeZone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.nexus_service.CreateAppointmentRequest} returns this
 */
proto.nexus_service.CreateAppointmentRequest.prototype.setTimeZone = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string note = 8;
 * @return {string}
 */
proto.nexus_service.CreateAppointmentRequest.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.nexus_service.CreateAppointmentRequest} returns this
 */
proto.nexus_service.CreateAppointmentRequest.prototype.setNote = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string note_author = 9;
 * @return {string}
 */
proto.nexus_service.CreateAppointmentRequest.prototype.getNoteAuthor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.nexus_service.CreateAppointmentRequest} returns this
 */
proto.nexus_service.CreateAppointmentRequest.prototype.setNoteAuthor = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string reservation_id = 10;
 * @return {string}
 */
proto.nexus_service.CreateAppointmentRequest.prototype.getReservationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.nexus_service.CreateAppointmentRequest} returns this
 */
proto.nexus_service.CreateAppointmentRequest.prototype.setReservationId = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string subscription_id = 11;
 * @return {string}
 */
proto.nexus_service.CreateAppointmentRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.nexus_service.CreateAppointmentRequest} returns this
 */
proto.nexus_service.CreateAppointmentRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.nexus_service.CreateAppointmentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.nexus_service.CreateAppointmentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.nexus_service.CreateAppointmentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.CreateAppointmentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    appointmentId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    errorMessage: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nexus_service.CreateAppointmentResponse}
 */
proto.nexus_service.CreateAppointmentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.nexus_service.CreateAppointmentResponse;
  return proto.nexus_service.CreateAppointmentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nexus_service.CreateAppointmentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nexus_service.CreateAppointmentResponse}
 */
proto.nexus_service.CreateAppointmentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppointmentId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nexus_service.CreateAppointmentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.nexus_service.CreateAppointmentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nexus_service.CreateAppointmentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.CreateAppointmentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppointmentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getErrorMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string appointment_id = 1;
 * @return {string}
 */
proto.nexus_service.CreateAppointmentResponse.prototype.getAppointmentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.nexus_service.CreateAppointmentResponse} returns this
 */
proto.nexus_service.CreateAppointmentResponse.prototype.setAppointmentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string error_message = 2;
 * @return {string}
 */
proto.nexus_service.CreateAppointmentResponse.prototype.getErrorMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.nexus_service.CreateAppointmentResponse} returns this
 */
proto.nexus_service.CreateAppointmentResponse.prototype.setErrorMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.nexus_service.CancelAppointmentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.nexus_service.CancelAppointmentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.nexus_service.CancelAppointmentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.CancelAppointmentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    appointmentId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nexus_service.CancelAppointmentRequest}
 */
proto.nexus_service.CancelAppointmentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.nexus_service.CancelAppointmentRequest;
  return proto.nexus_service.CancelAppointmentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nexus_service.CancelAppointmentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nexus_service.CancelAppointmentRequest}
 */
proto.nexus_service.CancelAppointmentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppointmentId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nexus_service.CancelAppointmentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.nexus_service.CancelAppointmentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nexus_service.CancelAppointmentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.CancelAppointmentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppointmentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string appointment_id = 1;
 * @return {string}
 */
proto.nexus_service.CancelAppointmentRequest.prototype.getAppointmentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.nexus_service.CancelAppointmentRequest} returns this
 */
proto.nexus_service.CancelAppointmentRequest.prototype.setAppointmentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.nexus_service.CancelAppointmentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.nexus_service.CancelAppointmentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.nexus_service.CancelAppointmentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.CancelAppointmentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nexus_service.CancelAppointmentResponse}
 */
proto.nexus_service.CancelAppointmentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.nexus_service.CancelAppointmentResponse;
  return proto.nexus_service.CancelAppointmentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nexus_service.CancelAppointmentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nexus_service.CancelAppointmentResponse}
 */
proto.nexus_service.CancelAppointmentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nexus_service.CancelAppointmentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.nexus_service.CancelAppointmentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nexus_service.CancelAppointmentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.CancelAppointmentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.nexus_service.ListAppointmentsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.nexus_service.ListAppointmentsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.nexus_service.ListAppointmentsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.ListAppointmentsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealershipId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    daysOut: jspb.Message.getFieldWithDefault(msg, 2, 0),
    accountNumber: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nexus_service.ListAppointmentsRequest}
 */
proto.nexus_service.ListAppointmentsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.nexus_service.ListAppointmentsRequest;
  return proto.nexus_service.ListAppointmentsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nexus_service.ListAppointmentsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nexus_service.ListAppointmentsRequest}
 */
proto.nexus_service.ListAppointmentsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealershipId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDaysOut(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nexus_service.ListAppointmentsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.nexus_service.ListAppointmentsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nexus_service.ListAppointmentsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.ListAppointmentsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealershipId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDaysOut();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getAccountNumber();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string dealership_id = 1;
 * @return {string}
 */
proto.nexus_service.ListAppointmentsRequest.prototype.getDealershipId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.nexus_service.ListAppointmentsRequest} returns this
 */
proto.nexus_service.ListAppointmentsRequest.prototype.setDealershipId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 days_out = 2;
 * @return {number}
 */
proto.nexus_service.ListAppointmentsRequest.prototype.getDaysOut = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.nexus_service.ListAppointmentsRequest} returns this
 */
proto.nexus_service.ListAppointmentsRequest.prototype.setDaysOut = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string account_number = 3;
 * @return {string}
 */
proto.nexus_service.ListAppointmentsRequest.prototype.getAccountNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.nexus_service.ListAppointmentsRequest} returns this
 */
proto.nexus_service.ListAppointmentsRequest.prototype.setAccountNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.nexus_service.AppointmentDay.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.nexus_service.AppointmentDay.prototype.toObject = function(opt_includeInstance) {
  return proto.nexus_service.AppointmentDay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.nexus_service.AppointmentDay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.AppointmentDay.toObject = function(includeInstance, msg) {
  var f, obj = {
    day: jspb.Message.getFieldWithDefault(msg, 1, ""),
    appointmentsList: jspb.Message.toObjectList(msg.getAppointmentsList(),
    appointment_pb.Appointment.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nexus_service.AppointmentDay}
 */
proto.nexus_service.AppointmentDay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.nexus_service.AppointmentDay;
  return proto.nexus_service.AppointmentDay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nexus_service.AppointmentDay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nexus_service.AppointmentDay}
 */
proto.nexus_service.AppointmentDay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDay(value);
      break;
    case 2:
      var value = new appointment_pb.Appointment;
      reader.readMessage(value,appointment_pb.Appointment.deserializeBinaryFromReader);
      msg.addAppointments(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nexus_service.AppointmentDay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.nexus_service.AppointmentDay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nexus_service.AppointmentDay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.AppointmentDay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDay();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAppointmentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      appointment_pb.Appointment.serializeBinaryToWriter
    );
  }
};


/**
 * optional string day = 1;
 * @return {string}
 */
proto.nexus_service.AppointmentDay.prototype.getDay = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.nexus_service.AppointmentDay} returns this
 */
proto.nexus_service.AppointmentDay.prototype.setDay = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated appointment.Appointment appointments = 2;
 * @return {!Array<!proto.appointment.Appointment>}
 */
proto.nexus_service.AppointmentDay.prototype.getAppointmentsList = function() {
  return /** @type{!Array<!proto.appointment.Appointment>} */ (
    jspb.Message.getRepeatedWrapperField(this, appointment_pb.Appointment, 2));
};


/**
 * @param {!Array<!proto.appointment.Appointment>} value
 * @return {!proto.nexus_service.AppointmentDay} returns this
*/
proto.nexus_service.AppointmentDay.prototype.setAppointmentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.appointment.Appointment=} opt_value
 * @param {number=} opt_index
 * @return {!proto.appointment.Appointment}
 */
proto.nexus_service.AppointmentDay.prototype.addAppointments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.appointment.Appointment, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.nexus_service.AppointmentDay} returns this
 */
proto.nexus_service.AppointmentDay.prototype.clearAppointmentsList = function() {
  return this.setAppointmentsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.nexus_service.ListAppointmentsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.nexus_service.ListAppointmentsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.nexus_service.ListAppointmentsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.nexus_service.ListAppointmentsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.ListAppointmentsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    appointmentDaysList: jspb.Message.toObjectList(msg.getAppointmentDaysList(),
    proto.nexus_service.AppointmentDay.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nexus_service.ListAppointmentsResponse}
 */
proto.nexus_service.ListAppointmentsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.nexus_service.ListAppointmentsResponse;
  return proto.nexus_service.ListAppointmentsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nexus_service.ListAppointmentsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nexus_service.ListAppointmentsResponse}
 */
proto.nexus_service.ListAppointmentsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.nexus_service.AppointmentDay;
      reader.readMessage(value,proto.nexus_service.AppointmentDay.deserializeBinaryFromReader);
      msg.addAppointmentDays(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nexus_service.ListAppointmentsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.nexus_service.ListAppointmentsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nexus_service.ListAppointmentsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.ListAppointmentsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppointmentDaysList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.nexus_service.AppointmentDay.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AppointmentDay appointment_days = 1;
 * @return {!Array<!proto.nexus_service.AppointmentDay>}
 */
proto.nexus_service.ListAppointmentsResponse.prototype.getAppointmentDaysList = function() {
  return /** @type{!Array<!proto.nexus_service.AppointmentDay>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.nexus_service.AppointmentDay, 1));
};


/**
 * @param {!Array<!proto.nexus_service.AppointmentDay>} value
 * @return {!proto.nexus_service.ListAppointmentsResponse} returns this
*/
proto.nexus_service.ListAppointmentsResponse.prototype.setAppointmentDaysList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.nexus_service.AppointmentDay=} opt_value
 * @param {number=} opt_index
 * @return {!proto.nexus_service.AppointmentDay}
 */
proto.nexus_service.ListAppointmentsResponse.prototype.addAppointmentDays = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.nexus_service.AppointmentDay, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.nexus_service.ListAppointmentsResponse} returns this
 */
proto.nexus_service.ListAppointmentsResponse.prototype.clearAppointmentDaysList = function() {
  return this.setAppointmentDaysList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.nexus_service.SetReservationProgramRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.nexus_service.SetReservationProgramRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.nexus_service.SetReservationProgramRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.SetReservationProgramRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    reservationId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    programId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    monthly: jspb.Message.getFieldWithDefault(msg, 5, 0),
    startFee: jspb.Message.getFieldWithDefault(msg, 6, 0),
    priceTermMonths: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nexus_service.SetReservationProgramRequest}
 */
proto.nexus_service.SetReservationProgramRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.nexus_service.SetReservationProgramRequest;
  return proto.nexus_service.SetReservationProgramRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nexus_service.SetReservationProgramRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nexus_service.SetReservationProgramRequest}
 */
proto.nexus_service.SetReservationProgramRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setReservationId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setProgramId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMonthly(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStartFee(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPriceTermMonths(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nexus_service.SetReservationProgramRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.nexus_service.SetReservationProgramRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nexus_service.SetReservationProgramRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.SetReservationProgramRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getReservationId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProgramId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getMonthly();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getStartFee();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getPriceTermMonths();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
};


/**
 * optional string customer_id = 2;
 * @return {string}
 */
proto.nexus_service.SetReservationProgramRequest.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.nexus_service.SetReservationProgramRequest} returns this
 */
proto.nexus_service.SetReservationProgramRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string reservation_id = 3;
 * @return {string}
 */
proto.nexus_service.SetReservationProgramRequest.prototype.getReservationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.nexus_service.SetReservationProgramRequest} returns this
 */
proto.nexus_service.SetReservationProgramRequest.prototype.setReservationId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string program_id = 4;
 * @return {string}
 */
proto.nexus_service.SetReservationProgramRequest.prototype.getProgramId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.nexus_service.SetReservationProgramRequest} returns this
 */
proto.nexus_service.SetReservationProgramRequest.prototype.setProgramId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 monthly = 5;
 * @return {number}
 */
proto.nexus_service.SetReservationProgramRequest.prototype.getMonthly = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.nexus_service.SetReservationProgramRequest} returns this
 */
proto.nexus_service.SetReservationProgramRequest.prototype.setMonthly = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 start_fee = 6;
 * @return {number}
 */
proto.nexus_service.SetReservationProgramRequest.prototype.getStartFee = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.nexus_service.SetReservationProgramRequest} returns this
 */
proto.nexus_service.SetReservationProgramRequest.prototype.setStartFee = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 price_term_months = 7;
 * @return {number}
 */
proto.nexus_service.SetReservationProgramRequest.prototype.getPriceTermMonths = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.nexus_service.SetReservationProgramRequest} returns this
 */
proto.nexus_service.SetReservationProgramRequest.prototype.setPriceTermMonths = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.nexus_service.SetReservationProgramResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.nexus_service.SetReservationProgramResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.nexus_service.SetReservationProgramResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.SetReservationProgramResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nexus_service.SetReservationProgramResponse}
 */
proto.nexus_service.SetReservationProgramResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.nexus_service.SetReservationProgramResponse;
  return proto.nexus_service.SetReservationProgramResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nexus_service.SetReservationProgramResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nexus_service.SetReservationProgramResponse}
 */
proto.nexus_service.SetReservationProgramResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nexus_service.SetReservationProgramResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.nexus_service.SetReservationProgramResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nexus_service.SetReservationProgramResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.SetReservationProgramResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.nexus_service.ListAppointmentTypeConfigsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.nexus_service.ListAppointmentTypeConfigsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.nexus_service.ListAppointmentTypeConfigsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.ListAppointmentTypeConfigsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nexus_service.ListAppointmentTypeConfigsRequest}
 */
proto.nexus_service.ListAppointmentTypeConfigsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.nexus_service.ListAppointmentTypeConfigsRequest;
  return proto.nexus_service.ListAppointmentTypeConfigsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nexus_service.ListAppointmentTypeConfigsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nexus_service.ListAppointmentTypeConfigsRequest}
 */
proto.nexus_service.ListAppointmentTypeConfigsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nexus_service.ListAppointmentTypeConfigsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.nexus_service.ListAppointmentTypeConfigsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nexus_service.ListAppointmentTypeConfigsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.ListAppointmentTypeConfigsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.nexus_service.ListAppointmentTypeConfigsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.nexus_service.ListAppointmentTypeConfigsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.nexus_service.ListAppointmentTypeConfigsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.nexus_service.ListAppointmentTypeConfigsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.ListAppointmentTypeConfigsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    appointmentTypeConfigsList: jspb.Message.toObjectList(msg.getAppointmentTypeConfigsList(),
    appointment_pb.AppointmentTypeConfig.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nexus_service.ListAppointmentTypeConfigsResponse}
 */
proto.nexus_service.ListAppointmentTypeConfigsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.nexus_service.ListAppointmentTypeConfigsResponse;
  return proto.nexus_service.ListAppointmentTypeConfigsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nexus_service.ListAppointmentTypeConfigsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nexus_service.ListAppointmentTypeConfigsResponse}
 */
proto.nexus_service.ListAppointmentTypeConfigsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new appointment_pb.AppointmentTypeConfig;
      reader.readMessage(value,appointment_pb.AppointmentTypeConfig.deserializeBinaryFromReader);
      msg.addAppointmentTypeConfigs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nexus_service.ListAppointmentTypeConfigsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.nexus_service.ListAppointmentTypeConfigsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nexus_service.ListAppointmentTypeConfigsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.ListAppointmentTypeConfigsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppointmentTypeConfigsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      appointment_pb.AppointmentTypeConfig.serializeBinaryToWriter
    );
  }
};


/**
 * repeated appointment.AppointmentTypeConfig appointment_type_configs = 1;
 * @return {!Array<!proto.appointment.AppointmentTypeConfig>}
 */
proto.nexus_service.ListAppointmentTypeConfigsResponse.prototype.getAppointmentTypeConfigsList = function() {
  return /** @type{!Array<!proto.appointment.AppointmentTypeConfig>} */ (
    jspb.Message.getRepeatedWrapperField(this, appointment_pb.AppointmentTypeConfig, 1));
};


/**
 * @param {!Array<!proto.appointment.AppointmentTypeConfig>} value
 * @return {!proto.nexus_service.ListAppointmentTypeConfigsResponse} returns this
*/
proto.nexus_service.ListAppointmentTypeConfigsResponse.prototype.setAppointmentTypeConfigsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.appointment.AppointmentTypeConfig=} opt_value
 * @param {number=} opt_index
 * @return {!proto.appointment.AppointmentTypeConfig}
 */
proto.nexus_service.ListAppointmentTypeConfigsResponse.prototype.addAppointmentTypeConfigs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.appointment.AppointmentTypeConfig, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.nexus_service.ListAppointmentTypeConfigsResponse} returns this
 */
proto.nexus_service.ListAppointmentTypeConfigsResponse.prototype.clearAppointmentTypeConfigsList = function() {
  return this.setAppointmentTypeConfigsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.nexus_service.SetAppointmentTypeConfigsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.nexus_service.SetAppointmentTypeConfigsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.nexus_service.SetAppointmentTypeConfigsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.nexus_service.SetAppointmentTypeConfigsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.SetAppointmentTypeConfigsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    appointmentTypeConfigsList: jspb.Message.toObjectList(msg.getAppointmentTypeConfigsList(),
    appointment_pb.AppointmentTypeConfig.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nexus_service.SetAppointmentTypeConfigsRequest}
 */
proto.nexus_service.SetAppointmentTypeConfigsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.nexus_service.SetAppointmentTypeConfigsRequest;
  return proto.nexus_service.SetAppointmentTypeConfigsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nexus_service.SetAppointmentTypeConfigsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nexus_service.SetAppointmentTypeConfigsRequest}
 */
proto.nexus_service.SetAppointmentTypeConfigsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new appointment_pb.AppointmentTypeConfig;
      reader.readMessage(value,appointment_pb.AppointmentTypeConfig.deserializeBinaryFromReader);
      msg.addAppointmentTypeConfigs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nexus_service.SetAppointmentTypeConfigsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.nexus_service.SetAppointmentTypeConfigsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nexus_service.SetAppointmentTypeConfigsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.SetAppointmentTypeConfigsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppointmentTypeConfigsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      appointment_pb.AppointmentTypeConfig.serializeBinaryToWriter
    );
  }
};


/**
 * repeated appointment.AppointmentTypeConfig appointment_type_configs = 1;
 * @return {!Array<!proto.appointment.AppointmentTypeConfig>}
 */
proto.nexus_service.SetAppointmentTypeConfigsRequest.prototype.getAppointmentTypeConfigsList = function() {
  return /** @type{!Array<!proto.appointment.AppointmentTypeConfig>} */ (
    jspb.Message.getRepeatedWrapperField(this, appointment_pb.AppointmentTypeConfig, 1));
};


/**
 * @param {!Array<!proto.appointment.AppointmentTypeConfig>} value
 * @return {!proto.nexus_service.SetAppointmentTypeConfigsRequest} returns this
*/
proto.nexus_service.SetAppointmentTypeConfigsRequest.prototype.setAppointmentTypeConfigsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.appointment.AppointmentTypeConfig=} opt_value
 * @param {number=} opt_index
 * @return {!proto.appointment.AppointmentTypeConfig}
 */
proto.nexus_service.SetAppointmentTypeConfigsRequest.prototype.addAppointmentTypeConfigs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.appointment.AppointmentTypeConfig, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.nexus_service.SetAppointmentTypeConfigsRequest} returns this
 */
proto.nexus_service.SetAppointmentTypeConfigsRequest.prototype.clearAppointmentTypeConfigsList = function() {
  return this.setAppointmentTypeConfigsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.nexus_service.SetAppointmentTypeConfigsResponse.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.nexus_service.SetAppointmentTypeConfigsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.nexus_service.SetAppointmentTypeConfigsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.nexus_service.SetAppointmentTypeConfigsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.SetAppointmentTypeConfigsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    updatedAppointmentTypeConfigsList: jspb.Message.toObjectList(msg.getUpdatedAppointmentTypeConfigsList(),
    appointment_pb.AppointmentTypeConfig.toObject, includeInstance),
    failedAppointmentTypesList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nexus_service.SetAppointmentTypeConfigsResponse}
 */
proto.nexus_service.SetAppointmentTypeConfigsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.nexus_service.SetAppointmentTypeConfigsResponse;
  return proto.nexus_service.SetAppointmentTypeConfigsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nexus_service.SetAppointmentTypeConfigsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nexus_service.SetAppointmentTypeConfigsResponse}
 */
proto.nexus_service.SetAppointmentTypeConfigsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new appointment_pb.AppointmentTypeConfig;
      reader.readMessage(value,appointment_pb.AppointmentTypeConfig.deserializeBinaryFromReader);
      msg.addUpdatedAppointmentTypeConfigs(value);
      break;
    case 2:
      var values = /** @type {!Array<!proto.appointment.AppointmentType>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addFailedAppointmentTypes(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nexus_service.SetAppointmentTypeConfigsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.nexus_service.SetAppointmentTypeConfigsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nexus_service.SetAppointmentTypeConfigsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.SetAppointmentTypeConfigsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdatedAppointmentTypeConfigsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      appointment_pb.AppointmentTypeConfig.serializeBinaryToWriter
    );
  }
  f = message.getFailedAppointmentTypesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      2,
      f
    );
  }
};


/**
 * repeated appointment.AppointmentTypeConfig updated_appointment_type_configs = 1;
 * @return {!Array<!proto.appointment.AppointmentTypeConfig>}
 */
proto.nexus_service.SetAppointmentTypeConfigsResponse.prototype.getUpdatedAppointmentTypeConfigsList = function() {
  return /** @type{!Array<!proto.appointment.AppointmentTypeConfig>} */ (
    jspb.Message.getRepeatedWrapperField(this, appointment_pb.AppointmentTypeConfig, 1));
};


/**
 * @param {!Array<!proto.appointment.AppointmentTypeConfig>} value
 * @return {!proto.nexus_service.SetAppointmentTypeConfigsResponse} returns this
*/
proto.nexus_service.SetAppointmentTypeConfigsResponse.prototype.setUpdatedAppointmentTypeConfigsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.appointment.AppointmentTypeConfig=} opt_value
 * @param {number=} opt_index
 * @return {!proto.appointment.AppointmentTypeConfig}
 */
proto.nexus_service.SetAppointmentTypeConfigsResponse.prototype.addUpdatedAppointmentTypeConfigs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.appointment.AppointmentTypeConfig, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.nexus_service.SetAppointmentTypeConfigsResponse} returns this
 */
proto.nexus_service.SetAppointmentTypeConfigsResponse.prototype.clearUpdatedAppointmentTypeConfigsList = function() {
  return this.setUpdatedAppointmentTypeConfigsList([]);
};


/**
 * repeated appointment.AppointmentType failed_appointment_types = 2;
 * @return {!Array<!proto.appointment.AppointmentType>}
 */
proto.nexus_service.SetAppointmentTypeConfigsResponse.prototype.getFailedAppointmentTypesList = function() {
  return /** @type {!Array<!proto.appointment.AppointmentType>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<!proto.appointment.AppointmentType>} value
 * @return {!proto.nexus_service.SetAppointmentTypeConfigsResponse} returns this
 */
proto.nexus_service.SetAppointmentTypeConfigsResponse.prototype.setFailedAppointmentTypesList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {!proto.appointment.AppointmentType} value
 * @param {number=} opt_index
 * @return {!proto.nexus_service.SetAppointmentTypeConfigsResponse} returns this
 */
proto.nexus_service.SetAppointmentTypeConfigsResponse.prototype.addFailedAppointmentTypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.nexus_service.SetAppointmentTypeConfigsResponse} returns this
 */
proto.nexus_service.SetAppointmentTypeConfigsResponse.prototype.clearFailedAppointmentTypesList = function() {
  return this.setFailedAppointmentTypesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.nexus_service.GetAppointmentCheckoutStatusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.nexus_service.GetAppointmentCheckoutStatusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.nexus_service.GetAppointmentCheckoutStatusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.GetAppointmentCheckoutStatusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    appointmentId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nexus_service.GetAppointmentCheckoutStatusRequest}
 */
proto.nexus_service.GetAppointmentCheckoutStatusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.nexus_service.GetAppointmentCheckoutStatusRequest;
  return proto.nexus_service.GetAppointmentCheckoutStatusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nexus_service.GetAppointmentCheckoutStatusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nexus_service.GetAppointmentCheckoutStatusRequest}
 */
proto.nexus_service.GetAppointmentCheckoutStatusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppointmentId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nexus_service.GetAppointmentCheckoutStatusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.nexus_service.GetAppointmentCheckoutStatusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nexus_service.GetAppointmentCheckoutStatusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.GetAppointmentCheckoutStatusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppointmentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string appointment_id = 1;
 * @return {string}
 */
proto.nexus_service.GetAppointmentCheckoutStatusRequest.prototype.getAppointmentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.nexus_service.GetAppointmentCheckoutStatusRequest} returns this
 */
proto.nexus_service.GetAppointmentCheckoutStatusRequest.prototype.setAppointmentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.nexus_service.GetAppointmentCheckoutStatusResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.nexus_service.GetAppointmentCheckoutStatusResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.nexus_service.GetAppointmentCheckoutStatusResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.GetAppointmentCheckoutStatusResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    appointmentCheckoutStatus: (f = msg.getAppointmentCheckoutStatus()) && appointment_pb.AppointmentCheckoutStatus.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nexus_service.GetAppointmentCheckoutStatusResponse}
 */
proto.nexus_service.GetAppointmentCheckoutStatusResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.nexus_service.GetAppointmentCheckoutStatusResponse;
  return proto.nexus_service.GetAppointmentCheckoutStatusResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nexus_service.GetAppointmentCheckoutStatusResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nexus_service.GetAppointmentCheckoutStatusResponse}
 */
proto.nexus_service.GetAppointmentCheckoutStatusResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new appointment_pb.AppointmentCheckoutStatus;
      reader.readMessage(value,appointment_pb.AppointmentCheckoutStatus.deserializeBinaryFromReader);
      msg.setAppointmentCheckoutStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nexus_service.GetAppointmentCheckoutStatusResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.nexus_service.GetAppointmentCheckoutStatusResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nexus_service.GetAppointmentCheckoutStatusResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.GetAppointmentCheckoutStatusResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppointmentCheckoutStatus();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      appointment_pb.AppointmentCheckoutStatus.serializeBinaryToWriter
    );
  }
};


/**
 * optional appointment.AppointmentCheckoutStatus appointment_checkout_status = 1;
 * @return {?proto.appointment.AppointmentCheckoutStatus}
 */
proto.nexus_service.GetAppointmentCheckoutStatusResponse.prototype.getAppointmentCheckoutStatus = function() {
  return /** @type{?proto.appointment.AppointmentCheckoutStatus} */ (
    jspb.Message.getWrapperField(this, appointment_pb.AppointmentCheckoutStatus, 1));
};


/**
 * @param {?proto.appointment.AppointmentCheckoutStatus|undefined} value
 * @return {!proto.nexus_service.GetAppointmentCheckoutStatusResponse} returns this
*/
proto.nexus_service.GetAppointmentCheckoutStatusResponse.prototype.setAppointmentCheckoutStatus = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.nexus_service.GetAppointmentCheckoutStatusResponse} returns this
 */
proto.nexus_service.GetAppointmentCheckoutStatusResponse.prototype.clearAppointmentCheckoutStatus = function() {
  return this.setAppointmentCheckoutStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.nexus_service.GetAppointmentCheckoutStatusResponse.prototype.hasAppointmentCheckoutStatus = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.nexus_service.AppointmentKeyHandOffRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.nexus_service.AppointmentKeyHandOffRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.nexus_service.AppointmentKeyHandOffRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.AppointmentKeyHandOffRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    appointmentKeyHandOff: (f = msg.getAppointmentKeyHandOff()) && appointment_pb.AppointmentKeyHandOff.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nexus_service.AppointmentKeyHandOffRequest}
 */
proto.nexus_service.AppointmentKeyHandOffRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.nexus_service.AppointmentKeyHandOffRequest;
  return proto.nexus_service.AppointmentKeyHandOffRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nexus_service.AppointmentKeyHandOffRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nexus_service.AppointmentKeyHandOffRequest}
 */
proto.nexus_service.AppointmentKeyHandOffRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new appointment_pb.AppointmentKeyHandOff;
      reader.readMessage(value,appointment_pb.AppointmentKeyHandOff.deserializeBinaryFromReader);
      msg.setAppointmentKeyHandOff(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nexus_service.AppointmentKeyHandOffRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.nexus_service.AppointmentKeyHandOffRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nexus_service.AppointmentKeyHandOffRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.AppointmentKeyHandOffRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppointmentKeyHandOff();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      appointment_pb.AppointmentKeyHandOff.serializeBinaryToWriter
    );
  }
};


/**
 * optional appointment.AppointmentKeyHandOff appointment_key_hand_off = 1;
 * @return {?proto.appointment.AppointmentKeyHandOff}
 */
proto.nexus_service.AppointmentKeyHandOffRequest.prototype.getAppointmentKeyHandOff = function() {
  return /** @type{?proto.appointment.AppointmentKeyHandOff} */ (
    jspb.Message.getWrapperField(this, appointment_pb.AppointmentKeyHandOff, 1));
};


/**
 * @param {?proto.appointment.AppointmentKeyHandOff|undefined} value
 * @return {!proto.nexus_service.AppointmentKeyHandOffRequest} returns this
*/
proto.nexus_service.AppointmentKeyHandOffRequest.prototype.setAppointmentKeyHandOff = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.nexus_service.AppointmentKeyHandOffRequest} returns this
 */
proto.nexus_service.AppointmentKeyHandOffRequest.prototype.clearAppointmentKeyHandOff = function() {
  return this.setAppointmentKeyHandOff(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.nexus_service.AppointmentKeyHandOffRequest.prototype.hasAppointmentKeyHandOff = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.nexus_service.AppointmentKeyHandOffResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.nexus_service.AppointmentKeyHandOffResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.nexus_service.AppointmentKeyHandOffResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.AppointmentKeyHandOffResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    appointmentId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nexus_service.AppointmentKeyHandOffResponse}
 */
proto.nexus_service.AppointmentKeyHandOffResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.nexus_service.AppointmentKeyHandOffResponse;
  return proto.nexus_service.AppointmentKeyHandOffResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nexus_service.AppointmentKeyHandOffResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nexus_service.AppointmentKeyHandOffResponse}
 */
proto.nexus_service.AppointmentKeyHandOffResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppointmentId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nexus_service.AppointmentKeyHandOffResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.nexus_service.AppointmentKeyHandOffResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nexus_service.AppointmentKeyHandOffResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.AppointmentKeyHandOffResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppointmentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string appointment_id = 1;
 * @return {string}
 */
proto.nexus_service.AppointmentKeyHandOffResponse.prototype.getAppointmentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.nexus_service.AppointmentKeyHandOffResponse} returns this
 */
proto.nexus_service.AppointmentKeyHandOffResponse.prototype.setAppointmentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.nexus_service.GetAppointmentKeyHandOffRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.nexus_service.GetAppointmentKeyHandOffRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.nexus_service.GetAppointmentKeyHandOffRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.GetAppointmentKeyHandOffRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    appointmentId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nexus_service.GetAppointmentKeyHandOffRequest}
 */
proto.nexus_service.GetAppointmentKeyHandOffRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.nexus_service.GetAppointmentKeyHandOffRequest;
  return proto.nexus_service.GetAppointmentKeyHandOffRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nexus_service.GetAppointmentKeyHandOffRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nexus_service.GetAppointmentKeyHandOffRequest}
 */
proto.nexus_service.GetAppointmentKeyHandOffRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppointmentId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nexus_service.GetAppointmentKeyHandOffRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.nexus_service.GetAppointmentKeyHandOffRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nexus_service.GetAppointmentKeyHandOffRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.GetAppointmentKeyHandOffRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppointmentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string appointment_id = 1;
 * @return {string}
 */
proto.nexus_service.GetAppointmentKeyHandOffRequest.prototype.getAppointmentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.nexus_service.GetAppointmentKeyHandOffRequest} returns this
 */
proto.nexus_service.GetAppointmentKeyHandOffRequest.prototype.setAppointmentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.nexus_service.GetAppointmentKeyHandOffResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.nexus_service.GetAppointmentKeyHandOffResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.nexus_service.GetAppointmentKeyHandOffResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.GetAppointmentKeyHandOffResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    appointmentKeyHandOff: (f = msg.getAppointmentKeyHandOff()) && appointment_pb.AppointmentKeyHandOff.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nexus_service.GetAppointmentKeyHandOffResponse}
 */
proto.nexus_service.GetAppointmentKeyHandOffResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.nexus_service.GetAppointmentKeyHandOffResponse;
  return proto.nexus_service.GetAppointmentKeyHandOffResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nexus_service.GetAppointmentKeyHandOffResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nexus_service.GetAppointmentKeyHandOffResponse}
 */
proto.nexus_service.GetAppointmentKeyHandOffResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new appointment_pb.AppointmentKeyHandOff;
      reader.readMessage(value,appointment_pb.AppointmentKeyHandOff.deserializeBinaryFromReader);
      msg.setAppointmentKeyHandOff(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nexus_service.GetAppointmentKeyHandOffResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.nexus_service.GetAppointmentKeyHandOffResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nexus_service.GetAppointmentKeyHandOffResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.GetAppointmentKeyHandOffResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppointmentKeyHandOff();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      appointment_pb.AppointmentKeyHandOff.serializeBinaryToWriter
    );
  }
};


/**
 * optional appointment.AppointmentKeyHandOff appointment_key_hand_off = 1;
 * @return {?proto.appointment.AppointmentKeyHandOff}
 */
proto.nexus_service.GetAppointmentKeyHandOffResponse.prototype.getAppointmentKeyHandOff = function() {
  return /** @type{?proto.appointment.AppointmentKeyHandOff} */ (
    jspb.Message.getWrapperField(this, appointment_pb.AppointmentKeyHandOff, 1));
};


/**
 * @param {?proto.appointment.AppointmentKeyHandOff|undefined} value
 * @return {!proto.nexus_service.GetAppointmentKeyHandOffResponse} returns this
*/
proto.nexus_service.GetAppointmentKeyHandOffResponse.prototype.setAppointmentKeyHandOff = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.nexus_service.GetAppointmentKeyHandOffResponse} returns this
 */
proto.nexus_service.GetAppointmentKeyHandOffResponse.prototype.clearAppointmentKeyHandOff = function() {
  return this.setAppointmentKeyHandOff(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.nexus_service.GetAppointmentKeyHandOffResponse.prototype.hasAppointmentKeyHandOff = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.nexus_service.ListCompletedAppointmentsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.nexus_service.ListCompletedAppointmentsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.nexus_service.ListCompletedAppointmentsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.ListCompletedAppointmentsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealerId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nexus_service.ListCompletedAppointmentsRequest}
 */
proto.nexus_service.ListCompletedAppointmentsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.nexus_service.ListCompletedAppointmentsRequest;
  return proto.nexus_service.ListCompletedAppointmentsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nexus_service.ListCompletedAppointmentsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nexus_service.ListCompletedAppointmentsRequest}
 */
proto.nexus_service.ListCompletedAppointmentsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nexus_service.ListCompletedAppointmentsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.nexus_service.ListCompletedAppointmentsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nexus_service.ListCompletedAppointmentsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.ListCompletedAppointmentsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string dealer_id = 1;
 * @return {string}
 */
proto.nexus_service.ListCompletedAppointmentsRequest.prototype.getDealerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.nexus_service.ListCompletedAppointmentsRequest} returns this
 */
proto.nexus_service.ListCompletedAppointmentsRequest.prototype.setDealerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.nexus_service.ListCompletedAppointmentsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.nexus_service.ListCompletedAppointmentsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.nexus_service.ListCompletedAppointmentsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.nexus_service.ListCompletedAppointmentsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.ListCompletedAppointmentsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    appointmentsList: jspb.Message.toObjectList(msg.getAppointmentsList(),
    appointment_pb.Appointment.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nexus_service.ListCompletedAppointmentsResponse}
 */
proto.nexus_service.ListCompletedAppointmentsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.nexus_service.ListCompletedAppointmentsResponse;
  return proto.nexus_service.ListCompletedAppointmentsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nexus_service.ListCompletedAppointmentsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nexus_service.ListCompletedAppointmentsResponse}
 */
proto.nexus_service.ListCompletedAppointmentsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new appointment_pb.Appointment;
      reader.readMessage(value,appointment_pb.Appointment.deserializeBinaryFromReader);
      msg.addAppointments(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nexus_service.ListCompletedAppointmentsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.nexus_service.ListCompletedAppointmentsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nexus_service.ListCompletedAppointmentsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.ListCompletedAppointmentsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppointmentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      appointment_pb.Appointment.serializeBinaryToWriter
    );
  }
};


/**
 * repeated appointment.Appointment appointments = 1;
 * @return {!Array<!proto.appointment.Appointment>}
 */
proto.nexus_service.ListCompletedAppointmentsResponse.prototype.getAppointmentsList = function() {
  return /** @type{!Array<!proto.appointment.Appointment>} */ (
    jspb.Message.getRepeatedWrapperField(this, appointment_pb.Appointment, 1));
};


/**
 * @param {!Array<!proto.appointment.Appointment>} value
 * @return {!proto.nexus_service.ListCompletedAppointmentsResponse} returns this
*/
proto.nexus_service.ListCompletedAppointmentsResponse.prototype.setAppointmentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.appointment.Appointment=} opt_value
 * @param {number=} opt_index
 * @return {!proto.appointment.Appointment}
 */
proto.nexus_service.ListCompletedAppointmentsResponse.prototype.addAppointments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.appointment.Appointment, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.nexus_service.ListCompletedAppointmentsResponse} returns this
 */
proto.nexus_service.ListCompletedAppointmentsResponse.prototype.clearAppointmentsList = function() {
  return this.setAppointmentsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.nexus_service.ListDealershipsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.nexus_service.ListDealershipsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.nexus_service.ListDealershipsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.ListDealershipsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nexus_service.ListDealershipsRequest}
 */
proto.nexus_service.ListDealershipsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.nexus_service.ListDealershipsRequest;
  return proto.nexus_service.ListDealershipsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nexus_service.ListDealershipsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nexus_service.ListDealershipsRequest}
 */
proto.nexus_service.ListDealershipsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nexus_service.ListDealershipsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.nexus_service.ListDealershipsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nexus_service.ListDealershipsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.ListDealershipsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.nexus_service.ListDealershipsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.nexus_service.ListDealershipsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.nexus_service.ListDealershipsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.nexus_service.ListDealershipsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.ListDealershipsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealersList: jspb.Message.toObjectList(msg.getDealersList(),
    dealer_pb.Dealership.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nexus_service.ListDealershipsResponse}
 */
proto.nexus_service.ListDealershipsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.nexus_service.ListDealershipsResponse;
  return proto.nexus_service.ListDealershipsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nexus_service.ListDealershipsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nexus_service.ListDealershipsResponse}
 */
proto.nexus_service.ListDealershipsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new dealer_pb.Dealership;
      reader.readMessage(value,dealer_pb.Dealership.deserializeBinaryFromReader);
      msg.addDealers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nexus_service.ListDealershipsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.nexus_service.ListDealershipsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nexus_service.ListDealershipsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.ListDealershipsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      dealer_pb.Dealership.serializeBinaryToWriter
    );
  }
};


/**
 * repeated dealer.Dealership dealers = 1;
 * @return {!Array<!proto.dealer.Dealership>}
 */
proto.nexus_service.ListDealershipsResponse.prototype.getDealersList = function() {
  return /** @type{!Array<!proto.dealer.Dealership>} */ (
    jspb.Message.getRepeatedWrapperField(this, dealer_pb.Dealership, 1));
};


/**
 * @param {!Array<!proto.dealer.Dealership>} value
 * @return {!proto.nexus_service.ListDealershipsResponse} returns this
*/
proto.nexus_service.ListDealershipsResponse.prototype.setDealersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.dealer.Dealership=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dealer.Dealership}
 */
proto.nexus_service.ListDealershipsResponse.prototype.addDealers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.dealer.Dealership, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.nexus_service.ListDealershipsResponse} returns this
 */
proto.nexus_service.ListDealershipsResponse.prototype.clearDealersList = function() {
  return this.setDealersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.nexus_service.UpdateAppointmentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.nexus_service.UpdateAppointmentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.nexus_service.UpdateAppointmentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.UpdateAppointmentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    appointment: (f = msg.getAppointment()) && appointment_pb.Appointment.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nexus_service.UpdateAppointmentRequest}
 */
proto.nexus_service.UpdateAppointmentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.nexus_service.UpdateAppointmentRequest;
  return proto.nexus_service.UpdateAppointmentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nexus_service.UpdateAppointmentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nexus_service.UpdateAppointmentRequest}
 */
proto.nexus_service.UpdateAppointmentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new appointment_pb.Appointment;
      reader.readMessage(value,appointment_pb.Appointment.deserializeBinaryFromReader);
      msg.setAppointment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nexus_service.UpdateAppointmentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.nexus_service.UpdateAppointmentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nexus_service.UpdateAppointmentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.UpdateAppointmentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppointment();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      appointment_pb.Appointment.serializeBinaryToWriter
    );
  }
};


/**
 * optional appointment.Appointment appointment = 1;
 * @return {?proto.appointment.Appointment}
 */
proto.nexus_service.UpdateAppointmentRequest.prototype.getAppointment = function() {
  return /** @type{?proto.appointment.Appointment} */ (
    jspb.Message.getWrapperField(this, appointment_pb.Appointment, 1));
};


/**
 * @param {?proto.appointment.Appointment|undefined} value
 * @return {!proto.nexus_service.UpdateAppointmentRequest} returns this
*/
proto.nexus_service.UpdateAppointmentRequest.prototype.setAppointment = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.nexus_service.UpdateAppointmentRequest} returns this
 */
proto.nexus_service.UpdateAppointmentRequest.prototype.clearAppointment = function() {
  return this.setAppointment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.nexus_service.UpdateAppointmentRequest.prototype.hasAppointment = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.nexus_service.UpdateAppointmentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.nexus_service.UpdateAppointmentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.nexus_service.UpdateAppointmentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.UpdateAppointmentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    appointmentId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nexus_service.UpdateAppointmentResponse}
 */
proto.nexus_service.UpdateAppointmentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.nexus_service.UpdateAppointmentResponse;
  return proto.nexus_service.UpdateAppointmentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nexus_service.UpdateAppointmentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nexus_service.UpdateAppointmentResponse}
 */
proto.nexus_service.UpdateAppointmentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppointmentId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nexus_service.UpdateAppointmentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.nexus_service.UpdateAppointmentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nexus_service.UpdateAppointmentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.UpdateAppointmentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppointmentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string appointment_id = 1;
 * @return {string}
 */
proto.nexus_service.UpdateAppointmentResponse.prototype.getAppointmentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.nexus_service.UpdateAppointmentResponse} returns this
 */
proto.nexus_service.UpdateAppointmentResponse.prototype.setAppointmentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.nexus_service.ListDealershipUsersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.nexus_service.ListDealershipUsersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.nexus_service.ListDealershipUsersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.ListDealershipUsersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealershipId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nexus_service.ListDealershipUsersRequest}
 */
proto.nexus_service.ListDealershipUsersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.nexus_service.ListDealershipUsersRequest;
  return proto.nexus_service.ListDealershipUsersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nexus_service.ListDealershipUsersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nexus_service.ListDealershipUsersRequest}
 */
proto.nexus_service.ListDealershipUsersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealershipId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nexus_service.ListDealershipUsersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.nexus_service.ListDealershipUsersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nexus_service.ListDealershipUsersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.ListDealershipUsersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealershipId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string dealership_id = 1;
 * @return {string}
 */
proto.nexus_service.ListDealershipUsersRequest.prototype.getDealershipId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.nexus_service.ListDealershipUsersRequest} returns this
 */
proto.nexus_service.ListDealershipUsersRequest.prototype.setDealershipId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.nexus_service.ListDealershipUsersResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.nexus_service.ListDealershipUsersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.nexus_service.ListDealershipUsersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.nexus_service.ListDealershipUsersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.ListDealershipUsersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealershipUsersList: jspb.Message.toObjectList(msg.getDealershipUsersList(),
    dealer_pb.DealershipUser.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nexus_service.ListDealershipUsersResponse}
 */
proto.nexus_service.ListDealershipUsersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.nexus_service.ListDealershipUsersResponse;
  return proto.nexus_service.ListDealershipUsersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nexus_service.ListDealershipUsersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nexus_service.ListDealershipUsersResponse}
 */
proto.nexus_service.ListDealershipUsersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new dealer_pb.DealershipUser;
      reader.readMessage(value,dealer_pb.DealershipUser.deserializeBinaryFromReader);
      msg.addDealershipUsers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nexus_service.ListDealershipUsersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.nexus_service.ListDealershipUsersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nexus_service.ListDealershipUsersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.ListDealershipUsersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealershipUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      dealer_pb.DealershipUser.serializeBinaryToWriter
    );
  }
};


/**
 * repeated dealer.DealershipUser dealership_users = 1;
 * @return {!Array<!proto.dealer.DealershipUser>}
 */
proto.nexus_service.ListDealershipUsersResponse.prototype.getDealershipUsersList = function() {
  return /** @type{!Array<!proto.dealer.DealershipUser>} */ (
    jspb.Message.getRepeatedWrapperField(this, dealer_pb.DealershipUser, 1));
};


/**
 * @param {!Array<!proto.dealer.DealershipUser>} value
 * @return {!proto.nexus_service.ListDealershipUsersResponse} returns this
*/
proto.nexus_service.ListDealershipUsersResponse.prototype.setDealershipUsersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.dealer.DealershipUser=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dealer.DealershipUser}
 */
proto.nexus_service.ListDealershipUsersResponse.prototype.addDealershipUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.dealer.DealershipUser, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.nexus_service.ListDealershipUsersResponse} returns this
 */
proto.nexus_service.ListDealershipUsersResponse.prototype.clearDealershipUsersList = function() {
  return this.setDealershipUsersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.nexus_service.GetVehicleMapRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.nexus_service.GetVehicleMapRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.nexus_service.GetVehicleMapRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.GetVehicleMapRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: (f = msg.getStatus()) && web_pb.ListParams.toObject(includeInstance, f),
    vehicleModel: jspb.Message.getFieldWithDefault(msg, 2, ""),
    vehicleMake: jspb.Message.getFieldWithDefault(msg, 3, ""),
    vehicleVin: jspb.Message.getFieldWithDefault(msg, 4, ""),
    customerId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    customerName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    level: jspb.Message.getFieldWithDefault(msg, 7, 0),
    maxItems: jspb.Message.getFieldWithDefault(msg, 8, 0),
    vehicleId: jspb.Message.getFieldWithDefault(msg, 9, ""),
    geoReverseLookup: jspb.Message.getBooleanFieldWithDefault(msg, 10, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nexus_service.GetVehicleMapRequest}
 */
proto.nexus_service.GetVehicleMapRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.nexus_service.GetVehicleMapRequest;
  return proto.nexus_service.GetVehicleMapRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nexus_service.GetVehicleMapRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nexus_service.GetVehicleMapRequest}
 */
proto.nexus_service.GetVehicleMapRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new web_pb.ListParams;
      reader.readMessage(value,web_pb.ListParams.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleModel(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleMake(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleVin(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerName(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLevel(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMaxItems(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGeoReverseLookup(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nexus_service.GetVehicleMapRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.nexus_service.GetVehicleMapRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nexus_service.GetVehicleMapRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.GetVehicleMapRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      web_pb.ListParams.serializeBinaryToWriter
    );
  }
  f = message.getVehicleModel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getVehicleMake();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getVehicleVin();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCustomerName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getLevel();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getMaxItems();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getGeoReverseLookup();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
};


/**
 * optional web.ListParams status = 1;
 * @return {?proto.web.ListParams}
 */
proto.nexus_service.GetVehicleMapRequest.prototype.getStatus = function() {
  return /** @type{?proto.web.ListParams} */ (
    jspb.Message.getWrapperField(this, web_pb.ListParams, 1));
};


/**
 * @param {?proto.web.ListParams|undefined} value
 * @return {!proto.nexus_service.GetVehicleMapRequest} returns this
*/
proto.nexus_service.GetVehicleMapRequest.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.nexus_service.GetVehicleMapRequest} returns this
 */
proto.nexus_service.GetVehicleMapRequest.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.nexus_service.GetVehicleMapRequest.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string vehicle_model = 2;
 * @return {string}
 */
proto.nexus_service.GetVehicleMapRequest.prototype.getVehicleModel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.nexus_service.GetVehicleMapRequest} returns this
 */
proto.nexus_service.GetVehicleMapRequest.prototype.setVehicleModel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string vehicle_make = 3;
 * @return {string}
 */
proto.nexus_service.GetVehicleMapRequest.prototype.getVehicleMake = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.nexus_service.GetVehicleMapRequest} returns this
 */
proto.nexus_service.GetVehicleMapRequest.prototype.setVehicleMake = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string vehicle_vin = 4;
 * @return {string}
 */
proto.nexus_service.GetVehicleMapRequest.prototype.getVehicleVin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.nexus_service.GetVehicleMapRequest} returns this
 */
proto.nexus_service.GetVehicleMapRequest.prototype.setVehicleVin = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string customer_id = 5;
 * @return {string}
 */
proto.nexus_service.GetVehicleMapRequest.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.nexus_service.GetVehicleMapRequest} returns this
 */
proto.nexus_service.GetVehicleMapRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string customer_name = 6;
 * @return {string}
 */
proto.nexus_service.GetVehicleMapRequest.prototype.getCustomerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.nexus_service.GetVehicleMapRequest} returns this
 */
proto.nexus_service.GetVehicleMapRequest.prototype.setCustomerName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional uint32 level = 7;
 * @return {number}
 */
proto.nexus_service.GetVehicleMapRequest.prototype.getLevel = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.nexus_service.GetVehicleMapRequest} returns this
 */
proto.nexus_service.GetVehicleMapRequest.prototype.setLevel = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional uint32 max_items = 8;
 * @return {number}
 */
proto.nexus_service.GetVehicleMapRequest.prototype.getMaxItems = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.nexus_service.GetVehicleMapRequest} returns this
 */
proto.nexus_service.GetVehicleMapRequest.prototype.setMaxItems = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string vehicle_id = 9;
 * @return {string}
 */
proto.nexus_service.GetVehicleMapRequest.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.nexus_service.GetVehicleMapRequest} returns this
 */
proto.nexus_service.GetVehicleMapRequest.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional bool geo_reverse_lookup = 10;
 * @return {boolean}
 */
proto.nexus_service.GetVehicleMapRequest.prototype.getGeoReverseLookup = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.nexus_service.GetVehicleMapRequest} returns this
 */
proto.nexus_service.GetVehicleMapRequest.prototype.setGeoReverseLookup = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.nexus_service.GetVehicleMapResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.nexus_service.GetVehicleMapResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.nexus_service.GetVehicleMapResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.nexus_service.GetVehicleMapResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.GetVehicleMapResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehiclesList: jspb.Message.toObjectList(msg.getVehiclesList(),
    proto.nexus_service.MapVehicle.toObject, includeInstance),
    total: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nexus_service.GetVehicleMapResponse}
 */
proto.nexus_service.GetVehicleMapResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.nexus_service.GetVehicleMapResponse;
  return proto.nexus_service.GetVehicleMapResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nexus_service.GetVehicleMapResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nexus_service.GetVehicleMapResponse}
 */
proto.nexus_service.GetVehicleMapResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.nexus_service.MapVehicle;
      reader.readMessage(value,proto.nexus_service.MapVehicle.deserializeBinaryFromReader);
      msg.addVehicles(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nexus_service.GetVehicleMapResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.nexus_service.GetVehicleMapResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nexus_service.GetVehicleMapResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.GetVehicleMapResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehiclesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.nexus_service.MapVehicle.serializeBinaryToWriter
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * repeated MapVehicle vehicles = 1;
 * @return {!Array<!proto.nexus_service.MapVehicle>}
 */
proto.nexus_service.GetVehicleMapResponse.prototype.getVehiclesList = function() {
  return /** @type{!Array<!proto.nexus_service.MapVehicle>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.nexus_service.MapVehicle, 1));
};


/**
 * @param {!Array<!proto.nexus_service.MapVehicle>} value
 * @return {!proto.nexus_service.GetVehicleMapResponse} returns this
*/
proto.nexus_service.GetVehicleMapResponse.prototype.setVehiclesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.nexus_service.MapVehicle=} opt_value
 * @param {number=} opt_index
 * @return {!proto.nexus_service.MapVehicle}
 */
proto.nexus_service.GetVehicleMapResponse.prototype.addVehicles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.nexus_service.MapVehicle, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.nexus_service.GetVehicleMapResponse} returns this
 */
proto.nexus_service.GetVehicleMapResponse.prototype.clearVehiclesList = function() {
  return this.setVehiclesList([]);
};


/**
 * optional uint32 total = 2;
 * @return {number}
 */
proto.nexus_service.GetVehicleMapResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.nexus_service.GetVehicleMapResponse} returns this
 */
proto.nexus_service.GetVehicleMapResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.nexus_service.MapVehicle.prototype.toObject = function(opt_includeInstance) {
  return proto.nexus_service.MapVehicle.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.nexus_service.MapVehicle} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.MapVehicle.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicle: (f = msg.getVehicle()) && proto.nexus_service.Vehicle.toObject(includeInstance, f),
    customer: (f = msg.getCustomer()) && proto.nexus_service.Customer.toObject(includeInstance, f),
    subscription: (f = msg.getSubscription()) && proto.nexus_service.Subscription.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nexus_service.MapVehicle}
 */
proto.nexus_service.MapVehicle.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.nexus_service.MapVehicle;
  return proto.nexus_service.MapVehicle.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nexus_service.MapVehicle} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nexus_service.MapVehicle}
 */
proto.nexus_service.MapVehicle.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.nexus_service.Vehicle;
      reader.readMessage(value,proto.nexus_service.Vehicle.deserializeBinaryFromReader);
      msg.setVehicle(value);
      break;
    case 2:
      var value = new proto.nexus_service.Customer;
      reader.readMessage(value,proto.nexus_service.Customer.deserializeBinaryFromReader);
      msg.setCustomer(value);
      break;
    case 3:
      var value = new proto.nexus_service.Subscription;
      reader.readMessage(value,proto.nexus_service.Subscription.deserializeBinaryFromReader);
      msg.setSubscription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nexus_service.MapVehicle.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.nexus_service.MapVehicle.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nexus_service.MapVehicle} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.MapVehicle.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicle();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.nexus_service.Vehicle.serializeBinaryToWriter
    );
  }
  f = message.getCustomer();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.nexus_service.Customer.serializeBinaryToWriter
    );
  }
  f = message.getSubscription();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.nexus_service.Subscription.serializeBinaryToWriter
    );
  }
};


/**
 * optional Vehicle vehicle = 1;
 * @return {?proto.nexus_service.Vehicle}
 */
proto.nexus_service.MapVehicle.prototype.getVehicle = function() {
  return /** @type{?proto.nexus_service.Vehicle} */ (
    jspb.Message.getWrapperField(this, proto.nexus_service.Vehicle, 1));
};


/**
 * @param {?proto.nexus_service.Vehicle|undefined} value
 * @return {!proto.nexus_service.MapVehicle} returns this
*/
proto.nexus_service.MapVehicle.prototype.setVehicle = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.nexus_service.MapVehicle} returns this
 */
proto.nexus_service.MapVehicle.prototype.clearVehicle = function() {
  return this.setVehicle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.nexus_service.MapVehicle.prototype.hasVehicle = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Customer customer = 2;
 * @return {?proto.nexus_service.Customer}
 */
proto.nexus_service.MapVehicle.prototype.getCustomer = function() {
  return /** @type{?proto.nexus_service.Customer} */ (
    jspb.Message.getWrapperField(this, proto.nexus_service.Customer, 2));
};


/**
 * @param {?proto.nexus_service.Customer|undefined} value
 * @return {!proto.nexus_service.MapVehicle} returns this
*/
proto.nexus_service.MapVehicle.prototype.setCustomer = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.nexus_service.MapVehicle} returns this
 */
proto.nexus_service.MapVehicle.prototype.clearCustomer = function() {
  return this.setCustomer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.nexus_service.MapVehicle.prototype.hasCustomer = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Subscription subscription = 3;
 * @return {?proto.nexus_service.Subscription}
 */
proto.nexus_service.MapVehicle.prototype.getSubscription = function() {
  return /** @type{?proto.nexus_service.Subscription} */ (
    jspb.Message.getWrapperField(this, proto.nexus_service.Subscription, 3));
};


/**
 * @param {?proto.nexus_service.Subscription|undefined} value
 * @return {!proto.nexus_service.MapVehicle} returns this
*/
proto.nexus_service.MapVehicle.prototype.setSubscription = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.nexus_service.MapVehicle} returns this
 */
proto.nexus_service.MapVehicle.prototype.clearSubscription = function() {
  return this.setSubscription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.nexus_service.MapVehicle.prototype.hasSubscription = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.nexus_service.UpdateVehicleCustodyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.nexus_service.UpdateVehicleCustodyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.nexus_service.UpdateVehicleCustodyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.UpdateVehicleCustodyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dealershipId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    customerId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nexus_service.UpdateVehicleCustodyRequest}
 */
proto.nexus_service.UpdateVehicleCustodyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.nexus_service.UpdateVehicleCustodyRequest;
  return proto.nexus_service.UpdateVehicleCustodyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nexus_service.UpdateVehicleCustodyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nexus_service.UpdateVehicleCustodyRequest}
 */
proto.nexus_service.UpdateVehicleCustodyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealershipId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nexus_service.UpdateVehicleCustodyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.nexus_service.UpdateVehicleCustodyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nexus_service.UpdateVehicleCustodyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.UpdateVehicleCustodyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDealershipId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.nexus_service.UpdateVehicleCustodyRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.nexus_service.UpdateVehicleCustodyRequest} returns this
 */
proto.nexus_service.UpdateVehicleCustodyRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string dealership_id = 2;
 * @return {string}
 */
proto.nexus_service.UpdateVehicleCustodyRequest.prototype.getDealershipId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.nexus_service.UpdateVehicleCustodyRequest} returns this
 */
proto.nexus_service.UpdateVehicleCustodyRequest.prototype.setDealershipId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string customer_id = 3;
 * @return {string}
 */
proto.nexus_service.UpdateVehicleCustodyRequest.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.nexus_service.UpdateVehicleCustodyRequest} returns this
 */
proto.nexus_service.UpdateVehicleCustodyRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.nexus_service.UpdateVehicleCustodyResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.nexus_service.UpdateVehicleCustodyResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.nexus_service.UpdateVehicleCustodyResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.UpdateVehicleCustodyResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nexus_service.UpdateVehicleCustodyResponse}
 */
proto.nexus_service.UpdateVehicleCustodyResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.nexus_service.UpdateVehicleCustodyResponse;
  return proto.nexus_service.UpdateVehicleCustodyResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nexus_service.UpdateVehicleCustodyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nexus_service.UpdateVehicleCustodyResponse}
 */
proto.nexus_service.UpdateVehicleCustodyResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nexus_service.UpdateVehicleCustodyResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.nexus_service.UpdateVehicleCustodyResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nexus_service.UpdateVehicleCustodyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.UpdateVehicleCustodyResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.nexus_service.GetLatestCustodyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.nexus_service.GetLatestCustodyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.nexus_service.GetLatestCustodyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.GetLatestCustodyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    custodyType: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nexus_service.GetLatestCustodyRequest}
 */
proto.nexus_service.GetLatestCustodyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.nexus_service.GetLatestCustodyRequest;
  return proto.nexus_service.GetLatestCustodyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nexus_service.GetLatestCustodyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nexus_service.GetLatestCustodyRequest}
 */
proto.nexus_service.GetLatestCustodyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 2:
      var value = /** @type {!proto.nexus_service.GetLatestCustodyRequest.CustodyType} */ (reader.readEnum());
      msg.setCustodyType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nexus_service.GetLatestCustodyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.nexus_service.GetLatestCustodyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nexus_service.GetLatestCustodyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.GetLatestCustodyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCustodyType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.nexus_service.GetLatestCustodyRequest.CustodyType = {
  DEALERSHIP: 0,
  CUSTOMER: 1,
  ANY: 2
};

/**
 * optional string vehicle_id = 1;
 * @return {string}
 */
proto.nexus_service.GetLatestCustodyRequest.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.nexus_service.GetLatestCustodyRequest} returns this
 */
proto.nexus_service.GetLatestCustodyRequest.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional CustodyType custody_type = 2;
 * @return {!proto.nexus_service.GetLatestCustodyRequest.CustodyType}
 */
proto.nexus_service.GetLatestCustodyRequest.prototype.getCustodyType = function() {
  return /** @type {!proto.nexus_service.GetLatestCustodyRequest.CustodyType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.nexus_service.GetLatestCustodyRequest.CustodyType} value
 * @return {!proto.nexus_service.GetLatestCustodyRequest} returns this
 */
proto.nexus_service.GetLatestCustodyRequest.prototype.setCustodyType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.nexus_service.GetLatestCustodyResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.nexus_service.GetLatestCustodyResponse.CustodianCase = {
  CUSTODIAN_NOT_SET: 0,
  DEALERSHIP: 1,
  CUSTOMER: 2
};

/**
 * @return {proto.nexus_service.GetLatestCustodyResponse.CustodianCase}
 */
proto.nexus_service.GetLatestCustodyResponse.prototype.getCustodianCase = function() {
  return /** @type {proto.nexus_service.GetLatestCustodyResponse.CustodianCase} */(jspb.Message.computeOneofCase(this, proto.nexus_service.GetLatestCustodyResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.nexus_service.GetLatestCustodyResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.nexus_service.GetLatestCustodyResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.nexus_service.GetLatestCustodyResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.GetLatestCustodyResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealership: (f = msg.getDealership()) && dealer_pb.Dealership.toObject(includeInstance, f),
    customer: (f = msg.getCustomer()) && domain_pb.Customer.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.nexus_service.GetLatestCustodyResponse}
 */
proto.nexus_service.GetLatestCustodyResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.nexus_service.GetLatestCustodyResponse;
  return proto.nexus_service.GetLatestCustodyResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.nexus_service.GetLatestCustodyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.nexus_service.GetLatestCustodyResponse}
 */
proto.nexus_service.GetLatestCustodyResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new dealer_pb.Dealership;
      reader.readMessage(value,dealer_pb.Dealership.deserializeBinaryFromReader);
      msg.setDealership(value);
      break;
    case 2:
      var value = new domain_pb.Customer;
      reader.readMessage(value,domain_pb.Customer.deserializeBinaryFromReader);
      msg.setCustomer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.nexus_service.GetLatestCustodyResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.nexus_service.GetLatestCustodyResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.nexus_service.GetLatestCustodyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.nexus_service.GetLatestCustodyResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealership();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      dealer_pb.Dealership.serializeBinaryToWriter
    );
  }
  f = message.getCustomer();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      domain_pb.Customer.serializeBinaryToWriter
    );
  }
};


/**
 * optional dealer.Dealership dealership = 1;
 * @return {?proto.dealer.Dealership}
 */
proto.nexus_service.GetLatestCustodyResponse.prototype.getDealership = function() {
  return /** @type{?proto.dealer.Dealership} */ (
    jspb.Message.getWrapperField(this, dealer_pb.Dealership, 1));
};


/**
 * @param {?proto.dealer.Dealership|undefined} value
 * @return {!proto.nexus_service.GetLatestCustodyResponse} returns this
*/
proto.nexus_service.GetLatestCustodyResponse.prototype.setDealership = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.nexus_service.GetLatestCustodyResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.nexus_service.GetLatestCustodyResponse} returns this
 */
proto.nexus_service.GetLatestCustodyResponse.prototype.clearDealership = function() {
  return this.setDealership(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.nexus_service.GetLatestCustodyResponse.prototype.hasDealership = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional domain.Customer customer = 2;
 * @return {?proto.domain.Customer}
 */
proto.nexus_service.GetLatestCustodyResponse.prototype.getCustomer = function() {
  return /** @type{?proto.domain.Customer} */ (
    jspb.Message.getWrapperField(this, domain_pb.Customer, 2));
};


/**
 * @param {?proto.domain.Customer|undefined} value
 * @return {!proto.nexus_service.GetLatestCustodyResponse} returns this
*/
proto.nexus_service.GetLatestCustodyResponse.prototype.setCustomer = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.nexus_service.GetLatestCustodyResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.nexus_service.GetLatestCustodyResponse} returns this
 */
proto.nexus_service.GetLatestCustodyResponse.prototype.clearCustomer = function() {
  return this.setCustomer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.nexus_service.GetLatestCustodyResponse.prototype.hasCustomer = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.nexus_service);
