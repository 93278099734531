import { createTheme } from '@mui/material/styles'
import { typography } from './theme/typography'
import { palette } from './theme/palette'
import { components } from './theme/components'

const theme = createTheme({
  palette,
  components,
  typography,
})

export default theme
