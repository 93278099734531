// source: vehicle_listing_service.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var vehicle_pb = require('./vehicle_pb.js');
goog.object.extend(proto, vehicle_pb);
var domain_pb = require('./domain_pb.js');
goog.object.extend(proto, domain_pb);
var inspection_pb = require('./inspection_pb.js');
goog.object.extend(proto, inspection_pb);
goog.exportSymbol('proto.vehicle_listing_service.ConfirmVehicleRequest', null, global);
goog.exportSymbol('proto.vehicle_listing_service.ConfirmVehicleResponse', null, global);
goog.exportSymbol('proto.vehicle_listing_service.DealershipCriteria', null, global);
goog.exportSymbol('proto.vehicle_listing_service.GetAvailableModelsRequest', null, global);
goog.exportSymbol('proto.vehicle_listing_service.GetAvailableModelsResponse', null, global);
goog.exportSymbol('proto.vehicle_listing_service.GetAvailableTrimsRequest', null, global);
goog.exportSymbol('proto.vehicle_listing_service.GetAvailableTrimsResponse', null, global);
goog.exportSymbol('proto.vehicle_listing_service.GetDealershipRequest', null, global);
goog.exportSymbol('proto.vehicle_listing_service.GetDealershipResponse', null, global);
goog.exportSymbol('proto.vehicle_listing_service.GetVehicleInspectionResultsRequest', null, global);
goog.exportSymbol('proto.vehicle_listing_service.GetVehicleRequest', null, global);
goog.exportSymbol('proto.vehicle_listing_service.ListDealershipsRequest', null, global);
goog.exportSymbol('proto.vehicle_listing_service.ListDealershipsResponse', null, global);
goog.exportSymbol('proto.vehicle_listing_service.ListOrdersRequest', null, global);
goog.exportSymbol('proto.vehicle_listing_service.ListOrdersResponse', null, global);
goog.exportSymbol('proto.vehicle_listing_service.ListProfilesRequest', null, global);
goog.exportSymbol('proto.vehicle_listing_service.ListProfilesResponse', null, global);
goog.exportSymbol('proto.vehicle_listing_service.ListVehiclesRequest', null, global);
goog.exportSymbol('proto.vehicle_listing_service.ListVehiclesResponse', null, global);
goog.exportSymbol('proto.vehicle_listing_service.OrderStatus', null, global);
goog.exportSymbol('proto.vehicle_listing_service.StartVehicleInspectionRequest', null, global);
goog.exportSymbol('proto.vehicle_listing_service.StartVehicleInspectionResponse', null, global);
goog.exportSymbol('proto.vehicle_listing_service.UpdateVehicleRequest', null, global);
goog.exportSymbol('proto.vehicle_listing_service.UpdateVehicleResponse', null, global);
goog.exportSymbol('proto.vehicle_listing_service.VehicleCriteria', null, global);
goog.exportSymbol('proto.vehicle_listing_service.VehicleOrder', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vehicle_listing_service.ListOrdersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vehicle_listing_service.ListOrdersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vehicle_listing_service.ListOrdersRequest.displayName = 'proto.vehicle_listing_service.ListOrdersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vehicle_listing_service.ListOrdersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vehicle_listing_service.ListOrdersResponse.repeatedFields_, null);
};
goog.inherits(proto.vehicle_listing_service.ListOrdersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vehicle_listing_service.ListOrdersResponse.displayName = 'proto.vehicle_listing_service.ListOrdersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vehicle_listing_service.VehicleOrder = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vehicle_listing_service.VehicleOrder, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vehicle_listing_service.VehicleOrder.displayName = 'proto.vehicle_listing_service.VehicleOrder';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vehicle_listing_service.ConfirmVehicleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vehicle_listing_service.ConfirmVehicleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vehicle_listing_service.ConfirmVehicleRequest.displayName = 'proto.vehicle_listing_service.ConfirmVehicleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vehicle_listing_service.ConfirmVehicleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vehicle_listing_service.ConfirmVehicleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vehicle_listing_service.ConfirmVehicleResponse.displayName = 'proto.vehicle_listing_service.ConfirmVehicleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vehicle_listing_service.GetVehicleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vehicle_listing_service.GetVehicleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vehicle_listing_service.GetVehicleRequest.displayName = 'proto.vehicle_listing_service.GetVehicleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vehicle_listing_service.ListProfilesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vehicle_listing_service.ListProfilesRequest.repeatedFields_, null);
};
goog.inherits(proto.vehicle_listing_service.ListProfilesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vehicle_listing_service.ListProfilesRequest.displayName = 'proto.vehicle_listing_service.ListProfilesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vehicle_listing_service.ListProfilesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vehicle_listing_service.ListProfilesResponse.repeatedFields_, null);
};
goog.inherits(proto.vehicle_listing_service.ListProfilesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vehicle_listing_service.ListProfilesResponse.displayName = 'proto.vehicle_listing_service.ListProfilesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vehicle_listing_service.ListVehiclesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vehicle_listing_service.ListVehiclesRequest.repeatedFields_, null);
};
goog.inherits(proto.vehicle_listing_service.ListVehiclesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vehicle_listing_service.ListVehiclesRequest.displayName = 'proto.vehicle_listing_service.ListVehiclesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vehicle_listing_service.VehicleCriteria = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vehicle_listing_service.VehicleCriteria, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vehicle_listing_service.VehicleCriteria.displayName = 'proto.vehicle_listing_service.VehicleCriteria';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vehicle_listing_service.ListVehiclesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vehicle_listing_service.ListVehiclesResponse.repeatedFields_, null);
};
goog.inherits(proto.vehicle_listing_service.ListVehiclesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vehicle_listing_service.ListVehiclesResponse.displayName = 'proto.vehicle_listing_service.ListVehiclesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vehicle_listing_service.UpdateVehicleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vehicle_listing_service.UpdateVehicleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vehicle_listing_service.UpdateVehicleRequest.displayName = 'proto.vehicle_listing_service.UpdateVehicleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vehicle_listing_service.UpdateVehicleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vehicle_listing_service.UpdateVehicleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vehicle_listing_service.UpdateVehicleResponse.displayName = 'proto.vehicle_listing_service.UpdateVehicleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vehicle_listing_service.ListDealershipsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vehicle_listing_service.ListDealershipsRequest.repeatedFields_, null);
};
goog.inherits(proto.vehicle_listing_service.ListDealershipsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vehicle_listing_service.ListDealershipsRequest.displayName = 'proto.vehicle_listing_service.ListDealershipsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vehicle_listing_service.ListDealershipsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vehicle_listing_service.ListDealershipsResponse.repeatedFields_, null);
};
goog.inherits(proto.vehicle_listing_service.ListDealershipsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vehicle_listing_service.ListDealershipsResponse.displayName = 'proto.vehicle_listing_service.ListDealershipsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vehicle_listing_service.GetDealershipRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vehicle_listing_service.GetDealershipRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vehicle_listing_service.GetDealershipRequest.displayName = 'proto.vehicle_listing_service.GetDealershipRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vehicle_listing_service.GetDealershipResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vehicle_listing_service.GetDealershipResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vehicle_listing_service.GetDealershipResponse.displayName = 'proto.vehicle_listing_service.GetDealershipResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vehicle_listing_service.DealershipCriteria = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vehicle_listing_service.DealershipCriteria, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vehicle_listing_service.DealershipCriteria.displayName = 'proto.vehicle_listing_service.DealershipCriteria';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vehicle_listing_service.StartVehicleInspectionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vehicle_listing_service.StartVehicleInspectionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vehicle_listing_service.StartVehicleInspectionRequest.displayName = 'proto.vehicle_listing_service.StartVehicleInspectionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vehicle_listing_service.StartVehicleInspectionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vehicle_listing_service.StartVehicleInspectionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vehicle_listing_service.StartVehicleInspectionResponse.displayName = 'proto.vehicle_listing_service.StartVehicleInspectionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vehicle_listing_service.GetVehicleInspectionResultsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vehicle_listing_service.GetVehicleInspectionResultsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vehicle_listing_service.GetVehicleInspectionResultsRequest.displayName = 'proto.vehicle_listing_service.GetVehicleInspectionResultsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vehicle_listing_service.GetAvailableModelsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vehicle_listing_service.GetAvailableModelsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vehicle_listing_service.GetAvailableModelsRequest.displayName = 'proto.vehicle_listing_service.GetAvailableModelsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vehicle_listing_service.GetAvailableModelsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vehicle_listing_service.GetAvailableModelsResponse.repeatedFields_, null);
};
goog.inherits(proto.vehicle_listing_service.GetAvailableModelsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vehicle_listing_service.GetAvailableModelsResponse.displayName = 'proto.vehicle_listing_service.GetAvailableModelsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vehicle_listing_service.GetAvailableTrimsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vehicle_listing_service.GetAvailableTrimsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vehicle_listing_service.GetAvailableTrimsRequest.displayName = 'proto.vehicle_listing_service.GetAvailableTrimsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vehicle_listing_service.GetAvailableTrimsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vehicle_listing_service.GetAvailableTrimsResponse.repeatedFields_, null);
};
goog.inherits(proto.vehicle_listing_service.GetAvailableTrimsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vehicle_listing_service.GetAvailableTrimsResponse.displayName = 'proto.vehicle_listing_service.GetAvailableTrimsResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vehicle_listing_service.ListOrdersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vehicle_listing_service.ListOrdersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vehicle_listing_service.ListOrdersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle_listing_service.ListOrdersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    startDate: jspb.Message.getFieldWithDefault(msg, 1, ""),
    endDate: jspb.Message.getFieldWithDefault(msg, 2, ""),
    pageNumber: jspb.Message.getFieldWithDefault(msg, 3, 0),
    pageSize: jspb.Message.getFieldWithDefault(msg, 4, 0),
    orderStatus: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vehicle_listing_service.ListOrdersRequest}
 */
proto.vehicle_listing_service.ListOrdersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vehicle_listing_service.ListOrdersRequest;
  return proto.vehicle_listing_service.ListOrdersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vehicle_listing_service.ListOrdersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vehicle_listing_service.ListOrdersRequest}
 */
proto.vehicle_listing_service.ListOrdersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartDate(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndDate(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageNumber(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 5:
      var value = /** @type {!proto.vehicle_listing_service.OrderStatus} */ (reader.readEnum());
      msg.setOrderStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vehicle_listing_service.ListOrdersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vehicle_listing_service.ListOrdersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vehicle_listing_service.ListOrdersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle_listing_service.ListOrdersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStartDate();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEndDate();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPageNumber();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getOrderStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * optional string start_date = 1;
 * @return {string}
 */
proto.vehicle_listing_service.ListOrdersRequest.prototype.getStartDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle_listing_service.ListOrdersRequest} returns this
 */
proto.vehicle_listing_service.ListOrdersRequest.prototype.setStartDate = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string end_date = 2;
 * @return {string}
 */
proto.vehicle_listing_service.ListOrdersRequest.prototype.getEndDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle_listing_service.ListOrdersRequest} returns this
 */
proto.vehicle_listing_service.ListOrdersRequest.prototype.setEndDate = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 page_number = 3;
 * @return {number}
 */
proto.vehicle_listing_service.ListOrdersRequest.prototype.getPageNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vehicle_listing_service.ListOrdersRequest} returns this
 */
proto.vehicle_listing_service.ListOrdersRequest.prototype.setPageNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 page_size = 4;
 * @return {number}
 */
proto.vehicle_listing_service.ListOrdersRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.vehicle_listing_service.ListOrdersRequest} returns this
 */
proto.vehicle_listing_service.ListOrdersRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional OrderStatus order_status = 5;
 * @return {!proto.vehicle_listing_service.OrderStatus}
 */
proto.vehicle_listing_service.ListOrdersRequest.prototype.getOrderStatus = function() {
  return /** @type {!proto.vehicle_listing_service.OrderStatus} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.vehicle_listing_service.OrderStatus} value
 * @return {!proto.vehicle_listing_service.ListOrdersRequest} returns this
 */
proto.vehicle_listing_service.ListOrdersRequest.prototype.setOrderStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vehicle_listing_service.ListOrdersResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vehicle_listing_service.ListOrdersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vehicle_listing_service.ListOrdersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vehicle_listing_service.ListOrdersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle_listing_service.ListOrdersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalOrders: jspb.Message.getFieldWithDefault(msg, 1, 0),
    vehicleOrdersList: jspb.Message.toObjectList(msg.getVehicleOrdersList(),
    proto.vehicle_listing_service.VehicleOrder.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vehicle_listing_service.ListOrdersResponse}
 */
proto.vehicle_listing_service.ListOrdersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vehicle_listing_service.ListOrdersResponse;
  return proto.vehicle_listing_service.ListOrdersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vehicle_listing_service.ListOrdersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vehicle_listing_service.ListOrdersResponse}
 */
proto.vehicle_listing_service.ListOrdersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalOrders(value);
      break;
    case 2:
      var value = new proto.vehicle_listing_service.VehicleOrder;
      reader.readMessage(value,proto.vehicle_listing_service.VehicleOrder.deserializeBinaryFromReader);
      msg.addVehicleOrders(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vehicle_listing_service.ListOrdersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vehicle_listing_service.ListOrdersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vehicle_listing_service.ListOrdersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle_listing_service.ListOrdersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalOrders();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getVehicleOrdersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.vehicle_listing_service.VehicleOrder.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 total_orders = 1;
 * @return {number}
 */
proto.vehicle_listing_service.ListOrdersResponse.prototype.getTotalOrders = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vehicle_listing_service.ListOrdersResponse} returns this
 */
proto.vehicle_listing_service.ListOrdersResponse.prototype.setTotalOrders = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated VehicleOrder vehicle_orders = 2;
 * @return {!Array<!proto.vehicle_listing_service.VehicleOrder>}
 */
proto.vehicle_listing_service.ListOrdersResponse.prototype.getVehicleOrdersList = function() {
  return /** @type{!Array<!proto.vehicle_listing_service.VehicleOrder>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vehicle_listing_service.VehicleOrder, 2));
};


/**
 * @param {!Array<!proto.vehicle_listing_service.VehicleOrder>} value
 * @return {!proto.vehicle_listing_service.ListOrdersResponse} returns this
*/
proto.vehicle_listing_service.ListOrdersResponse.prototype.setVehicleOrdersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.vehicle_listing_service.VehicleOrder=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vehicle_listing_service.VehicleOrder}
 */
proto.vehicle_listing_service.ListOrdersResponse.prototype.addVehicleOrders = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.vehicle_listing_service.VehicleOrder, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vehicle_listing_service.ListOrdersResponse} returns this
 */
proto.vehicle_listing_service.ListOrdersResponse.prototype.clearVehicleOrdersList = function() {
  return this.setVehicleOrdersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vehicle_listing_service.VehicleOrder.prototype.toObject = function(opt_includeInstance) {
  return proto.vehicle_listing_service.VehicleOrder.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vehicle_listing_service.VehicleOrder} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle_listing_service.VehicleOrder.toObject = function(includeInstance, msg) {
  var f, obj = {
    pickupDate: jspb.Message.getFieldWithDefault(msg, 1, 0),
    depositPaidDate: jspb.Message.getFieldWithDefault(msg, 2, 0),
    subscriptionStatus: jspb.Message.getFieldWithDefault(msg, 3, 0),
    dealerId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    dealerName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    customerName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    customerAccountNumber: jspb.Message.getFieldWithDefault(msg, 7, ""),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 8, ""),
    vehicleModelAndTrim: jspb.Message.getFieldWithDefault(msg, 9, ""),
    vehicleMiles: jspb.Message.getFieldWithDefault(msg, 10, ""),
    vehicleColor: jspb.Message.getFieldWithDefault(msg, 11, ""),
    dateListed: jspb.Message.getFieldWithDefault(msg, 12, 0),
    vehicleVin: jspb.Message.getFieldWithDefault(msg, 13, ""),
    securityDeposit: jspb.Message.getFieldWithDefault(msg, 14, 0),
    startPayment: jspb.Message.getFieldWithDefault(msg, 15, 0),
    monthlyPayment: jspb.Message.getFieldWithDefault(msg, 16, 0),
    reservationDeposit: jspb.Message.getFieldWithDefault(msg, 17, 0),
    orderStatus: jspb.Message.getFieldWithDefault(msg, 18, 0),
    customerId: jspb.Message.getFieldWithDefault(msg, 19, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vehicle_listing_service.VehicleOrder}
 */
proto.vehicle_listing_service.VehicleOrder.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vehicle_listing_service.VehicleOrder;
  return proto.vehicle_listing_service.VehicleOrder.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vehicle_listing_service.VehicleOrder} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vehicle_listing_service.VehicleOrder}
 */
proto.vehicle_listing_service.VehicleOrder.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPickupDate(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDepositPaidDate(value);
      break;
    case 3:
      var value = /** @type {!proto.domain.Subscription.Status} */ (reader.readEnum());
      msg.setSubscriptionStatus(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealerId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealerName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerAccountNumber(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleModelAndTrim(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleMiles(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleColor(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDateListed(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleVin(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSecurityDeposit(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStartPayment(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMonthlyPayment(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setReservationDeposit(value);
      break;
    case 18:
      var value = /** @type {!proto.vehicle_listing_service.OrderStatus} */ (reader.readEnum());
      msg.setOrderStatus(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vehicle_listing_service.VehicleOrder.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vehicle_listing_service.VehicleOrder.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vehicle_listing_service.VehicleOrder} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle_listing_service.VehicleOrder.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPickupDate();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getDepositPaidDate();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getSubscriptionStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getDealerId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDealerName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCustomerName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCustomerAccountNumber();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getVehicleModelAndTrim();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getVehicleMiles();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getVehicleColor();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getDateListed();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getVehicleVin();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getSecurityDeposit();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
  f = message.getStartPayment();
  if (f !== 0) {
    writer.writeInt32(
      15,
      f
    );
  }
  f = message.getMonthlyPayment();
  if (f !== 0) {
    writer.writeInt32(
      16,
      f
    );
  }
  f = message.getReservationDeposit();
  if (f !== 0) {
    writer.writeInt32(
      17,
      f
    );
  }
  f = message.getOrderStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      18,
      f
    );
  }
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
};


/**
 * optional int64 pickup_date = 1;
 * @return {number}
 */
proto.vehicle_listing_service.VehicleOrder.prototype.getPickupDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vehicle_listing_service.VehicleOrder} returns this
 */
proto.vehicle_listing_service.VehicleOrder.prototype.setPickupDate = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 deposit_paid_date = 2;
 * @return {number}
 */
proto.vehicle_listing_service.VehicleOrder.prototype.getDepositPaidDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vehicle_listing_service.VehicleOrder} returns this
 */
proto.vehicle_listing_service.VehicleOrder.prototype.setDepositPaidDate = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional domain.Subscription.Status subscription_status = 3;
 * @return {!proto.domain.Subscription.Status}
 */
proto.vehicle_listing_service.VehicleOrder.prototype.getSubscriptionStatus = function() {
  return /** @type {!proto.domain.Subscription.Status} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.domain.Subscription.Status} value
 * @return {!proto.vehicle_listing_service.VehicleOrder} returns this
 */
proto.vehicle_listing_service.VehicleOrder.prototype.setSubscriptionStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string dealer_id = 4;
 * @return {string}
 */
proto.vehicle_listing_service.VehicleOrder.prototype.getDealerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle_listing_service.VehicleOrder} returns this
 */
proto.vehicle_listing_service.VehicleOrder.prototype.setDealerId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string dealer_name = 5;
 * @return {string}
 */
proto.vehicle_listing_service.VehicleOrder.prototype.getDealerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle_listing_service.VehicleOrder} returns this
 */
proto.vehicle_listing_service.VehicleOrder.prototype.setDealerName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string customer_name = 6;
 * @return {string}
 */
proto.vehicle_listing_service.VehicleOrder.prototype.getCustomerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle_listing_service.VehicleOrder} returns this
 */
proto.vehicle_listing_service.VehicleOrder.prototype.setCustomerName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string customer_account_number = 7;
 * @return {string}
 */
proto.vehicle_listing_service.VehicleOrder.prototype.getCustomerAccountNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle_listing_service.VehicleOrder} returns this
 */
proto.vehicle_listing_service.VehicleOrder.prototype.setCustomerAccountNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string subscription_id = 8;
 * @return {string}
 */
proto.vehicle_listing_service.VehicleOrder.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle_listing_service.VehicleOrder} returns this
 */
proto.vehicle_listing_service.VehicleOrder.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string vehicle_model_and_trim = 9;
 * @return {string}
 */
proto.vehicle_listing_service.VehicleOrder.prototype.getVehicleModelAndTrim = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle_listing_service.VehicleOrder} returns this
 */
proto.vehicle_listing_service.VehicleOrder.prototype.setVehicleModelAndTrim = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string vehicle_miles = 10;
 * @return {string}
 */
proto.vehicle_listing_service.VehicleOrder.prototype.getVehicleMiles = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle_listing_service.VehicleOrder} returns this
 */
proto.vehicle_listing_service.VehicleOrder.prototype.setVehicleMiles = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string vehicle_color = 11;
 * @return {string}
 */
proto.vehicle_listing_service.VehicleOrder.prototype.getVehicleColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle_listing_service.VehicleOrder} returns this
 */
proto.vehicle_listing_service.VehicleOrder.prototype.setVehicleColor = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional int64 date_listed = 12;
 * @return {number}
 */
proto.vehicle_listing_service.VehicleOrder.prototype.getDateListed = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.vehicle_listing_service.VehicleOrder} returns this
 */
proto.vehicle_listing_service.VehicleOrder.prototype.setDateListed = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional string vehicle_vin = 13;
 * @return {string}
 */
proto.vehicle_listing_service.VehicleOrder.prototype.getVehicleVin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle_listing_service.VehicleOrder} returns this
 */
proto.vehicle_listing_service.VehicleOrder.prototype.setVehicleVin = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional int32 security_deposit = 14;
 * @return {number}
 */
proto.vehicle_listing_service.VehicleOrder.prototype.getSecurityDeposit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.vehicle_listing_service.VehicleOrder} returns this
 */
proto.vehicle_listing_service.VehicleOrder.prototype.setSecurityDeposit = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional int32 start_payment = 15;
 * @return {number}
 */
proto.vehicle_listing_service.VehicleOrder.prototype.getStartPayment = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.vehicle_listing_service.VehicleOrder} returns this
 */
proto.vehicle_listing_service.VehicleOrder.prototype.setStartPayment = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional int32 monthly_payment = 16;
 * @return {number}
 */
proto.vehicle_listing_service.VehicleOrder.prototype.getMonthlyPayment = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.vehicle_listing_service.VehicleOrder} returns this
 */
proto.vehicle_listing_service.VehicleOrder.prototype.setMonthlyPayment = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional int32 reservation_deposit = 17;
 * @return {number}
 */
proto.vehicle_listing_service.VehicleOrder.prototype.getReservationDeposit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.vehicle_listing_service.VehicleOrder} returns this
 */
proto.vehicle_listing_service.VehicleOrder.prototype.setReservationDeposit = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional OrderStatus order_status = 18;
 * @return {!proto.vehicle_listing_service.OrderStatus}
 */
proto.vehicle_listing_service.VehicleOrder.prototype.getOrderStatus = function() {
  return /** @type {!proto.vehicle_listing_service.OrderStatus} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {!proto.vehicle_listing_service.OrderStatus} value
 * @return {!proto.vehicle_listing_service.VehicleOrder} returns this
 */
proto.vehicle_listing_service.VehicleOrder.prototype.setOrderStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 18, value);
};


/**
 * optional string customer_id = 19;
 * @return {string}
 */
proto.vehicle_listing_service.VehicleOrder.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle_listing_service.VehicleOrder} returns this
 */
proto.vehicle_listing_service.VehicleOrder.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vehicle_listing_service.ConfirmVehicleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vehicle_listing_service.ConfirmVehicleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vehicle_listing_service.ConfirmVehicleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle_listing_service.ConfirmVehicleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    vehicleId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vehicle_listing_service.ConfirmVehicleRequest}
 */
proto.vehicle_listing_service.ConfirmVehicleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vehicle_listing_service.ConfirmVehicleRequest;
  return proto.vehicle_listing_service.ConfirmVehicleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vehicle_listing_service.ConfirmVehicleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vehicle_listing_service.ConfirmVehicleRequest}
 */
proto.vehicle_listing_service.ConfirmVehicleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vehicle_listing_service.ConfirmVehicleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vehicle_listing_service.ConfirmVehicleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vehicle_listing_service.ConfirmVehicleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle_listing_service.ConfirmVehicleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.vehicle_listing_service.ConfirmVehicleRequest.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle_listing_service.ConfirmVehicleRequest} returns this
 */
proto.vehicle_listing_service.ConfirmVehicleRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string vehicle_id = 2;
 * @return {string}
 */
proto.vehicle_listing_service.ConfirmVehicleRequest.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle_listing_service.ConfirmVehicleRequest} returns this
 */
proto.vehicle_listing_service.ConfirmVehicleRequest.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vehicle_listing_service.ConfirmVehicleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vehicle_listing_service.ConfirmVehicleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vehicle_listing_service.ConfirmVehicleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle_listing_service.ConfirmVehicleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vehicle_listing_service.ConfirmVehicleResponse}
 */
proto.vehicle_listing_service.ConfirmVehicleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vehicle_listing_service.ConfirmVehicleResponse;
  return proto.vehicle_listing_service.ConfirmVehicleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vehicle_listing_service.ConfirmVehicleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vehicle_listing_service.ConfirmVehicleResponse}
 */
proto.vehicle_listing_service.ConfirmVehicleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vehicle_listing_service.ConfirmVehicleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vehicle_listing_service.ConfirmVehicleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vehicle_listing_service.ConfirmVehicleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle_listing_service.ConfirmVehicleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.vehicle_listing_service.ConfirmVehicleResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vehicle_listing_service.ConfirmVehicleResponse} returns this
 */
proto.vehicle_listing_service.ConfirmVehicleResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vehicle_listing_service.GetVehicleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vehicle_listing_service.GetVehicleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vehicle_listing_service.GetVehicleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle_listing_service.GetVehicleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    customerId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vehicle_listing_service.GetVehicleRequest}
 */
proto.vehicle_listing_service.GetVehicleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vehicle_listing_service.GetVehicleRequest;
  return proto.vehicle_listing_service.GetVehicleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vehicle_listing_service.GetVehicleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vehicle_listing_service.GetVehicleRequest}
 */
proto.vehicle_listing_service.GetVehicleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vehicle_listing_service.GetVehicleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vehicle_listing_service.GetVehicleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vehicle_listing_service.GetVehicleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle_listing_service.GetVehicleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vehicle_listing_service.GetVehicleRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle_listing_service.GetVehicleRequest} returns this
 */
proto.vehicle_listing_service.GetVehicleRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string customer_id = 2;
 * @return {string}
 */
proto.vehicle_listing_service.GetVehicleRequest.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle_listing_service.GetVehicleRequest} returns this
 */
proto.vehicle_listing_service.GetVehicleRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vehicle_listing_service.ListProfilesRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vehicle_listing_service.ListProfilesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vehicle_listing_service.ListProfilesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vehicle_listing_service.ListProfilesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle_listing_service.ListProfilesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    criteriaList: jspb.Message.toObjectList(msg.getCriteriaList(),
    proto.vehicle_listing_service.VehicleCriteria.toObject, includeInstance),
    page: jspb.Message.getFieldWithDefault(msg, 2, 0),
    resultsPerPage: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vehicle_listing_service.ListProfilesRequest}
 */
proto.vehicle_listing_service.ListProfilesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vehicle_listing_service.ListProfilesRequest;
  return proto.vehicle_listing_service.ListProfilesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vehicle_listing_service.ListProfilesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vehicle_listing_service.ListProfilesRequest}
 */
proto.vehicle_listing_service.ListProfilesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vehicle_listing_service.VehicleCriteria;
      reader.readMessage(value,proto.vehicle_listing_service.VehicleCriteria.deserializeBinaryFromReader);
      msg.addCriteria(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setResultsPerPage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vehicle_listing_service.ListProfilesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vehicle_listing_service.ListProfilesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vehicle_listing_service.ListProfilesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle_listing_service.ListProfilesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCriteriaList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.vehicle_listing_service.VehicleCriteria.serializeBinaryToWriter
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getResultsPerPage();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * repeated VehicleCriteria criteria = 1;
 * @return {!Array<!proto.vehicle_listing_service.VehicleCriteria>}
 */
proto.vehicle_listing_service.ListProfilesRequest.prototype.getCriteriaList = function() {
  return /** @type{!Array<!proto.vehicle_listing_service.VehicleCriteria>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vehicle_listing_service.VehicleCriteria, 1));
};


/**
 * @param {!Array<!proto.vehicle_listing_service.VehicleCriteria>} value
 * @return {!proto.vehicle_listing_service.ListProfilesRequest} returns this
*/
proto.vehicle_listing_service.ListProfilesRequest.prototype.setCriteriaList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vehicle_listing_service.VehicleCriteria=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vehicle_listing_service.VehicleCriteria}
 */
proto.vehicle_listing_service.ListProfilesRequest.prototype.addCriteria = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vehicle_listing_service.VehicleCriteria, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vehicle_listing_service.ListProfilesRequest} returns this
 */
proto.vehicle_listing_service.ListProfilesRequest.prototype.clearCriteriaList = function() {
  return this.setCriteriaList([]);
};


/**
 * optional int32 page = 2;
 * @return {number}
 */
proto.vehicle_listing_service.ListProfilesRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vehicle_listing_service.ListProfilesRequest} returns this
 */
proto.vehicle_listing_service.ListProfilesRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 results_per_page = 3;
 * @return {number}
 */
proto.vehicle_listing_service.ListProfilesRequest.prototype.getResultsPerPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vehicle_listing_service.ListProfilesRequest} returns this
 */
proto.vehicle_listing_service.ListProfilesRequest.prototype.setResultsPerPage = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vehicle_listing_service.ListProfilesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vehicle_listing_service.ListProfilesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vehicle_listing_service.ListProfilesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vehicle_listing_service.ListProfilesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle_listing_service.ListProfilesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    profilesList: jspb.Message.toObjectList(msg.getProfilesList(),
    vehicle_pb.Profile.toObject, includeInstance),
    page: jspb.Message.getFieldWithDefault(msg, 2, 0),
    total: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vehicle_listing_service.ListProfilesResponse}
 */
proto.vehicle_listing_service.ListProfilesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vehicle_listing_service.ListProfilesResponse;
  return proto.vehicle_listing_service.ListProfilesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vehicle_listing_service.ListProfilesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vehicle_listing_service.ListProfilesResponse}
 */
proto.vehicle_listing_service.ListProfilesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new vehicle_pb.Profile;
      reader.readMessage(value,vehicle_pb.Profile.deserializeBinaryFromReader);
      msg.addProfiles(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vehicle_listing_service.ListProfilesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vehicle_listing_service.ListProfilesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vehicle_listing_service.ListProfilesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle_listing_service.ListProfilesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProfilesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      vehicle_pb.Profile.serializeBinaryToWriter
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * repeated vehicle.Profile profiles = 1;
 * @return {!Array<!proto.vehicle.Profile>}
 */
proto.vehicle_listing_service.ListProfilesResponse.prototype.getProfilesList = function() {
  return /** @type{!Array<!proto.vehicle.Profile>} */ (
    jspb.Message.getRepeatedWrapperField(this, vehicle_pb.Profile, 1));
};


/**
 * @param {!Array<!proto.vehicle.Profile>} value
 * @return {!proto.vehicle_listing_service.ListProfilesResponse} returns this
*/
proto.vehicle_listing_service.ListProfilesResponse.prototype.setProfilesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vehicle.Profile=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vehicle.Profile}
 */
proto.vehicle_listing_service.ListProfilesResponse.prototype.addProfiles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vehicle.Profile, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vehicle_listing_service.ListProfilesResponse} returns this
 */
proto.vehicle_listing_service.ListProfilesResponse.prototype.clearProfilesList = function() {
  return this.setProfilesList([]);
};


/**
 * optional int32 page = 2;
 * @return {number}
 */
proto.vehicle_listing_service.ListProfilesResponse.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vehicle_listing_service.ListProfilesResponse} returns this
 */
proto.vehicle_listing_service.ListProfilesResponse.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 total = 3;
 * @return {number}
 */
proto.vehicle_listing_service.ListProfilesResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vehicle_listing_service.ListProfilesResponse} returns this
 */
proto.vehicle_listing_service.ListProfilesResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vehicle_listing_service.ListVehiclesRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vehicle_listing_service.ListVehiclesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vehicle_listing_service.ListVehiclesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vehicle_listing_service.ListVehiclesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle_listing_service.ListVehiclesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    criteriaList: jspb.Message.toObjectList(msg.getCriteriaList(),
    proto.vehicle_listing_service.VehicleCriteria.toObject, includeInstance),
    page: jspb.Message.getFieldWithDefault(msg, 2, 0),
    resultsPerPage: jspb.Message.getFieldWithDefault(msg, 3, 0),
    customerId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vehicle_listing_service.ListVehiclesRequest}
 */
proto.vehicle_listing_service.ListVehiclesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vehicle_listing_service.ListVehiclesRequest;
  return proto.vehicle_listing_service.ListVehiclesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vehicle_listing_service.ListVehiclesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vehicle_listing_service.ListVehiclesRequest}
 */
proto.vehicle_listing_service.ListVehiclesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vehicle_listing_service.VehicleCriteria;
      reader.readMessage(value,proto.vehicle_listing_service.VehicleCriteria.deserializeBinaryFromReader);
      msg.addCriteria(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setResultsPerPage(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vehicle_listing_service.ListVehiclesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vehicle_listing_service.ListVehiclesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vehicle_listing_service.ListVehiclesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle_listing_service.ListVehiclesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCriteriaList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.vehicle_listing_service.VehicleCriteria.serializeBinaryToWriter
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getResultsPerPage();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * repeated VehicleCriteria criteria = 1;
 * @return {!Array<!proto.vehicle_listing_service.VehicleCriteria>}
 */
proto.vehicle_listing_service.ListVehiclesRequest.prototype.getCriteriaList = function() {
  return /** @type{!Array<!proto.vehicle_listing_service.VehicleCriteria>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vehicle_listing_service.VehicleCriteria, 1));
};


/**
 * @param {!Array<!proto.vehicle_listing_service.VehicleCriteria>} value
 * @return {!proto.vehicle_listing_service.ListVehiclesRequest} returns this
*/
proto.vehicle_listing_service.ListVehiclesRequest.prototype.setCriteriaList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vehicle_listing_service.VehicleCriteria=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vehicle_listing_service.VehicleCriteria}
 */
proto.vehicle_listing_service.ListVehiclesRequest.prototype.addCriteria = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vehicle_listing_service.VehicleCriteria, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vehicle_listing_service.ListVehiclesRequest} returns this
 */
proto.vehicle_listing_service.ListVehiclesRequest.prototype.clearCriteriaList = function() {
  return this.setCriteriaList([]);
};


/**
 * optional int32 page = 2;
 * @return {number}
 */
proto.vehicle_listing_service.ListVehiclesRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vehicle_listing_service.ListVehiclesRequest} returns this
 */
proto.vehicle_listing_service.ListVehiclesRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 results_per_page = 3;
 * @return {number}
 */
proto.vehicle_listing_service.ListVehiclesRequest.prototype.getResultsPerPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vehicle_listing_service.ListVehiclesRequest} returns this
 */
proto.vehicle_listing_service.ListVehiclesRequest.prototype.setResultsPerPage = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string customer_id = 4;
 * @return {string}
 */
proto.vehicle_listing_service.ListVehiclesRequest.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle_listing_service.ListVehiclesRequest} returns this
 */
proto.vehicle_listing_service.ListVehiclesRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vehicle_listing_service.VehicleCriteria.prototype.toObject = function(opt_includeInstance) {
  return proto.vehicle_listing_service.VehicleCriteria.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vehicle_listing_service.VehicleCriteria} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle_listing_service.VehicleCriteria.toObject = function(includeInstance, msg) {
  var f, obj = {
    property: jspb.Message.getFieldWithDefault(msg, 1, ""),
    query: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vehicle_listing_service.VehicleCriteria}
 */
proto.vehicle_listing_service.VehicleCriteria.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vehicle_listing_service.VehicleCriteria;
  return proto.vehicle_listing_service.VehicleCriteria.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vehicle_listing_service.VehicleCriteria} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vehicle_listing_service.VehicleCriteria}
 */
proto.vehicle_listing_service.VehicleCriteria.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProperty(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuery(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vehicle_listing_service.VehicleCriteria.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vehicle_listing_service.VehicleCriteria.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vehicle_listing_service.VehicleCriteria} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle_listing_service.VehicleCriteria.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProperty();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getQuery();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string property = 1;
 * @return {string}
 */
proto.vehicle_listing_service.VehicleCriteria.prototype.getProperty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle_listing_service.VehicleCriteria} returns this
 */
proto.vehicle_listing_service.VehicleCriteria.prototype.setProperty = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string query = 2;
 * @return {string}
 */
proto.vehicle_listing_service.VehicleCriteria.prototype.getQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle_listing_service.VehicleCriteria} returns this
 */
proto.vehicle_listing_service.VehicleCriteria.prototype.setQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vehicle_listing_service.ListVehiclesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vehicle_listing_service.ListVehiclesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vehicle_listing_service.ListVehiclesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vehicle_listing_service.ListVehiclesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle_listing_service.ListVehiclesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehiclesList: jspb.Message.toObjectList(msg.getVehiclesList(),
    vehicle_pb.Vehicle.toObject, includeInstance),
    total: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vehicle_listing_service.ListVehiclesResponse}
 */
proto.vehicle_listing_service.ListVehiclesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vehicle_listing_service.ListVehiclesResponse;
  return proto.vehicle_listing_service.ListVehiclesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vehicle_listing_service.ListVehiclesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vehicle_listing_service.ListVehiclesResponse}
 */
proto.vehicle_listing_service.ListVehiclesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new vehicle_pb.Vehicle;
      reader.readMessage(value,vehicle_pb.Vehicle.deserializeBinaryFromReader);
      msg.addVehicles(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vehicle_listing_service.ListVehiclesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vehicle_listing_service.ListVehiclesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vehicle_listing_service.ListVehiclesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle_listing_service.ListVehiclesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehiclesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      vehicle_pb.Vehicle.serializeBinaryToWriter
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * repeated vehicle.Vehicle vehicles = 1;
 * @return {!Array<!proto.vehicle.Vehicle>}
 */
proto.vehicle_listing_service.ListVehiclesResponse.prototype.getVehiclesList = function() {
  return /** @type{!Array<!proto.vehicle.Vehicle>} */ (
    jspb.Message.getRepeatedWrapperField(this, vehicle_pb.Vehicle, 1));
};


/**
 * @param {!Array<!proto.vehicle.Vehicle>} value
 * @return {!proto.vehicle_listing_service.ListVehiclesResponse} returns this
*/
proto.vehicle_listing_service.ListVehiclesResponse.prototype.setVehiclesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vehicle.Vehicle=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vehicle.Vehicle}
 */
proto.vehicle_listing_service.ListVehiclesResponse.prototype.addVehicles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vehicle.Vehicle, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vehicle_listing_service.ListVehiclesResponse} returns this
 */
proto.vehicle_listing_service.ListVehiclesResponse.prototype.clearVehiclesList = function() {
  return this.setVehiclesList([]);
};


/**
 * optional int32 total = 2;
 * @return {number}
 */
proto.vehicle_listing_service.ListVehiclesResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vehicle_listing_service.ListVehiclesResponse} returns this
 */
proto.vehicle_listing_service.ListVehiclesResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vehicle_listing_service.UpdateVehicleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vehicle_listing_service.UpdateVehicleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vehicle_listing_service.UpdateVehicleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle_listing_service.UpdateVehicleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicle: (f = msg.getVehicle()) && vehicle_pb.Vehicle.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vehicle_listing_service.UpdateVehicleRequest}
 */
proto.vehicle_listing_service.UpdateVehicleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vehicle_listing_service.UpdateVehicleRequest;
  return proto.vehicle_listing_service.UpdateVehicleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vehicle_listing_service.UpdateVehicleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vehicle_listing_service.UpdateVehicleRequest}
 */
proto.vehicle_listing_service.UpdateVehicleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new vehicle_pb.Vehicle;
      reader.readMessage(value,vehicle_pb.Vehicle.deserializeBinaryFromReader);
      msg.setVehicle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vehicle_listing_service.UpdateVehicleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vehicle_listing_service.UpdateVehicleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vehicle_listing_service.UpdateVehicleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle_listing_service.UpdateVehicleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicle();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      vehicle_pb.Vehicle.serializeBinaryToWriter
    );
  }
};


/**
 * optional vehicle.Vehicle vehicle = 1;
 * @return {?proto.vehicle.Vehicle}
 */
proto.vehicle_listing_service.UpdateVehicleRequest.prototype.getVehicle = function() {
  return /** @type{?proto.vehicle.Vehicle} */ (
    jspb.Message.getWrapperField(this, vehicle_pb.Vehicle, 1));
};


/**
 * @param {?proto.vehicle.Vehicle|undefined} value
 * @return {!proto.vehicle_listing_service.UpdateVehicleRequest} returns this
*/
proto.vehicle_listing_service.UpdateVehicleRequest.prototype.setVehicle = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vehicle_listing_service.UpdateVehicleRequest} returns this
 */
proto.vehicle_listing_service.UpdateVehicleRequest.prototype.clearVehicle = function() {
  return this.setVehicle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vehicle_listing_service.UpdateVehicleRequest.prototype.hasVehicle = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vehicle_listing_service.UpdateVehicleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vehicle_listing_service.UpdateVehicleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vehicle_listing_service.UpdateVehicleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle_listing_service.UpdateVehicleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    vehicle: (f = msg.getVehicle()) && vehicle_pb.Vehicle.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vehicle_listing_service.UpdateVehicleResponse}
 */
proto.vehicle_listing_service.UpdateVehicleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vehicle_listing_service.UpdateVehicleResponse;
  return proto.vehicle_listing_service.UpdateVehicleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vehicle_listing_service.UpdateVehicleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vehicle_listing_service.UpdateVehicleResponse}
 */
proto.vehicle_listing_service.UpdateVehicleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new vehicle_pb.Vehicle;
      reader.readMessage(value,vehicle_pb.Vehicle.deserializeBinaryFromReader);
      msg.setVehicle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vehicle_listing_service.UpdateVehicleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vehicle_listing_service.UpdateVehicleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vehicle_listing_service.UpdateVehicleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle_listing_service.UpdateVehicleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getVehicle();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      vehicle_pb.Vehicle.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.vehicle_listing_service.UpdateVehicleResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vehicle_listing_service.UpdateVehicleResponse} returns this
 */
proto.vehicle_listing_service.UpdateVehicleResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional vehicle.Vehicle vehicle = 2;
 * @return {?proto.vehicle.Vehicle}
 */
proto.vehicle_listing_service.UpdateVehicleResponse.prototype.getVehicle = function() {
  return /** @type{?proto.vehicle.Vehicle} */ (
    jspb.Message.getWrapperField(this, vehicle_pb.Vehicle, 2));
};


/**
 * @param {?proto.vehicle.Vehicle|undefined} value
 * @return {!proto.vehicle_listing_service.UpdateVehicleResponse} returns this
*/
proto.vehicle_listing_service.UpdateVehicleResponse.prototype.setVehicle = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vehicle_listing_service.UpdateVehicleResponse} returns this
 */
proto.vehicle_listing_service.UpdateVehicleResponse.prototype.clearVehicle = function() {
  return this.setVehicle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vehicle_listing_service.UpdateVehicleResponse.prototype.hasVehicle = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vehicle_listing_service.ListDealershipsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vehicle_listing_service.ListDealershipsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vehicle_listing_service.ListDealershipsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vehicle_listing_service.ListDealershipsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle_listing_service.ListDealershipsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    criteriaList: jspb.Message.toObjectList(msg.getCriteriaList(),
    proto.vehicle_listing_service.DealershipCriteria.toObject, includeInstance),
    page: jspb.Message.getFieldWithDefault(msg, 2, 0),
    resultsPerPage: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vehicle_listing_service.ListDealershipsRequest}
 */
proto.vehicle_listing_service.ListDealershipsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vehicle_listing_service.ListDealershipsRequest;
  return proto.vehicle_listing_service.ListDealershipsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vehicle_listing_service.ListDealershipsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vehicle_listing_service.ListDealershipsRequest}
 */
proto.vehicle_listing_service.ListDealershipsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vehicle_listing_service.DealershipCriteria;
      reader.readMessage(value,proto.vehicle_listing_service.DealershipCriteria.deserializeBinaryFromReader);
      msg.addCriteria(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setResultsPerPage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vehicle_listing_service.ListDealershipsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vehicle_listing_service.ListDealershipsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vehicle_listing_service.ListDealershipsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle_listing_service.ListDealershipsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCriteriaList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.vehicle_listing_service.DealershipCriteria.serializeBinaryToWriter
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getResultsPerPage();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * repeated DealershipCriteria criteria = 1;
 * @return {!Array<!proto.vehicle_listing_service.DealershipCriteria>}
 */
proto.vehicle_listing_service.ListDealershipsRequest.prototype.getCriteriaList = function() {
  return /** @type{!Array<!proto.vehicle_listing_service.DealershipCriteria>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vehicle_listing_service.DealershipCriteria, 1));
};


/**
 * @param {!Array<!proto.vehicle_listing_service.DealershipCriteria>} value
 * @return {!proto.vehicle_listing_service.ListDealershipsRequest} returns this
*/
proto.vehicle_listing_service.ListDealershipsRequest.prototype.setCriteriaList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vehicle_listing_service.DealershipCriteria=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vehicle_listing_service.DealershipCriteria}
 */
proto.vehicle_listing_service.ListDealershipsRequest.prototype.addCriteria = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vehicle_listing_service.DealershipCriteria, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vehicle_listing_service.ListDealershipsRequest} returns this
 */
proto.vehicle_listing_service.ListDealershipsRequest.prototype.clearCriteriaList = function() {
  return this.setCriteriaList([]);
};


/**
 * optional int32 page = 2;
 * @return {number}
 */
proto.vehicle_listing_service.ListDealershipsRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vehicle_listing_service.ListDealershipsRequest} returns this
 */
proto.vehicle_listing_service.ListDealershipsRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 results_per_page = 3;
 * @return {number}
 */
proto.vehicle_listing_service.ListDealershipsRequest.prototype.getResultsPerPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vehicle_listing_service.ListDealershipsRequest} returns this
 */
proto.vehicle_listing_service.ListDealershipsRequest.prototype.setResultsPerPage = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vehicle_listing_service.ListDealershipsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vehicle_listing_service.ListDealershipsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vehicle_listing_service.ListDealershipsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vehicle_listing_service.ListDealershipsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle_listing_service.ListDealershipsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealershipsList: jspb.Message.toObjectList(msg.getDealershipsList(),
    domain_pb.Dealership.toObject, includeInstance),
    total: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vehicle_listing_service.ListDealershipsResponse}
 */
proto.vehicle_listing_service.ListDealershipsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vehicle_listing_service.ListDealershipsResponse;
  return proto.vehicle_listing_service.ListDealershipsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vehicle_listing_service.ListDealershipsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vehicle_listing_service.ListDealershipsResponse}
 */
proto.vehicle_listing_service.ListDealershipsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new domain_pb.Dealership;
      reader.readMessage(value,domain_pb.Dealership.deserializeBinaryFromReader);
      msg.addDealerships(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vehicle_listing_service.ListDealershipsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vehicle_listing_service.ListDealershipsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vehicle_listing_service.ListDealershipsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle_listing_service.ListDealershipsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealershipsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      domain_pb.Dealership.serializeBinaryToWriter
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * repeated domain.Dealership dealerships = 1;
 * @return {!Array<!proto.domain.Dealership>}
 */
proto.vehicle_listing_service.ListDealershipsResponse.prototype.getDealershipsList = function() {
  return /** @type{!Array<!proto.domain.Dealership>} */ (
    jspb.Message.getRepeatedWrapperField(this, domain_pb.Dealership, 1));
};


/**
 * @param {!Array<!proto.domain.Dealership>} value
 * @return {!proto.vehicle_listing_service.ListDealershipsResponse} returns this
*/
proto.vehicle_listing_service.ListDealershipsResponse.prototype.setDealershipsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.domain.Dealership=} opt_value
 * @param {number=} opt_index
 * @return {!proto.domain.Dealership}
 */
proto.vehicle_listing_service.ListDealershipsResponse.prototype.addDealerships = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.domain.Dealership, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vehicle_listing_service.ListDealershipsResponse} returns this
 */
proto.vehicle_listing_service.ListDealershipsResponse.prototype.clearDealershipsList = function() {
  return this.setDealershipsList([]);
};


/**
 * optional int32 total = 2;
 * @return {number}
 */
proto.vehicle_listing_service.ListDealershipsResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vehicle_listing_service.ListDealershipsResponse} returns this
 */
proto.vehicle_listing_service.ListDealershipsResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vehicle_listing_service.GetDealershipRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vehicle_listing_service.GetDealershipRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vehicle_listing_service.GetDealershipRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle_listing_service.GetDealershipRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vehicle_listing_service.GetDealershipRequest}
 */
proto.vehicle_listing_service.GetDealershipRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vehicle_listing_service.GetDealershipRequest;
  return proto.vehicle_listing_service.GetDealershipRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vehicle_listing_service.GetDealershipRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vehicle_listing_service.GetDealershipRequest}
 */
proto.vehicle_listing_service.GetDealershipRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vehicle_listing_service.GetDealershipRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vehicle_listing_service.GetDealershipRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vehicle_listing_service.GetDealershipRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle_listing_service.GetDealershipRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vehicle_listing_service.GetDealershipRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle_listing_service.GetDealershipRequest} returns this
 */
proto.vehicle_listing_service.GetDealershipRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vehicle_listing_service.GetDealershipResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vehicle_listing_service.GetDealershipResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vehicle_listing_service.GetDealershipResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle_listing_service.GetDealershipResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealership: (f = msg.getDealership()) && domain_pb.Dealership.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vehicle_listing_service.GetDealershipResponse}
 */
proto.vehicle_listing_service.GetDealershipResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vehicle_listing_service.GetDealershipResponse;
  return proto.vehicle_listing_service.GetDealershipResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vehicle_listing_service.GetDealershipResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vehicle_listing_service.GetDealershipResponse}
 */
proto.vehicle_listing_service.GetDealershipResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new domain_pb.Dealership;
      reader.readMessage(value,domain_pb.Dealership.deserializeBinaryFromReader);
      msg.setDealership(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vehicle_listing_service.GetDealershipResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vehicle_listing_service.GetDealershipResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vehicle_listing_service.GetDealershipResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle_listing_service.GetDealershipResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealership();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      domain_pb.Dealership.serializeBinaryToWriter
    );
  }
};


/**
 * optional domain.Dealership dealership = 1;
 * @return {?proto.domain.Dealership}
 */
proto.vehicle_listing_service.GetDealershipResponse.prototype.getDealership = function() {
  return /** @type{?proto.domain.Dealership} */ (
    jspb.Message.getWrapperField(this, domain_pb.Dealership, 1));
};


/**
 * @param {?proto.domain.Dealership|undefined} value
 * @return {!proto.vehicle_listing_service.GetDealershipResponse} returns this
*/
proto.vehicle_listing_service.GetDealershipResponse.prototype.setDealership = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vehicle_listing_service.GetDealershipResponse} returns this
 */
proto.vehicle_listing_service.GetDealershipResponse.prototype.clearDealership = function() {
  return this.setDealership(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vehicle_listing_service.GetDealershipResponse.prototype.hasDealership = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vehicle_listing_service.DealershipCriteria.prototype.toObject = function(opt_includeInstance) {
  return proto.vehicle_listing_service.DealershipCriteria.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vehicle_listing_service.DealershipCriteria} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle_listing_service.DealershipCriteria.toObject = function(includeInstance, msg) {
  var f, obj = {
    property: jspb.Message.getFieldWithDefault(msg, 1, ""),
    query: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vehicle_listing_service.DealershipCriteria}
 */
proto.vehicle_listing_service.DealershipCriteria.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vehicle_listing_service.DealershipCriteria;
  return proto.vehicle_listing_service.DealershipCriteria.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vehicle_listing_service.DealershipCriteria} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vehicle_listing_service.DealershipCriteria}
 */
proto.vehicle_listing_service.DealershipCriteria.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProperty(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuery(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vehicle_listing_service.DealershipCriteria.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vehicle_listing_service.DealershipCriteria.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vehicle_listing_service.DealershipCriteria} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle_listing_service.DealershipCriteria.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProperty();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getQuery();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string property = 1;
 * @return {string}
 */
proto.vehicle_listing_service.DealershipCriteria.prototype.getProperty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle_listing_service.DealershipCriteria} returns this
 */
proto.vehicle_listing_service.DealershipCriteria.prototype.setProperty = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string query = 2;
 * @return {string}
 */
proto.vehicle_listing_service.DealershipCriteria.prototype.getQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle_listing_service.DealershipCriteria} returns this
 */
proto.vehicle_listing_service.DealershipCriteria.prototype.setQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vehicle_listing_service.StartVehicleInspectionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vehicle_listing_service.StartVehicleInspectionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vehicle_listing_service.StartVehicleInspectionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle_listing_service.StartVehicleInspectionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vehicle_listing_service.StartVehicleInspectionRequest}
 */
proto.vehicle_listing_service.StartVehicleInspectionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vehicle_listing_service.StartVehicleInspectionRequest;
  return proto.vehicle_listing_service.StartVehicleInspectionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vehicle_listing_service.StartVehicleInspectionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vehicle_listing_service.StartVehicleInspectionRequest}
 */
proto.vehicle_listing_service.StartVehicleInspectionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vehicle_listing_service.StartVehicleInspectionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vehicle_listing_service.StartVehicleInspectionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vehicle_listing_service.StartVehicleInspectionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle_listing_service.StartVehicleInspectionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vehicle_listing_service.StartVehicleInspectionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vehicle_listing_service.StartVehicleInspectionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vehicle_listing_service.StartVehicleInspectionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle_listing_service.StartVehicleInspectionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionKey: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vehicle_listing_service.StartVehicleInspectionResponse}
 */
proto.vehicle_listing_service.StartVehicleInspectionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vehicle_listing_service.StartVehicleInspectionResponse;
  return proto.vehicle_listing_service.StartVehicleInspectionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vehicle_listing_service.StartVehicleInspectionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vehicle_listing_service.StartVehicleInspectionResponse}
 */
proto.vehicle_listing_service.StartVehicleInspectionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vehicle_listing_service.StartVehicleInspectionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vehicle_listing_service.StartVehicleInspectionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vehicle_listing_service.StartVehicleInspectionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle_listing_service.StartVehicleInspectionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string session_key = 1;
 * @return {string}
 */
proto.vehicle_listing_service.StartVehicleInspectionResponse.prototype.getSessionKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle_listing_service.StartVehicleInspectionResponse} returns this
 */
proto.vehicle_listing_service.StartVehicleInspectionResponse.prototype.setSessionKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vehicle_listing_service.GetVehicleInspectionResultsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vehicle_listing_service.GetVehicleInspectionResultsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vehicle_listing_service.GetVehicleInspectionResultsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle_listing_service.GetVehicleInspectionResultsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    jwt: jspb.Message.getFieldWithDefault(msg, 1, ""),
    request: (f = msg.getRequest()) && inspection_pb.GetInspectionResultsRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vehicle_listing_service.GetVehicleInspectionResultsRequest}
 */
proto.vehicle_listing_service.GetVehicleInspectionResultsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vehicle_listing_service.GetVehicleInspectionResultsRequest;
  return proto.vehicle_listing_service.GetVehicleInspectionResultsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vehicle_listing_service.GetVehicleInspectionResultsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vehicle_listing_service.GetVehicleInspectionResultsRequest}
 */
proto.vehicle_listing_service.GetVehicleInspectionResultsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setJwt(value);
      break;
    case 2:
      var value = new inspection_pb.GetInspectionResultsRequest;
      reader.readMessage(value,inspection_pb.GetInspectionResultsRequest.deserializeBinaryFromReader);
      msg.setRequest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vehicle_listing_service.GetVehicleInspectionResultsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vehicle_listing_service.GetVehicleInspectionResultsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vehicle_listing_service.GetVehicleInspectionResultsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle_listing_service.GetVehicleInspectionResultsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getJwt();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRequest();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      inspection_pb.GetInspectionResultsRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional string jwt = 1;
 * @return {string}
 */
proto.vehicle_listing_service.GetVehicleInspectionResultsRequest.prototype.getJwt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle_listing_service.GetVehicleInspectionResultsRequest} returns this
 */
proto.vehicle_listing_service.GetVehicleInspectionResultsRequest.prototype.setJwt = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional inspection.GetInspectionResultsRequest request = 2;
 * @return {?proto.inspection.GetInspectionResultsRequest}
 */
proto.vehicle_listing_service.GetVehicleInspectionResultsRequest.prototype.getRequest = function() {
  return /** @type{?proto.inspection.GetInspectionResultsRequest} */ (
    jspb.Message.getWrapperField(this, inspection_pb.GetInspectionResultsRequest, 2));
};


/**
 * @param {?proto.inspection.GetInspectionResultsRequest|undefined} value
 * @return {!proto.vehicle_listing_service.GetVehicleInspectionResultsRequest} returns this
*/
proto.vehicle_listing_service.GetVehicleInspectionResultsRequest.prototype.setRequest = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vehicle_listing_service.GetVehicleInspectionResultsRequest} returns this
 */
proto.vehicle_listing_service.GetVehicleInspectionResultsRequest.prototype.clearRequest = function() {
  return this.setRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vehicle_listing_service.GetVehicleInspectionResultsRequest.prototype.hasRequest = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vehicle_listing_service.GetAvailableModelsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vehicle_listing_service.GetAvailableModelsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vehicle_listing_service.GetAvailableModelsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle_listing_service.GetAvailableModelsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    make: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vehicle_listing_service.GetAvailableModelsRequest}
 */
proto.vehicle_listing_service.GetAvailableModelsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vehicle_listing_service.GetAvailableModelsRequest;
  return proto.vehicle_listing_service.GetAvailableModelsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vehicle_listing_service.GetAvailableModelsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vehicle_listing_service.GetAvailableModelsRequest}
 */
proto.vehicle_listing_service.GetAvailableModelsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMake(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vehicle_listing_service.GetAvailableModelsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vehicle_listing_service.GetAvailableModelsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vehicle_listing_service.GetAvailableModelsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle_listing_service.GetAvailableModelsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMake();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string make = 1;
 * @return {string}
 */
proto.vehicle_listing_service.GetAvailableModelsRequest.prototype.getMake = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle_listing_service.GetAvailableModelsRequest} returns this
 */
proto.vehicle_listing_service.GetAvailableModelsRequest.prototype.setMake = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vehicle_listing_service.GetAvailableModelsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vehicle_listing_service.GetAvailableModelsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vehicle_listing_service.GetAvailableModelsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vehicle_listing_service.GetAvailableModelsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle_listing_service.GetAvailableModelsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    modelsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vehicle_listing_service.GetAvailableModelsResponse}
 */
proto.vehicle_listing_service.GetAvailableModelsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vehicle_listing_service.GetAvailableModelsResponse;
  return proto.vehicle_listing_service.GetAvailableModelsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vehicle_listing_service.GetAvailableModelsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vehicle_listing_service.GetAvailableModelsResponse}
 */
proto.vehicle_listing_service.GetAvailableModelsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addModels(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vehicle_listing_service.GetAvailableModelsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vehicle_listing_service.GetAvailableModelsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vehicle_listing_service.GetAvailableModelsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle_listing_service.GetAvailableModelsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModelsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string models = 1;
 * @return {!Array<string>}
 */
proto.vehicle_listing_service.GetAvailableModelsResponse.prototype.getModelsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vehicle_listing_service.GetAvailableModelsResponse} returns this
 */
proto.vehicle_listing_service.GetAvailableModelsResponse.prototype.setModelsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vehicle_listing_service.GetAvailableModelsResponse} returns this
 */
proto.vehicle_listing_service.GetAvailableModelsResponse.prototype.addModels = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vehicle_listing_service.GetAvailableModelsResponse} returns this
 */
proto.vehicle_listing_service.GetAvailableModelsResponse.prototype.clearModelsList = function() {
  return this.setModelsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vehicle_listing_service.GetAvailableTrimsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vehicle_listing_service.GetAvailableTrimsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vehicle_listing_service.GetAvailableTrimsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle_listing_service.GetAvailableTrimsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    make: jspb.Message.getFieldWithDefault(msg, 1, ""),
    model: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vehicle_listing_service.GetAvailableTrimsRequest}
 */
proto.vehicle_listing_service.GetAvailableTrimsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vehicle_listing_service.GetAvailableTrimsRequest;
  return proto.vehicle_listing_service.GetAvailableTrimsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vehicle_listing_service.GetAvailableTrimsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vehicle_listing_service.GetAvailableTrimsRequest}
 */
proto.vehicle_listing_service.GetAvailableTrimsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMake(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setModel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vehicle_listing_service.GetAvailableTrimsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vehicle_listing_service.GetAvailableTrimsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vehicle_listing_service.GetAvailableTrimsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle_listing_service.GetAvailableTrimsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMake();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getModel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string make = 1;
 * @return {string}
 */
proto.vehicle_listing_service.GetAvailableTrimsRequest.prototype.getMake = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle_listing_service.GetAvailableTrimsRequest} returns this
 */
proto.vehicle_listing_service.GetAvailableTrimsRequest.prototype.setMake = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string model = 2;
 * @return {string}
 */
proto.vehicle_listing_service.GetAvailableTrimsRequest.prototype.getModel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vehicle_listing_service.GetAvailableTrimsRequest} returns this
 */
proto.vehicle_listing_service.GetAvailableTrimsRequest.prototype.setModel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vehicle_listing_service.GetAvailableTrimsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vehicle_listing_service.GetAvailableTrimsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vehicle_listing_service.GetAvailableTrimsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vehicle_listing_service.GetAvailableTrimsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle_listing_service.GetAvailableTrimsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    trimsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vehicle_listing_service.GetAvailableTrimsResponse}
 */
proto.vehicle_listing_service.GetAvailableTrimsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vehicle_listing_service.GetAvailableTrimsResponse;
  return proto.vehicle_listing_service.GetAvailableTrimsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vehicle_listing_service.GetAvailableTrimsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vehicle_listing_service.GetAvailableTrimsResponse}
 */
proto.vehicle_listing_service.GetAvailableTrimsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addTrims(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vehicle_listing_service.GetAvailableTrimsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vehicle_listing_service.GetAvailableTrimsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vehicle_listing_service.GetAvailableTrimsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vehicle_listing_service.GetAvailableTrimsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTrimsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string trims = 1;
 * @return {!Array<string>}
 */
proto.vehicle_listing_service.GetAvailableTrimsResponse.prototype.getTrimsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vehicle_listing_service.GetAvailableTrimsResponse} returns this
 */
proto.vehicle_listing_service.GetAvailableTrimsResponse.prototype.setTrimsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vehicle_listing_service.GetAvailableTrimsResponse} returns this
 */
proto.vehicle_listing_service.GetAvailableTrimsResponse.prototype.addTrims = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vehicle_listing_service.GetAvailableTrimsResponse} returns this
 */
proto.vehicle_listing_service.GetAvailableTrimsResponse.prototype.clearTrimsList = function() {
  return this.setTrimsList([]);
};


/**
 * @enum {number}
 */
proto.vehicle_listing_service.OrderStatus = {
  ACCOUNT_CREATED: 0,
  PENDING: 1,
  QUALIFIED: 2,
  VEHICLE: 3,
  REJECTED: 4,
  TESLA_WAITLIST: 5,
  VEHICLE_SELECTED: 6,
  DEPOSIT_PAID: 7,
  PICKUP_SCHEDULED: 8,
  CANCELLED: 9
};

goog.object.extend(exports, proto.vehicle_listing_service);
