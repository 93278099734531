import React from 'react'
import {Box, Button, CircularProgress, Grid, Typography} from '@mui/material'
import {Section} from 'shared/Section'
import format from 'date-fns/format'
import {
  getFormattedTimeZoneAbbreviation,
  getFormattedEventTimeSlot,
  IAppointmentListItem,
} from '../../utils'
import {ExistingAppointmentModal} from '../../appointment-modals/existing-appointment-modal/ExistingAppointmentModal'
import {useAppointmentListItem} from './useAppointmentListItem'

interface AppointmentListItemProps {
  isLoading: boolean
  appointment: IAppointmentListItem
  displayCustomerDetails?: boolean
}

/*
* An appointment list item to be used on the appointment list overview on
* the reservation or tasks pages. This is > not < used inside the appointment
* modals that allow to view, edit or create appointments.
* */
export const AppointmentListItem: React.FC<AppointmentListItemProps> = ({
  isLoading,
  appointment,
  displayCustomerDetails,
}) => {
  const {
    selectedAppointment,
    setSelectedAppointment,
    handleClose,
  } = useAppointmentListItem()

  return (
    <Box>
      {isLoading ? (
        <Box sx={{ width: '100%', textAlign: 'center' }}>
          <CircularProgress/>
        </Box>
      ) : (
        <Box sx={{marginBottom: '30px'}}>
          <Section subSection>
            <Grid container direction="row" justifyContent="space-around">
              {displayCustomerDetails &&
                <>
                  <Grid item>
                    <Grid container item direction="column">
                      <Typography variant="caption">Customer name</Typography>
                      <Typography>{appointment?.customerName || 'N/A'}</Typography>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container item direction="column">
                      <Typography variant="caption">Vin #</Typography>
                      <Typography>{appointment?.vin || 'N/A'}</Typography>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container item direction="column">
                      <Typography variant="caption">Account #</Typography>
                      <Typography>{appointment?.customerAccountNumber || 'N/A'}</Typography>
                    </Grid>
                  </Grid>
                </>
              }
              <Grid item>
                <Grid container item direction="column">
                  <Typography variant="caption">Event location</Typography>
                  <Typography>{appointment?.location || 'N/A'}</Typography>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container item direction="column">
                  <Typography variant="caption">Event type</Typography>
                  <Typography>{appointment?.typeLabel || 'N/A'}</Typography>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container item direction="column">
                  <Typography variant="caption">Event date</Typography>
                  <Typography>{format(appointment?.date, 'MM/dd/yyyy') || 'N/A'}</Typography>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container item direction="column">
                  <Typography variant="caption">
                    Event time {getFormattedTimeZoneAbbreviation(appointment?.timeZone)}
                  </Typography>
                  <Typography>
                    {appointment?.startTime ? getFormattedEventTimeSlot(appointment?.startTime, appointment?.timeZone, 3600000/2) : 'N/A'}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container item direction="column">
                  <Typography variant="caption">Assignee</Typography>
                  <Typography>{appointment?.assigneeName || 'N/A'}</Typography>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container item direction="column">
                  <Typography variant="caption">Status</Typography>
                  <Typography className={appointment?.statusLabel === 'Cancelled' ? 'secondaryAccent' : ''}>{appointment?.statusLabel || 'N/A'}</Typography>
                </Grid>
              </Grid>
              <Grid item justifyContent="center" alignSelf="center">
                <Grid container item direction="column">
                  <Button variant="text" onClick={() => setSelectedAppointment(appointment)}>
                    <Typography className="primaryAccent bold">View</Typography>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Section>

          <ExistingAppointmentModal
            selectedAppointment={selectedAppointment}
            modalIsOpen={!!selectedAppointment}
            handleClose={handleClose}
            customerId={selectedAppointment?.customerId}
            subscriptionId={selectedAppointment?.subscriptionId}
          />
        </Box>
      )}
    </Box>
  )
}

