import React from 'react'
import { DataGrid, GridColumns } from '@mui/x-data-grid'
import {
  Box,
  Typography,
} from '@mui/material'
import { Link } from 'react-router-dom'
import { Document } from '../useVehicleDetail'

interface DocumentBlockProps {
  documents: Document[]
  isLoading: boolean
  children: React.ReactNode
}

export const DocumentBlock: React.FC<DocumentBlockProps> = ({
  documents,
  isLoading,
  children
}) => {

  const columns: GridColumns = [
    {
      field: 'uploadDate',
      headerName: 'Date Uploaded',
      flex: 0.2,
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      renderCell: ( props ) => (
        <Link className='documentLink' to={`/files/${props.row.url}`}>
          <Typography>{props.row.name}</Typography>
        </Link>
      )
    }
  ]

  return (
    <Box sx={{display: 'flex', flexDirection: 'column'}}>
      <DataGrid
        autoHeight
        columns={columns}
        rows={documents}
        loading={isLoading}
      />
      <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
        { children }
      </Box>
    </Box>
  )
}
