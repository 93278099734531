import React from 'react'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { AVAILABLE_MAKES } from './useVehiclesTable'
import { upperFirst } from 'lodash'

const useStyles = makeStyles({
  formControl: {
    marginLeft: 'auto',
  },
})

interface VehicleMakeSelectFieldProps {
  onChange: (selectedValue: string) => void
  value: string
}

export default function VehicleMakeSelectField({
  onChange,
  value,
}: VehicleMakeSelectFieldProps) {
  const classes = useStyles()

  return (
    <FormControl className={classes.formControl}>
      <InputLabel htmlFor="make-select">Make</InputLabel>
      <Select
        id="make-select"
        label="Make"
        onChange={(ev) => onChange(ev.target.value)}
        value={value}
      >
        {AVAILABLE_MAKES.map((make) => (
          <MenuItem key={make} value={make}>
            {upperFirst(make)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
