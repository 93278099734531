import { useMutation, useQuery, useQueryClient } from 'react-query'
import { NexusService } from 'global-apis/nexus-service'
import { useEffect, useState } from 'react'
import { IAppointmentListItem } from '../../utils'
import { AppointmentStatus } from '@nxcr-org/web-api-interface/lib/appointment_pb'
import { GetBillingStatusResponse } from '@nxcr-org/web-api-interface/lib/billing_service_pb'

export const useExistingAppointmentModal = (
  handleClose,
  selectedAppointment
) => {
  const queryClient = useQueryClient()
  const [isEditing, setIsEditing] = useState(false)
  const [appointment, setAppointment] = useState<IAppointmentListItem>()
  const [displayCancellationConfirmation, setDisplayCancellationConfirmation] =
    useState(false)

  useEffect(() => {
    setAppointment(selectedAppointment)
  }, [selectedAppointment])

  const isAppointmentCancelled = AppointmentStatus.CANCELLED === appointment?.status

  const updateAppointment = useMutation(
    () => {
      return NexusService.updateAppointment(appointment)
    },
    {
      onSuccess(data) {
        setIsEditing(false)
        setAppointment({
          ...appointment,
          id: data?.appointmentId,
        })
        invalidateAllQueries()
      },
      // when a double booking response comes back, an error is thrown
      // but the appointment is still created. We must invalidate the queries
      // here as well to have proper refreshed data
      onError() {
        invalidateAllQueries()
      },
    }
  )

  const cancelAppointment = useMutation(
    (id: string) => {
      return NexusService.cancelAppointment(id)
    },
    {
      onSuccess() {
        invalidateAllQueries()
      },
    }
  )

  function invalidateAllQueries() {
    queryClient.invalidateQueries([
      '/listAppointments',
      '/listAppointmentsByAccountNumber',
    ])
    queryClient.invalidateQueries([
      '/listAppointments',
      '/listAppointmentsByVin',
    ])
  }

  function closeModal() {
    setIsEditing(false)
    setDisplayCancellationConfirmation(false)
    updateAppointment.reset()
    handleClose()
  }

  function cancelChanges() {
    setAppointment(selectedAppointment)
    setIsEditing(false)
  }

  return {
    isEditing,
    updateAppointment,
    appointment,
    cancelAppointment,
    displayCancellationConfirmation,
    setDisplayCancellationConfirmation,
    setAppointment,
    setIsEditing,
    closeModal,
    cancelChanges,
    isAppointmentCancelled,
  }
}

export const useBillingStatusDriveOffPaid = ({ subscriptionId }) => {
  const {
    data: billingStatus,
    error,
  } = useQuery(
    ['/billingStatusDriveOffPaid', { subscriptionId }],
    () => {
      return NexusService.getBillingStatus(subscriptionId)
        .catch((err) => {
          console.error('getBillingStatus error', err)
          return new GetBillingStatusResponse().toObject()
        }).then((billingStatusResult) => {
          return {
            driveOffPaid: billingStatusResult.driveOffPaid,
          }
        })
    },
    {
      enabled: !!subscriptionId,
      placeholderData: {
        driveOffPaid: false,
      },
      onError() {
        console.log('useBillingStatusDriveOffPaid Error: ', error)
      },
    }
  )

  return {
    billingStatus,
  }
}
