import React, { ReactNode } from 'react'
import Modal from '@mui/material/Modal'
import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material'
import { Section } from 'shared/Section'
import { Article } from 'shared/Article'
import { useCreateAppointmentModal } from './useCreateAppointmentModal'
import { SearchBar } from './search-bar/SearchBar'
import { ReservationListItem } from '@nxcr-org/web-api-interface/lib/subscription_service_pb'
import { AppointmentModalBody } from '../AppointmentModalBody'
import { autonomyColor } from '../../../../../global-styles/autonomyColor'
import { NexusService } from 'global-apis/nexus-service'

interface CreateAppointmentModalProps {
  modalIsOpen: boolean
  handleClose: () => void
  onReservationPage?: boolean
  reservation?: any
}

export const CreateAppointmentModal: React.FC<CreateAppointmentModalProps> = ({
  modalIsOpen,
  handleClose,
  onReservationPage,
  reservation,
}) => {
  const {
    isEditing,
    appointment,
    createAppointment,
    setAppointment,
    buildAppointment,
    closeModal,
  } = useCreateAppointmentModal(handleClose, onReservationPage, reservation)

  const getCustomerSubscription = async (customerId: string) => {
    try {
      const subscriptionsList = (
        await NexusService.listCustomerSubscriptions(customerId)
      ).subscriptionsList

      if (!subscriptionsList || subscriptionsList.length === 0) {
        throw new Error('No subscriptions found for customer')
      }

      const activeSubscription = subscriptionsList.find(
        (subscription) => subscription.status === 'ACTIVE'
      )

      return activeSubscription || subscriptionsList[0]
    } catch (error) {
      console.error('Error fetching customer subscription:', error)
      throw error
    }
  }

  const createAppointmentRequest = async () => {
    if (!appointment.customerId) {
      throw new Error('Customer ID is required')
    }

    try {
      const subscription = await getCustomerSubscription(appointment.customerId)
      createAppointment.mutate(subscription.id)
    } catch (error) {
      console.error('Error creating appointment:', error)
      throw error
    }
  }
  function getHeaderButtons(): ReactNode {
    return (
      <Grid item>
        <Grid container columnGap={2}>
          <Grid item>
            <Button variant="outlined" color="primary" onClick={closeModal}>
              {isEditing ? 'Cancel' : 'Close'}
            </Button>
          </Grid>

          {isEditing && (
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                disabled={!appointment || !appointment.startTime}
                onClick={createAppointmentRequest}
              >
                Save
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    )
  }

  return (
    <Modal
      open={modalIsOpen}
      onClose={closeModal}
      sx={{ backgroundColor: autonomyColor.darkBlue }}
      className="modal-xl"
      aria-labelledby="appointment-modal"
      aria-describedby="create-appointment-modal"
    >
      <>
        <Article
          heading="Add New Appointment"
          headerHasBottomBorder={true}
          actionBlock={getHeaderButtons()}
        >
          <Section wrapperSection>
            {!appointment ? (
              <Section subSection>
                <Grid container direction="column" rowSpacing={4}>
                  <Grid item>
                    <Typography>
                      Please search for an Account number to associate with this
                      appointment
                    </Typography>
                  </Grid>
                  <Grid item>
                    <SearchBar
                      onOptionSelect={({
                        reservationId,
                        customerId,
                        firstName,
                        lastName,
                        accountNumber,
                        vin,
                        color,
                        model,
                      }: ReservationListItem.AsObject) => {
                        buildAppointment(
                          reservationId,
                          customerId,
                          firstName,
                          lastName,
                          accountNumber,
                          vin,
                          color,
                          model
                        )
                      }}
                    />
                  </Grid>
                </Grid>
              </Section>
            ) : (
              <>
                {createAppointment.isLoading ? (
                  <Box sx={{ width: '100%', textAlign: 'center' }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <>
                    {createAppointment.isSuccess && (
                      <Typography className="success">
                        {`Appointment ${createAppointment?.data?.appointmentId} created successfully `}
                      </Typography>
                    )}
                    {createAppointment.isError && (
                      <Typography className="error">
                        {createAppointment?.error?.message}
                      </Typography>
                    )}
                    <AppointmentModalBody
                      appointment={appointment}
                      isEditing={isEditing}
                      setAppointment={setAppointment}
                    />
                  </>
                )}
              </>
            )}
          </Section>
        </Article>
      </>
    </Modal>
  )
}
