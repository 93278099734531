import React from 'react'
import { Button } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import BackButtonArrow from '../assets/back-button-arrow'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles()(() => {
  return {
    backButton: {
      color: 'white',
      border: 'none',
      marginTop: 80,
      marginBottom: 25,
      '&:hover': {
        border: 'none',
      },
      '& .MuiButton-startIcon': {
        marginBottom: 5,
      },
    },
  }
})

type BackButtonProps = {
  lastPageRoute?: string
}

export const BackButton = ({ lastPageRoute }: BackButtonProps) => {
  const { classes } = useStyles()
  const history = useHistory()

  return (
    <Button
      className={classes.backButton}
      onClick={lastPageRoute ? () => history.push(`${lastPageRoute}`) : () => history.goBack()}
      variant="outlined"
      startIcon={<BackButtonArrow />}
    >
      Back
    </Button>
  )
}
