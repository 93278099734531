import {
  NexusServicePromiseClient,
} from '@nxcr-org/web-api-interface/lib/nexus_service_grpc_web_pb'
import { oktaAuth, OktaAuthInterceptor } from '../../../okta/config'
import { Env } from './env'
import {
  AddVehicleAssignmentRequest,
  RemoveVehicleAssignmentRequest,
} from '@nxcr-org/web-api-interface/lib/subscription_service_pb'

function nexusClient(): NexusServicePromiseClient {
  const idToken = oktaAuth.getIdToken()
  const target = Env.endpoints.fleet

  return new NexusServicePromiseClient(target, null, {
    unaryInterceptors: [new OktaAuthInterceptor(idToken)],
    'grpc-node.max_session_memory': 1000,
    'grpc-node.max_send_message_length': 1024 * 1024 * 100,
    'grpc-node.max_receive_message_length': 1024 * 1024 * 100,
  })
}

async function addVehicleAssignment(
  params: AddVehicleAssignmentRequest.AsObject
) {
  const client = nexusClient()
  const request = new AddVehicleAssignmentRequest()

  request.setSubscriptionId(params.subscriptionId)
  request.setVehicleId(params.vehicleId)

  return client.addVehicleAssignment(request)
}

async function removeVehicleAssignment(
  params: RemoveVehicleAssignmentRequest.AsObject
) {
  const client = nexusClient()
  const request = new RemoveVehicleAssignmentRequest()

  request.setSubscriptionId(params.subscriptionId)

  return client.removeVehicleAssignment(request)
}

export const SubscriptionService = {
  addVehicleAssignment,
  removeVehicleAssignment,
}
