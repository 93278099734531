import React from 'react'

const ClickToClipboard = ({ className, children}) => {
  return (
    <span
      className={className}
      onClick={() => navigator.clipboard.writeText(children)}
    >
      {children}
    </span>
  )
}
export default ClickToClipboard
