import React, { useState } from 'react'
import { Grid } from '@mui/material'

import makeStyles from '@mui/styles/makeStyles'

import AddDocument from '../add-document'
import Loading from '../../../shared/loading'
import { FleetService } from '../../api/fleet-service'
import { FileSelectionButton } from 'shared/FileSelectionButton'

const useStyles = makeStyles((
  {
    documentButton: {
      display: 'flex',
      alignItems: 'flex-end',
      flexDirection: 'row-reverse',
    },
  }
))

const SelectFile = ({ selectedFile, setSelectedFile }) => {
  const classes = useStyles()
  return (
    <Grid
      container
      spacing={4}
    >
      <Grid item xs={7}>
        {!!selectedFile && selectedFile.name}
      </Grid>
      <Grid
        item
        xs={5}
        className={classes.documentButton}
      >
        <FileSelectionButton
          type={'pdf'}
          id={'registrationFileBtn'}
          title={'Select File'}
          variant="contained"
          color="secondary"
          onChange={setSelectedFile} />
      </Grid>
    </Grid>
  )
}

const AddDealDocuments = (props) => {
  const [documentType, setDocumentType] = useState('registration')
  const [selectedFile, setSelectedFile] = useState(null)
  const [loading, setLoading] = useState(false)
  const handleFormSubmit = () => {
    setLoading(true)
    FleetService
      .addDocument({
        vin: props.vin,
        vehicleId: props.id,
        kind: documentType,
        document: selectedFile
      })
      .then(() => {
        setLoading(false)
        setDocumentType('registration')
        setSelectedFile(null)
      })
      .then(props.handleClose)
  }
  const validate = () => !selectedFile
  return (
    <AddDocument
      {...props}
      selectedFile={selectedFile}
      setDocumentType={setDocumentType}
      documentType={documentType}
      handleFormSubmit={handleFormSubmit}
      disabled={validate()}
      setSelectedFile={setSelectedFile}
      types={[
        {
          key: 'Reg262',
          value: 'reg262',
        },
        {
          key: 'Purchase Order',
          value: 'purchase_order',
        },
        {
          key: 'Reg51',
          value: 'reg51',
        },
        {
          key: 'Reg553',
          value: 'reg553',
        },
        {
          key: 'Buyers Guide',
          value: 'buyers_guide',
        },
        {
          key: 'Used Vehicle History Disclosure',
          value: 'used_vehicle_history_disclosure',
        },
      ]}
    >
      {loading && <Loading />}
      {!loading && (
        <SelectFile
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
        />
      )}
    </AddDocument>
  )
}

export default AddDealDocuments
