import { Customer, Subscription } from '@nxcr-org/web-api-interface/lib/domain_pb'
import { GetVehicleResponse } from '@nxcr-org/web-api-interface/lib/fleet_service_pb'
import {Odometer, StateMap, Vehicle} from '@nxcr-org/web-api-interface/lib/vehicle_pb'
import format from 'date-fns/format'
import { map } from 'lodash'
import {useMutation, UseMutationResult, useQuery, useQueryClient } from 'react-query'
import { useParams } from 'react-router'
import {FleetService} from '../../api/fleet-service'
import { VehicleDetailParams } from '../../fleet-management.types'
import {useContext, useState} from 'react'
import { SnackbarContext, Snack } from 'shared/Snackbar'

export function useVehicleDetail(): UseVehicleDetails {
  const { id } = useParams<VehicleDetailParams>()
  const [displayDialogState, setDisplayDialogState] = useState({
    addRegistrationDialog: false,
    addDealDocumentsDialog: false
  })

  const queryClient = useQueryClient()
  const { setSnack} = useContext(SnackbarContext)

  const addDocumentMutation = useMutation(
    ( params: AddDocumentMutationParams ) => FleetService.addDocument({
      vin: vehicle.vin,
      vehicleId: vehicle.id,
      kind: params.kind,
      document: params.document
    }), {
      onSuccess: () => {
        queryClient.invalidateQueries(['getVehicle', id])
      }
    }
  )

  const {
    data: vehicle,
    isLoading
  } = useQuery(['getVehicle', id], () => {
    return FleetService.getVehicle(id)
      .then(response =>{
        return formatVehicle(response)
      })
  })

  const onSuccessVehicleStateUpdate = () => {
    queryClient.invalidateQueries(['getVehicle', id])
    setSnack(new Snack({message: 'Successfully changed vehicle state', color:'success', open: true}))
  }

  const onErrorVehicleStateUpdate = (error: Error) => {
    setSnack(new Snack({message: `Error changing vehicle state: \n\n${error?.message}`, color:'error', open: true}))
  }

  return {
    vehicle,
    isLoading,
    displayDialogState,
    setDisplayDialogState,
    addDocumentMutation,
    onSuccessVehicleStateUpdate,
    onErrorVehicleStateUpdate,
    setReload(){
      console.log('reload data n page pls...')
    }
  }
}

export function formatVehicle(response: GetVehicleResponse.AsObject): FormattedVehicle {
  const { vehicle, customer } = response

  return {
    ...vehicle,
    customer,
    status: formatVehicleState( vehicle.state ),
    dealDocuments: getDealDocuments(vehicle),
    registrationDocuments: getRegistrationDocs(vehicle),
    inspectionDocuments: getInspectionDocs(vehicle),
    maintenanceDocuments: getMaintenanceDocs(vehicle),
    odometer: getLatestOdometerReading(vehicle)
  }
}

// note: this is reusable, might want to move it elsewhere later on
function formatVehicleState( state: StateMap[keyof StateMap] ): string {
  switch ( state ) {
  case 0:
    return 'In process'
  case 1:
    return 'In listing'
  case 2:
    return 'In order'
  case 3:
    return 'On subscription'
  case 4:
    return 'Charge off skip'
  case 5:
    return 'Pending return'
  case 6:
    return 'Return scheduled'
  case 7:
    return 'Return cancelled'
  case 8:
    return 'Off subscription'
  case 9:
    return 'Vehicle recycled'
  case 10:
    return 'Vehicle sold'
  case 11:
    return 'Repo eligible'
  case 12:
    return 'Out for repo'
  case 13:
    return 'Charge off eligible'
  default:
    return 'N/A'
  }
}

export function getLatestOdometerReading(
  vehicle: Vehicle.AsObject,
  readingType?: Odometer.ReadingTypeMap[keyof Odometer.ReadingTypeMap]
): Odometer.AsObject {
  const defaultValue = {
    date: 0,
    reading: 0,
    units: 0,
    readingType: readingType || 0,
  } as Odometer.AsObject

  return vehicle.odometersList
    .filter( odometer => readingType ? odometer.readingType === readingType : true )
    .reduce(( previousValue, currentValue ) => {
      previousValue = currentValue.date > previousValue.date ? currentValue : previousValue
      return previousValue
    }, defaultValue)
}

export function getInspectionDocs(vehicle: Vehicle.AsObject): Document[] {
  return map(vehicle.inspectionsList, file => {
    return {
      name: file.report,
      url: file.id,
      uploadDate: new Date(file.created),
      id: file.id
    }
  })
}

export function getMaintenanceDocs(vehicle: Vehicle.AsObject): Document[] {
  return map(vehicle.maintenancesList, file => {
    return {
      name: file.fileName,
      url: '',
      uploadDate: new Date(file.created),
      id: file.id

    }
  })
}

export function getRegistrationDocs(vehicle: Vehicle.AsObject) {
  return []
    .concat(
      vehicle.registrationsList.map((registration) => {
        return {
          ...registration,
          name: `Registration Expires: ${format(
            new Date(registration.expiration),
            'MM/dd/yyyy'
          )}`,
        }
      })
    )
    .concat(
      vehicle.warrantiesList.map((warranty) => {
        return {
          ...warranty,
          name: 'Warranty',
        }
      })
    )
    .concat(
      vehicle.titlesList.map((title) => {
        return {
          ...title,
          name: 'Title',
        }
      })
    )
}

export function getDealDocuments(vehicle: Vehicle.AsObject) {
  const {
    reg262,
    reg51,
    purchaseOrder,
    reg553,
    buyersGuide,
    usedVehicleHistoryDisclosure,
  } = vehicle

  const documents = []

  if (reg262) {
    documents.push(
      getDocument({
        fileName: reg262.fileName,
        name: 'REG262',
      })
    )
  }

  if (reg51) {
    documents.push(
      getDocument({
        fileName: reg51.fileName,
        name: 'REG51',
      })
    )
  }

  if (reg553) {
    documents.push(
      getDocument({
        fileName: reg553.fileName,
        name: 'REG553',
      })
    )
  }

  if (purchaseOrder) {
    documents.push(
      getDocument({
        fileName: purchaseOrder.fileName,
        name: 'Purchase Order',
      })
    )
  }

  if (buyersGuide) {
    documents.push(
      getDocument({
        fileName: buyersGuide.fileName,
        name: 'Buyers Guide',
      })
    )
  }

  if (usedVehicleHistoryDisclosure) {
    documents.push(
      getDocument({
        fileName: usedVehicleHistoryDisclosure.fileName,
        name: 'Used Vehicle History Disclosure',
      })
    )
  }

  return documents
}

const baseURL = ''

export function getDocument({
  fileName,
  name,
}: {
  fileName: string
  name: string
}) {
  return {
    url: `${baseURL}/${fileName}`,
    name,
  }
}

interface SubscriptionDetails extends Subscription.AsObject {
  startTime: Date
  pickUpTime: Date
  formattedStartTime: string
  formattedPickUpTime: string
  isPending: boolean
}

export interface Document {
  uploadDate: Date
  name: string
  url: string
  id: string
}

export interface FormattedVehicle extends Vehicle.AsObject {
  customer?: Customer.AsObject
  subscription?: SubscriptionDetails
  dealDocuments: Document[]
  registrationDocuments: Document[]
  inspectionDocuments: Document[]
  maintenanceDocuments: Document[]
  odometer: Odometer.AsObject
  status: string
}

interface AddDocumentMutationParams {
  document: File,
  kind: string
}

interface UseVehicleDetails {
  vehicle: FormattedVehicle
  isLoading: boolean
  setReload: () => void
  displayDialogState: DisplayDialogState
  setDisplayDialogState: ( state: DisplayDialogState ) => void
  addDocumentMutation: UseMutationResult
  onSuccessVehicleStateUpdate: () => void
  onErrorVehicleStateUpdate: ( error: Error ) => void
}

interface DisplayDialogState {
  addRegistrationDialog: boolean
  addDealDocumentsDialog: boolean
}
