import { useEffect, useState } from 'react'
import {
  formattedAppointmentStatus,
  formattedAppointmentTypes,
} from '../../../../fleet-management/api/reservation.formatted-enums'
import { IAppointmentListItem } from '../../utils'
import { useMutation, useQueryClient } from 'react-query'
import { NexusService } from 'global-apis/nexus-service'
import { getMakeAndModel } from '../../../../fleet-management/utils'

export const useCreateAppointmentModal = (
  handleClose?,
  onReservationPage?,
  reservation?
) => {
  const queryClient = useQueryClient()
  const [appointment, setAppointment] = useState<IAppointmentListItem>()
  const [isEditing, setIsEditing] = useState(true)
  const [isReservationAppointment, setIsReservationAppointment] =
    useState(false)

  useEffect(() => {
    if (onReservationPage) {
      buildAppointment(
        reservation?.reservation?.id,
        reservation?.customer?.id,
        reservation?.customerInfo?.firstName,
        reservation?.customerInfo?.lastName,
        reservation?.customerInfo?.accountNumber,
        reservation?.vehicle?.vin,
        reservation?.vehicle?.color,
        getMakeAndModel(reservation?.vehicle, 'model'),
        getMakeAndModel(reservation?.vehicle, 'make')
      )
      setIsReservationAppointment(true)
    }
  }, [isReservationAppointment])

  const createAppointment = useMutation(
    async (subscriptionId: string) => {
      return NexusService.createAppointment({
        ...appointment,
        subscriptionId: subscriptionId,
      })
    },
    {
      onSuccess(data) {
        setAppointment({
          ...appointment,
          id: data?.appointmentId,
        })
        setIsEditing(false)
        queryClient.invalidateQueries([
          '/listAppointments',
          '/listAppointmentsByAccountNumber',
        ])
      },
      // when a double booking response comes back, an error is thrown
      // but the appointment is still created. We must invalidate the queries
      // here as well to have proper refreshed data
      onError() {
        queryClient.invalidateQueries([
          '/listAppointments',
          '/listAppointmentsByAccountNumber',
        ])
      },
    }
  )

  function buildAppointment(
    reservationId,
    customerId,
    firstName,
    lastName,
    accountNumber,
    vin,
    color,
    model,
    make?
  ) {
    setIsEditing(true)
    const customerName = `${firstName} ${lastName}`
    setAppointment(
      createInitialAppointment(
        reservationId,
        customerId,
        customerName,
        accountNumber,
        vin,
        color,
        model,
        make
      )
    )
  }

  function closeModal() {
    // this is necessary to 'retrigger' buildAppointment when there is a
    // reservation. On initial opening of the modal, the searchbar is skipped
    // ( as intended ), but on close and reopen it would open the searchbar
    // again. By resetting this, we can ensure the searchbar is always skipped
    // and the reservation is handled as intended.
    if (onReservationPage) {
      setIsReservationAppointment(false)
    }
    setAppointment(undefined)
    createAppointment.reset()
    handleClose()
  }

  return {
    isEditing,
    appointment,
    createAppointment,
    buildAppointment,
    setAppointment,
    closeModal,
  }
}

function createInitialAppointment(
  reservationId: string,
  // subscriptionId: string,
  customerId: string,
  customerName: string,
  accountNumber: string,
  vin: string,
  color: string,
  model: string,
  make?: string
): IAppointmentListItem {
  return {
    id: '',
    reservationId,
    // subscriptionId,
    customerId,
    customerName: customerName,
    customerAccountNumber: accountNumber,
    location: '',
    dealershipId: 'b1d68de5-0f55-4470-9030-a5d3d7b3d84b', // temporarily hardcoded until BE is prepared
    date: new Date(),
    assigneeName: '',
    assigneeId: '',
    note: '',
    noteAuthor: '',
    startTime: 0,
    duration: 60, // temporary until BE is prepared
    // below type and status preferred default values are Select placeholders,
    // but MUI doesn't like that. Passing 'undefined' here will result in the correct
    // placeholder, but MUI console error and warnings
    type: formattedAppointmentTypes.PICKUP.value,
    typeLabel: formattedAppointmentTypes.PICKUP.label,
    status: formattedAppointmentStatus.SCHEDULED.value,
    statusLabel: formattedAppointmentStatus.SCHEDULED.label,
    timeZone: 'America/Los_Angeles', // hardcoded as such in BE right now
    notes: [],
    vin: vin,
    color: color,
    make: make,
    model: model,
  }
}
