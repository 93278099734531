import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
  Grid,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  CircularProgress,
} from '@mui/material'
import { useHolidaysBlock } from './useHolidaysBlock'
import { CloseOutlined } from '@mui/icons-material'
import { AutonomyCalendar } from 'shared/AutonomyCalendar/AutonomyCalendar'

interface HolidaysBlockProps {
  holidays?: formatDate[]
  setClosedDates: (closedDates: formatDate[] ) => void
  openDays: string[]
  isEditing: boolean
  isLoading: boolean
  isAdmin: boolean
  closedDates: formatDate[]
}

/** note:
 "HolidaysBlock" refers to the UI title of this block.
 It handles Autonomy Calendar Holidays ( immutable by a user )
 and any additional, dealership specific days off.
 **/
export const HolidaysBlock: React.FC<HolidaysBlockProps> = ({
  holidays,
  closedDates,
  openDays,
  setClosedDates,
  isEditing,
  isAdmin,
  isLoading,
}) => {
  const { rowToDelete, setRowToDelete } = useHolidaysBlock()

  // put both 'kinds' of closed day arrays ( AutonomyHolidays and closedDatesWithReason ) together to disable those in the calendar
  const disabledDates = [
    ...holidays.map((holiday) => holiday.date),
    ...closedDates.map((closedDate) => closedDate.date),
  ]

  return isLoading ? (
    <Box sx={{ width: '100%', textAlign: 'center' }}>
      <CircularProgress />
    </Box>
  ) : (
    <Grid container direction="column" spacing={6}>
      <Grid item>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="caption">Date</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="caption">Reason</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/*holidays are autonomyHolidays from the Calendar. These are immutable*/}
            {holidays.map((row) => (
              <TableRow key={`'holiday-'+${row.id}`}>
                <TableCell>
                  <Typography>{row.date.toLocaleDateString()}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{row.reason}</Typography>
                </TableCell>
              </TableRow>
            ))}

            {/*closedDates are dates super_admins may add, delete, edit*/}
            {closedDates.map((row) => (
              <TableRow key={row.id}>
                <TableCell>
                  {isEditing ? (
                    <AutonomyCalendar
                      value={row.date}
                      disabledDates={disabledDates}
                      openDays={openDays}
                      onChange={(date: Date) => {
                        closedDates[
                          closedDates.findIndex(
                            (closedDate) => closedDate.id === row.id
                          )
                        ].date = date
                        setClosedDates(closedDates)
                      }}
                    />
                  ) : (
                    <Typography>{row.date.toLocaleDateString()}</Typography>
                  )}
                </TableCell>
                <TableCell width="85%">
                  {isEditing ? (
                    <Box
                      sx={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'space-between',
                      }}
                    >
                      <div>
                        <TextField
                          placeholder="Set a reason"
                          value={row.reason}
                          onChange={(e) => {
                            closedDates[
                              closedDates.findIndex(
                                (closedDate) => closedDate.id === row.id
                              )
                            ].reason = e.target.value
                            setClosedDates(closedDates)
                          }}
                          className="compactTextField roundedTextField"
                        />
                      </div>
                      <div>
                        <IconButton
                          className="xsIcon"
                          onClick={() => {
                            setRowToDelete(row)
                          }}
                        >
                          <CloseOutlined />
                        </IconButton>
                      </div>
                    </Box>
                  ) : (
                    <Typography>{row.reason}</Typography>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>

      {isAdmin && isEditing && (
        <Grid item alignSelf="center">
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              const newRow = {
                id: new Date().toString(),
                date: new Date(),
                reason: '',
              }
              setClosedDates([...closedDates, newRow])
            }}
          >
            Add Date
          </Button>
        </Grid>
      )}

      <Dialog open={!!rowToDelete} onClose={() => setRowToDelete(null)}>
        <DialogTitle>Delete a date</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this date?</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => setRowToDelete(null)}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              const index = closedDates.findIndex(
                (closedDate) => closedDate.id === rowToDelete.id
              )
              setClosedDates([
                ...closedDates.slice(0, index),
                ...closedDates.slice(index + 1),
              ])
              setRowToDelete(null)
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  )
}
