import React, { FC } from 'react'
import { Button, Container, Typography } from '@mui/material'
// import FlagSection from '../flag-section'
import Loading from '../../../shared/loading'
import { BackButton } from '../../../../components/user-navigation/BackButton'
import { VehicleDetailsBlock } from './blocks/VehicleDetailsBlock'
import { DocumentBlock } from './blocks/DocumentBlock'
import AddRegistration from '../add-registration'
import AddDealDocuments from '../add-deal-documents'
import { Article } from 'shared/Article'
import { Section } from 'shared/Section'
import { FileSelectionButton } from 'shared/FileSelectionButton'
import { useVehicleDetail } from './useVehicleDetail'
import UpdateVehicleStateButtons from '../update-vehicle-state-buttons/updateVehicleStateButtons'

export const VehicleDetail: FC = () => {
  const {
    isLoading,
    vehicle,
    setDisplayDialogState,
    displayDialogState,
    addDocumentMutation,
    onSuccessVehicleStateUpdate,
    onErrorVehicleStateUpdate,
  } = useVehicleDetail()

  if (isLoading) {
    return <Loading />
  }

  const { year, make, model, id, vin } = vehicle

  const dialogs = [
    <AddRegistration
      key="addRegistrationDialog"
      open={displayDialogState.addRegistrationDialog}
      handleClose={() => {
        setDisplayDialogState({
          ...displayDialogState,
          addRegistrationDialog: false,
        })
      }}
      id={id}
      vin={vin}
    />,
    <AddDealDocuments
      key="addDeadDocumentsDialog"
      open={displayDialogState.addDealDocumentsDialog}
      handleClose={() => {
        setDisplayDialogState({
          ...displayDialogState,
          addDealDocumentsDialog: false,
        })
      }}
      id={id}
      vin={vin}
    />,
  ]

  return (
    <Container>
      <BackButton lastPageRoute="/fleet" />

      <Article
        heading={`${year} ${make} ${model}`}
        headerHasBottomBorder={true}
      >
        {dialogs.map((dialog) => dialog)}

        <Section heading="Vehicle details">
          <VehicleDetailsBlock vehicle={vehicle} />
        </Section>

        <Section heading="Vehicle actions">
          <Typography variant="caption">Change State</Typography>
          <div>
            <UpdateVehicleStateButtons
              vehicleId={vehicle.id}
              currentVehicleState={vehicle.state}
              onSuccess={onSuccessVehicleStateUpdate}
              onError={onErrorVehicleStateUpdate}
            />
          </div>
        </Section>

        <Section heading="Registration, Title & Warranty Documents">
          <DocumentBlock
            documents={vehicle.registrationDocuments}
            isLoading={isLoading}
          >
            <Button
              variant="outlined"
              color="primary"
              onClick={() =>
                setDisplayDialogState({
                  ...displayDialogState,
                  addRegistrationDialog: true,
                })
              }
            >
              Add Document
            </Button>
          </DocumentBlock>
        </Section>

        <Section heading="Inspections">
          <DocumentBlock
            documents={vehicle.inspectionDocuments}
            isLoading={isLoading}
          >
            {addDocumentMutation.isError ? `${addDocumentMutation.error}` : ''}
            <FileSelectionButton
              type={'pdf'}
              id={'inspection-doc-upload'}
              title={'Add Document'}
              onChange={(event) =>
                addDocumentMutation.mutate({
                  document: event,
                  kind: 'inspection',
                })
              }
            />
          </DocumentBlock>
        </Section>

        <Section heading="Maintenance Records">
          <DocumentBlock
            documents={vehicle.maintenanceDocuments}
            isLoading={isLoading}
          >
            <FileSelectionButton
              type={'pdf'}
              id={'maintenance-doc-upload'}
              title={'Add Document'}
              onChange={(event) =>
                addDocumentMutation.mutate({
                  document: event,
                  kind: 'maintenance',
                })
              }
            />
          </DocumentBlock>
          {/*  /!*{!!customer && <InsuranceSection customer={customer} /> }*!/*/}
          {/*  /!*<MaintenanceRecordsBlock*!/*/}
          {/*  /!*  documents={vehicle.maintenanceDocuments}*!/*/}
          {/*  /!*  id={id}*!/*/}
          {/*  /!*  vin={vin}*!/*/}
          {/*  /!*  setReload={setReload}*!/*/}
          {/*  /!*  isLoading={isLoading}*!/*/}
          {/*  /!*todo: maintenance is also different from the other blocks*!/*/}
        </Section>

        <Section heading="Deal Documents">
          <DocumentBlock
            documents={vehicle.dealDocuments}
            isLoading={isLoading}
          >
            <Button
              variant="outlined"
              color="primary"
              onClick={() =>
                setDisplayDialogState({
                  ...displayDialogState,
                  addDealDocumentsDialog: true,
                })
              }
            >
              Add Document
            </Button>
          </DocumentBlock>
        </Section>
      </Article>
    </Container>
  )
}
