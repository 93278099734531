import React, { useState } from 'react'
import {
  Button,
  Grid,
  MenuItem,
  Select,
  Typography
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { UseMutateFunction } from 'react-query'
import { map } from 'lodash'
import {
  formattedActionUserType,
  formattedCancellationReasons,
} from '../../../../api/reservation.formatted-enums'
import { Subscription } from '@nxcr-org/web-api-interface/lib/domain_pb'
import { CancelReservationResponse } from '@nxcr-org/web-api-interface/lib/subscription_service_pb'

interface CancellationBlockProps {
  errorCancelling
  isCancelled: boolean
  cancelSubscription: UseMutateFunction<
    CancelReservationResponse,
    unknown,
    {
      actionReason: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13
      userType: 0 | 1
    },
    unknown
  >
}

export const CancellationBlock: React.FC<CancellationBlockProps> = (props) => {
  const [userType, setUserType] =
    useState<
      Subscription.SubscriptionAction.UserTypeMap[keyof Subscription.SubscriptionAction.UserTypeMap]
    >(0)
  const [reason, setReason] =
    useState<
      Subscription.SubscriptionAction.ActionReasonMap[keyof Subscription.SubscriptionAction.ActionReasonMap]
    >(0)

  return (
    <Grid
      container
      spacing={2}
      direction="column">
      <Grid item>
        <Grid
          container
          rowSpacing={2}
          columnSpacing={6}>
          <Grid
            item
            lg={3}>
            <Grid
              container
              direction="column">
              <Typography variant="caption">Cancel As</Typography>
              <Select
                value={userType}
                onChange={(evt) => {
                  // @ts-ignore :: Doesn't like the value
                  setUserType(evt.target.value)
                }}
                IconComponent={ExpandMoreIcon}
              >
                {map(formattedActionUserType, (userType, index) => {
                  return (
                    <MenuItem
                      key={index}
                      value={userType.value}>
                      {userType.label}
                    </MenuItem>
                  )
                })}
              </Select>
            </Grid>
          </Grid>
          <Grid
            item
            lg={3}>
            <Grid
              container
              direction="column">
              <Typography variant="caption">Cancel Reason</Typography>
              <Select
                value={reason}
                onChange={(evt) => {
                  // @ts-ignore :: Doesn't like the value
                  setReason(evt.target.value)
                }}
                IconComponent={ExpandMoreIcon}
              >
                <MenuItem
                  value={
                    formattedCancellationReasons.DEALER_VEHICLE_NOT_AVAILABLE
                      .value
                  }
                >
                  {
                    formattedCancellationReasons.DEALER_VEHICLE_NOT_AVAILABLE
                      .label
                  }
                </MenuItem>
                <MenuItem
                  value={
                    formattedCancellationReasons
                      .DEALER_VEHICLE_SELLING_PRICE_REJECTED.value
                  }
                >
                  {
                    formattedCancellationReasons
                      .DEALER_VEHICLE_SELLING_PRICE_REJECTED.label
                  }
                </MenuItem>
                <MenuItem
                  value={formattedCancellationReasons.DEALER_VEHICLE_SOLD.value}
                >
                  {formattedCancellationReasons.DEALER_VEHICLE_SOLD.label}
                </MenuItem>
                <MenuItem
                  value={
                    formattedCancellationReasons.CUSTOMER_VEHICLE_TOO_EXPENSIVE
                      .value
                  }
                >
                  {
                    formattedCancellationReasons.CUSTOMER_VEHICLE_TOO_EXPENSIVE
                      .label
                  }
                </MenuItem>
                <MenuItem
                  value={
                    formattedCancellationReasons
                      .CUSTOMER_VEHICLE_DRIVE_NOT_AS_EXPECTED.value
                  }
                >
                  {
                    formattedCancellationReasons
                      .CUSTOMER_VEHICLE_DRIVE_NOT_AS_EXPECTED.label
                  }
                </MenuItem>
                <MenuItem
                  value={
                    formattedCancellationReasons.ANDROID_OS_UNSUPPORTED.value
                  }
                >
                  {formattedCancellationReasons.ANDROID_OS_UNSUPPORTED.label}
                </MenuItem>
                <MenuItem
                  value={
                    formattedCancellationReasons
                      .CUSTOMER_VEHICLE_DOES_NOT_WANT_VEHICLE.value
                  }
                >
                  {
                    formattedCancellationReasons
                      .CUSTOMER_VEHICLE_DOES_NOT_WANT_VEHICLE.label
                  }
                </MenuItem>
              </Select>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid item lg={6}>
          <Typography variant="caption">
            By clicking <Typography variant="caption" className="bold">Cancel Order</Typography>, the customer’s order will be cancelled. Their security deposit, if paid, will be refunded.
            Please verify this is the correct action you’d like to take.
          </Typography>
        </Grid>
      </Grid>
      <Grid
        item
        container
        xs={12}
        justifyContent="center">
        <Grid item>
          <Button
            variant="contained"
            disabled={props.isCancelled}
            onClick={() => {
              props.cancelSubscription({ actionReason: reason, userType })
            }}
          >
            Cancel Order
          </Button>
          {props.isCancelled ? (
            <Grid>
              <Typography>
                Order already cancelled
              </Typography>
            </Grid>
          ) : null}
        </Grid>
        {props.errorCancelling ? (
          <Grid>
            <Typography>
              {props.errorCancelling.message}
            </Typography>
          </Grid>
        ) : null}
      </Grid>
    </Grid>
  )
}
