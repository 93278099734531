import { useQuery} from 'react-query'
import { NexusService } from '../global-APIs/nexus-service'

export const usePromoPartners = () => {

  const { data: promoPartners } = useQuery(
    ['/promopartners'],
    () => {
      return NexusService.listPromoPartners().then((res) => {
        return { promoPartners: res.resultsList }
      })
    },
    {
      placeholderData: {
        promoPartners: [],
      },
    }
  )

  return {
    promoPartners,
  }
}

