/* eslint-disable indent */

import {
  Grid,
  Typography,
  Box,
  Button,
  List,
  ListItem,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import { map, find } from 'lodash'
import React from 'react'
import ReactJson from 'react-json-view'
import { palette } from '../../../global-styles/theme/palette'
import { safeParseJSON } from '../../../utils/safeParseJSON'
import {
  getQualification,
  ProcessOptions,
} from '../../fleet-management/components/reservation/useReservation'
import { useCQETab } from './useCQETab'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

const getFeedbackMessage = (decision: ProcessOptions) => {
  if (decision === ProcessOptions.approved) {
    return (
      <Typography
        color={palette.text.success}
      >{`Qualification Result Updated Successfully: Approved ${new Date()}`}</Typography>
    )
  }

  if (decision === ProcessOptions.rejected) {
    return (
      <Typography
        color={palette.text.info}
      >{`Qualification Result Updated Successfully: Rejected ${new Date()}`}</Typography>
    )
  }

  return <div />
}

export const CQETab = () => {
  const cqe = useCQETab()

  const scores = getQualification(cqe.cqeScores?.scoresList)

  return (
    <Grid container direction="column" spacing={6} style={{ padding: 20 }}>
      <Grid item direction="column" spacing={6}>
        {!scores.length && (
          <Grid item>
            <Typography>No qualification scores available</Typography>
          </Grid>
        )}

        {map(scores, (score, index) => {
          const sourceData = find(score.sourceData, {
            vendorName: score.vendor,
          })
          // TODO: The naming for this is off but that's because of how the backend handles it
          sourceData.sourceData = safeParseJSON(sourceData.sourceData)

          return (
            <Grid key={index} item container spacing={2} direction="column">
              <Grid item>
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    columnGap: '3rem',
                    rowGap: '1.5rem',
                  }}
                >
                  <div>
                    <Typography variant="caption">Date</Typography>
                    <div>{score.qualificationDate}</div>
                  </div>
                  <div>
                    <Typography variant="caption">Time</Typography>
                    <div>{score.qualificationTime}</div>
                  </div>
                  <div>
                    <Typography variant="caption">Result</Typography>
                    <div>
                      {score.result === 'Accepted' ? (
                        <Typography color={palette.text.success}>
                          {score.result}
                        </Typography>
                      ) : (
                        <Typography color={palette.text.warning}>
                          {score.result}
                        </Typography>
                      )}
                    </div>
                  </div>
                  <div>
                    <Typography variant="caption">Reason</Typography>
                    <div>{score.reason || 'N/A'}</div>
                  </div>
                  <div>
                    <Typography variant="caption">Score</Typography>
                    <div>{score.score || 'N/A'}</div>
                  </div>
                  <div>
                    <Typography variant="caption">Tier</Typography>
                    <div>{score.tier || 'N/A'}</div>
                  </div>
                  <div>
                    <Typography variant="caption">Vendor</Typography>
                    <div>{score.vendor || 'N/A'}</div>
                  </div>
                </Box>
              </Grid>
              <Grid item style={{ maxWidth: '100%' }}>
                <Typography variant="caption">
                  Full Qualification Response
                </Typography>
                {/*@TODO: going to need a custom theme for this*/}
                <ReactJson
                  theme="google"
                  name={false}
                  displayDataTypes={false}
                  src={sourceData || score.sourceData}
                  collapsed={1}
                  style={{
                    width: '100%',
                    overflowX: 'auto',
                  }}
                />
              </Grid>
            </Grid>
          )
        })}
      </Grid>
      <Grid item spacing={2} direction="column">
        <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: '10px' }}>
          <Box
            sx={{
              display: 'inline-flex',
              columnGap: 4,
              rowGap: 2,
              flexWrap: 'wrap',
              paddingLeft: '10px',
            }}
          >
            <Box sx={{ display: 'inline-flex', flexDirection: 'column' }}>
              <Typography variant="caption">Qualification Result</Typography>
              <Select
                value={cqe.decision}
                onChange={(evt) => {
                  cqe.setFeedbackMessage(null)
                  // @ts-ignore
                  cqe.setDecision(evt.target.value as ProcessOptions)
                  if (cqe.cqeScores?.disqualificationVendor) {
                    cqe.setOverrideDisqualificationVendor(
                      cqe.cqeScores?.disqualificationVendor
                    )
                  }
                }}
                IconComponent={ExpandMoreIcon}
              >
                <MenuItem disabled={true} value={ProcessOptions.unchanged}>
                  Select a Qualification Result
                </MenuItem>
                <MenuItem value={ProcessOptions.approved}>Approve</MenuItem>
                {/*@TODO: On reject, show an additional select with reject reasons and handle */}
                <MenuItem value={ProcessOptions.rejected}>Reject</MenuItem>
                {/*@TODO: discuss with UX. Is it really logical to have below option here?*/}
                <MenuItem value={ProcessOptions.recalculate}>
                  Repeat CQE
                </MenuItem>
              </Select>
            </Box>
            {cqe.showOverrideRejectionDropdown && (
              <Box sx={{ display: 'inline-flex', flexDirection: 'column' }}>
                <Typography variant="caption">Rejection Used</Typography>
                <Select
                  defaultValue={cqe.cqeScores?.disqualificationVendor?.id}
                  //@ts-ignore
                  value={cqe?.overrideDisqualificationVendor?.id}
                  onChange={(evt) =>
                    cqe.setOverrideDisqualificationVendor(
                      cqe.cqeScores?.scoresList.find(
                        (score) => score.id === evt.target.value
                      )
                    )
                  }
                  IconComponent={ExpandMoreIcon}
                >
                  {cqe.cqeScores?.scoresList?.map((score) => {
                    return (
                      <MenuItem key={score.id} value={score.id}>
                        {score.vendorName}
                      </MenuItem>
                    )
                  })}
                </Select>
              </Box>
            )}
            <Box
              sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}
            >
              <Typography variant="caption">Notes</Typography>
              <TextField
                value={cqe.currentNote}
                onChange={(evt) => cqe.setCurrentNote(evt.target.value)}
                fullWidth
                placeholder="Add notes about this update"
                variant="outlined"
              />
            </Box>
          </Box>
          {cqe.showOverrideRejectionDropdown && (
            <Box>
              <Typography variant="caption">Reason Codes</Typography>
              {cqe.cqeScores?.disqualificationVendor &&
                !cqe.overrideDisqualificationVendor && (
                  <List
                    sx={{
                      listStyleType: 'disc',
                      pl: 2,
                      '& .MuiListItem-root': {
                        display: 'list-item',
                      },
                    }}
                  >
                    {cqe.cqeScores?.disqualificationVendor.disqualifiedReasonsList.map(
                      (reason, index) => {
                        return <ListItem key={index}>{reason}</ListItem>
                      }
                    )}
                  </List>
                )}
              {cqe.overrideDisqualificationVendor && (
                <List
                  sx={{
                    listStyleType: 'disc',
                    pl: 2,
                    '& .MuiListItem-root': {
                      display: 'list-item',
                    },
                  }}
                >
                  {
                    // @ts-ignore
                    cqe.overrideDisqualificationVendor?.disqualifiedReasonsList.map(
                      (reason, index) => {
                        return <ListItem key={index}>{reason}</ListItem>
                      }
                    )
                  }
                </List>
              )}
            </Box>
          )}
        </Box>
        <Grid item>
          {cqe.overrideQualificationSuccess && getFeedbackMessage(cqe.decision)}
        </Grid>
        <Grid item sx={{ display: 'flex', justifyContent: 'center' }}>
          <Box sx={{ display: 'inline-flex', flexDirection: 'column' }}>
            <Button
              disabled={
                !cqe.isAdmin ||
                (!cqe.decision && cqe.currentNote === cqe.mostRecentNote)
              }
              onClick={() => {
                if (
                  cqe.decision === ProcessOptions.approved ||
                  cqe.decision === ProcessOptions.rejected
                ) {
                  cqe
                    .overrideQualification({
                      note: cqe.currentNote,
                      decision: cqe.decision,
                    })
                    .then(() => {
                      // setFeedbackMessage(getFeedbackMessage(decision))
                      // @ts-ignore
                      cqe.setDecision(ProcessOptions.unchanged)
                    })
                } else if (cqe.decision === ProcessOptions.recalculate) {
                  cqe.recalculateCustomerScores()
                }

                if (cqe.currentNote) {
                  cqe.addNote()
                }
              }}
              variant="contained"
              color="primary"
            >
              Update
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  )
}
