import React, { FC } from 'react'
import { Container, Grid } from '@mui/material'
import {  Route, Switch } from 'react-router-dom'
import { useNexusRouteStyles } from './NexusRoutes.styles'
import { VehiclesTable } from './components/listings-table/VehiclesTable'
import { FleetTable } from './components/fleet-table/FleetTable'
import { ReservationTable } from './components/reserved/ReservationTable'
import { Tasks } from '../scheduling-tool/tasks/Tasks'
import { Settings } from '../scheduling-tool/settings/Settings'
import { Article } from 'shared/Article'

const EmptyTab = () => {
  return (
    <Article
      heading="Empty tab, content coming soon!"
      headerHasBottomBorder={false}
    >
      <Grid container spacing={8}>
        <Grid item xs={12}>
          {/*content*/}
        </Grid>
      </Grid>
    </Article>
  )
}

export const NexusRoutes: FC = () => {
  const { classes } = useNexusRouteStyles()

  return (
    <div className={classes.root}>

      <Container 
        maxWidth="xl" 
        className={classes.container}
      >
        <Grid container spacing={4} className={classes.dataContainer}>
          <Grid item xs={12}>
            <Switch>
              <Route path="/fleet/" component={VehiclesTable} exact />
              <Route path="/fleet/active" component={FleetTable} />
              <Route path="/fleet/reservations" component={ReservationTable} />
              <Route path="/fleet/tasks" component={Tasks} />
              <Route path="/fleet/returns" component={EmptyTab} />
              <Route path="/fleet/settings" component={Settings} />
            </Switch>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}