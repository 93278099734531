import {
  GetInsuranceStatusRequest,
  CancelInsurancePolicyRequest,
  GetInsuredDriversRequest,
  GetInsuranceQuoteRequest,
  GetLatestQuoteGroupRequest,
} from '@nxcr-org/web-api-interface/lib/insurance_service_pb'

import { oktaAuth, OktaAuthInterceptor } from '../../okta/config'
import { Env } from '../fleet-management/api/env'
import { InsuranceServicePromiseClient } from '@nxcr-org/web-api-interface/lib/gateway_service_grpc_web_pb'
import { NexusServicePromiseClient } from '@nxcr-org/web-api-interface/lib/nexus_service_grpc_web_pb'

export const InsuranceService = {}

export function insuranceServiceClient(): InsuranceServicePromiseClient {
  const idToken = oktaAuth.getIdToken()
  const target = Env.endpoints.fleet

  return new InsuranceServicePromiseClient(target, null, {
    unaryInterceptors: [new OktaAuthInterceptor(idToken)],
  })
}

export function nexusServiceClient(): NexusServicePromiseClient {
  const idToken = oktaAuth.getIdToken()
  const target = Env.endpoints.fleet

  return new NexusServicePromiseClient(target, null, {
    unaryInterceptors: [new OktaAuthInterceptor(idToken)],
  })
}
export function getInsuranceStatus(customerId: string, subscriptionId: string) {
  const client = insuranceServiceClient()
  const request = new GetInsuranceStatusRequest()
  request.setSubscriptionId(subscriptionId)
  request.setCustomerId(customerId)

  return client
    .getInsuranceStatus(request)
    .then((res) => res.toObject())
    .catch((error) => console.log('Get insurance status error:', error))
}

export async function cancelInsurancePolicy(customerId: string) {
  const client = insuranceServiceClient()
  const request = new CancelInsurancePolicyRequest()

  request.setCustomerId(customerId)

  try {
    const res = await client.cancelInsurancePolicy(request)
    return res.toObject()
  } catch (error) {
    return console.log('Cancel Insurance error:', error)
  }
}

export function getInsuredDrivers(customerId: string, subscriptionId: string) {
  const client = nexusServiceClient()
  const request = new GetInsuredDriversRequest()
  request.setSubscriptionId(subscriptionId)
  request.setCustomerId(customerId)
  return client
    .getInsuredDrivers(request)
    .then((res) => res.toObject())
    .catch((error) => console.log('Get insured drivers error:', error))
}

export function getInsuranceQuote({ subscriptionId, customerId }) {
  const client = nexusServiceClient()
  const request = new GetInsuranceQuoteRequest()
  request.setSubscriptionId(subscriptionId)
  request.setCustomerId(customerId)
  return client
    .getInsuranceQuote(request)
    .then((res) => res.toObject())
    .catch((error) => console.log('Get insurance quote error:', error))
}

export function getLatestQuoteGroup({ subscriptionId }) {
  const client = insuranceServiceClient()
  const request = new GetLatestQuoteGroupRequest()
  request.setSubscriptionId(subscriptionId)
  return client
    .getLatestQuoteGroup(request)
    .then((res) => res.toObject())
    .catch((error) => console.log('Get latest quote error:', error))
}
