import { lowerCase, mapValues, startCase } from 'lodash'

export function labelize(key: string){
  return startCase(lowerCase(key))
}
// TODO: Should be a Record<string, number> but TS complains interface doesn't match
export function formatEnum<T extends object>(enumObject: T) {
  return mapValues(enumObject, (value, key) => {
    return {
      value,
      label: labelize(key),
    }
  })
}

export function getLabelFromEnum<T extends object>(TypeObject: T, num: number): string {
  const formattedEnumObject = formatEnum(TypeObject)
  let label = ''
  Object.keys(formattedEnumObject).map((type) => {
    if (num === formattedEnumObject[type].value) {
      label = formattedEnumObject[type].label
    }
  })
  return label
}