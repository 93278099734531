import React from 'react'
import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import Transition from 'shared/Transition'

const useStyles = makeStyles((theme) => ({
  paper: {
    minWidth: '20vw',
    padding: '15px 20px 20px 0',
  },
  dealerName: {
    textTransform: 'capitalize',
  },
  delistButton: {
    backgroundColor: theme.palette.error.main,
  },
}))

interface VinAssignmentModalProps {
  open: boolean
  onClose: () => void
  handleYesClick: any
  headerText: string
}

export const VinAssignmentModal: React.FC<VinAssignmentModalProps> = ({
  open,
  onClose,
  headerText,
  handleYesClick,
}) => {
  const classes = useStyles()

  return false

  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      classes={{ paper: classes.paper }}
    >
      <DialogTitle id="dialog-title">
        {headerText === 'Assign'
          ? 'Are you sure you want to assign a VIN to an active subscription?'
          : 'Are you sure you want to unassign a VIN from an active subscription?'}
      </DialogTitle>

      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button
          onClick={handleYesClick}
          autoFocus
          variant="contained"
          color="primary"
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  )
}
