import React, { FC } from 'react'
import {
  Autocomplete,
  Card,
  Typography,
  Box,
  Button,
  TextField,
  useMediaQuery,
  Link,
} from '@mui/material'
import { DataGrid, GridColumns } from '@mui/x-data-grid'
import { useReservationsTable } from './useReservationTable'
import { TableSearch } from '../../../shared/TableSearch'

export const ReservationTable: FC = () => {
  const isDesktop = useMediaQuery('(min-width:1400px)')

  const {
    reservations,
    total,
    page,
    rowsPerPage,
    setRowsPerPage,
    setPage,
    isLoading,
    setQuery,
    programs,
    search,
    setProgram,
  } = useReservationsTable()

  const columns: GridColumns = [
    {
      field: 'customerName',
      headerName: 'Customer Name',
      cellClassName: 'primaryAccent',
      flex: 1,
      renderCell: (props) => (
        <Link
          sx={{ fontWeight: 'bold' }}
          href={`/customers/${props.row.customerId}`}
        >
          {props.row.customerName}
        </Link>
      ),
    },
    {
      field: 'accountNumber',
      headerName: 'Account Number',
      flex: 0.75,
    },
    {
      field: 'make',
      headerName: 'Make',
      flex: 0.5,
    },
    {
      field: 'model',
      headerName: 'Model',
      flex: 0.5,
    },
    {
      field: 'waitlistStart',
      headerName: 'Start Date',
      flex: 0.75,
    },
    {
      field: 'color',
      headerName: 'Color Preferences',
      flex: 0.75,
    },
    {
      field: 'zipcode',
      headerName: 'ZIP Code',
      flex: 0.5,
    },
    {
      field: 'status',
      headerName: 'Reservation Status',
      flex: 1,
    },
    {
      field: 'vehicleDetail',
      headerName: '',
      flex: 1,
      renderCell: (props) => (
        <Link
          href={`/reservations/${props.row.reservationId}`}
          sx={{ textDecoration: 'none' }}
        >
          <Button variant="contained" color="primary">
            {isDesktop ? 'View Reservation' : 'View'}
          </Button>
        </Link>
      ),
    },
  ]

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        p: 3,
      }}
    >
      <Typography sx={{ marginLeft: '5px' }} variant="h1">
        Reservations
      </Typography>
      <Card>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <TableSearch
            label="Search by account number"
            setQuery={setQuery}
            search={search}
          />
          <Autocomplete
            sx={{ width: '100%', paddingRight: '10px', marginTop: '-8px' }}
            disableClearable
            options={[
              { id: undefined, description: 'All Programs' },
              ...programs.programsList,
            ]}
            //@ts-ignore
            getOptionLabel={(option) => option.description}
            renderInput={(inputProps) => (
              <TextField
                {...inputProps}
                label="All Programs"
                margin="normal"
                variant="outlined"
              />
            )}
            onChange={(e, value) => setProgram(value.id)}
          />
        </Box>
        <DataGrid
          columns={columns}
          rows={reservations}
          rowCount={total}
          pageSize={rowsPerPage}
          page={page}
          autoHeight
          loading={isLoading}
          paginationMode="server"
          onPageChange={(newPage) => setPage(newPage)}
          onPageSizeChange={(newPageSize) => setRowsPerPage(newPageSize)}
        />
        {/* </Grid> */}
      </Card>
    </Box>
  )
}
