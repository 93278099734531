
import { QualificationTier } from '@nxcr-org/web-api-interface/lib/domain_pb'

export function getOrderStatus(reservationStatus, qualificationStatus) {
  let orderStatus

  /** Using customer qualification status to identify the order status
      if reservation status is paid and customer is qualified
            .order status is Qualified
      if reservation status is paid and customer is DisQualified
            .order status is DisQualified
      if reservation status is paid and customer is qualification Pending
            .order status is Paid
    Aligns with: https://www.notion.so/nxcr/Customer-Approval-f13e968845fa499a8015e80a3ee881ab#e744d093e5744e6b9abff979960cb748
   */
  if (reservationStatus === 'PAID') {
    const isApproved =
      qualificationStatus === QualificationTier.TIER_1 ||
      qualificationStatus === QualificationTier.TIER_2 ||
      qualificationStatus === QualificationTier.TIER_3

    if (isApproved) {
      orderStatus = 'Qualified'
    } else if (QualificationTier.DISQUALIFIED === qualificationStatus) {
      orderStatus = 'Disqualified'
    } else if (
      QualificationTier.QUALIFICATION_PENDING === qualificationStatus
    ) {
      orderStatus = reservationStatus
    }
  } else {
    orderStatus = reservationStatus
  }
  return orderStatus
}
