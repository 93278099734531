import { useMutation, useQueryClient } from 'react-query'
import { NexusService } from '../global-APIs/nexus-service'
import toast from 'react-hot-toast'

import { useState } from 'react'
import { useParams } from 'react-router'

export const useEditCustomer = () => {
  const queryClient = useQueryClient()
  const { customerId } = useParams<{ customerId: string }>()
  const [isEditCustomerModalOpen, setIsEditCustomerModalOpen] =
    useState<boolean>(false)

  const { mutate: updateCustomer, error: updateCustomerError } = useMutation(
    ({
      firstName,
      lastName,
      email,
      phone,
    }: {
      firstName: string
      lastName: string
      email: string
      phone: string
    }) => {
      return NexusService.updateCustomer({
        customerId,
        firstName,
        lastName,
        email,
        phone,
      }).catch((err) => {
        console.error({
          message: 'Error',
          err,
          params: {
            firstName,
            lastName,
            email,
            phone,
          },
        })

        // ! Allow UI to handle but log error so that datadog picks up additional data
        throw err
      })
    },
    {
      onSuccess() {
        setIsEditCustomerModalOpen(false)
        toast.success('Customer details updated.')
        queryClient.invalidateQueries(['/customers', customerId])
      },
      onError() {
        toast.error('Something went wrong')
        console.log('Error: ', updateCustomerError)
      },
    }
  )

  return {
    updateCustomer,
    isEditCustomerModalOpen,
    setIsEditCustomerModalOpen,
  }
}
