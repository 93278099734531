import Button from '@mui/material/Button'
import PropTypes from 'prop-types'
import React from 'react'

FilterButton.propTypes = {
  isSelected: PropTypes.bool,
}

FilterButton.defaultProps = {
  isSelected: false,
}

export default function FilterButton({ isSelected, ...otherProps }) {
  return (
    <Button
      color={isSelected ? 'secondary' : 'primary'}
      variant={isSelected ? 'contained' : 'outlined'}
      size="large"
      sx={{
        '&:not(:last-child)': {
          marginRight: '0.75rem',
        },
      }}
      {...otherProps}
    />
  )
}
