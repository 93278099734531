import { StringParam, useQueryParams } from 'use-query-params'
import { useQuery } from 'react-query'
import { NexusService } from 'global-apis/nexus-service'

export function useInvoiceDetails() {
  const [{ invoiceId }, setQueryParams] = useQueryParams({
    invoiceId: StringParam,
  })

  const setInvoiceId = (invoiceId?: string) => {
    setQueryParams({
      invoiceId,
    })
  }

  const isInvoiceDetailsDrawerOpen = !!invoiceId


  function payInvoiceByInvoiceId(documentNumber: string, subscriptionId: string, paymentAmount: number) {
    console.log('payInvoiceByInvoiceId: ', documentNumber, subscriptionId, paymentAmount)
    const { data: success, error: payInvoiceError, refetch } = useQuery(
      ['/payInvoice'],
      () =>
        NexusService.payInvoice(documentNumber, subscriptionId, paymentAmount).then((res) => {
            return res
          }
        ),
      {
        onError() {
          console.log('Error: ', payInvoiceError)
        },
        refetchOnWindowFocus: false,
        enabled: false
      }
    )

    return {
      success,
      doPayInvoiceById: refetch}
  }

  return {
    invoiceId,
    setInvoiceId,
    isInvoiceDetailsDrawerOpen,
    payInvoiceByInvoiceId,
  }
}

