import React, { FC } from 'react'
import { Grid, Tooltip, Card, Typography, Box } from '@mui/material'
import { Alert } from '@mui/material'
import { DataGrid, GridRenderCellParams } from '@mui/x-data-grid'
import ListVehicleCell from '../vehicle-detail-cells/list-vehicle-cell'
import { ListDelistModal } from '../list-delist-modal/listDelistModal'
import FilterButton from '../filter/FilterButton'
import VehicleDetailCell from '../vehicle-detail-cells/vehicle-detail-cell'
import { useVehiclesTable } from './useVehiclesTable'
import VehicleMakeSelectField from './VehicleMakeSelectField'

export const VehiclesTable: FC = () => {
  const {
    tabs,
    vehicles,
    selectTab,
    activeTab,
    vehicleToList,
    onListVehicleClose,
    vehicleQuery,
    fetchListVehiclesError,
    listVehicle,
    listDelistMutation,
    total,
    setPage,
    setRowsPerPage,
    query,
    setQuery,
  } = useVehiclesTable()

  const isHidden = () => {
    return (
      tabs.ASSIGNED.label.toLowerCase() === activeTab ||
      tabs.SUBSCRIBED.label.toLowerCase() === activeTab
    )
  }

  const columns = [
    // {
    //   field: 'bodyType',
    //   headerName: ' ',
    //   flex: 0.5,
    //   renderCell: BodyStyleCell,
    // },
    {
      field: 'make',
      headerName: 'Make',
      flex: 0.5,
    },
    {
      field: 'model',
      headerName: 'Model',
      flex: 0.5,
    },
    {
      field: 'trim',
      headerName: 'Trim',
      flex: 1,
    },
    {
      field: 'miles',
      headerName: 'Miles',
      flex: 0.5,
    },
    {
      field: 'color',
      headerName: 'Color',
      flex: 0.5,
    },
    {
      field: 'vin',
      headerName: 'Vin',
      flex: 1,
    },
    {
      field: 'dealer',
      headerName: 'Dealership',
      flex: 1.2,
      renderCell(props: GridRenderCellParams) {
        return (
          <Tooltip title={props.row.dealerAddress}>
            <span>{props.row.dealerName}</span>
          </Tooltip>
        )
      },
    },
    {
      field: 'dealerAddress',
      headerName: 'Dealer Address',
      flex: 1.5,
      renderCell(props: GridRenderCellParams) {
        return (
          <Tooltip title={props.row.dealerAddress}>
            <span>{props.row.dealerAddress}</span>
          </Tooltip>
        )
      },
    },
    {
      field: 'list',
      headerName: 'List/Delist',
      flex: 1,
      renderCell: (props) => (
        <ListVehicleCell {...props} handler={listVehicle} />
      ),
    },
    {
      field: 'vehicleDetail',
      headerName: 'View Vehicle',
      flex: 1,
      renderCell: (props) => <VehicleDetailCell {...props} from="listings" />,
    },
  ]

  return (
    <Box>
      <Typography sx={{ marginLeft: '5px' }} variant="h1">
        Listings
      </Typography>
      {listDelistMutation.isSuccess && (
        <Grid item xs={12}>
          <Alert onClose={() => listDelistMutation.reset()}>
            Update Successful.
          </Alert>
        </Grid>
      )}
      {listDelistMutation.isError && (
        <Grid item xs={12}>
          <Alert severity="error" onClose={() => listDelistMutation.reset()}>
            {listDelistMutation.error?.message}
          </Alert>
        </Grid>
      )}
      <Card>
        <Box sx={{ display: 'flex', margin: '20px 10px' }}>
          <FilterButton
            isSelected={tabs.ELIGIBLE.label.toLowerCase() === activeTab}
            onClick={() => selectTab(tabs.ELIGIBLE.label)}
          >
            {tabs.ELIGIBLE.label}
          </FilterButton>
          <FilterButton
            isSelected={tabs.INELIGIBLE.label.toLowerCase() === activeTab}
            onClick={() => selectTab(tabs.INELIGIBLE.label)}
          >
            {tabs.INELIGIBLE.label}
          </FilterButton>
          <FilterButton
            isSelected={tabs.ASSIGNED.label.toLowerCase() === activeTab}
            onClick={() => selectTab(tabs.ASSIGNED.label)}
          >
            {tabs.ASSIGNED.label}
          </FilterButton>
          <FilterButton
            isSelected={tabs.SUBSCRIBED.label.toLowerCase() === activeTab}
            onClick={() => selectTab(tabs.SUBSCRIBED.label)}
          >
            {tabs.SUBSCRIBED.label}
          </FilterButton>
          <VehicleMakeSelectField
            onChange={(selectedMake) => setQuery({ make: selectedMake })}
            value={query.make}
          />
        </Box>
        {/* TODO: NEED TO CHECK WITH UX TEAM AND CHANGE THE WAY OF ERROR HANDLING */}
        {!fetchListVehiclesError && (
          <DataGrid
            autoHeight
            rows={vehicles}
            rowCount={total}
            columns={columns}
            loading={vehicleQuery.isLoading || vehicleQuery.isFetching}
            columnVisibilityModel={{ list: !isHidden() }}
            disableColumnFilter
            paginationMode="server"
            pageSize={query.rowsPerPage}
            page={query.page}
            onPageChange={(newPage) => setPage(newPage)}
            onPageSizeChange={(newPageSize) => setRowsPerPage(newPageSize)}
          />
        )}
        {!!vehicleToList.id && (
          <ListDelistModal
            open={!!vehicleToList.id}
            onClose={onListVehicleClose}
            listDelistMutation={listDelistMutation}
          />
        )}
      </Card>
    </Box>
  )
}
