import { Dealership } from '@nxcr-org/web-api-interface/lib/dealer_pb'

/**
 * @TODO create a format address that handles only the string concatenation
 */
export function formatDealerAddress(
  address: Dealership.AsObject['address'],
): string {
  return [
    address?.street,
    address?.street2,
    address?.city,
    address?.state,
    address?.postal,
  ]
    .filter((n) => !!n)
    .join(', ')
}