// source: dealership_service.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var dealer_pb = require('./dealer_pb.js');
goog.object.extend(proto, dealer_pb);
goog.exportSymbol('proto.dealership_service.CreateDealershipRequest', null, global);
goog.exportSymbol('proto.dealership_service.CreateDealershipResponse', null, global);
goog.exportSymbol('proto.dealership_service.CreateDealershipUserRequest', null, global);
goog.exportSymbol('proto.dealership_service.CreateDealershipUserResponse', null, global);
goog.exportSymbol('proto.dealership_service.DealershipUserSignaturesRequest', null, global);
goog.exportSymbol('proto.dealership_service.DealershipUserSignaturesResponse', null, global);
goog.exportSymbol('proto.dealership_service.GetDealerUserInfoRequest', null, global);
goog.exportSymbol('proto.dealership_service.GetDealerUserInfoResponse', null, global);
goog.exportSymbol('proto.dealership_service.GetDealershipUserByPhoneRequest', null, global);
goog.exportSymbol('proto.dealership_service.GetDealershipUserByPhoneResponse', null, global);
goog.exportSymbol('proto.dealership_service.GetFleetDealershipInfoRequest', null, global);
goog.exportSymbol('proto.dealership_service.GetFleetDealershipInfoResponse', null, global);
goog.exportSymbol('proto.dealership_service.GetSelfRequest', null, global);
goog.exportSymbol('proto.dealership_service.GetSelfResponse', null, global);
goog.exportSymbol('proto.dealership_service.ListDealershipsRequest', null, global);
goog.exportSymbol('proto.dealership_service.ListDealershipsResponse', null, global);
goog.exportSymbol('proto.dealership_service.UpdateDealershipRequest', null, global);
goog.exportSymbol('proto.dealership_service.UpdateDealershipResponse', null, global);
goog.exportSymbol('proto.dealership_service.UpdateDealershipUserRequest', null, global);
goog.exportSymbol('proto.dealership_service.UpdateDealershipUserResponse', null, global);
goog.exportSymbol('proto.dealership_service.ViewTeamRequest', null, global);
goog.exportSymbol('proto.dealership_service.ViewTeamResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealership_service.ListDealershipsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealership_service.ListDealershipsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealership_service.ListDealershipsRequest.displayName = 'proto.dealership_service.ListDealershipsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealership_service.ListDealershipsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dealership_service.ListDealershipsResponse.repeatedFields_, null);
};
goog.inherits(proto.dealership_service.ListDealershipsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealership_service.ListDealershipsResponse.displayName = 'proto.dealership_service.ListDealershipsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealership_service.CreateDealershipRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealership_service.CreateDealershipRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealership_service.CreateDealershipRequest.displayName = 'proto.dealership_service.CreateDealershipRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealership_service.CreateDealershipResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealership_service.CreateDealershipResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealership_service.CreateDealershipResponse.displayName = 'proto.dealership_service.CreateDealershipResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealership_service.CreateDealershipUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealership_service.CreateDealershipUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealership_service.CreateDealershipUserRequest.displayName = 'proto.dealership_service.CreateDealershipUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealership_service.CreateDealershipUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealership_service.CreateDealershipUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealership_service.CreateDealershipUserResponse.displayName = 'proto.dealership_service.CreateDealershipUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealership_service.GetFleetDealershipInfoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealership_service.GetFleetDealershipInfoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealership_service.GetFleetDealershipInfoRequest.displayName = 'proto.dealership_service.GetFleetDealershipInfoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealership_service.GetFleetDealershipInfoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealership_service.GetFleetDealershipInfoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealership_service.GetFleetDealershipInfoResponse.displayName = 'proto.dealership_service.GetFleetDealershipInfoResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealership_service.GetDealerUserInfoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealership_service.GetDealerUserInfoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealership_service.GetDealerUserInfoRequest.displayName = 'proto.dealership_service.GetDealerUserInfoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealership_service.GetDealerUserInfoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealership_service.GetDealerUserInfoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealership_service.GetDealerUserInfoResponse.displayName = 'proto.dealership_service.GetDealerUserInfoResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealership_service.ViewTeamRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealership_service.ViewTeamRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealership_service.ViewTeamRequest.displayName = 'proto.dealership_service.ViewTeamRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealership_service.ViewTeamResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dealership_service.ViewTeamResponse.repeatedFields_, null);
};
goog.inherits(proto.dealership_service.ViewTeamResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealership_service.ViewTeamResponse.displayName = 'proto.dealership_service.ViewTeamResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealership_service.UpdateDealershipUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealership_service.UpdateDealershipUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealership_service.UpdateDealershipUserRequest.displayName = 'proto.dealership_service.UpdateDealershipUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealership_service.UpdateDealershipUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealership_service.UpdateDealershipUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealership_service.UpdateDealershipUserResponse.displayName = 'proto.dealership_service.UpdateDealershipUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealership_service.DealershipUserSignaturesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealership_service.DealershipUserSignaturesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealership_service.DealershipUserSignaturesRequest.displayName = 'proto.dealership_service.DealershipUserSignaturesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealership_service.DealershipUserSignaturesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealership_service.DealershipUserSignaturesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealership_service.DealershipUserSignaturesResponse.displayName = 'proto.dealership_service.DealershipUserSignaturesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealership_service.UpdateDealershipRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealership_service.UpdateDealershipRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealership_service.UpdateDealershipRequest.displayName = 'proto.dealership_service.UpdateDealershipRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealership_service.UpdateDealershipResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealership_service.UpdateDealershipResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealership_service.UpdateDealershipResponse.displayName = 'proto.dealership_service.UpdateDealershipResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealership_service.GetSelfRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealership_service.GetSelfRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealership_service.GetSelfRequest.displayName = 'proto.dealership_service.GetSelfRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealership_service.GetSelfResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealership_service.GetSelfResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealership_service.GetSelfResponse.displayName = 'proto.dealership_service.GetSelfResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealership_service.GetDealershipUserByPhoneRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealership_service.GetDealershipUserByPhoneRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealership_service.GetDealershipUserByPhoneRequest.displayName = 'proto.dealership_service.GetDealershipUserByPhoneRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealership_service.GetDealershipUserByPhoneResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealership_service.GetDealershipUserByPhoneResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealership_service.GetDealershipUserByPhoneResponse.displayName = 'proto.dealership_service.GetDealershipUserByPhoneResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealership_service.ListDealershipsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dealership_service.ListDealershipsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealership_service.ListDealershipsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealership_service.ListDealershipsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealership_service.ListDealershipsRequest}
 */
proto.dealership_service.ListDealershipsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealership_service.ListDealershipsRequest;
  return proto.dealership_service.ListDealershipsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealership_service.ListDealershipsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealership_service.ListDealershipsRequest}
 */
proto.dealership_service.ListDealershipsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealership_service.ListDealershipsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealership_service.ListDealershipsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealership_service.ListDealershipsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealership_service.ListDealershipsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dealership_service.ListDealershipsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealership_service.ListDealershipsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dealership_service.ListDealershipsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealership_service.ListDealershipsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealership_service.ListDealershipsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealersList: jspb.Message.toObjectList(msg.getDealersList(),
    dealer_pb.Dealership.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealership_service.ListDealershipsResponse}
 */
proto.dealership_service.ListDealershipsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealership_service.ListDealershipsResponse;
  return proto.dealership_service.ListDealershipsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealership_service.ListDealershipsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealership_service.ListDealershipsResponse}
 */
proto.dealership_service.ListDealershipsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new dealer_pb.Dealership;
      reader.readMessage(value,dealer_pb.Dealership.deserializeBinaryFromReader);
      msg.addDealers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealership_service.ListDealershipsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealership_service.ListDealershipsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealership_service.ListDealershipsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealership_service.ListDealershipsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      dealer_pb.Dealership.serializeBinaryToWriter
    );
  }
};


/**
 * repeated dealer.Dealership dealers = 1;
 * @return {!Array<!proto.dealer.Dealership>}
 */
proto.dealership_service.ListDealershipsResponse.prototype.getDealersList = function() {
  return /** @type{!Array<!proto.dealer.Dealership>} */ (
    jspb.Message.getRepeatedWrapperField(this, dealer_pb.Dealership, 1));
};


/**
 * @param {!Array<!proto.dealer.Dealership>} value
 * @return {!proto.dealership_service.ListDealershipsResponse} returns this
*/
proto.dealership_service.ListDealershipsResponse.prototype.setDealersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.dealer.Dealership=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dealer.Dealership}
 */
proto.dealership_service.ListDealershipsResponse.prototype.addDealers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.dealer.Dealership, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dealership_service.ListDealershipsResponse} returns this
 */
proto.dealership_service.ListDealershipsResponse.prototype.clearDealersList = function() {
  return this.setDealersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealership_service.CreateDealershipRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dealership_service.CreateDealershipRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealership_service.CreateDealershipRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealership_service.CreateDealershipRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealership: (f = msg.getDealership()) && dealer_pb.Dealership.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealership_service.CreateDealershipRequest}
 */
proto.dealership_service.CreateDealershipRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealership_service.CreateDealershipRequest;
  return proto.dealership_service.CreateDealershipRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealership_service.CreateDealershipRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealership_service.CreateDealershipRequest}
 */
proto.dealership_service.CreateDealershipRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new dealer_pb.Dealership;
      reader.readMessage(value,dealer_pb.Dealership.deserializeBinaryFromReader);
      msg.setDealership(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealership_service.CreateDealershipRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealership_service.CreateDealershipRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealership_service.CreateDealershipRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealership_service.CreateDealershipRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealership();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      dealer_pb.Dealership.serializeBinaryToWriter
    );
  }
};


/**
 * optional dealer.Dealership dealership = 1;
 * @return {?proto.dealer.Dealership}
 */
proto.dealership_service.CreateDealershipRequest.prototype.getDealership = function() {
  return /** @type{?proto.dealer.Dealership} */ (
    jspb.Message.getWrapperField(this, dealer_pb.Dealership, 1));
};


/**
 * @param {?proto.dealer.Dealership|undefined} value
 * @return {!proto.dealership_service.CreateDealershipRequest} returns this
*/
proto.dealership_service.CreateDealershipRequest.prototype.setDealership = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dealership_service.CreateDealershipRequest} returns this
 */
proto.dealership_service.CreateDealershipRequest.prototype.clearDealership = function() {
  return this.setDealership(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dealership_service.CreateDealershipRequest.prototype.hasDealership = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealership_service.CreateDealershipResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dealership_service.CreateDealershipResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealership_service.CreateDealershipResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealership_service.CreateDealershipResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealership: (f = msg.getDealership()) && dealer_pb.Dealership.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealership_service.CreateDealershipResponse}
 */
proto.dealership_service.CreateDealershipResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealership_service.CreateDealershipResponse;
  return proto.dealership_service.CreateDealershipResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealership_service.CreateDealershipResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealership_service.CreateDealershipResponse}
 */
proto.dealership_service.CreateDealershipResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new dealer_pb.Dealership;
      reader.readMessage(value,dealer_pb.Dealership.deserializeBinaryFromReader);
      msg.setDealership(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealership_service.CreateDealershipResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealership_service.CreateDealershipResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealership_service.CreateDealershipResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealership_service.CreateDealershipResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealership();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      dealer_pb.Dealership.serializeBinaryToWriter
    );
  }
};


/**
 * optional dealer.Dealership dealership = 1;
 * @return {?proto.dealer.Dealership}
 */
proto.dealership_service.CreateDealershipResponse.prototype.getDealership = function() {
  return /** @type{?proto.dealer.Dealership} */ (
    jspb.Message.getWrapperField(this, dealer_pb.Dealership, 1));
};


/**
 * @param {?proto.dealer.Dealership|undefined} value
 * @return {!proto.dealership_service.CreateDealershipResponse} returns this
*/
proto.dealership_service.CreateDealershipResponse.prototype.setDealership = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dealership_service.CreateDealershipResponse} returns this
 */
proto.dealership_service.CreateDealershipResponse.prototype.clearDealership = function() {
  return this.setDealership(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dealership_service.CreateDealershipResponse.prototype.hasDealership = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealership_service.CreateDealershipUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dealership_service.CreateDealershipUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealership_service.CreateDealershipUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealership_service.CreateDealershipUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealershipUser: (f = msg.getDealershipUser()) && dealer_pb.DealershipUser.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealership_service.CreateDealershipUserRequest}
 */
proto.dealership_service.CreateDealershipUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealership_service.CreateDealershipUserRequest;
  return proto.dealership_service.CreateDealershipUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealership_service.CreateDealershipUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealership_service.CreateDealershipUserRequest}
 */
proto.dealership_service.CreateDealershipUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new dealer_pb.DealershipUser;
      reader.readMessage(value,dealer_pb.DealershipUser.deserializeBinaryFromReader);
      msg.setDealershipUser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealership_service.CreateDealershipUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealership_service.CreateDealershipUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealership_service.CreateDealershipUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealership_service.CreateDealershipUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealershipUser();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      dealer_pb.DealershipUser.serializeBinaryToWriter
    );
  }
};


/**
 * optional dealer.DealershipUser dealership_user = 1;
 * @return {?proto.dealer.DealershipUser}
 */
proto.dealership_service.CreateDealershipUserRequest.prototype.getDealershipUser = function() {
  return /** @type{?proto.dealer.DealershipUser} */ (
    jspb.Message.getWrapperField(this, dealer_pb.DealershipUser, 1));
};


/**
 * @param {?proto.dealer.DealershipUser|undefined} value
 * @return {!proto.dealership_service.CreateDealershipUserRequest} returns this
*/
proto.dealership_service.CreateDealershipUserRequest.prototype.setDealershipUser = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dealership_service.CreateDealershipUserRequest} returns this
 */
proto.dealership_service.CreateDealershipUserRequest.prototype.clearDealershipUser = function() {
  return this.setDealershipUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dealership_service.CreateDealershipUserRequest.prototype.hasDealershipUser = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealership_service.CreateDealershipUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dealership_service.CreateDealershipUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealership_service.CreateDealershipUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealership_service.CreateDealershipUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealershipUser: (f = msg.getDealershipUser()) && dealer_pb.DealershipUser.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealership_service.CreateDealershipUserResponse}
 */
proto.dealership_service.CreateDealershipUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealership_service.CreateDealershipUserResponse;
  return proto.dealership_service.CreateDealershipUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealership_service.CreateDealershipUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealership_service.CreateDealershipUserResponse}
 */
proto.dealership_service.CreateDealershipUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new dealer_pb.DealershipUser;
      reader.readMessage(value,dealer_pb.DealershipUser.deserializeBinaryFromReader);
      msg.setDealershipUser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealership_service.CreateDealershipUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealership_service.CreateDealershipUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealership_service.CreateDealershipUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealership_service.CreateDealershipUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealershipUser();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      dealer_pb.DealershipUser.serializeBinaryToWriter
    );
  }
};


/**
 * optional dealer.DealershipUser dealership_user = 1;
 * @return {?proto.dealer.DealershipUser}
 */
proto.dealership_service.CreateDealershipUserResponse.prototype.getDealershipUser = function() {
  return /** @type{?proto.dealer.DealershipUser} */ (
    jspb.Message.getWrapperField(this, dealer_pb.DealershipUser, 1));
};


/**
 * @param {?proto.dealer.DealershipUser|undefined} value
 * @return {!proto.dealership_service.CreateDealershipUserResponse} returns this
*/
proto.dealership_service.CreateDealershipUserResponse.prototype.setDealershipUser = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dealership_service.CreateDealershipUserResponse} returns this
 */
proto.dealership_service.CreateDealershipUserResponse.prototype.clearDealershipUser = function() {
  return this.setDealershipUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dealership_service.CreateDealershipUserResponse.prototype.hasDealershipUser = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealership_service.GetFleetDealershipInfoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dealership_service.GetFleetDealershipInfoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealership_service.GetFleetDealershipInfoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealership_service.GetFleetDealershipInfoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealerId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealership_service.GetFleetDealershipInfoRequest}
 */
proto.dealership_service.GetFleetDealershipInfoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealership_service.GetFleetDealershipInfoRequest;
  return proto.dealership_service.GetFleetDealershipInfoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealership_service.GetFleetDealershipInfoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealership_service.GetFleetDealershipInfoRequest}
 */
proto.dealership_service.GetFleetDealershipInfoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealership_service.GetFleetDealershipInfoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealership_service.GetFleetDealershipInfoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealership_service.GetFleetDealershipInfoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealership_service.GetFleetDealershipInfoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string dealer_id = 1;
 * @return {string}
 */
proto.dealership_service.GetFleetDealershipInfoRequest.prototype.getDealerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealership_service.GetFleetDealershipInfoRequest} returns this
 */
proto.dealership_service.GetFleetDealershipInfoRequest.prototype.setDealerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealership_service.GetFleetDealershipInfoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dealership_service.GetFleetDealershipInfoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealership_service.GetFleetDealershipInfoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealership_service.GetFleetDealershipInfoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealership: (f = msg.getDealership()) && dealer_pb.Dealership.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealership_service.GetFleetDealershipInfoResponse}
 */
proto.dealership_service.GetFleetDealershipInfoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealership_service.GetFleetDealershipInfoResponse;
  return proto.dealership_service.GetFleetDealershipInfoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealership_service.GetFleetDealershipInfoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealership_service.GetFleetDealershipInfoResponse}
 */
proto.dealership_service.GetFleetDealershipInfoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new dealer_pb.Dealership;
      reader.readMessage(value,dealer_pb.Dealership.deserializeBinaryFromReader);
      msg.setDealership(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealership_service.GetFleetDealershipInfoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealership_service.GetFleetDealershipInfoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealership_service.GetFleetDealershipInfoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealership_service.GetFleetDealershipInfoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealership();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      dealer_pb.Dealership.serializeBinaryToWriter
    );
  }
};


/**
 * optional dealer.Dealership dealership = 1;
 * @return {?proto.dealer.Dealership}
 */
proto.dealership_service.GetFleetDealershipInfoResponse.prototype.getDealership = function() {
  return /** @type{?proto.dealer.Dealership} */ (
    jspb.Message.getWrapperField(this, dealer_pb.Dealership, 1));
};


/**
 * @param {?proto.dealer.Dealership|undefined} value
 * @return {!proto.dealership_service.GetFleetDealershipInfoResponse} returns this
*/
proto.dealership_service.GetFleetDealershipInfoResponse.prototype.setDealership = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dealership_service.GetFleetDealershipInfoResponse} returns this
 */
proto.dealership_service.GetFleetDealershipInfoResponse.prototype.clearDealership = function() {
  return this.setDealership(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dealership_service.GetFleetDealershipInfoResponse.prototype.hasDealership = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealership_service.GetDealerUserInfoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dealership_service.GetDealerUserInfoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealership_service.GetDealerUserInfoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealership_service.GetDealerUserInfoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealerUserId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealership_service.GetDealerUserInfoRequest}
 */
proto.dealership_service.GetDealerUserInfoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealership_service.GetDealerUserInfoRequest;
  return proto.dealership_service.GetDealerUserInfoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealership_service.GetDealerUserInfoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealership_service.GetDealerUserInfoRequest}
 */
proto.dealership_service.GetDealerUserInfoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealerUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealership_service.GetDealerUserInfoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealership_service.GetDealerUserInfoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealership_service.GetDealerUserInfoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealership_service.GetDealerUserInfoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealerUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string dealer_user_id = 1;
 * @return {string}
 */
proto.dealership_service.GetDealerUserInfoRequest.prototype.getDealerUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealership_service.GetDealerUserInfoRequest} returns this
 */
proto.dealership_service.GetDealerUserInfoRequest.prototype.setDealerUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealership_service.GetDealerUserInfoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dealership_service.GetDealerUserInfoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealership_service.GetDealerUserInfoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealership_service.GetDealerUserInfoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealerUser: (f = msg.getDealerUser()) && dealer_pb.DealershipUser.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealership_service.GetDealerUserInfoResponse}
 */
proto.dealership_service.GetDealerUserInfoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealership_service.GetDealerUserInfoResponse;
  return proto.dealership_service.GetDealerUserInfoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealership_service.GetDealerUserInfoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealership_service.GetDealerUserInfoResponse}
 */
proto.dealership_service.GetDealerUserInfoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new dealer_pb.DealershipUser;
      reader.readMessage(value,dealer_pb.DealershipUser.deserializeBinaryFromReader);
      msg.setDealerUser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealership_service.GetDealerUserInfoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealership_service.GetDealerUserInfoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealership_service.GetDealerUserInfoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealership_service.GetDealerUserInfoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealerUser();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      dealer_pb.DealershipUser.serializeBinaryToWriter
    );
  }
};


/**
 * optional dealer.DealershipUser dealer_user = 1;
 * @return {?proto.dealer.DealershipUser}
 */
proto.dealership_service.GetDealerUserInfoResponse.prototype.getDealerUser = function() {
  return /** @type{?proto.dealer.DealershipUser} */ (
    jspb.Message.getWrapperField(this, dealer_pb.DealershipUser, 1));
};


/**
 * @param {?proto.dealer.DealershipUser|undefined} value
 * @return {!proto.dealership_service.GetDealerUserInfoResponse} returns this
*/
proto.dealership_service.GetDealerUserInfoResponse.prototype.setDealerUser = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dealership_service.GetDealerUserInfoResponse} returns this
 */
proto.dealership_service.GetDealerUserInfoResponse.prototype.clearDealerUser = function() {
  return this.setDealerUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dealership_service.GetDealerUserInfoResponse.prototype.hasDealerUser = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealership_service.ViewTeamRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dealership_service.ViewTeamRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealership_service.ViewTeamRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealership_service.ViewTeamRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealershipId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealership_service.ViewTeamRequest}
 */
proto.dealership_service.ViewTeamRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealership_service.ViewTeamRequest;
  return proto.dealership_service.ViewTeamRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealership_service.ViewTeamRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealership_service.ViewTeamRequest}
 */
proto.dealership_service.ViewTeamRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealershipId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealership_service.ViewTeamRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealership_service.ViewTeamRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealership_service.ViewTeamRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealership_service.ViewTeamRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealershipId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string dealership_id = 1;
 * @return {string}
 */
proto.dealership_service.ViewTeamRequest.prototype.getDealershipId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealership_service.ViewTeamRequest} returns this
 */
proto.dealership_service.ViewTeamRequest.prototype.setDealershipId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dealership_service.ViewTeamResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealership_service.ViewTeamResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dealership_service.ViewTeamResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealership_service.ViewTeamResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealership_service.ViewTeamResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealershipTeamList: jspb.Message.toObjectList(msg.getDealershipTeamList(),
    dealer_pb.DealershipUser.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealership_service.ViewTeamResponse}
 */
proto.dealership_service.ViewTeamResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealership_service.ViewTeamResponse;
  return proto.dealership_service.ViewTeamResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealership_service.ViewTeamResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealership_service.ViewTeamResponse}
 */
proto.dealership_service.ViewTeamResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new dealer_pb.DealershipUser;
      reader.readMessage(value,dealer_pb.DealershipUser.deserializeBinaryFromReader);
      msg.addDealershipTeam(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealership_service.ViewTeamResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealership_service.ViewTeamResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealership_service.ViewTeamResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealership_service.ViewTeamResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealershipTeamList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      dealer_pb.DealershipUser.serializeBinaryToWriter
    );
  }
};


/**
 * repeated dealer.DealershipUser dealership_team = 1;
 * @return {!Array<!proto.dealer.DealershipUser>}
 */
proto.dealership_service.ViewTeamResponse.prototype.getDealershipTeamList = function() {
  return /** @type{!Array<!proto.dealer.DealershipUser>} */ (
    jspb.Message.getRepeatedWrapperField(this, dealer_pb.DealershipUser, 1));
};


/**
 * @param {!Array<!proto.dealer.DealershipUser>} value
 * @return {!proto.dealership_service.ViewTeamResponse} returns this
*/
proto.dealership_service.ViewTeamResponse.prototype.setDealershipTeamList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.dealer.DealershipUser=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dealer.DealershipUser}
 */
proto.dealership_service.ViewTeamResponse.prototype.addDealershipTeam = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.dealer.DealershipUser, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dealership_service.ViewTeamResponse} returns this
 */
proto.dealership_service.ViewTeamResponse.prototype.clearDealershipTeamList = function() {
  return this.setDealershipTeamList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealership_service.UpdateDealershipUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dealership_service.UpdateDealershipUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealership_service.UpdateDealershipUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealership_service.UpdateDealershipUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealershipUser: (f = msg.getDealershipUser()) && dealer_pb.DealershipUser.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealership_service.UpdateDealershipUserRequest}
 */
proto.dealership_service.UpdateDealershipUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealership_service.UpdateDealershipUserRequest;
  return proto.dealership_service.UpdateDealershipUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealership_service.UpdateDealershipUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealership_service.UpdateDealershipUserRequest}
 */
proto.dealership_service.UpdateDealershipUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new dealer_pb.DealershipUser;
      reader.readMessage(value,dealer_pb.DealershipUser.deserializeBinaryFromReader);
      msg.setDealershipUser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealership_service.UpdateDealershipUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealership_service.UpdateDealershipUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealership_service.UpdateDealershipUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealership_service.UpdateDealershipUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealershipUser();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      dealer_pb.DealershipUser.serializeBinaryToWriter
    );
  }
};


/**
 * optional dealer.DealershipUser dealership_user = 1;
 * @return {?proto.dealer.DealershipUser}
 */
proto.dealership_service.UpdateDealershipUserRequest.prototype.getDealershipUser = function() {
  return /** @type{?proto.dealer.DealershipUser} */ (
    jspb.Message.getWrapperField(this, dealer_pb.DealershipUser, 1));
};


/**
 * @param {?proto.dealer.DealershipUser|undefined} value
 * @return {!proto.dealership_service.UpdateDealershipUserRequest} returns this
*/
proto.dealership_service.UpdateDealershipUserRequest.prototype.setDealershipUser = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dealership_service.UpdateDealershipUserRequest} returns this
 */
proto.dealership_service.UpdateDealershipUserRequest.prototype.clearDealershipUser = function() {
  return this.setDealershipUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dealership_service.UpdateDealershipUserRequest.prototype.hasDealershipUser = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealership_service.UpdateDealershipUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dealership_service.UpdateDealershipUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealership_service.UpdateDealershipUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealership_service.UpdateDealershipUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealershipUserId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealership_service.UpdateDealershipUserResponse}
 */
proto.dealership_service.UpdateDealershipUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealership_service.UpdateDealershipUserResponse;
  return proto.dealership_service.UpdateDealershipUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealership_service.UpdateDealershipUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealership_service.UpdateDealershipUserResponse}
 */
proto.dealership_service.UpdateDealershipUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealershipUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealership_service.UpdateDealershipUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealership_service.UpdateDealershipUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealership_service.UpdateDealershipUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealership_service.UpdateDealershipUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealershipUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string dealership_user_id = 1;
 * @return {string}
 */
proto.dealership_service.UpdateDealershipUserResponse.prototype.getDealershipUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealership_service.UpdateDealershipUserResponse} returns this
 */
proto.dealership_service.UpdateDealershipUserResponse.prototype.setDealershipUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealership_service.DealershipUserSignaturesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dealership_service.DealershipUserSignaturesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealership_service.DealershipUserSignaturesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealership_service.DealershipUserSignaturesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealerUserId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tos: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    privacyPolicy: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    eSignature: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealership_service.DealershipUserSignaturesRequest}
 */
proto.dealership_service.DealershipUserSignaturesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealership_service.DealershipUserSignaturesRequest;
  return proto.dealership_service.DealershipUserSignaturesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealership_service.DealershipUserSignaturesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealership_service.DealershipUserSignaturesRequest}
 */
proto.dealership_service.DealershipUserSignaturesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealerUserId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTos(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPrivacyPolicy(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setESignature(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealership_service.DealershipUserSignaturesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealership_service.DealershipUserSignaturesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealership_service.DealershipUserSignaturesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealership_service.DealershipUserSignaturesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealerUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTos();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getPrivacyPolicy();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getESignature();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string dealer_user_id = 1;
 * @return {string}
 */
proto.dealership_service.DealershipUserSignaturesRequest.prototype.getDealerUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealership_service.DealershipUserSignaturesRequest} returns this
 */
proto.dealership_service.DealershipUserSignaturesRequest.prototype.setDealerUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool tos = 2;
 * @return {boolean}
 */
proto.dealership_service.DealershipUserSignaturesRequest.prototype.getTos = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dealership_service.DealershipUserSignaturesRequest} returns this
 */
proto.dealership_service.DealershipUserSignaturesRequest.prototype.setTos = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool privacy_policy = 3;
 * @return {boolean}
 */
proto.dealership_service.DealershipUserSignaturesRequest.prototype.getPrivacyPolicy = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dealership_service.DealershipUserSignaturesRequest} returns this
 */
proto.dealership_service.DealershipUserSignaturesRequest.prototype.setPrivacyPolicy = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool e_signature = 4;
 * @return {boolean}
 */
proto.dealership_service.DealershipUserSignaturesRequest.prototype.getESignature = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dealership_service.DealershipUserSignaturesRequest} returns this
 */
proto.dealership_service.DealershipUserSignaturesRequest.prototype.setESignature = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealership_service.DealershipUserSignaturesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dealership_service.DealershipUserSignaturesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealership_service.DealershipUserSignaturesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealership_service.DealershipUserSignaturesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealerUserId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealership_service.DealershipUserSignaturesResponse}
 */
proto.dealership_service.DealershipUserSignaturesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealership_service.DealershipUserSignaturesResponse;
  return proto.dealership_service.DealershipUserSignaturesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealership_service.DealershipUserSignaturesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealership_service.DealershipUserSignaturesResponse}
 */
proto.dealership_service.DealershipUserSignaturesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealerUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealership_service.DealershipUserSignaturesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealership_service.DealershipUserSignaturesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealership_service.DealershipUserSignaturesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealership_service.DealershipUserSignaturesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealerUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string dealer_user_id = 1;
 * @return {string}
 */
proto.dealership_service.DealershipUserSignaturesResponse.prototype.getDealerUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealership_service.DealershipUserSignaturesResponse} returns this
 */
proto.dealership_service.DealershipUserSignaturesResponse.prototype.setDealerUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealership_service.UpdateDealershipRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dealership_service.UpdateDealershipRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealership_service.UpdateDealershipRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealership_service.UpdateDealershipRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealership: (f = msg.getDealership()) && dealer_pb.Dealership.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealership_service.UpdateDealershipRequest}
 */
proto.dealership_service.UpdateDealershipRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealership_service.UpdateDealershipRequest;
  return proto.dealership_service.UpdateDealershipRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealership_service.UpdateDealershipRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealership_service.UpdateDealershipRequest}
 */
proto.dealership_service.UpdateDealershipRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new dealer_pb.Dealership;
      reader.readMessage(value,dealer_pb.Dealership.deserializeBinaryFromReader);
      msg.setDealership(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealership_service.UpdateDealershipRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealership_service.UpdateDealershipRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealership_service.UpdateDealershipRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealership_service.UpdateDealershipRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealership();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      dealer_pb.Dealership.serializeBinaryToWriter
    );
  }
};


/**
 * optional dealer.Dealership dealership = 1;
 * @return {?proto.dealer.Dealership}
 */
proto.dealership_service.UpdateDealershipRequest.prototype.getDealership = function() {
  return /** @type{?proto.dealer.Dealership} */ (
    jspb.Message.getWrapperField(this, dealer_pb.Dealership, 1));
};


/**
 * @param {?proto.dealer.Dealership|undefined} value
 * @return {!proto.dealership_service.UpdateDealershipRequest} returns this
*/
proto.dealership_service.UpdateDealershipRequest.prototype.setDealership = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dealership_service.UpdateDealershipRequest} returns this
 */
proto.dealership_service.UpdateDealershipRequest.prototype.clearDealership = function() {
  return this.setDealership(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dealership_service.UpdateDealershipRequest.prototype.hasDealership = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealership_service.UpdateDealershipResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dealership_service.UpdateDealershipResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealership_service.UpdateDealershipResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealership_service.UpdateDealershipResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealershipId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealership_service.UpdateDealershipResponse}
 */
proto.dealership_service.UpdateDealershipResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealership_service.UpdateDealershipResponse;
  return proto.dealership_service.UpdateDealershipResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealership_service.UpdateDealershipResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealership_service.UpdateDealershipResponse}
 */
proto.dealership_service.UpdateDealershipResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealershipId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealership_service.UpdateDealershipResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealership_service.UpdateDealershipResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealership_service.UpdateDealershipResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealership_service.UpdateDealershipResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealershipId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string dealership_id = 1;
 * @return {string}
 */
proto.dealership_service.UpdateDealershipResponse.prototype.getDealershipId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealership_service.UpdateDealershipResponse} returns this
 */
proto.dealership_service.UpdateDealershipResponse.prototype.setDealershipId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealership_service.GetSelfRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dealership_service.GetSelfRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealership_service.GetSelfRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealership_service.GetSelfRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealership_service.GetSelfRequest}
 */
proto.dealership_service.GetSelfRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealership_service.GetSelfRequest;
  return proto.dealership_service.GetSelfRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealership_service.GetSelfRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealership_service.GetSelfRequest}
 */
proto.dealership_service.GetSelfRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealership_service.GetSelfRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealership_service.GetSelfRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealership_service.GetSelfRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealership_service.GetSelfRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealership_service.GetSelfResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dealership_service.GetSelfResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealership_service.GetSelfResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealership_service.GetSelfResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    user: (f = msg.getUser()) && dealer_pb.DealershipUser.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealership_service.GetSelfResponse}
 */
proto.dealership_service.GetSelfResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealership_service.GetSelfResponse;
  return proto.dealership_service.GetSelfResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealership_service.GetSelfResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealership_service.GetSelfResponse}
 */
proto.dealership_service.GetSelfResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new dealer_pb.DealershipUser;
      reader.readMessage(value,dealer_pb.DealershipUser.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealership_service.GetSelfResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealership_service.GetSelfResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealership_service.GetSelfResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealership_service.GetSelfResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      dealer_pb.DealershipUser.serializeBinaryToWriter
    );
  }
};


/**
 * optional dealer.DealershipUser user = 1;
 * @return {?proto.dealer.DealershipUser}
 */
proto.dealership_service.GetSelfResponse.prototype.getUser = function() {
  return /** @type{?proto.dealer.DealershipUser} */ (
    jspb.Message.getWrapperField(this, dealer_pb.DealershipUser, 1));
};


/**
 * @param {?proto.dealer.DealershipUser|undefined} value
 * @return {!proto.dealership_service.GetSelfResponse} returns this
*/
proto.dealership_service.GetSelfResponse.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dealership_service.GetSelfResponse} returns this
 */
proto.dealership_service.GetSelfResponse.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dealership_service.GetSelfResponse.prototype.hasUser = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealership_service.GetDealershipUserByPhoneRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dealership_service.GetDealershipUserByPhoneRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealership_service.GetDealershipUserByPhoneRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealership_service.GetDealershipUserByPhoneRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    phone: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealership_service.GetDealershipUserByPhoneRequest}
 */
proto.dealership_service.GetDealershipUserByPhoneRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealership_service.GetDealershipUserByPhoneRequest;
  return proto.dealership_service.GetDealershipUserByPhoneRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealership_service.GetDealershipUserByPhoneRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealership_service.GetDealershipUserByPhoneRequest}
 */
proto.dealership_service.GetDealershipUserByPhoneRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealership_service.GetDealershipUserByPhoneRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealership_service.GetDealershipUserByPhoneRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealership_service.GetDealershipUserByPhoneRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealership_service.GetDealershipUserByPhoneRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string phone = 1;
 * @return {string}
 */
proto.dealership_service.GetDealershipUserByPhoneRequest.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealership_service.GetDealershipUserByPhoneRequest} returns this
 */
proto.dealership_service.GetDealershipUserByPhoneRequest.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealership_service.GetDealershipUserByPhoneResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dealership_service.GetDealershipUserByPhoneResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealership_service.GetDealershipUserByPhoneResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealership_service.GetDealershipUserByPhoneResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    user: (f = msg.getUser()) && dealer_pb.DealershipUser.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealership_service.GetDealershipUserByPhoneResponse}
 */
proto.dealership_service.GetDealershipUserByPhoneResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealership_service.GetDealershipUserByPhoneResponse;
  return proto.dealership_service.GetDealershipUserByPhoneResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealership_service.GetDealershipUserByPhoneResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealership_service.GetDealershipUserByPhoneResponse}
 */
proto.dealership_service.GetDealershipUserByPhoneResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new dealer_pb.DealershipUser;
      reader.readMessage(value,dealer_pb.DealershipUser.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealership_service.GetDealershipUserByPhoneResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealership_service.GetDealershipUserByPhoneResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealership_service.GetDealershipUserByPhoneResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealership_service.GetDealershipUserByPhoneResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      dealer_pb.DealershipUser.serializeBinaryToWriter
    );
  }
};


/**
 * optional dealer.DealershipUser user = 1;
 * @return {?proto.dealer.DealershipUser}
 */
proto.dealership_service.GetDealershipUserByPhoneResponse.prototype.getUser = function() {
  return /** @type{?proto.dealer.DealershipUser} */ (
    jspb.Message.getWrapperField(this, dealer_pb.DealershipUser, 1));
};


/**
 * @param {?proto.dealer.DealershipUser|undefined} value
 * @return {!proto.dealership_service.GetDealershipUserByPhoneResponse} returns this
*/
proto.dealership_service.GetDealershipUserByPhoneResponse.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dealership_service.GetDealershipUserByPhoneResponse} returns this
 */
proto.dealership_service.GetDealershipUserByPhoneResponse.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dealership_service.GetDealershipUserByPhoneResponse.prototype.hasUser = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.dealership_service);
