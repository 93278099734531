import React, { FC } from 'react'
import { Autocomplete, Box, Card, CardContent, TextField, Typography, Collapse, IconButton } from '@mui/material'
import { AutocompleteOption, useMapQueryCard } from './useFilterCard'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { labelize } from '../../../utils/formatEnum'
import { isString } from 'lodash'
import { styled } from '@mui/material/styles'

const textFieldStyle = {
  marginTop: 1,
  marginBottom: 1
}

const ExpandMore = styled<any>((props) => {
  //eslint-disable-next-line
  const { expanded, ...other } = props
  return <IconButton {...other} />
})(({ theme, expanded }) => ({
  transform: !expanded ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}))

export const FilterCard: FC = () => {
  const mapQuery = useMapQueryCard()

  return (
    <Card
      sx={{
        position: 'absolute',
        zIndex: 1000,
        right: 400,
        top: 50,
        width: 300,
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
        <Typography variant="h5" sx={{ marginLeft: '10px' }}>
          Filter Map
        </Typography>
        <ExpandMore
          expand={mapQuery.expanded}
          onClick={mapQuery.handleExpandClick}
          aria-expanded={mapQuery.expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </Box>
      <Collapse in={mapQuery.expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Autocomplete
            options={mapQuery.statusOptions}
            onChange={mapQuery.setStatusOption}
            value={mapQuery.status}
            isOptionEqualToValue={(option: AutocompleteOption, value: string) => {
              return option?.key === value
            }}
            getOptionLabel={(option: string | AutocompleteOption) => {
              if (!isString(option)) {
                return option.label
              }
              return labelize(option)
            }}
            renderInput={(params) => {
              return <TextField sx={textFieldStyle} label="Status" {...params} />
            }}
          />
          <TextField
            sx={textFieldStyle}
            label="Customer Name"
            value={mapQuery.customerName}
            onChange={mapQuery.setCustomerName}
          />
          <TextField
            sx={textFieldStyle}
            label="VIN"
            value={mapQuery.vin}
            onChange={mapQuery.setVIN}
          />
          <TextField
            sx={textFieldStyle}
            label="Make"
            value={mapQuery.make}
            onChange={mapQuery.setMake}
          />
          <TextField
            sx={textFieldStyle}
            label="Model"
            value={mapQuery.model}
            onChange={mapQuery.setModel}
          />
        </CardContent>
      </Collapse>
    </Card>
  )
}
