import {useEffect, useState} from 'react'
import format from 'date-fns/format'
import {OpenDayOfWeek} from '@nxcr-org/web-api-interface/lib/dealer_service_pb'
import { formatEnum } from '../../../../../../utils/formatEnum'
import {IDayOfWeek} from 'scheduling/settings/utils'

const defaultOpeningHours = new Date(new Date().setHours(9, 0, 0, 0))
const defaultClosingHours = new Date(new Date().setHours(17, 0, 0, 0))

export const formattedDaysOfWeek = Object.values(formatEnum(OpenDayOfWeek.DayOfWeek))
  .filter( day => day.value !== 0 )
  .map( day => ({
    index: day.value,
    dayOfWeek: day.label,
    startTime: format(defaultOpeningHours, 'HH:mm'),
    endTime: format(defaultClosingHours, 'HH:mm'),
    timezone: undefined
  }))

export function isOpen( dayOfWeek: string, businessDays: IDayOfWeek[] ): boolean {
  return businessDays.find( bd => bd.dayOfWeek === dayOfWeek ) !== undefined
}

export function getTime( dayOfWeek: string, businessDays: IDayOfWeek[], time: 'startTime' | 'endTime' = 'startTime' ): string {
  return isOpen( dayOfWeek, businessDays ) ?
    businessDays.find( bd => bd.dayOfWeek === dayOfWeek )[time] :
    formattedDaysOfWeek.find( fd => fd.dayOfWeek === dayOfWeek )[time]
}

export const useBusinessDaysBlock = ( businessDays, setBusinessDays ) => {
  const [isOpenSameTimeEveryDay, setIsOpenSameTimeEveryDay] = useState( true )
  const [startTime, setStartTime] = useState(format(defaultOpeningHours, 'HH:mm'))
  const [endTime, setEndTime] = useState(format(defaultClosingHours, 'HH:mm'))

  // the start and end ranges of possible opening hours. Random values, ask Product for preferred ranges
  const openingRangeStart = new Date(new Date().setHours(7, 0, 0, 0))
  const openingRangeEnd = new Date(new Date().setHours(11, 0, 0, 0))

  // the start and end ranges of possible closing hours. Random values, ask Product for preferred ranges
  const closingRangeStart = new Date(new Date().setHours( 17, 0, 0, 0 ))
  const closingRangeEnd = new Date(new Date().setHours( 21, 0, 0, 0 ))

  useEffect(() => {
    if ( isOpenSameTimeEveryDay ) {
      setBusinessDays( businessDays.map(
        businessDay => {
          businessDay.startTime = startTime
          businessDay.endTime = endTime
          return businessDay
        }
      ))
    }
  }, [isOpenSameTimeEveryDay])

  return {
    startTime,
    endTime,
    defaultOpeningHours,
    defaultClosingHours,
    openingRangeStart,
    openingRangeEnd,
    closingRangeStart,
    closingRangeEnd,
    isOpenSameTimeEveryDay,
    setIsOpenSameTimeEveryDay,
    setStartTime,
    setEndTime,
  }
}

