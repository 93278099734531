import React from 'react'
import type { FC } from 'react'
import { Box, Button, Icon, ListItem } from '@mui/material'
import type { ListItemProps } from '@mui/material'
import { NavLink } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'
import { autonomyColor } from '../../global-styles/autonomyColor'


interface SidebarItemProps extends ListItemProps {
  label: string
  target: string
  icon: string
  exact?: boolean
}

const paddingLeft = 24

const useSidebarItemStyles = makeStyles()((theme) => {
  return {
    base: {
      borderRadius: 1,

      justifyContent: 'flex-start',
      pl: `${paddingLeft}px`,
      pr: 3,
      textAlign: 'left',
      textTransform: 'none',
      width: '100%',
      '& span': {
        color: 'white',
      },
      '& div': {
        color: theme.palette.neutral?.[300],
      },
      '& .MuiButton-startIcon': {
        color: theme.palette.neutral?.[300],
      },
      '&:hover': {
        backgroundColor: 'rgba(255,255,255, 0.08)',
      },
    },
    active: {
      backgroundColor: 'rgba(255,255,255, 0.08)',
      color: autonomyColor.cyan,
      fontWeight: 'fontWeightBold',
      '& span': {
        color: autonomyColor.cyan,
      },
      '& div': {
        color: autonomyColor.cyan,
      },
      '& .MuiButton-startIcon': {
        color: autonomyColor.cyan,
      },
    },
  }
})

export const SidebarItem: FC<SidebarItemProps> = (props) => {
  const { label, target, icon, exact } = props
  const { classes } = useSidebarItemStyles()


  return (
    <ListItem
      disableGutters
      sx={{
        display: 'flex',
        mb: 0.5,
        py: 0,
        px: 2,
      }}
    >
      <Button
        component={NavLink}
        to={target}
        exact={exact}
        startIcon={<Icon>{icon}</Icon>}
        disableRipple
        className={classes.base}
        activeClassName={classes.active}
      >
        <Box sx={{ flexGrow: 1 }}>{label}</Box>
      </Button>
    </ListItem>
  )
}
