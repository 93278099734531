import React from 'react'
import { Grid, Typography } from '@mui/material'
import { AppointmentFormField } from './appointment-form-field/AppointmentFormField'
import { IAppointmentListItem, SelectOption } from '../../utils'
import { useAppointmentForm } from './useAppointmentForm'
import { AutonomyCalendar } from 'shared/AutonomyCalendar/AutonomyCalendar'
import format from 'date-fns/format'
import TextareaAutosize from '@mui/material/TextareaAutosize'
import { useTextAreaStyles } from '../../../scheduling.styles'

interface AppointmentFormProps {
  appointment: IAppointmentListItem
  isEditing: boolean
  setAppointment?: (appointment: IAppointmentListItem) => void
}

export const AppointmentForm: React.FC<AppointmentFormProps> = ({
  appointment,
  isEditing,
  setAppointment,
}) => {
  const {
    setDealershipId,
    disableEventTime,
    dealershipUserOptions,
    appointmentStatusOptions,
    appointmentTypeOptions,
    eventTimesOptions,
    eventTimeLabel,
    minDate,
    userInfo,
    dealershipOptions,
  } = useAppointmentForm(appointment)

  const { classes } = useTextAreaStyles()

  return (
    <>
      <Grid container direction="column" spacing={6}>
        <Grid
          item
          container
          direction="row"
          justifyContent="space-between"
          spacing={4}
        >
          {/* EVENT LOCATION */}
          <Grid item>
            <Grid container item direction="column">
              <AppointmentFormField
                label="Event location"
                value={appointment?.dealershipId}
                isEditing={isEditing}
                options={dealershipOptions}
                onChange={(option: SelectOption) => {
                  setDealershipId(option.value as string)
                  setAppointment({
                    ...appointment,
                    location: option.label,
                    dealershipId: option.value as string,
                  })
                }}
              />
            </Grid>
          </Grid>

          {/* EVENT TYPE */}
          <Grid item>
            <Grid container item direction="column">
              <AppointmentFormField
                label="Event type"
                value={appointment?.type}
                isEditing={isEditing}
                options={appointmentTypeOptions}
                disabled={!appointmentTypeOptions.length}
                onChange={(option: SelectOption) => {
                  setAppointment({
                    ...appointment,
                    type: option.value as 0 | 1 | 2 | 3,
                    typeLabel: option.label,
                  })
                }}
              />
            </Grid>
          </Grid>

          {/* EVENT DATE */}
          <Grid item>
            <Grid container item direction="column">
              <Typography variant="caption">Event Date</Typography>
              {isEditing ? (
                <AutonomyCalendar
                  minDate={minDate}
                  value={appointment?.date}
                  disabled={
                    appointment?.type === null ||
                    appointment?.type === undefined
                  }
                  onChange={(date) => {
                    setAppointment({
                      ...appointment,
                      date,
                      startTime: 0,
                    })
                  }}
                />
              ) : (
                <Typography>
                  {format(appointment?.date, 'MM/dd/yyyy')}
                </Typography>
              )}
            </Grid>
          </Grid>

          {/* EVENT TIME */}
          <Grid item>
            <Grid container item direction="column">
              <AppointmentFormField
                label={eventTimeLabel}
                value={appointment?.startTime}
                isEditing={isEditing}
                options={eventTimesOptions}
                disabled={disableEventTime || !appointment?.date}
                onChange={(option: SelectOption) => {
                  setAppointment({
                    ...appointment,
                    startTime: option.value as number,
                  })
                }}
              />
            </Grid>
          </Grid>

          {/* EVENT STATUS */}
          <Grid item>
            <Grid container item direction="column">
              <AppointmentFormField
                label="Status"
                value={appointment?.status}
                isEditing={isEditing}
                disabled={true}
                options={appointmentStatusOptions}
                onChange={(option: SelectOption) => {
                  setAppointment({
                    ...appointment,
                    status: option.value as 0 | 1 | 2 | 3,
                    statusLabel: option.label,
                  })
                }}
              />
            </Grid>
          </Grid>

          {/* EVENT ASSIGNEE */}
          <Grid item>
            <Grid container item direction="column">
              <AppointmentFormField
                label="Assignee"
                value={appointment?.assigneeId}
                isEditing={isEditing}
                options={dealershipUserOptions}
                disabled={true} // @TODO: remove this once team members have been added
                onChange={(option: SelectOption) => {
                  setAppointment({
                    ...appointment,
                    assigneeId: option.value as string,
                    assigneeName: option.label,
                  })
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        {/*EVENT NOTE*/}
        <Grid item>
          <Grid container item direction="column">
            <Typography variant="caption">Notes</Typography>
            {isEditing ? (
              <TextareaAutosize
                minRows={2}
                value={appointment?.note}
                placeholder="Add any additional notes about this appointment here"
                className={classes.textArea}
                onChange={(e) => {
                  setAppointment({
                    ...appointment,
                    note: e.target.value,
                    noteAuthor: userInfo.name,
                  })
                }}
              />
            ) : (
              <Grid item>
                <Typography>
                  {appointment?.note || 'No notes available'}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
