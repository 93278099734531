import React from 'react'
import { Button } from '@mui/material'
import { OverridableStringUnion } from '@mui/types'
import {
  ButtonPropsColorOverrides,
  ButtonPropsVariantOverrides
} from '@mui/material/Button/Button'

interface FileSelectionButtonProps {
  type: string
  id: string
  title: string
  onChange: ( file: File ) => void
  variant?: OverridableStringUnion<
    'text' | 'outlined' | 'contained',
    ButtonPropsVariantOverrides
    >
  color?: OverridableStringUnion<
    'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning',
    ButtonPropsColorOverrides
    >
}

export const FileSelectionButton: React.FC<FileSelectionButtonProps> = ({
  type,
  id,
  title,
  variant = 'outlined',
  color = 'primary',
  onChange
}) => {

  return (
    <span>
      <input
        accept={`${type}/*`}
        hidden
        id={id}
        type="file"
        multiple={false}
        onChange={(e) => {
          e.preventDefault()
          onChange( e.target.files[0] )
        }}
      />
      <label
        htmlFor={id}
      >
        <Button
          variant={variant}
          color={color}
          component="span">
          {title}
        </Button>
      </label>
    </span>
  )
}
