import React, { useState } from 'react'
import { Grid, TextField, Box } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import {
  DesktopDatePicker,
  LocalizationProvider,
} from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'

import AddDocument from '../add-document'
import Loading from '../../../shared/loading'
import { FleetService } from '../../api/fleet-service'
import { FileSelectionButton } from 'shared/FileSelectionButton'

const useStyles = makeStyles()({
  documentButton: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'row-reverse',
  },
})

const RegistrationForm = ({
  setSelectedFile,
  selectedDate,
  handleDateChange,
  licensePlate,
  setLicensePlate,
}) => (
  <Box sx={
    {
      display: 'flex',
      justifyContent: 'space-evenly',
      gap: '20px'
    }
  }>
    <div>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
          disableToolbar
          variant="inline"
          inputVariant="outlined"
          format="MM/dd/yyyy"
          id="expiration-date"
          label="Expiration date"
          value={selectedDate}
          onChange={handleDateChange}
          required
          renderInput={(params) => <TextField {...params} />}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </LocalizationProvider>
    </div>

    <div>
      <TextField
        autoFocus
        id="licensePlate"
        label="License Plate"
        type="text"
        fullWidth
        variant="outlined"
        value={licensePlate}
        required
        onChange={(evt) => {
          setLicensePlate(evt.target.value)
        }}
      />
    </div>
    <div>
      <FileSelectionButton
        type={'pdf'}
        id={'registrationFileBtn'}
        variant="contained"
        color="secondary"
        title={'Select Registration'}
        onChange={setSelectedFile}
      />
    </div>
  </Box>
)

const Title = ({ selectedFile, setSelectedFile }) => {
  const { classes } = useStyles()
  return (
    <Grid container spacing={4}>
      <Grid item xs={8}>
        {!!selectedFile && selectedFile.name}
      </Grid>
      <Grid item xs={4} className={classes.documentButton}>
        <FileSelectionButton
          type={'pdf'}
          id={'titleFileBtn'}
          variant="contained"
          color="secondary"
          title={'Select Title'}
          onChange={setSelectedFile}
        />
      </Grid>
    </Grid>
  )
}

const Warranty = ({ selectedFile, setSelectedFile }) => {
  const { classes } = useStyles()
  return (
    <Grid container spacing={4}>
      <Grid item xs={7}>
        {!!selectedFile && selectedFile.name}
      </Grid>
      <Grid item xs={5} className={classes.documentButton}>
        <FileSelectionButton
          type={'pdf'}
          id={'warrantyFileBtn'}
          title={'Select Warranty'}
          variant="contained"
          color="secondary"
          onChange={setSelectedFile}
        />
      </Grid>
    </Grid>
  )
}

const AddRegistration = (props) => {
  const [documentType, setDocumentType] = useState('registration')
  const [selectedDate, setSelectedDate] = useState(undefined)
  const [selectedFile, setSelectedFile] = useState(null)
  const [licensePlate, setLicensePlate] = useState('')
  const [loading, setLoading] = useState(false)

  const handleDateChange = (date) => {
    setSelectedDate(date)
  }
  const handleFormSubmit = () => {
    if (selectedFile) {
      setLoading(true)
      FleetService.addDocument({
        vin: props.vin,
        vehicleId: props.id,
        kind: documentType,
        document: selectedFile,
        meta: documentType === 'registration' && {
          expiration: selectedDate.getTime(),
          license_plate: licensePlate,
        },
      })
        .then((response) => {
          setLoading(false)
          setSelectedDate(undefined)
          setSelectedFile(null)
          setLicensePlate(null)
          console.log({ 'AddRegistration.handleFormSubmit(res)': response })
        })
        .then(props.handleClose)
    }
  }

  const validate = () => {
    switch (documentType) {
      case 'registration':
        return !(selectedFile && licensePlate && selectedDate)
      case 'warranty':
      case 'title':
        return !selectedFile
    }
  }

  return (
    <AddDocument
      {...props}
      selectedFile={selectedFile}
      setDocumentType={setDocumentType}
      documentType={documentType}
      handleFormSubmit={handleFormSubmit}
      disabled={validate()}
      setSelectedFile={setSelectedFile}
      types={[
        {
          key: 'Registration',
          value: 'registration',
        },
        {
          key: 'Title',
          value: 'title',
        },
        {
          key: 'Warranty',
          value: 'warranty',
        },
      ]}
    >
      {loading && <Loading />}
      {!loading && documentType === 'registration' && (
        <RegistrationForm
          setSelectedFile={setSelectedFile}
          selectedDate={selectedDate}
          handleDateChange={handleDateChange}
          licensePlate={licensePlate}
          setLicensePlate={setLicensePlate}
        />
      )}
      {!loading && documentType === 'title' && (
        <Title selectedFile={selectedFile} setSelectedFile={setSelectedFile} />
      )}
      {!loading && documentType === 'warranty' && (
        <Warranty
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
        />
      )}
    </AddDocument>
  )
}

export default AddRegistration
