import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material'
import Modal from '@mui/material/Modal'
import React, { ReactNode } from 'react'
import { Article } from 'shared/Article'
import { Section } from 'shared/Section'
import { autonomyColor } from '../../../../../global-styles/autonomyColor'
import { IAppointmentListItem } from '../../utils'
import { AppointmentModalBody } from '../AppointmentModalBody'
import { useBillingStatusDriveOffPaid, useExistingAppointmentModal } from './useExistingAppointmentModal'

interface ExistingAppointmentModalProps {
  modalIsOpen: boolean
  handleClose: () => void
  selectedAppointment: IAppointmentListItem,
  customerId: string
  subscriptionId: string
}

export const ExistingAppointmentModal: React.FC<ExistingAppointmentModalProps> = ({
  modalIsOpen,
  handleClose,
  selectedAppointment,
  subscriptionId
}) => {
  const {
    isEditing,
    updateAppointment,
    appointment,
    displayCancellationConfirmation,
    cancelAppointment,
    setDisplayCancellationConfirmation,
    setAppointment,
    cancelChanges,
    setIsEditing,
    closeModal,
    isAppointmentCancelled,
  } = useExistingAppointmentModal(handleClose, selectedAppointment)

  const { billingStatus } = useBillingStatusDriveOffPaid({ subscriptionId })

  function getHeaderButtons(): ReactNode {
    return (
      <Grid item>
        <Grid
          container
          columnGap={2}>
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {(isEditing ? cancelChanges() : closeModal())}}>
              {isEditing ? 'Cancel' : 'Close'}
            </Button>
          </Grid>

          { !displayCancellationConfirmation && !isAppointmentCancelled && 
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {(isEditing ? updateAppointment.mutate() : setIsEditing(true))}}>
                {isEditing ? 'Save' : 'Edit'}
              </Button>
            </Grid>
          }
        </Grid>
      </Grid>
    )
  }

  return (
    <Modal
      open={modalIsOpen}
      onClose={closeModal}
      sx={{backgroundColor: autonomyColor.darkBlue}}
      className="modal-xl"
      aria-labelledby="appointment-modal"
      aria-describedby="create-appointment-modal">
      <>
        <Article
          heading="Appointment details"
          headerHasBottomBorder={true}
          actionBlock={getHeaderButtons()}>
          <Section wrapperSection>
            {!displayCancellationConfirmation ? (
              <>
                {updateAppointment.isLoading ? (
                  <Box sx={{ width: '100%', textAlign: 'center' }}>
                    <CircularProgress/>
                  </Box>
                ):(
                  <>
                    {updateAppointment.isSuccess && (
                      <Typography className="success">
                        {`Appointment ${updateAppointment?.data?.appointmentId} updated successfully`}
                      </Typography>
                    )}
                    {updateAppointment.isError && (
                      <Typography className="error">
                        {updateAppointment?.error?.message}
                      </Typography>
                    )}
                    <AppointmentModalBody
                      appointment={appointment}
                      isEditing={isEditing}
                      setAppointment={setAppointment}
                      isDriveOffPaid={billingStatus.driveOffPaid}
                    />

                    {/*If the appointment is not already cancelled...*/}
                    { appointment?.status !== 2 &&
                      <Grid container justifyContent="center" alignSelf="center">
                        <Button
                          disabled={isEditing}
                          onClick={() => setDisplayCancellationConfirmation(true)}
                          variant="contained"
                          color="secondary"
                        >
                          Cancel Appointment
                        </Button>
                      </Grid>
                    }
                  </>
                )}
              </>
            ):(
              // Cancellation confirmation content
              <Section
                subSection
                heading="Cancel appointment">
                {cancelAppointment.isLoading ? (
                  <Box sx={{ width: '100%', textAlign: 'center' }}>
                    <CircularProgress/>
                  </Box>
                ):(
                  <Grid container direction="column" spacing={2}>
                    {cancelAppointment.isSuccess ? (
                      <Grid item>
                        <Typography className="success">
                          Appointment &quot;{`${appointment?.id}`}&quot; Cancelled Successfully
                        </Typography>
                      </Grid>
                    ):(
                      <>
                        <Grid item>
                          <Typography>
                            Are you sure you want to cancel this appointment? You cannot undo
                            this action. Click confirm to continue, or cancel to go back.
                          </Typography>
                        </Grid>
                        <Grid item container direction="row" gap={2}>
                          <Grid item>
                            <Button
                              variant="outlined"
                              color="primary"
                              onClick={() => setDisplayCancellationConfirmation(false)}
                            >
                              Cancel
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button
                              variant="contained"
                              color="secondary"
                              onClick={() => {
                                cancelAppointment.mutate(appointment?.id)
                              }}
                            >
                              Confirm
                            </Button>
                          </Grid>
                        </Grid>
                        {cancelAppointment.isError &&
                          <Grid item>
                            <Typography className="error">
                              {cancelAppointment?.error?.message}
                            </Typography>
                          </Grid>
                        }
                      </>
                    )}
                  </Grid>
                )}
              </Section>
            )}
          </Section>
        </Article>
      </>
    </Modal>
  )
}
