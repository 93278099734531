import React from 'react'
import { AppointmentList } from './appointment-list/AppointmentList'
import { Box, Grid, Typography } from '@mui/material'
import { useTasks } from './useTasks'
import { CtaButton } from 'shared/CtaButton'
import { CreateAppointmentModal } from './appointment-modals/create-appointment-modal/CreateAppointmentModal'
import { RadioSelect } from '../../../components/RadioSelect/RadioSelect'

interface TasksProps {
  isLoading: boolean
}

/*
 * tasks page root
 * */
export const Tasks: React.FC<TasksProps> = ({ isLoading }) => {
  const {
    appoinments,
    dealershipId,
    dealershipOptions,
    createAppointmentModalIsVisible,
    setCreateAppointmentModalIsVisible,
    handleClose,
    selectDealership,
  } = useTasks()

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '30px',
        }}
      >
        <Box sx={{ display: 'flex', gap: '20px' }}>
          <Typography variant="h1">Tasks</Typography>
        </Box>
        <Box sx={{ display: 'flex', gap: '20px' }}>
          <CtaButton
            name="New Appointment"
            onClick={() => setCreateAppointmentModalIsVisible(true)}
          />
          <RadioSelect
            label="Location"
            value={dealershipId}
            options={dealershipOptions}
            onChange={selectDealership}
          />
        </Box>
      </Box>
      {/* <Grid container direction="column" spacing={4}> */}
      {appoinments?.map((appointment) => (
        <Grid item key={appointment?.day}>
          <AppointmentList
            isLoading={isLoading}
            appointmentDay={appointment}
            includeHeading={true}
          />
        </Grid>
      ))}
      {/* </Grid> */}

      <CreateAppointmentModal
        modalIsOpen={createAppointmentModalIsVisible}
        handleClose={handleClose}
      />
    </Box>
  )
}
