import * as React from 'react'
import { Alert, AlertColor, Snackbar } from '@mui/material'
import { createContext, useState } from 'react'
import { styled } from '@mui/material/styles'

class Snack {
  message?: string
  color?: AlertColor
  autoHideDuration?: number
  open: boolean
  anchorVertical?: 'top' | 'bottom'
  anchorHorizontal?: 'left' | 'center' | 'right'

  constructor(data: Snack) {
    this.message = data.message || ''
    this.color = data.color || 'info'
    this.autoHideDuration = data.autoHideDuration || 3000
    this.open = data.open
    this.anchorVertical = data.anchorVertical || 'bottom'
    this.anchorHorizontal = data.anchorHorizontal || 'right'
  }
}

export { Snack }

type SnackDefaultValue = {
  snack: Snack
  setSnack: React.Dispatch<React.SetStateAction<Snack>>
}

export const SnackbarContext = createContext<SnackDefaultValue>({
  snack: new Snack({ open: false }),
  setSnack: () => undefined,
})
interface Props {
  children: React.ReactNode
}
export const SnackbarComponent: React.FC = ({ children }: Props) => {
  const [snack, setSnack] = useState(new Snack({ open: false }))

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return
    }

    setSnack(new Snack({ color: snack.color, open: false }))
  }

  return (
    <SnackbarContext.Provider value={{ snack, setSnack }}>
      {children}
      <StyledSnackbar
        open={snack.open}
        autoHideDuration={snack.autoHideDuration}
        anchorOrigin={{
          vertical: snack.anchorVertical,
          horizontal: snack.anchorHorizontal,
        }}
        onClose={handleClose}
      >
        <Alert severity={snack.color}>{snack.message || ''}</Alert>
      </StyledSnackbar>
    </SnackbarContext.Provider>
  )
}

const StyledSnackbar = styled(Snackbar)`
  position: fixed;
  z-index: 5500;
  display: flex;
  bottom: 16px;
  left: 16px;
`
