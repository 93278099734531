import React from 'react'
import { makeStyles } from 'tss-react/mui'
import { useMainNavigation } from './useMainNavigation'
import { Sidebar } from './Sidebar'
import { TopNav } from './TopNav'
import { Box } from '@mui/material'
import { SnackbarComponent } from 'shared/Snackbar'

const useStyles = makeStyles<{ isSidebarOpen: boolean }>()((theme, params) => {
  return {
    root: {
      display: 'flex',
      flex: '1 1 auto',
      maxWidth: '100%',
      paddingTop: 64,
      [params.isSidebarOpen && `${theme.breakpoints.up('lg')}`]: {
        paddingLeft: 280,
      },
    },
    main: {
      flexGrow: 1,
      height: '100%',
      overflow: 'auto',
    },
  }
})

export const Layout = ({ children }) => {
  const { logout, routes, user, isSidebarOpen, toggleSidebar } = useMainNavigation()
  const { classes } = useStyles({
    isSidebarOpen
  })

  return (
    <div className={classes.root}>
      <TopNav
        logout={logout}
        user={user}
        toggleSidebar={toggleSidebar}
        isSidebarOpen={isSidebarOpen}
      />
      <Sidebar
        routeGroups={routes}
        isSidebarOpen={isSidebarOpen}
        toggleSidebar={toggleSidebar}
      />
      <Box
        sx={{
          display: 'flex',
          flex: '1 1 auto',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <SnackbarComponent>{children}</SnackbarComponent>
      </Box>
      {/* <main className={classes.main}>{children}</main> */}
    </div>
  )
}
