import { makeStyles } from 'tss-react/mui'

export const useNexusRouteStyles = makeStyles()(theme => {
  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
    dataContainer: {
      marginTop: theme.spacing(1),
    },
    container: {
      paddingBottom: theme.spacing(4),
    },
  }
})

export const useNexusTabStyles = makeStyles()((theme) => {
  return {
    // TODO: Is this something we should have in the theme? Will similar buttons appear?
    base: {
      height: 84,
      borderRadius: 8,
      display: 'flex',
      textAlign: 'center',
      alignItems: 'center',
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
      '&:hover': {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.text.secondary
      }
    },
    active: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.text.secondary
    },
  }
})
