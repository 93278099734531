import {useState} from 'react'
import {useIsFetching, useIsMutating, useMutation, useQuery, useQueryClient} from 'react-query'
import {NexusService} from 'global-apis/nexus-service'
import {formatAppointmentTypeConfigs, getFormattedAppointmentTypeConfigs, IDealership} from '../../utils'

export function useTaskSettings( dealership ) {
  const queryClient = useQueryClient()
  const [isEditing, setIsEditing] = useState( false )

  const {data: listEventTypes} = useQuery(
    ['/listEventTypes'],
    () => {
      return NexusService.listAppointmentTypeConfigs().then((response) => {
        return getFormattedAppointmentTypeConfigs(response.appointmentTypeConfigsList)
      })
    }
  )

  const {mutateAsync: updateEventTypes} = useMutation(
    () => {
      return NexusService.setAppointmentTypeConfigs(
        formatAppointmentTypeConfigs(listEventTypes)
      )
    },
    {
      onSuccess(){
        setIsEditing(false)
        queryClient.invalidateQueries(['/listEventTypes'])
      },
    },
  )

  function handleCancellation() {
    queryClient.invalidateQueries(['/listEventTypes'])
  }

  function setEventTypes(listEventTypes){
    queryClient.setQueryData( ['/listEventTypes'], listEventTypes)
  }

  function saveChanges(): Promise<any> {
    const mutations = [
      updateEventTypes()
    ]

    // perform all mutations asynchronously
    // currently there is only 1 block in Task Settings but in the future it may become more
    return Promise.all( mutations ).then(() => {
      queryClient.invalidateQueries(['/listEventTypes'])
      setIsEditing( !isEditing )
    })
  }

  const isFetching = useIsFetching()
  const isMutating = useIsMutating()

  return {
    dealership,
    listEventTypes,
    isEditing,
    isLoading: isFetching > 0 || isMutating > 0,
    setEventTypes,
    saveChanges,
    setIsEditing,
    handleCancellation,
  }
}

export interface TaskSettingsProps {
  dealership: IDealership
  isAdmin: boolean
}
