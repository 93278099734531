import { useQueryParams, withDefault, StringParam } from 'use-query-params'

export function useMapQuery(){
  return useQueryParams({
    status: withDefault(StringParam, ''),
    customerName: withDefault(StringParam, ''),
    make: withDefault(StringParam, ''),
    model: withDefault(StringParam, ''),
    vin: withDefault(StringParam, ''),
  })
}