import React from 'react'
import {Accordion, AccordionDetails, Typography} from '@mui/material'
import AccordionSummary from '@mui/material/AccordionSummary'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {AppointmentListItem} from './appointment-list-item/AppointmentListItem'
import {AppointmentDayListItem, getFormattedAppointmentDayLabel} from '../utils'

interface AppointmentListProps {
  isLoading: boolean
  appointmentDay: AppointmentDayListItem
  includeHeading?: boolean
}

/*
* A list of appointments for 1 day with an optional heading that displays
* the date the list of appointments belongs to. This list is rendered inside
* an accordion component.
*/
export const AppointmentList: React.FC<AppointmentListProps> = ({
  isLoading,
  appointmentDay,
  includeHeading
}) => {

  return (
    <>
      <Accordion square sx={{marginBottom: '30px'}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon/>}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          {includeHeading &&
            <Typography variant="h4">
              {getFormattedAppointmentDayLabel(appointmentDay?.day) || 'N/A'}
            </Typography>
          }
        </AccordionSummary>
        <AccordionDetails className="horizontal">
          {appointmentDay?.appointments?.map((appointment) => (
            <AppointmentListItem
              key={appointment?.id}
              isLoading={isLoading}
              appointment={appointment}
              displayCustomerDetails={true}/>
          ))}
        </AccordionDetails>
      </Accordion>
    </>
  )
}

