// source: subscription.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var domain_pb = require('./domain_pb.js');
goog.object.extend(proto, domain_pb);
goog.exportSymbol('proto.subscription.ColorOption', null, global);
goog.exportSymbol('proto.subscription.ColorOptionCreated', null, global);
goog.exportSymbol('proto.subscription.ContractProperties', null, global);
goog.exportSymbol('proto.subscription.PendingSubscriptionInitialized', null, global);
goog.exportSymbol('proto.subscription.PricingOption', null, global);
goog.exportSymbol('proto.subscription.PricingOptionCreated', null, global);
goog.exportSymbol('proto.subscription.ProcessDueStatements', null, global);
goog.exportSymbol('proto.subscription.Program', null, global);
goog.exportSymbol('proto.subscription.ProgramCreated', null, global);
goog.exportSymbol('proto.subscription.ProgramPricingOptionsUpdated', null, global);
goog.exportSymbol('proto.subscription.Reservation', null, global);
goog.exportSymbol('proto.subscription.Reservation.ProgramOptionsCase', null, global);
goog.exportSymbol('proto.subscription.ReservationCancelled', null, global);
goog.exportSymbol('proto.subscription.ReservationCreated', null, global);
goog.exportSymbol('proto.subscription.ReservationCreated.ProgramOptionsCase', null, global);
goog.exportSymbol('proto.subscription.ReservationOptionsUpdated', null, global);
goog.exportSymbol('proto.subscription.ReservationOptionsUpdated.ProgramOptionsCase', null, global);
goog.exportSymbol('proto.subscription.ReservationStatus', null, global);
goog.exportSymbol('proto.subscription.ReservationStatusUpdated', null, global);
goog.exportSymbol('proto.subscription.StatementDue', null, global);
goog.exportSymbol('proto.subscription.SubscriptionAction', null, global);
goog.exportSymbol('proto.subscription.SubscriptionAction.ActionReason', null, global);
goog.exportSymbol('proto.subscription.SubscriptionAction.ActionType', null, global);
goog.exportSymbol('proto.subscription.SubscriptionAction.UserType', null, global);
goog.exportSymbol('proto.subscription.SubscriptionActivated', null, global);
goog.exportSymbol('proto.subscription.SubscriptionStatus', null, global);
goog.exportSymbol('proto.subscription.SubscriptionUpdated', null, global);
goog.exportSymbol('proto.subscription.SubscriptionUpdated.Reason', null, global);
goog.exportSymbol('proto.subscription.TeslaModel3Options', null, global);
goog.exportSymbol('proto.subscription.TeslaModelYOptions', null, global);
goog.exportSymbol('proto.subscription.TrimOption', null, global);
goog.exportSymbol('proto.subscription.VehicleAssigned', null, global);
goog.exportSymbol('proto.subscription.VehiclePickupReceiptProperties', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.subscription.Reservation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.subscription.Reservation.oneofGroups_);
};
goog.inherits(proto.subscription.Reservation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.subscription.Reservation.displayName = 'proto.subscription.Reservation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.subscription.Program = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.subscription.Program.repeatedFields_, null);
};
goog.inherits(proto.subscription.Program, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.subscription.Program.displayName = 'proto.subscription.Program';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.subscription.TrimOption = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.subscription.TrimOption, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.subscription.TrimOption.displayName = 'proto.subscription.TrimOption';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.subscription.ColorOption = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.subscription.ColorOption, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.subscription.ColorOption.displayName = 'proto.subscription.ColorOption';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.subscription.PricingOption = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.subscription.PricingOption, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.subscription.PricingOption.displayName = 'proto.subscription.PricingOption';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.subscription.TeslaModel3Options = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.subscription.TeslaModel3Options, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.subscription.TeslaModel3Options.displayName = 'proto.subscription.TeslaModel3Options';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.subscription.TeslaModelYOptions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.subscription.TeslaModelYOptions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.subscription.TeslaModelYOptions.displayName = 'proto.subscription.TeslaModelYOptions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.subscription.ProgramCreated = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.subscription.ProgramCreated, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.subscription.ProgramCreated.displayName = 'proto.subscription.ProgramCreated';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.subscription.PricingOptionCreated = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.subscription.PricingOptionCreated, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.subscription.PricingOptionCreated.displayName = 'proto.subscription.PricingOptionCreated';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.subscription.ColorOptionCreated = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.subscription.ColorOptionCreated, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.subscription.ColorOptionCreated.displayName = 'proto.subscription.ColorOptionCreated';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.subscription.ReservationCreated = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.subscription.ReservationCreated.oneofGroups_);
};
goog.inherits(proto.subscription.ReservationCreated, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.subscription.ReservationCreated.displayName = 'proto.subscription.ReservationCreated';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.subscription.ReservationStatusUpdated = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.subscription.ReservationStatusUpdated, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.subscription.ReservationStatusUpdated.displayName = 'proto.subscription.ReservationStatusUpdated';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.subscription.PendingSubscriptionInitialized = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.subscription.PendingSubscriptionInitialized, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.subscription.PendingSubscriptionInitialized.displayName = 'proto.subscription.PendingSubscriptionInitialized';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.subscription.ReservationCancelled = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.subscription.ReservationCancelled.repeatedFields_, null);
};
goog.inherits(proto.subscription.ReservationCancelled, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.subscription.ReservationCancelled.displayName = 'proto.subscription.ReservationCancelled';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.subscription.SubscriptionAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.subscription.SubscriptionAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.subscription.SubscriptionAction.displayName = 'proto.subscription.SubscriptionAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.subscription.ReservationOptionsUpdated = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.subscription.ReservationOptionsUpdated.oneofGroups_);
};
goog.inherits(proto.subscription.ReservationOptionsUpdated, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.subscription.ReservationOptionsUpdated.displayName = 'proto.subscription.ReservationOptionsUpdated';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.subscription.ProcessDueStatements = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.subscription.ProcessDueStatements, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.subscription.ProcessDueStatements.displayName = 'proto.subscription.ProcessDueStatements';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.subscription.StatementDue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.subscription.StatementDue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.subscription.StatementDue.displayName = 'proto.subscription.StatementDue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.subscription.VehicleAssigned = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.subscription.VehicleAssigned, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.subscription.VehicleAssigned.displayName = 'proto.subscription.VehicleAssigned';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.subscription.SubscriptionActivated = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.subscription.SubscriptionActivated, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.subscription.SubscriptionActivated.displayName = 'proto.subscription.SubscriptionActivated';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.subscription.SubscriptionUpdated = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.subscription.SubscriptionUpdated, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.subscription.SubscriptionUpdated.displayName = 'proto.subscription.SubscriptionUpdated';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.subscription.ProgramPricingOptionsUpdated = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.subscription.ProgramPricingOptionsUpdated.repeatedFields_, null);
};
goog.inherits(proto.subscription.ProgramPricingOptionsUpdated, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.subscription.ProgramPricingOptionsUpdated.displayName = 'proto.subscription.ProgramPricingOptionsUpdated';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.subscription.ContractProperties = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.subscription.ContractProperties, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.subscription.ContractProperties.displayName = 'proto.subscription.ContractProperties';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.subscription.VehiclePickupReceiptProperties = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.subscription.VehiclePickupReceiptProperties, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.subscription.VehiclePickupReceiptProperties.displayName = 'proto.subscription.VehiclePickupReceiptProperties';
}

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.subscription.Reservation.oneofGroups_ = [[9,11]];

/**
 * @enum {number}
 */
proto.subscription.Reservation.ProgramOptionsCase = {
  PROGRAM_OPTIONS_NOT_SET: 0,
  MODEL_3: 9,
  MODEL_Y: 11
};

/**
 * @return {proto.subscription.Reservation.ProgramOptionsCase}
 */
proto.subscription.Reservation.prototype.getProgramOptionsCase = function() {
  return /** @type {proto.subscription.Reservation.ProgramOptionsCase} */(jspb.Message.computeOneofCase(this, proto.subscription.Reservation.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.subscription.Reservation.prototype.toObject = function(opt_includeInstance) {
  return proto.subscription.Reservation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.subscription.Reservation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription.Reservation.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    version: jspb.Message.getFieldWithDefault(msg, 2, 0),
    customerId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    programId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    reservationNumber: jspb.Message.getFieldWithDefault(msg, 6, ""),
    status: jspb.Message.getFieldWithDefault(msg, 7, 0),
    created: jspb.Message.getFieldWithDefault(msg, 8, 0),
    model3: (f = msg.getModel3()) && proto.subscription.TeslaModel3Options.toObject(includeInstance, f),
    modelY: (f = msg.getModelY()) && proto.subscription.TeslaModelYOptions.toObject(includeInstance, f),
    trackingId: jspb.Message.getFieldWithDefault(msg, 10, ""),
    initialProgramId: jspb.Message.getFieldWithDefault(msg, 12, ""),
    initialPriceOptionId: jspb.Message.getFieldWithDefault(msg, 13, ""),
    zipCode: jspb.Message.getFieldWithDefault(msg, 14, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.subscription.Reservation}
 */
proto.subscription.Reservation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.subscription.Reservation;
  return proto.subscription.Reservation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.subscription.Reservation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.subscription.Reservation}
 */
proto.subscription.Reservation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setVersion(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setProgramId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setReservationNumber(value);
      break;
    case 7:
      var value = /** @type {!proto.subscription.ReservationStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreated(value);
      break;
    case 9:
      var value = new proto.subscription.TeslaModel3Options;
      reader.readMessage(value,proto.subscription.TeslaModel3Options.deserializeBinaryFromReader);
      msg.setModel3(value);
      break;
    case 11:
      var value = new proto.subscription.TeslaModelYOptions;
      reader.readMessage(value,proto.subscription.TeslaModelYOptions.deserializeBinaryFromReader);
      msg.setModelY(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrackingId(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setInitialProgramId(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setInitialPriceOptionId(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setZipCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.subscription.Reservation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.subscription.Reservation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.subscription.Reservation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription.Reservation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVersion();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProgramId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getReservationNumber();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getCreated();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getModel3();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.subscription.TeslaModel3Options.serializeBinaryToWriter
    );
  }
  f = message.getModelY();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.subscription.TeslaModelYOptions.serializeBinaryToWriter
    );
  }
  f = message.getTrackingId();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getInitialProgramId();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getInitialPriceOptionId();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getZipCode();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.subscription.Reservation.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.Reservation} returns this
 */
proto.subscription.Reservation.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 version = 2;
 * @return {number}
 */
proto.subscription.Reservation.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.subscription.Reservation} returns this
 */
proto.subscription.Reservation.prototype.setVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string customer_id = 3;
 * @return {string}
 */
proto.subscription.Reservation.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.Reservation} returns this
 */
proto.subscription.Reservation.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string program_id = 4;
 * @return {string}
 */
proto.subscription.Reservation.prototype.getProgramId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.Reservation} returns this
 */
proto.subscription.Reservation.prototype.setProgramId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string subscription_id = 5;
 * @return {string}
 */
proto.subscription.Reservation.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.Reservation} returns this
 */
proto.subscription.Reservation.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string reservation_number = 6;
 * @return {string}
 */
proto.subscription.Reservation.prototype.getReservationNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.Reservation} returns this
 */
proto.subscription.Reservation.prototype.setReservationNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional ReservationStatus status = 7;
 * @return {!proto.subscription.ReservationStatus}
 */
proto.subscription.Reservation.prototype.getStatus = function() {
  return /** @type {!proto.subscription.ReservationStatus} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.subscription.ReservationStatus} value
 * @return {!proto.subscription.Reservation} returns this
 */
proto.subscription.Reservation.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional int64 created = 8;
 * @return {number}
 */
proto.subscription.Reservation.prototype.getCreated = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.subscription.Reservation} returns this
 */
proto.subscription.Reservation.prototype.setCreated = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional TeslaModel3Options model_3 = 9;
 * @return {?proto.subscription.TeslaModel3Options}
 */
proto.subscription.Reservation.prototype.getModel3 = function() {
  return /** @type{?proto.subscription.TeslaModel3Options} */ (
    jspb.Message.getWrapperField(this, proto.subscription.TeslaModel3Options, 9));
};


/**
 * @param {?proto.subscription.TeslaModel3Options|undefined} value
 * @return {!proto.subscription.Reservation} returns this
*/
proto.subscription.Reservation.prototype.setModel3 = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.subscription.Reservation.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.subscription.Reservation} returns this
 */
proto.subscription.Reservation.prototype.clearModel3 = function() {
  return this.setModel3(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.subscription.Reservation.prototype.hasModel3 = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional TeslaModelYOptions model_y = 11;
 * @return {?proto.subscription.TeslaModelYOptions}
 */
proto.subscription.Reservation.prototype.getModelY = function() {
  return /** @type{?proto.subscription.TeslaModelYOptions} */ (
    jspb.Message.getWrapperField(this, proto.subscription.TeslaModelYOptions, 11));
};


/**
 * @param {?proto.subscription.TeslaModelYOptions|undefined} value
 * @return {!proto.subscription.Reservation} returns this
*/
proto.subscription.Reservation.prototype.setModelY = function(value) {
  return jspb.Message.setOneofWrapperField(this, 11, proto.subscription.Reservation.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.subscription.Reservation} returns this
 */
proto.subscription.Reservation.prototype.clearModelY = function() {
  return this.setModelY(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.subscription.Reservation.prototype.hasModelY = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional string tracking_id = 10;
 * @return {string}
 */
proto.subscription.Reservation.prototype.getTrackingId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.Reservation} returns this
 */
proto.subscription.Reservation.prototype.setTrackingId = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string initial_program_id = 12;
 * @return {string}
 */
proto.subscription.Reservation.prototype.getInitialProgramId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.Reservation} returns this
 */
proto.subscription.Reservation.prototype.setInitialProgramId = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string initial_price_option_id = 13;
 * @return {string}
 */
proto.subscription.Reservation.prototype.getInitialPriceOptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.Reservation} returns this
 */
proto.subscription.Reservation.prototype.setInitialPriceOptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string zip_code = 14;
 * @return {string}
 */
proto.subscription.Reservation.prototype.getZipCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.Reservation} returns this
 */
proto.subscription.Reservation.prototype.setZipCode = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.subscription.Program.repeatedFields_ = [6,7,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.subscription.Program.prototype.toObject = function(opt_includeInstance) {
  return proto.subscription.Program.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.subscription.Program} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription.Program.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    version: jspb.Message.getFieldWithDefault(msg, 2, 0),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    description: jspb.Message.getFieldWithDefault(msg, 4, ""),
    created: jspb.Message.getFieldWithDefault(msg, 5, 0),
    trimOptionsList: jspb.Message.toObjectList(msg.getTrimOptionsList(),
    proto.subscription.TrimOption.toObject, includeInstance),
    colorOptionsList: jspb.Message.toObjectList(msg.getColorOptionsList(),
    proto.subscription.ColorOption.toObject, includeInstance),
    pricingOptionsList: jspb.Message.toObjectList(msg.getPricingOptionsList(),
    proto.subscription.PricingOption.toObject, includeInstance),
    make: jspb.Message.getFieldWithDefault(msg, 9, ""),
    model: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.subscription.Program}
 */
proto.subscription.Program.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.subscription.Program;
  return proto.subscription.Program.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.subscription.Program} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.subscription.Program}
 */
proto.subscription.Program.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setVersion(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreated(value);
      break;
    case 6:
      var value = new proto.subscription.TrimOption;
      reader.readMessage(value,proto.subscription.TrimOption.deserializeBinaryFromReader);
      msg.addTrimOptions(value);
      break;
    case 7:
      var value = new proto.subscription.ColorOption;
      reader.readMessage(value,proto.subscription.ColorOption.deserializeBinaryFromReader);
      msg.addColorOptions(value);
      break;
    case 8:
      var value = new proto.subscription.PricingOption;
      reader.readMessage(value,proto.subscription.PricingOption.deserializeBinaryFromReader);
      msg.addPricingOptions(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setMake(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setModel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.subscription.Program.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.subscription.Program.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.subscription.Program} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription.Program.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVersion();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCreated();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getTrimOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.subscription.TrimOption.serializeBinaryToWriter
    );
  }
  f = message.getColorOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.subscription.ColorOption.serializeBinaryToWriter
    );
  }
  f = message.getPricingOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.subscription.PricingOption.serializeBinaryToWriter
    );
  }
  f = message.getMake();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getModel();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.subscription.Program.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.Program} returns this
 */
proto.subscription.Program.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 version = 2;
 * @return {number}
 */
proto.subscription.Program.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.subscription.Program} returns this
 */
proto.subscription.Program.prototype.setVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.subscription.Program.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.Program} returns this
 */
proto.subscription.Program.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.subscription.Program.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.Program} returns this
 */
proto.subscription.Program.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 created = 5;
 * @return {number}
 */
proto.subscription.Program.prototype.getCreated = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.subscription.Program} returns this
 */
proto.subscription.Program.prototype.setCreated = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * repeated TrimOption trim_options = 6;
 * @return {!Array<!proto.subscription.TrimOption>}
 */
proto.subscription.Program.prototype.getTrimOptionsList = function() {
  return /** @type{!Array<!proto.subscription.TrimOption>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.subscription.TrimOption, 6));
};


/**
 * @param {!Array<!proto.subscription.TrimOption>} value
 * @return {!proto.subscription.Program} returns this
*/
proto.subscription.Program.prototype.setTrimOptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.subscription.TrimOption=} opt_value
 * @param {number=} opt_index
 * @return {!proto.subscription.TrimOption}
 */
proto.subscription.Program.prototype.addTrimOptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.subscription.TrimOption, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.subscription.Program} returns this
 */
proto.subscription.Program.prototype.clearTrimOptionsList = function() {
  return this.setTrimOptionsList([]);
};


/**
 * repeated ColorOption color_options = 7;
 * @return {!Array<!proto.subscription.ColorOption>}
 */
proto.subscription.Program.prototype.getColorOptionsList = function() {
  return /** @type{!Array<!proto.subscription.ColorOption>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.subscription.ColorOption, 7));
};


/**
 * @param {!Array<!proto.subscription.ColorOption>} value
 * @return {!proto.subscription.Program} returns this
*/
proto.subscription.Program.prototype.setColorOptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.subscription.ColorOption=} opt_value
 * @param {number=} opt_index
 * @return {!proto.subscription.ColorOption}
 */
proto.subscription.Program.prototype.addColorOptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.subscription.ColorOption, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.subscription.Program} returns this
 */
proto.subscription.Program.prototype.clearColorOptionsList = function() {
  return this.setColorOptionsList([]);
};


/**
 * repeated PricingOption pricing_options = 8;
 * @return {!Array<!proto.subscription.PricingOption>}
 */
proto.subscription.Program.prototype.getPricingOptionsList = function() {
  return /** @type{!Array<!proto.subscription.PricingOption>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.subscription.PricingOption, 8));
};


/**
 * @param {!Array<!proto.subscription.PricingOption>} value
 * @return {!proto.subscription.Program} returns this
*/
proto.subscription.Program.prototype.setPricingOptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.subscription.PricingOption=} opt_value
 * @param {number=} opt_index
 * @return {!proto.subscription.PricingOption}
 */
proto.subscription.Program.prototype.addPricingOptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.subscription.PricingOption, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.subscription.Program} returns this
 */
proto.subscription.Program.prototype.clearPricingOptionsList = function() {
  return this.setPricingOptionsList([]);
};


/**
 * optional string make = 9;
 * @return {string}
 */
proto.subscription.Program.prototype.getMake = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.Program} returns this
 */
proto.subscription.Program.prototype.setMake = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string model = 10;
 * @return {string}
 */
proto.subscription.Program.prototype.getModel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.Program} returns this
 */
proto.subscription.Program.prototype.setModel = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.subscription.TrimOption.prototype.toObject = function(opt_includeInstance) {
  return proto.subscription.TrimOption.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.subscription.TrimOption} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription.TrimOption.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    reservationFee: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.subscription.TrimOption}
 */
proto.subscription.TrimOption.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.subscription.TrimOption;
  return proto.subscription.TrimOption.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.subscription.TrimOption} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.subscription.TrimOption}
 */
proto.subscription.TrimOption.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setReservationFee(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.subscription.TrimOption.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.subscription.TrimOption.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.subscription.TrimOption} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription.TrimOption.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getReservationFee();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.subscription.TrimOption.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.TrimOption} returns this
 */
proto.subscription.TrimOption.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.subscription.TrimOption.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.TrimOption} returns this
 */
proto.subscription.TrimOption.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string reservation_fee = 3;
 * @return {string}
 */
proto.subscription.TrimOption.prototype.getReservationFee = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.TrimOption} returns this
 */
proto.subscription.TrimOption.prototype.setReservationFee = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.subscription.ColorOption.prototype.toObject = function(opt_includeInstance) {
  return proto.subscription.ColorOption.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.subscription.ColorOption} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription.ColorOption.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    colorName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    colorValue: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.subscription.ColorOption}
 */
proto.subscription.ColorOption.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.subscription.ColorOption;
  return proto.subscription.ColorOption.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.subscription.ColorOption} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.subscription.ColorOption}
 */
proto.subscription.ColorOption.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setColorName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setColorValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.subscription.ColorOption.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.subscription.ColorOption.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.subscription.ColorOption} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription.ColorOption.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getColorName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getColorValue();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.subscription.ColorOption.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.ColorOption} returns this
 */
proto.subscription.ColorOption.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string color_name = 2;
 * @return {string}
 */
proto.subscription.ColorOption.prototype.getColorName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.ColorOption} returns this
 */
proto.subscription.ColorOption.prototype.setColorName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string color_value = 3;
 * @return {string}
 */
proto.subscription.ColorOption.prototype.getColorValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.ColorOption} returns this
 */
proto.subscription.ColorOption.prototype.setColorValue = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.subscription.PricingOption.prototype.toObject = function(opt_includeInstance) {
  return proto.subscription.PricingOption.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.subscription.PricingOption} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription.PricingOption.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    startFee: jspb.Message.getFieldWithDefault(msg, 2, 0),
    monthly: jspb.Message.getFieldWithDefault(msg, 3, 0),
    deposit: jspb.Message.getFieldWithDefault(msg, 4, 0),
    reservationFee: jspb.Message.getFieldWithDefault(msg, 5, 0),
    priceTermMonths: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.subscription.PricingOption}
 */
proto.subscription.PricingOption.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.subscription.PricingOption;
  return proto.subscription.PricingOption.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.subscription.PricingOption} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.subscription.PricingOption}
 */
proto.subscription.PricingOption.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStartFee(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMonthly(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDeposit(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setReservationFee(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPriceTermMonths(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.subscription.PricingOption.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.subscription.PricingOption.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.subscription.PricingOption} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription.PricingOption.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStartFee();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getMonthly();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getDeposit();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getReservationFee();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getPriceTermMonths();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.subscription.PricingOption.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.PricingOption} returns this
 */
proto.subscription.PricingOption.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 start_fee = 2;
 * @return {number}
 */
proto.subscription.PricingOption.prototype.getStartFee = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.subscription.PricingOption} returns this
 */
proto.subscription.PricingOption.prototype.setStartFee = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 monthly = 3;
 * @return {number}
 */
proto.subscription.PricingOption.prototype.getMonthly = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.subscription.PricingOption} returns this
 */
proto.subscription.PricingOption.prototype.setMonthly = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 deposit = 4;
 * @return {number}
 */
proto.subscription.PricingOption.prototype.getDeposit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.subscription.PricingOption} returns this
 */
proto.subscription.PricingOption.prototype.setDeposit = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 reservation_fee = 5;
 * @return {number}
 */
proto.subscription.PricingOption.prototype.getReservationFee = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.subscription.PricingOption} returns this
 */
proto.subscription.PricingOption.prototype.setReservationFee = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 price_term_months = 6;
 * @return {number}
 */
proto.subscription.PricingOption.prototype.getPriceTermMonths = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.subscription.PricingOption} returns this
 */
proto.subscription.PricingOption.prototype.setPriceTermMonths = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.subscription.TeslaModel3Options.prototype.toObject = function(opt_includeInstance) {
  return proto.subscription.TeslaModel3Options.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.subscription.TeslaModel3Options} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription.TeslaModel3Options.toObject = function(includeInstance, msg) {
  var f, obj = {
    color: jspb.Message.getFieldWithDefault(msg, 1, ""),
    monthly: jspb.Message.getFieldWithDefault(msg, 2, 0),
    startFee: jspb.Message.getFieldWithDefault(msg, 3, 0),
    deposit: jspb.Message.getFieldWithDefault(msg, 4, 0),
    reservationFee: jspb.Message.getFieldWithDefault(msg, 5, 0),
    secondaryColor: jspb.Message.getFieldWithDefault(msg, 6, ""),
    priceTermMonths: jspb.Message.getFieldWithDefault(msg, 7, 0),
    make: jspb.Message.getFieldWithDefault(msg, 8, ""),
    model: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.subscription.TeslaModel3Options}
 */
proto.subscription.TeslaModel3Options.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.subscription.TeslaModel3Options;
  return proto.subscription.TeslaModel3Options.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.subscription.TeslaModel3Options} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.subscription.TeslaModel3Options}
 */
proto.subscription.TeslaModel3Options.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setColor(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMonthly(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStartFee(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDeposit(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setReservationFee(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSecondaryColor(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPriceTermMonths(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setMake(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setModel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.subscription.TeslaModel3Options.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.subscription.TeslaModel3Options.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.subscription.TeslaModel3Options} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription.TeslaModel3Options.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getColor();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMonthly();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getStartFee();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getDeposit();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getReservationFee();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getSecondaryColor();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPriceTermMonths();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getMake();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getModel();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string color = 1;
 * @return {string}
 */
proto.subscription.TeslaModel3Options.prototype.getColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.TeslaModel3Options} returns this
 */
proto.subscription.TeslaModel3Options.prototype.setColor = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 monthly = 2;
 * @return {number}
 */
proto.subscription.TeslaModel3Options.prototype.getMonthly = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.subscription.TeslaModel3Options} returns this
 */
proto.subscription.TeslaModel3Options.prototype.setMonthly = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 start_fee = 3;
 * @return {number}
 */
proto.subscription.TeslaModel3Options.prototype.getStartFee = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.subscription.TeslaModel3Options} returns this
 */
proto.subscription.TeslaModel3Options.prototype.setStartFee = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 deposit = 4;
 * @return {number}
 */
proto.subscription.TeslaModel3Options.prototype.getDeposit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.subscription.TeslaModel3Options} returns this
 */
proto.subscription.TeslaModel3Options.prototype.setDeposit = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 reservation_fee = 5;
 * @return {number}
 */
proto.subscription.TeslaModel3Options.prototype.getReservationFee = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.subscription.TeslaModel3Options} returns this
 */
proto.subscription.TeslaModel3Options.prototype.setReservationFee = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string secondary_color = 6;
 * @return {string}
 */
proto.subscription.TeslaModel3Options.prototype.getSecondaryColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.TeslaModel3Options} returns this
 */
proto.subscription.TeslaModel3Options.prototype.setSecondaryColor = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int32 price_term_months = 7;
 * @return {number}
 */
proto.subscription.TeslaModel3Options.prototype.getPriceTermMonths = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.subscription.TeslaModel3Options} returns this
 */
proto.subscription.TeslaModel3Options.prototype.setPriceTermMonths = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string make = 8;
 * @return {string}
 */
proto.subscription.TeslaModel3Options.prototype.getMake = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.TeslaModel3Options} returns this
 */
proto.subscription.TeslaModel3Options.prototype.setMake = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string model = 9;
 * @return {string}
 */
proto.subscription.TeslaModel3Options.prototype.getModel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.TeslaModel3Options} returns this
 */
proto.subscription.TeslaModel3Options.prototype.setModel = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.subscription.TeslaModelYOptions.prototype.toObject = function(opt_includeInstance) {
  return proto.subscription.TeslaModelYOptions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.subscription.TeslaModelYOptions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription.TeslaModelYOptions.toObject = function(includeInstance, msg) {
  var f, obj = {
    color: jspb.Message.getFieldWithDefault(msg, 1, ""),
    monthly: jspb.Message.getFieldWithDefault(msg, 2, 0),
    startFee: jspb.Message.getFieldWithDefault(msg, 3, 0),
    deposit: jspb.Message.getFieldWithDefault(msg, 4, 0),
    reservationFee: jspb.Message.getFieldWithDefault(msg, 5, 0),
    secondaryColor: jspb.Message.getFieldWithDefault(msg, 6, ""),
    priceTermMonths: jspb.Message.getFieldWithDefault(msg, 7, 0),
    make: jspb.Message.getFieldWithDefault(msg, 8, ""),
    model: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.subscription.TeslaModelYOptions}
 */
proto.subscription.TeslaModelYOptions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.subscription.TeslaModelYOptions;
  return proto.subscription.TeslaModelYOptions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.subscription.TeslaModelYOptions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.subscription.TeslaModelYOptions}
 */
proto.subscription.TeslaModelYOptions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setColor(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMonthly(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStartFee(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDeposit(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setReservationFee(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSecondaryColor(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPriceTermMonths(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setMake(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setModel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.subscription.TeslaModelYOptions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.subscription.TeslaModelYOptions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.subscription.TeslaModelYOptions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription.TeslaModelYOptions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getColor();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMonthly();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getStartFee();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getDeposit();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getReservationFee();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getSecondaryColor();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPriceTermMonths();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getMake();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getModel();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string color = 1;
 * @return {string}
 */
proto.subscription.TeslaModelYOptions.prototype.getColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.TeslaModelYOptions} returns this
 */
proto.subscription.TeslaModelYOptions.prototype.setColor = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 monthly = 2;
 * @return {number}
 */
proto.subscription.TeslaModelYOptions.prototype.getMonthly = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.subscription.TeslaModelYOptions} returns this
 */
proto.subscription.TeslaModelYOptions.prototype.setMonthly = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 start_fee = 3;
 * @return {number}
 */
proto.subscription.TeslaModelYOptions.prototype.getStartFee = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.subscription.TeslaModelYOptions} returns this
 */
proto.subscription.TeslaModelYOptions.prototype.setStartFee = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 deposit = 4;
 * @return {number}
 */
proto.subscription.TeslaModelYOptions.prototype.getDeposit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.subscription.TeslaModelYOptions} returns this
 */
proto.subscription.TeslaModelYOptions.prototype.setDeposit = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 reservation_fee = 5;
 * @return {number}
 */
proto.subscription.TeslaModelYOptions.prototype.getReservationFee = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.subscription.TeslaModelYOptions} returns this
 */
proto.subscription.TeslaModelYOptions.prototype.setReservationFee = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string secondary_color = 6;
 * @return {string}
 */
proto.subscription.TeslaModelYOptions.prototype.getSecondaryColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.TeslaModelYOptions} returns this
 */
proto.subscription.TeslaModelYOptions.prototype.setSecondaryColor = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int32 price_term_months = 7;
 * @return {number}
 */
proto.subscription.TeslaModelYOptions.prototype.getPriceTermMonths = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.subscription.TeslaModelYOptions} returns this
 */
proto.subscription.TeslaModelYOptions.prototype.setPriceTermMonths = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string make = 8;
 * @return {string}
 */
proto.subscription.TeslaModelYOptions.prototype.getMake = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.TeslaModelYOptions} returns this
 */
proto.subscription.TeslaModelYOptions.prototype.setMake = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string model = 9;
 * @return {string}
 */
proto.subscription.TeslaModelYOptions.prototype.getModel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.TeslaModelYOptions} returns this
 */
proto.subscription.TeslaModelYOptions.prototype.setModel = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.subscription.ProgramCreated.prototype.toObject = function(opt_includeInstance) {
  return proto.subscription.ProgramCreated.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.subscription.ProgramCreated} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription.ProgramCreated.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    created: jspb.Message.getFieldWithDefault(msg, 2, 0),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    description: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.subscription.ProgramCreated}
 */
proto.subscription.ProgramCreated.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.subscription.ProgramCreated;
  return proto.subscription.ProgramCreated.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.subscription.ProgramCreated} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.subscription.ProgramCreated}
 */
proto.subscription.ProgramCreated.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreated(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.subscription.ProgramCreated.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.subscription.ProgramCreated.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.subscription.ProgramCreated} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription.ProgramCreated.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreated();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.subscription.ProgramCreated.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.ProgramCreated} returns this
 */
proto.subscription.ProgramCreated.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 created = 2;
 * @return {number}
 */
proto.subscription.ProgramCreated.prototype.getCreated = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.subscription.ProgramCreated} returns this
 */
proto.subscription.ProgramCreated.prototype.setCreated = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.subscription.ProgramCreated.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.ProgramCreated} returns this
 */
proto.subscription.ProgramCreated.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.subscription.ProgramCreated.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.ProgramCreated} returns this
 */
proto.subscription.ProgramCreated.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.subscription.PricingOptionCreated.prototype.toObject = function(opt_includeInstance) {
  return proto.subscription.PricingOptionCreated.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.subscription.PricingOptionCreated} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription.PricingOptionCreated.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    startFee: jspb.Message.getFieldWithDefault(msg, 2, 0),
    monthly: jspb.Message.getFieldWithDefault(msg, 3, 0),
    deposit: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.subscription.PricingOptionCreated}
 */
proto.subscription.PricingOptionCreated.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.subscription.PricingOptionCreated;
  return proto.subscription.PricingOptionCreated.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.subscription.PricingOptionCreated} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.subscription.PricingOptionCreated}
 */
proto.subscription.PricingOptionCreated.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStartFee(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMonthly(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDeposit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.subscription.PricingOptionCreated.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.subscription.PricingOptionCreated.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.subscription.PricingOptionCreated} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription.PricingOptionCreated.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStartFee();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getMonthly();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getDeposit();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.subscription.PricingOptionCreated.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.PricingOptionCreated} returns this
 */
proto.subscription.PricingOptionCreated.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 start_fee = 2;
 * @return {number}
 */
proto.subscription.PricingOptionCreated.prototype.getStartFee = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.subscription.PricingOptionCreated} returns this
 */
proto.subscription.PricingOptionCreated.prototype.setStartFee = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 monthly = 3;
 * @return {number}
 */
proto.subscription.PricingOptionCreated.prototype.getMonthly = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.subscription.PricingOptionCreated} returns this
 */
proto.subscription.PricingOptionCreated.prototype.setMonthly = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 deposit = 4;
 * @return {number}
 */
proto.subscription.PricingOptionCreated.prototype.getDeposit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.subscription.PricingOptionCreated} returns this
 */
proto.subscription.PricingOptionCreated.prototype.setDeposit = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.subscription.ColorOptionCreated.prototype.toObject = function(opt_includeInstance) {
  return proto.subscription.ColorOptionCreated.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.subscription.ColorOptionCreated} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription.ColorOptionCreated.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    colorName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    colorValue: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.subscription.ColorOptionCreated}
 */
proto.subscription.ColorOptionCreated.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.subscription.ColorOptionCreated;
  return proto.subscription.ColorOptionCreated.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.subscription.ColorOptionCreated} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.subscription.ColorOptionCreated}
 */
proto.subscription.ColorOptionCreated.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setColorName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setColorValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.subscription.ColorOptionCreated.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.subscription.ColorOptionCreated.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.subscription.ColorOptionCreated} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription.ColorOptionCreated.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getColorName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getColorValue();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.subscription.ColorOptionCreated.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.ColorOptionCreated} returns this
 */
proto.subscription.ColorOptionCreated.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string color_name = 2;
 * @return {string}
 */
proto.subscription.ColorOptionCreated.prototype.getColorName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.ColorOptionCreated} returns this
 */
proto.subscription.ColorOptionCreated.prototype.setColorName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string color_value = 3;
 * @return {string}
 */
proto.subscription.ColorOptionCreated.prototype.getColorValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.ColorOptionCreated} returns this
 */
proto.subscription.ColorOptionCreated.prototype.setColorValue = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.subscription.ReservationCreated.oneofGroups_ = [[7,9]];

/**
 * @enum {number}
 */
proto.subscription.ReservationCreated.ProgramOptionsCase = {
  PROGRAM_OPTIONS_NOT_SET: 0,
  MODEL_3: 7,
  MODEL_Y: 9
};

/**
 * @return {proto.subscription.ReservationCreated.ProgramOptionsCase}
 */
proto.subscription.ReservationCreated.prototype.getProgramOptionsCase = function() {
  return /** @type {proto.subscription.ReservationCreated.ProgramOptionsCase} */(jspb.Message.computeOneofCase(this, proto.subscription.ReservationCreated.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.subscription.ReservationCreated.prototype.toObject = function(opt_includeInstance) {
  return proto.subscription.ReservationCreated.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.subscription.ReservationCreated} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription.ReservationCreated.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    customerId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    programId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    reservationNumber: jspb.Message.getFieldWithDefault(msg, 5, ""),
    created: jspb.Message.getFieldWithDefault(msg, 6, 0),
    model3: (f = msg.getModel3()) && proto.subscription.TeslaModel3Options.toObject(includeInstance, f),
    modelY: (f = msg.getModelY()) && proto.subscription.TeslaModelYOptions.toObject(includeInstance, f),
    trackingId: jspb.Message.getFieldWithDefault(msg, 8, ""),
    initialProgramId: jspb.Message.getFieldWithDefault(msg, 10, ""),
    initialPriceOptionId: jspb.Message.getFieldWithDefault(msg, 11, ""),
    zipCode: jspb.Message.getFieldWithDefault(msg, 12, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.subscription.ReservationCreated}
 */
proto.subscription.ReservationCreated.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.subscription.ReservationCreated;
  return proto.subscription.ReservationCreated.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.subscription.ReservationCreated} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.subscription.ReservationCreated}
 */
proto.subscription.ReservationCreated.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProgramId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setReservationNumber(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreated(value);
      break;
    case 7:
      var value = new proto.subscription.TeslaModel3Options;
      reader.readMessage(value,proto.subscription.TeslaModel3Options.deserializeBinaryFromReader);
      msg.setModel3(value);
      break;
    case 9:
      var value = new proto.subscription.TeslaModelYOptions;
      reader.readMessage(value,proto.subscription.TeslaModelYOptions.deserializeBinaryFromReader);
      msg.setModelY(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrackingId(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setInitialProgramId(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setInitialPriceOptionId(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setZipCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.subscription.ReservationCreated.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.subscription.ReservationCreated.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.subscription.ReservationCreated} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription.ReservationCreated.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProgramId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getReservationNumber();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCreated();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getModel3();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.subscription.TeslaModel3Options.serializeBinaryToWriter
    );
  }
  f = message.getModelY();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.subscription.TeslaModelYOptions.serializeBinaryToWriter
    );
  }
  f = message.getTrackingId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getInitialProgramId();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getInitialPriceOptionId();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getZipCode();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.subscription.ReservationCreated.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.ReservationCreated} returns this
 */
proto.subscription.ReservationCreated.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string customer_id = 2;
 * @return {string}
 */
proto.subscription.ReservationCreated.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.ReservationCreated} returns this
 */
proto.subscription.ReservationCreated.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string program_id = 3;
 * @return {string}
 */
proto.subscription.ReservationCreated.prototype.getProgramId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.ReservationCreated} returns this
 */
proto.subscription.ReservationCreated.prototype.setProgramId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string subscription_id = 4;
 * @return {string}
 */
proto.subscription.ReservationCreated.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.ReservationCreated} returns this
 */
proto.subscription.ReservationCreated.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string reservation_number = 5;
 * @return {string}
 */
proto.subscription.ReservationCreated.prototype.getReservationNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.ReservationCreated} returns this
 */
proto.subscription.ReservationCreated.prototype.setReservationNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int64 created = 6;
 * @return {number}
 */
proto.subscription.ReservationCreated.prototype.getCreated = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.subscription.ReservationCreated} returns this
 */
proto.subscription.ReservationCreated.prototype.setCreated = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional TeslaModel3Options model_3 = 7;
 * @return {?proto.subscription.TeslaModel3Options}
 */
proto.subscription.ReservationCreated.prototype.getModel3 = function() {
  return /** @type{?proto.subscription.TeslaModel3Options} */ (
    jspb.Message.getWrapperField(this, proto.subscription.TeslaModel3Options, 7));
};


/**
 * @param {?proto.subscription.TeslaModel3Options|undefined} value
 * @return {!proto.subscription.ReservationCreated} returns this
*/
proto.subscription.ReservationCreated.prototype.setModel3 = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.subscription.ReservationCreated.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.subscription.ReservationCreated} returns this
 */
proto.subscription.ReservationCreated.prototype.clearModel3 = function() {
  return this.setModel3(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.subscription.ReservationCreated.prototype.hasModel3 = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional TeslaModelYOptions model_y = 9;
 * @return {?proto.subscription.TeslaModelYOptions}
 */
proto.subscription.ReservationCreated.prototype.getModelY = function() {
  return /** @type{?proto.subscription.TeslaModelYOptions} */ (
    jspb.Message.getWrapperField(this, proto.subscription.TeslaModelYOptions, 9));
};


/**
 * @param {?proto.subscription.TeslaModelYOptions|undefined} value
 * @return {!proto.subscription.ReservationCreated} returns this
*/
proto.subscription.ReservationCreated.prototype.setModelY = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.subscription.ReservationCreated.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.subscription.ReservationCreated} returns this
 */
proto.subscription.ReservationCreated.prototype.clearModelY = function() {
  return this.setModelY(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.subscription.ReservationCreated.prototype.hasModelY = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional string tracking_id = 8;
 * @return {string}
 */
proto.subscription.ReservationCreated.prototype.getTrackingId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.ReservationCreated} returns this
 */
proto.subscription.ReservationCreated.prototype.setTrackingId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string initial_program_id = 10;
 * @return {string}
 */
proto.subscription.ReservationCreated.prototype.getInitialProgramId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.ReservationCreated} returns this
 */
proto.subscription.ReservationCreated.prototype.setInitialProgramId = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string initial_price_option_id = 11;
 * @return {string}
 */
proto.subscription.ReservationCreated.prototype.getInitialPriceOptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.ReservationCreated} returns this
 */
proto.subscription.ReservationCreated.prototype.setInitialPriceOptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string zip_code = 12;
 * @return {string}
 */
proto.subscription.ReservationCreated.prototype.getZipCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.ReservationCreated} returns this
 */
proto.subscription.ReservationCreated.prototype.setZipCode = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.subscription.ReservationStatusUpdated.prototype.toObject = function(opt_includeInstance) {
  return proto.subscription.ReservationStatusUpdated.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.subscription.ReservationStatusUpdated} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription.ReservationStatusUpdated.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    customerId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    newStatus: jspb.Message.getFieldWithDefault(msg, 3, 0),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    vehicleId: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.subscription.ReservationStatusUpdated}
 */
proto.subscription.ReservationStatusUpdated.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.subscription.ReservationStatusUpdated;
  return proto.subscription.ReservationStatusUpdated.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.subscription.ReservationStatusUpdated} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.subscription.ReservationStatusUpdated}
 */
proto.subscription.ReservationStatusUpdated.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 3:
      var value = /** @type {!proto.subscription.ReservationStatus} */ (reader.readEnum());
      msg.setNewStatus(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.subscription.ReservationStatusUpdated.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.subscription.ReservationStatusUpdated.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.subscription.ReservationStatusUpdated} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription.ReservationStatusUpdated.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getNewStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.subscription.ReservationStatusUpdated.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.ReservationStatusUpdated} returns this
 */
proto.subscription.ReservationStatusUpdated.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string customer_id = 2;
 * @return {string}
 */
proto.subscription.ReservationStatusUpdated.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.ReservationStatusUpdated} returns this
 */
proto.subscription.ReservationStatusUpdated.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional ReservationStatus new_status = 3;
 * @return {!proto.subscription.ReservationStatus}
 */
proto.subscription.ReservationStatusUpdated.prototype.getNewStatus = function() {
  return /** @type {!proto.subscription.ReservationStatus} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.subscription.ReservationStatus} value
 * @return {!proto.subscription.ReservationStatusUpdated} returns this
 */
proto.subscription.ReservationStatusUpdated.prototype.setNewStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string subscription_id = 4;
 * @return {string}
 */
proto.subscription.ReservationStatusUpdated.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.ReservationStatusUpdated} returns this
 */
proto.subscription.ReservationStatusUpdated.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string vehicle_id = 5;
 * @return {string}
 */
proto.subscription.ReservationStatusUpdated.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.ReservationStatusUpdated} returns this
 */
proto.subscription.ReservationStatusUpdated.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.subscription.PendingSubscriptionInitialized.prototype.toObject = function(opt_includeInstance) {
  return proto.subscription.PendingSubscriptionInitialized.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.subscription.PendingSubscriptionInitialized} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription.PendingSubscriptionInitialized.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    vehicleId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    customerId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.subscription.PendingSubscriptionInitialized}
 */
proto.subscription.PendingSubscriptionInitialized.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.subscription.PendingSubscriptionInitialized;
  return proto.subscription.PendingSubscriptionInitialized.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.subscription.PendingSubscriptionInitialized} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.subscription.PendingSubscriptionInitialized}
 */
proto.subscription.PendingSubscriptionInitialized.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.subscription.PendingSubscriptionInitialized.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.subscription.PendingSubscriptionInitialized.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.subscription.PendingSubscriptionInitialized} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription.PendingSubscriptionInitialized.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string subscription_id = 1;
 * @return {string}
 */
proto.subscription.PendingSubscriptionInitialized.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.PendingSubscriptionInitialized} returns this
 */
proto.subscription.PendingSubscriptionInitialized.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string vehicle_id = 2;
 * @return {string}
 */
proto.subscription.PendingSubscriptionInitialized.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.PendingSubscriptionInitialized} returns this
 */
proto.subscription.PendingSubscriptionInitialized.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string customer_id = 3;
 * @return {string}
 */
proto.subscription.PendingSubscriptionInitialized.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.PendingSubscriptionInitialized} returns this
 */
proto.subscription.PendingSubscriptionInitialized.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.subscription.ReservationCancelled.repeatedFields_ = [8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.subscription.ReservationCancelled.prototype.toObject = function(opt_includeInstance) {
  return proto.subscription.ReservationCancelled.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.subscription.ReservationCancelled} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription.ReservationCancelled.toObject = function(includeInstance, msg) {
  var f, obj = {
    reservationId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    customerId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    user: (f = msg.getUser()) && domain_pb.AutonomyUser.toObject(includeInstance, f),
    actionReason: jspb.Message.getFieldWithDefault(msg, 5, 0),
    accountNumber: jspb.Message.getFieldWithDefault(msg, 6, ""),
    invoiceNumber: jspb.Message.getFieldWithDefault(msg, 7, ""),
    transactionsList: jspb.Message.toObjectList(msg.getTransactionsList(),
    domain_pb.Transaction.toObject, includeInstance),
    refundAmount: jspb.Message.getFieldWithDefault(msg, 9, 0),
    notes: jspb.Message.getFieldWithDefault(msg, 10, ""),
    vehicleId: jspb.Message.getFieldWithDefault(msg, 11, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.subscription.ReservationCancelled}
 */
proto.subscription.ReservationCancelled.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.subscription.ReservationCancelled;
  return proto.subscription.ReservationCancelled.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.subscription.ReservationCancelled} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.subscription.ReservationCancelled}
 */
proto.subscription.ReservationCancelled.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReservationId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 4:
      var value = new domain_pb.AutonomyUser;
      reader.readMessage(value,domain_pb.AutonomyUser.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 5:
      var value = /** @type {!proto.subscription.SubscriptionAction.ActionReason} */ (reader.readEnum());
      msg.setActionReason(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNumber(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setInvoiceNumber(value);
      break;
    case 8:
      var value = new domain_pb.Transaction;
      reader.readMessage(value,domain_pb.Transaction.deserializeBinaryFromReader);
      msg.addTransactions(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRefundAmount(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setNotes(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.subscription.ReservationCancelled.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.subscription.ReservationCancelled.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.subscription.ReservationCancelled} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription.ReservationCancelled.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReservationId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      domain_pb.AutonomyUser.serializeBinaryToWriter
    );
  }
  f = message.getActionReason();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getAccountNumber();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getInvoiceNumber();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getTransactionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      domain_pb.Transaction.serializeBinaryToWriter
    );
  }
  f = message.getRefundAmount();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getNotes();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
};


/**
 * optional string reservation_id = 1;
 * @return {string}
 */
proto.subscription.ReservationCancelled.prototype.getReservationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.ReservationCancelled} returns this
 */
proto.subscription.ReservationCancelled.prototype.setReservationId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string subscription_id = 2;
 * @return {string}
 */
proto.subscription.ReservationCancelled.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.ReservationCancelled} returns this
 */
proto.subscription.ReservationCancelled.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string customer_id = 3;
 * @return {string}
 */
proto.subscription.ReservationCancelled.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.ReservationCancelled} returns this
 */
proto.subscription.ReservationCancelled.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional domain.AutonomyUser user = 4;
 * @return {?proto.domain.AutonomyUser}
 */
proto.subscription.ReservationCancelled.prototype.getUser = function() {
  return /** @type{?proto.domain.AutonomyUser} */ (
    jspb.Message.getWrapperField(this, domain_pb.AutonomyUser, 4));
};


/**
 * @param {?proto.domain.AutonomyUser|undefined} value
 * @return {!proto.subscription.ReservationCancelled} returns this
*/
proto.subscription.ReservationCancelled.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.subscription.ReservationCancelled} returns this
 */
proto.subscription.ReservationCancelled.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.subscription.ReservationCancelled.prototype.hasUser = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional SubscriptionAction.ActionReason action_reason = 5;
 * @return {!proto.subscription.SubscriptionAction.ActionReason}
 */
proto.subscription.ReservationCancelled.prototype.getActionReason = function() {
  return /** @type {!proto.subscription.SubscriptionAction.ActionReason} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.subscription.SubscriptionAction.ActionReason} value
 * @return {!proto.subscription.ReservationCancelled} returns this
 */
proto.subscription.ReservationCancelled.prototype.setActionReason = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional string account_number = 6;
 * @return {string}
 */
proto.subscription.ReservationCancelled.prototype.getAccountNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.ReservationCancelled} returns this
 */
proto.subscription.ReservationCancelled.prototype.setAccountNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string invoice_number = 7;
 * @return {string}
 */
proto.subscription.ReservationCancelled.prototype.getInvoiceNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.ReservationCancelled} returns this
 */
proto.subscription.ReservationCancelled.prototype.setInvoiceNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * repeated domain.Transaction transactions = 8;
 * @return {!Array<!proto.domain.Transaction>}
 */
proto.subscription.ReservationCancelled.prototype.getTransactionsList = function() {
  return /** @type{!Array<!proto.domain.Transaction>} */ (
    jspb.Message.getRepeatedWrapperField(this, domain_pb.Transaction, 8));
};


/**
 * @param {!Array<!proto.domain.Transaction>} value
 * @return {!proto.subscription.ReservationCancelled} returns this
*/
proto.subscription.ReservationCancelled.prototype.setTransactionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.domain.Transaction=} opt_value
 * @param {number=} opt_index
 * @return {!proto.domain.Transaction}
 */
proto.subscription.ReservationCancelled.prototype.addTransactions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.domain.Transaction, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.subscription.ReservationCancelled} returns this
 */
proto.subscription.ReservationCancelled.prototype.clearTransactionsList = function() {
  return this.setTransactionsList([]);
};


/**
 * optional int32 refund_amount = 9;
 * @return {number}
 */
proto.subscription.ReservationCancelled.prototype.getRefundAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.subscription.ReservationCancelled} returns this
 */
proto.subscription.ReservationCancelled.prototype.setRefundAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string notes = 10;
 * @return {string}
 */
proto.subscription.ReservationCancelled.prototype.getNotes = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.ReservationCancelled} returns this
 */
proto.subscription.ReservationCancelled.prototype.setNotes = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string vehicle_id = 11;
 * @return {string}
 */
proto.subscription.ReservationCancelled.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.ReservationCancelled} returns this
 */
proto.subscription.ReservationCancelled.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.subscription.SubscriptionAction.prototype.toObject = function(opt_includeInstance) {
  return proto.subscription.SubscriptionAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.subscription.SubscriptionAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription.SubscriptionAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    timestamp: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    userEmail: jspb.Message.getFieldWithDefault(msg, 3, ""),
    userType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    actionType: jspb.Message.getFieldWithDefault(msg, 5, 0),
    actionReason: jspb.Message.getFieldWithDefault(msg, 6, 0),
    notes: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.subscription.SubscriptionAction}
 */
proto.subscription.SubscriptionAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.subscription.SubscriptionAction;
  return proto.subscription.SubscriptionAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.subscription.SubscriptionAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.subscription.SubscriptionAction}
 */
proto.subscription.SubscriptionAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestamp(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserEmail(value);
      break;
    case 4:
      var value = /** @type {!proto.subscription.SubscriptionAction.UserType} */ (reader.readEnum());
      msg.setUserType(value);
      break;
    case 5:
      var value = /** @type {!proto.subscription.SubscriptionAction.ActionType} */ (reader.readEnum());
      msg.setActionType(value);
      break;
    case 6:
      var value = /** @type {!proto.subscription.SubscriptionAction.ActionReason} */ (reader.readEnum());
      msg.setActionReason(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setNotes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.subscription.SubscriptionAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.subscription.SubscriptionAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.subscription.SubscriptionAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription.SubscriptionAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUserEmail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUserType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getActionType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getActionReason();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getNotes();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.subscription.SubscriptionAction.UserType = {
  CUSTOMER: 0,
  DEALER: 1
};

/**
 * @enum {number}
 */
proto.subscription.SubscriptionAction.ActionType = {
  CREATE: 0,
  UPDATE: 1,
  CANCEL: 2
};

/**
 * @enum {number}
 */
proto.subscription.SubscriptionAction.ActionReason = {
  DEALER_VEHICLE_NOT_AVAILABLE: 0,
  DEALER_VEHICLE_SELLING_PRICE_REJECTED: 1,
  DEALER_VEHICLE_SOLD: 2,
  CUSTOMER_VEHICLE_TOO_EXPENSIVE: 3,
  CUSTOMER_VEHICLE_DRIVE_NOT_AS_EXPECTED: 4,
  LINE_ITEM_ADDED: 5,
  TRANSACTION_ADDED: 6,
  STATEMENT_ADDED: 7,
  VEHICLE_PICKUP: 8,
  SUBSCRIPTION_ACTIVATED: 9,
  CONTRACT_SIGNED: 10,
  PAYMENT_METHOD_ADDED: 11,
  ANDROID_OS_UNSUPPORTED: 12,
  CUSTOMER_VEHICLE_DOES_NOT_WANT_VEHICLE: 13
};

/**
 * optional int64 timestamp = 1;
 * @return {number}
 */
proto.subscription.SubscriptionAction.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.subscription.SubscriptionAction} returns this
 */
proto.subscription.SubscriptionAction.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string user_id = 2;
 * @return {string}
 */
proto.subscription.SubscriptionAction.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.SubscriptionAction} returns this
 */
proto.subscription.SubscriptionAction.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string user_email = 3;
 * @return {string}
 */
proto.subscription.SubscriptionAction.prototype.getUserEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.SubscriptionAction} returns this
 */
proto.subscription.SubscriptionAction.prototype.setUserEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional UserType user_type = 4;
 * @return {!proto.subscription.SubscriptionAction.UserType}
 */
proto.subscription.SubscriptionAction.prototype.getUserType = function() {
  return /** @type {!proto.subscription.SubscriptionAction.UserType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.subscription.SubscriptionAction.UserType} value
 * @return {!proto.subscription.SubscriptionAction} returns this
 */
proto.subscription.SubscriptionAction.prototype.setUserType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional ActionType action_type = 5;
 * @return {!proto.subscription.SubscriptionAction.ActionType}
 */
proto.subscription.SubscriptionAction.prototype.getActionType = function() {
  return /** @type {!proto.subscription.SubscriptionAction.ActionType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.subscription.SubscriptionAction.ActionType} value
 * @return {!proto.subscription.SubscriptionAction} returns this
 */
proto.subscription.SubscriptionAction.prototype.setActionType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional ActionReason action_reason = 6;
 * @return {!proto.subscription.SubscriptionAction.ActionReason}
 */
proto.subscription.SubscriptionAction.prototype.getActionReason = function() {
  return /** @type {!proto.subscription.SubscriptionAction.ActionReason} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.subscription.SubscriptionAction.ActionReason} value
 * @return {!proto.subscription.SubscriptionAction} returns this
 */
proto.subscription.SubscriptionAction.prototype.setActionReason = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional string notes = 7;
 * @return {string}
 */
proto.subscription.SubscriptionAction.prototype.getNotes = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.SubscriptionAction} returns this
 */
proto.subscription.SubscriptionAction.prototype.setNotes = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.subscription.ReservationOptionsUpdated.oneofGroups_ = [[4,6]];

/**
 * @enum {number}
 */
proto.subscription.ReservationOptionsUpdated.ProgramOptionsCase = {
  PROGRAM_OPTIONS_NOT_SET: 0,
  MODEL_3: 4,
  MODEL_Y: 6
};

/**
 * @return {proto.subscription.ReservationOptionsUpdated.ProgramOptionsCase}
 */
proto.subscription.ReservationOptionsUpdated.prototype.getProgramOptionsCase = function() {
  return /** @type {proto.subscription.ReservationOptionsUpdated.ProgramOptionsCase} */(jspb.Message.computeOneofCase(this, proto.subscription.ReservationOptionsUpdated.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.subscription.ReservationOptionsUpdated.prototype.toObject = function(opt_includeInstance) {
  return proto.subscription.ReservationOptionsUpdated.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.subscription.ReservationOptionsUpdated} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription.ReservationOptionsUpdated.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    trackingId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    updated: jspb.Message.getFieldWithDefault(msg, 3, 0),
    model3: (f = msg.getModel3()) && proto.subscription.TeslaModel3Options.toObject(includeInstance, f),
    modelY: (f = msg.getModelY()) && proto.subscription.TeslaModelYOptions.toObject(includeInstance, f),
    customerId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    programId: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.subscription.ReservationOptionsUpdated}
 */
proto.subscription.ReservationOptionsUpdated.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.subscription.ReservationOptionsUpdated;
  return proto.subscription.ReservationOptionsUpdated.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.subscription.ReservationOptionsUpdated} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.subscription.ReservationOptionsUpdated}
 */
proto.subscription.ReservationOptionsUpdated.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrackingId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdated(value);
      break;
    case 4:
      var value = new proto.subscription.TeslaModel3Options;
      reader.readMessage(value,proto.subscription.TeslaModel3Options.deserializeBinaryFromReader);
      msg.setModel3(value);
      break;
    case 6:
      var value = new proto.subscription.TeslaModelYOptions;
      reader.readMessage(value,proto.subscription.TeslaModelYOptions.deserializeBinaryFromReader);
      msg.setModelY(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setProgramId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.subscription.ReservationOptionsUpdated.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.subscription.ReservationOptionsUpdated.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.subscription.ReservationOptionsUpdated} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription.ReservationOptionsUpdated.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTrackingId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUpdated();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getModel3();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.subscription.TeslaModel3Options.serializeBinaryToWriter
    );
  }
  f = message.getModelY();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.subscription.TeslaModelYOptions.serializeBinaryToWriter
    );
  }
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getProgramId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.subscription.ReservationOptionsUpdated.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.ReservationOptionsUpdated} returns this
 */
proto.subscription.ReservationOptionsUpdated.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string tracking_id = 2;
 * @return {string}
 */
proto.subscription.ReservationOptionsUpdated.prototype.getTrackingId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.ReservationOptionsUpdated} returns this
 */
proto.subscription.ReservationOptionsUpdated.prototype.setTrackingId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 updated = 3;
 * @return {number}
 */
proto.subscription.ReservationOptionsUpdated.prototype.getUpdated = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.subscription.ReservationOptionsUpdated} returns this
 */
proto.subscription.ReservationOptionsUpdated.prototype.setUpdated = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional TeslaModel3Options model_3 = 4;
 * @return {?proto.subscription.TeslaModel3Options}
 */
proto.subscription.ReservationOptionsUpdated.prototype.getModel3 = function() {
  return /** @type{?proto.subscription.TeslaModel3Options} */ (
    jspb.Message.getWrapperField(this, proto.subscription.TeslaModel3Options, 4));
};


/**
 * @param {?proto.subscription.TeslaModel3Options|undefined} value
 * @return {!proto.subscription.ReservationOptionsUpdated} returns this
*/
proto.subscription.ReservationOptionsUpdated.prototype.setModel3 = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.subscription.ReservationOptionsUpdated.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.subscription.ReservationOptionsUpdated} returns this
 */
proto.subscription.ReservationOptionsUpdated.prototype.clearModel3 = function() {
  return this.setModel3(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.subscription.ReservationOptionsUpdated.prototype.hasModel3 = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional TeslaModelYOptions model_y = 6;
 * @return {?proto.subscription.TeslaModelYOptions}
 */
proto.subscription.ReservationOptionsUpdated.prototype.getModelY = function() {
  return /** @type{?proto.subscription.TeslaModelYOptions} */ (
    jspb.Message.getWrapperField(this, proto.subscription.TeslaModelYOptions, 6));
};


/**
 * @param {?proto.subscription.TeslaModelYOptions|undefined} value
 * @return {!proto.subscription.ReservationOptionsUpdated} returns this
*/
proto.subscription.ReservationOptionsUpdated.prototype.setModelY = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.subscription.ReservationOptionsUpdated.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.subscription.ReservationOptionsUpdated} returns this
 */
proto.subscription.ReservationOptionsUpdated.prototype.clearModelY = function() {
  return this.setModelY(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.subscription.ReservationOptionsUpdated.prototype.hasModelY = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string customer_id = 5;
 * @return {string}
 */
proto.subscription.ReservationOptionsUpdated.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.ReservationOptionsUpdated} returns this
 */
proto.subscription.ReservationOptionsUpdated.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string program_id = 7;
 * @return {string}
 */
proto.subscription.ReservationOptionsUpdated.prototype.getProgramId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.ReservationOptionsUpdated} returns this
 */
proto.subscription.ReservationOptionsUpdated.prototype.setProgramId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.subscription.ProcessDueStatements.prototype.toObject = function(opt_includeInstance) {
  return proto.subscription.ProcessDueStatements.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.subscription.ProcessDueStatements} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription.ProcessDueStatements.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.subscription.ProcessDueStatements}
 */
proto.subscription.ProcessDueStatements.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.subscription.ProcessDueStatements;
  return proto.subscription.ProcessDueStatements.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.subscription.ProcessDueStatements} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.subscription.ProcessDueStatements}
 */
proto.subscription.ProcessDueStatements.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.subscription.ProcessDueStatements.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.subscription.ProcessDueStatements.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.subscription.ProcessDueStatements} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription.ProcessDueStatements.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.subscription.StatementDue.prototype.toObject = function(opt_includeInstance) {
  return proto.subscription.StatementDue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.subscription.StatementDue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription.StatementDue.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    statement: (f = msg.getStatement()) && domain_pb.Statement.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.subscription.StatementDue}
 */
proto.subscription.StatementDue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.subscription.StatementDue;
  return proto.subscription.StatementDue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.subscription.StatementDue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.subscription.StatementDue}
 */
proto.subscription.StatementDue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 3:
      var value = new domain_pb.Statement;
      reader.readMessage(value,domain_pb.Statement.deserializeBinaryFromReader);
      msg.setStatement(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.subscription.StatementDue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.subscription.StatementDue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.subscription.StatementDue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription.StatementDue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStatement();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      domain_pb.Statement.serializeBinaryToWriter
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.subscription.StatementDue.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.StatementDue} returns this
 */
proto.subscription.StatementDue.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string subscription_id = 2;
 * @return {string}
 */
proto.subscription.StatementDue.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.StatementDue} returns this
 */
proto.subscription.StatementDue.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional domain.Statement statement = 3;
 * @return {?proto.domain.Statement}
 */
proto.subscription.StatementDue.prototype.getStatement = function() {
  return /** @type{?proto.domain.Statement} */ (
    jspb.Message.getWrapperField(this, domain_pb.Statement, 3));
};


/**
 * @param {?proto.domain.Statement|undefined} value
 * @return {!proto.subscription.StatementDue} returns this
*/
proto.subscription.StatementDue.prototype.setStatement = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.subscription.StatementDue} returns this
 */
proto.subscription.StatementDue.prototype.clearStatement = function() {
  return this.setStatement(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.subscription.StatementDue.prototype.hasStatement = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.subscription.VehicleAssigned.prototype.toObject = function(opt_includeInstance) {
  return proto.subscription.VehicleAssigned.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.subscription.VehicleAssigned} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription.VehicleAssigned.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    vin: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.subscription.VehicleAssigned}
 */
proto.subscription.VehicleAssigned.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.subscription.VehicleAssigned;
  return proto.subscription.VehicleAssigned.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.subscription.VehicleAssigned} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.subscription.VehicleAssigned}
 */
proto.subscription.VehicleAssigned.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVin(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.subscription.VehicleAssigned.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.subscription.VehicleAssigned.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.subscription.VehicleAssigned} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription.VehicleAssigned.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVin();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string vehicle_id = 1;
 * @return {string}
 */
proto.subscription.VehicleAssigned.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.VehicleAssigned} returns this
 */
proto.subscription.VehicleAssigned.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string vin = 2;
 * @return {string}
 */
proto.subscription.VehicleAssigned.prototype.getVin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.VehicleAssigned} returns this
 */
proto.subscription.VehicleAssigned.prototype.setVin = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.subscription.SubscriptionActivated.prototype.toObject = function(opt_includeInstance) {
  return proto.subscription.SubscriptionActivated.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.subscription.SubscriptionActivated} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription.SubscriptionActivated.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    vehicleId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    customerId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.subscription.SubscriptionActivated}
 */
proto.subscription.SubscriptionActivated.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.subscription.SubscriptionActivated;
  return proto.subscription.SubscriptionActivated.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.subscription.SubscriptionActivated} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.subscription.SubscriptionActivated}
 */
proto.subscription.SubscriptionActivated.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.subscription.SubscriptionActivated.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.subscription.SubscriptionActivated.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.subscription.SubscriptionActivated} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription.SubscriptionActivated.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string subscription_id = 1;
 * @return {string}
 */
proto.subscription.SubscriptionActivated.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.SubscriptionActivated} returns this
 */
proto.subscription.SubscriptionActivated.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string vehicle_id = 2;
 * @return {string}
 */
proto.subscription.SubscriptionActivated.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.SubscriptionActivated} returns this
 */
proto.subscription.SubscriptionActivated.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string customer_id = 3;
 * @return {string}
 */
proto.subscription.SubscriptionActivated.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.SubscriptionActivated} returns this
 */
proto.subscription.SubscriptionActivated.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.subscription.SubscriptionUpdated.prototype.toObject = function(opt_includeInstance) {
  return proto.subscription.SubscriptionUpdated.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.subscription.SubscriptionUpdated} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription.SubscriptionUpdated.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    vehicleId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    status: jspb.Message.getFieldWithDefault(msg, 3, 0),
    reason: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.subscription.SubscriptionUpdated}
 */
proto.subscription.SubscriptionUpdated.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.subscription.SubscriptionUpdated;
  return proto.subscription.SubscriptionUpdated.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.subscription.SubscriptionUpdated} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.subscription.SubscriptionUpdated}
 */
proto.subscription.SubscriptionUpdated.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 3:
      var value = /** @type {!proto.domain.Subscription.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 4:
      var value = /** @type {!proto.subscription.SubscriptionUpdated.Reason} */ (reader.readEnum());
      msg.setReason(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.subscription.SubscriptionUpdated.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.subscription.SubscriptionUpdated.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.subscription.SubscriptionUpdated} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription.SubscriptionUpdated.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getReason();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.subscription.SubscriptionUpdated.Reason = {
  UNKNOWN: 0,
  RETURNED: 1,
  SWAPPED: 2
};

/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.subscription.SubscriptionUpdated.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.SubscriptionUpdated} returns this
 */
proto.subscription.SubscriptionUpdated.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string vehicle_id = 2;
 * @return {string}
 */
proto.subscription.SubscriptionUpdated.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.SubscriptionUpdated} returns this
 */
proto.subscription.SubscriptionUpdated.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional domain.Subscription.Status status = 3;
 * @return {!proto.domain.Subscription.Status}
 */
proto.subscription.SubscriptionUpdated.prototype.getStatus = function() {
  return /** @type {!proto.domain.Subscription.Status} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.domain.Subscription.Status} value
 * @return {!proto.subscription.SubscriptionUpdated} returns this
 */
proto.subscription.SubscriptionUpdated.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional Reason reason = 4;
 * @return {!proto.subscription.SubscriptionUpdated.Reason}
 */
proto.subscription.SubscriptionUpdated.prototype.getReason = function() {
  return /** @type {!proto.subscription.SubscriptionUpdated.Reason} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.subscription.SubscriptionUpdated.Reason} value
 * @return {!proto.subscription.SubscriptionUpdated} returns this
 */
proto.subscription.SubscriptionUpdated.prototype.setReason = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.subscription.ProgramPricingOptionsUpdated.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.subscription.ProgramPricingOptionsUpdated.prototype.toObject = function(opt_includeInstance) {
  return proto.subscription.ProgramPricingOptionsUpdated.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.subscription.ProgramPricingOptionsUpdated} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription.ProgramPricingOptionsUpdated.toObject = function(includeInstance, msg) {
  var f, obj = {
    programId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pricingOptionsList: jspb.Message.toObjectList(msg.getPricingOptionsList(),
    proto.subscription.PricingOption.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.subscription.ProgramPricingOptionsUpdated}
 */
proto.subscription.ProgramPricingOptionsUpdated.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.subscription.ProgramPricingOptionsUpdated;
  return proto.subscription.ProgramPricingOptionsUpdated.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.subscription.ProgramPricingOptionsUpdated} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.subscription.ProgramPricingOptionsUpdated}
 */
proto.subscription.ProgramPricingOptionsUpdated.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProgramId(value);
      break;
    case 2:
      var value = new proto.subscription.PricingOption;
      reader.readMessage(value,proto.subscription.PricingOption.deserializeBinaryFromReader);
      msg.addPricingOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.subscription.ProgramPricingOptionsUpdated.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.subscription.ProgramPricingOptionsUpdated.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.subscription.ProgramPricingOptionsUpdated} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription.ProgramPricingOptionsUpdated.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProgramId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPricingOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.subscription.PricingOption.serializeBinaryToWriter
    );
  }
};


/**
 * optional string program_id = 1;
 * @return {string}
 */
proto.subscription.ProgramPricingOptionsUpdated.prototype.getProgramId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.ProgramPricingOptionsUpdated} returns this
 */
proto.subscription.ProgramPricingOptionsUpdated.prototype.setProgramId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated PricingOption pricing_options = 2;
 * @return {!Array<!proto.subscription.PricingOption>}
 */
proto.subscription.ProgramPricingOptionsUpdated.prototype.getPricingOptionsList = function() {
  return /** @type{!Array<!proto.subscription.PricingOption>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.subscription.PricingOption, 2));
};


/**
 * @param {!Array<!proto.subscription.PricingOption>} value
 * @return {!proto.subscription.ProgramPricingOptionsUpdated} returns this
*/
proto.subscription.ProgramPricingOptionsUpdated.prototype.setPricingOptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.subscription.PricingOption=} opt_value
 * @param {number=} opt_index
 * @return {!proto.subscription.PricingOption}
 */
proto.subscription.ProgramPricingOptionsUpdated.prototype.addPricingOptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.subscription.PricingOption, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.subscription.ProgramPricingOptionsUpdated} returns this
 */
proto.subscription.ProgramPricingOptionsUpdated.prototype.clearPricingOptionsList = function() {
  return this.setPricingOptionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.subscription.ContractProperties.prototype.toObject = function(opt_includeInstance) {
  return proto.subscription.ContractProperties.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.subscription.ContractProperties} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription.ContractProperties.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    vin: jspb.Message.getFieldWithDefault(msg, 3, ""),
    odometer: jspb.Message.getFieldWithDefault(msg, 4, ""),
    year: jspb.Message.getFieldWithDefault(msg, 5, ""),
    model: jspb.Message.getFieldWithDefault(msg, 6, ""),
    trim: jspb.Message.getFieldWithDefault(msg, 7, ""),
    make: jspb.Message.getFieldWithDefault(msg, 8, ""),
    paint: jspb.Message.getFieldWithDefault(msg, 9, ""),
    agreementDate: jspb.Message.getFieldWithDefault(msg, 10, ""),
    customerAccountNumber: jspb.Message.getFieldWithDefault(msg, 11, ""),
    customerName: jspb.Message.getFieldWithDefault(msg, 12, ""),
    billingAddress: jspb.Message.getFieldWithDefault(msg, 13, ""),
    subscriptionBaseMonthly: jspb.Message.getFieldWithDefault(msg, 14, ""),
    insuranceMonthly: jspb.Message.getFieldWithDefault(msg, 15, ""),
    insuranceMonthlyTax: jspb.Message.getFieldWithDefault(msg, 16, ""),
    subscriptionTax: jspb.Message.getFieldWithDefault(msg, 17, ""),
    subscriptionTotalMonthly: jspb.Message.getFieldWithDefault(msg, 18, ""),
    subscriptionTotalMonthlyTax: jspb.Message.getFieldWithDefault(msg, 19, ""),
    promoCode: jspb.Message.getFieldWithDefault(msg, 20, ""),
    promoCodeAmount: jspb.Message.getFieldWithDefault(msg, 21, ""),
    billedMonthlyStartingOn: jspb.Message.getFieldWithDefault(msg, 22, ""),
    subscriptionExcessMileageRate: jspb.Message.getFieldWithDefault(msg, 23, ""),
    insuranceExcessMileageRate: jspb.Message.getFieldWithDefault(msg, 24, ""),
    reservationFee: jspb.Message.getFieldWithDefault(msg, 25, ""),
    subscriptionRefundableDeposit: jspb.Message.getFieldWithDefault(msg, 26, ""),
    securityDepositPaidLastFourStatement: jspb.Message.getFieldWithDefault(msg, 27, ""),
    subscriptionStartPayment: jspb.Message.getFieldWithDefault(msg, 28, ""),
    subscriptionStartPaymentTax: jspb.Message.getFieldWithDefault(msg, 29, ""),
    driveOffSummary: jspb.Message.getFieldWithDefault(msg, 30, ""),
    driveOffTotalTax: jspb.Message.getFieldWithDefault(msg, 31, ""),
    subscriptionTotalDueToStart: jspb.Message.getFieldWithDefault(msg, 32, ""),
    firstMonthSubscriptionTax: jspb.Message.getFieldWithDefault(msg, 33, ""),
    firstMonthSubscriptionTotal: jspb.Message.getFieldWithDefault(msg, 34, ""),
    priceTermMonths: jspb.Message.getFieldWithDefault(msg, 35, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.subscription.ContractProperties}
 */
proto.subscription.ContractProperties.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.subscription.ContractProperties;
  return proto.subscription.ContractProperties.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.subscription.ContractProperties} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.subscription.ContractProperties}
 */
proto.subscription.ContractProperties.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setVin(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOdometer(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setYear(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setModel(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrim(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setMake(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaint(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setAgreementDate(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerAccountNumber(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerName(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setBillingAddress(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionBaseMonthly(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setInsuranceMonthly(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setInsuranceMonthlyTax(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionTax(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionTotalMonthly(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionTotalMonthlyTax(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setPromoCode(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setPromoCodeAmount(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setBilledMonthlyStartingOn(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionExcessMileageRate(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setInsuranceExcessMileageRate(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setReservationFee(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionRefundableDeposit(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.setSecurityDepositPaidLastFourStatement(value);
      break;
    case 28:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionStartPayment(value);
      break;
    case 29:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionStartPaymentTax(value);
      break;
    case 30:
      var value = /** @type {string} */ (reader.readString());
      msg.setDriveOffSummary(value);
      break;
    case 31:
      var value = /** @type {string} */ (reader.readString());
      msg.setDriveOffTotalTax(value);
      break;
    case 32:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionTotalDueToStart(value);
      break;
    case 33:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstMonthSubscriptionTax(value);
      break;
    case 34:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstMonthSubscriptionTotal(value);
      break;
    case 35:
      var value = /** @type {string} */ (reader.readString());
      msg.setPriceTermMonths(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.subscription.ContractProperties.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.subscription.ContractProperties.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.subscription.ContractProperties} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription.ContractProperties.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getVin();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOdometer();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getYear();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getModel();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getTrim();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getMake();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getPaint();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getAgreementDate();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getCustomerAccountNumber();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getCustomerName();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getBillingAddress();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getSubscriptionBaseMonthly();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getInsuranceMonthly();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getInsuranceMonthlyTax();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getSubscriptionTax();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getSubscriptionTotalMonthly();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getSubscriptionTotalMonthlyTax();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getPromoCode();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getPromoCodeAmount();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getBilledMonthlyStartingOn();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getSubscriptionExcessMileageRate();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getInsuranceExcessMileageRate();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getReservationFee();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getSubscriptionRefundableDeposit();
  if (f.length > 0) {
    writer.writeString(
      26,
      f
    );
  }
  f = message.getSecurityDepositPaidLastFourStatement();
  if (f.length > 0) {
    writer.writeString(
      27,
      f
    );
  }
  f = message.getSubscriptionStartPayment();
  if (f.length > 0) {
    writer.writeString(
      28,
      f
    );
  }
  f = message.getSubscriptionStartPaymentTax();
  if (f.length > 0) {
    writer.writeString(
      29,
      f
    );
  }
  f = message.getDriveOffSummary();
  if (f.length > 0) {
    writer.writeString(
      30,
      f
    );
  }
  f = message.getDriveOffTotalTax();
  if (f.length > 0) {
    writer.writeString(
      31,
      f
    );
  }
  f = message.getSubscriptionTotalDueToStart();
  if (f.length > 0) {
    writer.writeString(
      32,
      f
    );
  }
  f = message.getFirstMonthSubscriptionTax();
  if (f.length > 0) {
    writer.writeString(
      33,
      f
    );
  }
  f = message.getFirstMonthSubscriptionTotal();
  if (f.length > 0) {
    writer.writeString(
      34,
      f
    );
  }
  f = message.getPriceTermMonths();
  if (f.length > 0) {
    writer.writeString(
      35,
      f
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.subscription.ContractProperties.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.ContractProperties} returns this
 */
proto.subscription.ContractProperties.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string subscription_id = 2;
 * @return {string}
 */
proto.subscription.ContractProperties.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.ContractProperties} returns this
 */
proto.subscription.ContractProperties.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string vin = 3;
 * @return {string}
 */
proto.subscription.ContractProperties.prototype.getVin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.ContractProperties} returns this
 */
proto.subscription.ContractProperties.prototype.setVin = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string odometer = 4;
 * @return {string}
 */
proto.subscription.ContractProperties.prototype.getOdometer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.ContractProperties} returns this
 */
proto.subscription.ContractProperties.prototype.setOdometer = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string year = 5;
 * @return {string}
 */
proto.subscription.ContractProperties.prototype.getYear = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.ContractProperties} returns this
 */
proto.subscription.ContractProperties.prototype.setYear = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string model = 6;
 * @return {string}
 */
proto.subscription.ContractProperties.prototype.getModel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.ContractProperties} returns this
 */
proto.subscription.ContractProperties.prototype.setModel = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string trim = 7;
 * @return {string}
 */
proto.subscription.ContractProperties.prototype.getTrim = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.ContractProperties} returns this
 */
proto.subscription.ContractProperties.prototype.setTrim = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string make = 8;
 * @return {string}
 */
proto.subscription.ContractProperties.prototype.getMake = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.ContractProperties} returns this
 */
proto.subscription.ContractProperties.prototype.setMake = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string paint = 9;
 * @return {string}
 */
proto.subscription.ContractProperties.prototype.getPaint = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.ContractProperties} returns this
 */
proto.subscription.ContractProperties.prototype.setPaint = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string agreement_date = 10;
 * @return {string}
 */
proto.subscription.ContractProperties.prototype.getAgreementDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.ContractProperties} returns this
 */
proto.subscription.ContractProperties.prototype.setAgreementDate = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string customer_account_number = 11;
 * @return {string}
 */
proto.subscription.ContractProperties.prototype.getCustomerAccountNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.ContractProperties} returns this
 */
proto.subscription.ContractProperties.prototype.setCustomerAccountNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string customer_name = 12;
 * @return {string}
 */
proto.subscription.ContractProperties.prototype.getCustomerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.ContractProperties} returns this
 */
proto.subscription.ContractProperties.prototype.setCustomerName = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string billing_address = 13;
 * @return {string}
 */
proto.subscription.ContractProperties.prototype.getBillingAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.ContractProperties} returns this
 */
proto.subscription.ContractProperties.prototype.setBillingAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string subscription_base_monthly = 14;
 * @return {string}
 */
proto.subscription.ContractProperties.prototype.getSubscriptionBaseMonthly = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.ContractProperties} returns this
 */
proto.subscription.ContractProperties.prototype.setSubscriptionBaseMonthly = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string insurance_monthly = 15;
 * @return {string}
 */
proto.subscription.ContractProperties.prototype.getInsuranceMonthly = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.ContractProperties} returns this
 */
proto.subscription.ContractProperties.prototype.setInsuranceMonthly = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string insurance_monthly_tax = 16;
 * @return {string}
 */
proto.subscription.ContractProperties.prototype.getInsuranceMonthlyTax = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.ContractProperties} returns this
 */
proto.subscription.ContractProperties.prototype.setInsuranceMonthlyTax = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string subscription_tax = 17;
 * @return {string}
 */
proto.subscription.ContractProperties.prototype.getSubscriptionTax = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.ContractProperties} returns this
 */
proto.subscription.ContractProperties.prototype.setSubscriptionTax = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string subscription_total_monthly = 18;
 * @return {string}
 */
proto.subscription.ContractProperties.prototype.getSubscriptionTotalMonthly = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.ContractProperties} returns this
 */
proto.subscription.ContractProperties.prototype.setSubscriptionTotalMonthly = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string subscription_total_monthly_tax = 19;
 * @return {string}
 */
proto.subscription.ContractProperties.prototype.getSubscriptionTotalMonthlyTax = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.ContractProperties} returns this
 */
proto.subscription.ContractProperties.prototype.setSubscriptionTotalMonthlyTax = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string promo_code = 20;
 * @return {string}
 */
proto.subscription.ContractProperties.prototype.getPromoCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.ContractProperties} returns this
 */
proto.subscription.ContractProperties.prototype.setPromoCode = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string promo_code_amount = 21;
 * @return {string}
 */
proto.subscription.ContractProperties.prototype.getPromoCodeAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.ContractProperties} returns this
 */
proto.subscription.ContractProperties.prototype.setPromoCodeAmount = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string billed_monthly_starting_on = 22;
 * @return {string}
 */
proto.subscription.ContractProperties.prototype.getBilledMonthlyStartingOn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.ContractProperties} returns this
 */
proto.subscription.ContractProperties.prototype.setBilledMonthlyStartingOn = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional string subscription_excess_mileage_rate = 23;
 * @return {string}
 */
proto.subscription.ContractProperties.prototype.getSubscriptionExcessMileageRate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.ContractProperties} returns this
 */
proto.subscription.ContractProperties.prototype.setSubscriptionExcessMileageRate = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional string insurance_excess_mileage_rate = 24;
 * @return {string}
 */
proto.subscription.ContractProperties.prototype.getInsuranceExcessMileageRate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.ContractProperties} returns this
 */
proto.subscription.ContractProperties.prototype.setInsuranceExcessMileageRate = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional string reservation_fee = 25;
 * @return {string}
 */
proto.subscription.ContractProperties.prototype.getReservationFee = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.ContractProperties} returns this
 */
proto.subscription.ContractProperties.prototype.setReservationFee = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional string subscription_refundable_deposit = 26;
 * @return {string}
 */
proto.subscription.ContractProperties.prototype.getSubscriptionRefundableDeposit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.ContractProperties} returns this
 */
proto.subscription.ContractProperties.prototype.setSubscriptionRefundableDeposit = function(value) {
  return jspb.Message.setProto3StringField(this, 26, value);
};


/**
 * optional string security_deposit_paid_last_four_statement = 27;
 * @return {string}
 */
proto.subscription.ContractProperties.prototype.getSecurityDepositPaidLastFourStatement = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.ContractProperties} returns this
 */
proto.subscription.ContractProperties.prototype.setSecurityDepositPaidLastFourStatement = function(value) {
  return jspb.Message.setProto3StringField(this, 27, value);
};


/**
 * optional string subscription_start_payment = 28;
 * @return {string}
 */
proto.subscription.ContractProperties.prototype.getSubscriptionStartPayment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 28, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.ContractProperties} returns this
 */
proto.subscription.ContractProperties.prototype.setSubscriptionStartPayment = function(value) {
  return jspb.Message.setProto3StringField(this, 28, value);
};


/**
 * optional string subscription_start_payment_tax = 29;
 * @return {string}
 */
proto.subscription.ContractProperties.prototype.getSubscriptionStartPaymentTax = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 29, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.ContractProperties} returns this
 */
proto.subscription.ContractProperties.prototype.setSubscriptionStartPaymentTax = function(value) {
  return jspb.Message.setProto3StringField(this, 29, value);
};


/**
 * optional string drive_off_summary = 30;
 * @return {string}
 */
proto.subscription.ContractProperties.prototype.getDriveOffSummary = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.ContractProperties} returns this
 */
proto.subscription.ContractProperties.prototype.setDriveOffSummary = function(value) {
  return jspb.Message.setProto3StringField(this, 30, value);
};


/**
 * optional string drive_off_total_tax = 31;
 * @return {string}
 */
proto.subscription.ContractProperties.prototype.getDriveOffTotalTax = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 31, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.ContractProperties} returns this
 */
proto.subscription.ContractProperties.prototype.setDriveOffTotalTax = function(value) {
  return jspb.Message.setProto3StringField(this, 31, value);
};


/**
 * optional string subscription_total_due_to_start = 32;
 * @return {string}
 */
proto.subscription.ContractProperties.prototype.getSubscriptionTotalDueToStart = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 32, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.ContractProperties} returns this
 */
proto.subscription.ContractProperties.prototype.setSubscriptionTotalDueToStart = function(value) {
  return jspb.Message.setProto3StringField(this, 32, value);
};


/**
 * optional string first_month_subscription_tax = 33;
 * @return {string}
 */
proto.subscription.ContractProperties.prototype.getFirstMonthSubscriptionTax = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 33, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.ContractProperties} returns this
 */
proto.subscription.ContractProperties.prototype.setFirstMonthSubscriptionTax = function(value) {
  return jspb.Message.setProto3StringField(this, 33, value);
};


/**
 * optional string first_month_subscription_total = 34;
 * @return {string}
 */
proto.subscription.ContractProperties.prototype.getFirstMonthSubscriptionTotal = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 34, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.ContractProperties} returns this
 */
proto.subscription.ContractProperties.prototype.setFirstMonthSubscriptionTotal = function(value) {
  return jspb.Message.setProto3StringField(this, 34, value);
};


/**
 * optional string price_term_months = 35;
 * @return {string}
 */
proto.subscription.ContractProperties.prototype.getPriceTermMonths = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 35, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.ContractProperties} returns this
 */
proto.subscription.ContractProperties.prototype.setPriceTermMonths = function(value) {
  return jspb.Message.setProto3StringField(this, 35, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.subscription.VehiclePickupReceiptProperties.prototype.toObject = function(opt_includeInstance) {
  return proto.subscription.VehiclePickupReceiptProperties.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.subscription.VehiclePickupReceiptProperties} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription.VehiclePickupReceiptProperties.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    billingAddress: jspb.Message.getFieldWithDefault(msg, 2, ""),
    vin: jspb.Message.getFieldWithDefault(msg, 3, ""),
    autonomyId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    currentMileage: jspb.Message.getFieldWithDefault(msg, 5, ""),
    startDate: jspb.Message.getFieldWithDefault(msg, 6, ""),
    startFee: jspb.Message.getFieldWithDefault(msg, 7, ""),
    monthlyFee: jspb.Message.getFieldWithDefault(msg, 8, ""),
    nextMonthlyPayment: jspb.Message.getFieldWithDefault(msg, 9, ""),
    priceTermMonths: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.subscription.VehiclePickupReceiptProperties}
 */
proto.subscription.VehiclePickupReceiptProperties.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.subscription.VehiclePickupReceiptProperties;
  return proto.subscription.VehiclePickupReceiptProperties.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.subscription.VehiclePickupReceiptProperties} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.subscription.VehiclePickupReceiptProperties}
 */
proto.subscription.VehiclePickupReceiptProperties.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBillingAddress(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setVin(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAutonomyId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrentMileage(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartDate(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartFee(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setMonthlyFee(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextMonthlyPayment(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setPriceTermMonths(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.subscription.VehiclePickupReceiptProperties.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.subscription.VehiclePickupReceiptProperties.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.subscription.VehiclePickupReceiptProperties} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription.VehiclePickupReceiptProperties.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBillingAddress();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getVin();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAutonomyId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCurrentMileage();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getStartDate();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getStartFee();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getMonthlyFee();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getNextMonthlyPayment();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getPriceTermMonths();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional string customer_name = 1;
 * @return {string}
 */
proto.subscription.VehiclePickupReceiptProperties.prototype.getCustomerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.VehiclePickupReceiptProperties} returns this
 */
proto.subscription.VehiclePickupReceiptProperties.prototype.setCustomerName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string billing_address = 2;
 * @return {string}
 */
proto.subscription.VehiclePickupReceiptProperties.prototype.getBillingAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.VehiclePickupReceiptProperties} returns this
 */
proto.subscription.VehiclePickupReceiptProperties.prototype.setBillingAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string vin = 3;
 * @return {string}
 */
proto.subscription.VehiclePickupReceiptProperties.prototype.getVin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.VehiclePickupReceiptProperties} returns this
 */
proto.subscription.VehiclePickupReceiptProperties.prototype.setVin = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string autonomy_id = 4;
 * @return {string}
 */
proto.subscription.VehiclePickupReceiptProperties.prototype.getAutonomyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.VehiclePickupReceiptProperties} returns this
 */
proto.subscription.VehiclePickupReceiptProperties.prototype.setAutonomyId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string current_mileage = 5;
 * @return {string}
 */
proto.subscription.VehiclePickupReceiptProperties.prototype.getCurrentMileage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.VehiclePickupReceiptProperties} returns this
 */
proto.subscription.VehiclePickupReceiptProperties.prototype.setCurrentMileage = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string start_date = 6;
 * @return {string}
 */
proto.subscription.VehiclePickupReceiptProperties.prototype.getStartDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.VehiclePickupReceiptProperties} returns this
 */
proto.subscription.VehiclePickupReceiptProperties.prototype.setStartDate = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string start_fee = 7;
 * @return {string}
 */
proto.subscription.VehiclePickupReceiptProperties.prototype.getStartFee = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.VehiclePickupReceiptProperties} returns this
 */
proto.subscription.VehiclePickupReceiptProperties.prototype.setStartFee = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string monthly_fee = 8;
 * @return {string}
 */
proto.subscription.VehiclePickupReceiptProperties.prototype.getMonthlyFee = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.VehiclePickupReceiptProperties} returns this
 */
proto.subscription.VehiclePickupReceiptProperties.prototype.setMonthlyFee = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string next_monthly_payment = 9;
 * @return {string}
 */
proto.subscription.VehiclePickupReceiptProperties.prototype.getNextMonthlyPayment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.VehiclePickupReceiptProperties} returns this
 */
proto.subscription.VehiclePickupReceiptProperties.prototype.setNextMonthlyPayment = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string price_term_months = 10;
 * @return {string}
 */
proto.subscription.VehiclePickupReceiptProperties.prototype.getPriceTermMonths = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription.VehiclePickupReceiptProperties} returns this
 */
proto.subscription.VehiclePickupReceiptProperties.prototype.setPriceTermMonths = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * @enum {number}
 */
proto.subscription.SubscriptionStatus = {
  PENDING: 0,
  ACTIVE: 1,
  CLOSED: 2,
  RETURNING: 3
};

/**
 * @enum {number}
 */
proto.subscription.ReservationStatus = {
  CREATED: 0,
  PAID: 1,
  ASSIGNED_VEHICLE: 2,
  CONVERTED: 3,
  REFUND_REQUESTED: 4,
  REJECTED: 5,
  VEHICLE_UNASSIGNED: 6
};

goog.object.extend(exports, proto.subscription);
