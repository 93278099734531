import { AppointmentDay } from '@nxcr-org/web-api-interface/lib/nexus_service_pb'
import {
  Appointment,
  AppointmentStatus,
  AppointmentStatusMap,
  AppointmentType,
  AppointmentTypeMap,
  DealershipInfo,
  Note,
  CustomerInfo,
} from '@nxcr-org/web-api-interface/lib/appointment_pb'
import { getTimezoneOffset } from 'date-fns-tz'
import set from 'date-fns/set'
import { map } from 'lodash'

/*
 * Convert a unix timestamp to a normal date and into the given timezone.
 * Add the duration to that in order to display a formatted time slot like
 * '09:00 AM - 10:00 AM' to display on the appointment list item. This time slot
 * is displayed in the LOCAL time of where the event will take place.
 * */
export function getFormattedEventTimeSlot(
  startTime: number,
  timeZone: string,
  duration = 3600000
): string {
  const endTime = startTime + duration

  const formattedStartTime = new Date(startTime).toLocaleString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    timeZone: timeZone,
  })

  const formattedEndTime = new Date(endTime).toLocaleString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    timeZone: timeZone,
  })

  return formattedStartTime + ' - ' + formattedEndTime
}

/*
 * To avoid any confusion for the user, we'll add the timezone abbreviation string
 * next to time picker labels etc. so it is clear that the given timeslots are in
 * the timezone specified.
 * */
export function getFormattedTimeZoneAbbreviation(timeZone): string {
  switch (timeZone) {
    case 'America/Los_Angeles':
      return ' (PDT)' // @TODO: should be PST after DST... this shouldn't be hardcoded
  }
}

export function generateEventTimesOptions(
  appointmentDate: number
): SelectOption[] {
  const interval = 30 //minutes interval
  const startingHour = 8
  const endingHour = 19.5
  const startingMinutes = startingHour * 60 // start time in minutes
  const endingMinutes = endingHour * 60 // end time in minutes

  // get the user client timezone
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const timeZoneOffsetToUTC = getTimezoneOffset(userTimeZone, appointmentDate)
  const offsetToLA = timeZoneOffsetToUTC + 3600000 * 7

  const timeSlots = map(
    generateIntervals(startingMinutes, endingMinutes, interval),
    ({ hour, minute }) => {
      const startTime = set(appointmentDate, {
        hours: hour,
        minutes: minute,
        seconds: 0,
        milliseconds: 0,
      }).getTime()

      return {
        label: getFormattedEventTimeSlot(
          startTime + offsetToLA,
          'America/Los_Angeles',
          3600000 / 2
        ),
        value: startTime + offsetToLA,
      }
    }
  )

  return timeSlots
}

function generateIntervals(
  startHourInMinute: number,
  endHourInMinute: number,
  interval: number
) {
  const times = []

  for (let i = 0; startHourInMinute < 24 * 60; i++) {
    if (startHourInMinute > endHourInMinute) break

    const hh = Math.floor(startHourInMinute / 60) // getting hours of day in 0-24 format

    const mm = startHourInMinute % 60 // getting minutes of the hour in 0-55 format

    startHourInMinute = startHourInMinute + interval
    times[i] = {
      hour: hh,
      minute: mm,
    }
  }

  return times
}

/*
 * Returns a list of AppointmentDays from 'today' and later only
 * */
export function getAppointmentsFromCurrentDate(
  appointmentDaysList: AppointmentDayListItem[]
) {
  const currentDate = new Date()
  currentDate.setHours(0, 0, 0, 0)
  return appointmentDaysList.filter(
    (appointment) =>
      new Date(appointment.day).getTime() >= currentDate.getTime()
  )
}

/*
 * Returns a list of formatted AppointmentDays
 * */
export function getFormattedAppointmentDays(
  appointmentDays: AppointmentDay.AsObject[]
): AppointmentDayListItem[] {
  return appointmentDays.map(({ day, appointmentsList }) => ({
    day,
    appointments: formatAppointments(appointmentsList, day),
  }))
}

/*
 * Returns the formatted date of an AppointmentDay and prefixes the
 * formatted date with 'today -' or 'tomorrow -' if the dates are
 * today or tomorrow respectively.
 * */
export function getFormattedAppointmentDayLabel(appointmentDay) {
  const taskDate = new Date(appointmentDay)
  const today = new Date()
  today.setHours(0, 0, 0, 0)
  const diff = taskDate.getTime() - today.getTime()
  if (today.getTime() == taskDate.getTime()) {
    return `Today - ${Intl.DateTimeFormat().format(taskDate)}`
  } else if (diff <= 24 * 60 * 60 * 1000 && diff > 0) {
    return `Tomorrow - ${Intl.DateTimeFormat().format(taskDate)}`
  }

  return `${Intl.DateTimeFormat('en-us', { weekday: 'long' }).format(
    taskDate
  )} ${Intl.DateTimeFormat().format(taskDate)}`
}

/*
 * Return the corresponding enum key for a given value.
 * ( enumObject is used as param name because 'enum' is a reserved keyword )
 * */
export function getKeyByValue(enumObject, value): string {
  return Object.keys(enumObject).find((key) => enumObject[key] === value)
}

/*
 * Return a formatted string for the ( uppercased ) enum key
 * */
export function keyToReadable(key: string): string {
  if (key && key.length > 0) {
    key = key.toLowerCase()
    key = key.charAt(0).toUpperCase() + key.slice(1)
    return key
  }

  return null
}

/*
 * Format Appointments response to FE usable appointments
 * */
function formatAppointments(
  appointments: Appointment.AsObject[],
  day: string
): IAppointmentListItem[] {
  return appointments
    .filter((appointment) => !!appointment.customerInfo) // filter any appointments with corrupted customer data so non-corrupted appointments are still rendered
    .map(
      ({
        appointmentType: type,
        appointmentStatus: status,
        dealershipInfo,
        startTime,
        notesList,
        customerInfo,
        vehicleInfo,
        timeZone,
        id,
        duration,
        reservationId,
        subscriptionId,
      }) => {
        return {
          id,
          reservationId,
          date: new Date(day),
          customerId: customerInfo.id,
          customerName: customerInfo.fullName,
          customerAccountNumber: customerInfo.accountNum,
          location: dealershipInfo.name,
          dealershipId: dealershipInfo.id,
          assigneeName: dealershipInfo.assignee,
          assigneeId: dealershipInfo.assigneeId,
          note: notesList?.[notesList?.length - 1]?.content,
          noteAuthor: notesList?.[notesList?.length - 1]?.author,
          notes: notesList,
          startTime: startTime,
          typeLabel: keyToReadable(getKeyByValue(AppointmentType, type)),
          type: type,
          statusLabel: keyToReadable(getKeyByValue(AppointmentStatus, status)),
          status: status,
          timeZone,
          vehicleId: vehicleInfo?.id,
          vin: vehicleInfo?.vin,
          make: vehicleInfo?.make,
          model: vehicleInfo?.model,
          year: vehicleInfo?.year,
          color: vehicleInfo?.color,
          subscriptionId: subscriptionId,
          duration,
        }
      }
    )
}

/*
 * Format Appointment to BE usable
 * */
export function formatAppointmentListItem(
  listItem: IAppointmentListItem
): Appointment {
  const appointment = new Appointment()
  const notesList = listItem.notes.map(({ id, author, content, created }) => {
    const note = new Note()
    note.setAuthor(author)
    note.setId(id)
    note.setContent(content)
    note.setCreated(created)
    return note
  })
  const dealershipInfo = new DealershipInfo()
  dealershipInfo.setId(listItem.dealershipId)
  dealershipInfo.setName(listItem.location)
  dealershipInfo.setAssignee(listItem.assigneeName)
  dealershipInfo.setAssigneeId(listItem.assigneeId)

  const customerInfo = new CustomerInfo()
  customerInfo.setFullName(listItem.customerName)
  customerInfo.setPhone('')
  customerInfo.setId(listItem.customerId)
  customerInfo.setAccountNum(listItem.customerAccountNumber)

  // Add a new note to the notesList if the note has been changed by the user
  if (
    !notesList[0] ||
    notesList[0].getContent() !== listItem.note ||
    notesList[0].getAuthor() !== listItem.noteAuthor
  ) {
    const note = new Note()
    note.setContent(listItem.note)
    note.setAuthor(listItem.noteAuthor)
    notesList.push(note)
  }

  appointment.setNotesList(notesList)
  appointment.setDealershipInfo(dealershipInfo)
  appointment.setId(listItem.id)
  appointment.setStartTime(listItem.startTime)
  appointment.setAppointmentType(listItem.type)
  appointment.setAppointmentStatus(listItem.status)
  appointment.setTimeZone(listItem.timeZone)
  appointment.setDuration(listItem.duration)
  appointment.setCustomerInfo(customerInfo)

  return appointment
}

////////////////////////////////////
//////////// INTERFACES ////////////
///////////////////////////////////

export interface AppointmentDayListItem {
  day: string
  appointments: IAppointmentListItem[]
}

/*
 * Prefixed with 'I' to indicate 'Interface', to avoid conflicts or confusion
 * with the AppointmentListItem JSX component
 * */
export interface IAppointmentListItem {
  id: string
  reservationId: string
  subscriptionId?: string
  customerName: string
  customerId: string
  customerAccountNumber: string
  dealershipId: string
  location: string
  date: Date
  startTime: number
  status: AppointmentStatusMap[keyof AppointmentStatusMap]
  statusLabel: string
  assigneeName: string
  assigneeId: string
  note: string
  notes: Note.AsObject[]
  noteAuthor: string
  typeLabel: string
  timeZone: string
  duration?: number
  type?: AppointmentTypeMap[keyof AppointmentTypeMap]
  vehicleId?: string
  vin?: string
  make?: string
  model?: string
  year?: string
  color?: string
}

export interface SelectOption {
  label: string
  value: string | number
}
