import React, { useRef, useState } from 'react'
import type { ChangeEvent, FC } from 'react'

import {
  Button,
  Radio,
  FormControlLabel,
  Menu,
  MenuItem,
} from '@mui/material'
import { ChevronDown as ChevronDownIcon } from '../../global-styles/nexus-theme/icons/chevron-down'
import { autonomyColor } from '../../global-styles/autonomyColor'

interface RadioSelectProps<T = string | number | boolean> {
  label: string
  onChange?: (value: T) => void // Same as type as the value received above
  options: { label: string; value: T; disabled?: boolean }[]
  value: T // This should accept string[], number[] or boolean[]
}

export const RadioSelect: FC<RadioSelectProps> = (props) => {
  const { label, onChange, options, value = [], ...other } = props
  const anchorRef = useRef<HTMLButtonElement | null>(null)
  const [openMenu, setOpenMenu] = useState<boolean>(false)

  const handleOpenMenu = (): void => {
    setOpenMenu(true)
  }

  const handleCloseMenu = (): void => {
    setOpenMenu(false)
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    console.log(event.target.value)
    onChange(event.target.value)
  }

  return (
    <>
      <Button
        color="inherit"
        endIcon={<ChevronDownIcon fontSize="small" />}
        onClick={handleOpenMenu}
        ref={anchorRef}
        {...other}
        sx={{
          backgroundColor: autonomyColor.cyan,
          color: autonomyColor.black
        }}
      >
        {label}
      </Button>
      <Menu
        anchorEl={anchorRef.current}
        onClose={handleCloseMenu}
        open={openMenu}
        PaperProps={{ style: { minWidth: 250 } }}
      >
        {options.map((option) => (
          <MenuItem key={`${option.label}-${option.value}`}>
            <FormControlLabel
              disabled={option.disabled}
              control={
                <Radio
                  checked={value === option.value}
                  onChange={handleChange}
                  value={option.value}
                  sx={{
                    color: autonomyColor.white,
                    '&.Mui-checked': {
                      color: autonomyColor.white,
                    },
                  }}
                />
              }
              label={option.label}
              sx={{
                flexGrow: 1,
                mr: 0,
                color: autonomyColor.white,
                fontSize: '16px'
              }}
            />
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

