import { Box, Typography } from '@mui/material'
import React from 'react'
import { FormattedVehicle } from '../useVehicleDetail'
import ClickToClipboard from '../../click-to-clipboard'

// import { useVehicleImageStyle } from '../VehicleDetail.styles'

type VehicleDetailsBlockProps = {
  vehicle: FormattedVehicle
}

export const VehicleDetailsBlock: React.FC<VehicleDetailsBlockProps> = ({
  vehicle,
}) => {

  // const VehicleImage = (props: { image: string }) => {
  //   const { classes } = useVehicleImageStyle(props)
  //   return <div className={classes.vehicleImage} />
  // }

  return (
    <Box sx={
      {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '3rem',
      }
    }>
      <div>
        <Typography variant="caption">Color</Typography>
        {/*{ VehicleImage }*/}
      </div>
      <div>
        <Typography variant="caption">Make</Typography>
        <div>
          {vehicle.make || 'N/A'}
        </div>
      </div>
      <div>
        <Typography variant="caption">Model</Typography>
        <div>
          {vehicle.model || 'N/A'}
        </div>
      </div>
      <div>
        <Typography variant="caption">Odometer</Typography>
        <div>
          {vehicle.odometer.reading || 'N/A'}
        </div>
      </div>
      <div>
        <Typography variant="caption">Dealer</Typography>
        <div>
          {vehicle.dealer || 'N/A'}
        </div>
      </div>
      <div>
        <Typography variant="caption">VIN</Typography>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Typography className="hoverStyle">
            <ClickToClipboard>
              {vehicle.vin}
            </ClickToClipboard>
          </Typography>
          <div>
            <svg width="30" height="15" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.67974 3.98087C9.67974 3.08648 8.95413 2.36087 8.05974 2.36087H6.9024C6.65911 1.51148 5.88216 0.925781 4.99908 0.925781C4.11525 0.925781 3.33829 1.51148 3.09576 2.36087H1.93982C1.04544 2.36087 0.319824 3.08648 0.319824 3.98087V10.4609C0.319824 11.3553 1.04544 12.0809 1.93982 12.0809H8.05981C8.9542 12.0809 9.67981 11.3553 9.67981 10.4609L9.67974 3.98087ZM4.09975 2.90087C4.09975 2.40376 4.50264 2.00087 4.99975 2.00087C5.49685 2.00087 5.89975 2.40376 5.89975 2.90087V3.26087C5.89975 3.30868 5.88076 3.35438 5.84701 3.38813C5.81326 3.42188 5.76756 3.44087 5.71975 3.44087H4.27975C4.18061 3.44087 4.09975 3.36001 4.09975 3.26087V2.90087ZM8.59974 10.4609C8.59974 10.759 8.35786 11.0009 8.05974 11.0009H1.93975C1.64163 11.0009 1.39975 10.759 1.39975 10.4609V3.98087C1.39975 3.68275 1.64163 3.44087 1.93975 3.44087H3.03381C3.12381 4.06032 3.65396 4.52017 4.27975 4.52087H5.71975C6.34553 4.52016 6.87569 4.06032 6.96569 3.44087H8.05974C8.35786 3.44087 8.59974 3.68275 8.59974 3.98087V10.4609Z" fill="cyan"/>
            </svg>
          </div>
        </Box>
      </div>
      <div>
        <Typography variant="caption">State</Typography>
        <div>
          {vehicle.status}
        </div>
      </div>
      {vehicle?.customer?.accountNumber && (
        <div>
          <Typography variant="caption">Assigned To</Typography>
          <div>
            {vehicle?.customer?.accountNumber || 'N/A'}
          </div>
        </div>
      )}
    </Box>
  )
}
