import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
} from '@mui/material'

import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles((
  {
    addDocument: {
      minWidth: 600,
    },
  }
))

const AddDocument = ({
  open,
  handleClose,
  types,
  children,
  documentType,
  setDocumentType,
  handleFormSubmit,
  disabled,
  setSelectedFile
}) => {
  const classes = useStyles()

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      className={classes.addDocument}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="form-dialog-title">Add Document</DialogTitle>
      <DialogContent>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Document Type</InputLabel>
              <Select
                labelId="document-type"
                id="select-document-type"
                value={documentType}
                label="Document Type"
                onChange={(evt) => {
                  setSelectedFile(null)
                  setDocumentType(evt.target.value)
                }}
              >
                {types.map((type, i) => {
                  return (
                    <MenuItem key={i} value={type.value}>
                      {type.key}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            {children}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          type="submit"
          onClick={handleFormSubmit}
          disabled={disabled}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AddDocument
