import { useEffect, useState } from 'react'
import { FleetService } from '../../api/fleet-service'
import { NexusVehicle } from '@nxcr-org/web-api-interface/lib/fleet_service_pb'
import { useLoadingState } from '../../hooks/useLoadingState'
import { map } from 'lodash'
import { getCurrentActivity } from '../reservation/useReservation'
import { Address } from '@nxcr-org/web-api-interface/lib/domain_pb'
import AddressType = Address.AddressType;

export function useFleetTable() {
  const loadingState = useLoadingState()
  const [ vehicles, setVehicles ] = useState<FleetTableRow[]>([])
  const [ total, setTotal ] = useState(0)
  const [ page, setPage ] = useState(0)
  const [ pageSize, setPageSize ] = useState(100)

  useEffect(() => {
    loadingState.setLoading()
    setVehicles([])
    setTotal(0)

    FleetService.getVehicles({
      criteriaList: [
        {
          property: 'status',
          query: 'subscribed',
        }, {
          property: 'make',
          query: 'tesla'
        }
      ],
      page: 0,
      resultsPerPage: 500,
      customerId: ''
    })
      .then( ( res ) => {
        setVehicles(createFleetRows(res.vehiclesList))
        setTotal(res.total)
        loadingState.setLoaded()
      })
      .catch(err => {
        console.error('Error on Fleet Table: ')
        console.error(err)
        loadingState.setErrorLoading('Error loading vehicles')
      })
  }, [page, pageSize])

  return {
    loadingState,
    vehicles,
    total,
    page,
    pageSize,
    setPage,
    setPageSize
  }
}

function createFleetRows( vehicles: NexusVehicle.AsObject[]): FleetTableRow[] {
  return map( vehicles, ({ customersList, vehicle }) => {
    const customer = customersList?.slice(-1)?.[0]

    return {
      id: vehicle?.id,
      customerId: customer?.id,
      customerName:
        customer
          ? `${customer?.customerInfo?.firstName} ${customer?.customerInfo?.lastName}`
          : '',
      accountNumber: customer?.accountNumber,
      vin: vehicle?.vin,
      subscriptionStart: customer
        ? new Date(customer?.subscriptionsList[0]?.activationDate).toLocaleDateString()
        : '',
      postal: customer
        ? customer.customerInfo?.addressesList?.find( address => address.addressType === AddressType.PARKING )?.postal
        : '',
      modelTrim: `${vehicle?.model}/${vehicle?.trim}`,
      accountStatus: getCurrentActivity(customer?.activityState),
    }
  })
}

type FleetTableRow = {
  customerId: string;
  customerName: string;
  accountNumber: string;
  vin: string;
  subscriptionStart: string;
  postal: string;
  modelTrim: string;
  accountStatus: string;
}

