import { Vehicle } from '@nxcr-org/web-api-interface/lib/vehicle_pb'
import { last } from 'lodash'

export const intlFormatNumber = (number) =>
  new Intl.NumberFormat().format(number)

export const getMileage = (vehicle: Vehicle.AsObject) => {
  const mileage = last(vehicle?.odometersList)?.reading

  if (!Number.isInteger(mileage)) {
    return 'N/A'
  }
  
  return intlFormatNumber(mileage)
}

export const STATUS = {
  LOADING: 'LOADING',
  RESOLVED: 'RESOLVED',
  ERROR: 'ERROR',
}

export const RESERVATION_STATUSES = {
  ACTIVATED: 'Activated',
  CANCELLED: 'Cancelled',
  ASSIGNED_VEHICLE: 'Assigned Vehicle',
  PENDING: 'Pending',
  PAID: 'Paid',
  QUALIFIED: 'Qualified',
  DISQUALIFIED: 'Disqualified',
}


/**
 * @deprecated
 * @param vehicle 
 * @param type 
 * @returns 
 */
export const getMakeAndModel = (vehicle, type) => {
  if (vehicle?.makeModel?.length > 0) {
    const makeModel = vehicle?.makeModel.split('/')
    return type === 'make' ? makeModel[0] : makeModel[1]
  }
  return 'N/A'
}
