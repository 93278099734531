import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { Typography } from '@mui/material'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Grow from '@mui/material/Grow'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import { State } from '@nxcr-org/web-api-interface/lib/vehicle_pb'
import * as React from 'react'
import { FleetService } from '../../api/fleet-service'

interface UpdateButtonOpts {
  text: string
  updateFn: (vehicleId: string) => Promise<any>
}

const options: Array<UpdateButtonOpts> = [
  { text: 'In Process (delist)', updateFn: FleetService.updateStateToInProcess },
  { text: 'In Listing (list)', updateFn: FleetService.updateStateToInListing },
  { text: 'Sold', updateFn: FleetService.updateStateToSold },
  { text: 'Total Loss', updateFn: FleetService.updateStateToTotalLoss },
]
interface UpdateVehicleStateButtonsProps {
  vehicleId: string
  currentVehicleState: number
  onSuccess: () => void
  onError: (err: Error) => void
}

export default function UpdateVehicleStateButtons(props: UpdateVehicleStateButtonsProps) {
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef<HTMLDivElement>(null)
  const [selectedIndex, setSelectedIndex] = React.useState(1)

  const { vehicleId, currentVehicleState, onSuccess, onError } = props

  const changeStateEnabled = currentVehicleState === State.IN_LISTING || currentVehicleState === State.IN_PROCESS

  const handleClick = () => {
    options[selectedIndex].updateFn(vehicleId)
      .then(onSuccess)
      .catch(onError)
  }

  const handleMenuItemClick = (
    _event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number,
  ) => {
    setSelectedIndex(index)
    setOpen(false)
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return
    }

    setOpen(false)
  }

  return (
    <React.Fragment>
      {!changeStateEnabled && (
        <div>
          <Typography>Current state does not allow manual changes.</Typography>
        </div>
      )}
      <ButtonGroup
        variant="contained"
        ref={anchorRef}
        aria-label="Button group with a nested menu"
        disabled={!changeStateEnabled}
      >
        <Button onClick={handleClick}>{options[selectedIndex].text}</Button>
        <Button
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select state of vehicle"
          aria-haspopup="menu"
          onClick={handleToggle}
          style={{ padding: 0 }}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>

      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={index}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option.text}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  )
}