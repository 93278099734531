// source: customer_support_service.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var domain_pb = require('./domain_pb.js');
goog.object.extend(proto, domain_pb);
var vehicle_pb = require('./vehicle_pb.js');
goog.object.extend(proto, vehicle_pb);
var dealer_service_pb = require('./dealer_service_pb.js');
goog.object.extend(proto, dealer_service_pb);
goog.exportSymbol('proto.customer_support_service.CustomerIdentity', null, global);
goog.exportSymbol('proto.customer_support_service.CustomerInfo', null, global);
goog.exportSymbol('proto.customer_support_service.CustomerInsuranceInfo', null, global);
goog.exportSymbol('proto.customer_support_service.CustomerSubscriptions', null, global);
goog.exportSymbol('proto.customer_support_service.CustomerVehicles', null, global);
goog.exportSymbol('proto.customer_support_service.DateString', null, global);
goog.exportSymbol('proto.customer_support_service.GetPermissionsRequest', null, global);
goog.exportSymbol('proto.customer_support_service.Permissions', null, global);
goog.exportSymbol('proto.customer_support_service.UpdateCustomerGarageAddressRequest', null, global);
goog.exportSymbol('proto.customer_support_service.UpdateCustomerGarageAddressResponse', null, global);
goog.exportSymbol('proto.customer_support_service.UpdateCustomerInfoRequest', null, global);
goog.exportSymbol('proto.customer_support_service.UpdateCustomerInfoResponse', null, global);
goog.exportSymbol('proto.customer_support_service.UpdateCustomerInsuranceInfoRequest', null, global);
goog.exportSymbol('proto.customer_support_service.WipeTwilioFactorsRequest', null, global);
goog.exportSymbol('proto.customer_support_service.WipeTwilioFactorsResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.customer_support_service.GetPermissionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.customer_support_service.GetPermissionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.customer_support_service.GetPermissionsRequest.displayName = 'proto.customer_support_service.GetPermissionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.customer_support_service.Permissions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.customer_support_service.Permissions.repeatedFields_, null);
};
goog.inherits(proto.customer_support_service.Permissions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.customer_support_service.Permissions.displayName = 'proto.customer_support_service.Permissions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.customer_support_service.DateString = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.customer_support_service.DateString, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.customer_support_service.DateString.displayName = 'proto.customer_support_service.DateString';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.customer_support_service.CustomerIdentity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.customer_support_service.CustomerIdentity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.customer_support_service.CustomerIdentity.displayName = 'proto.customer_support_service.CustomerIdentity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.customer_support_service.CustomerInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.customer_support_service.CustomerInfo.repeatedFields_, null);
};
goog.inherits(proto.customer_support_service.CustomerInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.customer_support_service.CustomerInfo.displayName = 'proto.customer_support_service.CustomerInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.customer_support_service.UpdateCustomerInfoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.customer_support_service.UpdateCustomerInfoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.customer_support_service.UpdateCustomerInfoRequest.displayName = 'proto.customer_support_service.UpdateCustomerInfoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.customer_support_service.UpdateCustomerInfoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.customer_support_service.UpdateCustomerInfoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.customer_support_service.UpdateCustomerInfoResponse.displayName = 'proto.customer_support_service.UpdateCustomerInfoResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.customer_support_service.UpdateCustomerGarageAddressRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.customer_support_service.UpdateCustomerGarageAddressRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.customer_support_service.UpdateCustomerGarageAddressRequest.displayName = 'proto.customer_support_service.UpdateCustomerGarageAddressRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.customer_support_service.UpdateCustomerGarageAddressResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.customer_support_service.UpdateCustomerGarageAddressResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.customer_support_service.UpdateCustomerGarageAddressResponse.displayName = 'proto.customer_support_service.UpdateCustomerGarageAddressResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.customer_support_service.CustomerInsuranceInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.customer_support_service.CustomerInsuranceInfo.repeatedFields_, null);
};
goog.inherits(proto.customer_support_service.CustomerInsuranceInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.customer_support_service.CustomerInsuranceInfo.displayName = 'proto.customer_support_service.CustomerInsuranceInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.customer_support_service.UpdateCustomerInsuranceInfoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.customer_support_service.UpdateCustomerInsuranceInfoRequest.repeatedFields_, null);
};
goog.inherits(proto.customer_support_service.UpdateCustomerInsuranceInfoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.customer_support_service.UpdateCustomerInsuranceInfoRequest.displayName = 'proto.customer_support_service.UpdateCustomerInsuranceInfoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.customer_support_service.CustomerSubscriptions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.customer_support_service.CustomerSubscriptions.repeatedFields_, null);
};
goog.inherits(proto.customer_support_service.CustomerSubscriptions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.customer_support_service.CustomerSubscriptions.displayName = 'proto.customer_support_service.CustomerSubscriptions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.customer_support_service.CustomerVehicles = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.customer_support_service.CustomerVehicles.repeatedFields_, null);
};
goog.inherits(proto.customer_support_service.CustomerVehicles, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.customer_support_service.CustomerVehicles.displayName = 'proto.customer_support_service.CustomerVehicles';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.customer_support_service.WipeTwilioFactorsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.customer_support_service.WipeTwilioFactorsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.customer_support_service.WipeTwilioFactorsRequest.displayName = 'proto.customer_support_service.WipeTwilioFactorsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.customer_support_service.WipeTwilioFactorsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.customer_support_service.WipeTwilioFactorsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.customer_support_service.WipeTwilioFactorsResponse.displayName = 'proto.customer_support_service.WipeTwilioFactorsResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.customer_support_service.GetPermissionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.customer_support_service.GetPermissionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.customer_support_service.GetPermissionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer_support_service.GetPermissionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.customer_support_service.GetPermissionsRequest}
 */
proto.customer_support_service.GetPermissionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.customer_support_service.GetPermissionsRequest;
  return proto.customer_support_service.GetPermissionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.customer_support_service.GetPermissionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.customer_support_service.GetPermissionsRequest}
 */
proto.customer_support_service.GetPermissionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.customer_support_service.GetPermissionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.customer_support_service.GetPermissionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.customer_support_service.GetPermissionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer_support_service.GetPermissionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.customer_support_service.Permissions.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.customer_support_service.Permissions.prototype.toObject = function(opt_includeInstance) {
  return proto.customer_support_service.Permissions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.customer_support_service.Permissions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer_support_service.Permissions.toObject = function(includeInstance, msg) {
  var f, obj = {
    accessibleMethodsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.customer_support_service.Permissions}
 */
proto.customer_support_service.Permissions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.customer_support_service.Permissions;
  return proto.customer_support_service.Permissions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.customer_support_service.Permissions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.customer_support_service.Permissions}
 */
proto.customer_support_service.Permissions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addAccessibleMethods(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.customer_support_service.Permissions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.customer_support_service.Permissions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.customer_support_service.Permissions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer_support_service.Permissions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccessibleMethodsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string accessible_methods = 1;
 * @return {!Array<string>}
 */
proto.customer_support_service.Permissions.prototype.getAccessibleMethodsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.customer_support_service.Permissions} returns this
 */
proto.customer_support_service.Permissions.prototype.setAccessibleMethodsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.customer_support_service.Permissions} returns this
 */
proto.customer_support_service.Permissions.prototype.addAccessibleMethods = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.customer_support_service.Permissions} returns this
 */
proto.customer_support_service.Permissions.prototype.clearAccessibleMethodsList = function() {
  return this.setAccessibleMethodsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.customer_support_service.DateString.prototype.toObject = function(opt_includeInstance) {
  return proto.customer_support_service.DateString.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.customer_support_service.DateString} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer_support_service.DateString.toObject = function(includeInstance, msg) {
  var f, obj = {
    yyyyMmDd: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.customer_support_service.DateString}
 */
proto.customer_support_service.DateString.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.customer_support_service.DateString;
  return proto.customer_support_service.DateString.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.customer_support_service.DateString} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.customer_support_service.DateString}
 */
proto.customer_support_service.DateString.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setYyyyMmDd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.customer_support_service.DateString.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.customer_support_service.DateString.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.customer_support_service.DateString} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer_support_service.DateString.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getYyyyMmDd();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string yyyy_mm_dd = 1;
 * @return {string}
 */
proto.customer_support_service.DateString.prototype.getYyyyMmDd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer_support_service.DateString} returns this
 */
proto.customer_support_service.DateString.prototype.setYyyyMmDd = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.customer_support_service.CustomerIdentity.prototype.toObject = function(opt_includeInstance) {
  return proto.customer_support_service.CustomerIdentity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.customer_support_service.CustomerIdentity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer_support_service.CustomerIdentity.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.customer_support_service.CustomerIdentity}
 */
proto.customer_support_service.CustomerIdentity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.customer_support_service.CustomerIdentity;
  return proto.customer_support_service.CustomerIdentity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.customer_support_service.CustomerIdentity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.customer_support_service.CustomerIdentity}
 */
proto.customer_support_service.CustomerIdentity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.customer_support_service.CustomerIdentity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.customer_support_service.CustomerIdentity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.customer_support_service.CustomerIdentity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer_support_service.CustomerIdentity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.customer_support_service.CustomerIdentity.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer_support_service.CustomerIdentity} returns this
 */
proto.customer_support_service.CustomerIdentity.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.customer_support_service.CustomerInfo.repeatedFields_ = [8,9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.customer_support_service.CustomerInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.customer_support_service.CustomerInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.customer_support_service.CustomerInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer_support_service.CustomerInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    accountNumber: jspb.Message.getFieldWithDefault(msg, 2, ""),
    firstName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    language: jspb.Message.getFieldWithDefault(msg, 5, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 6, ""),
    email: jspb.Message.getFieldWithDefault(msg, 7, ""),
    addressesList: jspb.Message.toObjectList(msg.getAddressesList(),
    domain_pb.Address.toObject, includeInstance),
    imagesList: jspb.Message.toObjectList(msg.getImagesList(),
    domain_pb.Image.toObject, includeInstance),
    licenseExpirationDate: jspb.Message.getFieldWithDefault(msg, 10, 0),
    dateOfBirth: jspb.Message.getFieldWithDefault(msg, 11, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.customer_support_service.CustomerInfo}
 */
proto.customer_support_service.CustomerInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.customer_support_service.CustomerInfo;
  return proto.customer_support_service.CustomerInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.customer_support_service.CustomerInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.customer_support_service.CustomerInfo}
 */
proto.customer_support_service.CustomerInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNumber(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLanguage(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 8:
      var value = new domain_pb.Address;
      reader.readMessage(value,domain_pb.Address.deserializeBinaryFromReader);
      msg.addAddresses(value);
      break;
    case 9:
      var value = new domain_pb.Image;
      reader.readMessage(value,domain_pb.Image.deserializeBinaryFromReader);
      msg.addImages(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLicenseExpirationDate(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDateOfBirth(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.customer_support_service.CustomerInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.customer_support_service.CustomerInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.customer_support_service.CustomerInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer_support_service.CustomerInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccountNumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLanguage();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getAddressesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      domain_pb.Address.serializeBinaryToWriter
    );
  }
  f = message.getImagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      domain_pb.Image.serializeBinaryToWriter
    );
  }
  f = message.getLicenseExpirationDate();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getDateOfBirth();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.customer_support_service.CustomerInfo.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer_support_service.CustomerInfo} returns this
 */
proto.customer_support_service.CustomerInfo.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string account_number = 2;
 * @return {string}
 */
proto.customer_support_service.CustomerInfo.prototype.getAccountNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer_support_service.CustomerInfo} returns this
 */
proto.customer_support_service.CustomerInfo.prototype.setAccountNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string first_name = 3;
 * @return {string}
 */
proto.customer_support_service.CustomerInfo.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer_support_service.CustomerInfo} returns this
 */
proto.customer_support_service.CustomerInfo.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string last_name = 4;
 * @return {string}
 */
proto.customer_support_service.CustomerInfo.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer_support_service.CustomerInfo} returns this
 */
proto.customer_support_service.CustomerInfo.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string language = 5;
 * @return {string}
 */
proto.customer_support_service.CustomerInfo.prototype.getLanguage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer_support_service.CustomerInfo} returns this
 */
proto.customer_support_service.CustomerInfo.prototype.setLanguage = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string phone = 6;
 * @return {string}
 */
proto.customer_support_service.CustomerInfo.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer_support_service.CustomerInfo} returns this
 */
proto.customer_support_service.CustomerInfo.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string email = 7;
 * @return {string}
 */
proto.customer_support_service.CustomerInfo.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer_support_service.CustomerInfo} returns this
 */
proto.customer_support_service.CustomerInfo.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * repeated domain.Address addresses = 8;
 * @return {!Array<!proto.domain.Address>}
 */
proto.customer_support_service.CustomerInfo.prototype.getAddressesList = function() {
  return /** @type{!Array<!proto.domain.Address>} */ (
    jspb.Message.getRepeatedWrapperField(this, domain_pb.Address, 8));
};


/**
 * @param {!Array<!proto.domain.Address>} value
 * @return {!proto.customer_support_service.CustomerInfo} returns this
*/
proto.customer_support_service.CustomerInfo.prototype.setAddressesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.domain.Address=} opt_value
 * @param {number=} opt_index
 * @return {!proto.domain.Address}
 */
proto.customer_support_service.CustomerInfo.prototype.addAddresses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.domain.Address, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.customer_support_service.CustomerInfo} returns this
 */
proto.customer_support_service.CustomerInfo.prototype.clearAddressesList = function() {
  return this.setAddressesList([]);
};


/**
 * repeated domain.Image images = 9;
 * @return {!Array<!proto.domain.Image>}
 */
proto.customer_support_service.CustomerInfo.prototype.getImagesList = function() {
  return /** @type{!Array<!proto.domain.Image>} */ (
    jspb.Message.getRepeatedWrapperField(this, domain_pb.Image, 9));
};


/**
 * @param {!Array<!proto.domain.Image>} value
 * @return {!proto.customer_support_service.CustomerInfo} returns this
*/
proto.customer_support_service.CustomerInfo.prototype.setImagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.domain.Image=} opt_value
 * @param {number=} opt_index
 * @return {!proto.domain.Image}
 */
proto.customer_support_service.CustomerInfo.prototype.addImages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.domain.Image, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.customer_support_service.CustomerInfo} returns this
 */
proto.customer_support_service.CustomerInfo.prototype.clearImagesList = function() {
  return this.setImagesList([]);
};


/**
 * optional int64 license_expiration_date = 10;
 * @return {number}
 */
proto.customer_support_service.CustomerInfo.prototype.getLicenseExpirationDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.customer_support_service.CustomerInfo} returns this
 */
proto.customer_support_service.CustomerInfo.prototype.setLicenseExpirationDate = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int64 date_of_birth = 11;
 * @return {number}
 */
proto.customer_support_service.CustomerInfo.prototype.getDateOfBirth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.customer_support_service.CustomerInfo} returns this
 */
proto.customer_support_service.CustomerInfo.prototype.setDateOfBirth = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.customer_support_service.UpdateCustomerInfoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.customer_support_service.UpdateCustomerInfoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.customer_support_service.UpdateCustomerInfoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer_support_service.UpdateCustomerInfoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && proto.customer_support_service.CustomerIdentity.toObject(includeInstance, f),
    phone: jspb.Message.getFieldWithDefault(msg, 2, ""),
    email: jspb.Message.getFieldWithDefault(msg, 3, ""),
    serviceAddress: (f = msg.getServiceAddress()) && domain_pb.Address.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.customer_support_service.UpdateCustomerInfoRequest}
 */
proto.customer_support_service.UpdateCustomerInfoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.customer_support_service.UpdateCustomerInfoRequest;
  return proto.customer_support_service.UpdateCustomerInfoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.customer_support_service.UpdateCustomerInfoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.customer_support_service.UpdateCustomerInfoRequest}
 */
proto.customer_support_service.UpdateCustomerInfoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.customer_support_service.CustomerIdentity;
      reader.readMessage(value,proto.customer_support_service.CustomerIdentity.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 4:
      var value = new domain_pb.Address;
      reader.readMessage(value,domain_pb.Address.deserializeBinaryFromReader);
      msg.setServiceAddress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.customer_support_service.UpdateCustomerInfoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.customer_support_service.UpdateCustomerInfoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.customer_support_service.UpdateCustomerInfoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer_support_service.UpdateCustomerInfoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.customer_support_service.CustomerIdentity.serializeBinaryToWriter
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getServiceAddress();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      domain_pb.Address.serializeBinaryToWriter
    );
  }
};


/**
 * optional CustomerIdentity id = 1;
 * @return {?proto.customer_support_service.CustomerIdentity}
 */
proto.customer_support_service.UpdateCustomerInfoRequest.prototype.getId = function() {
  return /** @type{?proto.customer_support_service.CustomerIdentity} */ (
    jspb.Message.getWrapperField(this, proto.customer_support_service.CustomerIdentity, 1));
};


/**
 * @param {?proto.customer_support_service.CustomerIdentity|undefined} value
 * @return {!proto.customer_support_service.UpdateCustomerInfoRequest} returns this
*/
proto.customer_support_service.UpdateCustomerInfoRequest.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.customer_support_service.UpdateCustomerInfoRequest} returns this
 */
proto.customer_support_service.UpdateCustomerInfoRequest.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.customer_support_service.UpdateCustomerInfoRequest.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string phone = 2;
 * @return {string}
 */
proto.customer_support_service.UpdateCustomerInfoRequest.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer_support_service.UpdateCustomerInfoRequest} returns this
 */
proto.customer_support_service.UpdateCustomerInfoRequest.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string email = 3;
 * @return {string}
 */
proto.customer_support_service.UpdateCustomerInfoRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer_support_service.UpdateCustomerInfoRequest} returns this
 */
proto.customer_support_service.UpdateCustomerInfoRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional domain.Address service_address = 4;
 * @return {?proto.domain.Address}
 */
proto.customer_support_service.UpdateCustomerInfoRequest.prototype.getServiceAddress = function() {
  return /** @type{?proto.domain.Address} */ (
    jspb.Message.getWrapperField(this, domain_pb.Address, 4));
};


/**
 * @param {?proto.domain.Address|undefined} value
 * @return {!proto.customer_support_service.UpdateCustomerInfoRequest} returns this
*/
proto.customer_support_service.UpdateCustomerInfoRequest.prototype.setServiceAddress = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.customer_support_service.UpdateCustomerInfoRequest} returns this
 */
proto.customer_support_service.UpdateCustomerInfoRequest.prototype.clearServiceAddress = function() {
  return this.setServiceAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.customer_support_service.UpdateCustomerInfoRequest.prototype.hasServiceAddress = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.customer_support_service.UpdateCustomerInfoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.customer_support_service.UpdateCustomerInfoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.customer_support_service.UpdateCustomerInfoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer_support_service.UpdateCustomerInfoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.customer_support_service.UpdateCustomerInfoResponse}
 */
proto.customer_support_service.UpdateCustomerInfoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.customer_support_service.UpdateCustomerInfoResponse;
  return proto.customer_support_service.UpdateCustomerInfoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.customer_support_service.UpdateCustomerInfoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.customer_support_service.UpdateCustomerInfoResponse}
 */
proto.customer_support_service.UpdateCustomerInfoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.customer_support_service.UpdateCustomerInfoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.customer_support_service.UpdateCustomerInfoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.customer_support_service.UpdateCustomerInfoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer_support_service.UpdateCustomerInfoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.customer_support_service.UpdateCustomerGarageAddressRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.customer_support_service.UpdateCustomerGarageAddressRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.customer_support_service.UpdateCustomerGarageAddressRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer_support_service.UpdateCustomerGarageAddressRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    garageAddress: (f = msg.getGarageAddress()) && domain_pb.Address.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.customer_support_service.UpdateCustomerGarageAddressRequest}
 */
proto.customer_support_service.UpdateCustomerGarageAddressRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.customer_support_service.UpdateCustomerGarageAddressRequest;
  return proto.customer_support_service.UpdateCustomerGarageAddressRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.customer_support_service.UpdateCustomerGarageAddressRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.customer_support_service.UpdateCustomerGarageAddressRequest}
 */
proto.customer_support_service.UpdateCustomerGarageAddressRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = new domain_pb.Address;
      reader.readMessage(value,domain_pb.Address.deserializeBinaryFromReader);
      msg.setGarageAddress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.customer_support_service.UpdateCustomerGarageAddressRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.customer_support_service.UpdateCustomerGarageAddressRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.customer_support_service.UpdateCustomerGarageAddressRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer_support_service.UpdateCustomerGarageAddressRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getGarageAddress();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      domain_pb.Address.serializeBinaryToWriter
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.customer_support_service.UpdateCustomerGarageAddressRequest.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer_support_service.UpdateCustomerGarageAddressRequest} returns this
 */
proto.customer_support_service.UpdateCustomerGarageAddressRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional domain.Address garage_address = 2;
 * @return {?proto.domain.Address}
 */
proto.customer_support_service.UpdateCustomerGarageAddressRequest.prototype.getGarageAddress = function() {
  return /** @type{?proto.domain.Address} */ (
    jspb.Message.getWrapperField(this, domain_pb.Address, 2));
};


/**
 * @param {?proto.domain.Address|undefined} value
 * @return {!proto.customer_support_service.UpdateCustomerGarageAddressRequest} returns this
*/
proto.customer_support_service.UpdateCustomerGarageAddressRequest.prototype.setGarageAddress = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.customer_support_service.UpdateCustomerGarageAddressRequest} returns this
 */
proto.customer_support_service.UpdateCustomerGarageAddressRequest.prototype.clearGarageAddress = function() {
  return this.setGarageAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.customer_support_service.UpdateCustomerGarageAddressRequest.prototype.hasGarageAddress = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.customer_support_service.UpdateCustomerGarageAddressResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.customer_support_service.UpdateCustomerGarageAddressResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.customer_support_service.UpdateCustomerGarageAddressResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer_support_service.UpdateCustomerGarageAddressResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.customer_support_service.UpdateCustomerGarageAddressResponse}
 */
proto.customer_support_service.UpdateCustomerGarageAddressResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.customer_support_service.UpdateCustomerGarageAddressResponse;
  return proto.customer_support_service.UpdateCustomerGarageAddressResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.customer_support_service.UpdateCustomerGarageAddressResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.customer_support_service.UpdateCustomerGarageAddressResponse}
 */
proto.customer_support_service.UpdateCustomerGarageAddressResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.customer_support_service.UpdateCustomerGarageAddressResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.customer_support_service.UpdateCustomerGarageAddressResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.customer_support_service.UpdateCustomerGarageAddressResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer_support_service.UpdateCustomerGarageAddressResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.customer_support_service.CustomerInsuranceInfo.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.customer_support_service.CustomerInsuranceInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.customer_support_service.CustomerInsuranceInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.customer_support_service.CustomerInsuranceInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer_support_service.CustomerInsuranceInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && proto.customer_support_service.CustomerIdentity.toObject(includeInstance, f),
    insurancePoliciesList: jspb.Message.toObjectList(msg.getInsurancePoliciesList(),
    domain_pb.InsurancePolicy.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.customer_support_service.CustomerInsuranceInfo}
 */
proto.customer_support_service.CustomerInsuranceInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.customer_support_service.CustomerInsuranceInfo;
  return proto.customer_support_service.CustomerInsuranceInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.customer_support_service.CustomerInsuranceInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.customer_support_service.CustomerInsuranceInfo}
 */
proto.customer_support_service.CustomerInsuranceInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.customer_support_service.CustomerIdentity;
      reader.readMessage(value,proto.customer_support_service.CustomerIdentity.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new domain_pb.InsurancePolicy;
      reader.readMessage(value,domain_pb.InsurancePolicy.deserializeBinaryFromReader);
      msg.addInsurancePolicies(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.customer_support_service.CustomerInsuranceInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.customer_support_service.CustomerInsuranceInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.customer_support_service.CustomerInsuranceInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer_support_service.CustomerInsuranceInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.customer_support_service.CustomerIdentity.serializeBinaryToWriter
    );
  }
  f = message.getInsurancePoliciesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      domain_pb.InsurancePolicy.serializeBinaryToWriter
    );
  }
};


/**
 * optional CustomerIdentity id = 1;
 * @return {?proto.customer_support_service.CustomerIdentity}
 */
proto.customer_support_service.CustomerInsuranceInfo.prototype.getId = function() {
  return /** @type{?proto.customer_support_service.CustomerIdentity} */ (
    jspb.Message.getWrapperField(this, proto.customer_support_service.CustomerIdentity, 1));
};


/**
 * @param {?proto.customer_support_service.CustomerIdentity|undefined} value
 * @return {!proto.customer_support_service.CustomerInsuranceInfo} returns this
*/
proto.customer_support_service.CustomerInsuranceInfo.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.customer_support_service.CustomerInsuranceInfo} returns this
 */
proto.customer_support_service.CustomerInsuranceInfo.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.customer_support_service.CustomerInsuranceInfo.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated domain.InsurancePolicy insurance_policies = 2;
 * @return {!Array<!proto.domain.InsurancePolicy>}
 */
proto.customer_support_service.CustomerInsuranceInfo.prototype.getInsurancePoliciesList = function() {
  return /** @type{!Array<!proto.domain.InsurancePolicy>} */ (
    jspb.Message.getRepeatedWrapperField(this, domain_pb.InsurancePolicy, 2));
};


/**
 * @param {!Array<!proto.domain.InsurancePolicy>} value
 * @return {!proto.customer_support_service.CustomerInsuranceInfo} returns this
*/
proto.customer_support_service.CustomerInsuranceInfo.prototype.setInsurancePoliciesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.domain.InsurancePolicy=} opt_value
 * @param {number=} opt_index
 * @return {!proto.domain.InsurancePolicy}
 */
proto.customer_support_service.CustomerInsuranceInfo.prototype.addInsurancePolicies = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.domain.InsurancePolicy, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.customer_support_service.CustomerInsuranceInfo} returns this
 */
proto.customer_support_service.CustomerInsuranceInfo.prototype.clearInsurancePoliciesList = function() {
  return this.setInsurancePoliciesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.customer_support_service.UpdateCustomerInsuranceInfoRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.customer_support_service.UpdateCustomerInsuranceInfoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.customer_support_service.UpdateCustomerInsuranceInfoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.customer_support_service.UpdateCustomerInsuranceInfoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer_support_service.UpdateCustomerInsuranceInfoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && proto.customer_support_service.CustomerIdentity.toObject(includeInstance, f),
    insurancePoliciesList: jspb.Message.toObjectList(msg.getInsurancePoliciesList(),
    domain_pb.InsurancePolicy.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.customer_support_service.UpdateCustomerInsuranceInfoRequest}
 */
proto.customer_support_service.UpdateCustomerInsuranceInfoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.customer_support_service.UpdateCustomerInsuranceInfoRequest;
  return proto.customer_support_service.UpdateCustomerInsuranceInfoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.customer_support_service.UpdateCustomerInsuranceInfoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.customer_support_service.UpdateCustomerInsuranceInfoRequest}
 */
proto.customer_support_service.UpdateCustomerInsuranceInfoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.customer_support_service.CustomerIdentity;
      reader.readMessage(value,proto.customer_support_service.CustomerIdentity.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new domain_pb.InsurancePolicy;
      reader.readMessage(value,domain_pb.InsurancePolicy.deserializeBinaryFromReader);
      msg.addInsurancePolicies(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.customer_support_service.UpdateCustomerInsuranceInfoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.customer_support_service.UpdateCustomerInsuranceInfoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.customer_support_service.UpdateCustomerInsuranceInfoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer_support_service.UpdateCustomerInsuranceInfoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.customer_support_service.CustomerIdentity.serializeBinaryToWriter
    );
  }
  f = message.getInsurancePoliciesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      domain_pb.InsurancePolicy.serializeBinaryToWriter
    );
  }
};


/**
 * optional CustomerIdentity id = 1;
 * @return {?proto.customer_support_service.CustomerIdentity}
 */
proto.customer_support_service.UpdateCustomerInsuranceInfoRequest.prototype.getId = function() {
  return /** @type{?proto.customer_support_service.CustomerIdentity} */ (
    jspb.Message.getWrapperField(this, proto.customer_support_service.CustomerIdentity, 1));
};


/**
 * @param {?proto.customer_support_service.CustomerIdentity|undefined} value
 * @return {!proto.customer_support_service.UpdateCustomerInsuranceInfoRequest} returns this
*/
proto.customer_support_service.UpdateCustomerInsuranceInfoRequest.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.customer_support_service.UpdateCustomerInsuranceInfoRequest} returns this
 */
proto.customer_support_service.UpdateCustomerInsuranceInfoRequest.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.customer_support_service.UpdateCustomerInsuranceInfoRequest.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated domain.InsurancePolicy insurance_policies = 2;
 * @return {!Array<!proto.domain.InsurancePolicy>}
 */
proto.customer_support_service.UpdateCustomerInsuranceInfoRequest.prototype.getInsurancePoliciesList = function() {
  return /** @type{!Array<!proto.domain.InsurancePolicy>} */ (
    jspb.Message.getRepeatedWrapperField(this, domain_pb.InsurancePolicy, 2));
};


/**
 * @param {!Array<!proto.domain.InsurancePolicy>} value
 * @return {!proto.customer_support_service.UpdateCustomerInsuranceInfoRequest} returns this
*/
proto.customer_support_service.UpdateCustomerInsuranceInfoRequest.prototype.setInsurancePoliciesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.domain.InsurancePolicy=} opt_value
 * @param {number=} opt_index
 * @return {!proto.domain.InsurancePolicy}
 */
proto.customer_support_service.UpdateCustomerInsuranceInfoRequest.prototype.addInsurancePolicies = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.domain.InsurancePolicy, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.customer_support_service.UpdateCustomerInsuranceInfoRequest} returns this
 */
proto.customer_support_service.UpdateCustomerInsuranceInfoRequest.prototype.clearInsurancePoliciesList = function() {
  return this.setInsurancePoliciesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.customer_support_service.CustomerSubscriptions.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.customer_support_service.CustomerSubscriptions.prototype.toObject = function(opt_includeInstance) {
  return proto.customer_support_service.CustomerSubscriptions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.customer_support_service.CustomerSubscriptions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer_support_service.CustomerSubscriptions.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && proto.customer_support_service.CustomerIdentity.toObject(includeInstance, f),
    subscriptionsList: jspb.Message.toObjectList(msg.getSubscriptionsList(),
    domain_pb.Subscription.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.customer_support_service.CustomerSubscriptions}
 */
proto.customer_support_service.CustomerSubscriptions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.customer_support_service.CustomerSubscriptions;
  return proto.customer_support_service.CustomerSubscriptions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.customer_support_service.CustomerSubscriptions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.customer_support_service.CustomerSubscriptions}
 */
proto.customer_support_service.CustomerSubscriptions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.customer_support_service.CustomerIdentity;
      reader.readMessage(value,proto.customer_support_service.CustomerIdentity.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new domain_pb.Subscription;
      reader.readMessage(value,domain_pb.Subscription.deserializeBinaryFromReader);
      msg.addSubscriptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.customer_support_service.CustomerSubscriptions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.customer_support_service.CustomerSubscriptions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.customer_support_service.CustomerSubscriptions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer_support_service.CustomerSubscriptions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.customer_support_service.CustomerIdentity.serializeBinaryToWriter
    );
  }
  f = message.getSubscriptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      domain_pb.Subscription.serializeBinaryToWriter
    );
  }
};


/**
 * optional CustomerIdentity id = 1;
 * @return {?proto.customer_support_service.CustomerIdentity}
 */
proto.customer_support_service.CustomerSubscriptions.prototype.getId = function() {
  return /** @type{?proto.customer_support_service.CustomerIdentity} */ (
    jspb.Message.getWrapperField(this, proto.customer_support_service.CustomerIdentity, 1));
};


/**
 * @param {?proto.customer_support_service.CustomerIdentity|undefined} value
 * @return {!proto.customer_support_service.CustomerSubscriptions} returns this
*/
proto.customer_support_service.CustomerSubscriptions.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.customer_support_service.CustomerSubscriptions} returns this
 */
proto.customer_support_service.CustomerSubscriptions.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.customer_support_service.CustomerSubscriptions.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated domain.Subscription subscriptions = 2;
 * @return {!Array<!proto.domain.Subscription>}
 */
proto.customer_support_service.CustomerSubscriptions.prototype.getSubscriptionsList = function() {
  return /** @type{!Array<!proto.domain.Subscription>} */ (
    jspb.Message.getRepeatedWrapperField(this, domain_pb.Subscription, 2));
};


/**
 * @param {!Array<!proto.domain.Subscription>} value
 * @return {!proto.customer_support_service.CustomerSubscriptions} returns this
*/
proto.customer_support_service.CustomerSubscriptions.prototype.setSubscriptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.domain.Subscription=} opt_value
 * @param {number=} opt_index
 * @return {!proto.domain.Subscription}
 */
proto.customer_support_service.CustomerSubscriptions.prototype.addSubscriptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.domain.Subscription, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.customer_support_service.CustomerSubscriptions} returns this
 */
proto.customer_support_service.CustomerSubscriptions.prototype.clearSubscriptionsList = function() {
  return this.setSubscriptionsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.customer_support_service.CustomerVehicles.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.customer_support_service.CustomerVehicles.prototype.toObject = function(opt_includeInstance) {
  return proto.customer_support_service.CustomerVehicles.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.customer_support_service.CustomerVehicles} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer_support_service.CustomerVehicles.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && proto.customer_support_service.CustomerIdentity.toObject(includeInstance, f),
    vehiclesList: jspb.Message.toObjectList(msg.getVehiclesList(),
    vehicle_pb.Vehicle.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.customer_support_service.CustomerVehicles}
 */
proto.customer_support_service.CustomerVehicles.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.customer_support_service.CustomerVehicles;
  return proto.customer_support_service.CustomerVehicles.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.customer_support_service.CustomerVehicles} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.customer_support_service.CustomerVehicles}
 */
proto.customer_support_service.CustomerVehicles.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.customer_support_service.CustomerIdentity;
      reader.readMessage(value,proto.customer_support_service.CustomerIdentity.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new vehicle_pb.Vehicle;
      reader.readMessage(value,vehicle_pb.Vehicle.deserializeBinaryFromReader);
      msg.addVehicles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.customer_support_service.CustomerVehicles.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.customer_support_service.CustomerVehicles.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.customer_support_service.CustomerVehicles} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer_support_service.CustomerVehicles.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.customer_support_service.CustomerIdentity.serializeBinaryToWriter
    );
  }
  f = message.getVehiclesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      vehicle_pb.Vehicle.serializeBinaryToWriter
    );
  }
};


/**
 * optional CustomerIdentity id = 1;
 * @return {?proto.customer_support_service.CustomerIdentity}
 */
proto.customer_support_service.CustomerVehicles.prototype.getId = function() {
  return /** @type{?proto.customer_support_service.CustomerIdentity} */ (
    jspb.Message.getWrapperField(this, proto.customer_support_service.CustomerIdentity, 1));
};


/**
 * @param {?proto.customer_support_service.CustomerIdentity|undefined} value
 * @return {!proto.customer_support_service.CustomerVehicles} returns this
*/
proto.customer_support_service.CustomerVehicles.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.customer_support_service.CustomerVehicles} returns this
 */
proto.customer_support_service.CustomerVehicles.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.customer_support_service.CustomerVehicles.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated vehicle.Vehicle vehicles = 2;
 * @return {!Array<!proto.vehicle.Vehicle>}
 */
proto.customer_support_service.CustomerVehicles.prototype.getVehiclesList = function() {
  return /** @type{!Array<!proto.vehicle.Vehicle>} */ (
    jspb.Message.getRepeatedWrapperField(this, vehicle_pb.Vehicle, 2));
};


/**
 * @param {!Array<!proto.vehicle.Vehicle>} value
 * @return {!proto.customer_support_service.CustomerVehicles} returns this
*/
proto.customer_support_service.CustomerVehicles.prototype.setVehiclesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.vehicle.Vehicle=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vehicle.Vehicle}
 */
proto.customer_support_service.CustomerVehicles.prototype.addVehicles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.vehicle.Vehicle, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.customer_support_service.CustomerVehicles} returns this
 */
proto.customer_support_service.CustomerVehicles.prototype.clearVehiclesList = function() {
  return this.setVehiclesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.customer_support_service.WipeTwilioFactorsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.customer_support_service.WipeTwilioFactorsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.customer_support_service.WipeTwilioFactorsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer_support_service.WipeTwilioFactorsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && proto.customer_support_service.CustomerIdentity.toObject(includeInstance, f),
    isSandbox: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.customer_support_service.WipeTwilioFactorsRequest}
 */
proto.customer_support_service.WipeTwilioFactorsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.customer_support_service.WipeTwilioFactorsRequest;
  return proto.customer_support_service.WipeTwilioFactorsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.customer_support_service.WipeTwilioFactorsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.customer_support_service.WipeTwilioFactorsRequest}
 */
proto.customer_support_service.WipeTwilioFactorsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.customer_support_service.CustomerIdentity;
      reader.readMessage(value,proto.customer_support_service.CustomerIdentity.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSandbox(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.customer_support_service.WipeTwilioFactorsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.customer_support_service.WipeTwilioFactorsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.customer_support_service.WipeTwilioFactorsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer_support_service.WipeTwilioFactorsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.customer_support_service.CustomerIdentity.serializeBinaryToWriter
    );
  }
  f = message.getIsSandbox();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional CustomerIdentity id = 1;
 * @return {?proto.customer_support_service.CustomerIdentity}
 */
proto.customer_support_service.WipeTwilioFactorsRequest.prototype.getId = function() {
  return /** @type{?proto.customer_support_service.CustomerIdentity} */ (
    jspb.Message.getWrapperField(this, proto.customer_support_service.CustomerIdentity, 1));
};


/**
 * @param {?proto.customer_support_service.CustomerIdentity|undefined} value
 * @return {!proto.customer_support_service.WipeTwilioFactorsRequest} returns this
*/
proto.customer_support_service.WipeTwilioFactorsRequest.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.customer_support_service.WipeTwilioFactorsRequest} returns this
 */
proto.customer_support_service.WipeTwilioFactorsRequest.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.customer_support_service.WipeTwilioFactorsRequest.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool is_sandbox = 2;
 * @return {boolean}
 */
proto.customer_support_service.WipeTwilioFactorsRequest.prototype.getIsSandbox = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.customer_support_service.WipeTwilioFactorsRequest} returns this
 */
proto.customer_support_service.WipeTwilioFactorsRequest.prototype.setIsSandbox = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.customer_support_service.WipeTwilioFactorsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.customer_support_service.WipeTwilioFactorsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.customer_support_service.WipeTwilioFactorsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer_support_service.WipeTwilioFactorsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.customer_support_service.WipeTwilioFactorsResponse}
 */
proto.customer_support_service.WipeTwilioFactorsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.customer_support_service.WipeTwilioFactorsResponse;
  return proto.customer_support_service.WipeTwilioFactorsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.customer_support_service.WipeTwilioFactorsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.customer_support_service.WipeTwilioFactorsResponse}
 */
proto.customer_support_service.WipeTwilioFactorsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.customer_support_service.WipeTwilioFactorsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.customer_support_service.WipeTwilioFactorsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.customer_support_service.WipeTwilioFactorsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer_support_service.WipeTwilioFactorsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


goog.object.extend(exports, proto.customer_support_service);
