// ! This are here and commented as well as in the .env file because changing the .env file requires clearing parcel cache and restarting dev server. Easier to swap here.

// const endpointBase = 'https://pinot.dev.nxcr.com'
// const endpointBase = 'https://pinot.qa.nxcr.com'
const endpointBase = process.env.FLEET_LISTING_URL
// const endpointBase = 'https://pinot.staging.nxcr.com'
// const endpointBase = 'https://pinot.staging.nxcr.com'


export const Env = {
  endpoints: {
    fleet: endpointBase,
    listing: endpointBase,
    fleetUpload: process.env.FLEET_URL,
    nexusBffService: endpointBase,
    // fleetUpload: `https://fleet-service.develop.nxcr.com`,
  },
}
