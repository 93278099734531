import React, { useState, useEffect } from 'react'
import {
  Box,
  Button,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
  List,
  ListItem,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { ProcessCustomerArgs, ProcessOptions } from '../../useReservation'
import { palette } from '../../../../../../global-styles/theme/palette'
import { getStartingDecision } from './useQualificationBlock'
import { Score } from '@nxcr-org/web-api-interface/lib/domain_pb'

interface RecordUpdateBlockProps {
  currentTier: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 10 | 11 | 12 | 13 | 14 | 15
  overrideQualification: (args: ProcessCustomerArgs) => Promise<unknown>
  overrideQualificationSuccess: boolean
  mostRecentNote: string
  setNote: (noteText: string) => void
  addNote: () => void
  note: string
  customer: object
  recalculateCustomerScores: () => void
  recalculateCustomerScoresSuccess: boolean
  isAdmin: boolean
  cqeScores: Score.AsObject[]
  disqualificationVendor: Score.AsObject
  overrideDisqualificationVendor: Score.AsObject | undefined
  setOverrideDisqualificationVendor: (vendor: Score.AsObject) => void
}

const getFeedbackMessage = (decision: ProcessOptions) => {
  if (decision === ProcessOptions.approved) {
    return (
      <Typography
        color={palette.text.success}
      >{`Qualification Result Updated Successfully: Approved ${new Date()}`}</Typography>
    )
  }

  if (decision === ProcessOptions.rejected) {
    return (
      <Typography
        color={palette.text.info}
      >{`Qualification Result Updated Successfully: Rejected ${new Date()}`}</Typography>
    )
  }

  return <div />
}

export const RecordUpdateBlock: React.FC<RecordUpdateBlockProps> = ({
  overrideQualification,
  overrideQualificationSuccess,
  currentTier,
  note,
  setNote,
  mostRecentNote,
  recalculateCustomerScores,
  isAdmin,
  addNote,
  cqeScores,
  disqualificationVendor,
  overrideDisqualificationVendor,
  setOverrideDisqualificationVendor,
}) => {
  const [decision, setDecision] = useState(getStartingDecision(currentTier))
  const [feedbackMessage, setFeedbackMessage] = useState(<div />)
  const showOverrideRejectionDropdown = decision === ProcessOptions.rejected

  useEffect(() => {
    if (disqualificationVendor) {
      setDecision(ProcessOptions.rejected)
      setOverrideDisqualificationVendor(disqualificationVendor)
    }
  }, [disqualificationVendor])

  return (
    <Grid container spacing={2} direction="column">
      <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: '10px' }}>
        <Box
          sx={{
            display: 'inline-flex',
            columnGap: 4,
            rowGap: 2,
            flexWrap: 'wrap',
            paddingLeft: '10px',
          }}
        >
          <Box sx={{ display: 'inline-flex', flexDirection: 'column' }}>
            <Typography variant="caption">Qualification Result</Typography>
            <Select
              value={decision}
              onChange={(evt) => {
                setFeedbackMessage(null)
                setDecision(evt.target.value as ProcessOptions)
                if (disqualificationVendor) {
                  setOverrideDisqualificationVendor(disqualificationVendor)
                }
              }}
              IconComponent={ExpandMoreIcon}
            >
              <MenuItem disabled={true} value={ProcessOptions.unchanged}>
                Select a Qualification Result
              </MenuItem>
              <MenuItem value={ProcessOptions.approved}>Approve</MenuItem>
              {/*@TODO: On reject, show an additional select with reject reasons and handle */}
              <MenuItem value={ProcessOptions.rejected}>Reject</MenuItem>
              {/*@TODO: discuss with UX. Is it really logical to have below option here?*/}
              <MenuItem value={ProcessOptions.recalculate}>Repeat CQE</MenuItem>
            </Select>
          </Box>
          {showOverrideRejectionDropdown && (
            <Box sx={{ display: 'inline-flex', flexDirection: 'column' }}>
              <Typography variant="caption">Rejection Used</Typography>
              <Select
                defaultValue={disqualificationVendor?.id}
                //@ts-ignore
                value={overrideDisqualificationVendor?.id}
                onChange={(evt) =>
                  setOverrideDisqualificationVendor(
                    cqeScores.find((score) => score.id === evt.target.value)
                  )
                }
                IconComponent={ExpandMoreIcon}
              >
                {cqeScores?.map((score) => {
                  return (
                    <MenuItem key={score.id} value={score.id}>
                      {score.vendorName}
                    </MenuItem>
                  )
                })}
              </Select>
            </Box>
          )}
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '60%' }}>
            <Typography variant="caption">Notes</Typography>
            <TextField
              value={note}
              onChange={(evt) => setNote(evt.target.value)}
              fullWidth
              placeholder="Add notes about this update"
              variant="outlined"
            />
          </Box>
        </Box>
        {showOverrideRejectionDropdown && (
          <Box>
            <Typography variant="caption">Reason Codes</Typography>
            {disqualificationVendor && !overrideDisqualificationVendor && (
              <List
                sx={{
                  listStyleType: 'disc',
                  pl: 2,
                  '& .MuiListItem-root': {
                    display: 'list-item',
                  },
                }}
              >
                {disqualificationVendor.disqualifiedReasonsList.map(
                  (reason, index) => {
                    return <ListItem key={index}>{reason}</ListItem>
                  }
                )}
              </List>
            )}
            {overrideDisqualificationVendor && (
              <List
                sx={{
                  listStyleType: 'disc',
                  pl: 2,
                  '& .MuiListItem-root': {
                    display: 'list-item',
                  },
                }}
              >
                {overrideDisqualificationVendor.disqualifiedReasonsList.map(
                  (reason, index) => {
                    return <ListItem key={index}>{reason}</ListItem>
                  }
                )}
              </List>
            )}
          </Box>
        )}
      </Box>
      <Grid item>{overrideQualificationSuccess && feedbackMessage}</Grid>
      <Grid item sx={{ display: 'flex', justifyContent: 'center' }}>
        <Box sx={{ display: 'inline-flex', flexDirection: 'column' }}>
          <Button
            disabled={!isAdmin || (!decision && note === mostRecentNote)}
            onClick={() => {
              if (
                decision === ProcessOptions.approved ||
                decision === ProcessOptions.rejected
              ) {
                overrideQualification({
                  note,
                  decision: decision,
                }).then(() => {
                  setFeedbackMessage(getFeedbackMessage(decision))
                  setDecision(ProcessOptions.unchanged)
                })
              } else if (decision === ProcessOptions.recalculate) {
                recalculateCustomerScores()
              }

              if (note) {
                addNote()
              }
            }}
            variant="contained"
            color="primary"
          >
            Update
          </Button>
        </Box>
      </Grid>
    </Grid>
  )
}
