// source: subscription_service.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var subscription_pb = require('./subscription_pb.js');
goog.object.extend(proto, subscription_pb);
var domain_pb = require('./domain_pb.js');
goog.object.extend(proto, domain_pb);
goog.exportSymbol('proto.subscription_service.ActivateRequest', null, global);
goog.exportSymbol('proto.subscription_service.ActivateResponse', null, global);
goog.exportSymbol('proto.subscription_service.AddVehicleAssignmentRequest', null, global);
goog.exportSymbol('proto.subscription_service.AddVehicleAssignmentResponse', null, global);
goog.exportSymbol('proto.subscription_service.AdminActivateRequest', null, global);
goog.exportSymbol('proto.subscription_service.CancelReservationRequest', null, global);
goog.exportSymbol('proto.subscription_service.CancelReservationResponse', null, global);
goog.exportSymbol('proto.subscription_service.ContractResponse', null, global);
goog.exportSymbol('proto.subscription_service.CreateWebReservationRequest', null, global);
goog.exportSymbol('proto.subscription_service.CreateWebReservationResponse', null, global);
goog.exportSymbol('proto.subscription_service.DatedPricingOption', null, global);
goog.exportSymbol('proto.subscription_service.FindCustomerByPhoneRequest', null, global);
goog.exportSymbol('proto.subscription_service.FindCustomerByPhoneResponse', null, global);
goog.exportSymbol('proto.subscription_service.GetActiveOrLatestSubscriptionRequest', null, global);
goog.exportSymbol('proto.subscription_service.GetContractRequest', null, global);
goog.exportSymbol('proto.subscription_service.GetOwnContractRequest', null, global);
goog.exportSymbol('proto.subscription_service.GetOwnPickupReceiptRequest', null, global);
goog.exportSymbol('proto.subscription_service.GetPickupReceiptRequest', null, global);
goog.exportSymbol('proto.subscription_service.GetProgramRequest', null, global);
goog.exportSymbol('proto.subscription_service.GetProgramResponse', null, global);
goog.exportSymbol('proto.subscription_service.GetReservationRequest', null, global);
goog.exportSymbol('proto.subscription_service.GetReservationResponse', null, global);
goog.exportSymbol('proto.subscription_service.GetSubscriptionRequest', null, global);
goog.exportSymbol('proto.subscription_service.GetSubscriptionResponse', null, global);
goog.exportSymbol('proto.subscription_service.ListProgramsRequest', null, global);
goog.exportSymbol('proto.subscription_service.ListProgramsResponse', null, global);
goog.exportSymbol('proto.subscription_service.ListReservationsRequest', null, global);
goog.exportSymbol('proto.subscription_service.ListReservationsResponse', null, global);
goog.exportSymbol('proto.subscription_service.PickupReceiptResponse', null, global);
goog.exportSymbol('proto.subscription_service.PreviewOwnContractRequest', null, global);
goog.exportSymbol('proto.subscription_service.PreviewOwnPickupReceiptRequest', null, global);
goog.exportSymbol('proto.subscription_service.RemoveVehicleAssignmentRequest', null, global);
goog.exportSymbol('proto.subscription_service.RemoveVehicleAssignmentResponse', null, global);
goog.exportSymbol('proto.subscription_service.ReservationListItem', null, global);
goog.exportSymbol('proto.subscription_service.SetReservationProgramRequest', null, global);
goog.exportSymbol('proto.subscription_service.SetReservationProgramResponse', null, global);
goog.exportSymbol('proto.subscription_service.SignContractRequest', null, global);
goog.exportSymbol('proto.subscription_service.SignContractResponse', null, global);
goog.exportSymbol('proto.subscription_service.UpdateReservationRequest', null, global);
goog.exportSymbol('proto.subscription_service.UpdateReservationRequest.ProgramOptionsCase', null, global);
goog.exportSymbol('proto.subscription_service.UpdateReservationResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.subscription_service.PreviewOwnContractRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.subscription_service.PreviewOwnContractRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.subscription_service.PreviewOwnContractRequest.displayName = 'proto.subscription_service.PreviewOwnContractRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.subscription_service.GetOwnContractRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.subscription_service.GetOwnContractRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.subscription_service.GetOwnContractRequest.displayName = 'proto.subscription_service.GetOwnContractRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.subscription_service.GetContractRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.subscription_service.GetContractRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.subscription_service.GetContractRequest.displayName = 'proto.subscription_service.GetContractRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.subscription_service.ContractResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.subscription_service.ContractResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.subscription_service.ContractResponse.displayName = 'proto.subscription_service.ContractResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.subscription_service.SignContractRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.subscription_service.SignContractRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.subscription_service.SignContractRequest.displayName = 'proto.subscription_service.SignContractRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.subscription_service.SignContractResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.subscription_service.SignContractResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.subscription_service.SignContractResponse.displayName = 'proto.subscription_service.SignContractResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.subscription_service.PreviewOwnPickupReceiptRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.subscription_service.PreviewOwnPickupReceiptRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.subscription_service.PreviewOwnPickupReceiptRequest.displayName = 'proto.subscription_service.PreviewOwnPickupReceiptRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.subscription_service.GetOwnPickupReceiptRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.subscription_service.GetOwnPickupReceiptRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.subscription_service.GetOwnPickupReceiptRequest.displayName = 'proto.subscription_service.GetOwnPickupReceiptRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.subscription_service.GetPickupReceiptRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.subscription_service.GetPickupReceiptRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.subscription_service.GetPickupReceiptRequest.displayName = 'proto.subscription_service.GetPickupReceiptRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.subscription_service.PickupReceiptResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.subscription_service.PickupReceiptResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.subscription_service.PickupReceiptResponse.displayName = 'proto.subscription_service.PickupReceiptResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.subscription_service.AdminActivateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.subscription_service.AdminActivateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.subscription_service.AdminActivateRequest.displayName = 'proto.subscription_service.AdminActivateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.subscription_service.ActivateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.subscription_service.ActivateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.subscription_service.ActivateRequest.displayName = 'proto.subscription_service.ActivateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.subscription_service.ActivateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.subscription_service.ActivateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.subscription_service.ActivateResponse.displayName = 'proto.subscription_service.ActivateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.subscription_service.CancelReservationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.subscription_service.CancelReservationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.subscription_service.CancelReservationRequest.displayName = 'proto.subscription_service.CancelReservationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.subscription_service.CancelReservationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.subscription_service.CancelReservationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.subscription_service.CancelReservationResponse.displayName = 'proto.subscription_service.CancelReservationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.subscription_service.CreateWebReservationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.subscription_service.CreateWebReservationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.subscription_service.CreateWebReservationRequest.displayName = 'proto.subscription_service.CreateWebReservationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.subscription_service.CreateWebReservationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.subscription_service.CreateWebReservationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.subscription_service.CreateWebReservationResponse.displayName = 'proto.subscription_service.CreateWebReservationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.subscription_service.ListReservationsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.subscription_service.ListReservationsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.subscription_service.ListReservationsRequest.displayName = 'proto.subscription_service.ListReservationsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.subscription_service.ReservationListItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.subscription_service.ReservationListItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.subscription_service.ReservationListItem.displayName = 'proto.subscription_service.ReservationListItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.subscription_service.ListReservationsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.subscription_service.ListReservationsResponse.repeatedFields_, null);
};
goog.inherits(proto.subscription_service.ListReservationsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.subscription_service.ListReservationsResponse.displayName = 'proto.subscription_service.ListReservationsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.subscription_service.GetReservationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.subscription_service.GetReservationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.subscription_service.GetReservationRequest.displayName = 'proto.subscription_service.GetReservationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.subscription_service.GetReservationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.subscription_service.GetReservationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.subscription_service.GetReservationResponse.displayName = 'proto.subscription_service.GetReservationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.subscription_service.ListProgramsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.subscription_service.ListProgramsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.subscription_service.ListProgramsRequest.displayName = 'proto.subscription_service.ListProgramsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.subscription_service.ListProgramsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.subscription_service.ListProgramsResponse.repeatedFields_, null);
};
goog.inherits(proto.subscription_service.ListProgramsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.subscription_service.ListProgramsResponse.displayName = 'proto.subscription_service.ListProgramsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.subscription_service.UpdateReservationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.subscription_service.UpdateReservationRequest.oneofGroups_);
};
goog.inherits(proto.subscription_service.UpdateReservationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.subscription_service.UpdateReservationRequest.displayName = 'proto.subscription_service.UpdateReservationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.subscription_service.UpdateReservationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.subscription_service.UpdateReservationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.subscription_service.UpdateReservationResponse.displayName = 'proto.subscription_service.UpdateReservationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.subscription_service.FindCustomerByPhoneRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.subscription_service.FindCustomerByPhoneRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.subscription_service.FindCustomerByPhoneRequest.displayName = 'proto.subscription_service.FindCustomerByPhoneRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.subscription_service.FindCustomerByPhoneResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.subscription_service.FindCustomerByPhoneResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.subscription_service.FindCustomerByPhoneResponse.displayName = 'proto.subscription_service.FindCustomerByPhoneResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.subscription_service.SetReservationProgramRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.subscription_service.SetReservationProgramRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.subscription_service.SetReservationProgramRequest.displayName = 'proto.subscription_service.SetReservationProgramRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.subscription_service.SetReservationProgramResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.subscription_service.SetReservationProgramResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.subscription_service.SetReservationProgramResponse.displayName = 'proto.subscription_service.SetReservationProgramResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.subscription_service.DatedPricingOption = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.subscription_service.DatedPricingOption.repeatedFields_, null);
};
goog.inherits(proto.subscription_service.DatedPricingOption, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.subscription_service.DatedPricingOption.displayName = 'proto.subscription_service.DatedPricingOption';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.subscription_service.GetProgramRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.subscription_service.GetProgramRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.subscription_service.GetProgramRequest.displayName = 'proto.subscription_service.GetProgramRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.subscription_service.GetProgramResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.subscription_service.GetProgramResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.subscription_service.GetProgramResponse.displayName = 'proto.subscription_service.GetProgramResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.subscription_service.GetSubscriptionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.subscription_service.GetSubscriptionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.subscription_service.GetSubscriptionRequest.displayName = 'proto.subscription_service.GetSubscriptionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.subscription_service.GetSubscriptionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.subscription_service.GetSubscriptionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.subscription_service.GetSubscriptionResponse.displayName = 'proto.subscription_service.GetSubscriptionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.subscription_service.GetActiveOrLatestSubscriptionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.subscription_service.GetActiveOrLatestSubscriptionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.subscription_service.GetActiveOrLatestSubscriptionRequest.displayName = 'proto.subscription_service.GetActiveOrLatestSubscriptionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.subscription_service.AddVehicleAssignmentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.subscription_service.AddVehicleAssignmentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.subscription_service.AddVehicleAssignmentRequest.displayName = 'proto.subscription_service.AddVehicleAssignmentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.subscription_service.AddVehicleAssignmentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.subscription_service.AddVehicleAssignmentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.subscription_service.AddVehicleAssignmentResponse.displayName = 'proto.subscription_service.AddVehicleAssignmentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.subscription_service.RemoveVehicleAssignmentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.subscription_service.RemoveVehicleAssignmentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.subscription_service.RemoveVehicleAssignmentRequest.displayName = 'proto.subscription_service.RemoveVehicleAssignmentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.subscription_service.RemoveVehicleAssignmentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.subscription_service.RemoveVehicleAssignmentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.subscription_service.RemoveVehicleAssignmentResponse.displayName = 'proto.subscription_service.RemoveVehicleAssignmentResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.subscription_service.PreviewOwnContractRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.subscription_service.PreviewOwnContractRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.subscription_service.PreviewOwnContractRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription_service.PreviewOwnContractRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.subscription_service.PreviewOwnContractRequest}
 */
proto.subscription_service.PreviewOwnContractRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.subscription_service.PreviewOwnContractRequest;
  return proto.subscription_service.PreviewOwnContractRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.subscription_service.PreviewOwnContractRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.subscription_service.PreviewOwnContractRequest}
 */
proto.subscription_service.PreviewOwnContractRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.subscription_service.PreviewOwnContractRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.subscription_service.PreviewOwnContractRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.subscription_service.PreviewOwnContractRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription_service.PreviewOwnContractRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string subscription_id = 1;
 * @return {string}
 */
proto.subscription_service.PreviewOwnContractRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription_service.PreviewOwnContractRequest} returns this
 */
proto.subscription_service.PreviewOwnContractRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.subscription_service.GetOwnContractRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.subscription_service.GetOwnContractRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.subscription_service.GetOwnContractRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription_service.GetOwnContractRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.subscription_service.GetOwnContractRequest}
 */
proto.subscription_service.GetOwnContractRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.subscription_service.GetOwnContractRequest;
  return proto.subscription_service.GetOwnContractRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.subscription_service.GetOwnContractRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.subscription_service.GetOwnContractRequest}
 */
proto.subscription_service.GetOwnContractRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.subscription_service.GetOwnContractRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.subscription_service.GetOwnContractRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.subscription_service.GetOwnContractRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription_service.GetOwnContractRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.subscription_service.GetContractRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.subscription_service.GetContractRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.subscription_service.GetContractRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription_service.GetContractRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.subscription_service.GetContractRequest}
 */
proto.subscription_service.GetContractRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.subscription_service.GetContractRequest;
  return proto.subscription_service.GetContractRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.subscription_service.GetContractRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.subscription_service.GetContractRequest}
 */
proto.subscription_service.GetContractRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.subscription_service.GetContractRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.subscription_service.GetContractRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.subscription_service.GetContractRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription_service.GetContractRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.subscription_service.GetContractRequest.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription_service.GetContractRequest} returns this
 */
proto.subscription_service.GetContractRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.subscription_service.ContractResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.subscription_service.ContractResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.subscription_service.ContractResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription_service.ContractResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    customerId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    properties: (f = msg.getProperties()) && subscription_pb.ContractProperties.toObject(includeInstance, f),
    signature: jspb.Message.getFieldWithDefault(msg, 4, ""),
    signedAt: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.subscription_service.ContractResponse}
 */
proto.subscription_service.ContractResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.subscription_service.ContractResponse;
  return proto.subscription_service.ContractResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.subscription_service.ContractResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.subscription_service.ContractResponse}
 */
proto.subscription_service.ContractResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 3:
      var value = new subscription_pb.ContractProperties;
      reader.readMessage(value,subscription_pb.ContractProperties.deserializeBinaryFromReader);
      msg.setProperties(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSignature(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSignedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.subscription_service.ContractResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.subscription_service.ContractResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.subscription_service.ContractResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription_service.ContractResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProperties();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      subscription_pb.ContractProperties.serializeBinaryToWriter
    );
  }
  f = message.getSignature();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSignedAt();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.subscription_service.ContractResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription_service.ContractResponse} returns this
 */
proto.subscription_service.ContractResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string customer_id = 2;
 * @return {string}
 */
proto.subscription_service.ContractResponse.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription_service.ContractResponse} returns this
 */
proto.subscription_service.ContractResponse.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional subscription.ContractProperties properties = 3;
 * @return {?proto.subscription.ContractProperties}
 */
proto.subscription_service.ContractResponse.prototype.getProperties = function() {
  return /** @type{?proto.subscription.ContractProperties} */ (
    jspb.Message.getWrapperField(this, subscription_pb.ContractProperties, 3));
};


/**
 * @param {?proto.subscription.ContractProperties|undefined} value
 * @return {!proto.subscription_service.ContractResponse} returns this
*/
proto.subscription_service.ContractResponse.prototype.setProperties = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.subscription_service.ContractResponse} returns this
 */
proto.subscription_service.ContractResponse.prototype.clearProperties = function() {
  return this.setProperties(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.subscription_service.ContractResponse.prototype.hasProperties = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string signature = 4;
 * @return {string}
 */
proto.subscription_service.ContractResponse.prototype.getSignature = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription_service.ContractResponse} returns this
 */
proto.subscription_service.ContractResponse.prototype.setSignature = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 signed_at = 5;
 * @return {number}
 */
proto.subscription_service.ContractResponse.prototype.getSignedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.subscription_service.ContractResponse} returns this
 */
proto.subscription_service.ContractResponse.prototype.setSignedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.subscription_service.SignContractRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.subscription_service.SignContractRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.subscription_service.SignContractRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription_service.SignContractRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    signatureImage: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.subscription_service.SignContractRequest}
 */
proto.subscription_service.SignContractRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.subscription_service.SignContractRequest;
  return proto.subscription_service.SignContractRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.subscription_service.SignContractRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.subscription_service.SignContractRequest}
 */
proto.subscription_service.SignContractRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSignatureImage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.subscription_service.SignContractRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.subscription_service.SignContractRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.subscription_service.SignContractRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription_service.SignContractRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSignatureImage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string subscription_id = 1;
 * @return {string}
 */
proto.subscription_service.SignContractRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription_service.SignContractRequest} returns this
 */
proto.subscription_service.SignContractRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string signature_image = 2;
 * @return {string}
 */
proto.subscription_service.SignContractRequest.prototype.getSignatureImage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription_service.SignContractRequest} returns this
 */
proto.subscription_service.SignContractRequest.prototype.setSignatureImage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.subscription_service.SignContractResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.subscription_service.SignContractResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.subscription_service.SignContractResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription_service.SignContractResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.subscription_service.SignContractResponse}
 */
proto.subscription_service.SignContractResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.subscription_service.SignContractResponse;
  return proto.subscription_service.SignContractResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.subscription_service.SignContractResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.subscription_service.SignContractResponse}
 */
proto.subscription_service.SignContractResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.subscription_service.SignContractResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.subscription_service.SignContractResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.subscription_service.SignContractResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription_service.SignContractResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.subscription_service.PreviewOwnPickupReceiptRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.subscription_service.PreviewOwnPickupReceiptRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.subscription_service.PreviewOwnPickupReceiptRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription_service.PreviewOwnPickupReceiptRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.subscription_service.PreviewOwnPickupReceiptRequest}
 */
proto.subscription_service.PreviewOwnPickupReceiptRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.subscription_service.PreviewOwnPickupReceiptRequest;
  return proto.subscription_service.PreviewOwnPickupReceiptRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.subscription_service.PreviewOwnPickupReceiptRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.subscription_service.PreviewOwnPickupReceiptRequest}
 */
proto.subscription_service.PreviewOwnPickupReceiptRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.subscription_service.PreviewOwnPickupReceiptRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.subscription_service.PreviewOwnPickupReceiptRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.subscription_service.PreviewOwnPickupReceiptRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription_service.PreviewOwnPickupReceiptRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string vehicle_id = 1;
 * @return {string}
 */
proto.subscription_service.PreviewOwnPickupReceiptRequest.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription_service.PreviewOwnPickupReceiptRequest} returns this
 */
proto.subscription_service.PreviewOwnPickupReceiptRequest.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string subscription_id = 2;
 * @return {string}
 */
proto.subscription_service.PreviewOwnPickupReceiptRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription_service.PreviewOwnPickupReceiptRequest} returns this
 */
proto.subscription_service.PreviewOwnPickupReceiptRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.subscription_service.GetOwnPickupReceiptRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.subscription_service.GetOwnPickupReceiptRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.subscription_service.GetOwnPickupReceiptRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription_service.GetOwnPickupReceiptRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.subscription_service.GetOwnPickupReceiptRequest}
 */
proto.subscription_service.GetOwnPickupReceiptRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.subscription_service.GetOwnPickupReceiptRequest;
  return proto.subscription_service.GetOwnPickupReceiptRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.subscription_service.GetOwnPickupReceiptRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.subscription_service.GetOwnPickupReceiptRequest}
 */
proto.subscription_service.GetOwnPickupReceiptRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.subscription_service.GetOwnPickupReceiptRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.subscription_service.GetOwnPickupReceiptRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.subscription_service.GetOwnPickupReceiptRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription_service.GetOwnPickupReceiptRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string vehicle_id = 1;
 * @return {string}
 */
proto.subscription_service.GetOwnPickupReceiptRequest.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription_service.GetOwnPickupReceiptRequest} returns this
 */
proto.subscription_service.GetOwnPickupReceiptRequest.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string subscription_id = 2;
 * @return {string}
 */
proto.subscription_service.GetOwnPickupReceiptRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription_service.GetOwnPickupReceiptRequest} returns this
 */
proto.subscription_service.GetOwnPickupReceiptRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.subscription_service.GetPickupReceiptRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.subscription_service.GetPickupReceiptRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.subscription_service.GetPickupReceiptRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription_service.GetPickupReceiptRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    vehicleId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.subscription_service.GetPickupReceiptRequest}
 */
proto.subscription_service.GetPickupReceiptRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.subscription_service.GetPickupReceiptRequest;
  return proto.subscription_service.GetPickupReceiptRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.subscription_service.GetPickupReceiptRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.subscription_service.GetPickupReceiptRequest}
 */
proto.subscription_service.GetPickupReceiptRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.subscription_service.GetPickupReceiptRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.subscription_service.GetPickupReceiptRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.subscription_service.GetPickupReceiptRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription_service.GetPickupReceiptRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.subscription_service.GetPickupReceiptRequest.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription_service.GetPickupReceiptRequest} returns this
 */
proto.subscription_service.GetPickupReceiptRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string vehicle_id = 2;
 * @return {string}
 */
proto.subscription_service.GetPickupReceiptRequest.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription_service.GetPickupReceiptRequest} returns this
 */
proto.subscription_service.GetPickupReceiptRequest.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string subscription_id = 3;
 * @return {string}
 */
proto.subscription_service.GetPickupReceiptRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription_service.GetPickupReceiptRequest} returns this
 */
proto.subscription_service.GetPickupReceiptRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.subscription_service.PickupReceiptResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.subscription_service.PickupReceiptResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.subscription_service.PickupReceiptResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription_service.PickupReceiptResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    vehicleId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    receipt: (f = msg.getReceipt()) && subscription_pb.VehiclePickupReceiptProperties.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.subscription_service.PickupReceiptResponse}
 */
proto.subscription_service.PickupReceiptResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.subscription_service.PickupReceiptResponse;
  return proto.subscription_service.PickupReceiptResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.subscription_service.PickupReceiptResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.subscription_service.PickupReceiptResponse}
 */
proto.subscription_service.PickupReceiptResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 3:
      var value = new subscription_pb.VehiclePickupReceiptProperties;
      reader.readMessage(value,subscription_pb.VehiclePickupReceiptProperties.deserializeBinaryFromReader);
      msg.setReceipt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.subscription_service.PickupReceiptResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.subscription_service.PickupReceiptResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.subscription_service.PickupReceiptResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription_service.PickupReceiptResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getReceipt();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      subscription_pb.VehiclePickupReceiptProperties.serializeBinaryToWriter
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.subscription_service.PickupReceiptResponse.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription_service.PickupReceiptResponse} returns this
 */
proto.subscription_service.PickupReceiptResponse.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string vehicle_id = 2;
 * @return {string}
 */
proto.subscription_service.PickupReceiptResponse.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription_service.PickupReceiptResponse} returns this
 */
proto.subscription_service.PickupReceiptResponse.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional subscription.VehiclePickupReceiptProperties receipt = 3;
 * @return {?proto.subscription.VehiclePickupReceiptProperties}
 */
proto.subscription_service.PickupReceiptResponse.prototype.getReceipt = function() {
  return /** @type{?proto.subscription.VehiclePickupReceiptProperties} */ (
    jspb.Message.getWrapperField(this, subscription_pb.VehiclePickupReceiptProperties, 3));
};


/**
 * @param {?proto.subscription.VehiclePickupReceiptProperties|undefined} value
 * @return {!proto.subscription_service.PickupReceiptResponse} returns this
*/
proto.subscription_service.PickupReceiptResponse.prototype.setReceipt = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.subscription_service.PickupReceiptResponse} returns this
 */
proto.subscription_service.PickupReceiptResponse.prototype.clearReceipt = function() {
  return this.setReceipt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.subscription_service.PickupReceiptResponse.prototype.hasReceipt = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.subscription_service.AdminActivateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.subscription_service.AdminActivateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.subscription_service.AdminActivateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription_service.AdminActivateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.subscription_service.AdminActivateRequest}
 */
proto.subscription_service.AdminActivateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.subscription_service.AdminActivateRequest;
  return proto.subscription_service.AdminActivateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.subscription_service.AdminActivateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.subscription_service.AdminActivateRequest}
 */
proto.subscription_service.AdminActivateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.subscription_service.AdminActivateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.subscription_service.AdminActivateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.subscription_service.AdminActivateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription_service.AdminActivateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.subscription_service.AdminActivateRequest.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription_service.AdminActivateRequest} returns this
 */
proto.subscription_service.AdminActivateRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string subscription_id = 2;
 * @return {string}
 */
proto.subscription_service.AdminActivateRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription_service.AdminActivateRequest} returns this
 */
proto.subscription_service.AdminActivateRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.subscription_service.ActivateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.subscription_service.ActivateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.subscription_service.ActivateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription_service.ActivateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.subscription_service.ActivateRequest}
 */
proto.subscription_service.ActivateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.subscription_service.ActivateRequest;
  return proto.subscription_service.ActivateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.subscription_service.ActivateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.subscription_service.ActivateRequest}
 */
proto.subscription_service.ActivateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.subscription_service.ActivateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.subscription_service.ActivateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.subscription_service.ActivateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription_service.ActivateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string subscription_id = 1;
 * @return {string}
 */
proto.subscription_service.ActivateRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription_service.ActivateRequest} returns this
 */
proto.subscription_service.ActivateRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.subscription_service.ActivateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.subscription_service.ActivateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.subscription_service.ActivateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription_service.ActivateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.subscription_service.ActivateResponse}
 */
proto.subscription_service.ActivateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.subscription_service.ActivateResponse;
  return proto.subscription_service.ActivateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.subscription_service.ActivateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.subscription_service.ActivateResponse}
 */
proto.subscription_service.ActivateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.subscription_service.ActivateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.subscription_service.ActivateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.subscription_service.ActivateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription_service.ActivateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.subscription_service.CancelReservationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.subscription_service.CancelReservationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.subscription_service.CancelReservationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription_service.CancelReservationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    customerId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    customerReason: jspb.Message.getFieldWithDefault(msg, 3, ""),
    userType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    reservationId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    actionReason: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.subscription_service.CancelReservationRequest}
 */
proto.subscription_service.CancelReservationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.subscription_service.CancelReservationRequest;
  return proto.subscription_service.CancelReservationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.subscription_service.CancelReservationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.subscription_service.CancelReservationRequest}
 */
proto.subscription_service.CancelReservationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerReason(value);
      break;
    case 4:
      var value = /** @type {!proto.domain.Subscription.SubscriptionAction.UserType} */ (reader.readEnum());
      msg.setUserType(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setReservationId(value);
      break;
    case 6:
      var value = /** @type {!proto.subscription.SubscriptionAction.ActionReason} */ (reader.readEnum());
      msg.setActionReason(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.subscription_service.CancelReservationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.subscription_service.CancelReservationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.subscription_service.CancelReservationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription_service.CancelReservationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCustomerReason();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUserType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getReservationId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getActionReason();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
};


/**
 * optional string subscription_id = 1;
 * @return {string}
 */
proto.subscription_service.CancelReservationRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription_service.CancelReservationRequest} returns this
 */
proto.subscription_service.CancelReservationRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string customer_id = 2;
 * @return {string}
 */
proto.subscription_service.CancelReservationRequest.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription_service.CancelReservationRequest} returns this
 */
proto.subscription_service.CancelReservationRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string customer_reason = 3;
 * @return {string}
 */
proto.subscription_service.CancelReservationRequest.prototype.getCustomerReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription_service.CancelReservationRequest} returns this
 */
proto.subscription_service.CancelReservationRequest.prototype.setCustomerReason = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional domain.Subscription.SubscriptionAction.UserType user_type = 4;
 * @return {!proto.domain.Subscription.SubscriptionAction.UserType}
 */
proto.subscription_service.CancelReservationRequest.prototype.getUserType = function() {
  return /** @type {!proto.domain.Subscription.SubscriptionAction.UserType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.domain.Subscription.SubscriptionAction.UserType} value
 * @return {!proto.subscription_service.CancelReservationRequest} returns this
 */
proto.subscription_service.CancelReservationRequest.prototype.setUserType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string reservation_id = 5;
 * @return {string}
 */
proto.subscription_service.CancelReservationRequest.prototype.getReservationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription_service.CancelReservationRequest} returns this
 */
proto.subscription_service.CancelReservationRequest.prototype.setReservationId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional subscription.SubscriptionAction.ActionReason action_reason = 6;
 * @return {!proto.subscription.SubscriptionAction.ActionReason}
 */
proto.subscription_service.CancelReservationRequest.prototype.getActionReason = function() {
  return /** @type {!proto.subscription.SubscriptionAction.ActionReason} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.subscription.SubscriptionAction.ActionReason} value
 * @return {!proto.subscription_service.CancelReservationRequest} returns this
 */
proto.subscription_service.CancelReservationRequest.prototype.setActionReason = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.subscription_service.CancelReservationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.subscription_service.CancelReservationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.subscription_service.CancelReservationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription_service.CancelReservationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    reservationId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    customerId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.subscription_service.CancelReservationResponse}
 */
proto.subscription_service.CancelReservationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.subscription_service.CancelReservationResponse;
  return proto.subscription_service.CancelReservationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.subscription_service.CancelReservationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.subscription_service.CancelReservationResponse}
 */
proto.subscription_service.CancelReservationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReservationId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.subscription_service.CancelReservationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.subscription_service.CancelReservationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.subscription_service.CancelReservationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription_service.CancelReservationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReservationId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string reservation_id = 1;
 * @return {string}
 */
proto.subscription_service.CancelReservationResponse.prototype.getReservationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription_service.CancelReservationResponse} returns this
 */
proto.subscription_service.CancelReservationResponse.prototype.setReservationId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string customer_id = 2;
 * @return {string}
 */
proto.subscription_service.CancelReservationResponse.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription_service.CancelReservationResponse} returns this
 */
proto.subscription_service.CancelReservationResponse.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.subscription_service.CreateWebReservationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.subscription_service.CreateWebReservationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.subscription_service.CreateWebReservationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription_service.CreateWebReservationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    programId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    startFee: jspb.Message.getFieldWithDefault(msg, 2, 0),
    monthlyRate: jspb.Message.getFieldWithDefault(msg, 3, 0),
    firstName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 6, ""),
    email: jspb.Message.getFieldWithDefault(msg, 7, ""),
    cardholderName: jspb.Message.getFieldWithDefault(msg, 8, ""),
    cardNumber: jspb.Message.getFieldWithDefault(msg, 9, ""),
    cardExpiration: jspb.Message.getFieldWithDefault(msg, 10, ""),
    cardValidation: jspb.Message.getFieldWithDefault(msg, 11, ""),
    billingPostal: jspb.Message.getFieldWithDefault(msg, 12, ""),
    trackingId: jspb.Message.getFieldWithDefault(msg, 13, ""),
    priceTermMonths: jspb.Message.getFieldWithDefault(msg, 14, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.subscription_service.CreateWebReservationRequest}
 */
proto.subscription_service.CreateWebReservationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.subscription_service.CreateWebReservationRequest;
  return proto.subscription_service.CreateWebReservationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.subscription_service.CreateWebReservationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.subscription_service.CreateWebReservationRequest}
 */
proto.subscription_service.CreateWebReservationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProgramId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStartFee(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMonthlyRate(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCardholderName(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setCardNumber(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setCardExpiration(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setCardValidation(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setBillingPostal(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrackingId(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPriceTermMonths(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.subscription_service.CreateWebReservationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.subscription_service.CreateWebReservationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.subscription_service.CreateWebReservationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription_service.CreateWebReservationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProgramId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStartFee();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getMonthlyRate();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCardholderName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getCardNumber();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getCardExpiration();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getCardValidation();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getBillingPostal();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getTrackingId();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getPriceTermMonths();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
};


/**
 * optional string program_id = 1;
 * @return {string}
 */
proto.subscription_service.CreateWebReservationRequest.prototype.getProgramId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription_service.CreateWebReservationRequest} returns this
 */
proto.subscription_service.CreateWebReservationRequest.prototype.setProgramId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 start_fee = 2;
 * @return {number}
 */
proto.subscription_service.CreateWebReservationRequest.prototype.getStartFee = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.subscription_service.CreateWebReservationRequest} returns this
 */
proto.subscription_service.CreateWebReservationRequest.prototype.setStartFee = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 monthly_rate = 3;
 * @return {number}
 */
proto.subscription_service.CreateWebReservationRequest.prototype.getMonthlyRate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.subscription_service.CreateWebReservationRequest} returns this
 */
proto.subscription_service.CreateWebReservationRequest.prototype.setMonthlyRate = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string first_name = 4;
 * @return {string}
 */
proto.subscription_service.CreateWebReservationRequest.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription_service.CreateWebReservationRequest} returns this
 */
proto.subscription_service.CreateWebReservationRequest.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string last_name = 5;
 * @return {string}
 */
proto.subscription_service.CreateWebReservationRequest.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription_service.CreateWebReservationRequest} returns this
 */
proto.subscription_service.CreateWebReservationRequest.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string phone = 6;
 * @return {string}
 */
proto.subscription_service.CreateWebReservationRequest.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription_service.CreateWebReservationRequest} returns this
 */
proto.subscription_service.CreateWebReservationRequest.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string email = 7;
 * @return {string}
 */
proto.subscription_service.CreateWebReservationRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription_service.CreateWebReservationRequest} returns this
 */
proto.subscription_service.CreateWebReservationRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string cardholder_name = 8;
 * @return {string}
 */
proto.subscription_service.CreateWebReservationRequest.prototype.getCardholderName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription_service.CreateWebReservationRequest} returns this
 */
proto.subscription_service.CreateWebReservationRequest.prototype.setCardholderName = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string card_number = 9;
 * @return {string}
 */
proto.subscription_service.CreateWebReservationRequest.prototype.getCardNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription_service.CreateWebReservationRequest} returns this
 */
proto.subscription_service.CreateWebReservationRequest.prototype.setCardNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string card_expiration = 10;
 * @return {string}
 */
proto.subscription_service.CreateWebReservationRequest.prototype.getCardExpiration = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription_service.CreateWebReservationRequest} returns this
 */
proto.subscription_service.CreateWebReservationRequest.prototype.setCardExpiration = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string card_validation = 11;
 * @return {string}
 */
proto.subscription_service.CreateWebReservationRequest.prototype.getCardValidation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription_service.CreateWebReservationRequest} returns this
 */
proto.subscription_service.CreateWebReservationRequest.prototype.setCardValidation = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string billing_postal = 12;
 * @return {string}
 */
proto.subscription_service.CreateWebReservationRequest.prototype.getBillingPostal = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription_service.CreateWebReservationRequest} returns this
 */
proto.subscription_service.CreateWebReservationRequest.prototype.setBillingPostal = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string tracking_id = 13;
 * @return {string}
 */
proto.subscription_service.CreateWebReservationRequest.prototype.getTrackingId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription_service.CreateWebReservationRequest} returns this
 */
proto.subscription_service.CreateWebReservationRequest.prototype.setTrackingId = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional int32 price_term_months = 14;
 * @return {number}
 */
proto.subscription_service.CreateWebReservationRequest.prototype.getPriceTermMonths = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.subscription_service.CreateWebReservationRequest} returns this
 */
proto.subscription_service.CreateWebReservationRequest.prototype.setPriceTermMonths = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.subscription_service.CreateWebReservationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.subscription_service.CreateWebReservationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.subscription_service.CreateWebReservationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription_service.CreateWebReservationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    reservationId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    reservationNumber: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.subscription_service.CreateWebReservationResponse}
 */
proto.subscription_service.CreateWebReservationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.subscription_service.CreateWebReservationResponse;
  return proto.subscription_service.CreateWebReservationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.subscription_service.CreateWebReservationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.subscription_service.CreateWebReservationResponse}
 */
proto.subscription_service.CreateWebReservationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setReservationId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setReservationNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.subscription_service.CreateWebReservationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.subscription_service.CreateWebReservationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.subscription_service.CreateWebReservationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription_service.CreateWebReservationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getReservationId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getReservationNumber();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.subscription_service.CreateWebReservationResponse.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription_service.CreateWebReservationResponse} returns this
 */
proto.subscription_service.CreateWebReservationResponse.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string reservation_id = 2;
 * @return {string}
 */
proto.subscription_service.CreateWebReservationResponse.prototype.getReservationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription_service.CreateWebReservationResponse} returns this
 */
proto.subscription_service.CreateWebReservationResponse.prototype.setReservationId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string reservation_number = 3;
 * @return {string}
 */
proto.subscription_service.CreateWebReservationResponse.prototype.getReservationNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription_service.CreateWebReservationResponse} returns this
 */
proto.subscription_service.CreateWebReservationResponse.prototype.setReservationNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.subscription_service.ListReservationsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.subscription_service.ListReservationsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.subscription_service.ListReservationsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription_service.ListReservationsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    programId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pageState: jspb.Message.getFieldWithDefault(msg, 2, ""),
    pageSize: jspb.Message.getFieldWithDefault(msg, 3, 0),
    query: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.subscription_service.ListReservationsRequest}
 */
proto.subscription_service.ListReservationsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.subscription_service.ListReservationsRequest;
  return proto.subscription_service.ListReservationsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.subscription_service.ListReservationsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.subscription_service.ListReservationsRequest}
 */
proto.subscription_service.ListReservationsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProgramId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageState(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuery(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.subscription_service.ListReservationsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.subscription_service.ListReservationsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.subscription_service.ListReservationsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription_service.ListReservationsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProgramId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPageState();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getQuery();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string program_id = 1;
 * @return {string}
 */
proto.subscription_service.ListReservationsRequest.prototype.getProgramId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription_service.ListReservationsRequest} returns this
 */
proto.subscription_service.ListReservationsRequest.prototype.setProgramId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string page_state = 2;
 * @return {string}
 */
proto.subscription_service.ListReservationsRequest.prototype.getPageState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription_service.ListReservationsRequest} returns this
 */
proto.subscription_service.ListReservationsRequest.prototype.setPageState = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 page_size = 3;
 * @return {number}
 */
proto.subscription_service.ListReservationsRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.subscription_service.ListReservationsRequest} returns this
 */
proto.subscription_service.ListReservationsRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string query = 4;
 * @return {string}
 */
proto.subscription_service.ListReservationsRequest.prototype.getQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription_service.ListReservationsRequest} returns this
 */
proto.subscription_service.ListReservationsRequest.prototype.setQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.subscription_service.ReservationListItem.prototype.toObject = function(opt_includeInstance) {
  return proto.subscription_service.ReservationListItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.subscription_service.ReservationListItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription_service.ReservationListItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    reservation: (f = msg.getReservation()) && subscription_pb.Reservation.toObject(includeInstance, f),
    customer: (f = msg.getCustomer()) && domain_pb.Customer.toObject(includeInstance, f),
    reservationId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    customerId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    firstName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    accountNumber: jspb.Message.getFieldWithDefault(msg, 7, ""),
    waitlistStart: jspb.Message.getFieldWithDefault(msg, 8, ""),
    vin: jspb.Message.getFieldWithDefault(msg, 9, ""),
    model: jspb.Message.getFieldWithDefault(msg, 10, ""),
    color: jspb.Message.getFieldWithDefault(msg, 11, ""),
    status: jspb.Message.getFieldWithDefault(msg, 12, ""),
    postal: jspb.Message.getFieldWithDefault(msg, 13, ""),
    make: jspb.Message.getFieldWithDefault(msg, 14, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.subscription_service.ReservationListItem}
 */
proto.subscription_service.ReservationListItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.subscription_service.ReservationListItem;
  return proto.subscription_service.ReservationListItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.subscription_service.ReservationListItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.subscription_service.ReservationListItem}
 */
proto.subscription_service.ReservationListItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new subscription_pb.Reservation;
      reader.readMessage(value,subscription_pb.Reservation.deserializeBinaryFromReader);
      msg.setReservation(value);
      break;
    case 2:
      var value = new domain_pb.Customer;
      reader.readMessage(value,domain_pb.Customer.deserializeBinaryFromReader);
      msg.setCustomer(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setReservationId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNumber(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setWaitlistStart(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setVin(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setModel(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setColor(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostal(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setMake(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.subscription_service.ReservationListItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.subscription_service.ReservationListItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.subscription_service.ReservationListItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription_service.ReservationListItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReservation();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      subscription_pb.Reservation.serializeBinaryToWriter
    );
  }
  f = message.getCustomer();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      domain_pb.Customer.serializeBinaryToWriter
    );
  }
  f = message.getReservationId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAccountNumber();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getWaitlistStart();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getVin();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getModel();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getColor();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getPostal();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getMake();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
};


/**
 * optional subscription.Reservation reservation = 1;
 * @return {?proto.subscription.Reservation}
 */
proto.subscription_service.ReservationListItem.prototype.getReservation = function() {
  return /** @type{?proto.subscription.Reservation} */ (
    jspb.Message.getWrapperField(this, subscription_pb.Reservation, 1));
};


/**
 * @param {?proto.subscription.Reservation|undefined} value
 * @return {!proto.subscription_service.ReservationListItem} returns this
*/
proto.subscription_service.ReservationListItem.prototype.setReservation = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.subscription_service.ReservationListItem} returns this
 */
proto.subscription_service.ReservationListItem.prototype.clearReservation = function() {
  return this.setReservation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.subscription_service.ReservationListItem.prototype.hasReservation = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional domain.Customer customer = 2;
 * @return {?proto.domain.Customer}
 */
proto.subscription_service.ReservationListItem.prototype.getCustomer = function() {
  return /** @type{?proto.domain.Customer} */ (
    jspb.Message.getWrapperField(this, domain_pb.Customer, 2));
};


/**
 * @param {?proto.domain.Customer|undefined} value
 * @return {!proto.subscription_service.ReservationListItem} returns this
*/
proto.subscription_service.ReservationListItem.prototype.setCustomer = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.subscription_service.ReservationListItem} returns this
 */
proto.subscription_service.ReservationListItem.prototype.clearCustomer = function() {
  return this.setCustomer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.subscription_service.ReservationListItem.prototype.hasCustomer = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string reservation_id = 3;
 * @return {string}
 */
proto.subscription_service.ReservationListItem.prototype.getReservationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription_service.ReservationListItem} returns this
 */
proto.subscription_service.ReservationListItem.prototype.setReservationId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string customer_id = 4;
 * @return {string}
 */
proto.subscription_service.ReservationListItem.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription_service.ReservationListItem} returns this
 */
proto.subscription_service.ReservationListItem.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string first_name = 5;
 * @return {string}
 */
proto.subscription_service.ReservationListItem.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription_service.ReservationListItem} returns this
 */
proto.subscription_service.ReservationListItem.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string last_name = 6;
 * @return {string}
 */
proto.subscription_service.ReservationListItem.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription_service.ReservationListItem} returns this
 */
proto.subscription_service.ReservationListItem.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string account_number = 7;
 * @return {string}
 */
proto.subscription_service.ReservationListItem.prototype.getAccountNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription_service.ReservationListItem} returns this
 */
proto.subscription_service.ReservationListItem.prototype.setAccountNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string waitlist_start = 8;
 * @return {string}
 */
proto.subscription_service.ReservationListItem.prototype.getWaitlistStart = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription_service.ReservationListItem} returns this
 */
proto.subscription_service.ReservationListItem.prototype.setWaitlistStart = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string vin = 9;
 * @return {string}
 */
proto.subscription_service.ReservationListItem.prototype.getVin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription_service.ReservationListItem} returns this
 */
proto.subscription_service.ReservationListItem.prototype.setVin = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string model = 10;
 * @return {string}
 */
proto.subscription_service.ReservationListItem.prototype.getModel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription_service.ReservationListItem} returns this
 */
proto.subscription_service.ReservationListItem.prototype.setModel = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string color = 11;
 * @return {string}
 */
proto.subscription_service.ReservationListItem.prototype.getColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription_service.ReservationListItem} returns this
 */
proto.subscription_service.ReservationListItem.prototype.setColor = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string status = 12;
 * @return {string}
 */
proto.subscription_service.ReservationListItem.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription_service.ReservationListItem} returns this
 */
proto.subscription_service.ReservationListItem.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string postal = 13;
 * @return {string}
 */
proto.subscription_service.ReservationListItem.prototype.getPostal = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription_service.ReservationListItem} returns this
 */
proto.subscription_service.ReservationListItem.prototype.setPostal = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string make = 14;
 * @return {string}
 */
proto.subscription_service.ReservationListItem.prototype.getMake = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription_service.ReservationListItem} returns this
 */
proto.subscription_service.ReservationListItem.prototype.setMake = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.subscription_service.ListReservationsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.subscription_service.ListReservationsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.subscription_service.ListReservationsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.subscription_service.ListReservationsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription_service.ListReservationsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    reservationsList: jspb.Message.toObjectList(msg.getReservationsList(),
    proto.subscription_service.ReservationListItem.toObject, includeInstance),
    pageState: jspb.Message.getFieldWithDefault(msg, 2, ""),
    total: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.subscription_service.ListReservationsResponse}
 */
proto.subscription_service.ListReservationsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.subscription_service.ListReservationsResponse;
  return proto.subscription_service.ListReservationsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.subscription_service.ListReservationsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.subscription_service.ListReservationsResponse}
 */
proto.subscription_service.ListReservationsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.subscription_service.ReservationListItem;
      reader.readMessage(value,proto.subscription_service.ReservationListItem.deserializeBinaryFromReader);
      msg.addReservations(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageState(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.subscription_service.ListReservationsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.subscription_service.ListReservationsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.subscription_service.ListReservationsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription_service.ListReservationsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReservationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.subscription_service.ReservationListItem.serializeBinaryToWriter
    );
  }
  f = message.getPageState();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * repeated ReservationListItem reservations = 1;
 * @return {!Array<!proto.subscription_service.ReservationListItem>}
 */
proto.subscription_service.ListReservationsResponse.prototype.getReservationsList = function() {
  return /** @type{!Array<!proto.subscription_service.ReservationListItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.subscription_service.ReservationListItem, 1));
};


/**
 * @param {!Array<!proto.subscription_service.ReservationListItem>} value
 * @return {!proto.subscription_service.ListReservationsResponse} returns this
*/
proto.subscription_service.ListReservationsResponse.prototype.setReservationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.subscription_service.ReservationListItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.subscription_service.ReservationListItem}
 */
proto.subscription_service.ListReservationsResponse.prototype.addReservations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.subscription_service.ReservationListItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.subscription_service.ListReservationsResponse} returns this
 */
proto.subscription_service.ListReservationsResponse.prototype.clearReservationsList = function() {
  return this.setReservationsList([]);
};


/**
 * optional string page_state = 2;
 * @return {string}
 */
proto.subscription_service.ListReservationsResponse.prototype.getPageState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription_service.ListReservationsResponse} returns this
 */
proto.subscription_service.ListReservationsResponse.prototype.setPageState = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 total = 3;
 * @return {number}
 */
proto.subscription_service.ListReservationsResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.subscription_service.ListReservationsResponse} returns this
 */
proto.subscription_service.ListReservationsResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.subscription_service.GetReservationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.subscription_service.GetReservationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.subscription_service.GetReservationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription_service.GetReservationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.subscription_service.GetReservationRequest}
 */
proto.subscription_service.GetReservationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.subscription_service.GetReservationRequest;
  return proto.subscription_service.GetReservationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.subscription_service.GetReservationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.subscription_service.GetReservationRequest}
 */
proto.subscription_service.GetReservationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.subscription_service.GetReservationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.subscription_service.GetReservationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.subscription_service.GetReservationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription_service.GetReservationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.subscription_service.GetReservationRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription_service.GetReservationRequest} returns this
 */
proto.subscription_service.GetReservationRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.subscription_service.GetReservationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.subscription_service.GetReservationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.subscription_service.GetReservationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription_service.GetReservationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    reservation: (f = msg.getReservation()) && subscription_pb.Reservation.toObject(includeInstance, f),
    customer: (f = msg.getCustomer()) && domain_pb.Customer.toObject(includeInstance, f),
    program: (f = msg.getProgram()) && subscription_pb.Program.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.subscription_service.GetReservationResponse}
 */
proto.subscription_service.GetReservationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.subscription_service.GetReservationResponse;
  return proto.subscription_service.GetReservationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.subscription_service.GetReservationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.subscription_service.GetReservationResponse}
 */
proto.subscription_service.GetReservationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new subscription_pb.Reservation;
      reader.readMessage(value,subscription_pb.Reservation.deserializeBinaryFromReader);
      msg.setReservation(value);
      break;
    case 2:
      var value = new domain_pb.Customer;
      reader.readMessage(value,domain_pb.Customer.deserializeBinaryFromReader);
      msg.setCustomer(value);
      break;
    case 3:
      var value = new subscription_pb.Program;
      reader.readMessage(value,subscription_pb.Program.deserializeBinaryFromReader);
      msg.setProgram(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.subscription_service.GetReservationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.subscription_service.GetReservationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.subscription_service.GetReservationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription_service.GetReservationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReservation();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      subscription_pb.Reservation.serializeBinaryToWriter
    );
  }
  f = message.getCustomer();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      domain_pb.Customer.serializeBinaryToWriter
    );
  }
  f = message.getProgram();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      subscription_pb.Program.serializeBinaryToWriter
    );
  }
};


/**
 * optional subscription.Reservation reservation = 1;
 * @return {?proto.subscription.Reservation}
 */
proto.subscription_service.GetReservationResponse.prototype.getReservation = function() {
  return /** @type{?proto.subscription.Reservation} */ (
    jspb.Message.getWrapperField(this, subscription_pb.Reservation, 1));
};


/**
 * @param {?proto.subscription.Reservation|undefined} value
 * @return {!proto.subscription_service.GetReservationResponse} returns this
*/
proto.subscription_service.GetReservationResponse.prototype.setReservation = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.subscription_service.GetReservationResponse} returns this
 */
proto.subscription_service.GetReservationResponse.prototype.clearReservation = function() {
  return this.setReservation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.subscription_service.GetReservationResponse.prototype.hasReservation = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional domain.Customer customer = 2;
 * @return {?proto.domain.Customer}
 */
proto.subscription_service.GetReservationResponse.prototype.getCustomer = function() {
  return /** @type{?proto.domain.Customer} */ (
    jspb.Message.getWrapperField(this, domain_pb.Customer, 2));
};


/**
 * @param {?proto.domain.Customer|undefined} value
 * @return {!proto.subscription_service.GetReservationResponse} returns this
*/
proto.subscription_service.GetReservationResponse.prototype.setCustomer = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.subscription_service.GetReservationResponse} returns this
 */
proto.subscription_service.GetReservationResponse.prototype.clearCustomer = function() {
  return this.setCustomer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.subscription_service.GetReservationResponse.prototype.hasCustomer = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional subscription.Program program = 3;
 * @return {?proto.subscription.Program}
 */
proto.subscription_service.GetReservationResponse.prototype.getProgram = function() {
  return /** @type{?proto.subscription.Program} */ (
    jspb.Message.getWrapperField(this, subscription_pb.Program, 3));
};


/**
 * @param {?proto.subscription.Program|undefined} value
 * @return {!proto.subscription_service.GetReservationResponse} returns this
*/
proto.subscription_service.GetReservationResponse.prototype.setProgram = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.subscription_service.GetReservationResponse} returns this
 */
proto.subscription_service.GetReservationResponse.prototype.clearProgram = function() {
  return this.setProgram(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.subscription_service.GetReservationResponse.prototype.hasProgram = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.subscription_service.ListProgramsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.subscription_service.ListProgramsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.subscription_service.ListProgramsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription_service.ListProgramsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.subscription_service.ListProgramsRequest}
 */
proto.subscription_service.ListProgramsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.subscription_service.ListProgramsRequest;
  return proto.subscription_service.ListProgramsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.subscription_service.ListProgramsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.subscription_service.ListProgramsRequest}
 */
proto.subscription_service.ListProgramsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.subscription_service.ListProgramsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.subscription_service.ListProgramsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.subscription_service.ListProgramsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription_service.ListProgramsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.subscription_service.ListProgramsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.subscription_service.ListProgramsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.subscription_service.ListProgramsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.subscription_service.ListProgramsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription_service.ListProgramsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    programsList: jspb.Message.toObjectList(msg.getProgramsList(),
    subscription_pb.Program.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.subscription_service.ListProgramsResponse}
 */
proto.subscription_service.ListProgramsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.subscription_service.ListProgramsResponse;
  return proto.subscription_service.ListProgramsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.subscription_service.ListProgramsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.subscription_service.ListProgramsResponse}
 */
proto.subscription_service.ListProgramsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new subscription_pb.Program;
      reader.readMessage(value,subscription_pb.Program.deserializeBinaryFromReader);
      msg.addPrograms(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.subscription_service.ListProgramsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.subscription_service.ListProgramsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.subscription_service.ListProgramsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription_service.ListProgramsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProgramsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      subscription_pb.Program.serializeBinaryToWriter
    );
  }
};


/**
 * repeated subscription.Program programs = 1;
 * @return {!Array<!proto.subscription.Program>}
 */
proto.subscription_service.ListProgramsResponse.prototype.getProgramsList = function() {
  return /** @type{!Array<!proto.subscription.Program>} */ (
    jspb.Message.getRepeatedWrapperField(this, subscription_pb.Program, 1));
};


/**
 * @param {!Array<!proto.subscription.Program>} value
 * @return {!proto.subscription_service.ListProgramsResponse} returns this
*/
proto.subscription_service.ListProgramsResponse.prototype.setProgramsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.subscription.Program=} opt_value
 * @param {number=} opt_index
 * @return {!proto.subscription.Program}
 */
proto.subscription_service.ListProgramsResponse.prototype.addPrograms = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.subscription.Program, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.subscription_service.ListProgramsResponse} returns this
 */
proto.subscription_service.ListProgramsResponse.prototype.clearProgramsList = function() {
  return this.setProgramsList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.subscription_service.UpdateReservationRequest.oneofGroups_ = [[2,3]];

/**
 * @enum {number}
 */
proto.subscription_service.UpdateReservationRequest.ProgramOptionsCase = {
  PROGRAM_OPTIONS_NOT_SET: 0,
  MODEL3_OPTIONS: 2,
  MODEL_Y_OPTIONS: 3
};

/**
 * @return {proto.subscription_service.UpdateReservationRequest.ProgramOptionsCase}
 */
proto.subscription_service.UpdateReservationRequest.prototype.getProgramOptionsCase = function() {
  return /** @type {proto.subscription_service.UpdateReservationRequest.ProgramOptionsCase} */(jspb.Message.computeOneofCase(this, proto.subscription_service.UpdateReservationRequest.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.subscription_service.UpdateReservationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.subscription_service.UpdateReservationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.subscription_service.UpdateReservationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription_service.UpdateReservationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    reservationId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    model3Options: (f = msg.getModel3Options()) && subscription_pb.TeslaModel3Options.toObject(includeInstance, f),
    modelYOptions: (f = msg.getModelYOptions()) && subscription_pb.TeslaModelYOptions.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 11, 0),
    programId: jspb.Message.getFieldWithDefault(msg, 12, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.subscription_service.UpdateReservationRequest}
 */
proto.subscription_service.UpdateReservationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.subscription_service.UpdateReservationRequest;
  return proto.subscription_service.UpdateReservationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.subscription_service.UpdateReservationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.subscription_service.UpdateReservationRequest}
 */
proto.subscription_service.UpdateReservationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReservationId(value);
      break;
    case 2:
      var value = new subscription_pb.TeslaModel3Options;
      reader.readMessage(value,subscription_pb.TeslaModel3Options.deserializeBinaryFromReader);
      msg.setModel3Options(value);
      break;
    case 3:
      var value = new subscription_pb.TeslaModelYOptions;
      reader.readMessage(value,subscription_pb.TeslaModelYOptions.deserializeBinaryFromReader);
      msg.setModelYOptions(value);
      break;
    case 11:
      var value = /** @type {!proto.subscription.ReservationStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setProgramId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.subscription_service.UpdateReservationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.subscription_service.UpdateReservationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.subscription_service.UpdateReservationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription_service.UpdateReservationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReservationId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getModel3Options();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      subscription_pb.TeslaModel3Options.serializeBinaryToWriter
    );
  }
  f = message.getModelYOptions();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      subscription_pb.TeslaModelYOptions.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
  f = message.getProgramId();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
};


/**
 * optional string reservation_id = 1;
 * @return {string}
 */
proto.subscription_service.UpdateReservationRequest.prototype.getReservationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription_service.UpdateReservationRequest} returns this
 */
proto.subscription_service.UpdateReservationRequest.prototype.setReservationId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional subscription.TeslaModel3Options model3_options = 2;
 * @return {?proto.subscription.TeslaModel3Options}
 */
proto.subscription_service.UpdateReservationRequest.prototype.getModel3Options = function() {
  return /** @type{?proto.subscription.TeslaModel3Options} */ (
    jspb.Message.getWrapperField(this, subscription_pb.TeslaModel3Options, 2));
};


/**
 * @param {?proto.subscription.TeslaModel3Options|undefined} value
 * @return {!proto.subscription_service.UpdateReservationRequest} returns this
*/
proto.subscription_service.UpdateReservationRequest.prototype.setModel3Options = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.subscription_service.UpdateReservationRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.subscription_service.UpdateReservationRequest} returns this
 */
proto.subscription_service.UpdateReservationRequest.prototype.clearModel3Options = function() {
  return this.setModel3Options(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.subscription_service.UpdateReservationRequest.prototype.hasModel3Options = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional subscription.TeslaModelYOptions model_y_options = 3;
 * @return {?proto.subscription.TeslaModelYOptions}
 */
proto.subscription_service.UpdateReservationRequest.prototype.getModelYOptions = function() {
  return /** @type{?proto.subscription.TeslaModelYOptions} */ (
    jspb.Message.getWrapperField(this, subscription_pb.TeslaModelYOptions, 3));
};


/**
 * @param {?proto.subscription.TeslaModelYOptions|undefined} value
 * @return {!proto.subscription_service.UpdateReservationRequest} returns this
*/
proto.subscription_service.UpdateReservationRequest.prototype.setModelYOptions = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.subscription_service.UpdateReservationRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.subscription_service.UpdateReservationRequest} returns this
 */
proto.subscription_service.UpdateReservationRequest.prototype.clearModelYOptions = function() {
  return this.setModelYOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.subscription_service.UpdateReservationRequest.prototype.hasModelYOptions = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional subscription.ReservationStatus status = 11;
 * @return {!proto.subscription.ReservationStatus}
 */
proto.subscription_service.UpdateReservationRequest.prototype.getStatus = function() {
  return /** @type {!proto.subscription.ReservationStatus} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.subscription.ReservationStatus} value
 * @return {!proto.subscription_service.UpdateReservationRequest} returns this
 */
proto.subscription_service.UpdateReservationRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};


/**
 * optional string program_id = 12;
 * @return {string}
 */
proto.subscription_service.UpdateReservationRequest.prototype.getProgramId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription_service.UpdateReservationRequest} returns this
 */
proto.subscription_service.UpdateReservationRequest.prototype.setProgramId = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.subscription_service.UpdateReservationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.subscription_service.UpdateReservationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.subscription_service.UpdateReservationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription_service.UpdateReservationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.subscription_service.UpdateReservationResponse}
 */
proto.subscription_service.UpdateReservationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.subscription_service.UpdateReservationResponse;
  return proto.subscription_service.UpdateReservationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.subscription_service.UpdateReservationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.subscription_service.UpdateReservationResponse}
 */
proto.subscription_service.UpdateReservationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.subscription_service.UpdateReservationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.subscription_service.UpdateReservationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.subscription_service.UpdateReservationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription_service.UpdateReservationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.subscription_service.FindCustomerByPhoneRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.subscription_service.FindCustomerByPhoneRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.subscription_service.FindCustomerByPhoneRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription_service.FindCustomerByPhoneRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    phone: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.subscription_service.FindCustomerByPhoneRequest}
 */
proto.subscription_service.FindCustomerByPhoneRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.subscription_service.FindCustomerByPhoneRequest;
  return proto.subscription_service.FindCustomerByPhoneRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.subscription_service.FindCustomerByPhoneRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.subscription_service.FindCustomerByPhoneRequest}
 */
proto.subscription_service.FindCustomerByPhoneRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.subscription_service.FindCustomerByPhoneRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.subscription_service.FindCustomerByPhoneRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.subscription_service.FindCustomerByPhoneRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription_service.FindCustomerByPhoneRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string phone = 1;
 * @return {string}
 */
proto.subscription_service.FindCustomerByPhoneRequest.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription_service.FindCustomerByPhoneRequest} returns this
 */
proto.subscription_service.FindCustomerByPhoneRequest.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.subscription_service.FindCustomerByPhoneResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.subscription_service.FindCustomerByPhoneResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.subscription_service.FindCustomerByPhoneResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription_service.FindCustomerByPhoneResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    customer: (f = msg.getCustomer()) && domain_pb.Customer.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.subscription_service.FindCustomerByPhoneResponse}
 */
proto.subscription_service.FindCustomerByPhoneResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.subscription_service.FindCustomerByPhoneResponse;
  return proto.subscription_service.FindCustomerByPhoneResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.subscription_service.FindCustomerByPhoneResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.subscription_service.FindCustomerByPhoneResponse}
 */
proto.subscription_service.FindCustomerByPhoneResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new domain_pb.Customer;
      reader.readMessage(value,domain_pb.Customer.deserializeBinaryFromReader);
      msg.setCustomer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.subscription_service.FindCustomerByPhoneResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.subscription_service.FindCustomerByPhoneResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.subscription_service.FindCustomerByPhoneResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription_service.FindCustomerByPhoneResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomer();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      domain_pb.Customer.serializeBinaryToWriter
    );
  }
};


/**
 * optional domain.Customer customer = 1;
 * @return {?proto.domain.Customer}
 */
proto.subscription_service.FindCustomerByPhoneResponse.prototype.getCustomer = function() {
  return /** @type{?proto.domain.Customer} */ (
    jspb.Message.getWrapperField(this, domain_pb.Customer, 1));
};


/**
 * @param {?proto.domain.Customer|undefined} value
 * @return {!proto.subscription_service.FindCustomerByPhoneResponse} returns this
*/
proto.subscription_service.FindCustomerByPhoneResponse.prototype.setCustomer = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.subscription_service.FindCustomerByPhoneResponse} returns this
 */
proto.subscription_service.FindCustomerByPhoneResponse.prototype.clearCustomer = function() {
  return this.setCustomer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.subscription_service.FindCustomerByPhoneResponse.prototype.hasCustomer = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.subscription_service.SetReservationProgramRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.subscription_service.SetReservationProgramRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.subscription_service.SetReservationProgramRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription_service.SetReservationProgramRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    reservationId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    programId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    monthly: jspb.Message.getFieldWithDefault(msg, 4, 0),
    startFee: jspb.Message.getFieldWithDefault(msg, 5, 0),
    priceTermMonths: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.subscription_service.SetReservationProgramRequest}
 */
proto.subscription_service.SetReservationProgramRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.subscription_service.SetReservationProgramRequest;
  return proto.subscription_service.SetReservationProgramRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.subscription_service.SetReservationProgramRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.subscription_service.SetReservationProgramRequest}
 */
proto.subscription_service.SetReservationProgramRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setReservationId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProgramId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMonthly(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStartFee(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPriceTermMonths(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.subscription_service.SetReservationProgramRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.subscription_service.SetReservationProgramRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.subscription_service.SetReservationProgramRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription_service.SetReservationProgramRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getReservationId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProgramId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMonthly();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getStartFee();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getPriceTermMonths();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.subscription_service.SetReservationProgramRequest.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription_service.SetReservationProgramRequest} returns this
 */
proto.subscription_service.SetReservationProgramRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string reservation_id = 2;
 * @return {string}
 */
proto.subscription_service.SetReservationProgramRequest.prototype.getReservationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription_service.SetReservationProgramRequest} returns this
 */
proto.subscription_service.SetReservationProgramRequest.prototype.setReservationId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string program_id = 3;
 * @return {string}
 */
proto.subscription_service.SetReservationProgramRequest.prototype.getProgramId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription_service.SetReservationProgramRequest} returns this
 */
proto.subscription_service.SetReservationProgramRequest.prototype.setProgramId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 monthly = 4;
 * @return {number}
 */
proto.subscription_service.SetReservationProgramRequest.prototype.getMonthly = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.subscription_service.SetReservationProgramRequest} returns this
 */
proto.subscription_service.SetReservationProgramRequest.prototype.setMonthly = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 start_fee = 5;
 * @return {number}
 */
proto.subscription_service.SetReservationProgramRequest.prototype.getStartFee = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.subscription_service.SetReservationProgramRequest} returns this
 */
proto.subscription_service.SetReservationProgramRequest.prototype.setStartFee = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 price_term_months = 6;
 * @return {number}
 */
proto.subscription_service.SetReservationProgramRequest.prototype.getPriceTermMonths = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.subscription_service.SetReservationProgramRequest} returns this
 */
proto.subscription_service.SetReservationProgramRequest.prototype.setPriceTermMonths = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.subscription_service.SetReservationProgramResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.subscription_service.SetReservationProgramResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.subscription_service.SetReservationProgramResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription_service.SetReservationProgramResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.subscription_service.SetReservationProgramResponse}
 */
proto.subscription_service.SetReservationProgramResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.subscription_service.SetReservationProgramResponse;
  return proto.subscription_service.SetReservationProgramResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.subscription_service.SetReservationProgramResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.subscription_service.SetReservationProgramResponse}
 */
proto.subscription_service.SetReservationProgramResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.subscription_service.SetReservationProgramResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.subscription_service.SetReservationProgramResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.subscription_service.SetReservationProgramResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription_service.SetReservationProgramResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.subscription_service.DatedPricingOption.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.subscription_service.DatedPricingOption.prototype.toObject = function(opt_includeInstance) {
  return proto.subscription_service.DatedPricingOption.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.subscription_service.DatedPricingOption} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription_service.DatedPricingOption.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 4, ""),
    pricingOptionsList: jspb.Message.toObjectList(msg.getPricingOptionsList(),
    subscription_pb.PricingOption.toObject, includeInstance),
    fromDate: jspb.Message.getFieldWithDefault(msg, 2, 0),
    toDate: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.subscription_service.DatedPricingOption}
 */
proto.subscription_service.DatedPricingOption.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.subscription_service.DatedPricingOption;
  return proto.subscription_service.DatedPricingOption.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.subscription_service.DatedPricingOption} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.subscription_service.DatedPricingOption}
 */
proto.subscription_service.DatedPricingOption.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 1:
      var value = new subscription_pb.PricingOption;
      reader.readMessage(value,subscription_pb.PricingOption.deserializeBinaryFromReader);
      msg.addPricingOptions(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFromDate(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setToDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.subscription_service.DatedPricingOption.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.subscription_service.DatedPricingOption.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.subscription_service.DatedPricingOption} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription_service.DatedPricingOption.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPricingOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      subscription_pb.PricingOption.serializeBinaryToWriter
    );
  }
  f = message.getFromDate();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getToDate();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional string id = 4;
 * @return {string}
 */
proto.subscription_service.DatedPricingOption.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription_service.DatedPricingOption} returns this
 */
proto.subscription_service.DatedPricingOption.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated subscription.PricingOption pricing_options = 1;
 * @return {!Array<!proto.subscription.PricingOption>}
 */
proto.subscription_service.DatedPricingOption.prototype.getPricingOptionsList = function() {
  return /** @type{!Array<!proto.subscription.PricingOption>} */ (
    jspb.Message.getRepeatedWrapperField(this, subscription_pb.PricingOption, 1));
};


/**
 * @param {!Array<!proto.subscription.PricingOption>} value
 * @return {!proto.subscription_service.DatedPricingOption} returns this
*/
proto.subscription_service.DatedPricingOption.prototype.setPricingOptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.subscription.PricingOption=} opt_value
 * @param {number=} opt_index
 * @return {!proto.subscription.PricingOption}
 */
proto.subscription_service.DatedPricingOption.prototype.addPricingOptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.subscription.PricingOption, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.subscription_service.DatedPricingOption} returns this
 */
proto.subscription_service.DatedPricingOption.prototype.clearPricingOptionsList = function() {
  return this.setPricingOptionsList([]);
};


/**
 * optional int64 from_date = 2;
 * @return {number}
 */
proto.subscription_service.DatedPricingOption.prototype.getFromDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.subscription_service.DatedPricingOption} returns this
 */
proto.subscription_service.DatedPricingOption.prototype.setFromDate = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 to_date = 3;
 * @return {number}
 */
proto.subscription_service.DatedPricingOption.prototype.getToDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.subscription_service.DatedPricingOption} returns this
 */
proto.subscription_service.DatedPricingOption.prototype.setToDate = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.subscription_service.GetProgramRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.subscription_service.GetProgramRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.subscription_service.GetProgramRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription_service.GetProgramRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.subscription_service.GetProgramRequest}
 */
proto.subscription_service.GetProgramRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.subscription_service.GetProgramRequest;
  return proto.subscription_service.GetProgramRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.subscription_service.GetProgramRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.subscription_service.GetProgramRequest}
 */
proto.subscription_service.GetProgramRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.subscription_service.GetProgramRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.subscription_service.GetProgramRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.subscription_service.GetProgramRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription_service.GetProgramRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.subscription_service.GetProgramRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription_service.GetProgramRequest} returns this
 */
proto.subscription_service.GetProgramRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.subscription_service.GetProgramResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.subscription_service.GetProgramResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.subscription_service.GetProgramResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription_service.GetProgramResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    program: (f = msg.getProgram()) && subscription_pb.Program.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.subscription_service.GetProgramResponse}
 */
proto.subscription_service.GetProgramResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.subscription_service.GetProgramResponse;
  return proto.subscription_service.GetProgramResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.subscription_service.GetProgramResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.subscription_service.GetProgramResponse}
 */
proto.subscription_service.GetProgramResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new subscription_pb.Program;
      reader.readMessage(value,subscription_pb.Program.deserializeBinaryFromReader);
      msg.setProgram(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.subscription_service.GetProgramResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.subscription_service.GetProgramResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.subscription_service.GetProgramResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription_service.GetProgramResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProgram();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      subscription_pb.Program.serializeBinaryToWriter
    );
  }
};


/**
 * optional subscription.Program program = 1;
 * @return {?proto.subscription.Program}
 */
proto.subscription_service.GetProgramResponse.prototype.getProgram = function() {
  return /** @type{?proto.subscription.Program} */ (
    jspb.Message.getWrapperField(this, subscription_pb.Program, 1));
};


/**
 * @param {?proto.subscription.Program|undefined} value
 * @return {!proto.subscription_service.GetProgramResponse} returns this
*/
proto.subscription_service.GetProgramResponse.prototype.setProgram = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.subscription_service.GetProgramResponse} returns this
 */
proto.subscription_service.GetProgramResponse.prototype.clearProgram = function() {
  return this.setProgram(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.subscription_service.GetProgramResponse.prototype.hasProgram = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.subscription_service.GetSubscriptionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.subscription_service.GetSubscriptionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.subscription_service.GetSubscriptionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription_service.GetSubscriptionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.subscription_service.GetSubscriptionRequest}
 */
proto.subscription_service.GetSubscriptionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.subscription_service.GetSubscriptionRequest;
  return proto.subscription_service.GetSubscriptionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.subscription_service.GetSubscriptionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.subscription_service.GetSubscriptionRequest}
 */
proto.subscription_service.GetSubscriptionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.subscription_service.GetSubscriptionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.subscription_service.GetSubscriptionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.subscription_service.GetSubscriptionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription_service.GetSubscriptionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.subscription_service.GetSubscriptionRequest.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription_service.GetSubscriptionRequest} returns this
 */
proto.subscription_service.GetSubscriptionRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string subscription_id = 2;
 * @return {string}
 */
proto.subscription_service.GetSubscriptionRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription_service.GetSubscriptionRequest} returns this
 */
proto.subscription_service.GetSubscriptionRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.subscription_service.GetSubscriptionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.subscription_service.GetSubscriptionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.subscription_service.GetSubscriptionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription_service.GetSubscriptionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscription: (f = msg.getSubscription()) && domain_pb.Subscription.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.subscription_service.GetSubscriptionResponse}
 */
proto.subscription_service.GetSubscriptionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.subscription_service.GetSubscriptionResponse;
  return proto.subscription_service.GetSubscriptionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.subscription_service.GetSubscriptionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.subscription_service.GetSubscriptionResponse}
 */
proto.subscription_service.GetSubscriptionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new domain_pb.Subscription;
      reader.readMessage(value,domain_pb.Subscription.deserializeBinaryFromReader);
      msg.setSubscription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.subscription_service.GetSubscriptionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.subscription_service.GetSubscriptionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.subscription_service.GetSubscriptionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription_service.GetSubscriptionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubscription();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      domain_pb.Subscription.serializeBinaryToWriter
    );
  }
};


/**
 * optional domain.Subscription subscription = 1;
 * @return {?proto.domain.Subscription}
 */
proto.subscription_service.GetSubscriptionResponse.prototype.getSubscription = function() {
  return /** @type{?proto.domain.Subscription} */ (
    jspb.Message.getWrapperField(this, domain_pb.Subscription, 1));
};


/**
 * @param {?proto.domain.Subscription|undefined} value
 * @return {!proto.subscription_service.GetSubscriptionResponse} returns this
*/
proto.subscription_service.GetSubscriptionResponse.prototype.setSubscription = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.subscription_service.GetSubscriptionResponse} returns this
 */
proto.subscription_service.GetSubscriptionResponse.prototype.clearSubscription = function() {
  return this.setSubscription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.subscription_service.GetSubscriptionResponse.prototype.hasSubscription = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.subscription_service.GetActiveOrLatestSubscriptionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.subscription_service.GetActiveOrLatestSubscriptionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.subscription_service.GetActiveOrLatestSubscriptionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription_service.GetActiveOrLatestSubscriptionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.subscription_service.GetActiveOrLatestSubscriptionRequest}
 */
proto.subscription_service.GetActiveOrLatestSubscriptionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.subscription_service.GetActiveOrLatestSubscriptionRequest;
  return proto.subscription_service.GetActiveOrLatestSubscriptionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.subscription_service.GetActiveOrLatestSubscriptionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.subscription_service.GetActiveOrLatestSubscriptionRequest}
 */
proto.subscription_service.GetActiveOrLatestSubscriptionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.subscription_service.GetActiveOrLatestSubscriptionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.subscription_service.GetActiveOrLatestSubscriptionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.subscription_service.GetActiveOrLatestSubscriptionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription_service.GetActiveOrLatestSubscriptionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.subscription_service.GetActiveOrLatestSubscriptionRequest.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription_service.GetActiveOrLatestSubscriptionRequest} returns this
 */
proto.subscription_service.GetActiveOrLatestSubscriptionRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.subscription_service.AddVehicleAssignmentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.subscription_service.AddVehicleAssignmentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.subscription_service.AddVehicleAssignmentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription_service.AddVehicleAssignmentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    vehicleId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.subscription_service.AddVehicleAssignmentRequest}
 */
proto.subscription_service.AddVehicleAssignmentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.subscription_service.AddVehicleAssignmentRequest;
  return proto.subscription_service.AddVehicleAssignmentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.subscription_service.AddVehicleAssignmentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.subscription_service.AddVehicleAssignmentRequest}
 */
proto.subscription_service.AddVehicleAssignmentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.subscription_service.AddVehicleAssignmentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.subscription_service.AddVehicleAssignmentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.subscription_service.AddVehicleAssignmentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription_service.AddVehicleAssignmentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string subscription_id = 1;
 * @return {string}
 */
proto.subscription_service.AddVehicleAssignmentRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription_service.AddVehicleAssignmentRequest} returns this
 */
proto.subscription_service.AddVehicleAssignmentRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string vehicle_id = 2;
 * @return {string}
 */
proto.subscription_service.AddVehicleAssignmentRequest.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription_service.AddVehicleAssignmentRequest} returns this
 */
proto.subscription_service.AddVehicleAssignmentRequest.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.subscription_service.AddVehicleAssignmentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.subscription_service.AddVehicleAssignmentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.subscription_service.AddVehicleAssignmentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription_service.AddVehicleAssignmentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.subscription_service.AddVehicleAssignmentResponse}
 */
proto.subscription_service.AddVehicleAssignmentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.subscription_service.AddVehicleAssignmentResponse;
  return proto.subscription_service.AddVehicleAssignmentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.subscription_service.AddVehicleAssignmentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.subscription_service.AddVehicleAssignmentResponse}
 */
proto.subscription_service.AddVehicleAssignmentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.subscription_service.AddVehicleAssignmentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.subscription_service.AddVehicleAssignmentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.subscription_service.AddVehicleAssignmentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription_service.AddVehicleAssignmentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.subscription_service.RemoveVehicleAssignmentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.subscription_service.RemoveVehicleAssignmentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.subscription_service.RemoveVehicleAssignmentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription_service.RemoveVehicleAssignmentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.subscription_service.RemoveVehicleAssignmentRequest}
 */
proto.subscription_service.RemoveVehicleAssignmentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.subscription_service.RemoveVehicleAssignmentRequest;
  return proto.subscription_service.RemoveVehicleAssignmentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.subscription_service.RemoveVehicleAssignmentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.subscription_service.RemoveVehicleAssignmentRequest}
 */
proto.subscription_service.RemoveVehicleAssignmentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.subscription_service.RemoveVehicleAssignmentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.subscription_service.RemoveVehicleAssignmentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.subscription_service.RemoveVehicleAssignmentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription_service.RemoveVehicleAssignmentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string subscription_id = 1;
 * @return {string}
 */
proto.subscription_service.RemoveVehicleAssignmentRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.subscription_service.RemoveVehicleAssignmentRequest} returns this
 */
proto.subscription_service.RemoveVehicleAssignmentRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.subscription_service.RemoveVehicleAssignmentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.subscription_service.RemoveVehicleAssignmentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.subscription_service.RemoveVehicleAssignmentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription_service.RemoveVehicleAssignmentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.subscription_service.RemoveVehicleAssignmentResponse}
 */
proto.subscription_service.RemoveVehicleAssignmentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.subscription_service.RemoveVehicleAssignmentResponse;
  return proto.subscription_service.RemoveVehicleAssignmentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.subscription_service.RemoveVehicleAssignmentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.subscription_service.RemoveVehicleAssignmentResponse}
 */
proto.subscription_service.RemoveVehicleAssignmentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.subscription_service.RemoveVehicleAssignmentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.subscription_service.RemoveVehicleAssignmentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.subscription_service.RemoveVehicleAssignmentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.subscription_service.RemoveVehicleAssignmentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


goog.object.extend(exports, proto.subscription_service);
