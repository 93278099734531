import { useQuery } from 'react-query'
import { NexusService } from '../global-APIs/nexus-service'
import {
  InvoiceType,
  InvoiceStatus,
  InvoiceFE,
  LineItemFE,
} from '@nxcr-org/web-api-interface/lib/billing_service_pb'
import { getLabelFromEnum } from '../../utils/formatEnum'
import { formatDate } from '../../utils/date-formatter'
import { formatMoney } from '../../utils/currency-formatter'

export function useCustomerInvoices(customerId: string) {
  const { data: customerInvoices, error: customerInvoicesError, refetch } = useQuery(
    ['/customerinvoices'],
    () =>
      NexusService.getCustomerInvoices(customerId).then((res) =>
        formatInvoices(res.invoicesList)
      ),
    {
      placeholderData: [],
      onError() {
        console.log('Error: ', customerInvoicesError)
      },
    }
  )

  return {
    formattedInvoices: customerInvoices,
    refetchInvoices: refetch,
  }
}

export function formatInvoices(invoices: InvoiceFE.AsObject[]): InvoiceUI[] {
  return invoices.map((invoice) => {
    return {
      id: invoice.id,
      // type: getInvoiceTypeFromEnum(invoice.type),
      documentNumber: invoice.documentNumber,
      date: formatDate(invoice.date),
      dueDate: formatDate(invoice.dueDate),
      amount: formatMoney(invoice.amount),
      taxAmount: formatMoney(invoice.taxAmount),
      subtotalAmount: formatMoney(invoice.amount - invoice.taxAmount),
      balance: formatMoney(invoice.balance),
      status: getInvoiceStatusFromEnum(invoice.status),
      lineItems: formatLineItems(invoice.lineItemsList),
    }
  })
}

export function getInvoiceTypeFromEnum(num: number): string {
  return getLabelFromEnum(InvoiceType, num)
}

export function getInvoiceStatusFromEnum(num: number): string {
  return getLabelFromEnum(InvoiceStatus, num)
}

export function formatLineItems(
  lineItems: LineItemFE.AsObject[]
): InvoiceLineItemUI[] {
  return lineItems.map((lineItem) => {
    return {
      id: lineItem.id,
      amount: formatMoney(lineItem.amount),
      balance: formatMoney(lineItem.balance),
      description: lineItem.description,
    }
  })
}

export type InvoiceUI = {
  id: string
  // type: string
  documentNumber: string
  date: string
  dueDate: string
  amount: string
  taxAmount: string
  subtotalAmount: string
  balance: string
  status: string
  lineItems: InvoiceLineItemUI[]
}

export type InvoiceLineItemUI = {
  id: string
  amount: string
  balance: string
  description: string
}
