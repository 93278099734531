const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})



/**
 * Divides the value by 100 to convert from cents into dollars and returns formatted currency.
 * 
 * 10000 -> $100.00
 * @param value 
 * @returns 
 */
export function formatCents(value: number){
  return currencyFormatter.format(value / 100)
}

export function formatMoney(amount: number): string {
  const amountInDollars = formatCents(amount)
  return `${amountInDollars}`
}