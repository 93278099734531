import { makeStyles } from 'tss-react/mui'
import { autonomyColor } from '../../global-styles/autonomyColor'

import createBreakpoints from '@mui/system/createTheme/createBreakpoints'
const breakpoints = createBreakpoints({})

// export const useAppointmentModalStyles = makeStyles()((theme) => {
//   return {
//     base: {
//       backgroundColor: theme.palette.background.paper,
//       padding: theme.spacing(6),
//       color: theme.palette.text.primary,
//       borderRadius: 8,
//       position: 'absolute',
//       top: '50%',
//       left: '50%',
//       width: '90%',
//       transform: 'translate(-50%, -50%)',
//     },
//     body: {
//       paddingBottom: 24,
//       borderBottom: `1px solid ${autonomyColor.mutedBlue}`,
//       justifyContent: 'space-between',
//     },
//     cancelBlock: {
//       display: 'flex',
//       justifyContent: 'center',
//       button: {
//         backgroundColor: autonomyColor.pink,
//         color: autonomyColor.mediumBlue,
//         border: 'none',
//         ':disabled': {
//           backgroundColor: autonomyColor.pink,
//           color: autonomyColor.mediumBlue,
//           opacity: .5,
//         }
//       },
//     },
//   }
// })

// export const useCancelAppointmentStyles = makeStyles()((theme) => {
//   return {
//     body: {
//       padding: 24,
//       marginTop: 25,
//       backgroundColor: autonomyColor.darkBlue,
//     },
//     sectionHeader: {
//       fontSize: 18,
//     },
//     cancelText: {
//       margin: '25px 0',
//     },
//     cancelBtn: {
//       marginRight: 15,
//     },
//     errorText: {
//       marginTop: 25,
//       color: theme.palette.text.error,
//     },
//     successText: {
//       marginTop: 25,
//       color: theme.palette.text.success,
//     }
//   }
// })

export const useTextAreaStyles = makeStyles()(() => {
  return {
    textArea: {
      backgroundColor: autonomyColor.mediumBlue,
      width: '100%',
      border: '1px solid ' + `${autonomyColor.mutedBlue}`,
      color: autonomyColor.white,
      borderRadius: 5,
      padding: '5px 10px',
      fontFamily: 'Helvetica Neue',
      fontSize: 15,

      '::placeholder': {
        color: autonomyColor.mutedBlue,
      }
    },
  }
})

export const useSearchbarStyles = makeStyles()(() => {
  return {
    root: {
      '&.MuiAutocomplete-root': {
        '& .MuiOutlinedInput-root': {
          borderRadius: 50,
          border: `1px solid ${autonomyColor.mutedBlue}`,
          [breakpoints.up('lg')]: {
            width: '60%',
          },
          '&.Mui-focused': {
            borderRadius: '50px !important',
            border: `1px solid ${autonomyColor.mutedBlue}`,
          },
          '& input::placeholder': {
            color: autonomyColor.white,
          },
          '& .MuiSvgIcon-root': {
            color: autonomyColor.white,
          },
          '& .MuiAutocomplete-endAdornment': {
            display: 'none',
          },
        },
      },
    },
    popper: {
      color: autonomyColor.white,
      borderRadius: '0px 0px 8px 8px',
      border: `1px solid ${autonomyColor.mutedBlue}`,
      backgroundColor: autonomyColor.darkBlue,
      borderTop: 'none',
    },
    option: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      color: autonomyColor.white,
      margin: '25px 0',
      cursor: 'pointer',
    },
  }
})
