import { useState } from 'react'
import { useQuery } from 'react-query'
import { NexusService } from 'global-apis/nexus-service'
//import { useFlags } from 'launchdarkly-react-client-sdk'
import {
  GetBillingStatusResponse,
} from '@nxcr-org/web-api-interface/lib/billing_service_pb'
import { Subscription } from '@nxcr-org/web-api-interface/lib/domain_pb'

interface CheckboxProps {
  label: string
  active: boolean
}

export function useActivationStatus({
  isQualified,
  subscriptionId,
}: {
  isQualified: boolean
  customerId: string
  subscriptionId: string
}) {


  const [billingStatusError, setBillingStatusError] = useState(null)
  const [subscriptionStatusError, setSubscriptionStatusError] = useState(null)
  const statuses = getSubscriptionStatuses(subscriptionId, isQualified)
  const statusCol2 = getStatusCol2(subscriptionId)

  function getStatusCol2(subscriptionId: string) {
    const { isLoading, data } = useQuery(
      ['/billingStatus', {  subscriptionId }],
      () => {
        return NexusService.getBillingStatus(subscriptionId)
          .catch((err) => {
            console.error('getBillingStatus error', err)
            setBillingStatusError(err)
            return new GetBillingStatusResponse().toObject()
          }).then((billingStatusResult) => {
            return billingStatusResult
          })
      },
      {
        enabled: !!subscriptionId,
        placeholderData: new GetBillingStatusResponse().toObject(),
      }
    )
    if (billingStatusError) return getPlaceholderCheckbox('Error Loading Statuses')

    if (isLoading) return getPlaceholderCheckbox('Loading...')

    return [
      {
        label: 'Reservation Fee Paid',
        active: data.reservationFeePaid,
      },
      {
        label: 'Drive Off Paid',
        active: data.driveOffPaid,
      },
      {
        label: 'Signed Contract',
        active: data.signedContract,
      }
    ]
  }

  function getSubscriptionStatuses(subscriptionId: string, isQualified: boolean) {
    const { isLoading, data } = useQuery(
      ['/subscription', {  subscriptionId }],
      () => {
        return NexusService.getSubscriptionById(subscriptionId)
          .catch((err) => {
            console.error('getSubscriptionById error', err)
            setSubscriptionStatusError(err)
            return new Subscription().toObject()
          }).then((subscriptionResult) => {
            return subscriptionResult
          })
      },
      {
        enabled: !!subscriptionId,
        placeholderData: new Subscription().toObject(),
      }
    )

    if (subscriptionStatusError) return getPlaceholderCheckbox('Error Loading Statuses')
    if (isLoading) return getPlaceholderCheckbox('Loading...')

    return [
      {
        label: 'Qualified',
        active: isQualified,
      },
      {
        label: 'Vehicle Assigned',
        active: !!(typeof data.vehicleId !== 'undefined' && data.vehicleId),
      },
      {
        label: 'Activated',
        active: data.status == 'ACTIVE',
      },
    ]
  }

  function getPlaceholderCheckbox(label: string): CheckboxProps[] {
    return [
      {
        label: label,
        active: false,
      },
    ]
  }

  return {
    statuses,
    statusCol2,
    billingStatusError,
    subscriptionStatusError,
  }
}
