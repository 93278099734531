import { makeStyles } from 'tss-react/mui'
import { autonomyColor } from '../../../../../../global-styles/autonomyColor'

export const useChangeProgramDialogStyles = makeStyles()((theme) => {
  return {
    base: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(6),
      color: theme.palette.text.primary,
      borderRadius: 8,
      position: 'absolute',
      top: '50%',
      left: '50%',
      width: '75%',
      transform: 'translate(-50%, -50%)',
    },
    body: {
      marginTop: 24,
      padding: 24,
      alignContent: 'center',
      justifyContent: 'space-between',
      borderRadius: 10,
      backgroundColor: autonomyColor.darkBlue,
      marginBottom: 20,
    },
    programSection: {
      display: 'flex',
      flexDirection: 'column',
    },
    disclaimer: {
      fontSize: 10,
      color: autonomyColor.lightGray,
      margin: '25px 0',
    },
  }
})
