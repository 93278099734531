import {
  AppointmentType,
  AppointmentTypeConfig,
  AppointmentTypeMap
} from '@nxcr-org/web-api-interface/lib/appointment_pb'
import {
  Dealership,
  DealershipUser
} from '@nxcr-org/web-api-interface/lib/dealer_pb'
import {getKeyByValue, keyToReadable} from 'scheduling/tasks/utils'

export function getFormattedAppointmentTypeConfigs(
  appointmentTypeConfigsList: AppointmentTypeConfig.AsObject[]
): IAppointmentTypeConfig[]{

  return appointmentTypeConfigsList.map(({
    id,
    type,
    durationMinutes,
    countPerSlot
  }) => {
    return {
      id: id,
      type: type,
      typeLabel: keyToReadable(getKeyByValue(AppointmentType, type)),
      durationMinutes: durationMinutes,
      countPerSlot: countPerSlot
    }
  })
}

export function getFormattedDealershipUsers(
  dealershipUsers: DealershipUser.AsObject[]
): IDealershipUser[] {

  return dealershipUsers.map(({
    id,
    dealershipId,
    firstName,
    lastName,
    cellPhone,
    ...dealershipUsers
  }) => {
    return {
      id: id,
      dealershipId: dealershipId,
      name: `${firstName} ${lastName}`,
      cell: cellPhone,
      ...dealershipUsers
    }
  })
}

export function getFormattedDealershipList(
  dealershipList: Dealership.AsObject[]
): IDealership[] {
  return dealershipList.map(({
    id,
    name,
    ...dealershipList
  }) => {
    return {
      id: id,
      location: name,
      name: name,
      ...dealershipList
    }
  })
}

/*
* Format appointmentTypeConfigs to BE usable
* */
export function formatAppointmentTypeConfigs(
  appointmentTypeConfigsList: IAppointmentTypeConfig[]
): AppointmentTypeConfig[] {
  return appointmentTypeConfigsList.map(({
    id,
    type,
    countPerSlot,
    durationMinutes}) => {
    const appointmentTypeConfig = new AppointmentTypeConfig()
    appointmentTypeConfig.setId(id)
    appointmentTypeConfig.setType(type)
    appointmentTypeConfig.setCountPerSlot(countPerSlot)
    appointmentTypeConfig.setDurationMinutes(durationMinutes)

    return appointmentTypeConfig
  })
}

////////////////////////////////////
//////////// INTERFACES ////////////
///////////////////////////////////

export interface IAppointmentTypeConfig {
  id: string
  typeLabel: string
  type: AppointmentTypeMap[keyof AppointmentTypeMap]
  durationMinutes: number
  countPerSlot: number
}

export interface IDealershipUser {
  id: string
  dealershipId: string
  name: string
  cell: string
}

export interface IDealership {
  // dealership.address is currently undefined from be, but location should ultimately
  // be city and state so we can display that information nicely in the team details
  // and team members blocks
  id: string
  location: string
  name: string
}

export interface IDayOfWeek {
  dayOfWeek: string
  startTime: string
  endTime: string
  timezone: string
}

export interface IDate {
  id: string
  date: Date
  reason: string // is reason for ClosedDateWithReason or the description in IHoliday
}

export interface IHoliday {
  id?: string
  date: string
  reason: string
}
