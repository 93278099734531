import {useState} from 'react'
import {IAppointmentListItem} from '../../utils'

export const useAppointmentListItem = () => {
  const [selectedAppointment, setSelectedAppointment] = useState<IAppointmentListItem>()

  function handleClose() {
    setSelectedAppointment(undefined)
  }
  return {
    selectedAppointment,
    setSelectedAppointment,
    handleClose,
  }
}
