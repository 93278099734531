import { useEffect, useState } from 'react'
import { useOktaAuth } from '@okta/okta-react'
import { UserClaims } from '@okta/okta-auth-js/lib/types'
import {
  generateEventTimesOptions,
  getFormattedTimeZoneAbbreviation,
  IAppointmentListItem,
  keyToReadable,
} from '../../utils'
import { shouldDisableDate } from 'shared/AutonomyCalendar/useAutonomyCalendar'
import {
  AppointmentStatus,
  AppointmentType,
} from '@nxcr-org/web-api-interface/lib/appointment_pb'
import { NexusService } from 'global-apis/nexus-service'
import { useQuery } from 'react-query'
import { getFormattedDealershipUsers } from 'scheduling/settings/utils'
import { SchedulingService } from '../../../../global-APIs/nexus-service-scheduling'
import { handleDealerships } from '../../useTasks'

export const useAppointmentForm = (appointment: IAppointmentListItem) => {
  const { oktaAuth, authState } = useOktaAuth()
  const [userInfo, setUserInfo] = useState<UserClaims>(undefined)

  const [dealershipId, setDealershipId] = useState<string>()
  const [eventTimesOptions, setEventTimesOptions] = useState([])
  const [dealershipUserOptions, setDealershipUserOptions] = useState([])
  const [disableEventTime, setDisableEventTime] = useState(false)

  const { data: dealershipOptions } = useQuery(
    '/listDealerships',
    () => {
      return SchedulingService.listDealerships().then(handleDealerships)
    },
    {
      placeholderData: [],
    }
  )

  const { data: listDealershipUsers } = useQuery(
    ['/listDealershipUsers'],
    () => {
      return NexusService.listDealershipUsers(dealershipId).then((response) => {
        return getFormattedDealershipUsers(response.dealershipUsersList)
      })
    },
    {
      onSuccess: (data) => {
        setDealershipUserOptions(
          data.map((dealershipUser) => ({
            label: dealershipUser.name,
            value: dealershipUser.id,
          }))
        )
        console.log('dealershipUserList success', data)
      },
      enabled: !!dealershipId,
    }
  )

  /*
   * Minimum date is 'today', and the maximum selectable date
   * will be 21 days from the minDate, from 12:00 AM
   * */
  const minDate = new Date()
  minDate.setHours(0)
  minDate.setMinutes(0)
  minDate.setSeconds(0)
  minDate.setMilliseconds(0)

  useEffect(() => {
    if (authState && authState.isAuthenticated) {
      oktaAuth.getUser().then((user) => {
        if (user) {
          setUserInfo(user)
        }
      })
    }
  }, [oktaAuth, authState])

  useEffect(() => {
    setDealershipId(appointment.dealershipId)
  }, [appointment])

  useEffect(() => {
    setEventTimesOptions(generateEventTimesOptions(appointment.date.getTime()))

    const disable = shouldDisableDate(
      appointment.date,
      undefined,
      undefined,
      appointment.date,
      minDate
    )
    setDisableEventTime(disable)
  }, [appointment.date])

  const appointmentTypeOptions = Object.keys(AppointmentType).map(
    (appointmentType) => ({
      label: keyToReadable(appointmentType),
      value: AppointmentType[appointmentType],
    })
  )

  const appointmentStatusOptions = Object.keys(AppointmentStatus).map(
    (appointmentStatus) => ({
      label: keyToReadable(appointmentStatus),
      value: AppointmentStatus[appointmentStatus],
    })
  )

  const eventTimeLabel = `Event time ${getFormattedTimeZoneAbbreviation(
    appointment.timeZone
  )}`

  return {
    dealershipId,
    appointmentTypeOptions,
    appointmentStatusOptions,
    dealershipUserOptions,
    eventTimesOptions,
    eventTimeLabel,
    disableEventTime,
    minDate,
    userInfo,
    listDealershipUsers,
    setDisableEventTime,
    setDealershipId,
    dealershipOptions,
  }
}
