import {useState} from 'react'
import format from 'date-fns/format'

export interface AutonomyTimePickerProps {
  value: string
  disabled: boolean
  rangeStart: Date
  rangeEnd: Date
  interval?: number
  defaultTime: Date
  onChange: ( time: string ) => void
}

export const useAutonomyTimePicker = (
  value: string,
  defaultTime: Date,
  rangeStart: Date,
  rangeEnd: Date,
  interval?: number
) => {
  const [selectOption, setSelectedOption] = useState((() => value ? value : format(defaultTime, 'HH:mm'))())
  const timeOptions = generateRange( rangeStart, rangeEnd, interval )

  return {
    selectOption,
    setSelectedOption,
    timeOptions
  }
}

export const generateRange = ( start: Date, end: Date, interval = 30 ): Date[] => {
  const deltaHours = end.getHours() - start.getHours()
  const range = []

  for ( let i = 0; i <= deltaHours; i++ ) {
    const numIntervals = 60 / interval
    let date = new Date()
    date.setHours( start.getHours() + i )

    for ( let j = 0; j < numIntervals; j++ ) {
      date = new Date(date)
      date.setMinutes( j * interval )
      range.push( date )
    }
  }

  return range
}
