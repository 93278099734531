import { PaletteOptions } from '@mui/material/styles'
import { autonomyColor } from '../autonomyColor'

export const palette: PaletteOptions = {
  mode: 'dark',
  background: {
    default: autonomyColor.dark,
    paper: autonomyColor.mediumBlue,
    accent: {
      primary: autonomyColor.cyan,
      secondary: autonomyColor.pink,
      muted: autonomyColor.mutedBlue,
    },
    white: autonomyColor.white,
    black: autonomyColor.black,
  },
  primary: {
    main: autonomyColor.mediumBlue,
    dark: autonomyColor.darkBlue,
  },
  secondary: {
    main: autonomyColor.cyan,
  },
  tertiary: {
    main: autonomyColor.pink,
    contrastText: autonomyColor.dark,
  },
  text: {
    primary: autonomyColor.white,
    secondary: autonomyColor.black,
    error: autonomyColor.red,
    warning: autonomyColor.orange,
    success: autonomyColor.green,
    info: autonomyColor.pink,
    caption: autonomyColor.gray,
    highlight: {
      primary: autonomyColor.cyan,
      secondary: autonomyColor.pink
    }
  },
}
