import { Components } from '@mui/material/styles'
import { palette } from './palette'
import { autonomyColor } from '../autonomyColor'

import '@mui/x-data-grid/themeAugmentation'
// @TODO: check when x-date-pickers package gets bumped out of alpha, then below should work
// import '@mui/x-date-pickers/themeAugmentation'
// to be able to use breakpoints, uncomment below two lines:
// import createBreakpoints from '@mui/system/createTheme/createBreakpoints'
// const breakpoints = createBreakpoints({})

export const components: Components = {
  ///////// BUTTON /////////

  MuiButton: {
    defaultProps: {
      disableElevation: true,
    },
    styleOverrides: {
      root: {
        textTransform: 'none',
        textDecoration: 'none',
        borderRadius: '50px',
        padding: '2px 36px',
        fontSize: 16,
        color: palette.text.primary,
        border: '1px solid transparent',
        '&.round': {
          borderRadius: '50%',
          padding: 0,
          minHeight: 40,
          minWidth: 40,
        },
        '&.ctaBtn': {
          border: `1px solid ${autonomyColor.lightCyan}`,
          borderRadius: 5,
          color: autonomyColor.cyan,
          '> .ctaBtnIcon': {
            marginLeft: 15,
          },
        },
      },
      containedPrimary: {
        variant: 'contained',
        backgroundColor: palette.background.accent.primary,
        color: palette.text.secondary,
        '&:hover': {
          border: `1px solid ${palette.background.accent.primary}`,
          backgroundColor: autonomyColor.darkBlue,
          color: palette.text.highlight.primary,
        },
        '&:disabled': {
          backgroundColor: palette.background.accent.primary,
          color: palette.text.secondary,
          opacity: 0.5,
        },
      },
      containedSecondary: {
        variant: 'contained',
        backgroundColor: palette.background.accent.secondary,
        borderColor: palette.background.accent.secondary,
        color: palette.text.secondary,
        '&:hover': {
          border: `1px solid ${palette.background.accent.secondary}`,
          backgroundColor: autonomyColor.darkBlue,
          color: palette.text.highlight.secondary,
        },
        '&:disabled': {
          backgroundColor: palette.background.accent.secondary,
          borderColor: palette.background.accent.secondary,
          color: palette.text.secondary,
          opacity: 0.5,
        },
      },
      outlinedPrimary: {
        variant: 'outlined',
        color: palette.text.primary,
        border: '1px solid ' + `${palette.background.white}`,
        '&:hover': {
          border: `1px solid ${palette.background.white}`,
          backgroundColor: palette.background.white,
          color: palette.text.secondary,
        },
      },
    },
  },
  MuiTablePagination: {
    styleOverrides: {
      root: {
        color: palette.text.primary,
      },
    },
  },

  ///////// DATA GRID /////////

  MuiDataGrid: {
    styleOverrides: {
      columnSeparator: {
        display: 'none',
      },
      root: {
        backgroundColor: 'transparent',
        color: palette.text.primary,
        flexGrow: 1,
        fontSize: 16,
        fontWeight: 400,
        border: 'none',
        width: '100%',
        '& .MuiDataGrid-cell a': {
          textDecoration: 'none',
        },
        '& .MuiDataGrid-cell a.documentLink': {
          color: palette.text.primary,
          '&:hover': {
            textDecoration: 'underline',
          },
          '&:visited': {
            color: palette.text.primary,
          },
        },
        '& .MuiDataGrid-virtualScroller': {
          overflowY: 'hidden',
        },
        '& .MuiDataGrid-columnHeaders': {
          borderBottom: `1px solid ${autonomyColor.mutedBlue}`,
        },
        '& .MuiDataGrid-columnHeader.captionColumnHeader': {
          color: palette.text.caption,
          fontSize: 14,
        },
        '& .MuiDataGrid-row': {
          borderTop: `1px solid ${autonomyColor.mutedBlue}`,
          '&:first-of-type': {
            borderTop: '1px solid transparent',
          },
        },
        '& .MuiDataGrid-footerContainer': {
          borderTop: `1px solid ${autonomyColor.mutedBlue}`,
        },
        '& .MuiDataGrid-overlay': {
          backgroundColor: 'transparent',
        },
        '& .MuiDataGrid-cell.alignCenter': {
          justifyContent: 'center',
        },
        // Resetting MUI DataGrid default behaviour on renderEditCell
        // this should only affect / apply to cells with TextFields
        '& .MuiDataGrid-cell.MuiDataGrid-cell--editing': {
          paddingLeft: '11px',
          paddingRight: '11px',
          backgroundColor: 'transparent',
        },
      },
    },
  },
  MuiCircularProgress: {
    styleOverrides: {
      root: {
        color: autonomyColor.mutedBlue,
      },
    },
  },

  ///////// TYPOGRAPHY /////////

  MuiTypography: {
    styleOverrides: {
      root: {
        '&.primaryAccent': {
          color: palette.text.highlight.primary,
          fontWeight: 'bold',
        },
        '&.secondaryAccent': {
          color: palette.text.highlight.secondary,
        },
        // force pointer cursor on elements with non-inherent pointer hover styles
        '&.hoverStyle': {
          cursor: 'pointer',
        },
        '&.bold': {
          fontWeight: 700,
        },
        '&.muted': {
          opacity: 0.3,
        },
        '&.success': {
          color: palette.text.success,
        },
        '&.error': {
          color: palette.text.error,
        },
      },
    },
  },

  ///////// APP BAR /////////

  MuiAppBar: {
    styleOverrides: {
      root: {
        height: 64,
        minHeight: 64,
        backgroundColor: palette.background.default,
        backgroundImage: 'none', // remove linear gradient from appbar
        '& .MuiAutocomplete-root': {
          boxSizing: 'border-box',
          flex: 0.8,
          height: 37,
          maxHeight: 37,
          '& .MuiOutlinedInput-root': {
            backgroundColor: 'transparent',
            padding: '0 0 0 10px !important',
            '& .MuiOutlinedInput-notchedOutline': {
              border: `1px solid ${palette.background.accent.primary}`,
              borderRadius: 50,
            },
            '& .MuiInputAdornment-root.MuiInputAdornment-positionEnd': {
              backgroundColor: palette.background.accent.primary,
              height: '100%',
              maxHeight: '100%',
              borderRadius: '0 50px 50px 0',
              paddingLeft: 20,
              paddingRight: 20,
            },
            '> input::placeholder': {
              color: palette.text.highlight.primary,
            },
          },
          '& .MuiTextField-root': {
            backgroundColor: 'transparent',
            boxSizing: 'border-box',
            color: palette.text.primary,
            height: 37,
            maxHeight: 37,
          },
        },
      },
    },
  },

  ///////// DIALOGS /////////

  MuiDialog: {
    styleOverrides: {
      root: {
        '& .MuiDialog-container': {
          margin: '0 auto',
        },
        '& .MuiGrid-root': {
          marginTop: 0,
          width: '100%',
          marginLeft: 0,
          '& .MuiGrid-item': {
            paddingLeft: 0,
          },
        },
      },
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: {
        '& .MuiInputLabel-root': {
          color: palette.text.primary,
        },
      },
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: {
        padding: 20,
      },
    },
  },

  ///////// CHECKBOX /////////

  MuiCheckbox: {
    styleOverrides: {
      colorPrimary: {
        opacity: 0.3,
        color: palette.background.white,
        '&.Mui-checked': {
          color: palette.background.white,
          opacity: 1,
        },
      },
    },
  },

  ///////// FORM CONTROL LABEL /////////

  MuiFormControlLabel: {
    styleOverrides: {
      root: {
        color: palette.background.accent.muted,
        '&.active': {
          color: palette.background.white,
        },
        // reset cursor on elements with inherent pointer hover style
        '&.resetHoverStyle': {
          ':hover': {
            cursor: 'not-allowed',
          },
        },
        '&.resetHoverStyle .MuiCheckbox-root': {
          ':hover': {
            cursor: 'not-allowed',
          },
        },
        '&.compactCheckboxList': {
          '> .MuiCheckbox-root': {
            padding: '5px 19px 5px 9px',
          },
        },
        '&.switchLabel': {
          color: palette.text.primary,
          '&.muted': {
            opacity: 0.3,
          },
        },
        '&.checkboxLabel': {
          color: palette.text.primary,
        },
      },
    },
  },

  ///////// INPUTS /////////

  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        backgroundColor: palette.background.paper,
        color: '#80a0ad',
        fontSize: '.9rem',
        padding: '5px 19px',
        '> .MuiOutlinedInput-input': {
          padding: 0,
        },
        minWidth: '245px',
        '&.compactSelect': {
          minWidth: 0,
          height: '30px',
          maxHeight: '30px',
          color: palette.text.primary,
        },
        transition: 'opacity 200ms ease-in',
        '&.Mui-disabled': {
          opacity: 0.3,
          ':hover': {
            cursor: 'not-allowed',
          },
          '& .MuiSelect-select:hover': {
            cursor: 'not-allowed',
          },
        },
      },
    },
  },

  MuiTextField: {
    styleOverrides: {
      root: {
        '&.MuiTextField-root': {
          '&.compactTextField': {
            '> .MuiOutlinedInput-root': {
              color: palette.text.primary,
              minWidth: 0,
            },
            '&.roundedTextField': {
              '> .MuiOutlinedInput-root': {
                borderRadius: 50,
                minWidth: 0,
                width: 'auto',
                '&.xs': {
                  maxWidth: '100px',
                },
                '> .MuiInputBase-input': {
                  textAlign: 'center',
                },
              },
            },
          },
          '&.wideTextField': {
            '> .MuiOutlinedInput-root': {
              color: palette.text.primary,
              minWidth: '300px',
            },
          },
        },
      },
    },
  },

  MuiSelect: {
    defaultProps: {
      autoWidth: false,
    },
  },

  MuiInputLabel: {
    styleOverrides: {
      root: {
        color: palette.text.primary,
      },
    },
  },

  ///////// PAPER /////////

  MuiPaper: {
    styleOverrides: {
      root: {
        '> .MuiList-root': {
          backgroundColor: palette.background.paper,
          paddingTop: 0,
        },
        // the currently selected value in a Select dropdown
        '> .MuiList-root > li.Mui-selected': {
          paddingTop: 10,
          backgroundColor: palette.background.accent.muted,
        },
        '> .MuiList-root > li': {
          paddingLeft: '29px',
          paddingRight: '29px',
          color: palette.text.primary,
        },
        '> .MuiList-root > li:not(:last-of-type)': {
          paddingBottom: 10,
          borderBottom: '0.5px solid #80a0ad',
        },

        ///////// TEMP MUI X-DATE-PICKERS /////////

        // note: this should only be temporary, until the x-date-picker package gets
        // bumped out of alpha version. Then we should ( be able to ) just use MuiDatePicker

        '.MuiCalendarPicker-root': {
          backgroundColor: autonomyColor.darkBlue,
          // S M T etc.
          '.MuiTypography-caption': {
            color: '#E6F200',
            fontWeight: 800,
          },
          '.PrivatePickersSlideTransition-root': {
            height: 'auto',
          },
          // the days in the calendar month
          '.MuiPickersDay-root': {
            backgroundColor: autonomyColor.darkBlue,
            border: 'none',
            fontWeight: 800,
            // selected date
            '&.Mui-selected': {
              backgroundColor: palette.background.accent.secondary,
              color: autonomyColor.darkBlue,
            },
            '&.Mui-disabled': {
              color: autonomyColor.mutedBlue,
            },
          },
        },
      },
    },
  },

  ///////// MUI ICON BUTTON /////////

  MuiIconButton: {
    styleOverrides: {
      root: {
        '&.xsIcon': {
          '& .MuiSvgIcon-root': {
            height: '.7em',
            width: 'auto',
          },
        },
      },
    },
  },

  ///////// MUI SWITCH /////////

  MuiSwitch: {
    styleOverrides: {
      root: {
        width: '70px',
        '.MuiSwitch-thumb': {
          transform: 'translateX(6px)',
        },
        '.MuiSwitch-track': {
          backgroundColor: 'transparent',
          border: `1px solid ${palette.background.white}`,
          borderRadius: '50px',
          height: '26px',
          transform: 'translateY(-6px)',
        },
      },
      colorPrimary: {
        // unchecked base styles
        opacity: 0.3,
        '& .MuiSwitch-thumb': {
          backgroundColor: palette.background.white,
        },
        // checked style
        '&.Mui-checked': {
          opacity: 1,
        },
      },
    },
  },

  ///////// MUI TABLE /////////

  MuiTableRow: {
    styleOverrides: {
      root: {
        '& .MuiTableCell-root': {
          borderBottom: `1px solid ${autonomyColor.mutedBlue}`,
        },
        '& .MuiTableCell-root:first-of-type': {
          paddingLeft: 0,
        },
        '& .MuiTableCell-root:last-of-type': {
          paddingRight: 0,
        },
        '&:last-child': {
          '& .MuiTableCell-root': {
            borderBottom: '1px solid transparent',
          },
        },
      },
    },
  },

  ///////// MUI ACCORDION /////////

  MuiAccordion: {
    styleOverrides: {
      root: {
        backgroundImage: 'none',
        boxShadow: 'none',
        paddingTop: 50,
        paddingBottom: 50,
        position: 'initial',
        borderBottom: `1px solid ${autonomyColor.mutedBlue}`,
      },
    },
  },

  MuiAccordionDetails: {
    styleOverrides: {
      root: {
        '&.horizontal > .MuiGrid-root': {
          '> .MuiGrid-root': {
            '> .MuiGrid-root': {
              columnGap: '20px',
              rowGap: '20px',
            },
          },
        },
      },
    },
  },

  ///////// MUI MODAL /////////

  MuiModal: {
    styleOverrides: {
      root: {
        display: 'flex',
        alignItems: 'center',

        '&.modal-xl': {
          width: '90%',
          margin: '0 auto',
        },
      },
    },
  },
}
