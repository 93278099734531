import { map } from 'lodash'
import { useEffect, useState } from 'react'
import { safeParseJSON } from '../../utils/safeParseJSON'

function getTime() {
  return new Date().getTime()
}

const vestaboardURL = 'wss://platform.vestaboard.com/ws/'
function uuidv4() {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  )
}
const uid = uuidv4()

export function useVestaboard(){
  const [vbData, setVBData] = useState({
    fleet: '', 
    val: '',
    cost: '', 
    arp: '', 
    inv: '', 
    paid: '', 
    subs: '', 
    app: '', 
    resv: '', 
    qual: '', 
    cars: '', 
    daily: ''
  })

  useEffect(() => {
    const socket = new WebSocket(`${vestaboardURL}${uid}`)
    socket.onopen = function () {
      socket.send(
        '{"message":{"type":"DeviceHeartbeatMessage","deviceId":"' +
          uid +
          '","clientTime":' +
          getTime() +
          '}}'
      )
      socket.send(
        '{"message":{"type":"DeviceIdentifyMessage","deviceId":"' +
          uid +
          '","clientTime":' +
          getTime() +
          ',"boardId":"20c647fe-3057-4531-888d-a9e81faed9c7"}}'
      )
    }

    socket.onmessage = event => {
      const data = safeParseJSON(event.data)

      if(!isDisplayCommand(data)){
        return
      }


      const characters = data.message.characters.characters

      const vestaLines = map(characters, (chars) => {
        return map(chars, (char) => {
          return convertCharacterCodeToCharacter(char)
        })
      })

      const vestaboardData: VestaboardData = {
        fleet: vestaLines[0].slice(0, 6).join('').trim(),
        val: vestaLines[1].slice(0, 6).join('').trim(),
        cost: vestaLines[2].slice(0, 6).join('').trim(),
        arp: vestaLines[3].slice(0, 6).join('').trim(),
        inv: vestaLines[4].slice(0, 6).join('').trim(),
        paid: vestaLines[5].slice(0, 6).join('').trim(),
        subs: vestaLines[0].slice(13, 17).join('').trim(),
        app: vestaLines[1].slice(13, 17).join('').trim(),
        resv: vestaLines[2].slice(13, 17).join('').trim(),
        qual: vestaLines[3].slice(13, 17).join('').trim(),
        cars: vestaLines[4].slice(12, 17).join('').trim(),
        daily: vestaLines[5].slice(13, 17).join('').trim(),
      }

      setVBData(vestaboardData)
    }
    return () => {
      return socket.close()
    }
  }, [])

  return {
    vbData
  }
}

function isDisplayCommand(data: any): data is DeviceMessageDisplayCommand {
  return data?.message?.type === 'DeviceMessageDisplayCommand'
}

type VestaboardData = {
  fleet: string
  val: string
  cost: string
  arp: string
  inv: string
  paid: string
  subs: string
  app: string
  resv: string
  qual: string
  cars: string
  daily: string
}

type DeviceMessageDisplayCommand = {
  type: 'DeviceMessageDisplayCommand'
  deviceId: string
  serverTime: number
  messageId: string
  message: {
    characters: {
      characterSet: {
        value: string
      }
      characters: number[][]
    }
  }
}

function convertCharacterCodeToCharacter(characterCode) {
  switch (characterCode) {
  case 0: return ' '
  case 1: return 'A'
  case 2: return 'B'
  case 3: return 'C'
  case 4: return 'D'
  case 5: return 'E'
  case 6: return 'F'
  case 7: return 'G'
  case 8: return 'H'
  case 9: return 'I'
  case 10: return 'J'
  case 11: return 'K'
  case 12: return 'L'
  case 13: return 'M'
  case 14: return 'N'
  case 15: return 'O'
  case 16: return 'P'
  case 17: return 'Q'
  case 18: return 'R'
  case 19: return 'S'
  case 20: return 'T'
  case 21: return 'U'
  case 22: return 'V'
  case 23: return 'W'
  case 24: return 'X'
  case 25: return 'Y'
  case 26: return 'Z'
  case 27: return '1'
  case 28: return '2'
  case 29: return '3'
  case 30: return '4'
  case 31: return '5'
  case 32: return '6'
  case 33: return '7'
  case 34: return '8'
  case 35: return '9'
  case 36: return '0'
  case 37: return '!'
  case 38: return '@'
  case 39: return '#'
  case 40: return '$'
  case 41: return '('
  case 42: return ')'
  case 44: return '-'
  case 46: return '+'
  case 47: return '&'
  case 48: return '='
  case 49: return ';'
  case 50: return ':'
  case 52: return '\''
  case 53: return '"'
  case 54: return '%'
  case 55: return ','
  case 56: return '.'
  case 59: return '/'
  case 60: return '?'
  case 62: return '°'
  case 63: return '`R'
  case 64: return '`O'
  case 65: return '`Y'
  case 66: return '`G'
  case 67: return '`B'
  case 68: return '`V'
  case 69: return '`W'
  default: return '?'
  }
}