import React from 'react'
import { makeStyles } from 'tss-react/mui'
import { Box, Grid, Typography, Button, Select, MenuItem } from '@mui/material'

import { autonomyColor } from '../../../../../../global-styles/autonomyColor'
import { FormattedReservationResponse } from '../../useReservation'
import { LinearProgressLoader } from 'shared/LinearProgressLoader'
import { useProgramPricingBlock } from './useProgramPricingBlock'
import { palette } from '../../../../../../global-styles/theme/palette'
import { isGreaterThanZero } from '../../../../../../utils/isGreaterThanZero'
import { formatCents } from '../../../../../../utils/currency-formatter'
import { PricingOption } from '@nxcr-org/web-api-interface/lib/subscription_pb'

interface ProgramPricingBlockProps {
  reservation: FormattedReservationResponse
  isAdmin: boolean
  setReload: () => void
}

// @TODO: Ideally put it this in a Section component, but will need to be adjusted a bit
const useStyles = makeStyles()((theme) => {
  return {
    root: {
      margin: theme.spacing(2, 0, 4, 0),
      width: '100%',
    },
    body: {
      marginTop: 24,
      padding: theme.spacing(3),
      backgroundColor: theme.palette.primary.dark,
      borderRadius: 8,
    },
    loader: {
      backgroundColor: autonomyColor.lightBlue,
    },
    termLength: {
      marginTop: 16,
    },
  }
})

export const ProgramPricingBlock = ({
  reservation,
  isAdmin,
  setReload,
}: ProgramPricingBlockProps) => {
  const { classes } = useStyles()
  const {
    isEdit,
    setIsEdit,
    monthlyPayment,
    startFee,
    handleChange,
    cancelChanges,
    savePricing,
    isValidSubscriptionStatus,
    programPricingOptions,
  } = useProgramPricingBlock(reservation)

  const selectedPricingOption = programPricingOptions.find((option) => {
    return option.startFee === startFee && option.monthly === monthlyPayment
  })
  const selectedPricingOptionId = selectedPricingOption?.id || 'none'

  const storedTermLength = reservation?.vehicle.termLength
  const hasNoStoredTermLength = reservation?.vehicle.termLength === undefined

  const isCustomOption = (option: PricingOption.AsObject) =>
    option.id.startsWith('custom')

  return (
    <Grid item className={classes.root}>
      <Grid container item xs={12} justifyContent="space-between">
        <Typography variant="h3">Program Pricing</Typography>

        <Grid item>
          {!isEdit && !isValidSubscriptionStatus(reservation) && isAdmin && (
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setIsEdit(true)}
            >
              Edit
            </Button>
          )}

          {isEdit && (
            <Box sx={{ display: 'flex', columnGap: 1 }}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  savePricing.reset()
                  cancelChanges()
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  savePricing
                    .mutateAsync()
                    .then(() => {
                      setIsEdit(false)
                      setReload()
                    })
                    .catch(() => {
                      // Error is visually shown
                    })
                }}
                disabled={!isGreaterThanZero(monthlyPayment)}
              >
                Save
              </Button>
            </Box>
          )}
        </Grid>
      </Grid>

      {savePricing.isLoading && <LinearProgressLoader />}
      <Grid className={classes.body}>
        <Grid container spacing={2}>
          <Grid item>
            {hasNoStoredTermLength ? (
              <Typography marginBottom={2}>
                Customer selected pricing on website/in app:{' '}
                {formatCents(reservation?.vehicle.monthly)}/mo with a{' '}
                {formatCents(reservation?.vehicle.startFee)} start fee.
              </Typography>
            ) : (
              <Typography marginBottom={2}>
                Customer selected pricing on website/in app:{' '}
                {formatCents(reservation?.vehicle.monthly)}/mo with a{' '}
                {formatCents(reservation?.vehicle.startFee)} start fee and{' '}
                {storedTermLength === 0
                  ? 'unlimited '
                  : `${storedTermLength} months `}
                term length.
              </Typography>
            )}
            {!isValidSubscriptionStatus(reservation) && isAdmin && !isEdit && (
              <Typography>
                Select <b>Edit</b> to update this pricing.
              </Typography>
            )}
          </Grid>
          <Grid container item direction="column" spacing={1}>
            <Grid item>
              <Box sx={{ display: 'inline-flex', flexDirection: 'column' }}>
                {isEdit ? (
                  <>
                    <Typography>
                      Select <b>new</b> monthly payment and start fee (legacy
                      pricing not shown)
                    </Typography>
                    <Select
                      displayEmpty
                      id="pricing-options-select"
                      value={selectedPricingOptionId}
                      label="pricingOptions"
                      onChange={(e) => handleChange(e)}
                    >
                      <MenuItem disabled value="none">
                        Select a pricing option
                      </MenuItem>
                      {programPricingOptions.map((option) => {
                        return (
                          <MenuItem value={option.id} key={option.id}>
                            {`${formatCents(
                              option.monthly
                            )}/ mo and ${formatCents(
                              option.startFee
                            )} / start fee`}
                            {isCustomOption(option) ? ' (custom)' : ''}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </>
                ) : (
                  <>
                    <Typography variant="caption">
                      Monthly Payment and Start Fee
                    </Typography>
                    <Typography>
                      {' '}
                      {formatCents(monthlyPayment)}/mo and{' '}
                      {formatCents(startFee)} start fee
                    </Typography>
                  </>
                )}
              </Box>
            </Grid>
            <Grid item>
              {savePricing.isSuccess && (
                <Typography color={palette.text.success}>
                  Changes saved successfully
                </Typography>
              )}
              {savePricing.isError && (
                <Typography color={palette.text.error}>
                  {savePricing.error}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
