import React, { FC } from 'react'
import { Grid } from '@mui/material'
import { map } from 'lodash'
import { useVestaboard } from './useVestaboard'
import { Vestapoint } from './Vestapoint'

export const Vestaboard: FC = () => {
  const { vbData } = useVestaboard()

  return (
    <Grid container sx={{ maxWidth: 800, margin: '20px auto' }}>
      {map(vbData, (val, key) => {
        return (
          <Grid
            item
            xs={6}
            sx={{
              padding: '10px',
            }}
          >
            <Vestapoint label={key} value={val} />
          </Grid>
        )
      })}
    </Grid>
  )
}
