import React from 'react'
import { MenuItem, Select, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { isString } from 'lodash'
import { SelectOption } from '../../../utils'

interface AppointmentFormFieldProps {
  label: string
  value: string | number
  isEditing: boolean
  options: SelectOption[]
  onChange: (value: any) => void
  disabled?: boolean
}

/*
 * NOTE: This form field is a select element. Maybe we should rename this or
 * build it out later so that other form fields are handled here as well
 * */
export const AppointmentFormField: React.FC<AppointmentFormFieldProps> = ({
  label,
  value,
  disabled = false,
  isEditing,
  options,
  onChange,
}) => {
  return (
    <>
      <Typography variant="caption">{label}</Typography>
      {isEditing ? (
        <Select
          value={value}
          displayEmpty
          autoWidth={true}
          IconComponent={ExpandMoreIcon}
          onChange={(e) =>
            onChange(options.find((option) => option.value === e.target.value))
          }
          name={label}
          disabled={disabled}
          className="compactSelect"
          renderValue={(selected) => {
            const selectedOption = options.find(
              (option) => option.value === selected
            )?.label
            if (isString(selected)) {
              return selected.length ? selectedOption : label
            }
            return selected !== undefined ? selectedOption : label
          }}
        >
          {label && (
            <MenuItem value="" disabled>
              {label}
            </MenuItem>
          )}
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      ) : (
        <Typography>
          {options.find((option) => option.value === value)?.label || 'N/A'}
        </Typography>
      )}
    </>
  )
}
