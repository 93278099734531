import React, {useState} from 'react'
import { AutocompleteChangeDetails, AutocompleteChangeReason } from '@mui/material'
import { map } from 'lodash'
import { State } from '@nxcr-org/web-api-interface/lib/vehicle_pb'

import { formatEnum } from '../../../utils/formatEnum'
import { useMapQuery } from '../useMapQuery'

export function useMapQueryCard() {
  const [{ status, customerName, vin, make, model }, setQueryParams] = useMapQuery()
  const [expanded, setExpanded] = useState(false)

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  const statusOptions = [{
    label: 'Any Status',
    key: '',
    value: 1000
  }].concat(map(formatEnum(State), (val, key) => {
    return {
      ...val,
      key
    }
  }))


  const setStatusOption = (
    evt: React.SyntheticEvent,
    value: { label: string, key: string },
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<unknown>
  ) => {
    console.log({
      reason,
      details,
      value,
      evt
    })

    if(reason === 'clear'){
      return setQueryParams({
        status: undefined
      })
    }

    if(value.key.length === 0){
      return setQueryParams({
        status: undefined
      })
    }

    setQueryParams({
      status: value.key
    })
  }

  const buildSetQueryCallback = (key: string) => {
    return (evt: React.ChangeEvent<HTMLInputElement>) => {
      setQueryParams({
        [key]: evt.target.value,
      })
    }
  }

  const setCustomerName = buildSetQueryCallback('customerName')
  const setVIN = buildSetQueryCallback('vin')
  const setMake = buildSetQueryCallback('make')
  const setModel = buildSetQueryCallback('model')

  return {
    status,
    statusOptions,
    setStatusOption,
    customerName,
    vin,
    make,
    model,
    setCustomerName,
    setVIN,
    setMake,
    setModel,
    expanded, 
    setExpanded, 
    handleExpandClick,
  }
}

export type AutocompleteOption = {
  key: string
  value: number
  label: string
}
