// source: document_service.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var domain_pb = require('./domain_pb.js');
goog.object.extend(proto, domain_pb);
var inspection_pb = require('./inspection_pb.js');
goog.object.extend(proto, inspection_pb);
var subscription_pb = require('./subscription_pb.js');
goog.object.extend(proto, subscription_pb);
goog.exportSymbol('proto.document_service.Authorizations', null, global);
goog.exportSymbol('proto.document_service.AutoPayAuthorization', null, global);
goog.exportSymbol('proto.document_service.CustomerDocumentMeta', null, global);
goog.exportSymbol('proto.document_service.GeneratePickupReceiptRequest', null, global);
goog.exportSymbol('proto.document_service.GeneratePickupReceiptResponse', null, global);
goog.exportSymbol('proto.document_service.GetAllCustomerDocumentsRequest', null, global);
goog.exportSymbol('proto.document_service.GetAllCustomerDocumentsResponse', null, global);
goog.exportSymbol('proto.document_service.GetCustomerDocumentRequest', null, global);
goog.exportSymbol('proto.document_service.GetCustomerDocumentResponse', null, global);
goog.exportSymbol('proto.document_service.GetCustomerDocumentsRequest', null, global);
goog.exportSymbol('proto.document_service.GetCustomerDocumentsResponse', null, global);
goog.exportSymbol('proto.document_service.GetOwnDocumentRequest', null, global);
goog.exportSymbol('proto.document_service.GetOwnDocumentResponse', null, global);
goog.exportSymbol('proto.document_service.GetOwnDocumentsRequest', null, global);
goog.exportSymbol('proto.document_service.GetOwnDocumentsResponse', null, global);
goog.exportSymbol('proto.document_service.GetSubscriptionContractToSignRequest', null, global);
goog.exportSymbol('proto.document_service.GetSubscriptionContractToSignResponse', null, global);
goog.exportSymbol('proto.document_service.GetVehicleDocumentRequest', null, global);
goog.exportSymbol('proto.document_service.GetVehicleDocumentResponse', null, global);
goog.exportSymbol('proto.document_service.InitProjectionsRequest', null, global);
goog.exportSymbol('proto.document_service.InitProjectionsResponse', null, global);
goog.exportSymbol('proto.document_service.Inspection', null, global);
goog.exportSymbol('proto.document_service.InspectionPhoto', null, global);
goog.exportSymbol('proto.document_service.InspectionReport', null, global);
goog.exportSymbol('proto.document_service.Inspections', null, global);
goog.exportSymbol('proto.document_service.InsuranceCard', null, global);
goog.exportSymbol('proto.document_service.InsuranceCards', null, global);
goog.exportSymbol('proto.document_service.PickupReceipt', null, global);
goog.exportSymbol('proto.document_service.SignContractRequest', null, global);
goog.exportSymbol('proto.document_service.SignContractResponse', null, global);
goog.exportSymbol('proto.document_service.SubscriptionContract', null, global);
goog.exportSymbol('proto.document_service.UnsignedCustomerContractResponse', null, global);
goog.exportSymbol('proto.document_service.UploadCustomerInspectionImageRequest', null, global);
goog.exportSymbol('proto.document_service.UploadCustomerInspectionImageResponse', null, global);
goog.exportSymbol('proto.document_service.UploadInsuranceCardRequest', null, global);
goog.exportSymbol('proto.document_service.UploadInsuranceCardResponse', null, global);
goog.exportSymbol('proto.document_service.UploadSingleCustomerInspectionImageRequest', null, global);
goog.exportSymbol('proto.document_service.UploadSingleCustomerInspectionImageResponse', null, global);
goog.exportSymbol('proto.document_service.UploadVehicleDocumentRequest', null, global);
goog.exportSymbol('proto.document_service.UploadVehicleDocumentResponse', null, global);
goog.exportSymbol('proto.document_service.VehicleDocumentMeta', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.document_service.UploadInsuranceCardRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.document_service.UploadInsuranceCardRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.document_service.UploadInsuranceCardRequest.displayName = 'proto.document_service.UploadInsuranceCardRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.document_service.UploadInsuranceCardResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.document_service.UploadInsuranceCardResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.document_service.UploadInsuranceCardResponse.displayName = 'proto.document_service.UploadInsuranceCardResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.document_service.UploadSingleCustomerInspectionImageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.document_service.UploadSingleCustomerInspectionImageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.document_service.UploadSingleCustomerInspectionImageRequest.displayName = 'proto.document_service.UploadSingleCustomerInspectionImageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.document_service.UploadCustomerInspectionImageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.document_service.UploadCustomerInspectionImageRequest.repeatedFields_, null);
};
goog.inherits(proto.document_service.UploadCustomerInspectionImageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.document_service.UploadCustomerInspectionImageRequest.displayName = 'proto.document_service.UploadCustomerInspectionImageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.document_service.UploadSingleCustomerInspectionImageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.document_service.UploadSingleCustomerInspectionImageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.document_service.UploadSingleCustomerInspectionImageResponse.displayName = 'proto.document_service.UploadSingleCustomerInspectionImageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.document_service.UploadCustomerInspectionImageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.document_service.UploadCustomerInspectionImageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.document_service.UploadCustomerInspectionImageResponse.displayName = 'proto.document_service.UploadCustomerInspectionImageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.document_service.UploadVehicleDocumentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.document_service.UploadVehicleDocumentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.document_service.UploadVehicleDocumentRequest.displayName = 'proto.document_service.UploadVehicleDocumentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.document_service.UploadVehicleDocumentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.document_service.UploadVehicleDocumentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.document_service.UploadVehicleDocumentResponse.displayName = 'proto.document_service.UploadVehicleDocumentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.document_service.GetVehicleDocumentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.document_service.GetVehicleDocumentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.document_service.GetVehicleDocumentRequest.displayName = 'proto.document_service.GetVehicleDocumentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.document_service.GetVehicleDocumentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.document_service.GetVehicleDocumentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.document_service.GetVehicleDocumentResponse.displayName = 'proto.document_service.GetVehicleDocumentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.document_service.GetSubscriptionContractToSignRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.document_service.GetSubscriptionContractToSignRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.document_service.GetSubscriptionContractToSignRequest.displayName = 'proto.document_service.GetSubscriptionContractToSignRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.document_service.GetSubscriptionContractToSignResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.document_service.GetSubscriptionContractToSignResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.document_service.GetSubscriptionContractToSignResponse.displayName = 'proto.document_service.GetSubscriptionContractToSignResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.document_service.CustomerDocumentMeta = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.document_service.CustomerDocumentMeta, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.document_service.CustomerDocumentMeta.displayName = 'proto.document_service.CustomerDocumentMeta';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.document_service.VehicleDocumentMeta = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.document_service.VehicleDocumentMeta, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.document_service.VehicleDocumentMeta.displayName = 'proto.document_service.VehicleDocumentMeta';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.document_service.SubscriptionContract = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.document_service.SubscriptionContract, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.document_service.SubscriptionContract.displayName = 'proto.document_service.SubscriptionContract';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.document_service.PickupReceipt = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.document_service.PickupReceipt, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.document_service.PickupReceipt.displayName = 'proto.document_service.PickupReceipt';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.document_service.AutoPayAuthorization = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.document_service.AutoPayAuthorization, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.document_service.AutoPayAuthorization.displayName = 'proto.document_service.AutoPayAuthorization';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.document_service.Authorizations = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.document_service.Authorizations.repeatedFields_, null);
};
goog.inherits(proto.document_service.Authorizations, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.document_service.Authorizations.displayName = 'proto.document_service.Authorizations';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.document_service.InspectionPhoto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.document_service.InspectionPhoto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.document_service.InspectionPhoto.displayName = 'proto.document_service.InspectionPhoto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.document_service.InspectionReport = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.document_service.InspectionReport, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.document_service.InspectionReport.displayName = 'proto.document_service.InspectionReport';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.document_service.Inspection = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.document_service.Inspection.repeatedFields_, null);
};
goog.inherits(proto.document_service.Inspection, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.document_service.Inspection.displayName = 'proto.document_service.Inspection';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.document_service.Inspections = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.document_service.Inspections.repeatedFields_, null);
};
goog.inherits(proto.document_service.Inspections, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.document_service.Inspections.displayName = 'proto.document_service.Inspections';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.document_service.InsuranceCard = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.document_service.InsuranceCard, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.document_service.InsuranceCard.displayName = 'proto.document_service.InsuranceCard';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.document_service.InsuranceCards = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.document_service.InsuranceCards.repeatedFields_, null);
};
goog.inherits(proto.document_service.InsuranceCards, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.document_service.InsuranceCards.displayName = 'proto.document_service.InsuranceCards';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.document_service.GetOwnDocumentsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.document_service.GetOwnDocumentsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.document_service.GetOwnDocumentsRequest.displayName = 'proto.document_service.GetOwnDocumentsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.document_service.GetOwnDocumentsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.document_service.GetOwnDocumentsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.document_service.GetOwnDocumentsResponse.displayName = 'proto.document_service.GetOwnDocumentsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.document_service.GetOwnDocumentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.document_service.GetOwnDocumentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.document_service.GetOwnDocumentRequest.displayName = 'proto.document_service.GetOwnDocumentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.document_service.GetOwnDocumentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.document_service.GetOwnDocumentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.document_service.GetOwnDocumentResponse.displayName = 'proto.document_service.GetOwnDocumentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.document_service.SignContractRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.document_service.SignContractRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.document_service.SignContractRequest.displayName = 'proto.document_service.SignContractRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.document_service.SignContractResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.document_service.SignContractResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.document_service.SignContractResponse.displayName = 'proto.document_service.SignContractResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.document_service.GeneratePickupReceiptRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.document_service.GeneratePickupReceiptRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.document_service.GeneratePickupReceiptRequest.displayName = 'proto.document_service.GeneratePickupReceiptRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.document_service.GeneratePickupReceiptResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.document_service.GeneratePickupReceiptResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.document_service.GeneratePickupReceiptResponse.displayName = 'proto.document_service.GeneratePickupReceiptResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.document_service.UnsignedCustomerContractResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.document_service.UnsignedCustomerContractResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.document_service.UnsignedCustomerContractResponse.displayName = 'proto.document_service.UnsignedCustomerContractResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.document_service.GetAllCustomerDocumentsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.document_service.GetAllCustomerDocumentsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.document_service.GetAllCustomerDocumentsRequest.displayName = 'proto.document_service.GetAllCustomerDocumentsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.document_service.GetAllCustomerDocumentsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.document_service.GetAllCustomerDocumentsResponse.repeatedFields_, null);
};
goog.inherits(proto.document_service.GetAllCustomerDocumentsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.document_service.GetAllCustomerDocumentsResponse.displayName = 'proto.document_service.GetAllCustomerDocumentsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.document_service.GetCustomerDocumentsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.document_service.GetCustomerDocumentsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.document_service.GetCustomerDocumentsRequest.displayName = 'proto.document_service.GetCustomerDocumentsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.document_service.GetCustomerDocumentsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.document_service.GetCustomerDocumentsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.document_service.GetCustomerDocumentsResponse.displayName = 'proto.document_service.GetCustomerDocumentsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.document_service.GetCustomerDocumentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.document_service.GetCustomerDocumentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.document_service.GetCustomerDocumentRequest.displayName = 'proto.document_service.GetCustomerDocumentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.document_service.GetCustomerDocumentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.document_service.GetCustomerDocumentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.document_service.GetCustomerDocumentResponse.displayName = 'proto.document_service.GetCustomerDocumentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.document_service.InitProjectionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.document_service.InitProjectionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.document_service.InitProjectionsRequest.displayName = 'proto.document_service.InitProjectionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.document_service.InitProjectionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.document_service.InitProjectionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.document_service.InitProjectionsResponse.displayName = 'proto.document_service.InitProjectionsResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.document_service.UploadInsuranceCardRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.document_service.UploadInsuranceCardRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.document_service.UploadInsuranceCardRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.document_service.UploadInsuranceCardRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    insuranceCardBinary: msg.getInsuranceCardBinary_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.document_service.UploadInsuranceCardRequest}
 */
proto.document_service.UploadInsuranceCardRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.document_service.UploadInsuranceCardRequest;
  return proto.document_service.UploadInsuranceCardRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.document_service.UploadInsuranceCardRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.document_service.UploadInsuranceCardRequest}
 */
proto.document_service.UploadInsuranceCardRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setInsuranceCardBinary(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.document_service.UploadInsuranceCardRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.document_service.UploadInsuranceCardRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.document_service.UploadInsuranceCardRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.document_service.UploadInsuranceCardRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getInsuranceCardBinary_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      3,
      f
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.document_service.UploadInsuranceCardRequest.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.document_service.UploadInsuranceCardRequest} returns this
 */
proto.document_service.UploadInsuranceCardRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string subscription_id = 2;
 * @return {string}
 */
proto.document_service.UploadInsuranceCardRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.document_service.UploadInsuranceCardRequest} returns this
 */
proto.document_service.UploadInsuranceCardRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bytes insurance_card_binary = 3;
 * @return {string}
 */
proto.document_service.UploadInsuranceCardRequest.prototype.getInsuranceCardBinary = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes insurance_card_binary = 3;
 * This is a type-conversion wrapper around `getInsuranceCardBinary()`
 * @return {string}
 */
proto.document_service.UploadInsuranceCardRequest.prototype.getInsuranceCardBinary_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getInsuranceCardBinary()));
};


/**
 * optional bytes insurance_card_binary = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getInsuranceCardBinary()`
 * @return {!Uint8Array}
 */
proto.document_service.UploadInsuranceCardRequest.prototype.getInsuranceCardBinary_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getInsuranceCardBinary()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.document_service.UploadInsuranceCardRequest} returns this
 */
proto.document_service.UploadInsuranceCardRequest.prototype.setInsuranceCardBinary = function(value) {
  return jspb.Message.setProto3BytesField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.document_service.UploadInsuranceCardResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.document_service.UploadInsuranceCardResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.document_service.UploadInsuranceCardResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.document_service.UploadInsuranceCardResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    documentId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.document_service.UploadInsuranceCardResponse}
 */
proto.document_service.UploadInsuranceCardResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.document_service.UploadInsuranceCardResponse;
  return proto.document_service.UploadInsuranceCardResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.document_service.UploadInsuranceCardResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.document_service.UploadInsuranceCardResponse}
 */
proto.document_service.UploadInsuranceCardResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.document_service.UploadInsuranceCardResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.document_service.UploadInsuranceCardResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.document_service.UploadInsuranceCardResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.document_service.UploadInsuranceCardResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDocumentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string document_id = 1;
 * @return {string}
 */
proto.document_service.UploadInsuranceCardResponse.prototype.getDocumentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.document_service.UploadInsuranceCardResponse} returns this
 */
proto.document_service.UploadInsuranceCardResponse.prototype.setDocumentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.document_service.UploadSingleCustomerInspectionImageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.document_service.UploadSingleCustomerInspectionImageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.document_service.UploadSingleCustomerInspectionImageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.document_service.UploadSingleCustomerInspectionImageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    vin: jspb.Message.getFieldWithDefault(msg, 2, ""),
    inspectionId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    indexnum: jspb.Message.getFieldWithDefault(msg, 4, 0),
    image: msg.getImage_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.document_service.UploadSingleCustomerInspectionImageRequest}
 */
proto.document_service.UploadSingleCustomerInspectionImageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.document_service.UploadSingleCustomerInspectionImageRequest;
  return proto.document_service.UploadSingleCustomerInspectionImageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.document_service.UploadSingleCustomerInspectionImageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.document_service.UploadSingleCustomerInspectionImageRequest}
 */
proto.document_service.UploadSingleCustomerInspectionImageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVin(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setInspectionId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setIndexnum(value);
      break;
    case 5:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setImage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.document_service.UploadSingleCustomerInspectionImageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.document_service.UploadSingleCustomerInspectionImageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.document_service.UploadSingleCustomerInspectionImageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.document_service.UploadSingleCustomerInspectionImageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVin();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getInspectionId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getIndexnum();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getImage_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      5,
      f
    );
  }
};


/**
 * optional string vehicle_id = 1;
 * @return {string}
 */
proto.document_service.UploadSingleCustomerInspectionImageRequest.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.document_service.UploadSingleCustomerInspectionImageRequest} returns this
 */
proto.document_service.UploadSingleCustomerInspectionImageRequest.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string vin = 2;
 * @return {string}
 */
proto.document_service.UploadSingleCustomerInspectionImageRequest.prototype.getVin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.document_service.UploadSingleCustomerInspectionImageRequest} returns this
 */
proto.document_service.UploadSingleCustomerInspectionImageRequest.prototype.setVin = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string inspection_id = 3;
 * @return {string}
 */
proto.document_service.UploadSingleCustomerInspectionImageRequest.prototype.getInspectionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.document_service.UploadSingleCustomerInspectionImageRequest} returns this
 */
proto.document_service.UploadSingleCustomerInspectionImageRequest.prototype.setInspectionId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 indexNum = 4;
 * @return {number}
 */
proto.document_service.UploadSingleCustomerInspectionImageRequest.prototype.getIndexnum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.document_service.UploadSingleCustomerInspectionImageRequest} returns this
 */
proto.document_service.UploadSingleCustomerInspectionImageRequest.prototype.setIndexnum = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bytes image = 5;
 * @return {string}
 */
proto.document_service.UploadSingleCustomerInspectionImageRequest.prototype.getImage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * optional bytes image = 5;
 * This is a type-conversion wrapper around `getImage()`
 * @return {string}
 */
proto.document_service.UploadSingleCustomerInspectionImageRequest.prototype.getImage_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getImage()));
};


/**
 * optional bytes image = 5;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getImage()`
 * @return {!Uint8Array}
 */
proto.document_service.UploadSingleCustomerInspectionImageRequest.prototype.getImage_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getImage()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.document_service.UploadSingleCustomerInspectionImageRequest} returns this
 */
proto.document_service.UploadSingleCustomerInspectionImageRequest.prototype.setImage = function(value) {
  return jspb.Message.setProto3BytesField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.document_service.UploadCustomerInspectionImageRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.document_service.UploadCustomerInspectionImageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.document_service.UploadCustomerInspectionImageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.document_service.UploadCustomerInspectionImageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.document_service.UploadCustomerInspectionImageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    vin: jspb.Message.getFieldWithDefault(msg, 2, ""),
    inspectionId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    imagesList: msg.getImagesList_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.document_service.UploadCustomerInspectionImageRequest}
 */
proto.document_service.UploadCustomerInspectionImageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.document_service.UploadCustomerInspectionImageRequest;
  return proto.document_service.UploadCustomerInspectionImageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.document_service.UploadCustomerInspectionImageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.document_service.UploadCustomerInspectionImageRequest}
 */
proto.document_service.UploadCustomerInspectionImageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVin(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setInspectionId(value);
      break;
    case 4:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.addImages(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.document_service.UploadCustomerInspectionImageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.document_service.UploadCustomerInspectionImageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.document_service.UploadCustomerInspectionImageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.document_service.UploadCustomerInspectionImageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVin();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getInspectionId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getImagesList_asU8();
  if (f.length > 0) {
    writer.writeRepeatedBytes(
      4,
      f
    );
  }
};


/**
 * optional string vehicle_id = 1;
 * @return {string}
 */
proto.document_service.UploadCustomerInspectionImageRequest.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.document_service.UploadCustomerInspectionImageRequest} returns this
 */
proto.document_service.UploadCustomerInspectionImageRequest.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string vin = 2;
 * @return {string}
 */
proto.document_service.UploadCustomerInspectionImageRequest.prototype.getVin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.document_service.UploadCustomerInspectionImageRequest} returns this
 */
proto.document_service.UploadCustomerInspectionImageRequest.prototype.setVin = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string inspection_id = 3;
 * @return {string}
 */
proto.document_service.UploadCustomerInspectionImageRequest.prototype.getInspectionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.document_service.UploadCustomerInspectionImageRequest} returns this
 */
proto.document_service.UploadCustomerInspectionImageRequest.prototype.setInspectionId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated bytes images = 4;
 * @return {!Array<string>}
 */
proto.document_service.UploadCustomerInspectionImageRequest.prototype.getImagesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * repeated bytes images = 4;
 * This is a type-conversion wrapper around `getImagesList()`
 * @return {!Array<string>}
 */
proto.document_service.UploadCustomerInspectionImageRequest.prototype.getImagesList_asB64 = function() {
  return /** @type {!Array<string>} */ (jspb.Message.bytesListAsB64(
      this.getImagesList()));
};


/**
 * repeated bytes images = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getImagesList()`
 * @return {!Array<!Uint8Array>}
 */
proto.document_service.UploadCustomerInspectionImageRequest.prototype.getImagesList_asU8 = function() {
  return /** @type {!Array<!Uint8Array>} */ (jspb.Message.bytesListAsU8(
      this.getImagesList()));
};


/**
 * @param {!(Array<!Uint8Array>|Array<string>)} value
 * @return {!proto.document_service.UploadCustomerInspectionImageRequest} returns this
 */
proto.document_service.UploadCustomerInspectionImageRequest.prototype.setImagesList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {!(string|Uint8Array)} value
 * @param {number=} opt_index
 * @return {!proto.document_service.UploadCustomerInspectionImageRequest} returns this
 */
proto.document_service.UploadCustomerInspectionImageRequest.prototype.addImages = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.document_service.UploadCustomerInspectionImageRequest} returns this
 */
proto.document_service.UploadCustomerInspectionImageRequest.prototype.clearImagesList = function() {
  return this.setImagesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.document_service.UploadSingleCustomerInspectionImageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.document_service.UploadSingleCustomerInspectionImageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.document_service.UploadSingleCustomerInspectionImageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.document_service.UploadSingleCustomerInspectionImageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    inspectionId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.document_service.UploadSingleCustomerInspectionImageResponse}
 */
proto.document_service.UploadSingleCustomerInspectionImageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.document_service.UploadSingleCustomerInspectionImageResponse;
  return proto.document_service.UploadSingleCustomerInspectionImageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.document_service.UploadSingleCustomerInspectionImageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.document_service.UploadSingleCustomerInspectionImageResponse}
 */
proto.document_service.UploadSingleCustomerInspectionImageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInspectionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.document_service.UploadSingleCustomerInspectionImageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.document_service.UploadSingleCustomerInspectionImageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.document_service.UploadSingleCustomerInspectionImageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.document_service.UploadSingleCustomerInspectionImageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInspectionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string inspection_id = 1;
 * @return {string}
 */
proto.document_service.UploadSingleCustomerInspectionImageResponse.prototype.getInspectionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.document_service.UploadSingleCustomerInspectionImageResponse} returns this
 */
proto.document_service.UploadSingleCustomerInspectionImageResponse.prototype.setInspectionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.document_service.UploadCustomerInspectionImageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.document_service.UploadCustomerInspectionImageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.document_service.UploadCustomerInspectionImageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.document_service.UploadCustomerInspectionImageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    inspectionId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.document_service.UploadCustomerInspectionImageResponse}
 */
proto.document_service.UploadCustomerInspectionImageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.document_service.UploadCustomerInspectionImageResponse;
  return proto.document_service.UploadCustomerInspectionImageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.document_service.UploadCustomerInspectionImageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.document_service.UploadCustomerInspectionImageResponse}
 */
proto.document_service.UploadCustomerInspectionImageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInspectionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.document_service.UploadCustomerInspectionImageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.document_service.UploadCustomerInspectionImageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.document_service.UploadCustomerInspectionImageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.document_service.UploadCustomerInspectionImageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInspectionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string inspection_id = 1;
 * @return {string}
 */
proto.document_service.UploadCustomerInspectionImageResponse.prototype.getInspectionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.document_service.UploadCustomerInspectionImageResponse} returns this
 */
proto.document_service.UploadCustomerInspectionImageResponse.prototype.setInspectionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.document_service.UploadVehicleDocumentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.document_service.UploadVehicleDocumentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.document_service.UploadVehicleDocumentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.document_service.UploadVehicleDocumentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    vin: jspb.Message.getFieldWithDefault(msg, 1, ""),
    documentPayload: msg.getDocumentPayload_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.document_service.UploadVehicleDocumentRequest}
 */
proto.document_service.UploadVehicleDocumentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.document_service.UploadVehicleDocumentRequest;
  return proto.document_service.UploadVehicleDocumentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.document_service.UploadVehicleDocumentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.document_service.UploadVehicleDocumentRequest}
 */
proto.document_service.UploadVehicleDocumentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVin(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setDocumentPayload(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.document_service.UploadVehicleDocumentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.document_service.UploadVehicleDocumentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.document_service.UploadVehicleDocumentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.document_service.UploadVehicleDocumentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVin();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDocumentPayload_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * optional string vin = 1;
 * @return {string}
 */
proto.document_service.UploadVehicleDocumentRequest.prototype.getVin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.document_service.UploadVehicleDocumentRequest} returns this
 */
proto.document_service.UploadVehicleDocumentRequest.prototype.setVin = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bytes document_payload = 2;
 * @return {string}
 */
proto.document_service.UploadVehicleDocumentRequest.prototype.getDocumentPayload = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes document_payload = 2;
 * This is a type-conversion wrapper around `getDocumentPayload()`
 * @return {string}
 */
proto.document_service.UploadVehicleDocumentRequest.prototype.getDocumentPayload_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getDocumentPayload()));
};


/**
 * optional bytes document_payload = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getDocumentPayload()`
 * @return {!Uint8Array}
 */
proto.document_service.UploadVehicleDocumentRequest.prototype.getDocumentPayload_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getDocumentPayload()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.document_service.UploadVehicleDocumentRequest} returns this
 */
proto.document_service.UploadVehicleDocumentRequest.prototype.setDocumentPayload = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.document_service.UploadVehicleDocumentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.document_service.UploadVehicleDocumentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.document_service.UploadVehicleDocumentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.document_service.UploadVehicleDocumentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    documentId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.document_service.UploadVehicleDocumentResponse}
 */
proto.document_service.UploadVehicleDocumentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.document_service.UploadVehicleDocumentResponse;
  return proto.document_service.UploadVehicleDocumentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.document_service.UploadVehicleDocumentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.document_service.UploadVehicleDocumentResponse}
 */
proto.document_service.UploadVehicleDocumentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.document_service.UploadVehicleDocumentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.document_service.UploadVehicleDocumentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.document_service.UploadVehicleDocumentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.document_service.UploadVehicleDocumentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDocumentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string document_id = 1;
 * @return {string}
 */
proto.document_service.UploadVehicleDocumentResponse.prototype.getDocumentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.document_service.UploadVehicleDocumentResponse} returns this
 */
proto.document_service.UploadVehicleDocumentResponse.prototype.setDocumentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.document_service.GetVehicleDocumentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.document_service.GetVehicleDocumentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.document_service.GetVehicleDocumentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.document_service.GetVehicleDocumentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    documentId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.document_service.GetVehicleDocumentRequest}
 */
proto.document_service.GetVehicleDocumentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.document_service.GetVehicleDocumentRequest;
  return proto.document_service.GetVehicleDocumentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.document_service.GetVehicleDocumentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.document_service.GetVehicleDocumentRequest}
 */
proto.document_service.GetVehicleDocumentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.document_service.GetVehicleDocumentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.document_service.GetVehicleDocumentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.document_service.GetVehicleDocumentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.document_service.GetVehicleDocumentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDocumentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string document_id = 1;
 * @return {string}
 */
proto.document_service.GetVehicleDocumentRequest.prototype.getDocumentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.document_service.GetVehicleDocumentRequest} returns this
 */
proto.document_service.GetVehicleDocumentRequest.prototype.setDocumentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.document_service.GetVehicleDocumentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.document_service.GetVehicleDocumentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.document_service.GetVehicleDocumentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.document_service.GetVehicleDocumentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    documentPayload: msg.getDocumentPayload_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.document_service.GetVehicleDocumentResponse}
 */
proto.document_service.GetVehicleDocumentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.document_service.GetVehicleDocumentResponse;
  return proto.document_service.GetVehicleDocumentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.document_service.GetVehicleDocumentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.document_service.GetVehicleDocumentResponse}
 */
proto.document_service.GetVehicleDocumentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setDocumentPayload(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.document_service.GetVehicleDocumentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.document_service.GetVehicleDocumentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.document_service.GetVehicleDocumentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.document_service.GetVehicleDocumentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDocumentPayload_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
};


/**
 * optional bytes document_payload = 1;
 * @return {string}
 */
proto.document_service.GetVehicleDocumentResponse.prototype.getDocumentPayload = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes document_payload = 1;
 * This is a type-conversion wrapper around `getDocumentPayload()`
 * @return {string}
 */
proto.document_service.GetVehicleDocumentResponse.prototype.getDocumentPayload_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getDocumentPayload()));
};


/**
 * optional bytes document_payload = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getDocumentPayload()`
 * @return {!Uint8Array}
 */
proto.document_service.GetVehicleDocumentResponse.prototype.getDocumentPayload_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getDocumentPayload()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.document_service.GetVehicleDocumentResponse} returns this
 */
proto.document_service.GetVehicleDocumentResponse.prototype.setDocumentPayload = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.document_service.GetSubscriptionContractToSignRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.document_service.GetSubscriptionContractToSignRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.document_service.GetSubscriptionContractToSignRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.document_service.GetSubscriptionContractToSignRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    customerAccountNumber: jspb.Message.getFieldWithDefault(msg, 2, ""),
    customerFirstName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    customerLastName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    billingAddress: (f = msg.getBillingAddress()) && domain_pb.Address.toObject(includeInstance, f),
    vehicleId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    subscription: (f = msg.getSubscription()) && domain_pb.Subscription.toObject(includeInstance, f),
    inspectionResults: (f = msg.getInspectionResults()) && inspection_pb.InspectionResults.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.document_service.GetSubscriptionContractToSignRequest}
 */
proto.document_service.GetSubscriptionContractToSignRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.document_service.GetSubscriptionContractToSignRequest;
  return proto.document_service.GetSubscriptionContractToSignRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.document_service.GetSubscriptionContractToSignRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.document_service.GetSubscriptionContractToSignRequest}
 */
proto.document_service.GetSubscriptionContractToSignRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerAccountNumber(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerFirstName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerLastName(value);
      break;
    case 5:
      var value = new domain_pb.Address;
      reader.readMessage(value,domain_pb.Address.deserializeBinaryFromReader);
      msg.setBillingAddress(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 7:
      var value = new domain_pb.Subscription;
      reader.readMessage(value,domain_pb.Subscription.deserializeBinaryFromReader);
      msg.setSubscription(value);
      break;
    case 8:
      var value = new inspection_pb.InspectionResults;
      reader.readMessage(value,inspection_pb.InspectionResults.deserializeBinaryFromReader);
      msg.setInspectionResults(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.document_service.GetSubscriptionContractToSignRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.document_service.GetSubscriptionContractToSignRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.document_service.GetSubscriptionContractToSignRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.document_service.GetSubscriptionContractToSignRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCustomerAccountNumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCustomerFirstName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCustomerLastName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getBillingAddress();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      domain_pb.Address.serializeBinaryToWriter
    );
  }
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSubscription();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      domain_pb.Subscription.serializeBinaryToWriter
    );
  }
  f = message.getInspectionResults();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      inspection_pb.InspectionResults.serializeBinaryToWriter
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.document_service.GetSubscriptionContractToSignRequest.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.document_service.GetSubscriptionContractToSignRequest} returns this
 */
proto.document_service.GetSubscriptionContractToSignRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string customer_account_number = 2;
 * @return {string}
 */
proto.document_service.GetSubscriptionContractToSignRequest.prototype.getCustomerAccountNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.document_service.GetSubscriptionContractToSignRequest} returns this
 */
proto.document_service.GetSubscriptionContractToSignRequest.prototype.setCustomerAccountNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string customer_first_name = 3;
 * @return {string}
 */
proto.document_service.GetSubscriptionContractToSignRequest.prototype.getCustomerFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.document_service.GetSubscriptionContractToSignRequest} returns this
 */
proto.document_service.GetSubscriptionContractToSignRequest.prototype.setCustomerFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string customer_last_name = 4;
 * @return {string}
 */
proto.document_service.GetSubscriptionContractToSignRequest.prototype.getCustomerLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.document_service.GetSubscriptionContractToSignRequest} returns this
 */
proto.document_service.GetSubscriptionContractToSignRequest.prototype.setCustomerLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional domain.Address billing_address = 5;
 * @return {?proto.domain.Address}
 */
proto.document_service.GetSubscriptionContractToSignRequest.prototype.getBillingAddress = function() {
  return /** @type{?proto.domain.Address} */ (
    jspb.Message.getWrapperField(this, domain_pb.Address, 5));
};


/**
 * @param {?proto.domain.Address|undefined} value
 * @return {!proto.document_service.GetSubscriptionContractToSignRequest} returns this
*/
proto.document_service.GetSubscriptionContractToSignRequest.prototype.setBillingAddress = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.document_service.GetSubscriptionContractToSignRequest} returns this
 */
proto.document_service.GetSubscriptionContractToSignRequest.prototype.clearBillingAddress = function() {
  return this.setBillingAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.document_service.GetSubscriptionContractToSignRequest.prototype.hasBillingAddress = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string vehicle_id = 6;
 * @return {string}
 */
proto.document_service.GetSubscriptionContractToSignRequest.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.document_service.GetSubscriptionContractToSignRequest} returns this
 */
proto.document_service.GetSubscriptionContractToSignRequest.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional domain.Subscription subscription = 7;
 * @return {?proto.domain.Subscription}
 */
proto.document_service.GetSubscriptionContractToSignRequest.prototype.getSubscription = function() {
  return /** @type{?proto.domain.Subscription} */ (
    jspb.Message.getWrapperField(this, domain_pb.Subscription, 7));
};


/**
 * @param {?proto.domain.Subscription|undefined} value
 * @return {!proto.document_service.GetSubscriptionContractToSignRequest} returns this
*/
proto.document_service.GetSubscriptionContractToSignRequest.prototype.setSubscription = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.document_service.GetSubscriptionContractToSignRequest} returns this
 */
proto.document_service.GetSubscriptionContractToSignRequest.prototype.clearSubscription = function() {
  return this.setSubscription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.document_service.GetSubscriptionContractToSignRequest.prototype.hasSubscription = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional inspection.InspectionResults inspection_results = 8;
 * @return {?proto.inspection.InspectionResults}
 */
proto.document_service.GetSubscriptionContractToSignRequest.prototype.getInspectionResults = function() {
  return /** @type{?proto.inspection.InspectionResults} */ (
    jspb.Message.getWrapperField(this, inspection_pb.InspectionResults, 8));
};


/**
 * @param {?proto.inspection.InspectionResults|undefined} value
 * @return {!proto.document_service.GetSubscriptionContractToSignRequest} returns this
*/
proto.document_service.GetSubscriptionContractToSignRequest.prototype.setInspectionResults = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.document_service.GetSubscriptionContractToSignRequest} returns this
 */
proto.document_service.GetSubscriptionContractToSignRequest.prototype.clearInspectionResults = function() {
  return this.setInspectionResults(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.document_service.GetSubscriptionContractToSignRequest.prototype.hasInspectionResults = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.document_service.GetSubscriptionContractToSignResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.document_service.GetSubscriptionContractToSignResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.document_service.GetSubscriptionContractToSignResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.document_service.GetSubscriptionContractToSignResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    contractUri: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.document_service.GetSubscriptionContractToSignResponse}
 */
proto.document_service.GetSubscriptionContractToSignResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.document_service.GetSubscriptionContractToSignResponse;
  return proto.document_service.GetSubscriptionContractToSignResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.document_service.GetSubscriptionContractToSignResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.document_service.GetSubscriptionContractToSignResponse}
 */
proto.document_service.GetSubscriptionContractToSignResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setContractUri(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.document_service.GetSubscriptionContractToSignResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.document_service.GetSubscriptionContractToSignResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.document_service.GetSubscriptionContractToSignResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.document_service.GetSubscriptionContractToSignResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContractUri();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string contract_uri = 1;
 * @return {string}
 */
proto.document_service.GetSubscriptionContractToSignResponse.prototype.getContractUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.document_service.GetSubscriptionContractToSignResponse} returns this
 */
proto.document_service.GetSubscriptionContractToSignResponse.prototype.setContractUri = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.document_service.CustomerDocumentMeta.prototype.toObject = function(opt_includeInstance) {
  return proto.document_service.CustomerDocumentMeta.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.document_service.CustomerDocumentMeta} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.document_service.CustomerDocumentMeta.toObject = function(includeInstance, msg) {
  var f, obj = {
    documentsByIdMap: (f = msg.getDocumentsByIdMap()) ? f.toObject(includeInstance, undefined) : [],
    contractsMap: (f = msg.getContractsMap()) ? f.toObject(includeInstance, proto.document_service.SubscriptionContract.toObject) : [],
    autoPayAuthorizationsMap: (f = msg.getAutoPayAuthorizationsMap()) ? f.toObject(includeInstance, proto.document_service.Authorizations.toObject) : [],
    insuranceCardsMap: (f = msg.getInsuranceCardsMap()) ? f.toObject(includeInstance, proto.document_service.InsuranceCard.toObject) : [],
    vehicleInspectionsMap: (f = msg.getVehicleInspectionsMap()) ? f.toObject(includeInstance, proto.document_service.Inspections.toObject) : [],
    pickupReceiptMap: (f = msg.getPickupReceiptMap()) ? f.toObject(includeInstance, proto.document_service.PickupReceipt.toObject) : [],
    customerId: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.document_service.CustomerDocumentMeta}
 */
proto.document_service.CustomerDocumentMeta.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.document_service.CustomerDocumentMeta;
  return proto.document_service.CustomerDocumentMeta.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.document_service.CustomerDocumentMeta} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.document_service.CustomerDocumentMeta}
 */
proto.document_service.CustomerDocumentMeta.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getDocumentsByIdMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 2:
      var value = msg.getContractsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.document_service.SubscriptionContract.deserializeBinaryFromReader, "", new proto.document_service.SubscriptionContract());
         });
      break;
    case 3:
      var value = msg.getAutoPayAuthorizationsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.document_service.Authorizations.deserializeBinaryFromReader, "", new proto.document_service.Authorizations());
         });
      break;
    case 4:
      var value = msg.getInsuranceCardsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.document_service.InsuranceCard.deserializeBinaryFromReader, "", new proto.document_service.InsuranceCard());
         });
      break;
    case 5:
      var value = msg.getVehicleInspectionsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.document_service.Inspections.deserializeBinaryFromReader, "", new proto.document_service.Inspections());
         });
      break;
    case 6:
      var value = msg.getPickupReceiptMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.document_service.PickupReceipt.deserializeBinaryFromReader, "", new proto.document_service.PickupReceipt());
         });
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.document_service.CustomerDocumentMeta.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.document_service.CustomerDocumentMeta.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.document_service.CustomerDocumentMeta} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.document_service.CustomerDocumentMeta.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDocumentsByIdMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getContractsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.document_service.SubscriptionContract.serializeBinaryToWriter);
  }
  f = message.getAutoPayAuthorizationsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(3, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.document_service.Authorizations.serializeBinaryToWriter);
  }
  f = message.getInsuranceCardsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(4, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.document_service.InsuranceCard.serializeBinaryToWriter);
  }
  f = message.getVehicleInspectionsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(5, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.document_service.Inspections.serializeBinaryToWriter);
  }
  f = message.getPickupReceiptMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(6, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.document_service.PickupReceipt.serializeBinaryToWriter);
  }
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * map<string, string> documents_by_id = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.document_service.CustomerDocumentMeta.prototype.getDocumentsByIdMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.document_service.CustomerDocumentMeta} returns this
 */
proto.document_service.CustomerDocumentMeta.prototype.clearDocumentsByIdMap = function() {
  this.getDocumentsByIdMap().clear();
  return this;};


/**
 * map<string, SubscriptionContract> contracts = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.document_service.SubscriptionContract>}
 */
proto.document_service.CustomerDocumentMeta.prototype.getContractsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.document_service.SubscriptionContract>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      proto.document_service.SubscriptionContract));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.document_service.CustomerDocumentMeta} returns this
 */
proto.document_service.CustomerDocumentMeta.prototype.clearContractsMap = function() {
  this.getContractsMap().clear();
  return this;};


/**
 * map<string, Authorizations> auto_pay_authorizations = 3;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.document_service.Authorizations>}
 */
proto.document_service.CustomerDocumentMeta.prototype.getAutoPayAuthorizationsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.document_service.Authorizations>} */ (
      jspb.Message.getMapField(this, 3, opt_noLazyCreate,
      proto.document_service.Authorizations));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.document_service.CustomerDocumentMeta} returns this
 */
proto.document_service.CustomerDocumentMeta.prototype.clearAutoPayAuthorizationsMap = function() {
  this.getAutoPayAuthorizationsMap().clear();
  return this;};


/**
 * map<string, InsuranceCard> insurance_cards = 4;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.document_service.InsuranceCard>}
 */
proto.document_service.CustomerDocumentMeta.prototype.getInsuranceCardsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.document_service.InsuranceCard>} */ (
      jspb.Message.getMapField(this, 4, opt_noLazyCreate,
      proto.document_service.InsuranceCard));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.document_service.CustomerDocumentMeta} returns this
 */
proto.document_service.CustomerDocumentMeta.prototype.clearInsuranceCardsMap = function() {
  this.getInsuranceCardsMap().clear();
  return this;};


/**
 * map<string, Inspections> vehicle_inspections = 5;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.document_service.Inspections>}
 */
proto.document_service.CustomerDocumentMeta.prototype.getVehicleInspectionsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.document_service.Inspections>} */ (
      jspb.Message.getMapField(this, 5, opt_noLazyCreate,
      proto.document_service.Inspections));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.document_service.CustomerDocumentMeta} returns this
 */
proto.document_service.CustomerDocumentMeta.prototype.clearVehicleInspectionsMap = function() {
  this.getVehicleInspectionsMap().clear();
  return this;};


/**
 * map<string, PickupReceipt> pickup_receipt = 6;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.document_service.PickupReceipt>}
 */
proto.document_service.CustomerDocumentMeta.prototype.getPickupReceiptMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.document_service.PickupReceipt>} */ (
      jspb.Message.getMapField(this, 6, opt_noLazyCreate,
      proto.document_service.PickupReceipt));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.document_service.CustomerDocumentMeta} returns this
 */
proto.document_service.CustomerDocumentMeta.prototype.clearPickupReceiptMap = function() {
  this.getPickupReceiptMap().clear();
  return this;};


/**
 * optional string customer_id = 7;
 * @return {string}
 */
proto.document_service.CustomerDocumentMeta.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.document_service.CustomerDocumentMeta} returns this
 */
proto.document_service.CustomerDocumentMeta.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.document_service.VehicleDocumentMeta.prototype.toObject = function(opt_includeInstance) {
  return proto.document_service.VehicleDocumentMeta.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.document_service.VehicleDocumentMeta} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.document_service.VehicleDocumentMeta.toObject = function(includeInstance, msg) {
  var f, obj = {
    documentsByIdMap: (f = msg.getDocumentsByIdMap()) ? f.toObject(includeInstance, undefined) : [],
    inspectionsMap: (f = msg.getInspectionsMap()) ? f.toObject(includeInstance, proto.document_service.Inspection.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.document_service.VehicleDocumentMeta}
 */
proto.document_service.VehicleDocumentMeta.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.document_service.VehicleDocumentMeta;
  return proto.document_service.VehicleDocumentMeta.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.document_service.VehicleDocumentMeta} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.document_service.VehicleDocumentMeta}
 */
proto.document_service.VehicleDocumentMeta.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getDocumentsByIdMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 2:
      var value = msg.getInspectionsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.document_service.Inspection.deserializeBinaryFromReader, "", new proto.document_service.Inspection());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.document_service.VehicleDocumentMeta.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.document_service.VehicleDocumentMeta.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.document_service.VehicleDocumentMeta} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.document_service.VehicleDocumentMeta.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDocumentsByIdMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getInspectionsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.document_service.Inspection.serializeBinaryToWriter);
  }
};


/**
 * map<string, string> documents_by_id = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.document_service.VehicleDocumentMeta.prototype.getDocumentsByIdMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.document_service.VehicleDocumentMeta} returns this
 */
proto.document_service.VehicleDocumentMeta.prototype.clearDocumentsByIdMap = function() {
  this.getDocumentsByIdMap().clear();
  return this;};


/**
 * map<string, Inspection> inspections = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.document_service.Inspection>}
 */
proto.document_service.VehicleDocumentMeta.prototype.getInspectionsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.document_service.Inspection>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      proto.document_service.Inspection));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.document_service.VehicleDocumentMeta} returns this
 */
proto.document_service.VehicleDocumentMeta.prototype.clearInspectionsMap = function() {
  this.getInspectionsMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.document_service.SubscriptionContract.prototype.toObject = function(opt_includeInstance) {
  return proto.document_service.SubscriptionContract.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.document_service.SubscriptionContract} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.document_service.SubscriptionContract.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    creationDate: jspb.Message.getFieldWithDefault(msg, 2, 0),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    documentUri: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.document_service.SubscriptionContract}
 */
proto.document_service.SubscriptionContract.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.document_service.SubscriptionContract;
  return proto.document_service.SubscriptionContract.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.document_service.SubscriptionContract} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.document_service.SubscriptionContract}
 */
proto.document_service.SubscriptionContract.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreationDate(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentUri(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.document_service.SubscriptionContract.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.document_service.SubscriptionContract.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.document_service.SubscriptionContract} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.document_service.SubscriptionContract.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreationDate();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDocumentUri();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.document_service.SubscriptionContract.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.document_service.SubscriptionContract} returns this
 */
proto.document_service.SubscriptionContract.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 creation_date = 2;
 * @return {number}
 */
proto.document_service.SubscriptionContract.prototype.getCreationDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.document_service.SubscriptionContract} returns this
 */
proto.document_service.SubscriptionContract.prototype.setCreationDate = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string subscription_id = 3;
 * @return {string}
 */
proto.document_service.SubscriptionContract.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.document_service.SubscriptionContract} returns this
 */
proto.document_service.SubscriptionContract.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string document_uri = 4;
 * @return {string}
 */
proto.document_service.SubscriptionContract.prototype.getDocumentUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.document_service.SubscriptionContract} returns this
 */
proto.document_service.SubscriptionContract.prototype.setDocumentUri = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.document_service.PickupReceipt.prototype.toObject = function(opt_includeInstance) {
  return proto.document_service.PickupReceipt.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.document_service.PickupReceipt} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.document_service.PickupReceipt.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    creationDate: jspb.Message.getFieldWithDefault(msg, 2, 0),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    documentUri: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.document_service.PickupReceipt}
 */
proto.document_service.PickupReceipt.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.document_service.PickupReceipt;
  return proto.document_service.PickupReceipt.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.document_service.PickupReceipt} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.document_service.PickupReceipt}
 */
proto.document_service.PickupReceipt.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreationDate(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentUri(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.document_service.PickupReceipt.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.document_service.PickupReceipt.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.document_service.PickupReceipt} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.document_service.PickupReceipt.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreationDate();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDocumentUri();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.document_service.PickupReceipt.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.document_service.PickupReceipt} returns this
 */
proto.document_service.PickupReceipt.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 creation_date = 2;
 * @return {number}
 */
proto.document_service.PickupReceipt.prototype.getCreationDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.document_service.PickupReceipt} returns this
 */
proto.document_service.PickupReceipt.prototype.setCreationDate = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string subscription_id = 3;
 * @return {string}
 */
proto.document_service.PickupReceipt.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.document_service.PickupReceipt} returns this
 */
proto.document_service.PickupReceipt.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string document_uri = 4;
 * @return {string}
 */
proto.document_service.PickupReceipt.prototype.getDocumentUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.document_service.PickupReceipt} returns this
 */
proto.document_service.PickupReceipt.prototype.setDocumentUri = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.document_service.AutoPayAuthorization.prototype.toObject = function(opt_includeInstance) {
  return proto.document_service.AutoPayAuthorization.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.document_service.AutoPayAuthorization} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.document_service.AutoPayAuthorization.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    creationDate: jspb.Message.getFieldWithDefault(msg, 2, 0),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    documentUri: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.document_service.AutoPayAuthorization}
 */
proto.document_service.AutoPayAuthorization.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.document_service.AutoPayAuthorization;
  return proto.document_service.AutoPayAuthorization.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.document_service.AutoPayAuthorization} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.document_service.AutoPayAuthorization}
 */
proto.document_service.AutoPayAuthorization.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreationDate(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentUri(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.document_service.AutoPayAuthorization.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.document_service.AutoPayAuthorization.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.document_service.AutoPayAuthorization} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.document_service.AutoPayAuthorization.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreationDate();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDocumentUri();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.document_service.AutoPayAuthorization.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.document_service.AutoPayAuthorization} returns this
 */
proto.document_service.AutoPayAuthorization.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 creation_date = 2;
 * @return {number}
 */
proto.document_service.AutoPayAuthorization.prototype.getCreationDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.document_service.AutoPayAuthorization} returns this
 */
proto.document_service.AutoPayAuthorization.prototype.setCreationDate = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string subscription_id = 3;
 * @return {string}
 */
proto.document_service.AutoPayAuthorization.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.document_service.AutoPayAuthorization} returns this
 */
proto.document_service.AutoPayAuthorization.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string document_uri = 4;
 * @return {string}
 */
proto.document_service.AutoPayAuthorization.prototype.getDocumentUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.document_service.AutoPayAuthorization} returns this
 */
proto.document_service.AutoPayAuthorization.prototype.setDocumentUri = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.document_service.Authorizations.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.document_service.Authorizations.prototype.toObject = function(opt_includeInstance) {
  return proto.document_service.Authorizations.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.document_service.Authorizations} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.document_service.Authorizations.toObject = function(includeInstance, msg) {
  var f, obj = {
    authorizationsList: jspb.Message.toObjectList(msg.getAuthorizationsList(),
    proto.document_service.AutoPayAuthorization.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.document_service.Authorizations}
 */
proto.document_service.Authorizations.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.document_service.Authorizations;
  return proto.document_service.Authorizations.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.document_service.Authorizations} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.document_service.Authorizations}
 */
proto.document_service.Authorizations.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.document_service.AutoPayAuthorization;
      reader.readMessage(value,proto.document_service.AutoPayAuthorization.deserializeBinaryFromReader);
      msg.addAuthorizations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.document_service.Authorizations.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.document_service.Authorizations.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.document_service.Authorizations} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.document_service.Authorizations.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuthorizationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.document_service.AutoPayAuthorization.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AutoPayAuthorization authorizations = 1;
 * @return {!Array<!proto.document_service.AutoPayAuthorization>}
 */
proto.document_service.Authorizations.prototype.getAuthorizationsList = function() {
  return /** @type{!Array<!proto.document_service.AutoPayAuthorization>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.document_service.AutoPayAuthorization, 1));
};


/**
 * @param {!Array<!proto.document_service.AutoPayAuthorization>} value
 * @return {!proto.document_service.Authorizations} returns this
*/
proto.document_service.Authorizations.prototype.setAuthorizationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.document_service.AutoPayAuthorization=} opt_value
 * @param {number=} opt_index
 * @return {!proto.document_service.AutoPayAuthorization}
 */
proto.document_service.Authorizations.prototype.addAuthorizations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.document_service.AutoPayAuthorization, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.document_service.Authorizations} returns this
 */
proto.document_service.Authorizations.prototype.clearAuthorizationsList = function() {
  return this.setAuthorizationsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.document_service.InspectionPhoto.prototype.toObject = function(opt_includeInstance) {
  return proto.document_service.InspectionPhoto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.document_service.InspectionPhoto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.document_service.InspectionPhoto.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    label: jspb.Message.getFieldWithDefault(msg, 2, ""),
    documentUri: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.document_service.InspectionPhoto}
 */
proto.document_service.InspectionPhoto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.document_service.InspectionPhoto;
  return proto.document_service.InspectionPhoto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.document_service.InspectionPhoto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.document_service.InspectionPhoto}
 */
proto.document_service.InspectionPhoto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentUri(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.document_service.InspectionPhoto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.document_service.InspectionPhoto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.document_service.InspectionPhoto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.document_service.InspectionPhoto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDocumentUri();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.document_service.InspectionPhoto.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.document_service.InspectionPhoto} returns this
 */
proto.document_service.InspectionPhoto.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string label = 2;
 * @return {string}
 */
proto.document_service.InspectionPhoto.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.document_service.InspectionPhoto} returns this
 */
proto.document_service.InspectionPhoto.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string document_uri = 3;
 * @return {string}
 */
proto.document_service.InspectionPhoto.prototype.getDocumentUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.document_service.InspectionPhoto} returns this
 */
proto.document_service.InspectionPhoto.prototype.setDocumentUri = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.document_service.InspectionReport.prototype.toObject = function(opt_includeInstance) {
  return proto.document_service.InspectionReport.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.document_service.InspectionReport} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.document_service.InspectionReport.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    documentUri: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.document_service.InspectionReport}
 */
proto.document_service.InspectionReport.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.document_service.InspectionReport;
  return proto.document_service.InspectionReport.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.document_service.InspectionReport} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.document_service.InspectionReport}
 */
proto.document_service.InspectionReport.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentUri(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.document_service.InspectionReport.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.document_service.InspectionReport.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.document_service.InspectionReport} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.document_service.InspectionReport.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDocumentUri();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.document_service.InspectionReport.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.document_service.InspectionReport} returns this
 */
proto.document_service.InspectionReport.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string document_uri = 2;
 * @return {string}
 */
proto.document_service.InspectionReport.prototype.getDocumentUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.document_service.InspectionReport} returns this
 */
proto.document_service.InspectionReport.prototype.setDocumentUri = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.document_service.Inspection.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.document_service.Inspection.prototype.toObject = function(opt_includeInstance) {
  return proto.document_service.Inspection.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.document_service.Inspection} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.document_service.Inspection.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    creationDate: jspb.Message.getFieldWithDefault(msg, 2, 0),
    vehicleId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    report: (f = msg.getReport()) && proto.document_service.InspectionReport.toObject(includeInstance, f),
    photosList: jspb.Message.toObjectList(msg.getPhotosList(),
    proto.document_service.InspectionPhoto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.document_service.Inspection}
 */
proto.document_service.Inspection.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.document_service.Inspection;
  return proto.document_service.Inspection.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.document_service.Inspection} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.document_service.Inspection}
 */
proto.document_service.Inspection.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreationDate(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 4:
      var value = new proto.document_service.InspectionReport;
      reader.readMessage(value,proto.document_service.InspectionReport.deserializeBinaryFromReader);
      msg.setReport(value);
      break;
    case 5:
      var value = new proto.document_service.InspectionPhoto;
      reader.readMessage(value,proto.document_service.InspectionPhoto.deserializeBinaryFromReader);
      msg.addPhotos(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.document_service.Inspection.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.document_service.Inspection.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.document_service.Inspection} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.document_service.Inspection.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreationDate();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getReport();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.document_service.InspectionReport.serializeBinaryToWriter
    );
  }
  f = message.getPhotosList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.document_service.InspectionPhoto.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.document_service.Inspection.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.document_service.Inspection} returns this
 */
proto.document_service.Inspection.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 creation_date = 2;
 * @return {number}
 */
proto.document_service.Inspection.prototype.getCreationDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.document_service.Inspection} returns this
 */
proto.document_service.Inspection.prototype.setCreationDate = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string vehicle_id = 3;
 * @return {string}
 */
proto.document_service.Inspection.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.document_service.Inspection} returns this
 */
proto.document_service.Inspection.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional InspectionReport report = 4;
 * @return {?proto.document_service.InspectionReport}
 */
proto.document_service.Inspection.prototype.getReport = function() {
  return /** @type{?proto.document_service.InspectionReport} */ (
    jspb.Message.getWrapperField(this, proto.document_service.InspectionReport, 4));
};


/**
 * @param {?proto.document_service.InspectionReport|undefined} value
 * @return {!proto.document_service.Inspection} returns this
*/
proto.document_service.Inspection.prototype.setReport = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.document_service.Inspection} returns this
 */
proto.document_service.Inspection.prototype.clearReport = function() {
  return this.setReport(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.document_service.Inspection.prototype.hasReport = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated InspectionPhoto photos = 5;
 * @return {!Array<!proto.document_service.InspectionPhoto>}
 */
proto.document_service.Inspection.prototype.getPhotosList = function() {
  return /** @type{!Array<!proto.document_service.InspectionPhoto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.document_service.InspectionPhoto, 5));
};


/**
 * @param {!Array<!proto.document_service.InspectionPhoto>} value
 * @return {!proto.document_service.Inspection} returns this
*/
proto.document_service.Inspection.prototype.setPhotosList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.document_service.InspectionPhoto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.document_service.InspectionPhoto}
 */
proto.document_service.Inspection.prototype.addPhotos = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.document_service.InspectionPhoto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.document_service.Inspection} returns this
 */
proto.document_service.Inspection.prototype.clearPhotosList = function() {
  return this.setPhotosList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.document_service.Inspections.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.document_service.Inspections.prototype.toObject = function(opt_includeInstance) {
  return proto.document_service.Inspections.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.document_service.Inspections} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.document_service.Inspections.toObject = function(includeInstance, msg) {
  var f, obj = {
    inspectionsList: jspb.Message.toObjectList(msg.getInspectionsList(),
    proto.document_service.Inspection.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.document_service.Inspections}
 */
proto.document_service.Inspections.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.document_service.Inspections;
  return proto.document_service.Inspections.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.document_service.Inspections} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.document_service.Inspections}
 */
proto.document_service.Inspections.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.document_service.Inspection;
      reader.readMessage(value,proto.document_service.Inspection.deserializeBinaryFromReader);
      msg.addInspections(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.document_service.Inspections.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.document_service.Inspections.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.document_service.Inspections} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.document_service.Inspections.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInspectionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.document_service.Inspection.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Inspection inspections = 1;
 * @return {!Array<!proto.document_service.Inspection>}
 */
proto.document_service.Inspections.prototype.getInspectionsList = function() {
  return /** @type{!Array<!proto.document_service.Inspection>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.document_service.Inspection, 1));
};


/**
 * @param {!Array<!proto.document_service.Inspection>} value
 * @return {!proto.document_service.Inspections} returns this
*/
proto.document_service.Inspections.prototype.setInspectionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.document_service.Inspection=} opt_value
 * @param {number=} opt_index
 * @return {!proto.document_service.Inspection}
 */
proto.document_service.Inspections.prototype.addInspections = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.document_service.Inspection, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.document_service.Inspections} returns this
 */
proto.document_service.Inspections.prototype.clearInspectionsList = function() {
  return this.setInspectionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.document_service.InsuranceCard.prototype.toObject = function(opt_includeInstance) {
  return proto.document_service.InsuranceCard.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.document_service.InsuranceCard} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.document_service.InsuranceCard.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    creationDate: jspb.Message.getFieldWithDefault(msg, 2, 0),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    documentUri: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.document_service.InsuranceCard}
 */
proto.document_service.InsuranceCard.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.document_service.InsuranceCard;
  return proto.document_service.InsuranceCard.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.document_service.InsuranceCard} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.document_service.InsuranceCard}
 */
proto.document_service.InsuranceCard.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreationDate(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentUri(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.document_service.InsuranceCard.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.document_service.InsuranceCard.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.document_service.InsuranceCard} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.document_service.InsuranceCard.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreationDate();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDocumentUri();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.document_service.InsuranceCard.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.document_service.InsuranceCard} returns this
 */
proto.document_service.InsuranceCard.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 creation_date = 2;
 * @return {number}
 */
proto.document_service.InsuranceCard.prototype.getCreationDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.document_service.InsuranceCard} returns this
 */
proto.document_service.InsuranceCard.prototype.setCreationDate = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string subscription_id = 3;
 * @return {string}
 */
proto.document_service.InsuranceCard.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.document_service.InsuranceCard} returns this
 */
proto.document_service.InsuranceCard.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string document_uri = 4;
 * @return {string}
 */
proto.document_service.InsuranceCard.prototype.getDocumentUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.document_service.InsuranceCard} returns this
 */
proto.document_service.InsuranceCard.prototype.setDocumentUri = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.document_service.InsuranceCards.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.document_service.InsuranceCards.prototype.toObject = function(opt_includeInstance) {
  return proto.document_service.InsuranceCards.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.document_service.InsuranceCards} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.document_service.InsuranceCards.toObject = function(includeInstance, msg) {
  var f, obj = {
    insuranceCardsList: jspb.Message.toObjectList(msg.getInsuranceCardsList(),
    proto.document_service.InsuranceCard.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.document_service.InsuranceCards}
 */
proto.document_service.InsuranceCards.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.document_service.InsuranceCards;
  return proto.document_service.InsuranceCards.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.document_service.InsuranceCards} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.document_service.InsuranceCards}
 */
proto.document_service.InsuranceCards.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.document_service.InsuranceCard;
      reader.readMessage(value,proto.document_service.InsuranceCard.deserializeBinaryFromReader);
      msg.addInsuranceCards(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.document_service.InsuranceCards.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.document_service.InsuranceCards.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.document_service.InsuranceCards} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.document_service.InsuranceCards.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInsuranceCardsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.document_service.InsuranceCard.serializeBinaryToWriter
    );
  }
};


/**
 * repeated InsuranceCard insurance_cards = 1;
 * @return {!Array<!proto.document_service.InsuranceCard>}
 */
proto.document_service.InsuranceCards.prototype.getInsuranceCardsList = function() {
  return /** @type{!Array<!proto.document_service.InsuranceCard>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.document_service.InsuranceCard, 1));
};


/**
 * @param {!Array<!proto.document_service.InsuranceCard>} value
 * @return {!proto.document_service.InsuranceCards} returns this
*/
proto.document_service.InsuranceCards.prototype.setInsuranceCardsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.document_service.InsuranceCard=} opt_value
 * @param {number=} opt_index
 * @return {!proto.document_service.InsuranceCard}
 */
proto.document_service.InsuranceCards.prototype.addInsuranceCards = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.document_service.InsuranceCard, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.document_service.InsuranceCards} returns this
 */
proto.document_service.InsuranceCards.prototype.clearInsuranceCardsList = function() {
  return this.setInsuranceCardsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.document_service.GetOwnDocumentsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.document_service.GetOwnDocumentsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.document_service.GetOwnDocumentsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.document_service.GetOwnDocumentsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.document_service.GetOwnDocumentsRequest}
 */
proto.document_service.GetOwnDocumentsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.document_service.GetOwnDocumentsRequest;
  return proto.document_service.GetOwnDocumentsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.document_service.GetOwnDocumentsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.document_service.GetOwnDocumentsRequest}
 */
proto.document_service.GetOwnDocumentsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.document_service.GetOwnDocumentsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.document_service.GetOwnDocumentsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.document_service.GetOwnDocumentsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.document_service.GetOwnDocumentsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.document_service.GetOwnDocumentsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.document_service.GetOwnDocumentsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.document_service.GetOwnDocumentsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.document_service.GetOwnDocumentsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    contractsMap: (f = msg.getContractsMap()) ? f.toObject(includeInstance, proto.document_service.SubscriptionContract.toObject) : [],
    autoPayAuthorizationsMap: (f = msg.getAutoPayAuthorizationsMap()) ? f.toObject(includeInstance, proto.document_service.Authorizations.toObject) : [],
    insuranceCardsMap: (f = msg.getInsuranceCardsMap()) ? f.toObject(includeInstance, proto.document_service.InsuranceCard.toObject) : [],
    pickupReceiptMap: (f = msg.getPickupReceiptMap()) ? f.toObject(includeInstance, proto.document_service.PickupReceipt.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.document_service.GetOwnDocumentsResponse}
 */
proto.document_service.GetOwnDocumentsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.document_service.GetOwnDocumentsResponse;
  return proto.document_service.GetOwnDocumentsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.document_service.GetOwnDocumentsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.document_service.GetOwnDocumentsResponse}
 */
proto.document_service.GetOwnDocumentsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getContractsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.document_service.SubscriptionContract.deserializeBinaryFromReader, "", new proto.document_service.SubscriptionContract());
         });
      break;
    case 2:
      var value = msg.getAutoPayAuthorizationsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.document_service.Authorizations.deserializeBinaryFromReader, "", new proto.document_service.Authorizations());
         });
      break;
    case 3:
      var value = msg.getInsuranceCardsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.document_service.InsuranceCard.deserializeBinaryFromReader, "", new proto.document_service.InsuranceCard());
         });
      break;
    case 4:
      var value = msg.getPickupReceiptMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.document_service.PickupReceipt.deserializeBinaryFromReader, "", new proto.document_service.PickupReceipt());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.document_service.GetOwnDocumentsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.document_service.GetOwnDocumentsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.document_service.GetOwnDocumentsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.document_service.GetOwnDocumentsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContractsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.document_service.SubscriptionContract.serializeBinaryToWriter);
  }
  f = message.getAutoPayAuthorizationsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.document_service.Authorizations.serializeBinaryToWriter);
  }
  f = message.getInsuranceCardsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(3, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.document_service.InsuranceCard.serializeBinaryToWriter);
  }
  f = message.getPickupReceiptMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(4, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.document_service.PickupReceipt.serializeBinaryToWriter);
  }
};


/**
 * map<string, SubscriptionContract> contracts = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.document_service.SubscriptionContract>}
 */
proto.document_service.GetOwnDocumentsResponse.prototype.getContractsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.document_service.SubscriptionContract>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.document_service.SubscriptionContract));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.document_service.GetOwnDocumentsResponse} returns this
 */
proto.document_service.GetOwnDocumentsResponse.prototype.clearContractsMap = function() {
  this.getContractsMap().clear();
  return this;};


/**
 * map<string, Authorizations> auto_pay_authorizations = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.document_service.Authorizations>}
 */
proto.document_service.GetOwnDocumentsResponse.prototype.getAutoPayAuthorizationsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.document_service.Authorizations>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      proto.document_service.Authorizations));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.document_service.GetOwnDocumentsResponse} returns this
 */
proto.document_service.GetOwnDocumentsResponse.prototype.clearAutoPayAuthorizationsMap = function() {
  this.getAutoPayAuthorizationsMap().clear();
  return this;};


/**
 * map<string, InsuranceCard> insurance_cards = 3;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.document_service.InsuranceCard>}
 */
proto.document_service.GetOwnDocumentsResponse.prototype.getInsuranceCardsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.document_service.InsuranceCard>} */ (
      jspb.Message.getMapField(this, 3, opt_noLazyCreate,
      proto.document_service.InsuranceCard));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.document_service.GetOwnDocumentsResponse} returns this
 */
proto.document_service.GetOwnDocumentsResponse.prototype.clearInsuranceCardsMap = function() {
  this.getInsuranceCardsMap().clear();
  return this;};


/**
 * map<string, PickupReceipt> pickup_receipt = 4;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.document_service.PickupReceipt>}
 */
proto.document_service.GetOwnDocumentsResponse.prototype.getPickupReceiptMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.document_service.PickupReceipt>} */ (
      jspb.Message.getMapField(this, 4, opt_noLazyCreate,
      proto.document_service.PickupReceipt));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.document_service.GetOwnDocumentsResponse} returns this
 */
proto.document_service.GetOwnDocumentsResponse.prototype.clearPickupReceiptMap = function() {
  this.getPickupReceiptMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.document_service.GetOwnDocumentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.document_service.GetOwnDocumentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.document_service.GetOwnDocumentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.document_service.GetOwnDocumentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    documentId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.document_service.GetOwnDocumentRequest}
 */
proto.document_service.GetOwnDocumentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.document_service.GetOwnDocumentRequest;
  return proto.document_service.GetOwnDocumentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.document_service.GetOwnDocumentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.document_service.GetOwnDocumentRequest}
 */
proto.document_service.GetOwnDocumentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.document_service.GetOwnDocumentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.document_service.GetOwnDocumentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.document_service.GetOwnDocumentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.document_service.GetOwnDocumentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDocumentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string document_id = 1;
 * @return {string}
 */
proto.document_service.GetOwnDocumentRequest.prototype.getDocumentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.document_service.GetOwnDocumentRequest} returns this
 */
proto.document_service.GetOwnDocumentRequest.prototype.setDocumentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.document_service.GetOwnDocumentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.document_service.GetOwnDocumentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.document_service.GetOwnDocumentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.document_service.GetOwnDocumentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    documentUri: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.document_service.GetOwnDocumentResponse}
 */
proto.document_service.GetOwnDocumentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.document_service.GetOwnDocumentResponse;
  return proto.document_service.GetOwnDocumentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.document_service.GetOwnDocumentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.document_service.GetOwnDocumentResponse}
 */
proto.document_service.GetOwnDocumentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentUri(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.document_service.GetOwnDocumentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.document_service.GetOwnDocumentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.document_service.GetOwnDocumentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.document_service.GetOwnDocumentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDocumentUri();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string document_uri = 1;
 * @return {string}
 */
proto.document_service.GetOwnDocumentResponse.prototype.getDocumentUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.document_service.GetOwnDocumentResponse} returns this
 */
proto.document_service.GetOwnDocumentResponse.prototype.setDocumentUri = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.document_service.SignContractRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.document_service.SignContractRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.document_service.SignContractRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.document_service.SignContractRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    signatureImage: jspb.Message.getFieldWithDefault(msg, 3, ""),
    inspectionResults: (f = msg.getInspectionResults()) && inspection_pb.InspectionResults.toObject(includeInstance, f),
    customerIpAddress: jspb.Message.getFieldWithDefault(msg, 5, ""),
    contractProps: (f = msg.getContractProps()) && subscription_pb.ContractProperties.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.document_service.SignContractRequest}
 */
proto.document_service.SignContractRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.document_service.SignContractRequest;
  return proto.document_service.SignContractRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.document_service.SignContractRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.document_service.SignContractRequest}
 */
proto.document_service.SignContractRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSignatureImage(value);
      break;
    case 4:
      var value = new inspection_pb.InspectionResults;
      reader.readMessage(value,inspection_pb.InspectionResults.deserializeBinaryFromReader);
      msg.setInspectionResults(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerIpAddress(value);
      break;
    case 6:
      var value = new subscription_pb.ContractProperties;
      reader.readMessage(value,subscription_pb.ContractProperties.deserializeBinaryFromReader);
      msg.setContractProps(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.document_service.SignContractRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.document_service.SignContractRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.document_service.SignContractRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.document_service.SignContractRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSignatureImage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getInspectionResults();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      inspection_pb.InspectionResults.serializeBinaryToWriter
    );
  }
  f = message.getCustomerIpAddress();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getContractProps();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      subscription_pb.ContractProperties.serializeBinaryToWriter
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.document_service.SignContractRequest.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.document_service.SignContractRequest} returns this
 */
proto.document_service.SignContractRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string subscription_id = 2;
 * @return {string}
 */
proto.document_service.SignContractRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.document_service.SignContractRequest} returns this
 */
proto.document_service.SignContractRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string signature_image = 3;
 * @return {string}
 */
proto.document_service.SignContractRequest.prototype.getSignatureImage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.document_service.SignContractRequest} returns this
 */
proto.document_service.SignContractRequest.prototype.setSignatureImage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional inspection.InspectionResults inspection_results = 4;
 * @return {?proto.inspection.InspectionResults}
 */
proto.document_service.SignContractRequest.prototype.getInspectionResults = function() {
  return /** @type{?proto.inspection.InspectionResults} */ (
    jspb.Message.getWrapperField(this, inspection_pb.InspectionResults, 4));
};


/**
 * @param {?proto.inspection.InspectionResults|undefined} value
 * @return {!proto.document_service.SignContractRequest} returns this
*/
proto.document_service.SignContractRequest.prototype.setInspectionResults = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.document_service.SignContractRequest} returns this
 */
proto.document_service.SignContractRequest.prototype.clearInspectionResults = function() {
  return this.setInspectionResults(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.document_service.SignContractRequest.prototype.hasInspectionResults = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string customer_ip_address = 5;
 * @return {string}
 */
proto.document_service.SignContractRequest.prototype.getCustomerIpAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.document_service.SignContractRequest} returns this
 */
proto.document_service.SignContractRequest.prototype.setCustomerIpAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional subscription.ContractProperties contract_props = 6;
 * @return {?proto.subscription.ContractProperties}
 */
proto.document_service.SignContractRequest.prototype.getContractProps = function() {
  return /** @type{?proto.subscription.ContractProperties} */ (
    jspb.Message.getWrapperField(this, subscription_pb.ContractProperties, 6));
};


/**
 * @param {?proto.subscription.ContractProperties|undefined} value
 * @return {!proto.document_service.SignContractRequest} returns this
*/
proto.document_service.SignContractRequest.prototype.setContractProps = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.document_service.SignContractRequest} returns this
 */
proto.document_service.SignContractRequest.prototype.clearContractProps = function() {
  return this.setContractProps(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.document_service.SignContractRequest.prototype.hasContractProps = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.document_service.SignContractResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.document_service.SignContractResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.document_service.SignContractResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.document_service.SignContractResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    creationDate: jspb.Message.getFieldWithDefault(msg, 2, 0),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.document_service.SignContractResponse}
 */
proto.document_service.SignContractResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.document_service.SignContractResponse;
  return proto.document_service.SignContractResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.document_service.SignContractResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.document_service.SignContractResponse}
 */
proto.document_service.SignContractResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreationDate(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.document_service.SignContractResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.document_service.SignContractResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.document_service.SignContractResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.document_service.SignContractResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreationDate();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.document_service.SignContractResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.document_service.SignContractResponse} returns this
 */
proto.document_service.SignContractResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 creation_date = 2;
 * @return {number}
 */
proto.document_service.SignContractResponse.prototype.getCreationDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.document_service.SignContractResponse} returns this
 */
proto.document_service.SignContractResponse.prototype.setCreationDate = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string subscription_id = 3;
 * @return {string}
 */
proto.document_service.SignContractResponse.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.document_service.SignContractResponse} returns this
 */
proto.document_service.SignContractResponse.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.document_service.GeneratePickupReceiptRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.document_service.GeneratePickupReceiptRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.document_service.GeneratePickupReceiptRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.document_service.GeneratePickupReceiptRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    signatureImage: jspb.Message.getFieldWithDefault(msg, 3, ""),
    inspectionResults: (f = msg.getInspectionResults()) && inspection_pb.InspectionResults.toObject(includeInstance, f),
    insuranceexcessmileagefee: jspb.Message.getFieldWithDefault(msg, 5, ""),
    props: (f = msg.getProps()) && subscription_pb.VehiclePickupReceiptProperties.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.document_service.GeneratePickupReceiptRequest}
 */
proto.document_service.GeneratePickupReceiptRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.document_service.GeneratePickupReceiptRequest;
  return proto.document_service.GeneratePickupReceiptRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.document_service.GeneratePickupReceiptRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.document_service.GeneratePickupReceiptRequest}
 */
proto.document_service.GeneratePickupReceiptRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSignatureImage(value);
      break;
    case 4:
      var value = new inspection_pb.InspectionResults;
      reader.readMessage(value,inspection_pb.InspectionResults.deserializeBinaryFromReader);
      msg.setInspectionResults(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setInsuranceexcessmileagefee(value);
      break;
    case 6:
      var value = new subscription_pb.VehiclePickupReceiptProperties;
      reader.readMessage(value,subscription_pb.VehiclePickupReceiptProperties.deserializeBinaryFromReader);
      msg.setProps(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.document_service.GeneratePickupReceiptRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.document_service.GeneratePickupReceiptRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.document_service.GeneratePickupReceiptRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.document_service.GeneratePickupReceiptRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSignatureImage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getInspectionResults();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      inspection_pb.InspectionResults.serializeBinaryToWriter
    );
  }
  f = message.getInsuranceexcessmileagefee();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getProps();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      subscription_pb.VehiclePickupReceiptProperties.serializeBinaryToWriter
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.document_service.GeneratePickupReceiptRequest.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.document_service.GeneratePickupReceiptRequest} returns this
 */
proto.document_service.GeneratePickupReceiptRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string subscription_id = 2;
 * @return {string}
 */
proto.document_service.GeneratePickupReceiptRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.document_service.GeneratePickupReceiptRequest} returns this
 */
proto.document_service.GeneratePickupReceiptRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string signature_image = 3;
 * @return {string}
 */
proto.document_service.GeneratePickupReceiptRequest.prototype.getSignatureImage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.document_service.GeneratePickupReceiptRequest} returns this
 */
proto.document_service.GeneratePickupReceiptRequest.prototype.setSignatureImage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional inspection.InspectionResults inspection_results = 4;
 * @return {?proto.inspection.InspectionResults}
 */
proto.document_service.GeneratePickupReceiptRequest.prototype.getInspectionResults = function() {
  return /** @type{?proto.inspection.InspectionResults} */ (
    jspb.Message.getWrapperField(this, inspection_pb.InspectionResults, 4));
};


/**
 * @param {?proto.inspection.InspectionResults|undefined} value
 * @return {!proto.document_service.GeneratePickupReceiptRequest} returns this
*/
proto.document_service.GeneratePickupReceiptRequest.prototype.setInspectionResults = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.document_service.GeneratePickupReceiptRequest} returns this
 */
proto.document_service.GeneratePickupReceiptRequest.prototype.clearInspectionResults = function() {
  return this.setInspectionResults(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.document_service.GeneratePickupReceiptRequest.prototype.hasInspectionResults = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string insuranceExcessMileageFee = 5;
 * @return {string}
 */
proto.document_service.GeneratePickupReceiptRequest.prototype.getInsuranceexcessmileagefee = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.document_service.GeneratePickupReceiptRequest} returns this
 */
proto.document_service.GeneratePickupReceiptRequest.prototype.setInsuranceexcessmileagefee = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional subscription.VehiclePickupReceiptProperties props = 6;
 * @return {?proto.subscription.VehiclePickupReceiptProperties}
 */
proto.document_service.GeneratePickupReceiptRequest.prototype.getProps = function() {
  return /** @type{?proto.subscription.VehiclePickupReceiptProperties} */ (
    jspb.Message.getWrapperField(this, subscription_pb.VehiclePickupReceiptProperties, 6));
};


/**
 * @param {?proto.subscription.VehiclePickupReceiptProperties|undefined} value
 * @return {!proto.document_service.GeneratePickupReceiptRequest} returns this
*/
proto.document_service.GeneratePickupReceiptRequest.prototype.setProps = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.document_service.GeneratePickupReceiptRequest} returns this
 */
proto.document_service.GeneratePickupReceiptRequest.prototype.clearProps = function() {
  return this.setProps(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.document_service.GeneratePickupReceiptRequest.prototype.hasProps = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.document_service.GeneratePickupReceiptResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.document_service.GeneratePickupReceiptResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.document_service.GeneratePickupReceiptResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.document_service.GeneratePickupReceiptResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    creationDate: jspb.Message.getFieldWithDefault(msg, 2, 0),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.document_service.GeneratePickupReceiptResponse}
 */
proto.document_service.GeneratePickupReceiptResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.document_service.GeneratePickupReceiptResponse;
  return proto.document_service.GeneratePickupReceiptResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.document_service.GeneratePickupReceiptResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.document_service.GeneratePickupReceiptResponse}
 */
proto.document_service.GeneratePickupReceiptResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreationDate(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.document_service.GeneratePickupReceiptResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.document_service.GeneratePickupReceiptResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.document_service.GeneratePickupReceiptResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.document_service.GeneratePickupReceiptResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreationDate();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.document_service.GeneratePickupReceiptResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.document_service.GeneratePickupReceiptResponse} returns this
 */
proto.document_service.GeneratePickupReceiptResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 creation_date = 2;
 * @return {number}
 */
proto.document_service.GeneratePickupReceiptResponse.prototype.getCreationDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.document_service.GeneratePickupReceiptResponse} returns this
 */
proto.document_service.GeneratePickupReceiptResponse.prototype.setCreationDate = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string subscription_id = 3;
 * @return {string}
 */
proto.document_service.GeneratePickupReceiptResponse.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.document_service.GeneratePickupReceiptResponse} returns this
 */
proto.document_service.GeneratePickupReceiptResponse.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.document_service.UnsignedCustomerContractResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.document_service.UnsignedCustomerContractResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.document_service.UnsignedCustomerContractResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.document_service.UnsignedCustomerContractResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    pdfBytes: msg.getPdfBytes_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.document_service.UnsignedCustomerContractResponse}
 */
proto.document_service.UnsignedCustomerContractResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.document_service.UnsignedCustomerContractResponse;
  return proto.document_service.UnsignedCustomerContractResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.document_service.UnsignedCustomerContractResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.document_service.UnsignedCustomerContractResponse}
 */
proto.document_service.UnsignedCustomerContractResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setPdfBytes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.document_service.UnsignedCustomerContractResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.document_service.UnsignedCustomerContractResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.document_service.UnsignedCustomerContractResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.document_service.UnsignedCustomerContractResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPdfBytes_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
};


/**
 * optional bytes pdf_bytes = 1;
 * @return {string}
 */
proto.document_service.UnsignedCustomerContractResponse.prototype.getPdfBytes = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes pdf_bytes = 1;
 * This is a type-conversion wrapper around `getPdfBytes()`
 * @return {string}
 */
proto.document_service.UnsignedCustomerContractResponse.prototype.getPdfBytes_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getPdfBytes()));
};


/**
 * optional bytes pdf_bytes = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPdfBytes()`
 * @return {!Uint8Array}
 */
proto.document_service.UnsignedCustomerContractResponse.prototype.getPdfBytes_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getPdfBytes()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.document_service.UnsignedCustomerContractResponse} returns this
 */
proto.document_service.UnsignedCustomerContractResponse.prototype.setPdfBytes = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.document_service.GetAllCustomerDocumentsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.document_service.GetAllCustomerDocumentsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.document_service.GetAllCustomerDocumentsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.document_service.GetAllCustomerDocumentsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.document_service.GetAllCustomerDocumentsRequest}
 */
proto.document_service.GetAllCustomerDocumentsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.document_service.GetAllCustomerDocumentsRequest;
  return proto.document_service.GetAllCustomerDocumentsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.document_service.GetAllCustomerDocumentsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.document_service.GetAllCustomerDocumentsRequest}
 */
proto.document_service.GetAllCustomerDocumentsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.document_service.GetAllCustomerDocumentsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.document_service.GetAllCustomerDocumentsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.document_service.GetAllCustomerDocumentsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.document_service.GetAllCustomerDocumentsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.document_service.GetAllCustomerDocumentsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.document_service.GetAllCustomerDocumentsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.document_service.GetAllCustomerDocumentsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.document_service.GetAllCustomerDocumentsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.document_service.GetAllCustomerDocumentsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerDocumentsList: jspb.Message.toObjectList(msg.getCustomerDocumentsList(),
    proto.document_service.CustomerDocumentMeta.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.document_service.GetAllCustomerDocumentsResponse}
 */
proto.document_service.GetAllCustomerDocumentsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.document_service.GetAllCustomerDocumentsResponse;
  return proto.document_service.GetAllCustomerDocumentsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.document_service.GetAllCustomerDocumentsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.document_service.GetAllCustomerDocumentsResponse}
 */
proto.document_service.GetAllCustomerDocumentsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.document_service.CustomerDocumentMeta;
      reader.readMessage(value,proto.document_service.CustomerDocumentMeta.deserializeBinaryFromReader);
      msg.addCustomerDocuments(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.document_service.GetAllCustomerDocumentsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.document_service.GetAllCustomerDocumentsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.document_service.GetAllCustomerDocumentsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.document_service.GetAllCustomerDocumentsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerDocumentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.document_service.CustomerDocumentMeta.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CustomerDocumentMeta customer_documents = 1;
 * @return {!Array<!proto.document_service.CustomerDocumentMeta>}
 */
proto.document_service.GetAllCustomerDocumentsResponse.prototype.getCustomerDocumentsList = function() {
  return /** @type{!Array<!proto.document_service.CustomerDocumentMeta>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.document_service.CustomerDocumentMeta, 1));
};


/**
 * @param {!Array<!proto.document_service.CustomerDocumentMeta>} value
 * @return {!proto.document_service.GetAllCustomerDocumentsResponse} returns this
*/
proto.document_service.GetAllCustomerDocumentsResponse.prototype.setCustomerDocumentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.document_service.CustomerDocumentMeta=} opt_value
 * @param {number=} opt_index
 * @return {!proto.document_service.CustomerDocumentMeta}
 */
proto.document_service.GetAllCustomerDocumentsResponse.prototype.addCustomerDocuments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.document_service.CustomerDocumentMeta, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.document_service.GetAllCustomerDocumentsResponse} returns this
 */
proto.document_service.GetAllCustomerDocumentsResponse.prototype.clearCustomerDocumentsList = function() {
  return this.setCustomerDocumentsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.document_service.GetCustomerDocumentsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.document_service.GetCustomerDocumentsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.document_service.GetCustomerDocumentsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.document_service.GetCustomerDocumentsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.document_service.GetCustomerDocumentsRequest}
 */
proto.document_service.GetCustomerDocumentsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.document_service.GetCustomerDocumentsRequest;
  return proto.document_service.GetCustomerDocumentsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.document_service.GetCustomerDocumentsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.document_service.GetCustomerDocumentsRequest}
 */
proto.document_service.GetCustomerDocumentsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.document_service.GetCustomerDocumentsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.document_service.GetCustomerDocumentsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.document_service.GetCustomerDocumentsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.document_service.GetCustomerDocumentsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string customerId = 1;
 * @return {string}
 */
proto.document_service.GetCustomerDocumentsRequest.prototype.getCustomerid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.document_service.GetCustomerDocumentsRequest} returns this
 */
proto.document_service.GetCustomerDocumentsRequest.prototype.setCustomerid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.document_service.GetCustomerDocumentsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.document_service.GetCustomerDocumentsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.document_service.GetCustomerDocumentsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.document_service.GetCustomerDocumentsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    contractsMap: (f = msg.getContractsMap()) ? f.toObject(includeInstance, proto.document_service.SubscriptionContract.toObject) : [],
    autoPayAuthorizationsMap: (f = msg.getAutoPayAuthorizationsMap()) ? f.toObject(includeInstance, proto.document_service.Authorizations.toObject) : [],
    insuranceCardsMap: (f = msg.getInsuranceCardsMap()) ? f.toObject(includeInstance, proto.document_service.InsuranceCard.toObject) : [],
    pickupReceiptMap: (f = msg.getPickupReceiptMap()) ? f.toObject(includeInstance, proto.document_service.PickupReceipt.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.document_service.GetCustomerDocumentsResponse}
 */
proto.document_service.GetCustomerDocumentsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.document_service.GetCustomerDocumentsResponse;
  return proto.document_service.GetCustomerDocumentsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.document_service.GetCustomerDocumentsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.document_service.GetCustomerDocumentsResponse}
 */
proto.document_service.GetCustomerDocumentsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getContractsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.document_service.SubscriptionContract.deserializeBinaryFromReader, "", new proto.document_service.SubscriptionContract());
         });
      break;
    case 2:
      var value = msg.getAutoPayAuthorizationsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.document_service.Authorizations.deserializeBinaryFromReader, "", new proto.document_service.Authorizations());
         });
      break;
    case 3:
      var value = msg.getInsuranceCardsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.document_service.InsuranceCard.deserializeBinaryFromReader, "", new proto.document_service.InsuranceCard());
         });
      break;
    case 4:
      var value = msg.getPickupReceiptMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.document_service.PickupReceipt.deserializeBinaryFromReader, "", new proto.document_service.PickupReceipt());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.document_service.GetCustomerDocumentsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.document_service.GetCustomerDocumentsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.document_service.GetCustomerDocumentsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.document_service.GetCustomerDocumentsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContractsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.document_service.SubscriptionContract.serializeBinaryToWriter);
  }
  f = message.getAutoPayAuthorizationsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.document_service.Authorizations.serializeBinaryToWriter);
  }
  f = message.getInsuranceCardsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(3, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.document_service.InsuranceCard.serializeBinaryToWriter);
  }
  f = message.getPickupReceiptMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(4, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.document_service.PickupReceipt.serializeBinaryToWriter);
  }
};


/**
 * map<string, SubscriptionContract> contracts = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.document_service.SubscriptionContract>}
 */
proto.document_service.GetCustomerDocumentsResponse.prototype.getContractsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.document_service.SubscriptionContract>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.document_service.SubscriptionContract));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.document_service.GetCustomerDocumentsResponse} returns this
 */
proto.document_service.GetCustomerDocumentsResponse.prototype.clearContractsMap = function() {
  this.getContractsMap().clear();
  return this;};


/**
 * map<string, Authorizations> auto_pay_authorizations = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.document_service.Authorizations>}
 */
proto.document_service.GetCustomerDocumentsResponse.prototype.getAutoPayAuthorizationsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.document_service.Authorizations>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      proto.document_service.Authorizations));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.document_service.GetCustomerDocumentsResponse} returns this
 */
proto.document_service.GetCustomerDocumentsResponse.prototype.clearAutoPayAuthorizationsMap = function() {
  this.getAutoPayAuthorizationsMap().clear();
  return this;};


/**
 * map<string, InsuranceCard> insurance_cards = 3;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.document_service.InsuranceCard>}
 */
proto.document_service.GetCustomerDocumentsResponse.prototype.getInsuranceCardsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.document_service.InsuranceCard>} */ (
      jspb.Message.getMapField(this, 3, opt_noLazyCreate,
      proto.document_service.InsuranceCard));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.document_service.GetCustomerDocumentsResponse} returns this
 */
proto.document_service.GetCustomerDocumentsResponse.prototype.clearInsuranceCardsMap = function() {
  this.getInsuranceCardsMap().clear();
  return this;};


/**
 * map<string, PickupReceipt> pickup_receipt = 4;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.document_service.PickupReceipt>}
 */
proto.document_service.GetCustomerDocumentsResponse.prototype.getPickupReceiptMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.document_service.PickupReceipt>} */ (
      jspb.Message.getMapField(this, 4, opt_noLazyCreate,
      proto.document_service.PickupReceipt));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.document_service.GetCustomerDocumentsResponse} returns this
 */
proto.document_service.GetCustomerDocumentsResponse.prototype.clearPickupReceiptMap = function() {
  this.getPickupReceiptMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.document_service.GetCustomerDocumentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.document_service.GetCustomerDocumentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.document_service.GetCustomerDocumentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.document_service.GetCustomerDocumentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    documentId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.document_service.GetCustomerDocumentRequest}
 */
proto.document_service.GetCustomerDocumentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.document_service.GetCustomerDocumentRequest;
  return proto.document_service.GetCustomerDocumentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.document_service.GetCustomerDocumentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.document_service.GetCustomerDocumentRequest}
 */
proto.document_service.GetCustomerDocumentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.document_service.GetCustomerDocumentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.document_service.GetCustomerDocumentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.document_service.GetCustomerDocumentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.document_service.GetCustomerDocumentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDocumentId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string customerId = 1;
 * @return {string}
 */
proto.document_service.GetCustomerDocumentRequest.prototype.getCustomerid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.document_service.GetCustomerDocumentRequest} returns this
 */
proto.document_service.GetCustomerDocumentRequest.prototype.setCustomerid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string document_id = 2;
 * @return {string}
 */
proto.document_service.GetCustomerDocumentRequest.prototype.getDocumentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.document_service.GetCustomerDocumentRequest} returns this
 */
proto.document_service.GetCustomerDocumentRequest.prototype.setDocumentId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.document_service.GetCustomerDocumentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.document_service.GetCustomerDocumentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.document_service.GetCustomerDocumentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.document_service.GetCustomerDocumentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    documentUri: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.document_service.GetCustomerDocumentResponse}
 */
proto.document_service.GetCustomerDocumentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.document_service.GetCustomerDocumentResponse;
  return proto.document_service.GetCustomerDocumentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.document_service.GetCustomerDocumentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.document_service.GetCustomerDocumentResponse}
 */
proto.document_service.GetCustomerDocumentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentUri(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.document_service.GetCustomerDocumentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.document_service.GetCustomerDocumentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.document_service.GetCustomerDocumentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.document_service.GetCustomerDocumentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDocumentUri();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string document_uri = 1;
 * @return {string}
 */
proto.document_service.GetCustomerDocumentResponse.prototype.getDocumentUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.document_service.GetCustomerDocumentResponse} returns this
 */
proto.document_service.GetCustomerDocumentResponse.prototype.setDocumentUri = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.document_service.InitProjectionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.document_service.InitProjectionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.document_service.InitProjectionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.document_service.InitProjectionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    batchSize: jspb.Message.getFieldWithDefault(msg, 1, 0),
    wait: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.document_service.InitProjectionsRequest}
 */
proto.document_service.InitProjectionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.document_service.InitProjectionsRequest;
  return proto.document_service.InitProjectionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.document_service.InitProjectionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.document_service.InitProjectionsRequest}
 */
proto.document_service.InitProjectionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBatchSize(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWait(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.document_service.InitProjectionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.document_service.InitProjectionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.document_service.InitProjectionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.document_service.InitProjectionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBatchSize();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getWait();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional uint32 batch_size = 1;
 * @return {number}
 */
proto.document_service.InitProjectionsRequest.prototype.getBatchSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.document_service.InitProjectionsRequest} returns this
 */
proto.document_service.InitProjectionsRequest.prototype.setBatchSize = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool wait = 2;
 * @return {boolean}
 */
proto.document_service.InitProjectionsRequest.prototype.getWait = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.document_service.InitProjectionsRequest} returns this
 */
proto.document_service.InitProjectionsRequest.prototype.setWait = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.document_service.InitProjectionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.document_service.InitProjectionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.document_service.InitProjectionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.document_service.InitProjectionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.document_service.InitProjectionsResponse}
 */
proto.document_service.InitProjectionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.document_service.InitProjectionsResponse;
  return proto.document_service.InitProjectionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.document_service.InitProjectionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.document_service.InitProjectionsResponse}
 */
proto.document_service.InitProjectionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.document_service.InitProjectionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.document_service.InitProjectionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.document_service.InitProjectionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.document_service.InitProjectionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


goog.object.extend(exports, proto.document_service);
