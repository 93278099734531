import {
  Vehicle,
} from '@nxcr-org/web-api-interface/lib/vehicle_pb'

/**
 * Copied from original table code, I believe it checks if there is a custodies list and attempts to get the last one?
 * @param vehicle
 * @returns
 */
export function getDealer(vehicle: Vehicle.AsObject) {
  return vehicle.custodiesList
    ? vehicle.custodiesList[vehicle.custodiesList.length - 1]
    : undefined
}
