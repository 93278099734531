import { Dealership } from '@nxcr-org/web-api-interface/lib/dealer_pb'
import { Address } from '@nxcr-org/web-api-interface/lib/domain_pb'
import {
  DeleteMilesPurchasedLogRequest,
  GetSubscriptionMileagePurchasesRequest,
  GetSubscriptionMileagePurchasesResponse,
  GetSubscriptionMileageRequest,
  GetSubscriptionMileageResponse,
  LogMilesPurchasedRequest,
} from '@nxcr-org/web-api-interface/lib/fleet_service_pb'
import { NexusServicePromiseClient } from '@nxcr-org/web-api-interface/lib/nexus_service_grpc_web_pb'
import { GetLatestCustodyRequest } from '@nxcr-org/web-api-interface/lib/nexus_service_pb'
import { OktaAuthInterceptor, oktaAuth } from '../../okta/config'
import { spyOnError } from '../../utils/spyOnResponse'
import { Env } from '../fleet-management/api/env'
import { FleetServicePromiseClient } from '@nxcr-org/web-api-interface/lib/gateway_service_grpc_web_pb'

function getNexusService(): NexusServicePromiseClient {
  const idToken = oktaAuth.getIdToken()
  const target = Env.endpoints.fleet

  return new NexusServicePromiseClient(target, null, {
    unaryInterceptors: [new OktaAuthInterceptor(idToken)],
    'grpc-node.max_session_memory': 1000,
    'grpc-node.max_send_message_length': 1024 * 1024 * 100,
    'grpc-node.max_receive_message_length': 1024 * 1024 * 100,
  })
}
function getFleetService(): FleetServicePromiseClient {
  const idToken = oktaAuth.getIdToken()
  const target = Env.endpoints.fleet

  return new FleetServicePromiseClient(target, null, {
    unaryInterceptors: [new OktaAuthInterceptor(idToken)],
    'grpc-node.max_session_memory': 1000,
    'grpc-node.max_send_message_length': 1024 * 1024 * 100,
    'grpc-node.max_receive_message_length': 1024 * 1024 * 100,
  })
}
export function buildGetLatestCustodyRequest(
  params: Pick<GetLatestCustodyRequest.AsObject, 'vehicleId'>
) {
  const request = new GetLatestCustodyRequest()

  request.setCustodyType(GetLatestCustodyRequest.CustodyType.ANY)
  request.setVehicleId(params.vehicleId)

  return request
}

export async function getLatestCustody(
  params: Pick<GetLatestCustodyRequest.AsObject, 'vehicleId'>
) {
  const client = getNexusService()
  const request = buildGetLatestCustodyRequest(params)

  return client
    .getLatestCustody(request)
    .then((res) => {
      return res.toObject()
    })
    .catch(spyOnError('getLatestCustody'))
    .catch(() => {
      return {
        dealership: {
          name: 'Not Available',
          address: {
            street: 'Not Available',
          } as Address.AsObject,
        } as Dealership.AsObject,
      }
    })
}

export async function getSubscriptionMileage(
  subscriptionId: string
): Promise<GetSubscriptionMileageResponse.AsObject> {
  const client = getNexusService()
  const request = new GetSubscriptionMileageRequest()
  request.setSubscriptionId(subscriptionId)

  return client.getSubscriptionMileage(request).then((res) => {
    return res.toObject()
  })
}

export async function getSubscriptionMileagePurchases(
  subscriptionId: string
): Promise<GetSubscriptionMileagePurchasesResponse.AsObject> {
  const client = getNexusService()
  const request = new GetSubscriptionMileagePurchasesRequest()
  request.setSubscriptionId(subscriptionId)

  return client.getSubscriptionMileagePurchases(request).then((res) => {
    return res.toObject()
  })
}

export async function LogMilesPurchased(
  params: LogMilesPurchasedRequest.AsObject
) {
  const client = getFleetService()
  const request = new LogMilesPurchasedRequest()
  request.setVehicleId(params.vehicleId)
  request.setSubscriptionId(params.subscriptionId)
  request.setAmount(params.amount)
  request.setPrice(params.price)
  request.setTime(params.time)
  request.setReferenceId(params.referenceId)

  return client.logMilesPurchased(request).then((res) => {
    return res.toObject()
  })
}

export async function deleteMilesPurchasedLog(id: number) {
  const client = getFleetService()
  const request = new DeleteMilesPurchasedLogRequest()
  request.setId(id)

  return client.deleteMilesPurchasedLog(request).then((res) => {
    return res.toObject()
  })
}
