import React from 'react'
import {
  Box,
  Card,
  Divider,
  Tab,
  Tabs,
  Avatar,
  Typography,
} from '@mui/material'
import { Link, Switch, useLocation, Route } from 'react-router-dom'
import { CustomerContextProvider, useCustomerContext } from './CustomerContext'
import { CustomerInfo } from './CustomerInfo'
import { CustomerInvoices } from './Invoices/CustomerInvoicesComponent'
import { autonomyColor } from '../../global-styles/autonomyColor'
import { CQETab } from './CQE/CQETab'
import { ResetLoginAttemptsButton } from './ResetLoginAttempts/ResetLoginAttemptsButton'

import SubscriptionMileage from './Mileage/SubscriptionMileage'
import CustomerDocuments from './Documents/CustomerDocuments'
import { useEditCustomer } from './useEditCustomer'
import { FormDrawerComponent } from 'shared/FormDrawerComponent'
import { EditCustomer } from './EditCustomer'
import Insurance from './Insurance/Insurance'

const CustomerDetailsTabsWrapper = () => {
  const {
    customerId,
    customerName,
    subscriptionId,
    recurringBillingDay,
    customerInfo: currentCustomer,
  } = useCustomerContext()

  const {
    updateCustomer,
    isEditCustomerModalOpen,
    setIsEditCustomerModalOpen,
  } = useEditCustomer()
  //const { accountBalance } = useGetAccountBalance(customerId)
  const { pathname } = useLocation()

  //const [continueChangeBillingDay, setContinueChangeBillingDay] = React.useState(false)
  const handleClickOpen = () => {
    //setOpen(true)
  }

  //Payments two element that needs refactored
  //const balanceDetails = (
  //  <>
  //    <Typography color={autonomyColor.pink} variant="overline">
  //      Total balance
  //    </Typography>
  //    <Typography variant="h4">
  //      {formatMoney(accountBalance.openBalance)}
  //    </Typography>

  //    <Divider sx={{ my: 2, borderColor: autonomyColor.lightBlue }} />
  //    <Typography color={autonomyColor.pink} variant="overline">
  //      Past due balance
  //    </Typography>
  //    <Typography variant="h4">
  //      {formatMoney(accountBalance.pastDueBalance)}
  //    </Typography>
  //  </>
  //)

  return (
    <>
      <Box
        sx={{
          backgroundColor: 'background.default',
          p: 3,
        }}
      >
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
            backgroundColor: 'background.default',
            p: 3,
          }}
        >
          <Box sx={{ display: 'flex' }}>
            <Avatar
              src={''}
              sx={{
                height: 42,
                width: 42,
              }}
              onClick={() => {
                setIsEditCustomerModalOpen(true)
              }}
            />
            <Box sx={{ ml: 1 }}>
              <Typography
                sx={{ fontSize: 25 }}
                color="inherit"
                variant="subtitle2"
              >
                {customerName}
              </Typography>
              <Typography color={autonomyColor.white} variant="body2">
                user_id: {customerId}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: 'flex' }}>
            {recurringBillingDay != 0 && (
              <Box
                sx={{ ml: 1, marginRight: '60px' }}
                onClick={handleClickOpen}
              >
                <Typography color={autonomyColor.pink} variant="overline">
                  Recurring Billing Day
                </Typography>
                <Typography variant="h4" align={'center'}>
                  {recurringBillingDay}
                </Typography>
              </Box>
            )}
            {/* Payments 2 that needs refactored <Box>balanceDetails</Box>*/}
          </Box>
        </Box>
      </Box>
      <Card>
        <Tabs sx={{ px: 3 }} value={pathname}>
          <Tab
            component={Link}
            label={'Details'}
            value={`/customers/${customerId}`}
            to={`/customers/${customerId}`}
          />
          <Tab
            component={Link}
            label={'Invoices'}
            value={`/customers/${customerId}/invoices`}
            to={`/customers/${customerId}/invoices`}
          />
          <Tab
            component={Link}
            label={'CQE'}
            value={`/customers/${customerId}/cqe`}
            to={`/customers/${customerId}/cqe`}
          />
          <Tab
            component={Link}
            label={'Mileage'}
            value={`/customers/${customerId}/mileage`}
            to={`/customers/${customerId}/mileage`}
          />
          <Tab
            component={Link}
            label={'Documents'}
            value={`/customers/${customerId}/documents`}
            to={`/customers/${customerId}/documents`}
          />
          <Tab
            component={Link}
            label={'Insurance'}
            value={`/customers/${customerId}/insurance`}
            to={`/customers/${customerId}/insurance`}
          />
        </Tabs>

        <Divider />
        <Switch>
          <Route path="/customers/:customerId" exact>
            <CustomerInfo />
          </Route>
          <Route path="/customers/:customerId/invoices">
            <CustomerInvoices />
          </Route>
          <Route path="/customers/:customerId/cqe">
            <CQETab />
          </Route>
          <Route path="/customers/:customerId/mileage">
            {subscriptionId ? (
              <SubscriptionMileage subscriptionId={subscriptionId} />
            ) : null}
          </Route>
          <Route path="/customers/:customerId/documents">
            <CustomerDocuments />
          </Route>
          <Route path="/customers/:customerId/insurance">
            {subscriptionId ? (
              <Insurance
                subscriptionId={subscriptionId}
                customerId={customerId}
              />
            ) : null}
          </Route>
        </Switch>
      </Card>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: 3,
          marginBottom: 5,
        }}
      >
        <ResetLoginAttemptsButton />
      </Box>
      {isEditCustomerModalOpen && (
        <FormDrawerComponent
          isDrawerOpen={isEditCustomerModalOpen}
          setIsDrawerOpen={setIsEditCustomerModalOpen}
          header={'Edit Customer'}
          handleSave={updateCustomer}
          defaultValues={currentCustomer}
          form={({ errors, control }) => {
            return <EditCustomer errors={errors} control={control} />
          }}
        />
      )}
    </>
  )
}

export const CustomerDetailsPage = () => {
  return (
    <CustomerContextProvider>
      <CustomerDetailsTabsWrapper />
    </CustomerContextProvider>
  )
}
