import React, {ReactNode} from 'react'
import {Button, Grid} from '@mui/material'
import {Section} from 'shared/Section'
import {useTeamSettings} from './useTeamSettings'
import {TeamDetailsBlock} from './TeamDetailsBlock/TeamDetailsBlock'
import {IDealership} from '../../utils'
import {TeamMembersBlock} from 'scheduling/settings/blocks/TeamSettings/TeamMembersBlock/TeamMembersBlock'
import {BusinessDaysBlock} from 'scheduling/settings/blocks/TeamSettings/BusinessDaysBlock/BusinessDaysBlock'
import {HolidaysBlock} from 'scheduling/settings/blocks/TeamSettings/HolidaysBlock/HolidaysBlock'

interface TeamSettingsProps {
  dealership: IDealership
  setDealership: ( dealership: IDealership ) => void
  isAdmin: boolean
}

export const TeamSettings: React.FC<TeamSettingsProps> = ({
  dealership,
  setDealership,
  isAdmin
}) => {
  const {
    listDealerships,
    listDealershipUsers,
    businessDays,
    closedDates,
    holidays,
    isEditing,
    isLoading,
    setClosedDates,
    setBusinessDays,
    setIsEditing,
    handleCancellation,
    saveChanges,
  } = useTeamSettings( dealership, setDealership )

  function getHeaderButtons(): ReactNode {
    return (
      <Grid item>
        <Grid
          container
          columnGap={2}>
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              onClick={ () => {
                setIsEditing( !isEditing )
                if ( isEditing ) {
                  handleCancellation()
                }
              }}>
              { !isEditing ? 'Edit' : 'Cancel' }
            </Button>
          </Grid>

          { isEditing &&
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={ () => {
                  saveChanges()
                }}>
                Save
              </Button>
            </Grid>
          }
        </Grid>
      </Grid>
    )
  }

  return (
    <Section
      wrapperSection
      heading="Team Settings"
      headerButtons={isAdmin && getHeaderButtons()}>

      <Section subSection heading="Team Details">
        <TeamDetailsBlock
          listDealerships={listDealerships}
          isLoading={isLoading}
          isEditing={isEditing}
          setDealership={setDealership}
          dealership={dealership}/>
      </Section>
      <Section subSection heading="Business Hours">
        <BusinessDaysBlock
          isLoading={isLoading}
          businessDays={businessDays}
          setBusinessDays={setBusinessDays}
          isEditing={isEditing}
        />
      </Section>
      <Section subSection heading="Holidays & Closed Days">
        <HolidaysBlock
          closedDates={closedDates}
          holidays={holidays}
          openDays={businessDays.map(businessDay => businessDay.dayOfWeek)}
          setClosedDates={setClosedDates}
          isEditing={isEditing}
          isAdmin={isAdmin}
          isLoading={isLoading}
        />
      </Section>
      <Section subSection heading="Team Members">
        <TeamMembersBlock
          dealerships={listDealerships}
          teamMembers={listDealershipUsers}
          isLoading={isLoading}
          isEditing={isEditing}
          dealership={dealership}
        />
      </Section>
    </Section>
  )
}


