import {useQuery} from 'react-query'
import {NexusService} from 'global-apis/nexus-service'
import {useState} from 'react'
import { map } from 'lodash'
import { useQueryParams, withDefault, StringParam } from 'use-query-params'
import {
  getFormattedAppointmentDays,
  getAppointmentsFromCurrentDate
} from './utils'
import { SchedulingService } from '../../global-APIs/nexus-service-scheduling'
import { ListDealershipsResponse } from '@nxcr-org/web-api-interface/lib/nexus_service_pb'

const NXCRDealershipID = 'b1d68de5-0f55-4470-9030-a5d3d7b3d84b'

export const useTasks = () => {
  const [createAppointmentModalIsVisible, setCreateAppointmentModalIsVisible] = useState(false)
  const [{ dealershipId }, setQueryParams] = useQueryParams({
    dealershipId: withDefault(
      StringParam,
      NXCRDealershipID
    ),
  })

  const {
    data: dealershipOptions
  } = useQuery('/listDealerships', () => {
    return SchedulingService.listDealerships()
      .then(handleDealerships)
  }, {
    placeholderData: []
  })

  const { data: appoinments } = useQuery(
    ['/listAppointments', '/listAppointmentsByAccountNumber', dealershipId],
    () => {
      return NexusService.listAppointments({
        dealershipId
      }).then((response) => {
        return getAppointmentsFromCurrentDate(
          getFormattedAppointmentDays(response.appointmentDaysList)
        )
      })
    }
  )

  const selectDealership = (dealershipId: string) => {
    setQueryParams({
      dealershipId,
    })
  }

  function handleClose() {
    setCreateAppointmentModalIsVisible(false)
  }

  return {
    dealershipOptions,
    dealershipId,
    appoinments,
    selectDealership,
    createAppointmentModalIsVisible,
    setCreateAppointmentModalIsVisible,
    handleClose,
  }
}

export function handleDealerships(dealershipResponse: ListDealershipsResponse.AsObject){
  return map(dealershipResponse.dealersList, (dealership) => {
    return {
      label: dealership.name,
      value: dealership.id,
    }
  })
}