import React from 'react'
import { CircularProgress, Container } from '@mui/material'

import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles({
  loadingContainer: {
    marginTop: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '50vh',
  },
})
const Loading = () => {
  const classes = useStyles()
  return (
    <Container className={classes.loadingContainer}>
      <CircularProgress />
    </Container>
  )
}

export default Loading
