export const autonomyColor = {
  red: '#FF3148', // danger
  green: '#00C100', // success
  // Note: I made up these colours to complete the palette, we should replace them with Design approved colours
  orange: '#FF8CDD', // warning > NOTE: in the designs the 'warning' colours are pink, so 'orange' is set to a pink hexcode for now. I think it should be some orange shade but discuss with UX first
  
  //yellows
  yellow: '#ebcc34', // info
  infoPillYellow: 'hsl(60deg 100% 90% / 71%)',

  // Blues
  cyan: '#00DAFF',
  lightBlue: '#00B9BF',
  mutedBlue: '#407085',
  mediumBlue: '#00415C',
  darkBlue: '#05364D',
  lightCyan: '#006785',
  deepBlue: '#0C2939',
  columnHeader: '#1F2937',

  purple: '#B20043',
  pink: '#FF8CDD',

  // Monochrome
  white: '#ffffff',
  gray: '#A6A6A6',
  dark: '#15161E',
  darker: '#1C1C1C',
  black: '#000000',
  lightGray: '#A1A5B3',
}
