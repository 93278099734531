import { makeStyles } from 'tss-react/mui'
import theme from '../../../global-styles'

export const MAP_HEIGHT = {
  LARGE: '93vh',
  SMALL: '40vh',
  MIN: '5vh',
}

export const useMapStyles = makeStyles()({
  mapContainer: {
    width: '100vw',
    height: MAP_HEIGHT.LARGE,
    minHeight: MAP_HEIGHT.LARGE,
    zIndex: 2,
    display: 'block',
    position: 'relative',
    transition: 'all 0.33s ease-out',
  },
  mapBar: {
    backgroundColor: theme.palette.background.default,
    height: '5vh',
    cursor: 'pointer',
    position: 'absolute',
    bottom: 0,
    left: 0,
    zIndex: 100,
    minWidth: '100vw',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '300%',
    lineHeight: '12px',
    borderRadius: '12px 12px 0 0',
  },
})
