import React from 'react'
import {Typography, Grid, Button} from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { useAppointmentBlock } from './useAppointmentBlock'
import {
  AppointmentListItem
} from 'scheduling/tasks/appointment-list/appointment-list-item/AppointmentListItem'
import {
  CreateAppointmentModal
} from 'scheduling/tasks/appointment-modals/create-appointment-modal/CreateAppointmentModal'

interface AppointmentBlockProps {
  reservation: any
}

export const AppointmentBlock: React.FC<AppointmentBlockProps> = ({
  reservation
}) => {
  const {
    listAppointments,
    noOpenAppointments,
    noListedAppointments,
    isLoading,
    createAppointmentModalIsVisible,
    setCreateAppointmentModalIsVisible,
    handleClose,
  } = useAppointmentBlock(reservation.customerInfo.accountNumber)

  const { classes } = useStyles()

  return (
    <Grid item className={classes.root}>
      <Grid container item xs={12} justifyContent="space-between">
        <Typography variant="h3">Appointments</Typography>
        { ( noOpenAppointments || noListedAppointments ) && (
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                setCreateAppointmentModalIsVisible(true)
              }}>
              Create
            </Button>
          </Grid>
        )}
      </Grid>
      { noListedAppointments && (
        <Grid container className={classes.body}>
          <Grid item>
            <Typography>Appointment not yet scheduled</Typography>
          </Grid>
        </Grid>
      )}

      {listAppointments?.map((listAppointments) => (
        listAppointments?.appointments?.map((appointment) => (
          <AppointmentListItem
            key={appointment?.id}
            displayCustomerDetails={false}
            isLoading={isLoading}
            appointment={appointment}/>
        ))
      ))}

      <CreateAppointmentModal
        modalIsOpen={createAppointmentModalIsVisible}
        handleClose={handleClose}
        onReservationPage={true}
        reservation={reservation}
      />
    </Grid>
  )
}

const useStyles = makeStyles()((theme) => {
  return {
    root: {
      margin: theme.spacing(2, 0, 4, 0),
      width: '100%',
    },
    body: {
      marginTop: 24,
      padding: theme.spacing(3),
      backgroundColor: theme.palette.primary.dark,
      borderRadius: 8,
    },
  }
})
