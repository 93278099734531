import { useMutation, useQueryClient } from 'react-query'
import { NexusService } from '../global-APIs/nexus-service'
import toast from 'react-hot-toast'
import { useState } from 'react'

export const useCreatePromoPartner = () => {
  const queryClient = useQueryClient()
  const [newPartnerId, setNewPartnerId] = useState(undefined)

  const { mutate: createPromoPartner, error: createPromoPartnerError } =
    useMutation(
      ({ name }: { name: string }) => {
        return NexusService.createPromoPartner({
          name,
        })
          .then((res) => setNewPartnerId(res.id))
          .catch((err) => {
            console.error({
              message: 'Error',
              err,
              params: {
                name,
              },
            })

            // ! Allow UI to handle but log error so that datadog picks up additional data
            throw err
          })
      },
      {
        onSuccess() {
          toast.success('Promo Partner Created!')
          queryClient.invalidateQueries(['/promopartners'])
          setNewPartnerId(undefined)
        },
        onError() {
          toast.error('Something went wrong with adding new promo partner')
          console.log('Error: ', createPromoPartnerError)
        },
      }
    )

  return {
    createPromoPartner,
    newPartnerId,
    formatPromoPartnerInput,
  }
}

export function formatPromoPartnerInput(partnerInput: string): string {
  if (partnerInput.includes('Add')) {
    return partnerInput.replace('Add ', '').replace(/"/g, '')
  }
  return partnerInput.toUpperCase()
}
