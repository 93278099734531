import { Box, Divider, Grid, Typography } from '@mui/material'
import React from 'react'
import InsuredDrivers from './InsuredDrivers'
import { InsuranceBlock } from '../../fleet-management/components/reservation/blocks/InsuranceBlock/InsuranceBlock'
import { Section } from 'shared/Section'

const Insurance = ({ subscriptionId, customerId }) => {
  return (
    <Grid>
      <Box sx={{ ml: 1, margin: '20px' }}>
        <Typography color="inherit" variant="h4">
          Insurance
        </Typography>
      </Box>
      <Divider />

      <Box sx={{ ml: 1, margin: '20px' }}>
        <Section heading="">
          {subscriptionId ? (
            <InsuranceBlock
              subscriptionId={subscriptionId}
              customerId={customerId}
            />
          ) : null}
        </Section>
      </Box>
      <InsuredDrivers subscriptionId={subscriptionId} />
    </Grid>
  )
}

export default Insurance
