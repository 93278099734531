import { useQuery } from 'react-query'
import { GetCustomerResponse, MapVehicle } from '@nxcr-org/web-api-interface/lib/nexus_service_pb'
import { MapBoxAPI } from '../../global-APIs/map-box'
import { NexusService } from '../../global-APIs/nexus-service'
import { find } from 'lodash'
import { Subscription } from '@nxcr-org/web-api-interface/lib/domain_pb'
import { formatDistanceToNow } from 'date-fns'

type MapCardParams = {
  vehicle: MapVehicle.AsObject
}

export function useMapCard({ vehicle: { vehicle, customer } }: MapCardParams) {
  const { data: location, isLoading: locationIsLoading } = useQuery(
    ['reverseGeoCode', vehicle.locationLat, vehicle.locationLng],
    () => {
      return MapBoxAPI.getAddressFromPoint({
        long: Number.parseFloat(vehicle.locationLng),
        lat: Number.parseFloat(vehicle.locationLat),
      })
    }
  )

  const {
    data,
    isLoading: customerIsLoading
  } = useQuery(['customer', customer?.id ], () => {
    return NexusService.getCustomer({ customerId: customer.id })
      .then(res => {
        return {
          accountNumber: res.customer.accountNumber,
          subscriptionDuration: getSubscriptionDuration(res)
        }
      })
  }, {
    enabled: !!customer
  })

  return {
    location,
    locationIsLoading,
    customerIsLoading,
    accountNumber: data?.accountNumber,
    subscriptionDuration: data?.subscriptionDuration
  }
}

export function getSubscriptionDuration(customer: GetCustomerResponse.AsObject){
  const activeSubscription = find(customer.customer.subscriptionsList, {
    status: Subscription.Status.ACTIVE
  })

  if(!activeSubscription){
    return 'Inactive'
  }

  try {
    return formatDistanceToNow(new Date(activeSubscription.activationDate))
  } catch(err){
    console.log(err)
    return 'N/A'
  }
}
