import React from 'react'
import type { FC, ReactNode } from 'react'
import type { Theme } from '@mui/material'
import { styled } from '@mui/material/styles'
import type { SxProps } from '@mui/system'
import { autonomyColor } from '../../global-styles/autonomyColor'

// TODO: get these from API?
export type StatusPillColor =
  | 'issued'
  | 'due'
  | 'paid in full'
  | 'paid partial'
  | 'past due'
  | 'voided'

interface StatusPillProps {
  children?: ReactNode
  color?: StatusPillColor
  style?: any
  sx?: SxProps<Theme>
}

interface StatusPillRootProps {
  statusState: {
    color: StatusPillColor
  }
}

const StatusPillRoot = styled('span')<StatusPillRootProps>(
  ({ theme }) => {
    // TODO: remove hardcoded color
    const backgroundColor = autonomyColor.infoPillYellow
    const color = autonomyColor.black

    return {
      alignItems: 'center',
      backgroundColor,
      borderRadius: 12,
      color,
      cursor: 'default',
      display: 'inline-flex',
      flexGrow: 0,
      flexShrink: 0,
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.pxToRem(10),
      justifyContent: 'center',
      minWidth: 20,
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      textTransform: 'uppercase',
      whiteSpace: 'nowrap',
    }
  }
)

export const StatusPill: FC<StatusPillProps> = (props) => {
  const { color = 'issued', children, ...other } = props

  const statusState = { color }

  return (
    <StatusPillRoot statusState={statusState} {...other}>
      {children}
    </StatusPillRoot>
  )
}
