import React from 'react'
import {
  Box,
  Card,
  Button,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CardHeader,
  TablePagination,
} from '@mui/material'
import Scrollbar from './Scrollbar'
import { FormDrawerComponent } from '../shared/FormDrawerComponent'
import { usePromotions } from './usePromos'
import { useCreatePromos } from './useCreatePromos'
import { usePromoPartners } from './usePromoPartners'
import { formatDate } from '../../utils/date-formatter'
import { CreatePromoForm } from './CreatePromoDrawer'
import { formatCents } from '../../utils/currency-formatter'

export const Promotions = () => {
  const {
    getPromotionSourceFromEnum,
    promos,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    getPromoPartnerName,
  } = usePromotions()
  const {saveNewPromo, isCreatePromoModalOpen,
    setIsCreatePromoModalOpen,} = useCreatePromos()
  const {promoPartners} = usePromoPartners()

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        p: 3,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          height: '30px',
          marginBottom: '30px',
        }}
      >
        <CardHeader
          sx={{
            padding: 'none',
          }}
          title={'Promotions'}
        />
        <Button
          sx={{ mr: 3 }}
          color="primary"
          variant="contained"
          onClick={() => setIsCreatePromoModalOpen(true)}
        >
          Create Promotion
        </Button>
      </Box>
      <Card>
        <Scrollbar>
          <Table size={'small'}>
            <TableHead>
              <TableRow>
                <TableCell>Code Name</TableCell>
                <TableCell>Promo Amount</TableCell>
                <TableCell>Start</TableCell>
                <TableCell>End</TableCell>
                <TableCell>Partner</TableCell>
                <TableCell>Source Type</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {promos.promotions
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((promo) => (
                  <TableRow hover key={promo?.promotionId}>
                    <TableCell>
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex',
                        }}
                      >
                        <Box sx={{ ml: 1 }}>
                          <Link color="inherit" variant="subtitle2">
                            {promo.promoCode}
                          </Link>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell>
                      {formatCents(promo.rebateValue)}
                    </TableCell>
                    <TableCell>{formatDate(promo.validFrom)}</TableCell>
                    <TableCell>{formatDate(promo.validUntil)}</TableCell>
                    <TableCell>
                      {getPromoPartnerName(
                        promo.sourceId,
                        promoPartners.promoPartners
                      )}
                    </TableCell>
                    <TableCell>
                      {getPromotionSourceFromEnum(promo.source)}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Scrollbar>
        <TablePagination
          component="div"
          count={promos.promotions.length}
          onPageChange={(event, newPage) => setPage(newPage)}
          onRowsPerPageChange={(event) => {
            setRowsPerPage(parseInt(event.target.value, 10))
            setPage(0)
          }}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[10, 25, 50]}
        />
      </Card>
      {isCreatePromoModalOpen && (
        <FormDrawerComponent
          isDrawerOpen={isCreatePromoModalOpen}
          setIsDrawerOpen={setIsCreatePromoModalOpen}
          header={'Create Promo'}
          handleSave={saveNewPromo}
          form={({ errors, control }) => {
            return (
              <CreatePromoForm
                promoPartners={promoPartners}
                errors={errors}
                control={control}
              />
            )
          }}
        />
      )}
    </Box>
  )
}
