import {useState} from 'react'
import {IDate} from 'scheduling/settings/utils'

export const useHolidaysBlock = () => {
  const [rowToDelete, setRowToDelete] = useState<IDate>(null)

  return {
    rowToDelete,
    setRowToDelete,
  }
}

