import { each } from 'lodash'

import { UploadVehicleDocumentResponse } from '@nxcr-org/web-api-interface/lib/document_service_pb'
import {
  GetVehicleRequest,
  GetVehicleResponse,
  ListVehiclesRequest,
  ListVehiclesResponse,
  UpdateStateToInListingRequest,
  UpdateStateToInProcessRequest,
  UpdateStateToSoldRequest,
  UpdateStateToTotalLossRequest,
  VehicleCriteria,
} from '@nxcr-org/web-api-interface/lib/fleet_service_pb'
import { NexusServicePromiseClient } from '@nxcr-org/web-api-interface/lib/nexus_service_grpc_web_pb'
import { ActivityState as VehicleActivityState } from '@nxcr-org/web-api-interface/lib/vehicle_pb'
import { oktaAuth, OktaAuthInterceptor } from '../../../okta/config'
import { DocumentService } from './document-service'
import { Env } from './env'
import { spyOnResponse } from '../../../utils/spyOnResponse'

export const FleetService = {
  getVehicle,
  getVehicles,
  addDocument,
  updateStateToInProcess,
  updateStateToInListing,
  updateStateToSold,
  updateStateToTotalLoss,
}

function nexusClient(): NexusServicePromiseClient {
    const idToken = oktaAuth.getIdToken()
    const target = Env.endpoints.fleet

    return new NexusServicePromiseClient(target, null, {
        unaryInterceptors: [new OktaAuthInterceptor(idToken)],
        'grpc-node.max_session_memory': 1000,
        'grpc-node.max_send_message_length': 1024 * 1024 * 100,
        'grpc-node.max_receive_message_length': 1024 * 1024 * 100,
    })
}

export function buildGetVehiclesRequest(
  params: Partial<ListVehiclesRequest.AsObject> = {
    page: 0,
    customerId: '',
    criteriaList: [],
    resultsPerPage: 25,
    trimPropertiesList: [],
    listByStatus: false,
  }
) {
  const request = new ListVehiclesRequest()

  request.setPage(params.page)
  request.setResultsPerPage(params.resultsPerPage)

  each(params.criteriaList, (crit) => {
    const criteria = new VehicleCriteria()
    criteria.setProperty(crit.property)
    criteria.setQuery(crit.query)
    request.addCriteria(criteria)
  })

  return request
}

async function getVehicles(
  params: Partial<ListVehiclesRequest.AsObject>
): Promise<ListVehiclesResponse.AsObject> {
  const client = nexusClient()
  const request = buildGetVehiclesRequest(params)

  return client
    .listVehicles(request)
    .then(spyOnResponse('Get Listing Vehicles'))
    .then((res: ListVehiclesResponse) => {
      return res.toObject()
    })
}

async function getVehicle(
  vehicleId: string
): Promise<GetVehicleResponse.AsObject> {
  const client = nexusClient()
  const request = new GetVehicleRequest()

  request.setId(vehicleId)

  return client
    .getVehicle(request)
    .then(spyOnResponse('Get vehicle'))
    .then((res: GetVehicleResponse) => res.toObject())
}

export function buildAddDocumentFormData({
  meta,
}: {
  meta?: Record<string, string>
}) {
  const formData = new FormData()

  if (meta) {
    each(meta, (val, key) => {
      formData.append(key, val)
    })
  }

  return formData
}

async function addDocument({
  vin,
  document,
  kind,
  vehicleId,
  meta,
}: AddDocumentParams) {
  return DocumentService.uploadDocument({
    vin,
    document,
  }).then((res: UploadVehicleDocumentResponse) => {
    const body = buildAddDocumentFormData({
      meta,
    })

    return fetch(
      `${
        Env.endpoints.fleetUpload
      }/fleet/upload/${res.getDocumentId()}/${kind}/${vehicleId}`,
      {
        method: 'POST',
        body,
      }
    )
      .then((res) => res.json())
      .then(spyOnResponse('Fetch file'))
      .catch((err) => {
        console.error(err)
      })
  })
}

export function getNextVehicleActivityState(isEligible: boolean) {
  if (isEligible) {
    return VehicleActivityState.ELIGIBLE_FOR_FLEET
  }

  return VehicleActivityState.AGREED_SELLING_PRICE
}

async function updateStateToInProcess(vehicleId: string) {
  const client = nexusClient()
  const request = new UpdateStateToInProcessRequest()
  request.setVehicleId(vehicleId)

  return client.updateVehicleStateToInProcess(request)
}

async function updateStateToInListing(vehicleId: string) {
  const client = nexusClient()
  const request = new UpdateStateToInListingRequest()
  request.setVehicleId(vehicleId)

  return client.updateVehicleStateToInListing(request)
}

async function updateStateToSold(vehicleId: string) {
  const client = nexusClient()
  const request = new UpdateStateToSoldRequest()
  request.setVehicleId(vehicleId)

  return client.updateVehicleStateToSold(request)
}

async function updateStateToTotalLoss(vehicleId: string) {
  const client = nexusClient()
  const request = new UpdateStateToTotalLossRequest()
  request.setVehicleId(vehicleId)

  return client.updateVehicleStateToTotalLoss(request)
}

export interface AddDocumentParams {
  vin: string
  document: File
  kind: string // TODO Use proper document type strings
  vehicleId: string
  meta?: Record<string, string>
}
