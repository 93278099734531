import React, { FC } from 'react'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Grid,
  IconButton,
  Icon,
  CircularProgress,
} from '@mui/material'
import { MapVehicle } from '@nxcr-org/web-api-interface/lib/nexus_service_pb'
import { autonomyColor } from '../../../global-styles/autonomyColor'
import { useMapCard } from './useMapCard'
import { numberFormatter } from '../../../utils/number-formatter'

export const MapCard: FC<
  MapVehicle.AsObject & {
    showDialog: boolean
    closeDialog: () => void
  }
> = ({ vehicle, customer, showDialog, closeDialog }) => {
  const {
    location,
    locationIsLoading,
    customerIsLoading,
    accountNumber,
    subscriptionDuration,
  } = useMapCard({ vehicle: { vehicle, customer } })

  return (
    <Dialog maxWidth="lg" fullWidth open={showDialog} onClose={closeDialog}>
      <DialogTitle
        variant="h2"
        sx={{
          backgroundColor: autonomyColor.mediumBlue,
        }}
      >
        {vehicle.year} {vehicle.make} {vehicle.model}
        <IconButton
          onClick={closeDialog}
          aria-label="close"
          sx={{
            position: 'absolute',
            right: 10,
            top: 10,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Icon>close</Icon>
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          backgroundColor: autonomyColor.mediumBlue,
        }}
      >
        <Grid
          container
          spacing={2}
          alignContent="space-between"
          sx={{
            backgroundColor: autonomyColor.darkBlue,
            padding: 1,
          }}
        >
          <Grid xs={1} item>
            <Typography variant="caption">Account #</Typography>
            {customerIsLoading ? (
              <CircularProgress />
            ) : (
              <Typography>{accountNumber || 'N/A'}</Typography>
            )}
          </Grid>
          <Grid xs={2} item>
            <Typography variant="caption">VIN</Typography>
            <Typography>{vehicle.vin}</Typography>
          </Grid>
          <Grid xs={1} item>
            <Typography variant="caption">Color</Typography>
            <Typography>{vehicle.color}</Typography>
          </Grid>
          <Grid xs={1} item>
            <Typography variant="caption">Odometer</Typography>
            <Typography>
              {numberFormatter.format(vehicle.odometerReading)}
            </Typography>
          </Grid>
          <Grid xs={2} item>
            <Typography variant="caption">Customer</Typography>
            <Typography>{customer.name}</Typography>
          </Grid>
          <Grid xs={3} item>
            <Typography variant="caption">Location</Typography>
            {locationIsLoading ? (
              <CircularProgress />
            ) : (
              <Typography>{location?.place_name}</Typography>
            )}
          </Grid>
          <Grid flex={3} item>
            <Typography variant="caption">Subscription Duration</Typography>
            {customerIsLoading ? (
              <CircularProgress />
            ) : (
              <Typography sx={{ textTransform: 'capitalize' }}>
                {subscriptionDuration || 'N/A'}
              </Typography>
            )}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}
