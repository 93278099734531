import React from 'react'
import ReactDom from 'react-dom'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import createCache from '@emotion/cache'
import { CacheProvider } from '@emotion/react'
import { withLDProvider } from 'launchdarkly-react-client-sdk'
import {
  QueryParamProvider,
  transformSearchStringJsonSafe,
} from 'use-query-params'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'


import Routes from './routes'
import { createTheme } from '../global-styles/nexus-theme'
import { Toaster } from 'react-hot-toast'

const muiCache = createCache({
  key: 'mui',
  prepend: true,
})


const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      staleTime: 10 * 1000 // Time in milliseconds
    }
  }
})
const queryStringifyOptions = {
  transformSearchString: transformSearchStringJsonSafe,
}

const App = () => {
  const pmtTheme = createTheme({})

  return (
    <QueryClientProvider client={queryClient}>
      <QueryParamProvider
        ReactRouterRoute={Route}
        stringifyOptions={queryStringifyOptions}
      >
        <CacheProvider value={muiCache}>
          <ThemeProvider theme={pmtTheme}>
            <CssBaseline />
            <Toaster position="top-center" />
            <Routes />
            <ReactQueryDevtools />
          </ThemeProvider>
        </CacheProvider>
      </QueryParamProvider>
    </QueryClientProvider>
  )
}

const LDApp = withLDProvider({
  clientSideID: process.env.LD_KEY,
  deferInitialization: false,
})(App)

ReactDom.render(
  <Router>
    <LDApp />
  </Router>,
  document.getElementById('root')
)
