import { format } from 'date-fns'

export function formatDate(date: number | Date): string {
  if (date === 0 || date == undefined || date == null) {
    return 'Not Available'
  }
  return format(date, 'MM/dd/yyyy')
}

export const getCurrentTimestamp = (unit = 'seconds') => {
  // Get the current date and time
  const now = new Date()

  // Get the timestamp in milliseconds since epoch
  const timestampMilliseconds = now.getTime()

  // Return the timestamp in the specified unit
  if (unit === 'milliseconds') {
    return timestampMilliseconds
  } else if (unit === 'seconds') {
    return Math.floor(timestampMilliseconds / 1000)
  }
  throw new Error('Invalid unit. Please specify "seconds" or "milliseconds".')
}
export const convertDateToUnixTimestamp = (date, unit = 'seconds') => {
  // Parse the date if it's a string
  const parsedDate = typeof date === 'string' ? new Date(date) : date

  // Check if the parsed date is valid
  if (isNaN(parsedDate.getTime())) {
    throw new Error(
      'Invalid date. Please provide a valid date string or Date object.'
    )
  }

  // Get the timestamp in milliseconds since epoch
  const timestampMilliseconds = parsedDate.getTime()

  // Return the timestamp in the specified unit
  if (unit === 'milliseconds') {
    return timestampMilliseconds
  } else if (unit === 'seconds') {
    return Math.floor(timestampMilliseconds / 1000)
  }
  throw new Error('Invalid unit. Please specify "seconds" or "milliseconds".')
}
