import { TypographyOptions } from '@mui/material/styles/createTypography'
import { palette } from './palette'

export const typography: TypographyOptions = {
  fontFamily: ['"DM Sans"', '"Helvetica Neue"', 'sans-serif',].join(','),
  h1: {
    fontSize: 30,
    fontWeight: 700,
  },
  h3: {
    fontSize: 24,
    fontWeight: 500,
  },
  h4: {
    fontSize: 20,
    fontWeight: 500,
  },
  h2: {
    fontSize: 24,    
    fontWeight: 500,
  },
  caption: {
    color: palette.text.caption,
    fontSize: 14,
    '&.largeCaption': {
      fontSize: 15,
    }
  }
}
