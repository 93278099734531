export function shouldDisableDate(
  date: Date,
  disabledDates: Date[] = [],
  openDays: string[] = [],
  value?: Date,
  minDate?: Date,
  maxDate?: Date
) {
  const dateWithoutTime = new Date( date )
  dateWithoutTime.setHours(0, 0, 0, 0)
  // Disable dates falling outside of the allowed range
  if ( !!minDate && dateWithoutTime.getTime() < minDate.getTime() ) {
    return true
  }
  if ( !!maxDate && dateWithoutTime.getTime() > maxDate.getTime() ) {
    return true
  }

  // Disable date if it can be found in the array of disabled dates (unless it is the already selected date)
  const disabledDate = disabledDates.find( dd => dd.getTime() === dateWithoutTime.getTime() )
  if ( disabledDate ) {
    return disabledDate.getTime() !== value.getTime()
  }

  // Disable if this day isn't an open day ( a regular closed day of the week )
  const day = dateWithoutTime.toLocaleDateString('en-US', { weekday: 'long'})
  if ( openDays.length ) {
    return !(openDays.indexOf( day ) !== -1)
  }
  return false
}
