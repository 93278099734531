import React from 'react'
import { Box, TextField } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { Controller } from 'react-hook-form'
import { FormError } from 'shared/FormError'

interface EditCustomerFormProps {
  errors
  control
}

export const EditCustomer: React.FC<EditCustomerFormProps> = ({
  errors,
  control,
}) => {
  return (
    <Box>
      <Controller
        name="firstName"
        control={control}
        rules={{ required: 'This field is required', max: 100 }}
        render={({ field: { onChange, ...rest } }) => {
          return (
            <TextField
              fullWidth
              label="First Name"
              margin="normal"
              onChange={onChange}
              {...rest}
            />
          )
        }}
      />
      {errors?.firstName && <FormError text={errors.firstName.message} />}

      <Controller
        name="lastName"
        control={control}
        rules={{
          required: 'This field is required',
          max: 100,
        }}
        render={({ field: { onChange, ...rest } }) => (
          <TextField
            fullWidth
            label="Last Name"
            margin="normal"
            onChange={onChange}
            {...rest}
          />
        )}
      />
      {errors?.lastName && <FormError text={errors.lastName.message} />}

      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Controller
          name="phone"
          control={control}
          rules={{
            required: 'This field is Required',
            pattern: {
              value: /^\+?[1-9]\d{1,14}$/,
              message:
                'Phone format includes the country code and then numeric only ie +10000000000',
            },
          }}
          defaultValue={null}
          render={({ field: { onChange, ...rest } }) => (
            <TextField
              fullWidth
              label="Phone Number"
              margin="normal"
              onChange={onChange}
              {...rest}
            />
          )}
        />
      </LocalizationProvider>
      {errors?.phone && <FormError text={errors.phone.message} />}

      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Controller
          name="email"
          control={control}
          rules={{
            required: 'This field is Required',
            pattern: {
              value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
              message: 'Invalid email address',
            },
          }}
          defaultValue={null}
          render={({ field: { onChange, ...rest } }) => (
            <TextField
              fullWidth
              label="Email"
              margin="normal"
              onChange={onChange}
              {...rest}
            />
          )}
        />
      </LocalizationProvider>
      {errors?.email && <FormError text={errors.email.message} />}
    </Box>
  )
}
