import React from 'react'
import {
  Typography,
  Switch,
  FormControlLabel,
  FormGroup,
  Grid,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress, Box
} from '@mui/material'
import {
  formattedDaysOfWeek,
  getTime,
  isOpen,
  useBusinessDaysBlock
} from './useBusinessDaysBlock'
import { Checkbox } from '@mui/material'
import {AutonomyTimePicker} from 'shared/AutonomyTimePicker/AutonomyTimePicker'
import format from 'date-fns/format'
import {IDayOfWeek} from 'scheduling/settings/utils'

interface BusinessDaysBlockProps {
  businessDays: IDayOfWeek[]
  setBusinessDays: ( businessDays: IDayOfWeek[] ) => void
  isEditing: boolean
  isLoading: boolean
}

export const BusinessDaysBlock: React.FC<BusinessDaysBlockProps> = ({
  businessDays,
  setBusinessDays,
  isEditing,
  isLoading,
}) => {
  const {
    startTime,
    endTime,
    defaultOpeningHours,
    defaultClosingHours,
    openingRangeStart,
    openingRangeEnd,
    closingRangeStart,
    closingRangeEnd,
    isOpenSameTimeEveryDay,
    setIsOpenSameTimeEveryDay,
    setStartTime,
    setEndTime
  } = useBusinessDaysBlock( businessDays, setBusinessDays )

  return (
    isLoading ? (
      <Box sx={{ width: '100%', textAlign: 'center' }}>
        <CircularProgress/>
      </Box>
    ) : (
      <Grid
        container
        direction="column">

        { isEditing &&
          <Grid item container direction="row" spacing={5}>
            <Grid item>
              <FormGroup>
                <FormControlLabel
                  className="checkboxLabel"
                  control={
                    <Checkbox
                      checked={isOpenSameTimeEveryDay}
                      onChange={ e => setIsOpenSameTimeEveryDay( e.target.checked )}/>
                  } label="Same Time Every Day"/>
              </FormGroup>
            </Grid>
            <Grid item>
              <AutonomyTimePicker
                value={startTime}
                disabled={!isOpenSameTimeEveryDay}
                rangeStart={openingRangeStart}
                rangeEnd={openingRangeEnd}
                defaultTime={defaultOpeningHours}
                onChange={( time: string ) => {
                  setStartTime( time )
                  const updatedBusinessDays = businessDays.map( businessDay => ({
                    ...businessDay,
                    startTime: time
                  }))

                  setBusinessDays( updatedBusinessDays )
                }}/>
            </Grid>
            <Grid item>
              <Typography variant="caption">To</Typography>
            </Grid>
            <Grid item>
              <AutonomyTimePicker
                value={endTime}
                disabled={!isOpenSameTimeEveryDay}
                rangeStart={closingRangeStart}
                rangeEnd={closingRangeEnd}
                defaultTime={defaultClosingHours}
                onChange={( time: string ) => {
                  setEndTime(time)
                  const updatedBusinessDays = businessDays.map( businessDay => ({
                    ...businessDay,
                    endTime: time
                  }))

                  setBusinessDays( updatedBusinessDays )
                }}/>
            </Grid>
          </Grid>
        }

        <Grid item>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant="caption">Day open</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="caption">Start time</Typography>
                </TableCell>
                <TableCell/>
                <TableCell>
                  <Typography variant="caption">End time</Typography>
                </TableCell>
              </TableRow>
            </TableHead>

            { isEditing ? (
              <TableBody>
                {formattedDaysOfWeek.map((row) => (
                  <TableRow key={row.dayOfWeek}>
                    <TableCell width="15%">
                      <FormGroup>
                        <FormControlLabel
                          className={`switchLabel ${!isOpen( row.dayOfWeek, businessDays ) && 'muted'}`}
                          control={
                            <Switch
                              color="primary"
                              checked={isOpen( row.dayOfWeek, businessDays )}
                              onChange={ () => {
                                if ( isOpen( row.dayOfWeek, businessDays ) ) {
                                  // remove this business day from the array
                                  setBusinessDays( businessDays.filter( bd => bd.dayOfWeek !== row.dayOfWeek ) )
                                } else {
                                  // add a business day to the array
                                  const updatedBusinessDays = [...businessDays, {
                                    dayOfWeek: row.dayOfWeek,
                                    startTime: format(defaultOpeningHours, 'HH:mm'),
                                    endTime: format(defaultClosingHours, 'HH:mm'),
                                    timezone: 'UTC'
                                  }]
                                  setBusinessDays( updatedBusinessDays )
                                }
                              }}/>
                          }
                          label={row.dayOfWeek} />
                      </FormGroup>
                    </TableCell>
                    <TableCell width="10%">
                      <AutonomyTimePicker
                        value={getTime(row.dayOfWeek, businessDays)}
                        disabled={isOpenSameTimeEveryDay || !isOpen( row.dayOfWeek, businessDays )}
                        rangeStart={openingRangeStart}
                        rangeEnd={openingRangeEnd}
                        defaultTime={defaultOpeningHours}
                        onChange={( time: string ) => {
                          const index = businessDays.findIndex( businessDay => businessDay.dayOfWeek === row.dayOfWeek )
                          const updatedBusinessDays = [
                            ...businessDays.slice(0, index),
                            {
                              ...businessDays[index],
                              startTime: time
                            },
                            ... businessDays.slice(index + 1)
                          ]
                          setBusinessDays( updatedBusinessDays )
                        }}/>
                    </TableCell>
                    <TableCell width="5%" align="center">
                      <Typography variant="caption">to</Typography>
                    </TableCell>
                    <TableCell width="70%">
                      <AutonomyTimePicker
                        value={getTime(row.dayOfWeek, businessDays, 'endTime')}
                        disabled={isOpenSameTimeEveryDay || !isOpen( row.dayOfWeek, businessDays )}
                        rangeStart={closingRangeStart}
                        rangeEnd={closingRangeEnd}
                        defaultTime={defaultClosingHours}
                        onChange={( time: string ) => {
                          const index = businessDays.findIndex( businessDay => businessDay.dayOfWeek === row.dayOfWeek )
                          const updatedBusinessDays = [
                            ...businessDays.slice(0, index),
                            {
                              ...businessDays[index],
                              endTime: time
                            },
                            ... businessDays.slice(index + 1)
                          ]
                          setBusinessDays( updatedBusinessDays )
                        }}/>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            ):(
              <TableBody>
                { businessDays.map((row) => (
                  <TableRow key={row.dayOfWeek}>
                    <TableCell width="15%">
                      <Typography>
                        { row.dayOfWeek }
                      </Typography>
                    </TableCell>
                    <TableCell width="6%">
                      <Typography>
                        { row.startTime }
                      </Typography>
                    </TableCell>
                    <TableCell width="4%" align="center">
                      <Typography variant="caption">to</Typography>
                    </TableCell>
                    <TableCell width="75%">
                      <Typography>
                        { row.endTime }
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
        </Grid>
      </Grid>
    )
  )
}
