import { formatDate } from '../../utils/date-formatter'
import {
  StatementStatus,
  Transaction,
  LineItem,
  Address,
} from '@nxcr-org/web-api-interface/lib/domain_pb'
import { GetReservationResponse } from '@nxcr-org/web-api-interface/lib/subscription_service_pb'
import {
  formattedStatementStatus,
  formattedAddressType,
} from '../fleet-management/api/reservation.formatted-enums'

export function checkForNull(value) {
  if (value === undefined || value === null || value === '' || value == ' ') {
    return 'Not Available'
  }
  return value
}

export function formattedCustomerName(
  reservation: GetReservationResponse.AsObject
): string {
  return `${reservation?.customer?.customerInfo?.firstName} ${reservation?.customer?.customerInfo?.lastName}`
}

export function formattedPhoneNumber(number) {
  if (number !== 'Not Available') {
    const tenDigitNum = number.substr(-10)
    return `(${tenDigitNum.substr(0, 3)}) ${tenDigitNum.substr(
      3,
      3
    )} - ${tenDigitNum.substr(-4)}`
  }

  return number
}

export function setStatementStatus(statementStatusEnum: number): string {
  if (statementStatusEnum === StatementStatus.PENDING) {
    return formattedStatementStatus.PENDING.label
  }
  if (statementStatusEnum === StatementStatus.PAID) {
    return formattedStatementStatus.PAID.label
  }
  if (statementStatusEnum === StatementStatus.OVERDUE) {
    return formattedStatementStatus.OVERDUE.label
  }
  if (statementStatusEnum === StatementStatus.DUE) {
    return formattedStatementStatus.DUE.label
  }
  return formattedStatementStatus.CANCELLED.label
}

export function getTransactionId(statement, status): string {
  if (status === StatementStatus.PAID && statement.transactionsList.length) {
    return statement.transactionsList.find(
      ({ transactionType }) =>
        transactionType === Transaction.TransactionType.CAPTURE
    )?.gatewayTransactionId
  }

  return null
}

export function formatAddress(
  addresses: GetReservationResponse.AsObject['customer']['customerInfo']['addressesList'],
  addressTypeEnum: number
): string {
  const address = addresses.find(
    (address) => address.addressType === addressTypeEnum
  )
  return address
    ? `${address.street}, ${address.street2 ? address.street2 : ''}, ${
        address.city
      }, ${address.state}, ${address.postal}`
    : 'Not Available'
}

export function getAddressType(addressTypeEnum: number): string {
  if (addressTypeEnum === Address.AddressType.PARKING) {
    return formattedAddressType.PARKING.label
  }
  if (addressTypeEnum === Address.AddressType.LICENSE) {
    return formattedAddressType.LICENSE.label
  }
  return formattedAddressType.CONTACT.label
}

export function calculateStatementTotalAmount(
  lineItems: LineItem.AsObject[]
): number {
  return lineItems.reduce((acc, lineItem) => {
    return acc + lineItem.amount
  }, 0)
}

export function calculateRemainingStatementBalance(
  lineItems: LineItem.AsObject[]
): number {
  return lineItems.reduce((acc, lineItem) => {
    return acc + lineItem.balance
  }, 0)
}

export function formattedCustomerInfo(
  reservation: GetReservationResponse.AsObject
): FormattedCustomerInfo {
  return {
    firstName: checkForNull(reservation?.customer?.customerInfo?.firstName),
    lastName: checkForNull(reservation?.customer?.customerInfo?.lastName),
    email: checkForNull(reservation?.customer?.customerInfo?.email),

    phone: checkForNull(reservation?.customer?.customerInfo?.phone),
    parkingAddress: formatAddress(
      reservation?.customer?.customerInfo?.addressesList,
      Address.AddressType.PARKING
    ),

    licenseAddress: formatAddress(
      reservation?.customer?.customerInfo?.addressesList,
      Address.AddressType.LICENSE
    ),

    contactAddress: formatAddress(
      reservation?.customer?.customerInfo?.addressesList,
      Address.AddressType.CONTACT
    ),

    accountNumber: checkForNull(reservation?.customer?.accountNumber),

    liscenceExpirationDate: formatDate(
      reservation?.customer?.customerInfo?.licenseExpirationDate
    ),
  }
}

export type FormattedCustomerInfo = {
  firstName: string
  lastName: string
  accountNumber: string
  parkingAddress: string
  licenseAddress: string
  contactAddress: string
  email: string
  phone: string
  liscenceExpirationDate: string
}

export interface LineItems {
  title: string
  amount: number
  balance: number
  id: string
}
