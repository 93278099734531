// source: contract_service.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.contract_service.AddTemplateRequest', null, global);
goog.exportSymbol('proto.contract_service.AddTemplateResponse', null, global);
goog.exportSymbol('proto.contract_service.ContractProperties', null, global);
goog.exportSymbol('proto.contract_service.ContractType', null, global);
goog.exportSymbol('proto.contract_service.CustomerContractMeta', null, global);
goog.exportSymbol('proto.contract_service.GetCustomerContractsRequest', null, global);
goog.exportSymbol('proto.contract_service.GetCustomerContractsResponse', null, global);
goog.exportSymbol('proto.contract_service.LegalCopy', null, global);
goog.exportSymbol('proto.contract_service.PickupReceipt', null, global);
goog.exportSymbol('proto.contract_service.PreviewCustomerContractResponse', null, global);
goog.exportSymbol('proto.contract_service.SignContractRequest', null, global);
goog.exportSymbol('proto.contract_service.SignContractResponse', null, global);
goog.exportSymbol('proto.contract_service.SubscriptionContract', null, global);
goog.exportSymbol('proto.contract_service.SubscriptionLegalCopy', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.contract_service.SignContractRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.contract_service.SignContractRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.contract_service.SignContractRequest.displayName = 'proto.contract_service.SignContractRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.contract_service.ContractProperties = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.contract_service.ContractProperties, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.contract_service.ContractProperties.displayName = 'proto.contract_service.ContractProperties';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.contract_service.SignContractResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.contract_service.SignContractResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.contract_service.SignContractResponse.displayName = 'proto.contract_service.SignContractResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.contract_service.AddTemplateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.contract_service.AddTemplateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.contract_service.AddTemplateRequest.displayName = 'proto.contract_service.AddTemplateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.contract_service.LegalCopy = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.contract_service.LegalCopy, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.contract_service.LegalCopy.displayName = 'proto.contract_service.LegalCopy';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.contract_service.SubscriptionLegalCopy = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.contract_service.SubscriptionLegalCopy.repeatedFields_, null);
};
goog.inherits(proto.contract_service.SubscriptionLegalCopy, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.contract_service.SubscriptionLegalCopy.displayName = 'proto.contract_service.SubscriptionLegalCopy';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.contract_service.AddTemplateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.contract_service.AddTemplateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.contract_service.AddTemplateResponse.displayName = 'proto.contract_service.AddTemplateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.contract_service.SubscriptionContract = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.contract_service.SubscriptionContract, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.contract_service.SubscriptionContract.displayName = 'proto.contract_service.SubscriptionContract';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.contract_service.PickupReceipt = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.contract_service.PickupReceipt, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.contract_service.PickupReceipt.displayName = 'proto.contract_service.PickupReceipt';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.contract_service.GetCustomerContractsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.contract_service.GetCustomerContractsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.contract_service.GetCustomerContractsRequest.displayName = 'proto.contract_service.GetCustomerContractsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.contract_service.GetCustomerContractsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.contract_service.GetCustomerContractsResponse.repeatedFields_, null);
};
goog.inherits(proto.contract_service.GetCustomerContractsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.contract_service.GetCustomerContractsResponse.displayName = 'proto.contract_service.GetCustomerContractsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.contract_service.CustomerContractMeta = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.contract_service.CustomerContractMeta, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.contract_service.CustomerContractMeta.displayName = 'proto.contract_service.CustomerContractMeta';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.contract_service.PreviewCustomerContractResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.contract_service.PreviewCustomerContractResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.contract_service.PreviewCustomerContractResponse.displayName = 'proto.contract_service.PreviewCustomerContractResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.contract_service.SignContractRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.contract_service.SignContractRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.contract_service.SignContractRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.contract_service.SignContractRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    contractType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    contractVersion: jspb.Message.getFieldWithDefault(msg, 4, 0),
    createdBeforeUnix: jspb.Message.getFieldWithDefault(msg, 5, 0),
    contractProperties: (f = msg.getContractProperties()) && proto.contract_service.ContractProperties.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.contract_service.SignContractRequest}
 */
proto.contract_service.SignContractRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.contract_service.SignContractRequest;
  return proto.contract_service.SignContractRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.contract_service.SignContractRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.contract_service.SignContractRequest}
 */
proto.contract_service.SignContractRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 3:
      var value = /** @type {!proto.contract_service.ContractType} */ (reader.readEnum());
      msg.setContractType(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setContractVersion(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedBeforeUnix(value);
      break;
    case 6:
      var value = new proto.contract_service.ContractProperties;
      reader.readMessage(value,proto.contract_service.ContractProperties.deserializeBinaryFromReader);
      msg.setContractProperties(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.contract_service.SignContractRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.contract_service.SignContractRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.contract_service.SignContractRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.contract_service.SignContractRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getContractType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getContractVersion();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getCreatedBeforeUnix();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getContractProperties();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.contract_service.ContractProperties.serializeBinaryToWriter
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.contract_service.SignContractRequest.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.contract_service.SignContractRequest} returns this
 */
proto.contract_service.SignContractRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string subscription_id = 2;
 * @return {string}
 */
proto.contract_service.SignContractRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.contract_service.SignContractRequest} returns this
 */
proto.contract_service.SignContractRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional ContractType contract_type = 3;
 * @return {!proto.contract_service.ContractType}
 */
proto.contract_service.SignContractRequest.prototype.getContractType = function() {
  return /** @type {!proto.contract_service.ContractType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.contract_service.ContractType} value
 * @return {!proto.contract_service.SignContractRequest} returns this
 */
proto.contract_service.SignContractRequest.prototype.setContractType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional int64 contract_version = 4;
 * @return {number}
 */
proto.contract_service.SignContractRequest.prototype.getContractVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.contract_service.SignContractRequest} returns this
 */
proto.contract_service.SignContractRequest.prototype.setContractVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 created_before_unix = 5;
 * @return {number}
 */
proto.contract_service.SignContractRequest.prototype.getCreatedBeforeUnix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.contract_service.SignContractRequest} returns this
 */
proto.contract_service.SignContractRequest.prototype.setCreatedBeforeUnix = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional ContractProperties contract_properties = 6;
 * @return {?proto.contract_service.ContractProperties}
 */
proto.contract_service.SignContractRequest.prototype.getContractProperties = function() {
  return /** @type{?proto.contract_service.ContractProperties} */ (
    jspb.Message.getWrapperField(this, proto.contract_service.ContractProperties, 6));
};


/**
 * @param {?proto.contract_service.ContractProperties|undefined} value
 * @return {!proto.contract_service.SignContractRequest} returns this
*/
proto.contract_service.SignContractRequest.prototype.setContractProperties = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.contract_service.SignContractRequest} returns this
 */
proto.contract_service.SignContractRequest.prototype.clearContractProperties = function() {
  return this.setContractProperties(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.contract_service.SignContractRequest.prototype.hasContractProperties = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.contract_service.ContractProperties.prototype.toObject = function(opt_includeInstance) {
  return proto.contract_service.ContractProperties.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.contract_service.ContractProperties} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.contract_service.ContractProperties.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    vin: jspb.Message.getFieldWithDefault(msg, 3, ""),
    odometer: jspb.Message.getFieldWithDefault(msg, 4, ""),
    year: jspb.Message.getFieldWithDefault(msg, 5, ""),
    model: jspb.Message.getFieldWithDefault(msg, 6, ""),
    trim: jspb.Message.getFieldWithDefault(msg, 7, ""),
    make: jspb.Message.getFieldWithDefault(msg, 8, ""),
    paint: jspb.Message.getFieldWithDefault(msg, 9, ""),
    agreementDate: jspb.Message.getFieldWithDefault(msg, 10, ""),
    customerAccountNumber: jspb.Message.getFieldWithDefault(msg, 11, ""),
    customerName: jspb.Message.getFieldWithDefault(msg, 12, ""),
    billingAddress: jspb.Message.getFieldWithDefault(msg, 13, ""),
    subscriptionBaseMonthly: jspb.Message.getFieldWithDefault(msg, 14, ""),
    insuranceMonthly: jspb.Message.getFieldWithDefault(msg, 15, ""),
    insuranceMonthlyTax: jspb.Message.getFieldWithDefault(msg, 16, ""),
    subscriptionTax: jspb.Message.getFieldWithDefault(msg, 17, ""),
    subscriptionTotalMonthly: jspb.Message.getFieldWithDefault(msg, 18, ""),
    subscriptionTotalMonthlyTax: jspb.Message.getFieldWithDefault(msg, 19, ""),
    promoCode: jspb.Message.getFieldWithDefault(msg, 20, ""),
    promoCodeAmount: jspb.Message.getFieldWithDefault(msg, 21, ""),
    billedMonthlyStartingOn: jspb.Message.getFieldWithDefault(msg, 22, ""),
    subscriptionExcessMileageRate: jspb.Message.getFieldWithDefault(msg, 23, ""),
    insuranceExcessMileageRate: jspb.Message.getFieldWithDefault(msg, 24, ""),
    reservationFee: jspb.Message.getFieldWithDefault(msg, 25, ""),
    subscriptionRefundableDeposit: jspb.Message.getFieldWithDefault(msg, 26, ""),
    securityDepositPaidLastFourStatement: jspb.Message.getFieldWithDefault(msg, 27, ""),
    subscriptionStartPayment: jspb.Message.getFieldWithDefault(msg, 28, ""),
    subscriptionStartPaymentTax: jspb.Message.getFieldWithDefault(msg, 29, ""),
    driveOffSummary: jspb.Message.getFieldWithDefault(msg, 30, ""),
    driveOffTotalTax: jspb.Message.getFieldWithDefault(msg, 31, ""),
    subscriptionTotalDueToStart: jspb.Message.getFieldWithDefault(msg, 32, ""),
    firstMonthSubscriptionTax: jspb.Message.getFieldWithDefault(msg, 33, ""),
    firstMonthSubscriptionTotal: jspb.Message.getFieldWithDefault(msg, 34, ""),
    priceTermMonths: jspb.Message.getFieldWithDefault(msg, 35, ""),
    signatureImage: jspb.Message.getFieldWithDefault(msg, 36, ""),
    autonomyId: jspb.Message.getFieldWithDefault(msg, 37, ""),
    currentMileage: jspb.Message.getFieldWithDefault(msg, 38, ""),
    startDate: jspb.Message.getFieldWithDefault(msg, 39, ""),
    startFee: jspb.Message.getFieldWithDefault(msg, 40, ""),
    monthlyFee: jspb.Message.getFieldWithDefault(msg, 41, ""),
    nextMonthlyPayment: jspb.Message.getFieldWithDefault(msg, 42, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.contract_service.ContractProperties}
 */
proto.contract_service.ContractProperties.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.contract_service.ContractProperties;
  return proto.contract_service.ContractProperties.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.contract_service.ContractProperties} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.contract_service.ContractProperties}
 */
proto.contract_service.ContractProperties.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setVin(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOdometer(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setYear(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setModel(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrim(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setMake(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaint(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setAgreementDate(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerAccountNumber(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerName(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setBillingAddress(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionBaseMonthly(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setInsuranceMonthly(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setInsuranceMonthlyTax(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionTax(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionTotalMonthly(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionTotalMonthlyTax(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setPromoCode(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setPromoCodeAmount(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setBilledMonthlyStartingOn(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionExcessMileageRate(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setInsuranceExcessMileageRate(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setReservationFee(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionRefundableDeposit(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.setSecurityDepositPaidLastFourStatement(value);
      break;
    case 28:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionStartPayment(value);
      break;
    case 29:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionStartPaymentTax(value);
      break;
    case 30:
      var value = /** @type {string} */ (reader.readString());
      msg.setDriveOffSummary(value);
      break;
    case 31:
      var value = /** @type {string} */ (reader.readString());
      msg.setDriveOffTotalTax(value);
      break;
    case 32:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionTotalDueToStart(value);
      break;
    case 33:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstMonthSubscriptionTax(value);
      break;
    case 34:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstMonthSubscriptionTotal(value);
      break;
    case 35:
      var value = /** @type {string} */ (reader.readString());
      msg.setPriceTermMonths(value);
      break;
    case 36:
      var value = /** @type {string} */ (reader.readString());
      msg.setSignatureImage(value);
      break;
    case 37:
      var value = /** @type {string} */ (reader.readString());
      msg.setAutonomyId(value);
      break;
    case 38:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrentMileage(value);
      break;
    case 39:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartDate(value);
      break;
    case 40:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartFee(value);
      break;
    case 41:
      var value = /** @type {string} */ (reader.readString());
      msg.setMonthlyFee(value);
      break;
    case 42:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextMonthlyPayment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.contract_service.ContractProperties.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.contract_service.ContractProperties.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.contract_service.ContractProperties} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.contract_service.ContractProperties.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getVin();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOdometer();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getYear();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getModel();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getTrim();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getMake();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getPaint();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getAgreementDate();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getCustomerAccountNumber();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getCustomerName();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getBillingAddress();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getSubscriptionBaseMonthly();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getInsuranceMonthly();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getInsuranceMonthlyTax();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getSubscriptionTax();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getSubscriptionTotalMonthly();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getSubscriptionTotalMonthlyTax();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getPromoCode();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getPromoCodeAmount();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getBilledMonthlyStartingOn();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getSubscriptionExcessMileageRate();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getInsuranceExcessMileageRate();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getReservationFee();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getSubscriptionRefundableDeposit();
  if (f.length > 0) {
    writer.writeString(
      26,
      f
    );
  }
  f = message.getSecurityDepositPaidLastFourStatement();
  if (f.length > 0) {
    writer.writeString(
      27,
      f
    );
  }
  f = message.getSubscriptionStartPayment();
  if (f.length > 0) {
    writer.writeString(
      28,
      f
    );
  }
  f = message.getSubscriptionStartPaymentTax();
  if (f.length > 0) {
    writer.writeString(
      29,
      f
    );
  }
  f = message.getDriveOffSummary();
  if (f.length > 0) {
    writer.writeString(
      30,
      f
    );
  }
  f = message.getDriveOffTotalTax();
  if (f.length > 0) {
    writer.writeString(
      31,
      f
    );
  }
  f = message.getSubscriptionTotalDueToStart();
  if (f.length > 0) {
    writer.writeString(
      32,
      f
    );
  }
  f = message.getFirstMonthSubscriptionTax();
  if (f.length > 0) {
    writer.writeString(
      33,
      f
    );
  }
  f = message.getFirstMonthSubscriptionTotal();
  if (f.length > 0) {
    writer.writeString(
      34,
      f
    );
  }
  f = message.getPriceTermMonths();
  if (f.length > 0) {
    writer.writeString(
      35,
      f
    );
  }
  f = message.getSignatureImage();
  if (f.length > 0) {
    writer.writeString(
      36,
      f
    );
  }
  f = message.getAutonomyId();
  if (f.length > 0) {
    writer.writeString(
      37,
      f
    );
  }
  f = message.getCurrentMileage();
  if (f.length > 0) {
    writer.writeString(
      38,
      f
    );
  }
  f = message.getStartDate();
  if (f.length > 0) {
    writer.writeString(
      39,
      f
    );
  }
  f = message.getStartFee();
  if (f.length > 0) {
    writer.writeString(
      40,
      f
    );
  }
  f = message.getMonthlyFee();
  if (f.length > 0) {
    writer.writeString(
      41,
      f
    );
  }
  f = message.getNextMonthlyPayment();
  if (f.length > 0) {
    writer.writeString(
      42,
      f
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.contract_service.ContractProperties.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.contract_service.ContractProperties} returns this
 */
proto.contract_service.ContractProperties.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string subscription_id = 2;
 * @return {string}
 */
proto.contract_service.ContractProperties.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.contract_service.ContractProperties} returns this
 */
proto.contract_service.ContractProperties.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string vin = 3;
 * @return {string}
 */
proto.contract_service.ContractProperties.prototype.getVin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.contract_service.ContractProperties} returns this
 */
proto.contract_service.ContractProperties.prototype.setVin = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string odometer = 4;
 * @return {string}
 */
proto.contract_service.ContractProperties.prototype.getOdometer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.contract_service.ContractProperties} returns this
 */
proto.contract_service.ContractProperties.prototype.setOdometer = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string year = 5;
 * @return {string}
 */
proto.contract_service.ContractProperties.prototype.getYear = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.contract_service.ContractProperties} returns this
 */
proto.contract_service.ContractProperties.prototype.setYear = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string model = 6;
 * @return {string}
 */
proto.contract_service.ContractProperties.prototype.getModel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.contract_service.ContractProperties} returns this
 */
proto.contract_service.ContractProperties.prototype.setModel = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string trim = 7;
 * @return {string}
 */
proto.contract_service.ContractProperties.prototype.getTrim = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.contract_service.ContractProperties} returns this
 */
proto.contract_service.ContractProperties.prototype.setTrim = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string make = 8;
 * @return {string}
 */
proto.contract_service.ContractProperties.prototype.getMake = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.contract_service.ContractProperties} returns this
 */
proto.contract_service.ContractProperties.prototype.setMake = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string paint = 9;
 * @return {string}
 */
proto.contract_service.ContractProperties.prototype.getPaint = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.contract_service.ContractProperties} returns this
 */
proto.contract_service.ContractProperties.prototype.setPaint = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string agreement_date = 10;
 * @return {string}
 */
proto.contract_service.ContractProperties.prototype.getAgreementDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.contract_service.ContractProperties} returns this
 */
proto.contract_service.ContractProperties.prototype.setAgreementDate = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string customer_account_number = 11;
 * @return {string}
 */
proto.contract_service.ContractProperties.prototype.getCustomerAccountNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.contract_service.ContractProperties} returns this
 */
proto.contract_service.ContractProperties.prototype.setCustomerAccountNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string customer_name = 12;
 * @return {string}
 */
proto.contract_service.ContractProperties.prototype.getCustomerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.contract_service.ContractProperties} returns this
 */
proto.contract_service.ContractProperties.prototype.setCustomerName = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string billing_address = 13;
 * @return {string}
 */
proto.contract_service.ContractProperties.prototype.getBillingAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.contract_service.ContractProperties} returns this
 */
proto.contract_service.ContractProperties.prototype.setBillingAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string subscription_base_monthly = 14;
 * @return {string}
 */
proto.contract_service.ContractProperties.prototype.getSubscriptionBaseMonthly = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.contract_service.ContractProperties} returns this
 */
proto.contract_service.ContractProperties.prototype.setSubscriptionBaseMonthly = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string insurance_monthly = 15;
 * @return {string}
 */
proto.contract_service.ContractProperties.prototype.getInsuranceMonthly = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.contract_service.ContractProperties} returns this
 */
proto.contract_service.ContractProperties.prototype.setInsuranceMonthly = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string insurance_monthly_tax = 16;
 * @return {string}
 */
proto.contract_service.ContractProperties.prototype.getInsuranceMonthlyTax = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.contract_service.ContractProperties} returns this
 */
proto.contract_service.ContractProperties.prototype.setInsuranceMonthlyTax = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string subscription_tax = 17;
 * @return {string}
 */
proto.contract_service.ContractProperties.prototype.getSubscriptionTax = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.contract_service.ContractProperties} returns this
 */
proto.contract_service.ContractProperties.prototype.setSubscriptionTax = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string subscription_total_monthly = 18;
 * @return {string}
 */
proto.contract_service.ContractProperties.prototype.getSubscriptionTotalMonthly = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.contract_service.ContractProperties} returns this
 */
proto.contract_service.ContractProperties.prototype.setSubscriptionTotalMonthly = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string subscription_total_monthly_tax = 19;
 * @return {string}
 */
proto.contract_service.ContractProperties.prototype.getSubscriptionTotalMonthlyTax = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.contract_service.ContractProperties} returns this
 */
proto.contract_service.ContractProperties.prototype.setSubscriptionTotalMonthlyTax = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string promo_code = 20;
 * @return {string}
 */
proto.contract_service.ContractProperties.prototype.getPromoCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.contract_service.ContractProperties} returns this
 */
proto.contract_service.ContractProperties.prototype.setPromoCode = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string promo_code_amount = 21;
 * @return {string}
 */
proto.contract_service.ContractProperties.prototype.getPromoCodeAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.contract_service.ContractProperties} returns this
 */
proto.contract_service.ContractProperties.prototype.setPromoCodeAmount = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string billed_monthly_starting_on = 22;
 * @return {string}
 */
proto.contract_service.ContractProperties.prototype.getBilledMonthlyStartingOn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.contract_service.ContractProperties} returns this
 */
proto.contract_service.ContractProperties.prototype.setBilledMonthlyStartingOn = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional string subscription_excess_mileage_rate = 23;
 * @return {string}
 */
proto.contract_service.ContractProperties.prototype.getSubscriptionExcessMileageRate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.contract_service.ContractProperties} returns this
 */
proto.contract_service.ContractProperties.prototype.setSubscriptionExcessMileageRate = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional string insurance_excess_mileage_rate = 24;
 * @return {string}
 */
proto.contract_service.ContractProperties.prototype.getInsuranceExcessMileageRate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.contract_service.ContractProperties} returns this
 */
proto.contract_service.ContractProperties.prototype.setInsuranceExcessMileageRate = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional string reservation_fee = 25;
 * @return {string}
 */
proto.contract_service.ContractProperties.prototype.getReservationFee = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.contract_service.ContractProperties} returns this
 */
proto.contract_service.ContractProperties.prototype.setReservationFee = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional string subscription_refundable_deposit = 26;
 * @return {string}
 */
proto.contract_service.ContractProperties.prototype.getSubscriptionRefundableDeposit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * @param {string} value
 * @return {!proto.contract_service.ContractProperties} returns this
 */
proto.contract_service.ContractProperties.prototype.setSubscriptionRefundableDeposit = function(value) {
  return jspb.Message.setProto3StringField(this, 26, value);
};


/**
 * optional string security_deposit_paid_last_four_statement = 27;
 * @return {string}
 */
proto.contract_service.ContractProperties.prototype.getSecurityDepositPaidLastFourStatement = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
};


/**
 * @param {string} value
 * @return {!proto.contract_service.ContractProperties} returns this
 */
proto.contract_service.ContractProperties.prototype.setSecurityDepositPaidLastFourStatement = function(value) {
  return jspb.Message.setProto3StringField(this, 27, value);
};


/**
 * optional string subscription_start_payment = 28;
 * @return {string}
 */
proto.contract_service.ContractProperties.prototype.getSubscriptionStartPayment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 28, ""));
};


/**
 * @param {string} value
 * @return {!proto.contract_service.ContractProperties} returns this
 */
proto.contract_service.ContractProperties.prototype.setSubscriptionStartPayment = function(value) {
  return jspb.Message.setProto3StringField(this, 28, value);
};


/**
 * optional string subscription_start_payment_tax = 29;
 * @return {string}
 */
proto.contract_service.ContractProperties.prototype.getSubscriptionStartPaymentTax = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 29, ""));
};


/**
 * @param {string} value
 * @return {!proto.contract_service.ContractProperties} returns this
 */
proto.contract_service.ContractProperties.prototype.setSubscriptionStartPaymentTax = function(value) {
  return jspb.Message.setProto3StringField(this, 29, value);
};


/**
 * optional string drive_off_summary = 30;
 * @return {string}
 */
proto.contract_service.ContractProperties.prototype.getDriveOffSummary = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
};


/**
 * @param {string} value
 * @return {!proto.contract_service.ContractProperties} returns this
 */
proto.contract_service.ContractProperties.prototype.setDriveOffSummary = function(value) {
  return jspb.Message.setProto3StringField(this, 30, value);
};


/**
 * optional string drive_off_total_tax = 31;
 * @return {string}
 */
proto.contract_service.ContractProperties.prototype.getDriveOffTotalTax = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 31, ""));
};


/**
 * @param {string} value
 * @return {!proto.contract_service.ContractProperties} returns this
 */
proto.contract_service.ContractProperties.prototype.setDriveOffTotalTax = function(value) {
  return jspb.Message.setProto3StringField(this, 31, value);
};


/**
 * optional string subscription_total_due_to_start = 32;
 * @return {string}
 */
proto.contract_service.ContractProperties.prototype.getSubscriptionTotalDueToStart = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 32, ""));
};


/**
 * @param {string} value
 * @return {!proto.contract_service.ContractProperties} returns this
 */
proto.contract_service.ContractProperties.prototype.setSubscriptionTotalDueToStart = function(value) {
  return jspb.Message.setProto3StringField(this, 32, value);
};


/**
 * optional string first_month_subscription_tax = 33;
 * @return {string}
 */
proto.contract_service.ContractProperties.prototype.getFirstMonthSubscriptionTax = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 33, ""));
};


/**
 * @param {string} value
 * @return {!proto.contract_service.ContractProperties} returns this
 */
proto.contract_service.ContractProperties.prototype.setFirstMonthSubscriptionTax = function(value) {
  return jspb.Message.setProto3StringField(this, 33, value);
};


/**
 * optional string first_month_subscription_total = 34;
 * @return {string}
 */
proto.contract_service.ContractProperties.prototype.getFirstMonthSubscriptionTotal = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 34, ""));
};


/**
 * @param {string} value
 * @return {!proto.contract_service.ContractProperties} returns this
 */
proto.contract_service.ContractProperties.prototype.setFirstMonthSubscriptionTotal = function(value) {
  return jspb.Message.setProto3StringField(this, 34, value);
};


/**
 * optional string price_term_months = 35;
 * @return {string}
 */
proto.contract_service.ContractProperties.prototype.getPriceTermMonths = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 35, ""));
};


/**
 * @param {string} value
 * @return {!proto.contract_service.ContractProperties} returns this
 */
proto.contract_service.ContractProperties.prototype.setPriceTermMonths = function(value) {
  return jspb.Message.setProto3StringField(this, 35, value);
};


/**
 * optional string signature_image = 36;
 * @return {string}
 */
proto.contract_service.ContractProperties.prototype.getSignatureImage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 36, ""));
};


/**
 * @param {string} value
 * @return {!proto.contract_service.ContractProperties} returns this
 */
proto.contract_service.ContractProperties.prototype.setSignatureImage = function(value) {
  return jspb.Message.setProto3StringField(this, 36, value);
};


/**
 * optional string autonomy_id = 37;
 * @return {string}
 */
proto.contract_service.ContractProperties.prototype.getAutonomyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 37, ""));
};


/**
 * @param {string} value
 * @return {!proto.contract_service.ContractProperties} returns this
 */
proto.contract_service.ContractProperties.prototype.setAutonomyId = function(value) {
  return jspb.Message.setProto3StringField(this, 37, value);
};


/**
 * optional string current_mileage = 38;
 * @return {string}
 */
proto.contract_service.ContractProperties.prototype.getCurrentMileage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 38, ""));
};


/**
 * @param {string} value
 * @return {!proto.contract_service.ContractProperties} returns this
 */
proto.contract_service.ContractProperties.prototype.setCurrentMileage = function(value) {
  return jspb.Message.setProto3StringField(this, 38, value);
};


/**
 * optional string start_date = 39;
 * @return {string}
 */
proto.contract_service.ContractProperties.prototype.getStartDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 39, ""));
};


/**
 * @param {string} value
 * @return {!proto.contract_service.ContractProperties} returns this
 */
proto.contract_service.ContractProperties.prototype.setStartDate = function(value) {
  return jspb.Message.setProto3StringField(this, 39, value);
};


/**
 * optional string start_fee = 40;
 * @return {string}
 */
proto.contract_service.ContractProperties.prototype.getStartFee = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 40, ""));
};


/**
 * @param {string} value
 * @return {!proto.contract_service.ContractProperties} returns this
 */
proto.contract_service.ContractProperties.prototype.setStartFee = function(value) {
  return jspb.Message.setProto3StringField(this, 40, value);
};


/**
 * optional string monthly_fee = 41;
 * @return {string}
 */
proto.contract_service.ContractProperties.prototype.getMonthlyFee = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 41, ""));
};


/**
 * @param {string} value
 * @return {!proto.contract_service.ContractProperties} returns this
 */
proto.contract_service.ContractProperties.prototype.setMonthlyFee = function(value) {
  return jspb.Message.setProto3StringField(this, 41, value);
};


/**
 * optional string next_monthly_payment = 42;
 * @return {string}
 */
proto.contract_service.ContractProperties.prototype.getNextMonthlyPayment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 42, ""));
};


/**
 * @param {string} value
 * @return {!proto.contract_service.ContractProperties} returns this
 */
proto.contract_service.ContractProperties.prototype.setNextMonthlyPayment = function(value) {
  return jspb.Message.setProto3StringField(this, 42, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.contract_service.SignContractResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.contract_service.SignContractResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.contract_service.SignContractResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.contract_service.SignContractResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    creationDate: jspb.Message.getFieldWithDefault(msg, 2, 0),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    version: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.contract_service.SignContractResponse}
 */
proto.contract_service.SignContractResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.contract_service.SignContractResponse;
  return proto.contract_service.SignContractResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.contract_service.SignContractResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.contract_service.SignContractResponse}
 */
proto.contract_service.SignContractResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreationDate(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.contract_service.SignContractResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.contract_service.SignContractResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.contract_service.SignContractResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.contract_service.SignContractResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreationDate();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getVersion();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.contract_service.SignContractResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.contract_service.SignContractResponse} returns this
 */
proto.contract_service.SignContractResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 creation_date = 2;
 * @return {number}
 */
proto.contract_service.SignContractResponse.prototype.getCreationDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.contract_service.SignContractResponse} returns this
 */
proto.contract_service.SignContractResponse.prototype.setCreationDate = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string subscription_id = 3;
 * @return {string}
 */
proto.contract_service.SignContractResponse.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.contract_service.SignContractResponse} returns this
 */
proto.contract_service.SignContractResponse.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 version = 4;
 * @return {number}
 */
proto.contract_service.SignContractResponse.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.contract_service.SignContractResponse} returns this
 */
proto.contract_service.SignContractResponse.prototype.setVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.contract_service.AddTemplateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.contract_service.AddTemplateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.contract_service.AddTemplateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.contract_service.AddTemplateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    contractType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    template: jspb.Message.getFieldWithDefault(msg, 2, ""),
    legalCopy: (f = msg.getLegalCopy()) && proto.contract_service.LegalCopy.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.contract_service.AddTemplateRequest}
 */
proto.contract_service.AddTemplateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.contract_service.AddTemplateRequest;
  return proto.contract_service.AddTemplateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.contract_service.AddTemplateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.contract_service.AddTemplateRequest}
 */
proto.contract_service.AddTemplateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.contract_service.ContractType} */ (reader.readEnum());
      msg.setContractType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTemplate(value);
      break;
    case 3:
      var value = new proto.contract_service.LegalCopy;
      reader.readMessage(value,proto.contract_service.LegalCopy.deserializeBinaryFromReader);
      msg.setLegalCopy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.contract_service.AddTemplateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.contract_service.AddTemplateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.contract_service.AddTemplateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.contract_service.AddTemplateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContractType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getTemplate();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLegalCopy();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.contract_service.LegalCopy.serializeBinaryToWriter
    );
  }
};


/**
 * optional ContractType contract_type = 1;
 * @return {!proto.contract_service.ContractType}
 */
proto.contract_service.AddTemplateRequest.prototype.getContractType = function() {
  return /** @type {!proto.contract_service.ContractType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.contract_service.ContractType} value
 * @return {!proto.contract_service.AddTemplateRequest} returns this
 */
proto.contract_service.AddTemplateRequest.prototype.setContractType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string template = 2;
 * @return {string}
 */
proto.contract_service.AddTemplateRequest.prototype.getTemplate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.contract_service.AddTemplateRequest} returns this
 */
proto.contract_service.AddTemplateRequest.prototype.setTemplate = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional LegalCopy legal_copy = 3;
 * @return {?proto.contract_service.LegalCopy}
 */
proto.contract_service.AddTemplateRequest.prototype.getLegalCopy = function() {
  return /** @type{?proto.contract_service.LegalCopy} */ (
    jspb.Message.getWrapperField(this, proto.contract_service.LegalCopy, 3));
};


/**
 * @param {?proto.contract_service.LegalCopy|undefined} value
 * @return {!proto.contract_service.AddTemplateRequest} returns this
*/
proto.contract_service.AddTemplateRequest.prototype.setLegalCopy = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.contract_service.AddTemplateRequest} returns this
 */
proto.contract_service.AddTemplateRequest.prototype.clearLegalCopy = function() {
  return this.setLegalCopy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.contract_service.AddTemplateRequest.prototype.hasLegalCopy = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.contract_service.LegalCopy.prototype.toObject = function(opt_includeInstance) {
  return proto.contract_service.LegalCopy.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.contract_service.LegalCopy} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.contract_service.LegalCopy.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscriptionLegalCopy: (f = msg.getSubscriptionLegalCopy()) && proto.contract_service.SubscriptionLegalCopy.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.contract_service.LegalCopy}
 */
proto.contract_service.LegalCopy.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.contract_service.LegalCopy;
  return proto.contract_service.LegalCopy.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.contract_service.LegalCopy} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.contract_service.LegalCopy}
 */
proto.contract_service.LegalCopy.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.contract_service.SubscriptionLegalCopy;
      reader.readMessage(value,proto.contract_service.SubscriptionLegalCopy.deserializeBinaryFromReader);
      msg.setSubscriptionLegalCopy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.contract_service.LegalCopy.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.contract_service.LegalCopy.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.contract_service.LegalCopy} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.contract_service.LegalCopy.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubscriptionLegalCopy();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.contract_service.SubscriptionLegalCopy.serializeBinaryToWriter
    );
  }
};


/**
 * optional SubscriptionLegalCopy subscription_legal_copy = 1;
 * @return {?proto.contract_service.SubscriptionLegalCopy}
 */
proto.contract_service.LegalCopy.prototype.getSubscriptionLegalCopy = function() {
  return /** @type{?proto.contract_service.SubscriptionLegalCopy} */ (
    jspb.Message.getWrapperField(this, proto.contract_service.SubscriptionLegalCopy, 1));
};


/**
 * @param {?proto.contract_service.SubscriptionLegalCopy|undefined} value
 * @return {!proto.contract_service.LegalCopy} returns this
*/
proto.contract_service.LegalCopy.prototype.setSubscriptionLegalCopy = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.contract_service.LegalCopy} returns this
 */
proto.contract_service.LegalCopy.prototype.clearSubscriptionLegalCopy = function() {
  return this.setSubscriptionLegalCopy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.contract_service.LegalCopy.prototype.hasSubscriptionLegalCopy = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.contract_service.SubscriptionLegalCopy.repeatedFields_ = [1,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.contract_service.SubscriptionLegalCopy.prototype.toObject = function(opt_includeInstance) {
  return proto.contract_service.SubscriptionLegalCopy.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.contract_service.SubscriptionLegalCopy} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.contract_service.SubscriptionLegalCopy.toObject = function(includeInstance, msg) {
  var f, obj = {
    paragraphsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    excessMileageDisclaimerNoInsurance: jspb.Message.getFieldWithDefault(msg, 2, ""),
    excessMileageDisclaimerWithInsurance: jspb.Message.getFieldWithDefault(msg, 3, ""),
    bulletPointsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.contract_service.SubscriptionLegalCopy}
 */
proto.contract_service.SubscriptionLegalCopy.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.contract_service.SubscriptionLegalCopy;
  return proto.contract_service.SubscriptionLegalCopy.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.contract_service.SubscriptionLegalCopy} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.contract_service.SubscriptionLegalCopy}
 */
proto.contract_service.SubscriptionLegalCopy.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addParagraphs(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setExcessMileageDisclaimerNoInsurance(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setExcessMileageDisclaimerWithInsurance(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addBulletPoints(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.contract_service.SubscriptionLegalCopy.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.contract_service.SubscriptionLegalCopy.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.contract_service.SubscriptionLegalCopy} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.contract_service.SubscriptionLegalCopy.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getParagraphsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getExcessMileageDisclaimerNoInsurance();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getExcessMileageDisclaimerWithInsurance();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBulletPointsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
};


/**
 * repeated string paragraphs = 1;
 * @return {!Array<string>}
 */
proto.contract_service.SubscriptionLegalCopy.prototype.getParagraphsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.contract_service.SubscriptionLegalCopy} returns this
 */
proto.contract_service.SubscriptionLegalCopy.prototype.setParagraphsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.contract_service.SubscriptionLegalCopy} returns this
 */
proto.contract_service.SubscriptionLegalCopy.prototype.addParagraphs = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.contract_service.SubscriptionLegalCopy} returns this
 */
proto.contract_service.SubscriptionLegalCopy.prototype.clearParagraphsList = function() {
  return this.setParagraphsList([]);
};


/**
 * optional string excess_mileage_disclaimer_no_insurance = 2;
 * @return {string}
 */
proto.contract_service.SubscriptionLegalCopy.prototype.getExcessMileageDisclaimerNoInsurance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.contract_service.SubscriptionLegalCopy} returns this
 */
proto.contract_service.SubscriptionLegalCopy.prototype.setExcessMileageDisclaimerNoInsurance = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string excess_mileage_disclaimer_with_insurance = 3;
 * @return {string}
 */
proto.contract_service.SubscriptionLegalCopy.prototype.getExcessMileageDisclaimerWithInsurance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.contract_service.SubscriptionLegalCopy} returns this
 */
proto.contract_service.SubscriptionLegalCopy.prototype.setExcessMileageDisclaimerWithInsurance = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated string bullet_points = 4;
 * @return {!Array<string>}
 */
proto.contract_service.SubscriptionLegalCopy.prototype.getBulletPointsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.contract_service.SubscriptionLegalCopy} returns this
 */
proto.contract_service.SubscriptionLegalCopy.prototype.setBulletPointsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.contract_service.SubscriptionLegalCopy} returns this
 */
proto.contract_service.SubscriptionLegalCopy.prototype.addBulletPoints = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.contract_service.SubscriptionLegalCopy} returns this
 */
proto.contract_service.SubscriptionLegalCopy.prototype.clearBulletPointsList = function() {
  return this.setBulletPointsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.contract_service.AddTemplateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.contract_service.AddTemplateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.contract_service.AddTemplateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.contract_service.AddTemplateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    template: jspb.Message.getFieldWithDefault(msg, 1, ""),
    version: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.contract_service.AddTemplateResponse}
 */
proto.contract_service.AddTemplateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.contract_service.AddTemplateResponse;
  return proto.contract_service.AddTemplateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.contract_service.AddTemplateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.contract_service.AddTemplateResponse}
 */
proto.contract_service.AddTemplateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTemplate(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.contract_service.AddTemplateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.contract_service.AddTemplateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.contract_service.AddTemplateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.contract_service.AddTemplateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTemplate();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVersion();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional string template = 1;
 * @return {string}
 */
proto.contract_service.AddTemplateResponse.prototype.getTemplate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.contract_service.AddTemplateResponse} returns this
 */
proto.contract_service.AddTemplateResponse.prototype.setTemplate = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 version = 2;
 * @return {number}
 */
proto.contract_service.AddTemplateResponse.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.contract_service.AddTemplateResponse} returns this
 */
proto.contract_service.AddTemplateResponse.prototype.setVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.contract_service.SubscriptionContract.prototype.toObject = function(opt_includeInstance) {
  return proto.contract_service.SubscriptionContract.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.contract_service.SubscriptionContract} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.contract_service.SubscriptionContract.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    creationDate: jspb.Message.getFieldWithDefault(msg, 2, 0),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    documentUri: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.contract_service.SubscriptionContract}
 */
proto.contract_service.SubscriptionContract.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.contract_service.SubscriptionContract;
  return proto.contract_service.SubscriptionContract.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.contract_service.SubscriptionContract} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.contract_service.SubscriptionContract}
 */
proto.contract_service.SubscriptionContract.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreationDate(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentUri(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.contract_service.SubscriptionContract.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.contract_service.SubscriptionContract.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.contract_service.SubscriptionContract} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.contract_service.SubscriptionContract.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreationDate();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDocumentUri();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.contract_service.SubscriptionContract.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.contract_service.SubscriptionContract} returns this
 */
proto.contract_service.SubscriptionContract.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 creation_date = 2;
 * @return {number}
 */
proto.contract_service.SubscriptionContract.prototype.getCreationDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.contract_service.SubscriptionContract} returns this
 */
proto.contract_service.SubscriptionContract.prototype.setCreationDate = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string subscription_id = 3;
 * @return {string}
 */
proto.contract_service.SubscriptionContract.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.contract_service.SubscriptionContract} returns this
 */
proto.contract_service.SubscriptionContract.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string document_uri = 4;
 * @return {string}
 */
proto.contract_service.SubscriptionContract.prototype.getDocumentUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.contract_service.SubscriptionContract} returns this
 */
proto.contract_service.SubscriptionContract.prototype.setDocumentUri = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.contract_service.PickupReceipt.prototype.toObject = function(opt_includeInstance) {
  return proto.contract_service.PickupReceipt.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.contract_service.PickupReceipt} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.contract_service.PickupReceipt.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    creationDate: jspb.Message.getFieldWithDefault(msg, 2, 0),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    documentUri: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.contract_service.PickupReceipt}
 */
proto.contract_service.PickupReceipt.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.contract_service.PickupReceipt;
  return proto.contract_service.PickupReceipt.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.contract_service.PickupReceipt} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.contract_service.PickupReceipt}
 */
proto.contract_service.PickupReceipt.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreationDate(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentUri(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.contract_service.PickupReceipt.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.contract_service.PickupReceipt.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.contract_service.PickupReceipt} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.contract_service.PickupReceipt.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreationDate();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDocumentUri();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.contract_service.PickupReceipt.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.contract_service.PickupReceipt} returns this
 */
proto.contract_service.PickupReceipt.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 creation_date = 2;
 * @return {number}
 */
proto.contract_service.PickupReceipt.prototype.getCreationDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.contract_service.PickupReceipt} returns this
 */
proto.contract_service.PickupReceipt.prototype.setCreationDate = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string subscription_id = 3;
 * @return {string}
 */
proto.contract_service.PickupReceipt.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.contract_service.PickupReceipt} returns this
 */
proto.contract_service.PickupReceipt.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string document_uri = 4;
 * @return {string}
 */
proto.contract_service.PickupReceipt.prototype.getDocumentUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.contract_service.PickupReceipt} returns this
 */
proto.contract_service.PickupReceipt.prototype.setDocumentUri = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.contract_service.GetCustomerContractsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.contract_service.GetCustomerContractsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.contract_service.GetCustomerContractsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.contract_service.GetCustomerContractsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.contract_service.GetCustomerContractsRequest}
 */
proto.contract_service.GetCustomerContractsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.contract_service.GetCustomerContractsRequest;
  return proto.contract_service.GetCustomerContractsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.contract_service.GetCustomerContractsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.contract_service.GetCustomerContractsRequest}
 */
proto.contract_service.GetCustomerContractsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.contract_service.GetCustomerContractsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.contract_service.GetCustomerContractsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.contract_service.GetCustomerContractsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.contract_service.GetCustomerContractsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.contract_service.GetCustomerContractsRequest.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.contract_service.GetCustomerContractsRequest} returns this
 */
proto.contract_service.GetCustomerContractsRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.contract_service.GetCustomerContractsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.contract_service.GetCustomerContractsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.contract_service.GetCustomerContractsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.contract_service.GetCustomerContractsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.contract_service.GetCustomerContractsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerContractsList: jspb.Message.toObjectList(msg.getCustomerContractsList(),
    proto.contract_service.CustomerContractMeta.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.contract_service.GetCustomerContractsResponse}
 */
proto.contract_service.GetCustomerContractsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.contract_service.GetCustomerContractsResponse;
  return proto.contract_service.GetCustomerContractsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.contract_service.GetCustomerContractsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.contract_service.GetCustomerContractsResponse}
 */
proto.contract_service.GetCustomerContractsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.contract_service.CustomerContractMeta;
      reader.readMessage(value,proto.contract_service.CustomerContractMeta.deserializeBinaryFromReader);
      msg.addCustomerContracts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.contract_service.GetCustomerContractsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.contract_service.GetCustomerContractsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.contract_service.GetCustomerContractsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.contract_service.GetCustomerContractsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerContractsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.contract_service.CustomerContractMeta.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CustomerContractMeta customer_contracts = 1;
 * @return {!Array<!proto.contract_service.CustomerContractMeta>}
 */
proto.contract_service.GetCustomerContractsResponse.prototype.getCustomerContractsList = function() {
  return /** @type{!Array<!proto.contract_service.CustomerContractMeta>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.contract_service.CustomerContractMeta, 1));
};


/**
 * @param {!Array<!proto.contract_service.CustomerContractMeta>} value
 * @return {!proto.contract_service.GetCustomerContractsResponse} returns this
*/
proto.contract_service.GetCustomerContractsResponse.prototype.setCustomerContractsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.contract_service.CustomerContractMeta=} opt_value
 * @param {number=} opt_index
 * @return {!proto.contract_service.CustomerContractMeta}
 */
proto.contract_service.GetCustomerContractsResponse.prototype.addCustomerContracts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.contract_service.CustomerContractMeta, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.contract_service.GetCustomerContractsResponse} returns this
 */
proto.contract_service.GetCustomerContractsResponse.prototype.clearCustomerContractsList = function() {
  return this.setCustomerContractsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.contract_service.CustomerContractMeta.prototype.toObject = function(opt_includeInstance) {
  return proto.contract_service.CustomerContractMeta.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.contract_service.CustomerContractMeta} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.contract_service.CustomerContractMeta.toObject = function(includeInstance, msg) {
  var f, obj = {
    documentsByIdMap: (f = msg.getDocumentsByIdMap()) ? f.toObject(includeInstance, undefined) : [],
    contractsMap: (f = msg.getContractsMap()) ? f.toObject(includeInstance, proto.contract_service.SubscriptionContract.toObject) : [],
    pickupReceiptMap: (f = msg.getPickupReceiptMap()) ? f.toObject(includeInstance, proto.contract_service.PickupReceipt.toObject) : [],
    customerId: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.contract_service.CustomerContractMeta}
 */
proto.contract_service.CustomerContractMeta.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.contract_service.CustomerContractMeta;
  return proto.contract_service.CustomerContractMeta.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.contract_service.CustomerContractMeta} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.contract_service.CustomerContractMeta}
 */
proto.contract_service.CustomerContractMeta.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getDocumentsByIdMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 2:
      var value = msg.getContractsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.contract_service.SubscriptionContract.deserializeBinaryFromReader, "", new proto.contract_service.SubscriptionContract());
         });
      break;
    case 4:
      var value = msg.getPickupReceiptMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.contract_service.PickupReceipt.deserializeBinaryFromReader, "", new proto.contract_service.PickupReceipt());
         });
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.contract_service.CustomerContractMeta.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.contract_service.CustomerContractMeta.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.contract_service.CustomerContractMeta} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.contract_service.CustomerContractMeta.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDocumentsByIdMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getContractsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.contract_service.SubscriptionContract.serializeBinaryToWriter);
  }
  f = message.getPickupReceiptMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(4, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.contract_service.PickupReceipt.serializeBinaryToWriter);
  }
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * map<string, string> documents_by_id = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.contract_service.CustomerContractMeta.prototype.getDocumentsByIdMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.contract_service.CustomerContractMeta} returns this
 */
proto.contract_service.CustomerContractMeta.prototype.clearDocumentsByIdMap = function() {
  this.getDocumentsByIdMap().clear();
  return this;};


/**
 * map<string, SubscriptionContract> contracts = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.contract_service.SubscriptionContract>}
 */
proto.contract_service.CustomerContractMeta.prototype.getContractsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.contract_service.SubscriptionContract>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      proto.contract_service.SubscriptionContract));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.contract_service.CustomerContractMeta} returns this
 */
proto.contract_service.CustomerContractMeta.prototype.clearContractsMap = function() {
  this.getContractsMap().clear();
  return this;};


/**
 * map<string, PickupReceipt> pickup_receipt = 4;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.contract_service.PickupReceipt>}
 */
proto.contract_service.CustomerContractMeta.prototype.getPickupReceiptMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.contract_service.PickupReceipt>} */ (
      jspb.Message.getMapField(this, 4, opt_noLazyCreate,
      proto.contract_service.PickupReceipt));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.contract_service.CustomerContractMeta} returns this
 */
proto.contract_service.CustomerContractMeta.prototype.clearPickupReceiptMap = function() {
  this.getPickupReceiptMap().clear();
  return this;};


/**
 * optional string customer_id = 5;
 * @return {string}
 */
proto.contract_service.CustomerContractMeta.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.contract_service.CustomerContractMeta} returns this
 */
proto.contract_service.CustomerContractMeta.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.contract_service.PreviewCustomerContractResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.contract_service.PreviewCustomerContractResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.contract_service.PreviewCustomerContractResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.contract_service.PreviewCustomerContractResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    pdfBytes: msg.getPdfBytes_asB64(),
    legalCopy: (f = msg.getLegalCopy()) && proto.contract_service.LegalCopy.toObject(includeInstance, f),
    version: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.contract_service.PreviewCustomerContractResponse}
 */
proto.contract_service.PreviewCustomerContractResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.contract_service.PreviewCustomerContractResponse;
  return proto.contract_service.PreviewCustomerContractResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.contract_service.PreviewCustomerContractResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.contract_service.PreviewCustomerContractResponse}
 */
proto.contract_service.PreviewCustomerContractResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setPdfBytes(value);
      break;
    case 2:
      var value = new proto.contract_service.LegalCopy;
      reader.readMessage(value,proto.contract_service.LegalCopy.deserializeBinaryFromReader);
      msg.setLegalCopy(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.contract_service.PreviewCustomerContractResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.contract_service.PreviewCustomerContractResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.contract_service.PreviewCustomerContractResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.contract_service.PreviewCustomerContractResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPdfBytes_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getLegalCopy();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.contract_service.LegalCopy.serializeBinaryToWriter
    );
  }
  f = message.getVersion();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional bytes pdf_bytes = 1;
 * @return {string}
 */
proto.contract_service.PreviewCustomerContractResponse.prototype.getPdfBytes = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes pdf_bytes = 1;
 * This is a type-conversion wrapper around `getPdfBytes()`
 * @return {string}
 */
proto.contract_service.PreviewCustomerContractResponse.prototype.getPdfBytes_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getPdfBytes()));
};


/**
 * optional bytes pdf_bytes = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPdfBytes()`
 * @return {!Uint8Array}
 */
proto.contract_service.PreviewCustomerContractResponse.prototype.getPdfBytes_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getPdfBytes()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.contract_service.PreviewCustomerContractResponse} returns this
 */
proto.contract_service.PreviewCustomerContractResponse.prototype.setPdfBytes = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional LegalCopy legal_copy = 2;
 * @return {?proto.contract_service.LegalCopy}
 */
proto.contract_service.PreviewCustomerContractResponse.prototype.getLegalCopy = function() {
  return /** @type{?proto.contract_service.LegalCopy} */ (
    jspb.Message.getWrapperField(this, proto.contract_service.LegalCopy, 2));
};


/**
 * @param {?proto.contract_service.LegalCopy|undefined} value
 * @return {!proto.contract_service.PreviewCustomerContractResponse} returns this
*/
proto.contract_service.PreviewCustomerContractResponse.prototype.setLegalCopy = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.contract_service.PreviewCustomerContractResponse} returns this
 */
proto.contract_service.PreviewCustomerContractResponse.prototype.clearLegalCopy = function() {
  return this.setLegalCopy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.contract_service.PreviewCustomerContractResponse.prototype.hasLegalCopy = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int64 version = 3;
 * @return {number}
 */
proto.contract_service.PreviewCustomerContractResponse.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.contract_service.PreviewCustomerContractResponse} returns this
 */
proto.contract_service.PreviewCustomerContractResponse.prototype.setVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * @enum {number}
 */
proto.contract_service.ContractType = {
  SUBSCRIPTION_CONTRACT: 0,
  PICKUP_RECEIPT: 1
};

goog.object.extend(exports, proto.contract_service);
