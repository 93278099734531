/* eslint-disable no-global-assign */
import { OktaAuth, OktaAuthOptions } from '@okta/okta-auth-js'
import * as grpc from 'grpc-web'
import { mapKeys, snakeCase } from 'lodash'
import { safeParseJSON } from '../utils/safeParseJSON'

const debugSettingsKey = 'gRPCDebugSettings'

type DebugSettings = {
  logAuth: boolean
  logMessageObject: boolean
  logMessageBinary: boolean
}

function loadDebugSettings(): DebugSettings {
  if (process.env.IS_UNIT_TESTS) {
    return {
      logAuth: false,
      logMessageBinary: false,
      logMessageObject: false,
    }
  }

  const settings = localStorage.getItem(debugSettingsKey)

  if (!settings) {
    return {
      logAuth: false,
      logMessageBinary: false,
      logMessageObject: false,
    }
  }

  return safeParseJSON(settings) as DebugSettings
}

const debugSettings = loadDebugSettings()
initDebug()

function initDebug(){
  if (process.env.IS_UNIT_TESTS) {
    return
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.toggleGRPCDebug = (options?: DebugSettings) => {
    debugSettings.logAuth = options?.logAuth || !debugSettings.logAuth

    debugSettings.logMessageObject =
      options?.logMessageObject || !debugSettings.logMessageObject

    debugSettings.logMessageBinary = options?.logMessageBinary || false

    localStorage.setItem(debugSettingsKey, JSON.stringify(debugSettings))
  }
}


const config: OktaAuthOptions = {
  clientId: process.env.CLIENT_ID,
  issuer: process.env.ISSUER,
  redirectUri: process.env.REDIRECT,
  scopes: ['openid', 'profile', 'email', 'groups'],
  pkce: true,
}

if (process.env.IS_UNIT_TESTS) {
  const storage = {}
  config.tokenManager = {
    storage: {
      getItem(key) {
        return storage[key]
      },
      setItem(key, value) {
        storage[key] = value
      },
      removeItem(key) {
        delete storage[key]
      },
    },
  }
}

export const oktaAuth = new OktaAuth(config)

export function OktaAuthInterceptor(oktaAuthToken) {
  this.oktaAuthToken = oktaAuthToken
}

// Disable because it's not easy to type this generic.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
OktaAuthInterceptor.prototype.intercept = function (
  request: grpc.Request<{ toObject: () => void }, any>,
  invoker
) {
  const reqMetadata = request.getMetadata()
  reqMetadata['authorization'] = this.oktaAuthToken

  if(
    debugSettings.logAuth
    || debugSettings.logMessageBinary
    || debugSettings.logMessageObject
  ){
    console.log({
      auth: debugSettings.logAuth && request.getMetadata(),
      message:
        debugSettings.logMessageObject &&
        getLogMessage(request.getRequestMessage()),
      instance: debugSettings.logMessageBinary && request.getRequestMessage(),
      invoker,
      request,
      callOptions: request.getCallOptions(),
    })
  }

  return invoker(request)
}

function getLogMessage(message: { toObject: () => any }){
  return mapKeys(message?.toObject(), (value, key) => {
    return snakeCase(key)
  })
}
