import { isFunction } from 'lodash'

export function spyOnResponse(label = 'Spying on promise response') {
  console.log(`${label}: `)
  return function (res: any) {
    console.log(res)

    if (isFunction(res.toObject)) {
      console.log(`${label} — As Object: `)
      console.log(res.toObject())
    }

    return res
  }
}

export function spyOnError(label = 'Spying on promise error') {
  return function (err: any) {
    console.log(`${label}: `, err)
    throw err
  }
}
