import React from 'react'
import { Button } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'

type CtaButtonProps = {
  name: string
  onClick: () => void
}

export const CtaButton: React.FC<CtaButtonProps> = ({ name, onClick }) => {
  return (
    <Button
      variant="outlined"
      className="ctaBtn"
      onClick={onClick}
    >
      {name} <AddIcon className="ctaBtnIcon" />
    </Button>
  )
}
