/**
 * Initially used to safely parse the JSON from CQE qualification raw data
 * @param json 
 */
export function safeParseJSON(json: string){
  if(json === ''){
    return ''
  }

  try {
    return JSON.parse(json)
  } catch(e){
    return json
  }
}