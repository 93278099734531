import React, {ReactNode} from 'react'
import {Button, Grid} from '@mui/material'
import {Section} from 'shared/Section'
import {TaskSettingsProps, useTaskSettings} from './useTaskSettings'
import {EventTypeBlock} from './EventTypeBlock/EventTypeBlock'

export const TaskSettings: React.FC<TaskSettingsProps> = ({
  dealership,
  isAdmin
}) => {
  const {
    listEventTypes,
    isEditing,
    isLoading,
    saveChanges,
    setIsEditing,
    setEventTypes,
    handleCancellation,
  } = useTaskSettings( dealership )

  function getHeaderButtons(): ReactNode {
    return (
      <Grid item>
        <Grid
          container
          columnGap={2}>
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              onClick={ () => {
                setIsEditing( !isEditing )
                if ( isEditing ) {
                  handleCancellation()
                }
              }}>
              { !isEditing ? 'Edit' : 'Cancel' }
            </Button>
          </Grid>

          { isEditing &&
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={ () => {
                  saveChanges()
                }}>
                Save
              </Button>
            </Grid>
          }
        </Grid>
      </Grid>
    )
  }

  return (
    <Section
      wrapperSection
      heading="Task Settings"
      headerButtons={isAdmin && getHeaderButtons()}>

      <Section subSection heading="Event Type">
        <EventTypeBlock
          setEventTypes={setEventTypes}
          listEventTypes={listEventTypes}
          isLoading={isLoading}
          isEditing={isEditing}/>
      </Section>
    </Section>
  )
}
