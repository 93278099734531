import React, { useState } from 'react'
import {
  Box,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import { autonomyColor } from '../../global-styles/autonomyColor'
import { useCustomerContext } from './CustomerContext'
import { Delete } from '@material-ui/icons'
import DeleteDialog from 'shared/DeleteDialog'
import toast from 'react-hot-toast'
import { formattedPhoneNumber } from './useCustomers.utils'
export const CustomerInfo = () => {
  const [phoneNumber, setPhoneNumber] = useState(null)
  const {
    customerInfo,
    subscriptionStatus,
    customerId,
    updateCustomerWithFakePhone,
  } = useCustomerContext()
  const handleCloseFakePhone = () => setPhoneNumber(null)
  const onUpdateFakePhone = async () => {
    try {
      const result = await updateCustomerWithFakePhone(customerId)
      if (result) {
        setPhoneNumber(null)
      }
    } catch (error) {
      console.error('Error updating: ', error)
      toast.error('An error occurred while updating the record.')
    }
  }
  return (
    <>
      <Table size={'small'}>
        <TableHead>
          <TableRow>
            <TableCell>
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                }}
              >
                <Box sx={{ ml: 1 }}>
                  <Link color={autonomyColor.pink} variant="subtitle2">
                    Basic Details
                  </Link>
                </Box>
              </Box>
            </TableCell>
            <TableCell>
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                }}
              >
                <Box sx={{ ml: 1 }}>
                  <Link color="inherit" variant="subtitle2"></Link>
                </Box>
              </Box>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow hover>
            <TableCell>
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                }}
              >
                <Box sx={{ ml: 1 }}>
                  <Link color={autonomyColor.white} variant="subtitle2">
                    Email
                  </Link>
                </Box>
              </Box>
            </TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>
              {customerInfo?.email}
            </TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell>
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                }}
              >
                <Box sx={{ ml: 1 }}>
                  <Link color={autonomyColor.white} variant="subtitle2">
                    Phone
                  </Link>
                </Box>
              </Box>
            </TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>
              {customerInfo?.phone
                ? formattedPhoneNumber(customerInfo?.phone)
                : null}
              {customerInfo?.phone ? (
                <span>
                  <IconButton
                    className="xsIcon"
                    onClick={() => {
                      setPhoneNumber(customerInfo?.phone)
                    }}
                  >
                    <Delete />
                  </IconButton>
                </span>
              ) : null}
            </TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell>
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                }}
              >
                <Box sx={{ ml: 1 }}>
                  <Link color={autonomyColor.white} variant="subtitle2">
                    Parking Address
                  </Link>
                </Box>
              </Box>
            </TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>
              {customerInfo?.parkingAddress}
            </TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell>
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                }}
              >
                <Box sx={{ ml: 1 }}>
                  <Link color={autonomyColor.white} variant="subtitle2">
                    License Address
                  </Link>
                </Box>
              </Box>
            </TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>
              {customerInfo?.licenseAddress}
            </TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell>
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                }}
              >
                <Box sx={{ ml: 1 }}>
                  <Link color={autonomyColor.white} variant="subtitle2">
                    Contact Address
                  </Link>
                </Box>
              </Box>
            </TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>
              {customerInfo?.contactAddress}
            </TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell>
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                }}
              >
                <Box sx={{ ml: 1 }}>
                  <Link color={autonomyColor.white} variant="subtitle2">
                    Subscription status
                  </Link>
                </Box>
              </Box>
            </TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>
              {subscriptionStatus}
            </TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell>
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                }}
              >
                <Box sx={{ ml: 1 }}>
                  <Link color={autonomyColor.white} variant="subtitle2">
                    Account Number
                  </Link>
                </Box>
              </Box>
            </TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>
              {customerInfo?.accountNumber}
            </TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell>
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                }}
              >
                <Box sx={{ ml: 1 }}>
                  <Link color={autonomyColor.white} variant="subtitle2">
                    Driver&apos;s License Exp. Date
                  </Link>
                </Box>
              </Box>
            </TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>
              {customerInfo?.liscenceExpirationDate}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <DeleteDialog
        open={Boolean(phoneNumber)}
        handleClose={handleCloseFakePhone}
        title="Update customer with fake phone number"
        description={`You are about to change the phone number ${customerInfo.phone} to a fake number would you like to proceed?`}
        onConfirm={onUpdateFakePhone}
      />
    </>
  )
}
