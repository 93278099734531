import { Box, Typography } from '@mui/material'
import { format } from 'date-fns'
import * as React from 'react'
import { VehicleBlockProps } from '../useReservation'

export const VehicleBlock: React.FC<VehicleBlockProps> = ({
  make,
  model,
  colorPreference,
  vin,
  mileage,
  dateListed,
  trim,
}) => {
  const formatDateListed = dateListed
    ? format(new Date(dateListed), 'MM/dd/yy')
    : 'N/A'

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        columnGap: '3rem',
        rowGap: '1.5rem',
      }}
    >
      <div>
        <Typography variant="caption">Make/Model</Typography>
        <div>{make ? `${make}/${model}` : 'N/A'}</div>
      </div>
      <div>
        <Typography variant="caption">Trim</Typography>
        <div>{trim || 'N/A'}</div>
      </div>
      <div>
        <Typography variant="caption">Miles</Typography>
        <div>{mileage || 'N/A'}</div>
      </div>
      <div>
        <Typography variant="caption">Color Preference</Typography>
        <div>{colorPreference || 'N/A'}</div>
      </div>
      <div>
        <Typography variant="caption">Date Listed</Typography>
        <div>{formatDateListed}</div>
      </div>
      <div>
        <Typography variant="caption">VIN</Typography>
        <div>{vin || 'N/A'}</div>
      </div>
    </Box>
  )
}
