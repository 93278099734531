import { format } from 'date-fns'
import { get } from 'lodash'
import {
  JsonParam,
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params'
import Bluebird from 'bluebird'
import { useState } from 'react'
import { QualificationTierMap } from '@nxcr-org/web-api-interface/lib/domain_pb'
import { Vehicle } from '@nxcr-org/web-api-interface/lib/vehicle_pb'
import { FleetService } from '../../../../api/fleet-service'
import { getMileage, RESERVATION_STATUSES } from '../../../../utils'
import { getDealer } from '../../../../utils/getDealer'
import { useQuery } from 'react-query'
import { NexusService } from '../../../../../global-APIs/nexus-service'
import { formatDealerAddress } from '../../../../../../utils/formatDealerAddress'

export function useAssignmentBlock({
  assignVehicle,
  customerTier,
  assignedVehicle,
  unAssignVehicle,
  reservationStatus,
  make,
}: {
  assignVehicle: (vehicleId: string) => void
  customerTier: QualificationTierMap[keyof QualificationTierMap]
  reload: boolean
  assignedVehicle: Vehicle.AsObject
  unAssignVehicle: () => void
  reservationStatus: string
  make?: string
}) {
  const [{ visibleAssignmentColumns, vehiclePage, vin }, setQueryParams] =
    useQueryParams({
      vehiclePage: withDefault(NumberParam, 0),
      visibleAssignmentColumns: withDefault(JsonParam, {
        color: true,
        make: true,
        model: true,
        mileage: true,
        trim: true,
        dealerName: true,
        dealerAddress: false,
        acquired: true,
        vin: true,
        assignment: true,
      }),
      vin: StringParam,
    })

  const criteria = [
    {
      property: 'make',
      query: make?.toLowerCase?.() || 'tesla',
    },
    {
      property: 'status',
      query: 'eligible',
    },
    ...(vin?.length
      ? [
          {
            property: 'vin',
            query: vin,
          },
        ]
      : []),
  ]

  // TODO - remove?
  // criteria.push({
  //   property: 'model',
  //   query: '',
  //   // query: isModel3 ? '3' : 'Y',
  // })

  const requestParams = {
    page: vehiclePage,
    customerId: '',
    criteriaList: criteria,
    resultsPerPage: 5,
  }

  const onColumnVisibilityModelChange = (newModel) => {
    setQueryParams({
      visibleAssignmentColumns: newModel,
    })
  }

  const setPage = (nextPage: number) => {
    setQueryParams({ vehiclePage: nextPage })
  }

  const setVIN = (nextVIN: string) => {
    setQueryParams({ vin: nextVIN })
  }

  const defaultVinModalState = {
    open: false,
    onClose: undefined,
    handleYesClick: undefined,
    headerText: '',
  }

  const [vinModal, setVinModal] = useState(defaultVinModalState)

  const { data: vehicles, isFetching } = useQuery(
    ['getVehicles', requestParams, vin, customerTier, assignedVehicle],
    async () => {
      if (assignedVehicle) {
        return {
          vehiclesList: await formatVehicles({
            vehicles: [],
            assignVehicle,
            customerTier,
            assignedVehicle,
            unAssignVehicle,
            reservationStatus,
            vinModal,
            setVinModal,
            defaultVinModalState,
          }),
          total: 1,
        }
      }

      return FleetService.getVehicles(requestParams).then(
        async (vehicleResponse) => {
          const vehicles = vehicleResponse.vehiclesList.map((v) => v.vehicle)

          return {
            vehiclesList: await formatVehicles({
              vehicles,
              assignVehicle,
              customerTier,
              assignedVehicle,
              unAssignVehicle,
              reservationStatus,
              vinModal,
              setVinModal,
              defaultVinModalState,
            }),
            total: vehicleResponse.total,
          }
        }
      )
    },
    {
      placeholderData: {
        vehiclesList: [],
        total: 0,
      },
      enabled: !!make,
    }
  )
  return {
    vehicles,
    visibleAssignmentColumns,
    onColumnVisibilityModelChange,
    setPage,
    vehiclePage,
    isFetching,
    setVIN,
    vin,
    vinModal,
    setVinModal,
    defaultVinModalState,
  }
}

const defaultTeslaImage =
  'https://d2ivfcfbdvj3sm.cloudfront.net/6fd260a869c389e6f8668ba55ef85f70ed0d4a500f1c3e8e88cc1df8ab/color_0640_001_png/MY2021/14913/14913_cc0640_001_PBSB.png'

const getAssignedVehicle = (
  assignedVehicle: Vehicle.AsObject,
  vehicles: Vehicle.AsObject[]
) => {
  if (assignedVehicle) {
    return { isAssignedVehicle: true, vehiclesList: [assignedVehicle] }
  }
  return { isAssignedVehicle: false, vehiclesList: [...vehicles] }
}
async function formatVehicles({
  vehicles,
  assignVehicle,

  assignedVehicle,
  unAssignVehicle,
  reservationStatus,
}: // vinModal,
// setVinModal,
// defaultVinModalState,
{
  vehicles: Vehicle.AsObject[]
  assignVehicle: (vehicleId: string) => void
  customerTier: QualificationTierMap[keyof QualificationTierMap]

  assignedVehicle: Vehicle.AsObject
  unAssignVehicle: () => void
  reservationStatus: string
  vinModal
  setVinModal
  defaultVinModalState
}): Promise<AssignableVehicle[]> {
  const filteredVehiclesByAssignment = getAssignedVehicle(
    assignedVehicle,
    vehicles
  )
  return Bluebird.map(
    filteredVehiclesByAssignment.vehiclesList,
    async (vehicle) => {
      // if the user is qualified or the vehicle is assigned this is not disabled
      const customerIsQualified =
        reservationStatus === RESERVATION_STATUSES.QUALIFIED ||
        reservationStatus === RESERVATION_STATUSES.ASSIGNED_VEHICLE ||
        reservationStatus === RESERVATION_STATUSES.ACTIVATED
      // ! This is for allowing unassignment
      // const vehicleIsTheOneAssigned =
      //   filteredVehiclesByAssignment.isAssignedVehicle
      const vehicleIsTheOneAssigned =
        filteredVehiclesByAssignment.isAssignedVehicle
      const enabled = customerIsQualified || vehicleIsTheOneAssigned
      const text = filteredVehiclesByAssignment.isAssignedVehicle
        ? 'Unassign'
        : 'Assign'

      const imgPath =
        vehicle.profilesList[0]?.mediaListList?.[
          findMediaIndex(vehicle)
        ]?.data.toString()
      // NOTE: this currently always returns undefined ( findMediaIndexColor returns -1, because the
      // color props in mediaLists are empty strings ). I think BE fix needed here, then the color would be
      // matched and it would return the correct media index and data path with the image URL

      const latestCustody = await NexusService.getLatestCustody({
        vehicleId: vehicle.id,
      })

      return {
        id: vehicle.id || 'id',
        make: vehicle.make,
        bodyType: get(
          vehicle,
          imgPath, // see note above
          defaultTeslaImage // fallback
        ),
        model: vehicle.model,
        mileage: getMileage(vehicle),
        color: getExteriorColor(vehicle),
        acquired: getDateAcquired(vehicle),
        vin: vehicle.vin,
        text,
        dealerAddress: formatDealerAddress(latestCustody?.dealership?.address),
        dealerName: latestCustody?.dealership?.name,
        trim: vehicle.trim,
        disabled: !enabled,
        status: vehicle.state,
        assignVehicle: () => {
          if (filteredVehiclesByAssignment.isAssignedVehicle) {
            unAssignVehicle()
            // setVinModal({...vinModal, open: true, handleYesClick: () => {unAssignVehicle(); setVinModal(defaultVinModalState)}, headerText: text})
          } else {
            assignVehicle(vehicle.id)
            // setVinModal({...vinModal, open: true, handleYesClick: () => {assignVehicle(vehicle.id); setVinModal(defaultVinModalState)}, headerText: text})
          }
        },
      }
    }
  )
}

function getExteriorColor(vehicle: Vehicle.AsObject) {
  return vehicle?.exteriorColorsList?.[0]?.title
}

function findMediaIndex(vehicle) {
  return vehicle?.profilesList[0]?.mediaListList?.findIndex(
    ({ color }) => getExteriorColor(vehicle) === color
  )
}

function getDateAcquired(vehicle: Vehicle.AsObject) {
  const hasCustodiesList = !!vehicle.custodiesList

  if (!hasCustodiesList) {
    console.error(`Vehicle ID#: ${vehicle.id} missing custodies list`)
    return 'N/A'
  }

  const currentDealer = getDealer(vehicle)

  if (currentDealer) {
    const dateAcquired = new Date(currentDealer?.date)

    return format(dateAcquired, 'MM/dd/yy')
  }

  return 'N/A'
}

export interface AssignableVehicle {
  id: string
  make: string
  bodyType: string
  model: string
  mileage: string
  color: string
  acquired: string
  vin: string
  trim: string
  dealerAddress: string
  dealerName: string
}
