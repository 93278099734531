import React, { FC } from 'react'
import { Typography, Box, Card, Link } from '@mui/material'
import { DataGrid, GridColumns } from '@mui/x-data-grid'
import { useFleetTable } from './useFleetTable'

export const FleetTable: FC = () => {

  const {
    vehicles,
    total,
    page,
    pageSize,
    setPage,
    setPageSize,
    loadingState,
  } = useFleetTable()

  const columns: GridColumns = [
    {
      field: 'customerName',
      headerName: 'Customer Name',
      cellClassName: 'primaryAccent',
      flex: 1,
      renderCell: (props) => (
        <Link sx={{ fontWeight: 'bold' }} href={`/customers/${props.row.customerId}`}>
          {props.row.customerName}
        </Link>
      ),
    },
    {
      field: 'accountNumber',
      headerName: 'Account Number',
      flex: 1,
    },
    {
      field: 'vin',
      headerName: 'VIN',
      flex: 1,
      renderCell: (props) => (
        <Link sx={{ fontWeight: 'bold' }} href={`/view/fleet/${props.row.id}`}>
          {props.row.vin}
        </Link>
      ),
    },
    {
      field: 'subscriptionStart',
      headerName: 'Subscription Start',
      flex: 1,
    },
    {
      field: 'postal',
      headerName: 'ZIP Code',
      flex: 0.5,
    },
    {
      field: 'modelTrim',
      headerName: 'Model/Trim',
      flex: 1,
    },
    {
      field: 'accountStatus',
      headerName: 'Account Status',
      flex: 1,
      renderCell: (props) => (
        <Typography className="secondaryAccent">
          {props.row.accountStatus}
        </Typography>
      ),
    },
  ]

  return (
    <Box>
      <Typography sx={{ marginLeft: '5px' }} variant="h1">
        Active Vehicles
      </Typography>
      <Card>
        <DataGrid
          columns={columns}
          rows={vehicles}
          rowCount={total}
          pageSize={pageSize}
          page={page}
          autoHeight
          loading={loadingState.isLoading}
          paginationMode="server"
          onPageChange={(newPage) => setPage(newPage)}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        />
      </Card>
    </Box>
  )
}
