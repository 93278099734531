import * as _ from 'lodash'
import { useCallback,  useState } from 'react'
import { useQuery } from 'react-query'
import { MapVehicle } from '@nxcr-org/web-api-interface/lib/nexus_service_pb'
import { GeoJSONSource, useMap as useMapBoxMap } from 'react-map-gl'
import { NexusService } from 'global-apis/nexus-service'
import { Modifier } from '@nxcr-org/web-api-interface/lib/web_pb'
import { useMapQuery } from '../useMapQuery'


export function useMap() {
  const [selectedVehicle, setSelectedVehicle] = useState<MapVehicle.AsObject | false>(false)  
  const map = useMapBoxMap()
  const [{ status, customerName, make, model, vin }] = useMapQuery()

  const initialViewState = {
    latitude: 37.67521882607042,
    longitude: -97.4297319194973,
    zoom: 4,
  }

  const selectVehicle = ({
    vehicle,
    customer
  }: {
    vehicle: string
    customer: string 
  }) => {
    setSelectedVehicle({
      vehicle: JSON.parse(vehicle),
      customer: JSON.parse(customer)
    })
  }

  const deselectVehicle = () => {
    setSelectedVehicle(false)
  }

  const onClick = useCallback((event) => {
    console.log(event.features)
    const feature = event.features && event.features[0]
    const featureIsCluster = feature?.properties?.cluster
    const featureIsVehicle =
      !!feature?.properties?.vehicle && !!feature?.properties?.customer
      
    if (featureIsVehicle) {
      return selectVehicle({
        vehicle: feature?.properties?.vehicle,
        customer: feature?.properties?.customer
      })
    }

    if(featureIsCluster){
      const clusterId = feature.properties.cluster_id
      const source = map.assets.getSource('car-source') as GeoJSONSource
      return source?.getClusterExpansionZoom(clusterId, (err, zoom) => {
        if (err) {
          return
        }

        map.assets.easeTo({
          center: feature.geometry.coordinates,
          zoom,
          duration: 500,
        })
      })
      
    }

    return deselectVehicle()
  }, [map])

  const { data: vehicles } = useQuery(
    ['/vehicles', status, make, model, vin, customerName],
    async () => {
      return NexusService.getVehicles({
        status: {
          queryList: [
            {
              value: status,
              property: 'status',
              modifier: Modifier.EQUAL_TO,
            },
          ],
          orderList: [],
          pagination: {
            limit: 10000,
            offset: 0,
          },
        },
        vehicleModel: model,
        vehicleMake: make,
        vehicleVin: vin,
        customerId: '',
        customerName: customerName,
        level: 0,
        maxItems: 0,
        vehicleId: '',
        geoReverseLookup: false,
      })
        .then(async (res) => {
          console.log({ res })
          return {
            vehicles: res.vehiclesList,
            geoJSON: generateGeoJSON(res.vehiclesList),
          }
        })
        .catch((err) => {
          // TODO: Should show a toast or message
          console.log({ err })
          return {
            vehicles: [],
            geoJSON: {
              type: 'FeatureCollection',
              features: [],
            } as GeoJSON.FeatureCollection<GeoJSON.Geometry>,
          }
        })
    },
    {
      refetchInterval: 25000,
      placeholderData: {
        vehicles: [],
        geoJSON: {
          type: 'FeatureCollection',
          features: [],
        },
      },
    }
  )

  return {
    onClick,
    vehicles,
    initialViewState,
    selectedVehicle,
    deselectVehicle,
  }
}


function generateGeoJSON(
  vehicles: MapVehicle.AsObject[]
): GeoJSON.FeatureCollection<GeoJSON.Geometry> {
  return {
    type: 'FeatureCollection',
    features: _.chain(vehicles)
      .filter((fleetVehicle) => {
        return (
          !!fleetVehicle.vehicle.locationLat &&
          !!fleetVehicle.vehicle.locationLng
        )
      })
      .map((fleetVehicle) => {
        return {
          type: 'Feature' as const,
          geometry: {
            type: 'Point' as const,
            coordinates: [
              Number.parseFloat(fleetVehicle.vehicle.locationLng),
              Number.parseFloat(fleetVehicle.vehicle.locationLat),
            ],
          },
          id: fleetVehicle.vehicle.id,
          properties: {
            vehicleId: fleetVehicle?.vehicle?.id,
            vehicle: fleetVehicle?.vehicle,
            customer: fleetVehicle?.customer,
          },
        }
      })
      .value(),
  }
}
