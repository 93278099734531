import {
  GetCustomerDocumentRequest,
  GetCustomerDocumentsRequest,
} from '@nxcr-org/web-api-interface/lib/document_service_pb'
import { DocumentServicePromiseClient } from '@nxcr-org/web-api-interface/lib/gateway_service_grpc_web_pb'
import { oktaAuth, OktaAuthInterceptor } from '../../okta/config'

import { Env } from '../fleet-management/api/env'

function getDocumentService(): DocumentServicePromiseClient {
  const idToken = oktaAuth.getIdToken()
  const target = Env.endpoints.fleet

  return new DocumentServicePromiseClient(target, null, {
    unaryInterceptors: [new OktaAuthInterceptor(idToken)],
    'grpc-node.max_session_memory': 1000,
    'grpc-node.max_send_message_length': 1024 * 1024 * 100,
    'grpc-node.max_receive_message_length': 1024 * 1024 * 100,
  })
}

export async function getCustomerDocuments(customerId) {
  const client = getDocumentService()

  const request = new GetCustomerDocumentsRequest()
  request.setCustomerid(customerId)

  return client.getCustomerDocuments(request).then((res) => {
    return res.toObject()
  })
}

export async function getCustomerDocument(customerId, documentId) {
  const client = getDocumentService()

  const request = new GetCustomerDocumentRequest()
  request.setCustomerid(customerId)
  request.setDocumentId(documentId)

  return client.getCustomerDocument(request).then((res) => {
    return res.toObject()
  })
}
