import React from 'react'
import { Grid, Typography } from '@mui/material'
import { DataGrid, GridColumns } from '@mui/x-data-grid'

import { getGridRowClass } from '../../../utils/getGridRowClass'
import useInsurance from '../useInsurance'

const InsuredDrivers = ({ subscriptionId }) => {
  const { useInsuredDrivers } = useInsurance()
  const { insuredDrivers } = useInsuredDrivers(subscriptionId)

  return (
    <Grid container spacing={2} p={3} direction="row">
      <Grid item xs={12}>
        <Grid item xs={12}>
          <Typography variant="h6">Insured Drivers</Typography>
        </Grid>

        <DataGrid
          autoHeight
          rowHeight={35}
          sx={{ pt: 2 }}
          columns={contractColumns}
          getRowClassName={(params) =>
            getGridRowClass(params.indexRelativeToCurrentPage)
          }
          rows={insuredDrivers || []}
        />
      </Grid>
    </Grid>
  )
}

export default InsuredDrivers
const contractColumns: GridColumns = [
  {
    field: 'firstName',
    headerName: 'First Name',
    width: 300,
  },
  {
    field: 'lastName',
    headerName: 'Last Name',
    width: 300,
  },
  {
    field: 'dateOfBirth',
    headerName: 'Date of birth',
    width: 150,
  },
  {
    field: 'isprimary',
    headerName: 'Primary',
    width: 150,
    renderCell: (props) => (props.row.isprimary ? 'Yes' : 'No'),
  },
]
