import {
  Box,
  Drawer,
  List,
  ListSubheader,
  Divider,
  Typography,
  Button,
  useMediaQuery,
  Theme,
  Toolbar,
} from '@mui/material'
import { map } from 'lodash'
import React, { FC } from 'react'
import { autonomyColor } from '../../global-styles/autonomyColor'


import { NexusRouteGroup } from './layout.types'
import { SidebarItem } from './SidebarItem'



export const Sidebar: FC<{
  routeGroups: NexusRouteGroup[]
  isSidebarOpen: boolean
  toggleSidebar: () => void
}> = ({ routeGroups, isSidebarOpen, toggleSidebar }) => {

  
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'), {
    noSsr: true,
  })

  const content = (
    <>
      <Toolbar />
      {map(routeGroups, (routeGroup) => {
        return (
          <List
            key={routeGroup.label}
            subheader={
              <ListSubheader
                disableGutters
                disableSticky
                sx={{
                  color: 'neutral.500',
                  fontSize: '0.75rem',
                  fontWeight: 700,
                  lineHeight: 2.5,
                  ml: 2,
                  textTransform: 'uppercase',
                }}
              >
                {routeGroup.label}
              </ListSubheader>
            }
          >
            {map(routeGroup.routes, (route) => {
              return (
                <SidebarItem
                  key={route.label}
                  exact={route.exact}
                  target={route.target}
                  label={route.label}
                  icon={route.icon}
                />
              )
            })}
          </List>
        )
      })}
      <Divider />
      <Box
        sx={{
          paddingTop: 10,
          padding: 2,
        }}
      >
        <Typography variant="subtitle1">Need Help?</Typography>
        <Typography variant="caption">Join the slack channel!</Typography>
        <Button
          variant="contained"
          target="_blank"
          href="https://slack.com/app_redirect?channel=C03NYPE9L9K"
        >
          #nexus-support
        </Button>
      </Box>
      <div style={{ flex: 1 }} />
    </>
  )
  
  if (lgUp) {
    return (
      <>
        
        <Drawer
          anchor="left"
          onClose={toggleSidebar}
          open={isSidebarOpen}
          PaperProps={{
            sx: {
              backgroundColor: autonomyColor.deepBlue,
              borderRightColor: 'divider',
              borderRightStyle: 'solid',
              borderRightWidth: (theme) =>
                theme.palette.mode === 'dark' ? 1 : 0,
              color: '#FFFFFF',
              width: 280,
            },
          }}
          variant="persistent"
        >
          {content}
        </Drawer>
      </>
    )
  }

  return (
    <Drawer
      anchor="left"
      onClose={toggleSidebar}
      open={isSidebarOpen}
      PaperProps={{
        sx: {
          backgroundColor: autonomyColor.deepBlue,
          color: '#FFFFFF',
          width: 280,
        },
      }}
      sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
      variant="temporary"
    >
      {content}
    </Drawer>
  )
}
