import { NexusServicePromiseClient } from '@nxcr-org/web-api-interface/lib/nexus_service_grpc_web_pb'
import {
  SubscriptionPickupDateRequest,
  SetReservationProgramRequest,
  ListAppointmentsRequest,
  CreateAppointmentRequest,
  CancelAppointmentRequest,
  UpdateAppointmentRequest,
  ListDealershipsRequest,
} from '@nxcr-org/web-api-interface/lib/nexus_service_pb'
import { oktaAuth, OktaAuthInterceptor } from '../../okta/config'
import { Env } from '../fleet-management/api/env'
import { isBefore } from 'date-fns'

import {
  formatAppointmentListItem,
  IAppointmentListItem,
} from '../scheduling-tool/tasks/utils'
import { spyOnResponse, spyOnError } from '../../utils/spyOnResponse'

export const SchedulingService = {
  listAppointments,
  listDealerships,
}

function getNexusService(): NexusServicePromiseClient {
  const idToken = oktaAuth.getIdToken()
  const target = Env.endpoints.fleet

  return new NexusServicePromiseClient(target, null, {
    unaryInterceptors: [new OktaAuthInterceptor(idToken)],
    'grpc-node.max_session_memory': 1000,
    'grpc-node.max_send_message_length': 1024 * 1024 * 100,
    'grpc-node.max_receive_message_length': 1024 * 1024 * 100,
  })
}

export function buildSetPickupDate(
  params: Omit<SubscriptionPickupDateRequest.AsObject, 'pickupDateUnix'> & {
    pickupDate: Date
  }
) {
  const request = new SubscriptionPickupDateRequest()

  if (!params.customerId) {
    throw new Error('Customer ID must be provided.')
  }

  if (!params.subscriptionId) {
    throw new Error('Subscription ID must be provided.')
  }

  if (isBefore(params.pickupDate, new Date())) {
    throw new Error('Pick up date must be in the future.')
  }

  request.setCustomerId(params.customerId)
  request.setSubscriptionId(params.subscriptionId)
  request.setPickupDateUnix(params.pickupDate.getTime())

  return request
}

export async function setPickupDate(
  params: Omit<SubscriptionPickupDateRequest.AsObject, 'pickupDateUnix'> & {
    pickupDate: Date
  }
) {
  const client = getNexusService()
  const request = buildSetPickupDate(params)

  return client.setSubscriptionPickupDate(request).then((res) => {
    return res.toObject()
  })
}

export function buildSetReservationProgramRequest(
  params: SetReservationProgramRequest.AsObject
) {
  const request = new SetReservationProgramRequest()

  if (!params.programId || params.programId.length === 0) {
    throw new Error('Program ID must be provided.')
  }

  if (!params.customerId || params.customerId.length === 0) {
    throw new Error('Customer ID must be provided.')
  }

  if (!params.reservationId || params.reservationId.length === 0) {
    throw new Error('Reservation ID must be provided.')
  }

  if (!params.monthly) {
    throw new Error('Monthly amount must be provided.')
  }

  request.setProgramId(params.programId)
  request.setCustomerId(params.customerId)
  request.setReservationId(params.reservationId)

  request.setMonthly(params.monthly)
  request.setStartFee(params.startFee)
  request.setPriceTermMonths(params.priceTermMonths)

  return request
}

export async function setReservationProgram(
  params: SetReservationProgramRequest.AsObject
) {
  const client = getNexusService()
  const request = buildSetReservationProgramRequest(params)

  return client
    .setReservationProgram(request)
    .then(spyOnResponse('setReservationProgram'))
    .catch(spyOnError('setReservationProgramError'))
}

export async function listAppointments(
  params: Partial<ListAppointmentsRequest.AsObject>
) {
  const client = getNexusService()
  const request = new ListAppointmentsRequest()

  request.setDealershipId(params.dealershipId)
  request.setDaysOut(365)

  return client.listAppointments(request).then((res) => res.toObject())
}

export async function listAppointmentsByAccountNumber(accountNumber: string) {
  const client = getNexusService()
  const request = new ListAppointmentsRequest()

  request.setAccountNumber(accountNumber)
  request.setDaysOut(365)

  return client.listAppointments(request).then((res) => res.toObject())
}

export function buildCancellationRequest(appointmentId: string) {
  const request = new CancelAppointmentRequest()

  if (!appointmentId) {
    throw new Error('Appointment ID must be provided.')
  }

  request.setAppointmentId(appointmentId)
  return request
}

export async function cancelAppointment(appointmentId: string) {
  const client = getNexusService()
  const request = buildCancellationRequest(appointmentId)

  return client.cancelAppointment(request).then((res) => res.toObject())
}

export function createAppointment(appointment: IAppointmentListItem) {
  const client = getNexusService()

  const request = buildCreateAppointmentRequest(appointment)
  return client
    .createAppointment(request)
    .then(spyOnResponse('create appointment'))
    .then((res) => {
      return res.toObject()
    })
}

export function buildCreateAppointmentRequest(
  appointment: IAppointmentListItem
) {
  const request = new CreateAppointmentRequest()

  if (!appointment.dealershipId) {
    throw new Error('Dealership ID must be provided')
  }
  if (!appointment.customerId) {
    throw new Error('Customer ID must be provided')
  }
  if (!appointment.timeZone) {
    throw new Error('Timezone must be provided')
  }
  if (appointment.note && !appointment.noteAuthor) {
    throw new Error('Note author must be set')
  }

  request.setReservationId(appointment.reservationId)
  request.setDealerId(appointment.dealershipId)
  request.setCustomerId(appointment.customerId)
  request.setStartTime(appointment.startTime)
  request.setDuration(appointment.duration)
  request.setTimeZone(appointment.timeZone)
  request.setAppointmentType(appointment.type)
  request.setNote(appointment.note)
  request.setNoteAuthor(appointment.noteAuthor)
  return request
}

export async function updateAppointment(appointment) {
  const client = getNexusService()
  const request = buildUpdateAppointmentRequest(appointment)

  return client
    .updateAppointment(request)
    .then(spyOnResponse('update appointment'))
    .then((res) => {
      return res.toObject()
    })
}

export function buildUpdateAppointmentRequest(
  appointment: IAppointmentListItem
) {
  const request = new UpdateAppointmentRequest()

  request.setAppointment(formatAppointmentListItem(appointment))

  return request
}

async function listDealerships() {
  const client = getNexusService()
  const request = new ListDealershipsRequest()

  return client.listDealerships(request).then((res) => {
    return res.toObject()
  })
}
