import { LayerProps } from 'react-map-gl'

export const clusterLayer: LayerProps = {
  id: 'point',
  type: 'circle',
  filter: ['has', 'point_count'],
  paint: {
    'circle-color': [
      'step',
      ['get', 'point_count'],
      '#FF8CDD',
      100,
      '#FF8CDD',
      750,
      '#FF8CDD',
    ],
    'circle-radius': ['step', ['get', 'point_count'], 15, 100, 20, 750, 40],
  },
}

export const clusterCountLayer: LayerProps = {
  id: 'cluster-count',
  type: 'symbol',
  source: 'clusteredVehicles',
  filter: ['has', 'point_count'],
  layout: {
    'text-field': '{point_count_abbreviated}',
    'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
    'text-size': 16,
  },
  paint: {
    'text-color': '#ffffff',
  },
}

export const unclusteredPointLayer: LayerProps = {
  id: 'unclustered-point',
  type: 'circle',
  source: 'vehicles',
  filter: ['!', ['has', 'point_count']],
  paint: {
    'circle-color': '#FF8CDD',
    'circle-radius': 7,
  },
}
