// source: vendor_invoicing.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var domain_pb = require('./domain_pb.js');
goog.object.extend(proto, domain_pb);
goog.exportSymbol('proto.vendor_invoicing.CancelOrderStatementRequest', null, global);
goog.exportSymbol('proto.vendor_invoicing.CancelOrderStatementResponse', null, global);
goog.exportSymbol('proto.vendor_invoicing.CreateMultiItemOrderRequest', null, global);
goog.exportSymbol('proto.vendor_invoicing.OrderItem', null, global);
goog.exportSymbol('proto.vendor_invoicing.OrderStatementCriteria', null, global);
goog.exportSymbol('proto.vendor_invoicing.RetrieveOrderStatementsRequest', null, global);
goog.exportSymbol('proto.vendor_invoicing.RetrieveOrderStatementsResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vendor_invoicing.CancelOrderStatementRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vendor_invoicing.CancelOrderStatementRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vendor_invoicing.CancelOrderStatementRequest.displayName = 'proto.vendor_invoicing.CancelOrderStatementRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vendor_invoicing.CancelOrderStatementResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vendor_invoicing.CancelOrderStatementResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vendor_invoicing.CancelOrderStatementResponse.displayName = 'proto.vendor_invoicing.CancelOrderStatementResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vendor_invoicing.CreateMultiItemOrderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vendor_invoicing.CreateMultiItemOrderRequest.repeatedFields_, null);
};
goog.inherits(proto.vendor_invoicing.CreateMultiItemOrderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vendor_invoicing.CreateMultiItemOrderRequest.displayName = 'proto.vendor_invoicing.CreateMultiItemOrderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vendor_invoicing.OrderItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vendor_invoicing.OrderItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vendor_invoicing.OrderItem.displayName = 'proto.vendor_invoicing.OrderItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vendor_invoicing.RetrieveOrderStatementsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vendor_invoicing.RetrieveOrderStatementsRequest.repeatedFields_, null);
};
goog.inherits(proto.vendor_invoicing.RetrieveOrderStatementsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vendor_invoicing.RetrieveOrderStatementsRequest.displayName = 'proto.vendor_invoicing.RetrieveOrderStatementsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vendor_invoicing.OrderStatementCriteria = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vendor_invoicing.OrderStatementCriteria, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vendor_invoicing.OrderStatementCriteria.displayName = 'proto.vendor_invoicing.OrderStatementCriteria';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vendor_invoicing.RetrieveOrderStatementsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vendor_invoicing.RetrieveOrderStatementsResponse.repeatedFields_, null);
};
goog.inherits(proto.vendor_invoicing.RetrieveOrderStatementsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vendor_invoicing.RetrieveOrderStatementsResponse.displayName = 'proto.vendor_invoicing.RetrieveOrderStatementsResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vendor_invoicing.CancelOrderStatementRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vendor_invoicing.CancelOrderStatementRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vendor_invoicing.CancelOrderStatementRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vendor_invoicing.CancelOrderStatementRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    orderNumber: jspb.Message.getFieldWithDefault(msg, 2, ""),
    accountNumber: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vendor_invoicing.CancelOrderStatementRequest}
 */
proto.vendor_invoicing.CancelOrderStatementRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vendor_invoicing.CancelOrderStatementRequest;
  return proto.vendor_invoicing.CancelOrderStatementRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vendor_invoicing.CancelOrderStatementRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vendor_invoicing.CancelOrderStatementRequest}
 */
proto.vendor_invoicing.CancelOrderStatementRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderNumber(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vendor_invoicing.CancelOrderStatementRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vendor_invoicing.CancelOrderStatementRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vendor_invoicing.CancelOrderStatementRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vendor_invoicing.CancelOrderStatementRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrderNumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAccountNumber();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.vendor_invoicing.CancelOrderStatementRequest.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vendor_invoicing.CancelOrderStatementRequest} returns this
 */
proto.vendor_invoicing.CancelOrderStatementRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string order_number = 2;
 * @return {string}
 */
proto.vendor_invoicing.CancelOrderStatementRequest.prototype.getOrderNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vendor_invoicing.CancelOrderStatementRequest} returns this
 */
proto.vendor_invoicing.CancelOrderStatementRequest.prototype.setOrderNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string account_number = 3;
 * @return {string}
 */
proto.vendor_invoicing.CancelOrderStatementRequest.prototype.getAccountNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vendor_invoicing.CancelOrderStatementRequest} returns this
 */
proto.vendor_invoicing.CancelOrderStatementRequest.prototype.setAccountNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vendor_invoicing.CancelOrderStatementResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vendor_invoicing.CancelOrderStatementResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vendor_invoicing.CancelOrderStatementResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vendor_invoicing.CancelOrderStatementResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    statementId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    accountNumber: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vendor_invoicing.CancelOrderStatementResponse}
 */
proto.vendor_invoicing.CancelOrderStatementResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vendor_invoicing.CancelOrderStatementResponse;
  return proto.vendor_invoicing.CancelOrderStatementResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vendor_invoicing.CancelOrderStatementResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vendor_invoicing.CancelOrderStatementResponse}
 */
proto.vendor_invoicing.CancelOrderStatementResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatementId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vendor_invoicing.CancelOrderStatementResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vendor_invoicing.CancelOrderStatementResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vendor_invoicing.CancelOrderStatementResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vendor_invoicing.CancelOrderStatementResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getStatementId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAccountNumber();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.vendor_invoicing.CancelOrderStatementResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vendor_invoicing.CancelOrderStatementResponse} returns this
 */
proto.vendor_invoicing.CancelOrderStatementResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string statement_id = 2;
 * @return {string}
 */
proto.vendor_invoicing.CancelOrderStatementResponse.prototype.getStatementId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vendor_invoicing.CancelOrderStatementResponse} returns this
 */
proto.vendor_invoicing.CancelOrderStatementResponse.prototype.setStatementId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string account_number = 3;
 * @return {string}
 */
proto.vendor_invoicing.CancelOrderStatementResponse.prototype.getAccountNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vendor_invoicing.CancelOrderStatementResponse} returns this
 */
proto.vendor_invoicing.CancelOrderStatementResponse.prototype.setAccountNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vendor_invoicing.CreateMultiItemOrderRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vendor_invoicing.CreateMultiItemOrderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vendor_invoicing.CreateMultiItemOrderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vendor_invoicing.CreateMultiItemOrderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vendor_invoicing.CreateMultiItemOrderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountNumber: jspb.Message.getFieldWithDefault(msg, 1, ""),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.vendor_invoicing.OrderItem.toObject, includeInstance),
    orderDate: jspb.Message.getFieldWithDefault(msg, 3, 0),
    invoiceOrder: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vendor_invoicing.CreateMultiItemOrderRequest}
 */
proto.vendor_invoicing.CreateMultiItemOrderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vendor_invoicing.CreateMultiItemOrderRequest;
  return proto.vendor_invoicing.CreateMultiItemOrderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vendor_invoicing.CreateMultiItemOrderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vendor_invoicing.CreateMultiItemOrderRequest}
 */
proto.vendor_invoicing.CreateMultiItemOrderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNumber(value);
      break;
    case 2:
      var value = new proto.vendor_invoicing.OrderItem;
      reader.readMessage(value,proto.vendor_invoicing.OrderItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOrderDate(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInvoiceOrder(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vendor_invoicing.CreateMultiItemOrderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vendor_invoicing.CreateMultiItemOrderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vendor_invoicing.CreateMultiItemOrderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vendor_invoicing.CreateMultiItemOrderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountNumber();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.vendor_invoicing.OrderItem.serializeBinaryToWriter
    );
  }
  f = message.getOrderDate();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getInvoiceOrder();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string account_number = 1;
 * @return {string}
 */
proto.vendor_invoicing.CreateMultiItemOrderRequest.prototype.getAccountNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vendor_invoicing.CreateMultiItemOrderRequest} returns this
 */
proto.vendor_invoicing.CreateMultiItemOrderRequest.prototype.setAccountNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated OrderItem items = 2;
 * @return {!Array<!proto.vendor_invoicing.OrderItem>}
 */
proto.vendor_invoicing.CreateMultiItemOrderRequest.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.vendor_invoicing.OrderItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vendor_invoicing.OrderItem, 2));
};


/**
 * @param {!Array<!proto.vendor_invoicing.OrderItem>} value
 * @return {!proto.vendor_invoicing.CreateMultiItemOrderRequest} returns this
*/
proto.vendor_invoicing.CreateMultiItemOrderRequest.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.vendor_invoicing.OrderItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vendor_invoicing.OrderItem}
 */
proto.vendor_invoicing.CreateMultiItemOrderRequest.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.vendor_invoicing.OrderItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vendor_invoicing.CreateMultiItemOrderRequest} returns this
 */
proto.vendor_invoicing.CreateMultiItemOrderRequest.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional int64 order_date = 3;
 * @return {number}
 */
proto.vendor_invoicing.CreateMultiItemOrderRequest.prototype.getOrderDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vendor_invoicing.CreateMultiItemOrderRequest} returns this
 */
proto.vendor_invoicing.CreateMultiItemOrderRequest.prototype.setOrderDate = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bool invoice_order = 4;
 * @return {boolean}
 */
proto.vendor_invoicing.CreateMultiItemOrderRequest.prototype.getInvoiceOrder = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vendor_invoicing.CreateMultiItemOrderRequest} returns this
 */
proto.vendor_invoicing.CreateMultiItemOrderRequest.prototype.setInvoiceOrder = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional string subscription_id = 5;
 * @return {string}
 */
proto.vendor_invoicing.CreateMultiItemOrderRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.vendor_invoicing.CreateMultiItemOrderRequest} returns this
 */
proto.vendor_invoicing.CreateMultiItemOrderRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vendor_invoicing.OrderItem.prototype.toObject = function(opt_includeInstance) {
  return proto.vendor_invoicing.OrderItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vendor_invoicing.OrderItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vendor_invoicing.OrderItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    amount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    productType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    quantity: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vendor_invoicing.OrderItem}
 */
proto.vendor_invoicing.OrderItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vendor_invoicing.OrderItem;
  return proto.vendor_invoicing.OrderItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vendor_invoicing.OrderItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vendor_invoicing.OrderItem}
 */
proto.vendor_invoicing.OrderItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAmount(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {!proto.domain.Product.ProductType} */ (reader.readEnum());
      msg.setProductType(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setQuantity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vendor_invoicing.OrderItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vendor_invoicing.OrderItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vendor_invoicing.OrderItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vendor_invoicing.OrderItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAmount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProductType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getQuantity();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional int32 amount = 2;
 * @return {number}
 */
proto.vendor_invoicing.OrderItem.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vendor_invoicing.OrderItem} returns this
 */
proto.vendor_invoicing.OrderItem.prototype.setAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.vendor_invoicing.OrderItem.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vendor_invoicing.OrderItem} returns this
 */
proto.vendor_invoicing.OrderItem.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional domain.Product.ProductType product_type = 4;
 * @return {!proto.domain.Product.ProductType}
 */
proto.vendor_invoicing.OrderItem.prototype.getProductType = function() {
  return /** @type {!proto.domain.Product.ProductType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.domain.Product.ProductType} value
 * @return {!proto.vendor_invoicing.OrderItem} returns this
 */
proto.vendor_invoicing.OrderItem.prototype.setProductType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional int32 quantity = 5;
 * @return {number}
 */
proto.vendor_invoicing.OrderItem.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.vendor_invoicing.OrderItem} returns this
 */
proto.vendor_invoicing.OrderItem.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vendor_invoicing.RetrieveOrderStatementsRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vendor_invoicing.RetrieveOrderStatementsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vendor_invoicing.RetrieveOrderStatementsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vendor_invoicing.RetrieveOrderStatementsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vendor_invoicing.RetrieveOrderStatementsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountNumber: jspb.Message.getFieldWithDefault(msg, 1, ""),
    criteriaList: jspb.Message.toObjectList(msg.getCriteriaList(),
    proto.vendor_invoicing.OrderStatementCriteria.toObject, includeInstance),
    customerId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vendor_invoicing.RetrieveOrderStatementsRequest}
 */
proto.vendor_invoicing.RetrieveOrderStatementsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vendor_invoicing.RetrieveOrderStatementsRequest;
  return proto.vendor_invoicing.RetrieveOrderStatementsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vendor_invoicing.RetrieveOrderStatementsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vendor_invoicing.RetrieveOrderStatementsRequest}
 */
proto.vendor_invoicing.RetrieveOrderStatementsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNumber(value);
      break;
    case 2:
      var value = new proto.vendor_invoicing.OrderStatementCriteria;
      reader.readMessage(value,proto.vendor_invoicing.OrderStatementCriteria.deserializeBinaryFromReader);
      msg.addCriteria(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vendor_invoicing.RetrieveOrderStatementsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vendor_invoicing.RetrieveOrderStatementsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vendor_invoicing.RetrieveOrderStatementsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vendor_invoicing.RetrieveOrderStatementsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountNumber();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCriteriaList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.vendor_invoicing.OrderStatementCriteria.serializeBinaryToWriter
    );
  }
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string account_number = 1;
 * @return {string}
 */
proto.vendor_invoicing.RetrieveOrderStatementsRequest.prototype.getAccountNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vendor_invoicing.RetrieveOrderStatementsRequest} returns this
 */
proto.vendor_invoicing.RetrieveOrderStatementsRequest.prototype.setAccountNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated OrderStatementCriteria criteria = 2;
 * @return {!Array<!proto.vendor_invoicing.OrderStatementCriteria>}
 */
proto.vendor_invoicing.RetrieveOrderStatementsRequest.prototype.getCriteriaList = function() {
  return /** @type{!Array<!proto.vendor_invoicing.OrderStatementCriteria>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vendor_invoicing.OrderStatementCriteria, 2));
};


/**
 * @param {!Array<!proto.vendor_invoicing.OrderStatementCriteria>} value
 * @return {!proto.vendor_invoicing.RetrieveOrderStatementsRequest} returns this
*/
proto.vendor_invoicing.RetrieveOrderStatementsRequest.prototype.setCriteriaList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.vendor_invoicing.OrderStatementCriteria=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vendor_invoicing.OrderStatementCriteria}
 */
proto.vendor_invoicing.RetrieveOrderStatementsRequest.prototype.addCriteria = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.vendor_invoicing.OrderStatementCriteria, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vendor_invoicing.RetrieveOrderStatementsRequest} returns this
 */
proto.vendor_invoicing.RetrieveOrderStatementsRequest.prototype.clearCriteriaList = function() {
  return this.setCriteriaList([]);
};


/**
 * optional string customer_id = 3;
 * @return {string}
 */
proto.vendor_invoicing.RetrieveOrderStatementsRequest.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vendor_invoicing.RetrieveOrderStatementsRequest} returns this
 */
proto.vendor_invoicing.RetrieveOrderStatementsRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vendor_invoicing.OrderStatementCriteria.prototype.toObject = function(opt_includeInstance) {
  return proto.vendor_invoicing.OrderStatementCriteria.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vendor_invoicing.OrderStatementCriteria} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vendor_invoicing.OrderStatementCriteria.toObject = function(includeInstance, msg) {
  var f, obj = {
    property: jspb.Message.getFieldWithDefault(msg, 1, ""),
    query: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vendor_invoicing.OrderStatementCriteria}
 */
proto.vendor_invoicing.OrderStatementCriteria.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vendor_invoicing.OrderStatementCriteria;
  return proto.vendor_invoicing.OrderStatementCriteria.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vendor_invoicing.OrderStatementCriteria} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vendor_invoicing.OrderStatementCriteria}
 */
proto.vendor_invoicing.OrderStatementCriteria.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProperty(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuery(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vendor_invoicing.OrderStatementCriteria.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vendor_invoicing.OrderStatementCriteria.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vendor_invoicing.OrderStatementCriteria} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vendor_invoicing.OrderStatementCriteria.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProperty();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getQuery();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string property = 1;
 * @return {string}
 */
proto.vendor_invoicing.OrderStatementCriteria.prototype.getProperty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vendor_invoicing.OrderStatementCriteria} returns this
 */
proto.vendor_invoicing.OrderStatementCriteria.prototype.setProperty = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string query = 2;
 * @return {string}
 */
proto.vendor_invoicing.OrderStatementCriteria.prototype.getQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vendor_invoicing.OrderStatementCriteria} returns this
 */
proto.vendor_invoicing.OrderStatementCriteria.prototype.setQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vendor_invoicing.RetrieveOrderStatementsResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vendor_invoicing.RetrieveOrderStatementsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vendor_invoicing.RetrieveOrderStatementsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vendor_invoicing.RetrieveOrderStatementsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vendor_invoicing.RetrieveOrderStatementsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    statementsList: jspb.Message.toObjectList(msg.getStatementsList(),
    domain_pb.Statement.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vendor_invoicing.RetrieveOrderStatementsResponse}
 */
proto.vendor_invoicing.RetrieveOrderStatementsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vendor_invoicing.RetrieveOrderStatementsResponse;
  return proto.vendor_invoicing.RetrieveOrderStatementsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vendor_invoicing.RetrieveOrderStatementsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vendor_invoicing.RetrieveOrderStatementsResponse}
 */
proto.vendor_invoicing.RetrieveOrderStatementsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new domain_pb.Statement;
      reader.readMessage(value,domain_pb.Statement.deserializeBinaryFromReader);
      msg.addStatements(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vendor_invoicing.RetrieveOrderStatementsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vendor_invoicing.RetrieveOrderStatementsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vendor_invoicing.RetrieveOrderStatementsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vendor_invoicing.RetrieveOrderStatementsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getStatementsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      domain_pb.Statement.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.vendor_invoicing.RetrieveOrderStatementsResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vendor_invoicing.RetrieveOrderStatementsResponse} returns this
 */
proto.vendor_invoicing.RetrieveOrderStatementsResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * repeated domain.Statement statements = 2;
 * @return {!Array<!proto.domain.Statement>}
 */
proto.vendor_invoicing.RetrieveOrderStatementsResponse.prototype.getStatementsList = function() {
  return /** @type{!Array<!proto.domain.Statement>} */ (
    jspb.Message.getRepeatedWrapperField(this, domain_pb.Statement, 2));
};


/**
 * @param {!Array<!proto.domain.Statement>} value
 * @return {!proto.vendor_invoicing.RetrieveOrderStatementsResponse} returns this
*/
proto.vendor_invoicing.RetrieveOrderStatementsResponse.prototype.setStatementsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.domain.Statement=} opt_value
 * @param {number=} opt_index
 * @return {!proto.domain.Statement}
 */
proto.vendor_invoicing.RetrieveOrderStatementsResponse.prototype.addStatements = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.domain.Statement, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vendor_invoicing.RetrieveOrderStatementsResponse} returns this
 */
proto.vendor_invoicing.RetrieveOrderStatementsResponse.prototype.clearStatementsList = function() {
  return this.setStatementsList([]);
};


goog.object.extend(exports, proto.vendor_invoicing);
