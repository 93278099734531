import React from 'react'
import { Box, Autocomplete, TextField } from '@mui/material'
import { createFilterOptions } from '@mui/material/Autocomplete'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { PromotionPartner } from '@nxcr-org/web-api-interface/lib/billing_service_pb'
import { Controller } from 'react-hook-form'
import { FormError } from 'shared/FormError'
import { formatPromoPartnerInput } from './useCreatePromoPartner'

interface CreatePromoFormProps {
  promoPartners: { promoPartners: PromotionPartner.AsObject[] }
  errors
  control
}

export const CreatePromoForm: React.FC<CreatePromoFormProps> = ({
  promoPartners,
  errors,
  control,
}) => {
  const filter = createFilterOptions()
  return (
    <Box>
      <Controller
        name="promoCode"
        control={control}
        rules={{ required: 'This field is required' }}
        render={({ field: { onChange } }) => {
          return (
            <TextField
              fullWidth
              label="Promo Code"
              margin="normal"
              onChange={onChange}
            />
          )
        }}
      />
      {errors?.promoCode && <FormError text={errors.promoCode.message} />}

      <Controller
        name="rebateValue"
        control={control}
        rules={{
          required: 'This field is required',
          max: 1000,
          pattern: {
            value: /^[1-9]\d*(\.\d+)?$/,
            message: 'This input is number only',
          },
        }}
        render={({ field: { onChange } }) => (
          <TextField
            fullWidth
            label="Promo Amount"
            margin="normal"
            onChange={onChange}
          />
        )}
      />
      {errors?.rebateValue &&
        (errors.rebateValue.message ? (
          <FormError text={errors.rebateValue.message} />
        ) : (
          <FormError text={'Promo Amount must be less than $1000.00'} />
        ))}

      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Controller
          name="validFrom"
          control={control}
          rules={{ required: 'This field is Required' }}
          defaultValue={null}
          render={({ field: { onChange, value } }) => (
            <DatePicker
              label="Start Date"
              renderInput={(inputProps) => (
                <TextField
                  sx={{
                    width: '100%',
                  }}
                  margin="normal"
                  {...inputProps}
                />
              )}
              onChange={onChange}
              value={value}
            />
          )}
        />
      </LocalizationProvider>
      {errors?.validFrom && <FormError text={errors.validFrom.message} />}

      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Controller
          name="validUntil"
          control={control}
          rules={{ required: 'This field is Required' }}
          defaultValue={null}
          render={({ field: { onChange, value } }) => (
            <DatePicker
              label="End Date"
              renderInput={(inputProps) => (
                <TextField
                  sx={{ width: '100%' }}
                  margin="normal"
                  {...inputProps}
                />
              )}
              onChange={onChange}
              value={value}
            />
          )}
        />
      </LocalizationProvider>
      {errors?.validUntil && <FormError text={errors.validUntil.message} />}

      <Controller
        name="promoPartner"
        control={control}
        rules={{ required: 'This field is Required' }}
        render={({ field: { onChange } }) => (
          <Autocomplete
            disableClearable
            options={promoPartners?.promoPartners?.map(
              (partnerObj) => partnerObj.name
            )}
            renderInput={(inputProps) => (
              <TextField
                {...inputProps}
                label="Promo Partner"
                margin="normal"
                variant="outlined"
              />
            )}
            onChange={(_, data) => onChange(formatPromoPartnerInput(data))}
            // @ts-ignore
            filterOptions={(options, params) => {
              const filtered = filter(options, params)

              const { inputValue } = params
              // Suggest the creation of a new value
              const isExisting = options.some(
                (option) => inputValue === option
              )
              if (inputValue !== '' && !isExisting) {
                filtered.push(`Add "${inputValue.toUpperCase()}"`)
              }
              return filtered
            }}
          />
        )}
      />

      {errors?.promoPartner && <FormError text={errors.promoPartner.message} />}
    </Box>
  )
}
