import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {MenuItem, Select} from '@mui/material'
import format from 'date-fns/format'
import React from 'react'
import {AutonomyTimePickerProps, useAutonomyTimePicker} from './useAutonomyTimePicker'

export const AutonomyTimePicker: React.FC<AutonomyTimePickerProps> = ({
  value,
  disabled,
  rangeStart,
  rangeEnd,
  interval,
  defaultTime,
  onChange
}) => {
  const {
    timeOptions,
    selectOption,
    setSelectedOption,
  } = useAutonomyTimePicker( value, defaultTime, rangeStart, rangeEnd, interval )

  return (
    <Select
      className="compactSelect"
      value={value}
      autoWidth={false}
      disabled={disabled}
      IconComponent={ExpandMoreIcon}
      onChange={ (e) => {
        setSelectedOption( e.target.value )
        onChange( e.target.value )
      }}>
      <MenuItem value={ selectOption }>
        { selectOption }
      </MenuItem>
      {timeOptions.map((option) => (
        <MenuItem
          value={format(option, 'HH:mm')}
          key={format(option, 'HH:mm')}>
          {format(option, 'HH:mm')}
        </MenuItem>
      ))}
    </Select>
  )
}
