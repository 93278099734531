import React from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material'
import { useQueryClient } from 'react-query'
import makeStyles from '@mui/styles/makeStyles'
import Transition from 'shared/Transition'
import { Controller, useForm } from 'react-hook-form'
import { FormError } from 'shared/FormError'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { useCreatePunitiveMiles } from '../useCreatePunitiveMiles'
import { endOfToday, isValid } from 'date-fns'
import { getSubscriptionById } from 'global-apis/nexus-service-payments'
import { useCustomerContext } from '../../CustomerContext'
import {
  convertDateToUnixTimestamp,
  getCurrentTimestamp,
} from '../../../../utils/date-formatter'
import toast from 'react-hot-toast'
const useStyles = makeStyles(() => ({
  addmiles: {
    minWidth: 800,
  },
}))

interface punitiveMilesModal {
  open: boolean
  onClose: () => void
  subscriptionId: string
}

export const PunitiveMilesModal: React.FC<punitiveMilesModal> = ({
  open,
  onClose,
  subscriptionId,
  // addPunitiveMilesMutation,
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      amount: '',
      price: '',
      time: '',
    },
  })

  const queryClient = useQueryClient()
  const classes = useStyles()
  const { customerInfo } = useCustomerContext()

  const { addPunitiveMiles } = useCreatePunitiveMiles(subscriptionId)

  const onSubmit = async (data) => {
    try {
      if (!subscriptionId) {
        throw new Error('SubscriptionId not provided')
      }

      const subscription = await getSubscriptionById(subscriptionId)
      if (!subscription) {
        throw new Error('Subscription not found')
      }

      if (!data.amount || !data.price || !data.time) {
        throw new Error('Please provide all required fields')
      }

      // Get the current date and time
      const unixTimestampSeconds = getCurrentTimestamp('seconds')
      const selectedDateinUnix = convertDateToUnixTimestamp(
        data.time,
        'seconds'
      )

      const payload = {
        amount: parseInt(data.amount, 10),
        price: data.price,
        time: selectedDateinUnix,
        subscriptionId,
        vehicleId: subscription.vehicleId,
        referenceId: `manual-ops-${customerInfo.accountNumber}-${unixTimestampSeconds}`,
      }
      const response = await addPunitiveMiles(payload)
      if (response.success) {
        queryClient.invalidateQueries([
          'subscriptionMileagePurchases',
          subscriptionId,
        ])
        queryClient.invalidateQueries(['subscriptionMileage', subscriptionId])
      }
      resetForm()
    } catch (error) {
      console.error('Error: ', error)
      toast.error('Error adding punitive miles')
    } finally {
      onClose()
      queryClient.invalidateQueries([
        'subscriptionMileagePurchases',
        subscriptionId,
      ])
      queryClient.invalidateQueries(['subscriptionMileage', subscriptionId])
    }
  }
  const resetForm = () => {
    reset({
      amount: '',
      price: '',
      time: '',
    })
  }
  const handleClose = () => {
    resetForm()
    onClose()
  }
  return (
    <Dialog
      maxWidth="md"
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      classes={{ paper: classes.addmiles }}
    >
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle id="dialog-title">Add Punitive Miles</DialogTitle>
          <DialogContent>
            <Controller
              name="amount"
              control={control}
              rules={{
                required: 'This field is required',
                pattern: {
                  value: /^[1-9]\d*(\.\d+)?$/,
                  message: 'This input is number only',
                },
              }}
              render={({ field }) => {
                return (
                  <TextField
                    fullWidth
                    label="Miles"
                    margin="normal"
                    {...field}
                    // onChange={onChange}
                  />
                )
              }}
            />
            {errors?.amount && <FormError text={errors.amount.message} />}

            <Controller
              name="price"
              control={control}
              rules={{
                required: 'This field is required',

                pattern: {
                  value: /^[1-9]\d*(\.\d+)?$/,
                  message: 'This input is number only',
                },
              }}
              render={({ field: { onChange } }) => (
                <TextField
                  fullWidth
                  label="Price"
                  margin="normal"
                  onChange={onChange}
                />
              )}
            />
            {errors?.price && <FormError text={errors.price.message} />}

            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Controller
                name="time"
                control={control}
                rules={{
                  required: 'This field is Required',
                  validate: (value) =>
                    value && isValid(value) ? true : 'Invalid date',
                }}
                defaultValue={null}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <DatePicker
                    label="Date Purchased"
                    renderInput={(inputProps) => (
                      <TextField
                        sx={{
                          width: '100%',
                        }}
                        margin="normal"
                        {...inputProps}
                        error={!!error}
                        helperText={error ? error.message : ''}
                      />
                    )}
                    onChange={(date) => {
                      onChange(date)
                    }}
                    value={value}
                    maxDate={endOfToday()}
                  />
                )}
              />
            </LocalizationProvider>
            {errors?.time && <FormError text={errors.time.message} />}
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>

            <Button
              type="submit"
              autoFocus
              variant="contained"
              color="secondary"
            >
              Yes
            </Button>
          </DialogActions>
        </form>
      </Box>
    </Dialog>
  )
}
