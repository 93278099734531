import { TextField, Box, InputAdornment } from '@mui/material'
import { Search as SearchIcon } from '../../global-styles/nexus-theme/icons/search'
import React from 'react'

//Have a few optional params since the old "TableSeach" worked a little differently 
//TODO: consolidate tablesearch functionality accross tnx/reservations/all components
interface TableSearchProps {
  setQuery?: (evt: React.ChangeEvent<HTMLInputElement>) => void
  setSearch?: (input: string) => void
  search?: string
  label: string
}

export const TableSearch: React.FC<TableSearchProps> = ({
  setQuery,
  setSearch,
  search,
  label,
}) => {
  return (
    <Box
      sx={{
        m: 1,
        maxWidth: '100%',
        width: 500,
      }}
    >
      {setSearch ? (
        <TextField
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon fontSize="small" />
              </InputAdornment>
            ),
          }}
          placeholder={label}
          onChange={(e) => setSearch(e.target.value)}
          value={search}
        />
      ) : (
        <TextField
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon fontSize="small" />
              </InputAdornment>
            ),
          }}
          placeholder={label}
          onChange={setQuery}
          value={search}
        />
      )}
    </Box>
  )
}
