import { useState } from 'react'

export function useLoadingState() {
  const [state, setLoadingState] = useState({
    isLoading: true,
    hasError: false,
    errorMessage: '',
  })

  const setLoading = () => {
    setLoadingState({
      isLoading: true,
      hasError: false,
      errorMessage: '',
    })
  }

  const setLoaded = () => {
    setLoadingState({
      isLoading: false,
      hasError: false,
      errorMessage: '',
    })
  }

  const setErrorLoading = (errorMessage: string) => {
    setLoadingState({
      isLoading: false,
      hasError: true,
      errorMessage,
    })
  }

  return {
    ...state,
    setLoading,
    setLoaded,
    setErrorLoading,
  }
}
