import { pick } from 'lodash'
import {
  Subscription,
  StatementStatus,
  Address,
} from '@nxcr-org/web-api-interface/lib/domain_pb'
import { PromotionSource } from '@nxcr-org/web-api-interface/lib/billing_service_pb'
import { NexusVehicle } from '@nxcr-org/web-api-interface/lib/fleet_service_pb'
import {
  AppointmentStatus,
  AppointmentType,
} from '@nxcr-org/web-api-interface/lib/appointment_pb'
import { formatEnum } from '../../../utils/formatEnum'



export const formattedCancellationReasons = formatEnum(
  Subscription.SubscriptionAction.ActionReason
)

export const formattedStatementStatus = formatEnum(StatementStatus)
export const formattedSubscriptionStatus = formatEnum(Subscription.Status)
export const formattedAddressType = formatEnum(Address.AddressType)
export const formattedPromotionSource = formatEnum(PromotionSource)

// stub, rejection reasons dont exist yet in BE
// export const formattedRejectionReasons = formatEnum(
//   Subscription.SubscriptionAction.ActionReason
// )

export const formattedActionUserType = formatEnum(
  Subscription.SubscriptionAction.UserType
)

export const formattedNexusVehicleStatuses = formatEnum(NexusVehicle.Status)

export const ValidCancellationReasons = [
  Subscription.SubscriptionAction.ActionReason.DEALER_VEHICLE_NOT_AVAILABLE,
  Subscription.SubscriptionAction.ActionReason
    .DEALER_VEHICLE_SELLING_PRICE_REJECTED,
  Subscription.SubscriptionAction.ActionReason.DEALER_VEHICLE_SOLD,
  Subscription.SubscriptionAction.ActionReason.CUSTOMER_VEHICLE_TOO_EXPENSIVE,
  Subscription.SubscriptionAction.ActionReason
    .CUSTOMER_VEHICLE_DRIVE_NOT_AS_EXPECTED,
  Subscription.SubscriptionAction.ActionReason.ANDROID_OS_UNSUPPORTED,
  Subscription.SubscriptionAction.ActionReason
    .CUSTOMER_VEHICLE_DOES_NOT_WANT_VEHICLE,
]

export const ReservationEnums = {
  actionReasons: formattedCancellationReasons,
  cancellationReasons: formatEnum(
    pick(Subscription.SubscriptionAction.ActionReason, ValidCancellationReasons)
  ),
}

export const formattedAppointmentTypes = formatEnum(AppointmentType)
export const formattedAppointmentStatus = formatEnum(AppointmentStatus)
