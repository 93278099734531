import React from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import SearchIcon from '@mui/icons-material/Search'
import { Grid, Typography } from '@mui/material'
import { useSearchbarStyles } from '../../../../scheduling.styles'
import { useSearchBar } from './useSearchBar'
import {ReservationListItem} from '@nxcr-org/web-api-interface/lib/subscription_service_pb'

interface SearchBarProps {
  onOptionSelect: ( value: ReservationListItem.AsObject ) => void
}

export const SearchBar: React.FC<SearchBarProps> = ({
  onOptionSelect,
}) => {
  const {
    isLoading,
    searchQuery,
    searchReservation,
    searchResults,
    resetSearch,
    setSearchQuery,
  } = useSearchBar()

  const { classes } = useSearchbarStyles()
  return (
    <Autocomplete
      id="account-number-search"
      open={!!searchResults.length}
      loading={isLoading}
      options={searchResults}
      blurOnSelect={true}
      classes={{ root: classes.root, popper: classes.popper }}
      clearOnBlur={true}
      inputValue={searchQuery}
      getOptionLabel={(option: OptionType) => {
        return option.label
      }}
      renderOption={(props, option) => {
        return (
          <Grid
            key={props.id}
            container
            className={classes.option}
            onClick={() => {
              onOptionSelect(searchResults.find( result => result.accountNumber === option?.accountNumber))
              resetSearch()
            }}
          >
            <Grid item>
              <Typography variant="caption">Customer Name</Typography>
              <Typography>
                {option?.firstName} {option?.lastName}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="caption">Vin #</Typography>
              <Typography>{option?.vin || 'N/A'}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="caption">Account #</Typography>
              <Typography>{option?.accountNumber || 'N/A'}</Typography>
            </Grid>
          </Grid>
        )
      }}
      freeSolo
      sx={{
        display: {
          xs: 'none',
          sm: 'flex',
        },
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            autoFocus
            variant="outlined"
            value={searchQuery}
            placeholder="Search by Account Number"
            onChange={(evt) => {
              setSearchQuery(evt.target.value)
              searchReservation.mutate(evt.target.value)
            }}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <>
                  <SearchIcon />
                </>
              ),
            }}
          />
        )
      }}
    />
  )
}

interface OptionType {
  label: string
  firstName: string
  lastName: string
  accountNumber: string
  vin: string
}
