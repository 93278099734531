import React from 'react'
import {
  Box,
  CircularProgress,
  Grid,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {IDealership, IDealershipUser} from '../../../utils'

interface TeamMembersBlockProps {
  dealerships: IDealership[]
  dealership: IDealership
  teamMembers: IDealershipUser[]
  isEditing: boolean
  isLoading: boolean
}

export const TeamMembersBlock: React.FC<TeamMembersBlockProps> = ({
  dealerships,
  dealership,
  teamMembers,
  isEditing,
  isLoading,
}) => {

  return (
    isLoading ? (
      <Box sx={{ width: '100%', textAlign: 'center' }}>
        <CircularProgress/>
      </Box>
    ) : (
      <Grid>
        <Grid item>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant="caption">
                    Member name
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="caption">
                    Phone number
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="caption">
                    Assigned team
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {teamMembers?.map((user) => (
                <TableRow key={user.id}>
                  <TableCell width="15%">
                    <Typography>
                      {user.name || 'N/A'}
                    </Typography>
                  </TableCell>
                  <TableCell width="15%">
                    <Typography>
                      {user.cell || 'N/A'}
                    </Typography>
                  </TableCell>
                  { isEditing ? (
                    <TableCell width="70%">
                      <Select
                        autoWidth={false}
                        className="compactSelect"
                        IconComponent={ExpandMoreIcon}
                        value={dealership?.name}
                        disabled={true} // @TODO: disabled until further notice
                        onChange={(e) => {
                          console.log(e)
                          // @TODO: assign to another team. On hold for now
                        }}>
                        {dealerships?.map( ({id, name}) =>
                          <MenuItem
                            key={id}
                            value={name}>
                            {name}
                          </MenuItem>
                        )}
                      </Select>
                    </TableCell>
                  ) : (
                    <TableCell width="70%">
                      <Typography>
                        {dealership?.name || 'N/A'}
                      </Typography>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    )
  )
}
