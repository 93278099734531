const adminCQERoles = [
  'Nexus-CQE-Admin-Dev',
  'Nexus-CQE-Admin-Prod',
  'Nexus-CQE-SuperAdmin-Dev',
  'Nexus-CQE-SuperAdmin-Prod',
]

export function getIsAdmin(userGroups: string[]) {
  return adminCQERoles.some((role) => userGroups?.includes(role))
}
