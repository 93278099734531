import React from 'react'
import {
  DatePicker,
  LocalizationProvider
} from '@mui/x-date-pickers'
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {TextField} from '@mui/material'
import {shouldDisableDate} from './useAutonomyCalendar'

/**
 * todo: AutonomyHolidays, ClosedDates and regular closed office days should be in this Calendar by
 * default. These dates make up the basis of this AutonomyCalendar component and are dependent on dealershipId.
 *
 * - all listDealerships share the same AutonomyHolidays
 * - closed office days and ClosedDates ( additional out of the ordinary closed days ) are dependent on dealership
 *
 * This is currently not entirely implemented yet in the BE but this is what this component is intended for.
 * Normal MUI DatePickers can be used for other calendars that arent related to listDealerships etc.
 *
 * We should then be able to add more disabled dates if required on top of the default ones. For instance: in
 * createAppointment any dates after 3 weeks have to be disabled
 */

export const AutonomyCalendar: React.FC<AutonomyCalendarProps> = ({
  value,
  disabledDates,
  openDays,
  onChange,
  disabled,
  minDate,
  maxDate
}) => {

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        views={['day']}
        value={value}
        onChange={onChange}
        disabled={disabled}
        components={{
          OpenPickerIcon: ExpandMoreIcon
        }}
        renderInput={(params) => <TextField {...params} className="compactTextField"/>}
        shouldDisableDate={ date => shouldDisableDate( date, disabledDates, openDays, value, minDate, maxDate )}
      />
    </LocalizationProvider>
  )
}

interface AutonomyCalendarProps {
  value: Date
  onChange: ( date: Date ) => void
  disabledDates?: Date[]
  disabled?: boolean
  openDays?: string[]
  minDate?: Date
  maxDate?: Date
}

