import { Button } from '@mui/material'
import React from 'react'

const ListVehicleCell = (props) => {
  let {
    formattedValue: { vehicle, isEligible },
    handler,
  } = props

  if (vehicle.vehicle) {
    vehicle = vehicle.vehicle
  }

  return (
    <Button
      color="secondary"
      onClick={() => {
        handler(vehicle)
      }}
      variant="contained"
    >
      {isEligible ? 'Delist' : 'List'}
    </Button>
  )
}

export default ListVehicleCell
