import { useMutation, useQueryClient } from 'react-query'

import toast from 'react-hot-toast'

import { NexusService } from 'global-apis/nexus-service'

export const useCreatePunitiveMiles = (subscriptionId) => {
  const queryClient = useQueryClient()

  const { mutateAsync: createPunitiveMiles, error: createPromoError } =
    useMutation(
      ({
        vehicleId,
        subscriptionId,
        amount,
        price,
        time,
        referenceId,
      }: PunitiveMilesInput) => {
        return NexusService.LogMilesPurchased({
          vehicleId,
          subscriptionId,
          amount,
          price,
          time,
          referenceId,
        }).catch((err) => {
          console.error({
            message: 'Error',
            err,
            params: {
              vehicleId,
              subscriptionId,
              amount,
              price,
              time,
              referenceId,
            },
          })

          // ! Allow UI to handle but log error so that datadog picks up additional data
          throw err
        })
      },
      {
        onSuccess() {
          invalidate()
          toast.success('Punitive miles added.')
        },
        onError() {
          toast.error(
            'Something went wrong or there is an error adding pumitive miles.'
          )
          console.log('Error: ', createPromoError)
        },
      }
    )
  const mutatioDeleteMilesPurchsed = useMutation(
    (id: number) => {
      return NexusService.deleteMilesPurchasedLog(id)
    },
    {
      onSuccess() {
        invalidate()
      },
      // when a double booking response comes back, an error is thrown
      // but the appointment is still created. We must invalidate the queries
      // here as well to have proper refreshed data
      onError(e) {
        toast.error(
          'Something went wrong or there is an error adding pumitive miles.'
        )
        console.log('Error: ', e)
      },
    }
  )
  const invalidate = () => {
    queryClient.invalidateQueries([
      'subscriptionMileagePurchases',
      subscriptionId,
    ])
    queryClient.invalidateQueries(['subscriptionMileage', subscriptionId])
  }
  const deleteMilesPurchasedById = (id) => {
    return mutatioDeleteMilesPurchsed.mutateAsync(id)
  }
  function addPunitiveMiles(formData: PunitiveMilesInput) {
    return createPunitiveMiles(formData)
  }

  return {
    addPunitiveMiles,
    deleteMilesPurchasedById,
  }
}

export type PunitiveMilesInput = {
  vehicleId: string
  amount: number
  price: number
  time: number
  subscriptionId: string
  referenceId: string
}
