import * as React from 'react'
import {Grid, Typography, Box} from '@mui/material'
import ReactJson from 'react-json-view'
import { find, map } from 'lodash'
import { Section } from 'shared/Section'
import {useReservation, getQualification} from '../../useReservation'
import {palette} from '../../../../../../global-styles/theme/palette'
import { safeParseJSON } from '../../../../../../utils/safeParseJSON'

export const QualificationBlock = () => {
  
  const {cqeScores} = useReservation()
  const scores = getQualification(cqeScores)

  return (
    <Section heading="Qualification">
      <Grid container direction="column" spacing={6}>
        { !scores.length &&
          <Grid item>
            <Typography>No qualification scores available</Typography>
          </Grid>
        }

        {map(scores, (score, index) => {
          const sourceData =
            find(score.sourceData, {
              vendorName: score.vendor,
            }) 
          // TODO: The naming for this is off but that's because of how the backend handles it
          sourceData.sourceData = safeParseJSON(sourceData.sourceData)

          return (
            <Grid key={index} item container spacing={2} direction="column">
              <Grid item>
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    columnGap: '3rem',
                    rowGap: '1.5rem',
                  }}
                >
                  <div>
                    <Typography variant="caption">Date</Typography>
                    <div>{score.qualificationDate}</div>
                  </div>
                  <div>
                    <Typography variant="caption">Time</Typography>
                    <div>{score.qualificationTime}</div>
                  </div>
                  <div>
                    <Typography variant="caption">Result</Typography>
                    <div>
                      {score.result === 'Accepted' ? (
                        <Typography color={palette.text.success}>
                          {score.result}
                        </Typography>
                      ) : (
                        <Typography color={palette.text.warning}>
                          {score.result}
                        </Typography>
                      )}
                    </div>
                  </div>
                  <div>
                    <Typography variant="caption">Reason</Typography>
                    <div>{score.reason || 'N/A'}</div>
                  </div>
                  <div>
                    <Typography variant="caption">Score</Typography>
                    <div>{score.score || 'N/A'}</div>
                  </div>
                  <div>
                    <Typography variant="caption">Tier</Typography>
                    <div>{score.tier || 'N/A'}</div>
                  </div>
                  <div>
                    <Typography variant="caption">Vendor</Typography>
                    <div>{score.vendor || 'N/A'}</div>
                  </div>
                </Box>
              </Grid>
              <Grid item style={{ maxWidth: '100%' }}>
                <Typography variant="caption">
                  Full Qualification Response
                </Typography>
                {/*@TODO: going to need a custom theme for this*/}
                <ReactJson
                  theme="google"
                  name={false}
                  displayDataTypes={false}
                  src={
                    sourceData || score.sourceData
                  }
                  collapsed={1}
                  style={{
                    width: '100%',
                    overflowX: 'auto',
                  }}
                />
              </Grid>
            </Grid>
          )
        })}
      </Grid>
    </Section>
  )
}



