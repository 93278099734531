// source: dealer_service.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var domain_pb = require('./domain_pb.js');
goog.object.extend(proto, domain_pb);
var vehicle_pb = require('./vehicle_pb.js');
goog.object.extend(proto, vehicle_pb);
goog.exportSymbol('proto.dealer_service.AcceptDealerUserTosRequest', null, global);
goog.exportSymbol('proto.dealer_service.AcceptDealerUserTosResponse', null, global);
goog.exportSymbol('proto.dealer_service.AcceptVDPTosRequest', null, global);
goog.exportSymbol('proto.dealer_service.AcceptVDPTosResponse', null, global);
goog.exportSymbol('proto.dealer_service.AddInvitationRequest', null, global);
goog.exportSymbol('proto.dealer_service.AddInvitationResponse', null, global);
goog.exportSymbol('proto.dealer_service.AddWireInfoRequest', null, global);
goog.exportSymbol('proto.dealer_service.AddWireInfoResponse', null, global);
goog.exportSymbol('proto.dealer_service.AppointmentTypeConfig', null, global);
goog.exportSymbol('proto.dealer_service.AppointmentVehicleLink', null, global);
goog.exportSymbol('proto.dealer_service.AssignResponsibilityRequest', null, global);
goog.exportSymbol('proto.dealer_service.AssignResponsibilityRequest.Responsibility', null, global);
goog.exportSymbol('proto.dealer_service.AssignResponsibilityResponse', null, global);
goog.exportSymbol('proto.dealer_service.Calendar', null, global);
goog.exportSymbol('proto.dealer_service.CheckVerificationCodeCreateRequest', null, global);
goog.exportSymbol('proto.dealer_service.CheckVerificationCodeCreateResponse', null, global);
goog.exportSymbol('proto.dealer_service.CheckVerificationCodeLoginRequest', null, global);
goog.exportSymbol('proto.dealer_service.CheckVerificationCodeLoginResponse', null, global);
goog.exportSymbol('proto.dealer_service.ClosedDateWithReason', null, global);
goog.exportSymbol('proto.dealer_service.ConfirmVehicleAvailableRequest', null, global);
goog.exportSymbol('proto.dealer_service.ConfirmVehicleAvailableResponse', null, global);
goog.exportSymbol('proto.dealer_service.ConfirmVehiclePickupRequest', null, global);
goog.exportSymbol('proto.dealer_service.ConfirmVehiclePickupResponse', null, global);
goog.exportSymbol('proto.dealer_service.ConfirmVehiclePriceRequest', null, global);
goog.exportSymbol('proto.dealer_service.ConfirmVehiclePriceResponse', null, global);
goog.exportSymbol('proto.dealer_service.Date', null, global);
goog.exportSymbol('proto.dealer_service.DeleteNotificationsRequest', null, global);
goog.exportSymbol('proto.dealer_service.DeleteNotificationsResponse', null, global);
goog.exportSymbol('proto.dealer_service.DeleteTeamMemberRequest', null, global);
goog.exportSymbol('proto.dealer_service.DeleteTeamMemberResponse', null, global);
goog.exportSymbol('proto.dealer_service.DeleteUserRequest', null, global);
goog.exportSymbol('proto.dealer_service.DeleteUserResponse', null, global);
goog.exportSymbol('proto.dealer_service.Event', null, global);
goog.exportSymbol('proto.dealer_service.Feature', null, global);
goog.exportSymbol('proto.dealer_service.GetAppointmentTypeConfigsRequest', null, global);
goog.exportSymbol('proto.dealer_service.GetAppointmentTypeConfigsResponse', null, global);
goog.exportSymbol('proto.dealer_service.GetAvailabilityRequest', null, global);
goog.exportSymbol('proto.dealer_service.GetAvailabilityResponse', null, global);
goog.exportSymbol('proto.dealer_service.GetAvailabilitySlotsRequest', null, global);
goog.exportSymbol('proto.dealer_service.GetAvailabilitySlotsResponse', null, global);
goog.exportSymbol('proto.dealer_service.GetCheckoutStatusRequest', null, global);
goog.exportSymbol('proto.dealer_service.GetCheckoutStatusResponse', null, global);
goog.exportSymbol('proto.dealer_service.GetClosedDatesRequest', null, global);
goog.exportSymbol('proto.dealer_service.GetClosedDatesResponse', null, global);
goog.exportSymbol('proto.dealer_service.GetCustomerInfoRequest', null, global);
goog.exportSymbol('proto.dealer_service.GetCustomerInfoResponse', null, global);
goog.exportSymbol('proto.dealer_service.GetDealerUsersRequest', null, global);
goog.exportSymbol('proto.dealer_service.GetDealerUsersResponse', null, global);
goog.exportSymbol('proto.dealer_service.GetDealershipEventsRequest', null, global);
goog.exportSymbol('proto.dealer_service.GetDealershipEventsResponse', null, global);
goog.exportSymbol('proto.dealer_service.GetDealershipInfoRequest', null, global);
goog.exportSymbol('proto.dealer_service.GetDealershipInfoResponse', null, global);
goog.exportSymbol('proto.dealer_service.GetDealershipOffersRequest', null, global);
goog.exportSymbol('proto.dealer_service.GetDealershipOffersResponse', null, global);
goog.exportSymbol('proto.dealer_service.GetEmailInformationRequest', null, global);
goog.exportSymbol('proto.dealer_service.GetEmailInformationResponse', null, global);
goog.exportSymbol('proto.dealer_service.GetHolidaysRequest', null, global);
goog.exportSymbol('proto.dealer_service.GetHolidaysResponse', null, global);
goog.exportSymbol('proto.dealer_service.GetNotificationsRequest', null, global);
goog.exportSymbol('proto.dealer_service.GetNotificationsResponse', null, global);
goog.exportSymbol('proto.dealer_service.GetUserInformationRequest', null, global);
goog.exportSymbol('proto.dealer_service.GetUserInformationResponse', null, global);
goog.exportSymbol('proto.dealer_service.GetVehicleByIdRequest', null, global);
goog.exportSymbol('proto.dealer_service.GetVehicleByIdResponse', null, global);
goog.exportSymbol('proto.dealer_service.GlobalFeaturesRequest', null, global);
goog.exportSymbol('proto.dealer_service.GlobalFeaturesResponse', null, global);
goog.exportSymbol('proto.dealer_service.KeyHandOffRequest', null, global);
goog.exportSymbol('proto.dealer_service.KeyHandOffResponse', null, global);
goog.exportSymbol('proto.dealer_service.NotificationsTable', null, global);
goog.exportSymbol('proto.dealer_service.Offer', null, global);
goog.exportSymbol('proto.dealer_service.OpenDayOfWeek', null, global);
goog.exportSymbol('proto.dealer_service.OpenDayOfWeek.DayOfWeek', null, global);
goog.exportSymbol('proto.dealer_service.ReadNotificationRequest', null, global);
goog.exportSymbol('proto.dealer_service.ReadNotificationResponse', null, global);
goog.exportSymbol('proto.dealer_service.SearchDealershipsRequest', null, global);
goog.exportSymbol('proto.dealer_service.SearchDealershipsResponse', null, global);
goog.exportSymbol('proto.dealer_service.SendVerificationCodeRequest', null, global);
goog.exportSymbol('proto.dealer_service.SendVerificationCodeResponse', null, global);
goog.exportSymbol('proto.dealer_service.SetAppointmentRequest', null, global);
goog.exportSymbol('proto.dealer_service.SetAppointmentResponse', null, global);
goog.exportSymbol('proto.dealer_service.SetAppointmentTypeConfigsRequest', null, global);
goog.exportSymbol('proto.dealer_service.SetAppointmentTypeConfigsResponse', null, global);
goog.exportSymbol('proto.dealer_service.SetAppointmentTypesResponse', null, global);
goog.exportSymbol('proto.dealer_service.SetClosedDatesRequest', null, global);
goog.exportSymbol('proto.dealer_service.SetClosedDatesResponse', null, global);
goog.exportSymbol('proto.dealer_service.UpdateAvailabilityRequest', null, global);
goog.exportSymbol('proto.dealer_service.UpdateAvailabilityResponse', null, global);
goog.exportSymbol('proto.dealer_service.UpdateClosedDatesRequest', null, global);
goog.exportSymbol('proto.dealer_service.UpdateClosedDatesResponse', null, global);
goog.exportSymbol('proto.dealer_service.UpdateDealerUserRequest', null, global);
goog.exportSymbol('proto.dealer_service.UpdateDealerUserResponse', null, global);
goog.exportSymbol('proto.dealer_service.ViewDealerAppointmentsRequest', null, global);
goog.exportSymbol('proto.dealer_service.ViewDealerAppointmentsResponse', null, global);
goog.exportSymbol('proto.dealer_service.ViewPastDealsRequest', null, global);
goog.exportSymbol('proto.dealer_service.ViewPastDealsResponse', null, global);
goog.exportSymbol('proto.dealer_service.ViewTeamRequest', null, global);
goog.exportSymbol('proto.dealer_service.ViewTeamResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer_service.DeleteTeamMemberRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealer_service.DeleteTeamMemberRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer_service.DeleteTeamMemberRequest.displayName = 'proto.dealer_service.DeleteTeamMemberRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer_service.DeleteTeamMemberResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealer_service.DeleteTeamMemberResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer_service.DeleteTeamMemberResponse.displayName = 'proto.dealer_service.DeleteTeamMemberResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer_service.UpdateDealerUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealer_service.UpdateDealerUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer_service.UpdateDealerUserRequest.displayName = 'proto.dealer_service.UpdateDealerUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer_service.UpdateDealerUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealer_service.UpdateDealerUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer_service.UpdateDealerUserResponse.displayName = 'proto.dealer_service.UpdateDealerUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer_service.AssignResponsibilityRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dealer_service.AssignResponsibilityRequest.repeatedFields_, null);
};
goog.inherits(proto.dealer_service.AssignResponsibilityRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer_service.AssignResponsibilityRequest.displayName = 'proto.dealer_service.AssignResponsibilityRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer_service.AssignResponsibilityResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealer_service.AssignResponsibilityResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer_service.AssignResponsibilityResponse.displayName = 'proto.dealer_service.AssignResponsibilityResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer_service.GetDealershipInfoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealer_service.GetDealershipInfoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer_service.GetDealershipInfoRequest.displayName = 'proto.dealer_service.GetDealershipInfoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer_service.GetDealershipInfoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealer_service.GetDealershipInfoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer_service.GetDealershipInfoResponse.displayName = 'proto.dealer_service.GetDealershipInfoResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer_service.SearchDealershipsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealer_service.SearchDealershipsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer_service.SearchDealershipsRequest.displayName = 'proto.dealer_service.SearchDealershipsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer_service.SearchDealershipsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dealer_service.SearchDealershipsResponse.repeatedFields_, null);
};
goog.inherits(proto.dealer_service.SearchDealershipsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer_service.SearchDealershipsResponse.displayName = 'proto.dealer_service.SearchDealershipsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer_service.SendVerificationCodeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealer_service.SendVerificationCodeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer_service.SendVerificationCodeRequest.displayName = 'proto.dealer_service.SendVerificationCodeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer_service.SendVerificationCodeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealer_service.SendVerificationCodeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer_service.SendVerificationCodeResponse.displayName = 'proto.dealer_service.SendVerificationCodeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer_service.CheckVerificationCodeCreateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealer_service.CheckVerificationCodeCreateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer_service.CheckVerificationCodeCreateRequest.displayName = 'proto.dealer_service.CheckVerificationCodeCreateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer_service.CheckVerificationCodeCreateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealer_service.CheckVerificationCodeCreateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer_service.CheckVerificationCodeCreateResponse.displayName = 'proto.dealer_service.CheckVerificationCodeCreateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer_service.CheckVerificationCodeLoginRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealer_service.CheckVerificationCodeLoginRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer_service.CheckVerificationCodeLoginRequest.displayName = 'proto.dealer_service.CheckVerificationCodeLoginRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer_service.CheckVerificationCodeLoginResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealer_service.CheckVerificationCodeLoginResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer_service.CheckVerificationCodeLoginResponse.displayName = 'proto.dealer_service.CheckVerificationCodeLoginResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer_service.AddWireInfoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealer_service.AddWireInfoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer_service.AddWireInfoRequest.displayName = 'proto.dealer_service.AddWireInfoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer_service.AddWireInfoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealer_service.AddWireInfoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer_service.AddWireInfoResponse.displayName = 'proto.dealer_service.AddWireInfoResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer_service.GetUserInformationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealer_service.GetUserInformationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer_service.GetUserInformationRequest.displayName = 'proto.dealer_service.GetUserInformationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer_service.GetUserInformationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealer_service.GetUserInformationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer_service.GetUserInformationResponse.displayName = 'proto.dealer_service.GetUserInformationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer_service.GetEmailInformationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealer_service.GetEmailInformationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer_service.GetEmailInformationRequest.displayName = 'proto.dealer_service.GetEmailInformationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer_service.GetEmailInformationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealer_service.GetEmailInformationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer_service.GetEmailInformationResponse.displayName = 'proto.dealer_service.GetEmailInformationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer_service.GetVehicleByIdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealer_service.GetVehicleByIdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer_service.GetVehicleByIdRequest.displayName = 'proto.dealer_service.GetVehicleByIdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer_service.GetVehicleByIdResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealer_service.GetVehicleByIdResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer_service.GetVehicleByIdResponse.displayName = 'proto.dealer_service.GetVehicleByIdResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer_service.ViewTeamRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealer_service.ViewTeamRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer_service.ViewTeamRequest.displayName = 'proto.dealer_service.ViewTeamRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer_service.ViewTeamResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dealer_service.ViewTeamResponse.repeatedFields_, null);
};
goog.inherits(proto.dealer_service.ViewTeamResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer_service.ViewTeamResponse.displayName = 'proto.dealer_service.ViewTeamResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer_service.GetDealershipEventsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealer_service.GetDealershipEventsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer_service.GetDealershipEventsRequest.displayName = 'proto.dealer_service.GetDealershipEventsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer_service.GetDealershipEventsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dealer_service.GetDealershipEventsResponse.repeatedFields_, null);
};
goog.inherits(proto.dealer_service.GetDealershipEventsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer_service.GetDealershipEventsResponse.displayName = 'proto.dealer_service.GetDealershipEventsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer_service.ViewDealerAppointmentsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealer_service.ViewDealerAppointmentsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer_service.ViewDealerAppointmentsRequest.displayName = 'proto.dealer_service.ViewDealerAppointmentsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer_service.ViewDealerAppointmentsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dealer_service.ViewDealerAppointmentsResponse.repeatedFields_, null);
};
goog.inherits(proto.dealer_service.ViewDealerAppointmentsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer_service.ViewDealerAppointmentsResponse.displayName = 'proto.dealer_service.ViewDealerAppointmentsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer_service.AppointmentVehicleLink = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealer_service.AppointmentVehicleLink, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer_service.AppointmentVehicleLink.displayName = 'proto.dealer_service.AppointmentVehicleLink';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer_service.AddInvitationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealer_service.AddInvitationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer_service.AddInvitationRequest.displayName = 'proto.dealer_service.AddInvitationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer_service.AddInvitationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealer_service.AddInvitationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer_service.AddInvitationResponse.displayName = 'proto.dealer_service.AddInvitationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer_service.ConfirmVehicleAvailableRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealer_service.ConfirmVehicleAvailableRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer_service.ConfirmVehicleAvailableRequest.displayName = 'proto.dealer_service.ConfirmVehicleAvailableRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer_service.ConfirmVehicleAvailableResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealer_service.ConfirmVehicleAvailableResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer_service.ConfirmVehicleAvailableResponse.displayName = 'proto.dealer_service.ConfirmVehicleAvailableResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer_service.ConfirmVehiclePriceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealer_service.ConfirmVehiclePriceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer_service.ConfirmVehiclePriceRequest.displayName = 'proto.dealer_service.ConfirmVehiclePriceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer_service.ConfirmVehiclePriceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealer_service.ConfirmVehiclePriceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer_service.ConfirmVehiclePriceResponse.displayName = 'proto.dealer_service.ConfirmVehiclePriceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer_service.ViewPastDealsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealer_service.ViewPastDealsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer_service.ViewPastDealsRequest.displayName = 'proto.dealer_service.ViewPastDealsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer_service.ViewPastDealsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dealer_service.ViewPastDealsResponse.repeatedFields_, null);
};
goog.inherits(proto.dealer_service.ViewPastDealsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer_service.ViewPastDealsResponse.displayName = 'proto.dealer_service.ViewPastDealsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer_service.GetCustomerInfoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealer_service.GetCustomerInfoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer_service.GetCustomerInfoRequest.displayName = 'proto.dealer_service.GetCustomerInfoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer_service.GetCustomerInfoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealer_service.GetCustomerInfoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer_service.GetCustomerInfoResponse.displayName = 'proto.dealer_service.GetCustomerInfoResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer_service.Offer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealer_service.Offer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer_service.Offer.displayName = 'proto.dealer_service.Offer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer_service.GetDealershipOffersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealer_service.GetDealershipOffersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer_service.GetDealershipOffersRequest.displayName = 'proto.dealer_service.GetDealershipOffersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer_service.GetDealershipOffersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dealer_service.GetDealershipOffersResponse.repeatedFields_, null);
};
goog.inherits(proto.dealer_service.GetDealershipOffersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer_service.GetDealershipOffersResponse.displayName = 'proto.dealer_service.GetDealershipOffersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer_service.GetCheckoutStatusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealer_service.GetCheckoutStatusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer_service.GetCheckoutStatusRequest.displayName = 'proto.dealer_service.GetCheckoutStatusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer_service.GetCheckoutStatusResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealer_service.GetCheckoutStatusResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer_service.GetCheckoutStatusResponse.displayName = 'proto.dealer_service.GetCheckoutStatusResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer_service.AcceptDealerUserTosRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealer_service.AcceptDealerUserTosRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer_service.AcceptDealerUserTosRequest.displayName = 'proto.dealer_service.AcceptDealerUserTosRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer_service.AcceptDealerUserTosResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealer_service.AcceptDealerUserTosResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer_service.AcceptDealerUserTosResponse.displayName = 'proto.dealer_service.AcceptDealerUserTosResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer_service.AcceptVDPTosRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealer_service.AcceptVDPTosRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer_service.AcceptVDPTosRequest.displayName = 'proto.dealer_service.AcceptVDPTosRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer_service.AcceptVDPTosResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealer_service.AcceptVDPTosResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer_service.AcceptVDPTosResponse.displayName = 'proto.dealer_service.AcceptVDPTosResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer_service.DeleteUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealer_service.DeleteUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer_service.DeleteUserRequest.displayName = 'proto.dealer_service.DeleteUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer_service.DeleteUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealer_service.DeleteUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer_service.DeleteUserResponse.displayName = 'proto.dealer_service.DeleteUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer_service.Feature = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealer_service.Feature, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer_service.Feature.displayName = 'proto.dealer_service.Feature';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer_service.GlobalFeaturesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealer_service.GlobalFeaturesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer_service.GlobalFeaturesRequest.displayName = 'proto.dealer_service.GlobalFeaturesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer_service.GlobalFeaturesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dealer_service.GlobalFeaturesResponse.repeatedFields_, null);
};
goog.inherits(proto.dealer_service.GlobalFeaturesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer_service.GlobalFeaturesResponse.displayName = 'proto.dealer_service.GlobalFeaturesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer_service.ConfirmVehiclePickupRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealer_service.ConfirmVehiclePickupRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer_service.ConfirmVehiclePickupRequest.displayName = 'proto.dealer_service.ConfirmVehiclePickupRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer_service.ConfirmVehiclePickupResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealer_service.ConfirmVehiclePickupResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer_service.ConfirmVehiclePickupResponse.displayName = 'proto.dealer_service.ConfirmVehiclePickupResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer_service.Event = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealer_service.Event, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer_service.Event.displayName = 'proto.dealer_service.Event';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer_service.Date = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealer_service.Date, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer_service.Date.displayName = 'proto.dealer_service.Date';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer_service.Calendar = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dealer_service.Calendar.repeatedFields_, null);
};
goog.inherits(proto.dealer_service.Calendar, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer_service.Calendar.displayName = 'proto.dealer_service.Calendar';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer_service.GetHolidaysRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealer_service.GetHolidaysRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer_service.GetHolidaysRequest.displayName = 'proto.dealer_service.GetHolidaysRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer_service.GetHolidaysResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealer_service.GetHolidaysResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer_service.GetHolidaysResponse.displayName = 'proto.dealer_service.GetHolidaysResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer_service.OpenDayOfWeek = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealer_service.OpenDayOfWeek, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer_service.OpenDayOfWeek.displayName = 'proto.dealer_service.OpenDayOfWeek';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer_service.UpdateAvailabilityRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dealer_service.UpdateAvailabilityRequest.repeatedFields_, null);
};
goog.inherits(proto.dealer_service.UpdateAvailabilityRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer_service.UpdateAvailabilityRequest.displayName = 'proto.dealer_service.UpdateAvailabilityRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer_service.UpdateAvailabilityResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealer_service.UpdateAvailabilityResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer_service.UpdateAvailabilityResponse.displayName = 'proto.dealer_service.UpdateAvailabilityResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer_service.ClosedDateWithReason = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealer_service.ClosedDateWithReason, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer_service.ClosedDateWithReason.displayName = 'proto.dealer_service.ClosedDateWithReason';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer_service.UpdateClosedDatesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dealer_service.UpdateClosedDatesRequest.repeatedFields_, null);
};
goog.inherits(proto.dealer_service.UpdateClosedDatesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer_service.UpdateClosedDatesRequest.displayName = 'proto.dealer_service.UpdateClosedDatesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer_service.UpdateClosedDatesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealer_service.UpdateClosedDatesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer_service.UpdateClosedDatesResponse.displayName = 'proto.dealer_service.UpdateClosedDatesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer_service.SetClosedDatesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dealer_service.SetClosedDatesRequest.repeatedFields_, null);
};
goog.inherits(proto.dealer_service.SetClosedDatesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer_service.SetClosedDatesRequest.displayName = 'proto.dealer_service.SetClosedDatesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer_service.SetClosedDatesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealer_service.SetClosedDatesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer_service.SetClosedDatesResponse.displayName = 'proto.dealer_service.SetClosedDatesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer_service.GetAvailabilityRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealer_service.GetAvailabilityRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer_service.GetAvailabilityRequest.displayName = 'proto.dealer_service.GetAvailabilityRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer_service.GetAvailabilityResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dealer_service.GetAvailabilityResponse.repeatedFields_, null);
};
goog.inherits(proto.dealer_service.GetAvailabilityResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer_service.GetAvailabilityResponse.displayName = 'proto.dealer_service.GetAvailabilityResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer_service.GetClosedDatesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealer_service.GetClosedDatesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer_service.GetClosedDatesRequest.displayName = 'proto.dealer_service.GetClosedDatesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer_service.GetClosedDatesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dealer_service.GetClosedDatesResponse.repeatedFields_, null);
};
goog.inherits(proto.dealer_service.GetClosedDatesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer_service.GetClosedDatesResponse.displayName = 'proto.dealer_service.GetClosedDatesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer_service.NotificationsTable = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealer_service.NotificationsTable, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer_service.NotificationsTable.displayName = 'proto.dealer_service.NotificationsTable';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer_service.GetNotificationsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealer_service.GetNotificationsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer_service.GetNotificationsRequest.displayName = 'proto.dealer_service.GetNotificationsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer_service.GetNotificationsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dealer_service.GetNotificationsResponse.repeatedFields_, null);
};
goog.inherits(proto.dealer_service.GetNotificationsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer_service.GetNotificationsResponse.displayName = 'proto.dealer_service.GetNotificationsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer_service.GetAvailabilitySlotsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealer_service.GetAvailabilitySlotsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer_service.GetAvailabilitySlotsRequest.displayName = 'proto.dealer_service.GetAvailabilitySlotsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer_service.GetAvailabilitySlotsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dealer_service.GetAvailabilitySlotsResponse.repeatedFields_, null);
};
goog.inherits(proto.dealer_service.GetAvailabilitySlotsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer_service.GetAvailabilitySlotsResponse.displayName = 'proto.dealer_service.GetAvailabilitySlotsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer_service.ReadNotificationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealer_service.ReadNotificationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer_service.ReadNotificationRequest.displayName = 'proto.dealer_service.ReadNotificationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer_service.ReadNotificationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealer_service.ReadNotificationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer_service.ReadNotificationResponse.displayName = 'proto.dealer_service.ReadNotificationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer_service.DeleteNotificationsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dealer_service.DeleteNotificationsRequest.repeatedFields_, null);
};
goog.inherits(proto.dealer_service.DeleteNotificationsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer_service.DeleteNotificationsRequest.displayName = 'proto.dealer_service.DeleteNotificationsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer_service.DeleteNotificationsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealer_service.DeleteNotificationsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer_service.DeleteNotificationsResponse.displayName = 'proto.dealer_service.DeleteNotificationsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer_service.SetAppointmentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealer_service.SetAppointmentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer_service.SetAppointmentRequest.displayName = 'proto.dealer_service.SetAppointmentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer_service.SetAppointmentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealer_service.SetAppointmentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer_service.SetAppointmentResponse.displayName = 'proto.dealer_service.SetAppointmentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer_service.KeyHandOffRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealer_service.KeyHandOffRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer_service.KeyHandOffRequest.displayName = 'proto.dealer_service.KeyHandOffRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer_service.KeyHandOffResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealer_service.KeyHandOffResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer_service.KeyHandOffResponse.displayName = 'proto.dealer_service.KeyHandOffResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer_service.AppointmentTypeConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealer_service.AppointmentTypeConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer_service.AppointmentTypeConfig.displayName = 'proto.dealer_service.AppointmentTypeConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer_service.GetAppointmentTypeConfigsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealer_service.GetAppointmentTypeConfigsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer_service.GetAppointmentTypeConfigsRequest.displayName = 'proto.dealer_service.GetAppointmentTypeConfigsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer_service.GetAppointmentTypeConfigsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dealer_service.GetAppointmentTypeConfigsResponse.repeatedFields_, null);
};
goog.inherits(proto.dealer_service.GetAppointmentTypeConfigsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer_service.GetAppointmentTypeConfigsResponse.displayName = 'proto.dealer_service.GetAppointmentTypeConfigsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer_service.SetAppointmentTypeConfigsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dealer_service.SetAppointmentTypeConfigsRequest.repeatedFields_, null);
};
goog.inherits(proto.dealer_service.SetAppointmentTypeConfigsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer_service.SetAppointmentTypeConfigsRequest.displayName = 'proto.dealer_service.SetAppointmentTypeConfigsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer_service.SetAppointmentTypeConfigsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dealer_service.SetAppointmentTypeConfigsResponse.repeatedFields_, null);
};
goog.inherits(proto.dealer_service.SetAppointmentTypeConfigsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer_service.SetAppointmentTypeConfigsResponse.displayName = 'proto.dealer_service.SetAppointmentTypeConfigsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer_service.SetAppointmentTypesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealer_service.SetAppointmentTypesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer_service.SetAppointmentTypesResponse.displayName = 'proto.dealer_service.SetAppointmentTypesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer_service.GetDealerUsersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealer_service.GetDealerUsersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer_service.GetDealerUsersRequest.displayName = 'proto.dealer_service.GetDealerUsersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer_service.GetDealerUsersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dealer_service.GetDealerUsersResponse.repeatedFields_, null);
};
goog.inherits(proto.dealer_service.GetDealerUsersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer_service.GetDealerUsersResponse.displayName = 'proto.dealer_service.GetDealerUsersResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer_service.DeleteTeamMemberRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer_service.DeleteTeamMemberRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer_service.DeleteTeamMemberRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.DeleteTeamMemberRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealershipId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dealerUserId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    teamMemberId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer_service.DeleteTeamMemberRequest}
 */
proto.dealer_service.DeleteTeamMemberRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer_service.DeleteTeamMemberRequest;
  return proto.dealer_service.DeleteTeamMemberRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer_service.DeleteTeamMemberRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer_service.DeleteTeamMemberRequest}
 */
proto.dealer_service.DeleteTeamMemberRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealershipId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealerUserId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTeamMemberId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer_service.DeleteTeamMemberRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer_service.DeleteTeamMemberRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer_service.DeleteTeamMemberRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.DeleteTeamMemberRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealershipId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDealerUserId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTeamMemberId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string dealership_id = 1;
 * @return {string}
 */
proto.dealer_service.DeleteTeamMemberRequest.prototype.getDealershipId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.DeleteTeamMemberRequest} returns this
 */
proto.dealer_service.DeleteTeamMemberRequest.prototype.setDealershipId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string dealer_user_id = 2;
 * @return {string}
 */
proto.dealer_service.DeleteTeamMemberRequest.prototype.getDealerUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.DeleteTeamMemberRequest} returns this
 */
proto.dealer_service.DeleteTeamMemberRequest.prototype.setDealerUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string team_member_id = 3;
 * @return {string}
 */
proto.dealer_service.DeleteTeamMemberRequest.prototype.getTeamMemberId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.DeleteTeamMemberRequest} returns this
 */
proto.dealer_service.DeleteTeamMemberRequest.prototype.setTeamMemberId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer_service.DeleteTeamMemberResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer_service.DeleteTeamMemberResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer_service.DeleteTeamMemberResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.DeleteTeamMemberResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    dealerUserId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    error: jspb.Message.getFieldWithDefault(msg, 3, ""),
    errorCode: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer_service.DeleteTeamMemberResponse}
 */
proto.dealer_service.DeleteTeamMemberResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer_service.DeleteTeamMemberResponse;
  return proto.dealer_service.DeleteTeamMemberResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer_service.DeleteTeamMemberResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer_service.DeleteTeamMemberResponse}
 */
proto.dealer_service.DeleteTeamMemberResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealerUserId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setErrorCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer_service.DeleteTeamMemberResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer_service.DeleteTeamMemberResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer_service.DeleteTeamMemberResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.DeleteTeamMemberResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getDealerUserId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getErrorCode();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.dealer_service.DeleteTeamMemberResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dealer_service.DeleteTeamMemberResponse} returns this
 */
proto.dealer_service.DeleteTeamMemberResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string dealer_user_id = 2;
 * @return {string}
 */
proto.dealer_service.DeleteTeamMemberResponse.prototype.getDealerUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.DeleteTeamMemberResponse} returns this
 */
proto.dealer_service.DeleteTeamMemberResponse.prototype.setDealerUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string error = 3;
 * @return {string}
 */
proto.dealer_service.DeleteTeamMemberResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.DeleteTeamMemberResponse} returns this
 */
proto.dealer_service.DeleteTeamMemberResponse.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 error_code = 4;
 * @return {number}
 */
proto.dealer_service.DeleteTeamMemberResponse.prototype.getErrorCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.dealer_service.DeleteTeamMemberResponse} returns this
 */
proto.dealer_service.DeleteTeamMemberResponse.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer_service.UpdateDealerUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer_service.UpdateDealerUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer_service.UpdateDealerUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.UpdateDealerUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealerUserId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    firstName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    workEmail: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer_service.UpdateDealerUserRequest}
 */
proto.dealer_service.UpdateDealerUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer_service.UpdateDealerUserRequest;
  return proto.dealer_service.UpdateDealerUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer_service.UpdateDealerUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer_service.UpdateDealerUserRequest}
 */
proto.dealer_service.UpdateDealerUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealerUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setWorkEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer_service.UpdateDealerUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer_service.UpdateDealerUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer_service.UpdateDealerUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.UpdateDealerUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealerUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getWorkEmail();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string dealer_user_id = 1;
 * @return {string}
 */
proto.dealer_service.UpdateDealerUserRequest.prototype.getDealerUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.UpdateDealerUserRequest} returns this
 */
proto.dealer_service.UpdateDealerUserRequest.prototype.setDealerUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string first_name = 2;
 * @return {string}
 */
proto.dealer_service.UpdateDealerUserRequest.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.UpdateDealerUserRequest} returns this
 */
proto.dealer_service.UpdateDealerUserRequest.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string last_name = 3;
 * @return {string}
 */
proto.dealer_service.UpdateDealerUserRequest.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.UpdateDealerUserRequest} returns this
 */
proto.dealer_service.UpdateDealerUserRequest.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string work_email = 4;
 * @return {string}
 */
proto.dealer_service.UpdateDealerUserRequest.prototype.getWorkEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.UpdateDealerUserRequest} returns this
 */
proto.dealer_service.UpdateDealerUserRequest.prototype.setWorkEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer_service.UpdateDealerUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer_service.UpdateDealerUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer_service.UpdateDealerUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.UpdateDealerUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    dealerUserId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    error: jspb.Message.getFieldWithDefault(msg, 3, ""),
    errorCode: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer_service.UpdateDealerUserResponse}
 */
proto.dealer_service.UpdateDealerUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer_service.UpdateDealerUserResponse;
  return proto.dealer_service.UpdateDealerUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer_service.UpdateDealerUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer_service.UpdateDealerUserResponse}
 */
proto.dealer_service.UpdateDealerUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealerUserId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setErrorCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer_service.UpdateDealerUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer_service.UpdateDealerUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer_service.UpdateDealerUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.UpdateDealerUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getDealerUserId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getErrorCode();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.dealer_service.UpdateDealerUserResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dealer_service.UpdateDealerUserResponse} returns this
 */
proto.dealer_service.UpdateDealerUserResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string dealer_user_id = 2;
 * @return {string}
 */
proto.dealer_service.UpdateDealerUserResponse.prototype.getDealerUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.UpdateDealerUserResponse} returns this
 */
proto.dealer_service.UpdateDealerUserResponse.prototype.setDealerUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string error = 3;
 * @return {string}
 */
proto.dealer_service.UpdateDealerUserResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.UpdateDealerUserResponse} returns this
 */
proto.dealer_service.UpdateDealerUserResponse.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 error_code = 4;
 * @return {number}
 */
proto.dealer_service.UpdateDealerUserResponse.prototype.getErrorCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.dealer_service.UpdateDealerUserResponse} returns this
 */
proto.dealer_service.UpdateDealerUserResponse.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dealer_service.AssignResponsibilityRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer_service.AssignResponsibilityRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer_service.AssignResponsibilityRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer_service.AssignResponsibilityRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.AssignResponsibilityRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealerUserId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    responsibilityList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer_service.AssignResponsibilityRequest}
 */
proto.dealer_service.AssignResponsibilityRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer_service.AssignResponsibilityRequest;
  return proto.dealer_service.AssignResponsibilityRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer_service.AssignResponsibilityRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer_service.AssignResponsibilityRequest}
 */
proto.dealer_service.AssignResponsibilityRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealerUserId(value);
      break;
    case 2:
      var values = /** @type {!Array<!proto.dealer_service.AssignResponsibilityRequest.Responsibility>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addResponsibility(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer_service.AssignResponsibilityRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer_service.AssignResponsibilityRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer_service.AssignResponsibilityRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.AssignResponsibilityRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealerUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getResponsibilityList();
  if (f.length > 0) {
    writer.writePackedEnum(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.dealer_service.AssignResponsibilityRequest.Responsibility = {
  CUSTOMER_INTERACTION: 0,
  SALES_MANAGEMENT: 1,
  PAYMENT_PROCESSING: 2,
  SUPER_ADMIN: 3
};

/**
 * optional string dealer_user_id = 1;
 * @return {string}
 */
proto.dealer_service.AssignResponsibilityRequest.prototype.getDealerUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.AssignResponsibilityRequest} returns this
 */
proto.dealer_service.AssignResponsibilityRequest.prototype.setDealerUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Responsibility responsibility = 2;
 * @return {!Array<!proto.dealer_service.AssignResponsibilityRequest.Responsibility>}
 */
proto.dealer_service.AssignResponsibilityRequest.prototype.getResponsibilityList = function() {
  return /** @type {!Array<!proto.dealer_service.AssignResponsibilityRequest.Responsibility>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<!proto.dealer_service.AssignResponsibilityRequest.Responsibility>} value
 * @return {!proto.dealer_service.AssignResponsibilityRequest} returns this
 */
proto.dealer_service.AssignResponsibilityRequest.prototype.setResponsibilityList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {!proto.dealer_service.AssignResponsibilityRequest.Responsibility} value
 * @param {number=} opt_index
 * @return {!proto.dealer_service.AssignResponsibilityRequest} returns this
 */
proto.dealer_service.AssignResponsibilityRequest.prototype.addResponsibility = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dealer_service.AssignResponsibilityRequest} returns this
 */
proto.dealer_service.AssignResponsibilityRequest.prototype.clearResponsibilityList = function() {
  return this.setResponsibilityList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer_service.AssignResponsibilityResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer_service.AssignResponsibilityResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer_service.AssignResponsibilityResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.AssignResponsibilityResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    dealerUserId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    error: jspb.Message.getFieldWithDefault(msg, 3, ""),
    errorCode: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer_service.AssignResponsibilityResponse}
 */
proto.dealer_service.AssignResponsibilityResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer_service.AssignResponsibilityResponse;
  return proto.dealer_service.AssignResponsibilityResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer_service.AssignResponsibilityResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer_service.AssignResponsibilityResponse}
 */
proto.dealer_service.AssignResponsibilityResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealerUserId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setErrorCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer_service.AssignResponsibilityResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer_service.AssignResponsibilityResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer_service.AssignResponsibilityResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.AssignResponsibilityResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getDealerUserId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getErrorCode();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.dealer_service.AssignResponsibilityResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dealer_service.AssignResponsibilityResponse} returns this
 */
proto.dealer_service.AssignResponsibilityResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string dealer_user_id = 2;
 * @return {string}
 */
proto.dealer_service.AssignResponsibilityResponse.prototype.getDealerUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.AssignResponsibilityResponse} returns this
 */
proto.dealer_service.AssignResponsibilityResponse.prototype.setDealerUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string error = 3;
 * @return {string}
 */
proto.dealer_service.AssignResponsibilityResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.AssignResponsibilityResponse} returns this
 */
proto.dealer_service.AssignResponsibilityResponse.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 error_code = 4;
 * @return {number}
 */
proto.dealer_service.AssignResponsibilityResponse.prototype.getErrorCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.dealer_service.AssignResponsibilityResponse} returns this
 */
proto.dealer_service.AssignResponsibilityResponse.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer_service.GetDealershipInfoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer_service.GetDealershipInfoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer_service.GetDealershipInfoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.GetDealershipInfoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealershipId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer_service.GetDealershipInfoRequest}
 */
proto.dealer_service.GetDealershipInfoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer_service.GetDealershipInfoRequest;
  return proto.dealer_service.GetDealershipInfoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer_service.GetDealershipInfoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer_service.GetDealershipInfoRequest}
 */
proto.dealer_service.GetDealershipInfoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealershipId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer_service.GetDealershipInfoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer_service.GetDealershipInfoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer_service.GetDealershipInfoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.GetDealershipInfoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealershipId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string dealership_id = 1;
 * @return {string}
 */
proto.dealer_service.GetDealershipInfoRequest.prototype.getDealershipId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.GetDealershipInfoRequest} returns this
 */
proto.dealer_service.GetDealershipInfoRequest.prototype.setDealershipId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer_service.GetDealershipInfoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer_service.GetDealershipInfoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer_service.GetDealershipInfoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.GetDealershipInfoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: jspb.Message.getFieldWithDefault(msg, 2, ""),
    dealership: (f = msg.getDealership()) && domain_pb.Dealership.toObject(includeInstance, f),
    errorCode: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer_service.GetDealershipInfoResponse}
 */
proto.dealer_service.GetDealershipInfoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer_service.GetDealershipInfoResponse;
  return proto.dealer_service.GetDealershipInfoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer_service.GetDealershipInfoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer_service.GetDealershipInfoResponse}
 */
proto.dealer_service.GetDealershipInfoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 3:
      var value = new domain_pb.Dealership;
      reader.readMessage(value,domain_pb.Dealership.deserializeBinaryFromReader);
      msg.setDealership(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setErrorCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer_service.GetDealershipInfoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer_service.GetDealershipInfoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer_service.GetDealershipInfoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.GetDealershipInfoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDealership();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      domain_pb.Dealership.serializeBinaryToWriter
    );
  }
  f = message.getErrorCode();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.dealer_service.GetDealershipInfoResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dealer_service.GetDealershipInfoResponse} returns this
 */
proto.dealer_service.GetDealershipInfoResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.dealer_service.GetDealershipInfoResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.GetDealershipInfoResponse} returns this
 */
proto.dealer_service.GetDealershipInfoResponse.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional domain.Dealership dealership = 3;
 * @return {?proto.domain.Dealership}
 */
proto.dealer_service.GetDealershipInfoResponse.prototype.getDealership = function() {
  return /** @type{?proto.domain.Dealership} */ (
    jspb.Message.getWrapperField(this, domain_pb.Dealership, 3));
};


/**
 * @param {?proto.domain.Dealership|undefined} value
 * @return {!proto.dealer_service.GetDealershipInfoResponse} returns this
*/
proto.dealer_service.GetDealershipInfoResponse.prototype.setDealership = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dealer_service.GetDealershipInfoResponse} returns this
 */
proto.dealer_service.GetDealershipInfoResponse.prototype.clearDealership = function() {
  return this.setDealership(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dealer_service.GetDealershipInfoResponse.prototype.hasDealership = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int32 error_code = 4;
 * @return {number}
 */
proto.dealer_service.GetDealershipInfoResponse.prototype.getErrorCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.dealer_service.GetDealershipInfoResponse} returns this
 */
proto.dealer_service.GetDealershipInfoResponse.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer_service.SearchDealershipsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer_service.SearchDealershipsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer_service.SearchDealershipsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.SearchDealershipsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    searchTerm: jspb.Message.getFieldWithDefault(msg, 1, ""),
    latitude: jspb.Message.getFieldWithDefault(msg, 2, ""),
    longitude: jspb.Message.getFieldWithDefault(msg, 3, ""),
    limit: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer_service.SearchDealershipsRequest}
 */
proto.dealer_service.SearchDealershipsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer_service.SearchDealershipsRequest;
  return proto.dealer_service.SearchDealershipsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer_service.SearchDealershipsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer_service.SearchDealershipsRequest}
 */
proto.dealer_service.SearchDealershipsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearchTerm(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLatitude(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLongitude(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLimit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer_service.SearchDealershipsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer_service.SearchDealershipsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer_service.SearchDealershipsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.SearchDealershipsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSearchTerm();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLatitude();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLongitude();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional string search_term = 1;
 * @return {string}
 */
proto.dealer_service.SearchDealershipsRequest.prototype.getSearchTerm = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.SearchDealershipsRequest} returns this
 */
proto.dealer_service.SearchDealershipsRequest.prototype.setSearchTerm = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string latitude = 2;
 * @return {string}
 */
proto.dealer_service.SearchDealershipsRequest.prototype.getLatitude = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.SearchDealershipsRequest} returns this
 */
proto.dealer_service.SearchDealershipsRequest.prototype.setLatitude = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string longitude = 3;
 * @return {string}
 */
proto.dealer_service.SearchDealershipsRequest.prototype.getLongitude = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.SearchDealershipsRequest} returns this
 */
proto.dealer_service.SearchDealershipsRequest.prototype.setLongitude = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 limit = 4;
 * @return {number}
 */
proto.dealer_service.SearchDealershipsRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.dealer_service.SearchDealershipsRequest} returns this
 */
proto.dealer_service.SearchDealershipsRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dealer_service.SearchDealershipsResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer_service.SearchDealershipsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer_service.SearchDealershipsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer_service.SearchDealershipsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.SearchDealershipsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: jspb.Message.getFieldWithDefault(msg, 2, ""),
    dealershipsList: jspb.Message.toObjectList(msg.getDealershipsList(),
    domain_pb.Dealership.toObject, includeInstance),
    errorCode: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer_service.SearchDealershipsResponse}
 */
proto.dealer_service.SearchDealershipsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer_service.SearchDealershipsResponse;
  return proto.dealer_service.SearchDealershipsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer_service.SearchDealershipsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer_service.SearchDealershipsResponse}
 */
proto.dealer_service.SearchDealershipsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 3:
      var value = new domain_pb.Dealership;
      reader.readMessage(value,domain_pb.Dealership.deserializeBinaryFromReader);
      msg.addDealerships(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setErrorCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer_service.SearchDealershipsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer_service.SearchDealershipsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer_service.SearchDealershipsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.SearchDealershipsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDealershipsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      domain_pb.Dealership.serializeBinaryToWriter
    );
  }
  f = message.getErrorCode();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.dealer_service.SearchDealershipsResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dealer_service.SearchDealershipsResponse} returns this
 */
proto.dealer_service.SearchDealershipsResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.dealer_service.SearchDealershipsResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.SearchDealershipsResponse} returns this
 */
proto.dealer_service.SearchDealershipsResponse.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated domain.Dealership dealerships = 3;
 * @return {!Array<!proto.domain.Dealership>}
 */
proto.dealer_service.SearchDealershipsResponse.prototype.getDealershipsList = function() {
  return /** @type{!Array<!proto.domain.Dealership>} */ (
    jspb.Message.getRepeatedWrapperField(this, domain_pb.Dealership, 3));
};


/**
 * @param {!Array<!proto.domain.Dealership>} value
 * @return {!proto.dealer_service.SearchDealershipsResponse} returns this
*/
proto.dealer_service.SearchDealershipsResponse.prototype.setDealershipsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.domain.Dealership=} opt_value
 * @param {number=} opt_index
 * @return {!proto.domain.Dealership}
 */
proto.dealer_service.SearchDealershipsResponse.prototype.addDealerships = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.domain.Dealership, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dealer_service.SearchDealershipsResponse} returns this
 */
proto.dealer_service.SearchDealershipsResponse.prototype.clearDealershipsList = function() {
  return this.setDealershipsList([]);
};


/**
 * optional int32 error_code = 4;
 * @return {number}
 */
proto.dealer_service.SearchDealershipsResponse.prototype.getErrorCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.dealer_service.SearchDealershipsResponse} returns this
 */
proto.dealer_service.SearchDealershipsResponse.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer_service.SendVerificationCodeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer_service.SendVerificationCodeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer_service.SendVerificationCodeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.SendVerificationCodeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    cellPhone: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer_service.SendVerificationCodeRequest}
 */
proto.dealer_service.SendVerificationCodeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer_service.SendVerificationCodeRequest;
  return proto.dealer_service.SendVerificationCodeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer_service.SendVerificationCodeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer_service.SendVerificationCodeRequest}
 */
proto.dealer_service.SendVerificationCodeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCellPhone(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer_service.SendVerificationCodeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer_service.SendVerificationCodeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer_service.SendVerificationCodeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.SendVerificationCodeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCellPhone();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string cell_phone = 1;
 * @return {string}
 */
proto.dealer_service.SendVerificationCodeRequest.prototype.getCellPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.SendVerificationCodeRequest} returns this
 */
proto.dealer_service.SendVerificationCodeRequest.prototype.setCellPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer_service.SendVerificationCodeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer_service.SendVerificationCodeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer_service.SendVerificationCodeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.SendVerificationCodeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    cellPhone: jspb.Message.getFieldWithDefault(msg, 2, ""),
    error: jspb.Message.getFieldWithDefault(msg, 3, ""),
    errorCode: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer_service.SendVerificationCodeResponse}
 */
proto.dealer_service.SendVerificationCodeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer_service.SendVerificationCodeResponse;
  return proto.dealer_service.SendVerificationCodeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer_service.SendVerificationCodeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer_service.SendVerificationCodeResponse}
 */
proto.dealer_service.SendVerificationCodeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCellPhone(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setErrorCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer_service.SendVerificationCodeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer_service.SendVerificationCodeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer_service.SendVerificationCodeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.SendVerificationCodeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getCellPhone();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getErrorCode();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.dealer_service.SendVerificationCodeResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dealer_service.SendVerificationCodeResponse} returns this
 */
proto.dealer_service.SendVerificationCodeResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string cell_phone = 2;
 * @return {string}
 */
proto.dealer_service.SendVerificationCodeResponse.prototype.getCellPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.SendVerificationCodeResponse} returns this
 */
proto.dealer_service.SendVerificationCodeResponse.prototype.setCellPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string error = 3;
 * @return {string}
 */
proto.dealer_service.SendVerificationCodeResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.SendVerificationCodeResponse} returns this
 */
proto.dealer_service.SendVerificationCodeResponse.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 error_code = 4;
 * @return {number}
 */
proto.dealer_service.SendVerificationCodeResponse.prototype.getErrorCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.dealer_service.SendVerificationCodeResponse} returns this
 */
proto.dealer_service.SendVerificationCodeResponse.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer_service.CheckVerificationCodeCreateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer_service.CheckVerificationCodeCreateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer_service.CheckVerificationCodeCreateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.CheckVerificationCodeCreateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    verificationCode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    phoneNumber: jspb.Message.getFieldWithDefault(msg, 2, ""),
    dealershipId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer_service.CheckVerificationCodeCreateRequest}
 */
proto.dealer_service.CheckVerificationCodeCreateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer_service.CheckVerificationCodeCreateRequest;
  return proto.dealer_service.CheckVerificationCodeCreateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer_service.CheckVerificationCodeCreateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer_service.CheckVerificationCodeCreateRequest}
 */
proto.dealer_service.CheckVerificationCodeCreateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVerificationCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumber(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealershipId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer_service.CheckVerificationCodeCreateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer_service.CheckVerificationCodeCreateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer_service.CheckVerificationCodeCreateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.CheckVerificationCodeCreateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVerificationCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDealershipId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string verification_code = 1;
 * @return {string}
 */
proto.dealer_service.CheckVerificationCodeCreateRequest.prototype.getVerificationCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.CheckVerificationCodeCreateRequest} returns this
 */
proto.dealer_service.CheckVerificationCodeCreateRequest.prototype.setVerificationCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string phone_number = 2;
 * @return {string}
 */
proto.dealer_service.CheckVerificationCodeCreateRequest.prototype.getPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.CheckVerificationCodeCreateRequest} returns this
 */
proto.dealer_service.CheckVerificationCodeCreateRequest.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string dealership_id = 3;
 * @return {string}
 */
proto.dealer_service.CheckVerificationCodeCreateRequest.prototype.getDealershipId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.CheckVerificationCodeCreateRequest} returns this
 */
proto.dealer_service.CheckVerificationCodeCreateRequest.prototype.setDealershipId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer_service.CheckVerificationCodeCreateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer_service.CheckVerificationCodeCreateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer_service.CheckVerificationCodeCreateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.CheckVerificationCodeCreateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    dealerUserId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    error: jspb.Message.getFieldWithDefault(msg, 3, ""),
    errorCode: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer_service.CheckVerificationCodeCreateResponse}
 */
proto.dealer_service.CheckVerificationCodeCreateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer_service.CheckVerificationCodeCreateResponse;
  return proto.dealer_service.CheckVerificationCodeCreateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer_service.CheckVerificationCodeCreateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer_service.CheckVerificationCodeCreateResponse}
 */
proto.dealer_service.CheckVerificationCodeCreateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealerUserId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setErrorCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer_service.CheckVerificationCodeCreateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer_service.CheckVerificationCodeCreateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer_service.CheckVerificationCodeCreateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.CheckVerificationCodeCreateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getDealerUserId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getErrorCode();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.dealer_service.CheckVerificationCodeCreateResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dealer_service.CheckVerificationCodeCreateResponse} returns this
 */
proto.dealer_service.CheckVerificationCodeCreateResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string dealer_user_id = 2;
 * @return {string}
 */
proto.dealer_service.CheckVerificationCodeCreateResponse.prototype.getDealerUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.CheckVerificationCodeCreateResponse} returns this
 */
proto.dealer_service.CheckVerificationCodeCreateResponse.prototype.setDealerUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string error = 3;
 * @return {string}
 */
proto.dealer_service.CheckVerificationCodeCreateResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.CheckVerificationCodeCreateResponse} returns this
 */
proto.dealer_service.CheckVerificationCodeCreateResponse.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 error_code = 4;
 * @return {number}
 */
proto.dealer_service.CheckVerificationCodeCreateResponse.prototype.getErrorCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.dealer_service.CheckVerificationCodeCreateResponse} returns this
 */
proto.dealer_service.CheckVerificationCodeCreateResponse.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer_service.CheckVerificationCodeLoginRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer_service.CheckVerificationCodeLoginRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer_service.CheckVerificationCodeLoginRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.CheckVerificationCodeLoginRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    verificationCode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    phoneNumber: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer_service.CheckVerificationCodeLoginRequest}
 */
proto.dealer_service.CheckVerificationCodeLoginRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer_service.CheckVerificationCodeLoginRequest;
  return proto.dealer_service.CheckVerificationCodeLoginRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer_service.CheckVerificationCodeLoginRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer_service.CheckVerificationCodeLoginRequest}
 */
proto.dealer_service.CheckVerificationCodeLoginRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVerificationCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer_service.CheckVerificationCodeLoginRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer_service.CheckVerificationCodeLoginRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer_service.CheckVerificationCodeLoginRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.CheckVerificationCodeLoginRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVerificationCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string verification_code = 1;
 * @return {string}
 */
proto.dealer_service.CheckVerificationCodeLoginRequest.prototype.getVerificationCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.CheckVerificationCodeLoginRequest} returns this
 */
proto.dealer_service.CheckVerificationCodeLoginRequest.prototype.setVerificationCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string phone_number = 2;
 * @return {string}
 */
proto.dealer_service.CheckVerificationCodeLoginRequest.prototype.getPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.CheckVerificationCodeLoginRequest} returns this
 */
proto.dealer_service.CheckVerificationCodeLoginRequest.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer_service.CheckVerificationCodeLoginResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer_service.CheckVerificationCodeLoginResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer_service.CheckVerificationCodeLoginResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.CheckVerificationCodeLoginResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    dealerUserId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    error: jspb.Message.getFieldWithDefault(msg, 3, ""),
    token: jspb.Message.getFieldWithDefault(msg, 4, ""),
    errorCode: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer_service.CheckVerificationCodeLoginResponse}
 */
proto.dealer_service.CheckVerificationCodeLoginResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer_service.CheckVerificationCodeLoginResponse;
  return proto.dealer_service.CheckVerificationCodeLoginResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer_service.CheckVerificationCodeLoginResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer_service.CheckVerificationCodeLoginResponse}
 */
proto.dealer_service.CheckVerificationCodeLoginResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealerUserId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setErrorCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer_service.CheckVerificationCodeLoginResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer_service.CheckVerificationCodeLoginResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer_service.CheckVerificationCodeLoginResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.CheckVerificationCodeLoginResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getDealerUserId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getErrorCode();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.dealer_service.CheckVerificationCodeLoginResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dealer_service.CheckVerificationCodeLoginResponse} returns this
 */
proto.dealer_service.CheckVerificationCodeLoginResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string dealer_user_id = 2;
 * @return {string}
 */
proto.dealer_service.CheckVerificationCodeLoginResponse.prototype.getDealerUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.CheckVerificationCodeLoginResponse} returns this
 */
proto.dealer_service.CheckVerificationCodeLoginResponse.prototype.setDealerUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string error = 3;
 * @return {string}
 */
proto.dealer_service.CheckVerificationCodeLoginResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.CheckVerificationCodeLoginResponse} returns this
 */
proto.dealer_service.CheckVerificationCodeLoginResponse.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string token = 4;
 * @return {string}
 */
proto.dealer_service.CheckVerificationCodeLoginResponse.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.CheckVerificationCodeLoginResponse} returns this
 */
proto.dealer_service.CheckVerificationCodeLoginResponse.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 error_code = 5;
 * @return {number}
 */
proto.dealer_service.CheckVerificationCodeLoginResponse.prototype.getErrorCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.dealer_service.CheckVerificationCodeLoginResponse} returns this
 */
proto.dealer_service.CheckVerificationCodeLoginResponse.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer_service.AddWireInfoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer_service.AddWireInfoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer_service.AddWireInfoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.AddWireInfoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    bankName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    accountNumber: jspb.Message.getFieldWithDefault(msg, 2, ""),
    routingNumber: jspb.Message.getFieldWithDefault(msg, 3, ""),
    dealershipId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    dealerUserId: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer_service.AddWireInfoRequest}
 */
proto.dealer_service.AddWireInfoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer_service.AddWireInfoRequest;
  return proto.dealer_service.AddWireInfoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer_service.AddWireInfoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer_service.AddWireInfoRequest}
 */
proto.dealer_service.AddWireInfoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBankName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNumber(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoutingNumber(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealershipId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealerUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer_service.AddWireInfoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer_service.AddWireInfoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer_service.AddWireInfoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.AddWireInfoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBankName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccountNumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRoutingNumber();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDealershipId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDealerUserId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string bank_name = 1;
 * @return {string}
 */
proto.dealer_service.AddWireInfoRequest.prototype.getBankName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.AddWireInfoRequest} returns this
 */
proto.dealer_service.AddWireInfoRequest.prototype.setBankName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string account_number = 2;
 * @return {string}
 */
proto.dealer_service.AddWireInfoRequest.prototype.getAccountNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.AddWireInfoRequest} returns this
 */
proto.dealer_service.AddWireInfoRequest.prototype.setAccountNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string routing_number = 3;
 * @return {string}
 */
proto.dealer_service.AddWireInfoRequest.prototype.getRoutingNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.AddWireInfoRequest} returns this
 */
proto.dealer_service.AddWireInfoRequest.prototype.setRoutingNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string dealership_id = 4;
 * @return {string}
 */
proto.dealer_service.AddWireInfoRequest.prototype.getDealershipId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.AddWireInfoRequest} returns this
 */
proto.dealer_service.AddWireInfoRequest.prototype.setDealershipId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string dealer_user_id = 5;
 * @return {string}
 */
proto.dealer_service.AddWireInfoRequest.prototype.getDealerUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.AddWireInfoRequest} returns this
 */
proto.dealer_service.AddWireInfoRequest.prototype.setDealerUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer_service.AddWireInfoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer_service.AddWireInfoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer_service.AddWireInfoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.AddWireInfoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    dealerUserId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    error: jspb.Message.getFieldWithDefault(msg, 3, ""),
    errorCode: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer_service.AddWireInfoResponse}
 */
proto.dealer_service.AddWireInfoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer_service.AddWireInfoResponse;
  return proto.dealer_service.AddWireInfoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer_service.AddWireInfoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer_service.AddWireInfoResponse}
 */
proto.dealer_service.AddWireInfoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealerUserId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setErrorCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer_service.AddWireInfoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer_service.AddWireInfoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer_service.AddWireInfoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.AddWireInfoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getDealerUserId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getErrorCode();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.dealer_service.AddWireInfoResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dealer_service.AddWireInfoResponse} returns this
 */
proto.dealer_service.AddWireInfoResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string dealer_user_id = 2;
 * @return {string}
 */
proto.dealer_service.AddWireInfoResponse.prototype.getDealerUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.AddWireInfoResponse} returns this
 */
proto.dealer_service.AddWireInfoResponse.prototype.setDealerUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string error = 3;
 * @return {string}
 */
proto.dealer_service.AddWireInfoResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.AddWireInfoResponse} returns this
 */
proto.dealer_service.AddWireInfoResponse.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 error_code = 4;
 * @return {number}
 */
proto.dealer_service.AddWireInfoResponse.prototype.getErrorCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.dealer_service.AddWireInfoResponse} returns this
 */
proto.dealer_service.AddWireInfoResponse.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer_service.GetUserInformationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer_service.GetUserInformationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer_service.GetUserInformationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.GetUserInformationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealerUserId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer_service.GetUserInformationRequest}
 */
proto.dealer_service.GetUserInformationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer_service.GetUserInformationRequest;
  return proto.dealer_service.GetUserInformationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer_service.GetUserInformationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer_service.GetUserInformationRequest}
 */
proto.dealer_service.GetUserInformationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealerUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer_service.GetUserInformationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer_service.GetUserInformationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer_service.GetUserInformationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.GetUserInformationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealerUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string dealer_user_id = 1;
 * @return {string}
 */
proto.dealer_service.GetUserInformationRequest.prototype.getDealerUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.GetUserInformationRequest} returns this
 */
proto.dealer_service.GetUserInformationRequest.prototype.setDealerUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer_service.GetUserInformationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer_service.GetUserInformationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer_service.GetUserInformationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.GetUserInformationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    dealerUserInformation: (f = msg.getDealerUserInformation()) && domain_pb.DealerUser.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 3, ""),
    errorCode: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer_service.GetUserInformationResponse}
 */
proto.dealer_service.GetUserInformationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer_service.GetUserInformationResponse;
  return proto.dealer_service.GetUserInformationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer_service.GetUserInformationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer_service.GetUserInformationResponse}
 */
proto.dealer_service.GetUserInformationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new domain_pb.DealerUser;
      reader.readMessage(value,domain_pb.DealerUser.deserializeBinaryFromReader);
      msg.setDealerUserInformation(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setErrorCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer_service.GetUserInformationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer_service.GetUserInformationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer_service.GetUserInformationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.GetUserInformationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getDealerUserInformation();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      domain_pb.DealerUser.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getErrorCode();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.dealer_service.GetUserInformationResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dealer_service.GetUserInformationResponse} returns this
 */
proto.dealer_service.GetUserInformationResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional domain.DealerUser dealer_user_information = 2;
 * @return {?proto.domain.DealerUser}
 */
proto.dealer_service.GetUserInformationResponse.prototype.getDealerUserInformation = function() {
  return /** @type{?proto.domain.DealerUser} */ (
    jspb.Message.getWrapperField(this, domain_pb.DealerUser, 2));
};


/**
 * @param {?proto.domain.DealerUser|undefined} value
 * @return {!proto.dealer_service.GetUserInformationResponse} returns this
*/
proto.dealer_service.GetUserInformationResponse.prototype.setDealerUserInformation = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dealer_service.GetUserInformationResponse} returns this
 */
proto.dealer_service.GetUserInformationResponse.prototype.clearDealerUserInformation = function() {
  return this.setDealerUserInformation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dealer_service.GetUserInformationResponse.prototype.hasDealerUserInformation = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string error = 3;
 * @return {string}
 */
proto.dealer_service.GetUserInformationResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.GetUserInformationResponse} returns this
 */
proto.dealer_service.GetUserInformationResponse.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 error_code = 4;
 * @return {number}
 */
proto.dealer_service.GetUserInformationResponse.prototype.getErrorCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.dealer_service.GetUserInformationResponse} returns this
 */
proto.dealer_service.GetUserInformationResponse.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer_service.GetEmailInformationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer_service.GetEmailInformationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer_service.GetEmailInformationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.GetEmailInformationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealerUserId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer_service.GetEmailInformationRequest}
 */
proto.dealer_service.GetEmailInformationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer_service.GetEmailInformationRequest;
  return proto.dealer_service.GetEmailInformationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer_service.GetEmailInformationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer_service.GetEmailInformationRequest}
 */
proto.dealer_service.GetEmailInformationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealerUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer_service.GetEmailInformationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer_service.GetEmailInformationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer_service.GetEmailInformationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.GetEmailInformationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealerUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string dealer_user_id = 1;
 * @return {string}
 */
proto.dealer_service.GetEmailInformationRequest.prototype.getDealerUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.GetEmailInformationRequest} returns this
 */
proto.dealer_service.GetEmailInformationRequest.prototype.setDealerUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer_service.GetEmailInformationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer_service.GetEmailInformationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer_service.GetEmailInformationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.GetEmailInformationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    dealerUser: (f = msg.getDealerUser()) && domain_pb.DealerUser.toObject(includeInstance, f),
    dealership: (f = msg.getDealership()) && domain_pb.Dealership.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 4, ""),
    errorCode: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer_service.GetEmailInformationResponse}
 */
proto.dealer_service.GetEmailInformationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer_service.GetEmailInformationResponse;
  return proto.dealer_service.GetEmailInformationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer_service.GetEmailInformationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer_service.GetEmailInformationResponse}
 */
proto.dealer_service.GetEmailInformationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new domain_pb.DealerUser;
      reader.readMessage(value,domain_pb.DealerUser.deserializeBinaryFromReader);
      msg.setDealerUser(value);
      break;
    case 3:
      var value = new domain_pb.Dealership;
      reader.readMessage(value,domain_pb.Dealership.deserializeBinaryFromReader);
      msg.setDealership(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setErrorCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer_service.GetEmailInformationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer_service.GetEmailInformationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer_service.GetEmailInformationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.GetEmailInformationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getDealerUser();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      domain_pb.DealerUser.serializeBinaryToWriter
    );
  }
  f = message.getDealership();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      domain_pb.Dealership.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getErrorCode();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.dealer_service.GetEmailInformationResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dealer_service.GetEmailInformationResponse} returns this
 */
proto.dealer_service.GetEmailInformationResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional domain.DealerUser dealer_user = 2;
 * @return {?proto.domain.DealerUser}
 */
proto.dealer_service.GetEmailInformationResponse.prototype.getDealerUser = function() {
  return /** @type{?proto.domain.DealerUser} */ (
    jspb.Message.getWrapperField(this, domain_pb.DealerUser, 2));
};


/**
 * @param {?proto.domain.DealerUser|undefined} value
 * @return {!proto.dealer_service.GetEmailInformationResponse} returns this
*/
proto.dealer_service.GetEmailInformationResponse.prototype.setDealerUser = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dealer_service.GetEmailInformationResponse} returns this
 */
proto.dealer_service.GetEmailInformationResponse.prototype.clearDealerUser = function() {
  return this.setDealerUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dealer_service.GetEmailInformationResponse.prototype.hasDealerUser = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional domain.Dealership dealership = 3;
 * @return {?proto.domain.Dealership}
 */
proto.dealer_service.GetEmailInformationResponse.prototype.getDealership = function() {
  return /** @type{?proto.domain.Dealership} */ (
    jspb.Message.getWrapperField(this, domain_pb.Dealership, 3));
};


/**
 * @param {?proto.domain.Dealership|undefined} value
 * @return {!proto.dealer_service.GetEmailInformationResponse} returns this
*/
proto.dealer_service.GetEmailInformationResponse.prototype.setDealership = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dealer_service.GetEmailInformationResponse} returns this
 */
proto.dealer_service.GetEmailInformationResponse.prototype.clearDealership = function() {
  return this.setDealership(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dealer_service.GetEmailInformationResponse.prototype.hasDealership = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string error = 4;
 * @return {string}
 */
proto.dealer_service.GetEmailInformationResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.GetEmailInformationResponse} returns this
 */
proto.dealer_service.GetEmailInformationResponse.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 error_code = 5;
 * @return {number}
 */
proto.dealer_service.GetEmailInformationResponse.prototype.getErrorCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.dealer_service.GetEmailInformationResponse} returns this
 */
proto.dealer_service.GetEmailInformationResponse.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer_service.GetVehicleByIdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer_service.GetVehicleByIdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer_service.GetVehicleByIdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.GetVehicleByIdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer_service.GetVehicleByIdRequest}
 */
proto.dealer_service.GetVehicleByIdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer_service.GetVehicleByIdRequest;
  return proto.dealer_service.GetVehicleByIdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer_service.GetVehicleByIdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer_service.GetVehicleByIdRequest}
 */
proto.dealer_service.GetVehicleByIdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer_service.GetVehicleByIdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer_service.GetVehicleByIdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer_service.GetVehicleByIdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.GetVehicleByIdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.dealer_service.GetVehicleByIdRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.GetVehicleByIdRequest} returns this
 */
proto.dealer_service.GetVehicleByIdRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer_service.GetVehicleByIdResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer_service.GetVehicleByIdResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer_service.GetVehicleByIdResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.GetVehicleByIdResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    vehicle: (f = msg.getVehicle()) && vehicle_pb.Vehicle.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 3, ""),
    errorCode: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer_service.GetVehicleByIdResponse}
 */
proto.dealer_service.GetVehicleByIdResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer_service.GetVehicleByIdResponse;
  return proto.dealer_service.GetVehicleByIdResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer_service.GetVehicleByIdResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer_service.GetVehicleByIdResponse}
 */
proto.dealer_service.GetVehicleByIdResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new vehicle_pb.Vehicle;
      reader.readMessage(value,vehicle_pb.Vehicle.deserializeBinaryFromReader);
      msg.setVehicle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setErrorCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer_service.GetVehicleByIdResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer_service.GetVehicleByIdResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer_service.GetVehicleByIdResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.GetVehicleByIdResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getVehicle();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      vehicle_pb.Vehicle.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getErrorCode();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.dealer_service.GetVehicleByIdResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dealer_service.GetVehicleByIdResponse} returns this
 */
proto.dealer_service.GetVehicleByIdResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional vehicle.Vehicle vehicle = 2;
 * @return {?proto.vehicle.Vehicle}
 */
proto.dealer_service.GetVehicleByIdResponse.prototype.getVehicle = function() {
  return /** @type{?proto.vehicle.Vehicle} */ (
    jspb.Message.getWrapperField(this, vehicle_pb.Vehicle, 2));
};


/**
 * @param {?proto.vehicle.Vehicle|undefined} value
 * @return {!proto.dealer_service.GetVehicleByIdResponse} returns this
*/
proto.dealer_service.GetVehicleByIdResponse.prototype.setVehicle = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dealer_service.GetVehicleByIdResponse} returns this
 */
proto.dealer_service.GetVehicleByIdResponse.prototype.clearVehicle = function() {
  return this.setVehicle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dealer_service.GetVehicleByIdResponse.prototype.hasVehicle = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string error = 3;
 * @return {string}
 */
proto.dealer_service.GetVehicleByIdResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.GetVehicleByIdResponse} returns this
 */
proto.dealer_service.GetVehicleByIdResponse.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 error_code = 4;
 * @return {number}
 */
proto.dealer_service.GetVehicleByIdResponse.prototype.getErrorCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.dealer_service.GetVehicleByIdResponse} returns this
 */
proto.dealer_service.GetVehicleByIdResponse.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer_service.ViewTeamRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer_service.ViewTeamRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer_service.ViewTeamRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.ViewTeamRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealerUserId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dealershipId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer_service.ViewTeamRequest}
 */
proto.dealer_service.ViewTeamRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer_service.ViewTeamRequest;
  return proto.dealer_service.ViewTeamRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer_service.ViewTeamRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer_service.ViewTeamRequest}
 */
proto.dealer_service.ViewTeamRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealerUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealershipId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer_service.ViewTeamRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer_service.ViewTeamRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer_service.ViewTeamRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.ViewTeamRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealerUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDealershipId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string dealer_user_id = 1;
 * @return {string}
 */
proto.dealer_service.ViewTeamRequest.prototype.getDealerUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.ViewTeamRequest} returns this
 */
proto.dealer_service.ViewTeamRequest.prototype.setDealerUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string dealership_id = 2;
 * @return {string}
 */
proto.dealer_service.ViewTeamRequest.prototype.getDealershipId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.ViewTeamRequest} returns this
 */
proto.dealer_service.ViewTeamRequest.prototype.setDealershipId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dealer_service.ViewTeamResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer_service.ViewTeamResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer_service.ViewTeamResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer_service.ViewTeamResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.ViewTeamResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    teamMembersList: jspb.Message.toObjectList(msg.getTeamMembersList(),
    domain_pb.TeamMember.toObject, includeInstance),
    error: jspb.Message.getFieldWithDefault(msg, 3, ""),
    errorCode: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer_service.ViewTeamResponse}
 */
proto.dealer_service.ViewTeamResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer_service.ViewTeamResponse;
  return proto.dealer_service.ViewTeamResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer_service.ViewTeamResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer_service.ViewTeamResponse}
 */
proto.dealer_service.ViewTeamResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new domain_pb.TeamMember;
      reader.readMessage(value,domain_pb.TeamMember.deserializeBinaryFromReader);
      msg.addTeamMembers(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setErrorCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer_service.ViewTeamResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer_service.ViewTeamResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer_service.ViewTeamResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.ViewTeamResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getTeamMembersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      domain_pb.TeamMember.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getErrorCode();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.dealer_service.ViewTeamResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dealer_service.ViewTeamResponse} returns this
 */
proto.dealer_service.ViewTeamResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * repeated domain.TeamMember team_members = 2;
 * @return {!Array<!proto.domain.TeamMember>}
 */
proto.dealer_service.ViewTeamResponse.prototype.getTeamMembersList = function() {
  return /** @type{!Array<!proto.domain.TeamMember>} */ (
    jspb.Message.getRepeatedWrapperField(this, domain_pb.TeamMember, 2));
};


/**
 * @param {!Array<!proto.domain.TeamMember>} value
 * @return {!proto.dealer_service.ViewTeamResponse} returns this
*/
proto.dealer_service.ViewTeamResponse.prototype.setTeamMembersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.domain.TeamMember=} opt_value
 * @param {number=} opt_index
 * @return {!proto.domain.TeamMember}
 */
proto.dealer_service.ViewTeamResponse.prototype.addTeamMembers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.domain.TeamMember, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dealer_service.ViewTeamResponse} returns this
 */
proto.dealer_service.ViewTeamResponse.prototype.clearTeamMembersList = function() {
  return this.setTeamMembersList([]);
};


/**
 * optional string error = 3;
 * @return {string}
 */
proto.dealer_service.ViewTeamResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.ViewTeamResponse} returns this
 */
proto.dealer_service.ViewTeamResponse.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 error_code = 4;
 * @return {number}
 */
proto.dealer_service.ViewTeamResponse.prototype.getErrorCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.dealer_service.ViewTeamResponse} returns this
 */
proto.dealer_service.ViewTeamResponse.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer_service.GetDealershipEventsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer_service.GetDealershipEventsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer_service.GetDealershipEventsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.GetDealershipEventsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealerUserId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dealershipId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    timezone: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer_service.GetDealershipEventsRequest}
 */
proto.dealer_service.GetDealershipEventsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer_service.GetDealershipEventsRequest;
  return proto.dealer_service.GetDealershipEventsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer_service.GetDealershipEventsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer_service.GetDealershipEventsRequest}
 */
proto.dealer_service.GetDealershipEventsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealerUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealershipId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimezone(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer_service.GetDealershipEventsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer_service.GetDealershipEventsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer_service.GetDealershipEventsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.GetDealershipEventsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealerUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDealershipId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTimezone();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string dealer_user_id = 1;
 * @return {string}
 */
proto.dealer_service.GetDealershipEventsRequest.prototype.getDealerUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.GetDealershipEventsRequest} returns this
 */
proto.dealer_service.GetDealershipEventsRequest.prototype.setDealerUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string dealership_id = 2;
 * @return {string}
 */
proto.dealer_service.GetDealershipEventsRequest.prototype.getDealershipId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.GetDealershipEventsRequest} returns this
 */
proto.dealer_service.GetDealershipEventsRequest.prototype.setDealershipId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string timezone = 3;
 * @return {string}
 */
proto.dealer_service.GetDealershipEventsRequest.prototype.getTimezone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.GetDealershipEventsRequest} returns this
 */
proto.dealer_service.GetDealershipEventsRequest.prototype.setTimezone = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dealer_service.GetDealershipEventsResponse.repeatedFields_ = [4,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer_service.GetDealershipEventsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer_service.GetDealershipEventsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer_service.GetDealershipEventsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.GetDealershipEventsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    dealershipId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    offersForReview: jspb.Message.getFieldWithDefault(msg, 3, ""),
    stockNumbersList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    scheduledAppointments: jspb.Message.getFieldWithDefault(msg, 5, ""),
    completedDeals: jspb.Message.getFieldWithDefault(msg, 6, ""),
    teamMembersList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    inventory: jspb.Message.getFieldWithDefault(msg, 8, 0),
    error: jspb.Message.getFieldWithDefault(msg, 9, ""),
    errorCode: jspb.Message.getFieldWithDefault(msg, 10, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer_service.GetDealershipEventsResponse}
 */
proto.dealer_service.GetDealershipEventsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer_service.GetDealershipEventsResponse;
  return proto.dealer_service.GetDealershipEventsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer_service.GetDealershipEventsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer_service.GetDealershipEventsResponse}
 */
proto.dealer_service.GetDealershipEventsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealershipId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOffersForReview(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addStockNumbers(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setScheduledAppointments(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompletedDeals(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.addTeamMembers(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setInventory(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setErrorCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer_service.GetDealershipEventsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer_service.GetDealershipEventsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer_service.GetDealershipEventsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.GetDealershipEventsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getDealershipId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOffersForReview();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStockNumbersList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getScheduledAppointments();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCompletedDeals();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getTeamMembersList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      7,
      f
    );
  }
  f = message.getInventory();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getErrorCode();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.dealer_service.GetDealershipEventsResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dealer_service.GetDealershipEventsResponse} returns this
 */
proto.dealer_service.GetDealershipEventsResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string dealership_id = 2;
 * @return {string}
 */
proto.dealer_service.GetDealershipEventsResponse.prototype.getDealershipId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.GetDealershipEventsResponse} returns this
 */
proto.dealer_service.GetDealershipEventsResponse.prototype.setDealershipId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string offers_for_review = 3;
 * @return {string}
 */
proto.dealer_service.GetDealershipEventsResponse.prototype.getOffersForReview = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.GetDealershipEventsResponse} returns this
 */
proto.dealer_service.GetDealershipEventsResponse.prototype.setOffersForReview = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated string stock_numbers = 4;
 * @return {!Array<string>}
 */
proto.dealer_service.GetDealershipEventsResponse.prototype.getStockNumbersList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.dealer_service.GetDealershipEventsResponse} returns this
 */
proto.dealer_service.GetDealershipEventsResponse.prototype.setStockNumbersList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.dealer_service.GetDealershipEventsResponse} returns this
 */
proto.dealer_service.GetDealershipEventsResponse.prototype.addStockNumbers = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dealer_service.GetDealershipEventsResponse} returns this
 */
proto.dealer_service.GetDealershipEventsResponse.prototype.clearStockNumbersList = function() {
  return this.setStockNumbersList([]);
};


/**
 * optional string scheduled_appointments = 5;
 * @return {string}
 */
proto.dealer_service.GetDealershipEventsResponse.prototype.getScheduledAppointments = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.GetDealershipEventsResponse} returns this
 */
proto.dealer_service.GetDealershipEventsResponse.prototype.setScheduledAppointments = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string completed_deals = 6;
 * @return {string}
 */
proto.dealer_service.GetDealershipEventsResponse.prototype.getCompletedDeals = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.GetDealershipEventsResponse} returns this
 */
proto.dealer_service.GetDealershipEventsResponse.prototype.setCompletedDeals = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * repeated string team_members = 7;
 * @return {!Array<string>}
 */
proto.dealer_service.GetDealershipEventsResponse.prototype.getTeamMembersList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.dealer_service.GetDealershipEventsResponse} returns this
 */
proto.dealer_service.GetDealershipEventsResponse.prototype.setTeamMembersList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.dealer_service.GetDealershipEventsResponse} returns this
 */
proto.dealer_service.GetDealershipEventsResponse.prototype.addTeamMembers = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dealer_service.GetDealershipEventsResponse} returns this
 */
proto.dealer_service.GetDealershipEventsResponse.prototype.clearTeamMembersList = function() {
  return this.setTeamMembersList([]);
};


/**
 * optional int32 inventory = 8;
 * @return {number}
 */
proto.dealer_service.GetDealershipEventsResponse.prototype.getInventory = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.dealer_service.GetDealershipEventsResponse} returns this
 */
proto.dealer_service.GetDealershipEventsResponse.prototype.setInventory = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string error = 9;
 * @return {string}
 */
proto.dealer_service.GetDealershipEventsResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.GetDealershipEventsResponse} returns this
 */
proto.dealer_service.GetDealershipEventsResponse.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional int32 error_code = 10;
 * @return {number}
 */
proto.dealer_service.GetDealershipEventsResponse.prototype.getErrorCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.dealer_service.GetDealershipEventsResponse} returns this
 */
proto.dealer_service.GetDealershipEventsResponse.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer_service.ViewDealerAppointmentsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer_service.ViewDealerAppointmentsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer_service.ViewDealerAppointmentsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.ViewDealerAppointmentsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealershipId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    scheduledAfterTimestamp: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer_service.ViewDealerAppointmentsRequest}
 */
proto.dealer_service.ViewDealerAppointmentsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer_service.ViewDealerAppointmentsRequest;
  return proto.dealer_service.ViewDealerAppointmentsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer_service.ViewDealerAppointmentsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer_service.ViewDealerAppointmentsRequest}
 */
proto.dealer_service.ViewDealerAppointmentsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealershipId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setScheduledAfterTimestamp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer_service.ViewDealerAppointmentsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer_service.ViewDealerAppointmentsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer_service.ViewDealerAppointmentsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.ViewDealerAppointmentsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealershipId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getScheduledAfterTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional string dealership_id = 1;
 * @return {string}
 */
proto.dealer_service.ViewDealerAppointmentsRequest.prototype.getDealershipId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.ViewDealerAppointmentsRequest} returns this
 */
proto.dealer_service.ViewDealerAppointmentsRequest.prototype.setDealershipId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 scheduled_after_timestamp = 2;
 * @return {number}
 */
proto.dealer_service.ViewDealerAppointmentsRequest.prototype.getScheduledAfterTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.dealer_service.ViewDealerAppointmentsRequest} returns this
 */
proto.dealer_service.ViewDealerAppointmentsRequest.prototype.setScheduledAfterTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dealer_service.ViewDealerAppointmentsResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer_service.ViewDealerAppointmentsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer_service.ViewDealerAppointmentsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer_service.ViewDealerAppointmentsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.ViewDealerAppointmentsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    appointmentVehiclePairList: jspb.Message.toObjectList(msg.getAppointmentVehiclePairList(),
    proto.dealer_service.AppointmentVehicleLink.toObject, includeInstance),
    error: jspb.Message.getFieldWithDefault(msg, 3, ""),
    errorCode: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer_service.ViewDealerAppointmentsResponse}
 */
proto.dealer_service.ViewDealerAppointmentsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer_service.ViewDealerAppointmentsResponse;
  return proto.dealer_service.ViewDealerAppointmentsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer_service.ViewDealerAppointmentsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer_service.ViewDealerAppointmentsResponse}
 */
proto.dealer_service.ViewDealerAppointmentsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new proto.dealer_service.AppointmentVehicleLink;
      reader.readMessage(value,proto.dealer_service.AppointmentVehicleLink.deserializeBinaryFromReader);
      msg.addAppointmentVehiclePair(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setErrorCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer_service.ViewDealerAppointmentsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer_service.ViewDealerAppointmentsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer_service.ViewDealerAppointmentsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.ViewDealerAppointmentsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getAppointmentVehiclePairList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.dealer_service.AppointmentVehicleLink.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getErrorCode();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.dealer_service.ViewDealerAppointmentsResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dealer_service.ViewDealerAppointmentsResponse} returns this
 */
proto.dealer_service.ViewDealerAppointmentsResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * repeated AppointmentVehicleLink appointment_vehicle_pair = 2;
 * @return {!Array<!proto.dealer_service.AppointmentVehicleLink>}
 */
proto.dealer_service.ViewDealerAppointmentsResponse.prototype.getAppointmentVehiclePairList = function() {
  return /** @type{!Array<!proto.dealer_service.AppointmentVehicleLink>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.dealer_service.AppointmentVehicleLink, 2));
};


/**
 * @param {!Array<!proto.dealer_service.AppointmentVehicleLink>} value
 * @return {!proto.dealer_service.ViewDealerAppointmentsResponse} returns this
*/
proto.dealer_service.ViewDealerAppointmentsResponse.prototype.setAppointmentVehiclePairList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.dealer_service.AppointmentVehicleLink=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dealer_service.AppointmentVehicleLink}
 */
proto.dealer_service.ViewDealerAppointmentsResponse.prototype.addAppointmentVehiclePair = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.dealer_service.AppointmentVehicleLink, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dealer_service.ViewDealerAppointmentsResponse} returns this
 */
proto.dealer_service.ViewDealerAppointmentsResponse.prototype.clearAppointmentVehiclePairList = function() {
  return this.setAppointmentVehiclePairList([]);
};


/**
 * optional string error = 3;
 * @return {string}
 */
proto.dealer_service.ViewDealerAppointmentsResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.ViewDealerAppointmentsResponse} returns this
 */
proto.dealer_service.ViewDealerAppointmentsResponse.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 error_code = 4;
 * @return {number}
 */
proto.dealer_service.ViewDealerAppointmentsResponse.prototype.getErrorCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.dealer_service.ViewDealerAppointmentsResponse} returns this
 */
proto.dealer_service.ViewDealerAppointmentsResponse.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer_service.AppointmentVehicleLink.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer_service.AppointmentVehicleLink.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer_service.AppointmentVehicleLink} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.AppointmentVehicleLink.toObject = function(includeInstance, msg) {
  var f, obj = {
    appointment: (f = msg.getAppointment()) && domain_pb.Appointment.toObject(includeInstance, f),
    appointmentVehicle: (f = msg.getAppointmentVehicle()) && domain_pb.AppointmentVehicle.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer_service.AppointmentVehicleLink}
 */
proto.dealer_service.AppointmentVehicleLink.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer_service.AppointmentVehicleLink;
  return proto.dealer_service.AppointmentVehicleLink.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer_service.AppointmentVehicleLink} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer_service.AppointmentVehicleLink}
 */
proto.dealer_service.AppointmentVehicleLink.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new domain_pb.Appointment;
      reader.readMessage(value,domain_pb.Appointment.deserializeBinaryFromReader);
      msg.setAppointment(value);
      break;
    case 2:
      var value = new domain_pb.AppointmentVehicle;
      reader.readMessage(value,domain_pb.AppointmentVehicle.deserializeBinaryFromReader);
      msg.setAppointmentVehicle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer_service.AppointmentVehicleLink.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer_service.AppointmentVehicleLink.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer_service.AppointmentVehicleLink} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.AppointmentVehicleLink.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppointment();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      domain_pb.Appointment.serializeBinaryToWriter
    );
  }
  f = message.getAppointmentVehicle();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      domain_pb.AppointmentVehicle.serializeBinaryToWriter
    );
  }
};


/**
 * optional domain.Appointment appointment = 1;
 * @return {?proto.domain.Appointment}
 */
proto.dealer_service.AppointmentVehicleLink.prototype.getAppointment = function() {
  return /** @type{?proto.domain.Appointment} */ (
    jspb.Message.getWrapperField(this, domain_pb.Appointment, 1));
};


/**
 * @param {?proto.domain.Appointment|undefined} value
 * @return {!proto.dealer_service.AppointmentVehicleLink} returns this
*/
proto.dealer_service.AppointmentVehicleLink.prototype.setAppointment = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dealer_service.AppointmentVehicleLink} returns this
 */
proto.dealer_service.AppointmentVehicleLink.prototype.clearAppointment = function() {
  return this.setAppointment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dealer_service.AppointmentVehicleLink.prototype.hasAppointment = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional domain.AppointmentVehicle appointment_vehicle = 2;
 * @return {?proto.domain.AppointmentVehicle}
 */
proto.dealer_service.AppointmentVehicleLink.prototype.getAppointmentVehicle = function() {
  return /** @type{?proto.domain.AppointmentVehicle} */ (
    jspb.Message.getWrapperField(this, domain_pb.AppointmentVehicle, 2));
};


/**
 * @param {?proto.domain.AppointmentVehicle|undefined} value
 * @return {!proto.dealer_service.AppointmentVehicleLink} returns this
*/
proto.dealer_service.AppointmentVehicleLink.prototype.setAppointmentVehicle = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dealer_service.AppointmentVehicleLink} returns this
 */
proto.dealer_service.AppointmentVehicleLink.prototype.clearAppointmentVehicle = function() {
  return this.setAppointmentVehicle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dealer_service.AppointmentVehicleLink.prototype.hasAppointmentVehicle = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer_service.AddInvitationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer_service.AddInvitationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer_service.AddInvitationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.AddInvitationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealershipId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sentByDealerUserId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    phoneNumber: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer_service.AddInvitationRequest}
 */
proto.dealer_service.AddInvitationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer_service.AddInvitationRequest;
  return proto.dealer_service.AddInvitationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer_service.AddInvitationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer_service.AddInvitationRequest}
 */
proto.dealer_service.AddInvitationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealershipId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSentByDealerUserId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer_service.AddInvitationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer_service.AddInvitationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer_service.AddInvitationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.AddInvitationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealershipId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSentByDealerUserId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string dealership_id = 1;
 * @return {string}
 */
proto.dealer_service.AddInvitationRequest.prototype.getDealershipId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.AddInvitationRequest} returns this
 */
proto.dealer_service.AddInvitationRequest.prototype.setDealershipId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string sent_by_dealer_user_id = 2;
 * @return {string}
 */
proto.dealer_service.AddInvitationRequest.prototype.getSentByDealerUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.AddInvitationRequest} returns this
 */
proto.dealer_service.AddInvitationRequest.prototype.setSentByDealerUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string phone_number = 3;
 * @return {string}
 */
proto.dealer_service.AddInvitationRequest.prototype.getPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.AddInvitationRequest} returns this
 */
proto.dealer_service.AddInvitationRequest.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer_service.AddInvitationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer_service.AddInvitationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer_service.AddInvitationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.AddInvitationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    id: jspb.Message.getFieldWithDefault(msg, 2, ""),
    status: jspb.Message.getFieldWithDefault(msg, 3, ""),
    error: jspb.Message.getFieldWithDefault(msg, 4, ""),
    errorCode: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer_service.AddInvitationResponse}
 */
proto.dealer_service.AddInvitationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer_service.AddInvitationResponse;
  return proto.dealer_service.AddInvitationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer_service.AddInvitationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer_service.AddInvitationResponse}
 */
proto.dealer_service.AddInvitationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setErrorCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer_service.AddInvitationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer_service.AddInvitationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer_service.AddInvitationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.AddInvitationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getErrorCode();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.dealer_service.AddInvitationResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dealer_service.AddInvitationResponse} returns this
 */
proto.dealer_service.AddInvitationResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string id = 2;
 * @return {string}
 */
proto.dealer_service.AddInvitationResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.AddInvitationResponse} returns this
 */
proto.dealer_service.AddInvitationResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string status = 3;
 * @return {string}
 */
proto.dealer_service.AddInvitationResponse.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.AddInvitationResponse} returns this
 */
proto.dealer_service.AddInvitationResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string error = 4;
 * @return {string}
 */
proto.dealer_service.AddInvitationResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.AddInvitationResponse} returns this
 */
proto.dealer_service.AddInvitationResponse.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 error_code = 5;
 * @return {number}
 */
proto.dealer_service.AddInvitationResponse.prototype.getErrorCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.dealer_service.AddInvitationResponse} returns this
 */
proto.dealer_service.AddInvitationResponse.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer_service.ConfirmVehicleAvailableRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer_service.ConfirmVehicleAvailableRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer_service.ConfirmVehicleAvailableRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.ConfirmVehicleAvailableRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealerUserId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    vehicleId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    dealershipId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    offerId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    customerId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    agree: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer_service.ConfirmVehicleAvailableRequest}
 */
proto.dealer_service.ConfirmVehicleAvailableRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer_service.ConfirmVehicleAvailableRequest;
  return proto.dealer_service.ConfirmVehicleAvailableRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer_service.ConfirmVehicleAvailableRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer_service.ConfirmVehicleAvailableRequest}
 */
proto.dealer_service.ConfirmVehicleAvailableRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealerUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealershipId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOfferId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAgree(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer_service.ConfirmVehicleAvailableRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer_service.ConfirmVehicleAvailableRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer_service.ConfirmVehicleAvailableRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.ConfirmVehicleAvailableRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealerUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDealershipId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOfferId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAgree();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional string dealer_user_id = 1;
 * @return {string}
 */
proto.dealer_service.ConfirmVehicleAvailableRequest.prototype.getDealerUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.ConfirmVehicleAvailableRequest} returns this
 */
proto.dealer_service.ConfirmVehicleAvailableRequest.prototype.setDealerUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string vehicle_id = 2;
 * @return {string}
 */
proto.dealer_service.ConfirmVehicleAvailableRequest.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.ConfirmVehicleAvailableRequest} returns this
 */
proto.dealer_service.ConfirmVehicleAvailableRequest.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string dealership_id = 3;
 * @return {string}
 */
proto.dealer_service.ConfirmVehicleAvailableRequest.prototype.getDealershipId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.ConfirmVehicleAvailableRequest} returns this
 */
proto.dealer_service.ConfirmVehicleAvailableRequest.prototype.setDealershipId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string offer_id = 4;
 * @return {string}
 */
proto.dealer_service.ConfirmVehicleAvailableRequest.prototype.getOfferId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.ConfirmVehicleAvailableRequest} returns this
 */
proto.dealer_service.ConfirmVehicleAvailableRequest.prototype.setOfferId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string customer_id = 5;
 * @return {string}
 */
proto.dealer_service.ConfirmVehicleAvailableRequest.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.ConfirmVehicleAvailableRequest} returns this
 */
proto.dealer_service.ConfirmVehicleAvailableRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional bool agree = 6;
 * @return {boolean}
 */
proto.dealer_service.ConfirmVehicleAvailableRequest.prototype.getAgree = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dealer_service.ConfirmVehicleAvailableRequest} returns this
 */
proto.dealer_service.ConfirmVehicleAvailableRequest.prototype.setAgree = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer_service.ConfirmVehicleAvailableResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer_service.ConfirmVehicleAvailableResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer_service.ConfirmVehicleAvailableResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.ConfirmVehicleAvailableResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    dealerUserId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    vehicleId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    error: jspb.Message.getFieldWithDefault(msg, 4, ""),
    errorCode: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer_service.ConfirmVehicleAvailableResponse}
 */
proto.dealer_service.ConfirmVehicleAvailableResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer_service.ConfirmVehicleAvailableResponse;
  return proto.dealer_service.ConfirmVehicleAvailableResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer_service.ConfirmVehicleAvailableResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer_service.ConfirmVehicleAvailableResponse}
 */
proto.dealer_service.ConfirmVehicleAvailableResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealerUserId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setErrorCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer_service.ConfirmVehicleAvailableResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer_service.ConfirmVehicleAvailableResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer_service.ConfirmVehicleAvailableResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.ConfirmVehicleAvailableResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getDealerUserId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getErrorCode();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.dealer_service.ConfirmVehicleAvailableResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dealer_service.ConfirmVehicleAvailableResponse} returns this
 */
proto.dealer_service.ConfirmVehicleAvailableResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string dealer_user_id = 2;
 * @return {string}
 */
proto.dealer_service.ConfirmVehicleAvailableResponse.prototype.getDealerUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.ConfirmVehicleAvailableResponse} returns this
 */
proto.dealer_service.ConfirmVehicleAvailableResponse.prototype.setDealerUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string vehicle_id = 3;
 * @return {string}
 */
proto.dealer_service.ConfirmVehicleAvailableResponse.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.ConfirmVehicleAvailableResponse} returns this
 */
proto.dealer_service.ConfirmVehicleAvailableResponse.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string error = 4;
 * @return {string}
 */
proto.dealer_service.ConfirmVehicleAvailableResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.ConfirmVehicleAvailableResponse} returns this
 */
proto.dealer_service.ConfirmVehicleAvailableResponse.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 error_code = 5;
 * @return {number}
 */
proto.dealer_service.ConfirmVehicleAvailableResponse.prototype.getErrorCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.dealer_service.ConfirmVehicleAvailableResponse} returns this
 */
proto.dealer_service.ConfirmVehicleAvailableResponse.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer_service.ConfirmVehiclePriceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer_service.ConfirmVehiclePriceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer_service.ConfirmVehiclePriceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.ConfirmVehiclePriceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealerUserId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    vehicleId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    dealershipId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    offerId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    customerId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    agree: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer_service.ConfirmVehiclePriceRequest}
 */
proto.dealer_service.ConfirmVehiclePriceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer_service.ConfirmVehiclePriceRequest;
  return proto.dealer_service.ConfirmVehiclePriceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer_service.ConfirmVehiclePriceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer_service.ConfirmVehiclePriceRequest}
 */
proto.dealer_service.ConfirmVehiclePriceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealerUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealershipId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOfferId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAgree(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer_service.ConfirmVehiclePriceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer_service.ConfirmVehiclePriceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer_service.ConfirmVehiclePriceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.ConfirmVehiclePriceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealerUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDealershipId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOfferId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAgree();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional string dealer_user_id = 1;
 * @return {string}
 */
proto.dealer_service.ConfirmVehiclePriceRequest.prototype.getDealerUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.ConfirmVehiclePriceRequest} returns this
 */
proto.dealer_service.ConfirmVehiclePriceRequest.prototype.setDealerUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string vehicle_id = 2;
 * @return {string}
 */
proto.dealer_service.ConfirmVehiclePriceRequest.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.ConfirmVehiclePriceRequest} returns this
 */
proto.dealer_service.ConfirmVehiclePriceRequest.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string dealership_id = 3;
 * @return {string}
 */
proto.dealer_service.ConfirmVehiclePriceRequest.prototype.getDealershipId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.ConfirmVehiclePriceRequest} returns this
 */
proto.dealer_service.ConfirmVehiclePriceRequest.prototype.setDealershipId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string offer_id = 4;
 * @return {string}
 */
proto.dealer_service.ConfirmVehiclePriceRequest.prototype.getOfferId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.ConfirmVehiclePriceRequest} returns this
 */
proto.dealer_service.ConfirmVehiclePriceRequest.prototype.setOfferId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string customer_id = 5;
 * @return {string}
 */
proto.dealer_service.ConfirmVehiclePriceRequest.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.ConfirmVehiclePriceRequest} returns this
 */
proto.dealer_service.ConfirmVehiclePriceRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional bool agree = 6;
 * @return {boolean}
 */
proto.dealer_service.ConfirmVehiclePriceRequest.prototype.getAgree = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dealer_service.ConfirmVehiclePriceRequest} returns this
 */
proto.dealer_service.ConfirmVehiclePriceRequest.prototype.setAgree = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer_service.ConfirmVehiclePriceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer_service.ConfirmVehiclePriceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer_service.ConfirmVehiclePriceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.ConfirmVehiclePriceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    dealerUserId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    vehicleId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    error: jspb.Message.getFieldWithDefault(msg, 4, ""),
    errorCode: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer_service.ConfirmVehiclePriceResponse}
 */
proto.dealer_service.ConfirmVehiclePriceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer_service.ConfirmVehiclePriceResponse;
  return proto.dealer_service.ConfirmVehiclePriceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer_service.ConfirmVehiclePriceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer_service.ConfirmVehiclePriceResponse}
 */
proto.dealer_service.ConfirmVehiclePriceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealerUserId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setErrorCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer_service.ConfirmVehiclePriceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer_service.ConfirmVehiclePriceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer_service.ConfirmVehiclePriceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.ConfirmVehiclePriceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getDealerUserId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getErrorCode();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.dealer_service.ConfirmVehiclePriceResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dealer_service.ConfirmVehiclePriceResponse} returns this
 */
proto.dealer_service.ConfirmVehiclePriceResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string dealer_user_id = 2;
 * @return {string}
 */
proto.dealer_service.ConfirmVehiclePriceResponse.prototype.getDealerUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.ConfirmVehiclePriceResponse} returns this
 */
proto.dealer_service.ConfirmVehiclePriceResponse.prototype.setDealerUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string vehicle_id = 3;
 * @return {string}
 */
proto.dealer_service.ConfirmVehiclePriceResponse.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.ConfirmVehiclePriceResponse} returns this
 */
proto.dealer_service.ConfirmVehiclePriceResponse.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string error = 4;
 * @return {string}
 */
proto.dealer_service.ConfirmVehiclePriceResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.ConfirmVehiclePriceResponse} returns this
 */
proto.dealer_service.ConfirmVehiclePriceResponse.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 error_code = 5;
 * @return {number}
 */
proto.dealer_service.ConfirmVehiclePriceResponse.prototype.getErrorCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.dealer_service.ConfirmVehiclePriceResponse} returns this
 */
proto.dealer_service.ConfirmVehiclePriceResponse.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer_service.ViewPastDealsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer_service.ViewPastDealsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer_service.ViewPastDealsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.ViewPastDealsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealerUserId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dealershipId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer_service.ViewPastDealsRequest}
 */
proto.dealer_service.ViewPastDealsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer_service.ViewPastDealsRequest;
  return proto.dealer_service.ViewPastDealsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer_service.ViewPastDealsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer_service.ViewPastDealsRequest}
 */
proto.dealer_service.ViewPastDealsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealerUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealershipId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer_service.ViewPastDealsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer_service.ViewPastDealsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer_service.ViewPastDealsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.ViewPastDealsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealerUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDealershipId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string dealer_user_id = 1;
 * @return {string}
 */
proto.dealer_service.ViewPastDealsRequest.prototype.getDealerUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.ViewPastDealsRequest} returns this
 */
proto.dealer_service.ViewPastDealsRequest.prototype.setDealerUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string dealership_id = 2;
 * @return {string}
 */
proto.dealer_service.ViewPastDealsRequest.prototype.getDealershipId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.ViewPastDealsRequest} returns this
 */
proto.dealer_service.ViewPastDealsRequest.prototype.setDealershipId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dealer_service.ViewPastDealsResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer_service.ViewPastDealsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer_service.ViewPastDealsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer_service.ViewPastDealsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.ViewPastDealsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    pastDealsList: jspb.Message.toObjectList(msg.getPastDealsList(),
    domain_pb.Appointment.toObject, includeInstance),
    error: jspb.Message.getFieldWithDefault(msg, 3, ""),
    errorCode: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer_service.ViewPastDealsResponse}
 */
proto.dealer_service.ViewPastDealsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer_service.ViewPastDealsResponse;
  return proto.dealer_service.ViewPastDealsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer_service.ViewPastDealsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer_service.ViewPastDealsResponse}
 */
proto.dealer_service.ViewPastDealsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new domain_pb.Appointment;
      reader.readMessage(value,domain_pb.Appointment.deserializeBinaryFromReader);
      msg.addPastDeals(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setErrorCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer_service.ViewPastDealsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer_service.ViewPastDealsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer_service.ViewPastDealsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.ViewPastDealsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getPastDealsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      domain_pb.Appointment.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getErrorCode();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.dealer_service.ViewPastDealsResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dealer_service.ViewPastDealsResponse} returns this
 */
proto.dealer_service.ViewPastDealsResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * repeated domain.Appointment past_deals = 2;
 * @return {!Array<!proto.domain.Appointment>}
 */
proto.dealer_service.ViewPastDealsResponse.prototype.getPastDealsList = function() {
  return /** @type{!Array<!proto.domain.Appointment>} */ (
    jspb.Message.getRepeatedWrapperField(this, domain_pb.Appointment, 2));
};


/**
 * @param {!Array<!proto.domain.Appointment>} value
 * @return {!proto.dealer_service.ViewPastDealsResponse} returns this
*/
proto.dealer_service.ViewPastDealsResponse.prototype.setPastDealsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.domain.Appointment=} opt_value
 * @param {number=} opt_index
 * @return {!proto.domain.Appointment}
 */
proto.dealer_service.ViewPastDealsResponse.prototype.addPastDeals = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.domain.Appointment, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dealer_service.ViewPastDealsResponse} returns this
 */
proto.dealer_service.ViewPastDealsResponse.prototype.clearPastDealsList = function() {
  return this.setPastDealsList([]);
};


/**
 * optional string error = 3;
 * @return {string}
 */
proto.dealer_service.ViewPastDealsResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.ViewPastDealsResponse} returns this
 */
proto.dealer_service.ViewPastDealsResponse.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 error_code = 9;
 * @return {number}
 */
proto.dealer_service.ViewPastDealsResponse.prototype.getErrorCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.dealer_service.ViewPastDealsResponse} returns this
 */
proto.dealer_service.ViewPastDealsResponse.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer_service.GetCustomerInfoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer_service.GetCustomerInfoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer_service.GetCustomerInfoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.GetCustomerInfoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer_service.GetCustomerInfoRequest}
 */
proto.dealer_service.GetCustomerInfoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer_service.GetCustomerInfoRequest;
  return proto.dealer_service.GetCustomerInfoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer_service.GetCustomerInfoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer_service.GetCustomerInfoRequest}
 */
proto.dealer_service.GetCustomerInfoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer_service.GetCustomerInfoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer_service.GetCustomerInfoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer_service.GetCustomerInfoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.GetCustomerInfoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.dealer_service.GetCustomerInfoRequest.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.GetCustomerInfoRequest} returns this
 */
proto.dealer_service.GetCustomerInfoRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer_service.GetCustomerInfoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer_service.GetCustomerInfoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer_service.GetCustomerInfoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.GetCustomerInfoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    customer: (f = msg.getCustomer()) && domain_pb.Customer.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 3, ""),
    errorCode: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer_service.GetCustomerInfoResponse}
 */
proto.dealer_service.GetCustomerInfoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer_service.GetCustomerInfoResponse;
  return proto.dealer_service.GetCustomerInfoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer_service.GetCustomerInfoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer_service.GetCustomerInfoResponse}
 */
proto.dealer_service.GetCustomerInfoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new domain_pb.Customer;
      reader.readMessage(value,domain_pb.Customer.deserializeBinaryFromReader);
      msg.setCustomer(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setErrorCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer_service.GetCustomerInfoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer_service.GetCustomerInfoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer_service.GetCustomerInfoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.GetCustomerInfoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getCustomer();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      domain_pb.Customer.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getErrorCode();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.dealer_service.GetCustomerInfoResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dealer_service.GetCustomerInfoResponse} returns this
 */
proto.dealer_service.GetCustomerInfoResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional domain.Customer customer = 2;
 * @return {?proto.domain.Customer}
 */
proto.dealer_service.GetCustomerInfoResponse.prototype.getCustomer = function() {
  return /** @type{?proto.domain.Customer} */ (
    jspb.Message.getWrapperField(this, domain_pb.Customer, 2));
};


/**
 * @param {?proto.domain.Customer|undefined} value
 * @return {!proto.dealer_service.GetCustomerInfoResponse} returns this
*/
proto.dealer_service.GetCustomerInfoResponse.prototype.setCustomer = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dealer_service.GetCustomerInfoResponse} returns this
 */
proto.dealer_service.GetCustomerInfoResponse.prototype.clearCustomer = function() {
  return this.setCustomer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dealer_service.GetCustomerInfoResponse.prototype.hasCustomer = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string error = 3;
 * @return {string}
 */
proto.dealer_service.GetCustomerInfoResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.GetCustomerInfoResponse} returns this
 */
proto.dealer_service.GetCustomerInfoResponse.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 error_code = 4;
 * @return {number}
 */
proto.dealer_service.GetCustomerInfoResponse.prototype.getErrorCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.dealer_service.GetCustomerInfoResponse} returns this
 */
proto.dealer_service.GetCustomerInfoResponse.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer_service.Offer.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer_service.Offer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer_service.Offer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.Offer.toObject = function(includeInstance, msg) {
  var f, obj = {
    offerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dealershipId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    dateReceived: jspb.Message.getFieldWithDefault(msg, 3, ""),
    carYear: jspb.Message.getFieldWithDefault(msg, 4, ""),
    carMake: jspb.Message.getFieldWithDefault(msg, 5, ""),
    carModel: jspb.Message.getFieldWithDefault(msg, 6, ""),
    carVin: jspb.Message.getFieldWithDefault(msg, 7, ""),
    stockNumber: jspb.Message.getFieldWithDefault(msg, 8, ""),
    vehicleId: jspb.Message.getFieldWithDefault(msg, 9, ""),
    price: jspb.Message.getFieldWithDefault(msg, 10, 0),
    customerId: jspb.Message.getFieldWithDefault(msg, 11, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer_service.Offer}
 */
proto.dealer_service.Offer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer_service.Offer;
  return proto.dealer_service.Offer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer_service.Offer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer_service.Offer}
 */
proto.dealer_service.Offer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOfferId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealershipId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateReceived(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCarYear(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCarMake(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCarModel(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCarVin(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setStockNumber(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPrice(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer_service.Offer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer_service.Offer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer_service.Offer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.Offer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOfferId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDealershipId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDateReceived();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCarYear();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCarMake();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCarModel();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCarVin();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getStockNumber();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
};


/**
 * optional string offer_id = 1;
 * @return {string}
 */
proto.dealer_service.Offer.prototype.getOfferId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.Offer} returns this
 */
proto.dealer_service.Offer.prototype.setOfferId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string dealership_id = 2;
 * @return {string}
 */
proto.dealer_service.Offer.prototype.getDealershipId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.Offer} returns this
 */
proto.dealer_service.Offer.prototype.setDealershipId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string date_received = 3;
 * @return {string}
 */
proto.dealer_service.Offer.prototype.getDateReceived = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.Offer} returns this
 */
proto.dealer_service.Offer.prototype.setDateReceived = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string car_year = 4;
 * @return {string}
 */
proto.dealer_service.Offer.prototype.getCarYear = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.Offer} returns this
 */
proto.dealer_service.Offer.prototype.setCarYear = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string car_make = 5;
 * @return {string}
 */
proto.dealer_service.Offer.prototype.getCarMake = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.Offer} returns this
 */
proto.dealer_service.Offer.prototype.setCarMake = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string car_model = 6;
 * @return {string}
 */
proto.dealer_service.Offer.prototype.getCarModel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.Offer} returns this
 */
proto.dealer_service.Offer.prototype.setCarModel = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string car_vin = 7;
 * @return {string}
 */
proto.dealer_service.Offer.prototype.getCarVin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.Offer} returns this
 */
proto.dealer_service.Offer.prototype.setCarVin = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string stock_number = 8;
 * @return {string}
 */
proto.dealer_service.Offer.prototype.getStockNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.Offer} returns this
 */
proto.dealer_service.Offer.prototype.setStockNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string vehicle_id = 9;
 * @return {string}
 */
proto.dealer_service.Offer.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.Offer} returns this
 */
proto.dealer_service.Offer.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional int32 price = 10;
 * @return {number}
 */
proto.dealer_service.Offer.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.dealer_service.Offer} returns this
 */
proto.dealer_service.Offer.prototype.setPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional string customer_id = 11;
 * @return {string}
 */
proto.dealer_service.Offer.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.Offer} returns this
 */
proto.dealer_service.Offer.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer_service.GetDealershipOffersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer_service.GetDealershipOffersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer_service.GetDealershipOffersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.GetDealershipOffersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealerUserId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dealershipId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer_service.GetDealershipOffersRequest}
 */
proto.dealer_service.GetDealershipOffersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer_service.GetDealershipOffersRequest;
  return proto.dealer_service.GetDealershipOffersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer_service.GetDealershipOffersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer_service.GetDealershipOffersRequest}
 */
proto.dealer_service.GetDealershipOffersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealerUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealershipId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer_service.GetDealershipOffersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer_service.GetDealershipOffersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer_service.GetDealershipOffersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.GetDealershipOffersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealerUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDealershipId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string dealer_user_id = 1;
 * @return {string}
 */
proto.dealer_service.GetDealershipOffersRequest.prototype.getDealerUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.GetDealershipOffersRequest} returns this
 */
proto.dealer_service.GetDealershipOffersRequest.prototype.setDealerUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string dealership_id = 2;
 * @return {string}
 */
proto.dealer_service.GetDealershipOffersRequest.prototype.getDealershipId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.GetDealershipOffersRequest} returns this
 */
proto.dealer_service.GetDealershipOffersRequest.prototype.setDealershipId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dealer_service.GetDealershipOffersResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer_service.GetDealershipOffersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer_service.GetDealershipOffersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer_service.GetDealershipOffersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.GetDealershipOffersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    dealershipId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    offersList: jspb.Message.toObjectList(msg.getOffersList(),
    proto.dealer_service.Offer.toObject, includeInstance),
    error: jspb.Message.getFieldWithDefault(msg, 4, ""),
    errorCode: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer_service.GetDealershipOffersResponse}
 */
proto.dealer_service.GetDealershipOffersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer_service.GetDealershipOffersResponse;
  return proto.dealer_service.GetDealershipOffersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer_service.GetDealershipOffersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer_service.GetDealershipOffersResponse}
 */
proto.dealer_service.GetDealershipOffersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealershipId(value);
      break;
    case 3:
      var value = new proto.dealer_service.Offer;
      reader.readMessage(value,proto.dealer_service.Offer.deserializeBinaryFromReader);
      msg.addOffers(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setErrorCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer_service.GetDealershipOffersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer_service.GetDealershipOffersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer_service.GetDealershipOffersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.GetDealershipOffersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getDealershipId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOffersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.dealer_service.Offer.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getErrorCode();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.dealer_service.GetDealershipOffersResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dealer_service.GetDealershipOffersResponse} returns this
 */
proto.dealer_service.GetDealershipOffersResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string dealership_id = 2;
 * @return {string}
 */
proto.dealer_service.GetDealershipOffersResponse.prototype.getDealershipId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.GetDealershipOffersResponse} returns this
 */
proto.dealer_service.GetDealershipOffersResponse.prototype.setDealershipId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Offer offers = 3;
 * @return {!Array<!proto.dealer_service.Offer>}
 */
proto.dealer_service.GetDealershipOffersResponse.prototype.getOffersList = function() {
  return /** @type{!Array<!proto.dealer_service.Offer>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.dealer_service.Offer, 3));
};


/**
 * @param {!Array<!proto.dealer_service.Offer>} value
 * @return {!proto.dealer_service.GetDealershipOffersResponse} returns this
*/
proto.dealer_service.GetDealershipOffersResponse.prototype.setOffersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.dealer_service.Offer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dealer_service.Offer}
 */
proto.dealer_service.GetDealershipOffersResponse.prototype.addOffers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.dealer_service.Offer, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dealer_service.GetDealershipOffersResponse} returns this
 */
proto.dealer_service.GetDealershipOffersResponse.prototype.clearOffersList = function() {
  return this.setOffersList([]);
};


/**
 * optional string error = 4;
 * @return {string}
 */
proto.dealer_service.GetDealershipOffersResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.GetDealershipOffersResponse} returns this
 */
proto.dealer_service.GetDealershipOffersResponse.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 error_code = 5;
 * @return {number}
 */
proto.dealer_service.GetDealershipOffersResponse.prototype.getErrorCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.dealer_service.GetDealershipOffersResponse} returns this
 */
proto.dealer_service.GetDealershipOffersResponse.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer_service.GetCheckoutStatusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer_service.GetCheckoutStatusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer_service.GetCheckoutStatusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.GetCheckoutStatusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer_service.GetCheckoutStatusRequest}
 */
proto.dealer_service.GetCheckoutStatusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer_service.GetCheckoutStatusRequest;
  return proto.dealer_service.GetCheckoutStatusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer_service.GetCheckoutStatusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer_service.GetCheckoutStatusRequest}
 */
proto.dealer_service.GetCheckoutStatusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer_service.GetCheckoutStatusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer_service.GetCheckoutStatusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer_service.GetCheckoutStatusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.GetCheckoutStatusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string subscription_id = 1;
 * @return {string}
 */
proto.dealer_service.GetCheckoutStatusRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.GetCheckoutStatusRequest} returns this
 */
proto.dealer_service.GetCheckoutStatusRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer_service.GetCheckoutStatusResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer_service.GetCheckoutStatusResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer_service.GetCheckoutStatusResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.GetCheckoutStatusResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: jspb.Message.getFieldWithDefault(msg, 2, ""),
    subscriptionid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    inspectionComplete: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    contractSignedSubmitted: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    subscriptionActivated: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    insuranceLinked: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    licenseScanned: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 9, 0),
    paymentComplete: jspb.Message.getBooleanFieldWithDefault(msg, 10, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer_service.GetCheckoutStatusResponse}
 */
proto.dealer_service.GetCheckoutStatusResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer_service.GetCheckoutStatusResponse;
  return proto.dealer_service.GetCheckoutStatusResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer_service.GetCheckoutStatusResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer_service.GetCheckoutStatusResponse}
 */
proto.dealer_service.GetCheckoutStatusResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionid(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInspectionComplete(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setContractSignedSubmitted(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSubscriptionActivated(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInsuranceLinked(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLicenseScanned(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setErrorCode(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPaymentComplete(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer_service.GetCheckoutStatusResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer_service.GetCheckoutStatusResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer_service.GetCheckoutStatusResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.GetCheckoutStatusResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSubscriptionid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getInspectionComplete();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getContractSignedSubmitted();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getSubscriptionActivated();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getInsuranceLinked();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getLicenseScanned();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getErrorCode();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getPaymentComplete();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.dealer_service.GetCheckoutStatusResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dealer_service.GetCheckoutStatusResponse} returns this
 */
proto.dealer_service.GetCheckoutStatusResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.dealer_service.GetCheckoutStatusResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.GetCheckoutStatusResponse} returns this
 */
proto.dealer_service.GetCheckoutStatusResponse.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string subscriptionId = 3;
 * @return {string}
 */
proto.dealer_service.GetCheckoutStatusResponse.prototype.getSubscriptionid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.GetCheckoutStatusResponse} returns this
 */
proto.dealer_service.GetCheckoutStatusResponse.prototype.setSubscriptionid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool inspection_complete = 4;
 * @return {boolean}
 */
proto.dealer_service.GetCheckoutStatusResponse.prototype.getInspectionComplete = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dealer_service.GetCheckoutStatusResponse} returns this
 */
proto.dealer_service.GetCheckoutStatusResponse.prototype.setInspectionComplete = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool contract_signed_submitted = 5;
 * @return {boolean}
 */
proto.dealer_service.GetCheckoutStatusResponse.prototype.getContractSignedSubmitted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dealer_service.GetCheckoutStatusResponse} returns this
 */
proto.dealer_service.GetCheckoutStatusResponse.prototype.setContractSignedSubmitted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool subscription_activated = 6;
 * @return {boolean}
 */
proto.dealer_service.GetCheckoutStatusResponse.prototype.getSubscriptionActivated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dealer_service.GetCheckoutStatusResponse} returns this
 */
proto.dealer_service.GetCheckoutStatusResponse.prototype.setSubscriptionActivated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool insurance_linked = 7;
 * @return {boolean}
 */
proto.dealer_service.GetCheckoutStatusResponse.prototype.getInsuranceLinked = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dealer_service.GetCheckoutStatusResponse} returns this
 */
proto.dealer_service.GetCheckoutStatusResponse.prototype.setInsuranceLinked = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool license_scanned = 8;
 * @return {boolean}
 */
proto.dealer_service.GetCheckoutStatusResponse.prototype.getLicenseScanned = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dealer_service.GetCheckoutStatusResponse} returns this
 */
proto.dealer_service.GetCheckoutStatusResponse.prototype.setLicenseScanned = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional int32 error_code = 9;
 * @return {number}
 */
proto.dealer_service.GetCheckoutStatusResponse.prototype.getErrorCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.dealer_service.GetCheckoutStatusResponse} returns this
 */
proto.dealer_service.GetCheckoutStatusResponse.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional bool payment_complete = 10;
 * @return {boolean}
 */
proto.dealer_service.GetCheckoutStatusResponse.prototype.getPaymentComplete = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dealer_service.GetCheckoutStatusResponse} returns this
 */
proto.dealer_service.GetCheckoutStatusResponse.prototype.setPaymentComplete = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer_service.AcceptDealerUserTosRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer_service.AcceptDealerUserTosRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer_service.AcceptDealerUserTosRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.AcceptDealerUserTosRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealerUserId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer_service.AcceptDealerUserTosRequest}
 */
proto.dealer_service.AcceptDealerUserTosRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer_service.AcceptDealerUserTosRequest;
  return proto.dealer_service.AcceptDealerUserTosRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer_service.AcceptDealerUserTosRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer_service.AcceptDealerUserTosRequest}
 */
proto.dealer_service.AcceptDealerUserTosRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealerUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer_service.AcceptDealerUserTosRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer_service.AcceptDealerUserTosRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer_service.AcceptDealerUserTosRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.AcceptDealerUserTosRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealerUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string dealer_user_id = 1;
 * @return {string}
 */
proto.dealer_service.AcceptDealerUserTosRequest.prototype.getDealerUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.AcceptDealerUserTosRequest} returns this
 */
proto.dealer_service.AcceptDealerUserTosRequest.prototype.setDealerUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer_service.AcceptDealerUserTosResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer_service.AcceptDealerUserTosResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer_service.AcceptDealerUserTosResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.AcceptDealerUserTosResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errorCode: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer_service.AcceptDealerUserTosResponse}
 */
proto.dealer_service.AcceptDealerUserTosResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer_service.AcceptDealerUserTosResponse;
  return proto.dealer_service.AcceptDealerUserTosResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer_service.AcceptDealerUserTosResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer_service.AcceptDealerUserTosResponse}
 */
proto.dealer_service.AcceptDealerUserTosResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setErrorCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer_service.AcceptDealerUserTosResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer_service.AcceptDealerUserTosResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer_service.AcceptDealerUserTosResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.AcceptDealerUserTosResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrorCode();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.dealer_service.AcceptDealerUserTosResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dealer_service.AcceptDealerUserTosResponse} returns this
 */
proto.dealer_service.AcceptDealerUserTosResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.dealer_service.AcceptDealerUserTosResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.AcceptDealerUserTosResponse} returns this
 */
proto.dealer_service.AcceptDealerUserTosResponse.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 error_code = 3;
 * @return {number}
 */
proto.dealer_service.AcceptDealerUserTosResponse.prototype.getErrorCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.dealer_service.AcceptDealerUserTosResponse} returns this
 */
proto.dealer_service.AcceptDealerUserTosResponse.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer_service.AcceptVDPTosRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer_service.AcceptVDPTosRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer_service.AcceptVDPTosRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.AcceptVDPTosRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealerUserId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dealershipId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    vehicleId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer_service.AcceptVDPTosRequest}
 */
proto.dealer_service.AcceptVDPTosRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer_service.AcceptVDPTosRequest;
  return proto.dealer_service.AcceptVDPTosRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer_service.AcceptVDPTosRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer_service.AcceptVDPTosRequest}
 */
proto.dealer_service.AcceptVDPTosRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealerUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealershipId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer_service.AcceptVDPTosRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer_service.AcceptVDPTosRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer_service.AcceptVDPTosRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.AcceptVDPTosRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealerUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDealershipId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string dealer_user_id = 1;
 * @return {string}
 */
proto.dealer_service.AcceptVDPTosRequest.prototype.getDealerUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.AcceptVDPTosRequest} returns this
 */
proto.dealer_service.AcceptVDPTosRequest.prototype.setDealerUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string dealership_id = 2;
 * @return {string}
 */
proto.dealer_service.AcceptVDPTosRequest.prototype.getDealershipId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.AcceptVDPTosRequest} returns this
 */
proto.dealer_service.AcceptVDPTosRequest.prototype.setDealershipId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string vehicle_id = 3;
 * @return {string}
 */
proto.dealer_service.AcceptVDPTosRequest.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.AcceptVDPTosRequest} returns this
 */
proto.dealer_service.AcceptVDPTosRequest.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer_service.AcceptVDPTosResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer_service.AcceptVDPTosResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer_service.AcceptVDPTosResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.AcceptVDPTosResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errorCode: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer_service.AcceptVDPTosResponse}
 */
proto.dealer_service.AcceptVDPTosResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer_service.AcceptVDPTosResponse;
  return proto.dealer_service.AcceptVDPTosResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer_service.AcceptVDPTosResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer_service.AcceptVDPTosResponse}
 */
proto.dealer_service.AcceptVDPTosResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setErrorCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer_service.AcceptVDPTosResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer_service.AcceptVDPTosResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer_service.AcceptVDPTosResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.AcceptVDPTosResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrorCode();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.dealer_service.AcceptVDPTosResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dealer_service.AcceptVDPTosResponse} returns this
 */
proto.dealer_service.AcceptVDPTosResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.dealer_service.AcceptVDPTosResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.AcceptVDPTosResponse} returns this
 */
proto.dealer_service.AcceptVDPTosResponse.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 error_code = 3;
 * @return {number}
 */
proto.dealer_service.AcceptVDPTosResponse.prototype.getErrorCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.dealer_service.AcceptVDPTosResponse} returns this
 */
proto.dealer_service.AcceptVDPTosResponse.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer_service.DeleteUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer_service.DeleteUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer_service.DeleteUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.DeleteUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    cellPhone: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer_service.DeleteUserRequest}
 */
proto.dealer_service.DeleteUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer_service.DeleteUserRequest;
  return proto.dealer_service.DeleteUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer_service.DeleteUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer_service.DeleteUserRequest}
 */
proto.dealer_service.DeleteUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCellPhone(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer_service.DeleteUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer_service.DeleteUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer_service.DeleteUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.DeleteUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCellPhone();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string cell_phone = 1;
 * @return {string}
 */
proto.dealer_service.DeleteUserRequest.prototype.getCellPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.DeleteUserRequest} returns this
 */
proto.dealer_service.DeleteUserRequest.prototype.setCellPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer_service.DeleteUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer_service.DeleteUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer_service.DeleteUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.DeleteUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealerUserId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    success: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    error: jspb.Message.getFieldWithDefault(msg, 3, ""),
    errorCode: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer_service.DeleteUserResponse}
 */
proto.dealer_service.DeleteUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer_service.DeleteUserResponse;
  return proto.dealer_service.DeleteUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer_service.DeleteUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer_service.DeleteUserResponse}
 */
proto.dealer_service.DeleteUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealerUserId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setErrorCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer_service.DeleteUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer_service.DeleteUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer_service.DeleteUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.DeleteUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealerUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getErrorCode();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional string dealer_user_id = 1;
 * @return {string}
 */
proto.dealer_service.DeleteUserResponse.prototype.getDealerUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.DeleteUserResponse} returns this
 */
proto.dealer_service.DeleteUserResponse.prototype.setDealerUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool success = 2;
 * @return {boolean}
 */
proto.dealer_service.DeleteUserResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dealer_service.DeleteUserResponse} returns this
 */
proto.dealer_service.DeleteUserResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional string error = 3;
 * @return {string}
 */
proto.dealer_service.DeleteUserResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.DeleteUserResponse} returns this
 */
proto.dealer_service.DeleteUserResponse.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 error_code = 4;
 * @return {number}
 */
proto.dealer_service.DeleteUserResponse.prototype.getErrorCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.dealer_service.DeleteUserResponse} returns this
 */
proto.dealer_service.DeleteUserResponse.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer_service.Feature.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer_service.Feature.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer_service.Feature} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.Feature.toObject = function(includeInstance, msg) {
  var f, obj = {
    featureName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    featureActive: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer_service.Feature}
 */
proto.dealer_service.Feature.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer_service.Feature;
  return proto.dealer_service.Feature.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer_service.Feature} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer_service.Feature}
 */
proto.dealer_service.Feature.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFeatureName(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFeatureActive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer_service.Feature.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer_service.Feature.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer_service.Feature} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.Feature.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFeatureName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFeatureActive();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string feature_name = 1;
 * @return {string}
 */
proto.dealer_service.Feature.prototype.getFeatureName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.Feature} returns this
 */
proto.dealer_service.Feature.prototype.setFeatureName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool feature_active = 2;
 * @return {boolean}
 */
proto.dealer_service.Feature.prototype.getFeatureActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dealer_service.Feature} returns this
 */
proto.dealer_service.Feature.prototype.setFeatureActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer_service.GlobalFeaturesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer_service.GlobalFeaturesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer_service.GlobalFeaturesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.GlobalFeaturesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer_service.GlobalFeaturesRequest}
 */
proto.dealer_service.GlobalFeaturesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer_service.GlobalFeaturesRequest;
  return proto.dealer_service.GlobalFeaturesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer_service.GlobalFeaturesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer_service.GlobalFeaturesRequest}
 */
proto.dealer_service.GlobalFeaturesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer_service.GlobalFeaturesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer_service.GlobalFeaturesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer_service.GlobalFeaturesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.GlobalFeaturesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dealer_service.GlobalFeaturesResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer_service.GlobalFeaturesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer_service.GlobalFeaturesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer_service.GlobalFeaturesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.GlobalFeaturesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    featuresList: jspb.Message.toObjectList(msg.getFeaturesList(),
    proto.dealer_service.Feature.toObject, includeInstance),
    error: jspb.Message.getFieldWithDefault(msg, 3, ""),
    errorCode: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer_service.GlobalFeaturesResponse}
 */
proto.dealer_service.GlobalFeaturesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer_service.GlobalFeaturesResponse;
  return proto.dealer_service.GlobalFeaturesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer_service.GlobalFeaturesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer_service.GlobalFeaturesResponse}
 */
proto.dealer_service.GlobalFeaturesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new proto.dealer_service.Feature;
      reader.readMessage(value,proto.dealer_service.Feature.deserializeBinaryFromReader);
      msg.addFeatures(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setErrorCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer_service.GlobalFeaturesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer_service.GlobalFeaturesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer_service.GlobalFeaturesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.GlobalFeaturesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getFeaturesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.dealer_service.Feature.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getErrorCode();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.dealer_service.GlobalFeaturesResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dealer_service.GlobalFeaturesResponse} returns this
 */
proto.dealer_service.GlobalFeaturesResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * repeated Feature features = 2;
 * @return {!Array<!proto.dealer_service.Feature>}
 */
proto.dealer_service.GlobalFeaturesResponse.prototype.getFeaturesList = function() {
  return /** @type{!Array<!proto.dealer_service.Feature>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.dealer_service.Feature, 2));
};


/**
 * @param {!Array<!proto.dealer_service.Feature>} value
 * @return {!proto.dealer_service.GlobalFeaturesResponse} returns this
*/
proto.dealer_service.GlobalFeaturesResponse.prototype.setFeaturesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.dealer_service.Feature=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dealer_service.Feature}
 */
proto.dealer_service.GlobalFeaturesResponse.prototype.addFeatures = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.dealer_service.Feature, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dealer_service.GlobalFeaturesResponse} returns this
 */
proto.dealer_service.GlobalFeaturesResponse.prototype.clearFeaturesList = function() {
  return this.setFeaturesList([]);
};


/**
 * optional string error = 3;
 * @return {string}
 */
proto.dealer_service.GlobalFeaturesResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.GlobalFeaturesResponse} returns this
 */
proto.dealer_service.GlobalFeaturesResponse.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 error_code = 4;
 * @return {number}
 */
proto.dealer_service.GlobalFeaturesResponse.prototype.getErrorCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.dealer_service.GlobalFeaturesResponse} returns this
 */
proto.dealer_service.GlobalFeaturesResponse.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer_service.ConfirmVehiclePickupRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer_service.ConfirmVehiclePickupRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer_service.ConfirmVehiclePickupRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.ConfirmVehiclePickupRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealerUserId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    vehicleId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    dealershipId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    offerId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    customerId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    pickupConfirmed: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer_service.ConfirmVehiclePickupRequest}
 */
proto.dealer_service.ConfirmVehiclePickupRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer_service.ConfirmVehiclePickupRequest;
  return proto.dealer_service.ConfirmVehiclePickupRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer_service.ConfirmVehiclePickupRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer_service.ConfirmVehiclePickupRequest}
 */
proto.dealer_service.ConfirmVehiclePickupRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealerUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealershipId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOfferId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPickupConfirmed(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer_service.ConfirmVehiclePickupRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer_service.ConfirmVehiclePickupRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer_service.ConfirmVehiclePickupRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.ConfirmVehiclePickupRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealerUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDealershipId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOfferId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPickupConfirmed();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional string dealer_user_id = 1;
 * @return {string}
 */
proto.dealer_service.ConfirmVehiclePickupRequest.prototype.getDealerUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.ConfirmVehiclePickupRequest} returns this
 */
proto.dealer_service.ConfirmVehiclePickupRequest.prototype.setDealerUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string vehicle_id = 2;
 * @return {string}
 */
proto.dealer_service.ConfirmVehiclePickupRequest.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.ConfirmVehiclePickupRequest} returns this
 */
proto.dealer_service.ConfirmVehiclePickupRequest.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string dealership_id = 3;
 * @return {string}
 */
proto.dealer_service.ConfirmVehiclePickupRequest.prototype.getDealershipId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.ConfirmVehiclePickupRequest} returns this
 */
proto.dealer_service.ConfirmVehiclePickupRequest.prototype.setDealershipId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string offer_id = 4;
 * @return {string}
 */
proto.dealer_service.ConfirmVehiclePickupRequest.prototype.getOfferId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.ConfirmVehiclePickupRequest} returns this
 */
proto.dealer_service.ConfirmVehiclePickupRequest.prototype.setOfferId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string customer_id = 5;
 * @return {string}
 */
proto.dealer_service.ConfirmVehiclePickupRequest.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.ConfirmVehiclePickupRequest} returns this
 */
proto.dealer_service.ConfirmVehiclePickupRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional bool pickup_confirmed = 6;
 * @return {boolean}
 */
proto.dealer_service.ConfirmVehiclePickupRequest.prototype.getPickupConfirmed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dealer_service.ConfirmVehiclePickupRequest} returns this
 */
proto.dealer_service.ConfirmVehiclePickupRequest.prototype.setPickupConfirmed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer_service.ConfirmVehiclePickupResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer_service.ConfirmVehiclePickupResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer_service.ConfirmVehiclePickupResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.ConfirmVehiclePickupResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    dealerUserId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    vehicleId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    dealershipId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    offerId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    customerId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    pickupConfirmed: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    error: jspb.Message.getFieldWithDefault(msg, 8, ""),
    errorCode: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer_service.ConfirmVehiclePickupResponse}
 */
proto.dealer_service.ConfirmVehiclePickupResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer_service.ConfirmVehiclePickupResponse;
  return proto.dealer_service.ConfirmVehiclePickupResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer_service.ConfirmVehiclePickupResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer_service.ConfirmVehiclePickupResponse}
 */
proto.dealer_service.ConfirmVehiclePickupResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealerUserId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealershipId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setOfferId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPickupConfirmed(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setErrorCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer_service.ConfirmVehiclePickupResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer_service.ConfirmVehiclePickupResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer_service.ConfirmVehiclePickupResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.ConfirmVehiclePickupResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getDealerUserId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDealershipId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getOfferId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPickupConfirmed();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getErrorCode();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.dealer_service.ConfirmVehiclePickupResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dealer_service.ConfirmVehiclePickupResponse} returns this
 */
proto.dealer_service.ConfirmVehiclePickupResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string dealer_user_id = 2;
 * @return {string}
 */
proto.dealer_service.ConfirmVehiclePickupResponse.prototype.getDealerUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.ConfirmVehiclePickupResponse} returns this
 */
proto.dealer_service.ConfirmVehiclePickupResponse.prototype.setDealerUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string vehicle_id = 3;
 * @return {string}
 */
proto.dealer_service.ConfirmVehiclePickupResponse.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.ConfirmVehiclePickupResponse} returns this
 */
proto.dealer_service.ConfirmVehiclePickupResponse.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string dealership_id = 4;
 * @return {string}
 */
proto.dealer_service.ConfirmVehiclePickupResponse.prototype.getDealershipId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.ConfirmVehiclePickupResponse} returns this
 */
proto.dealer_service.ConfirmVehiclePickupResponse.prototype.setDealershipId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string offer_id = 5;
 * @return {string}
 */
proto.dealer_service.ConfirmVehiclePickupResponse.prototype.getOfferId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.ConfirmVehiclePickupResponse} returns this
 */
proto.dealer_service.ConfirmVehiclePickupResponse.prototype.setOfferId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string customer_id = 6;
 * @return {string}
 */
proto.dealer_service.ConfirmVehiclePickupResponse.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.ConfirmVehiclePickupResponse} returns this
 */
proto.dealer_service.ConfirmVehiclePickupResponse.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional bool pickup_confirmed = 7;
 * @return {boolean}
 */
proto.dealer_service.ConfirmVehiclePickupResponse.prototype.getPickupConfirmed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dealer_service.ConfirmVehiclePickupResponse} returns this
 */
proto.dealer_service.ConfirmVehiclePickupResponse.prototype.setPickupConfirmed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional string error = 8;
 * @return {string}
 */
proto.dealer_service.ConfirmVehiclePickupResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.ConfirmVehiclePickupResponse} returns this
 */
proto.dealer_service.ConfirmVehiclePickupResponse.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional int32 error_code = 9;
 * @return {number}
 */
proto.dealer_service.ConfirmVehiclePickupResponse.prototype.getErrorCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.dealer_service.ConfirmVehiclePickupResponse} returns this
 */
proto.dealer_service.ConfirmVehiclePickupResponse.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer_service.Event.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer_service.Event.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer_service.Event} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.Event.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    starttime: jspb.Message.getFieldWithDefault(msg, 4, ""),
    duration: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer_service.Event}
 */
proto.dealer_service.Event.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer_service.Event;
  return proto.dealer_service.Event.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer_service.Event} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer_service.Event}
 */
proto.dealer_service.Event.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStarttime(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDuration(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer_service.Event.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer_service.Event.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer_service.Event} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.Event.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStarttime();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDuration();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.dealer_service.Event.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.Event} returns this
 */
proto.dealer_service.Event.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.dealer_service.Event.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.Event} returns this
 */
proto.dealer_service.Event.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.dealer_service.Event.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.Event} returns this
 */
proto.dealer_service.Event.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string startTime = 4;
 * @return {string}
 */
proto.dealer_service.Event.prototype.getStarttime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.Event} returns this
 */
proto.dealer_service.Event.prototype.setStarttime = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 duration = 5;
 * @return {number}
 */
proto.dealer_service.Event.prototype.getDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.dealer_service.Event} returns this
 */
proto.dealer_service.Event.prototype.setDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer_service.Date.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer_service.Date.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer_service.Date} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.Date.toObject = function(includeInstance, msg) {
  var f, obj = {
    description: jspb.Message.getFieldWithDefault(msg, 1, ""),
    datetype: jspb.Message.getFieldWithDefault(msg, 2, ""),
    datestring: jspb.Message.getFieldWithDefault(msg, 3, ""),
    dow: jspb.Message.getFieldWithDefault(msg, 4, 0),
    month: jspb.Message.getFieldWithDefault(msg, 5, 0),
    day: jspb.Message.getFieldWithDefault(msg, 6, 0),
    year: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer_service.Date}
 */
proto.dealer_service.Date.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer_service.Date;
  return proto.dealer_service.Date.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer_service.Date} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer_service.Date}
 */
proto.dealer_service.Date.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDatetype(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDatestring(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDow(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMonth(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDay(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setYear(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer_service.Date.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer_service.Date.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer_service.Date} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.Date.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDatetype();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDatestring();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDow();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getMonth();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getDay();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getYear();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
};


/**
 * optional string description = 1;
 * @return {string}
 */
proto.dealer_service.Date.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.Date} returns this
 */
proto.dealer_service.Date.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string dateType = 2;
 * @return {string}
 */
proto.dealer_service.Date.prototype.getDatetype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.Date} returns this
 */
proto.dealer_service.Date.prototype.setDatetype = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string dateString = 3;
 * @return {string}
 */
proto.dealer_service.Date.prototype.getDatestring = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.Date} returns this
 */
proto.dealer_service.Date.prototype.setDatestring = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 dow = 4;
 * @return {number}
 */
proto.dealer_service.Date.prototype.getDow = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.dealer_service.Date} returns this
 */
proto.dealer_service.Date.prototype.setDow = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 month = 5;
 * @return {number}
 */
proto.dealer_service.Date.prototype.getMonth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.dealer_service.Date} returns this
 */
proto.dealer_service.Date.prototype.setMonth = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 day = 6;
 * @return {number}
 */
proto.dealer_service.Date.prototype.getDay = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.dealer_service.Date} returns this
 */
proto.dealer_service.Date.prototype.setDay = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 year = 7;
 * @return {number}
 */
proto.dealer_service.Date.prototype.getYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.dealer_service.Date} returns this
 */
proto.dealer_service.Date.prototype.setYear = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dealer_service.Calendar.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer_service.Calendar.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer_service.Calendar.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer_service.Calendar} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.Calendar.toObject = function(includeInstance, msg) {
  var f, obj = {
    year: jspb.Message.getFieldWithDefault(msg, 1, 0),
    month: jspb.Message.getFieldWithDefault(msg, 2, 0),
    datesList: jspb.Message.toObjectList(msg.getDatesList(),
    proto.dealer_service.Date.toObject, includeInstance),
    timezone: jspb.Message.getFieldWithDefault(msg, 4, ""),
    dateformat: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer_service.Calendar}
 */
proto.dealer_service.Calendar.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer_service.Calendar;
  return proto.dealer_service.Calendar.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer_service.Calendar} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer_service.Calendar}
 */
proto.dealer_service.Calendar.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setYear(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMonth(value);
      break;
    case 3:
      var value = new proto.dealer_service.Date;
      reader.readMessage(value,proto.dealer_service.Date.deserializeBinaryFromReader);
      msg.addDates(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimezone(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateformat(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer_service.Calendar.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer_service.Calendar.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer_service.Calendar} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.Calendar.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getYear();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMonth();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getDatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.dealer_service.Date.serializeBinaryToWriter
    );
  }
  f = message.getTimezone();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDateformat();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional int32 year = 1;
 * @return {number}
 */
proto.dealer_service.Calendar.prototype.getYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.dealer_service.Calendar} returns this
 */
proto.dealer_service.Calendar.prototype.setYear = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 month = 2;
 * @return {number}
 */
proto.dealer_service.Calendar.prototype.getMonth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.dealer_service.Calendar} returns this
 */
proto.dealer_service.Calendar.prototype.setMonth = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated Date dates = 3;
 * @return {!Array<!proto.dealer_service.Date>}
 */
proto.dealer_service.Calendar.prototype.getDatesList = function() {
  return /** @type{!Array<!proto.dealer_service.Date>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.dealer_service.Date, 3));
};


/**
 * @param {!Array<!proto.dealer_service.Date>} value
 * @return {!proto.dealer_service.Calendar} returns this
*/
proto.dealer_service.Calendar.prototype.setDatesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.dealer_service.Date=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dealer_service.Date}
 */
proto.dealer_service.Calendar.prototype.addDates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.dealer_service.Date, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dealer_service.Calendar} returns this
 */
proto.dealer_service.Calendar.prototype.clearDatesList = function() {
  return this.setDatesList([]);
};


/**
 * optional string timeZone = 4;
 * @return {string}
 */
proto.dealer_service.Calendar.prototype.getTimezone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.Calendar} returns this
 */
proto.dealer_service.Calendar.prototype.setTimezone = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string dateFormat = 5;
 * @return {string}
 */
proto.dealer_service.Calendar.prototype.getDateformat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.Calendar} returns this
 */
proto.dealer_service.Calendar.prototype.setDateformat = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer_service.GetHolidaysRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer_service.GetHolidaysRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer_service.GetHolidaysRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.GetHolidaysRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    year: jspb.Message.getFieldWithDefault(msg, 1, 0),
    month: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer_service.GetHolidaysRequest}
 */
proto.dealer_service.GetHolidaysRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer_service.GetHolidaysRequest;
  return proto.dealer_service.GetHolidaysRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer_service.GetHolidaysRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer_service.GetHolidaysRequest}
 */
proto.dealer_service.GetHolidaysRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setYear(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMonth(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer_service.GetHolidaysRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer_service.GetHolidaysRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer_service.GetHolidaysRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.GetHolidaysRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getYear();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMonth();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 year = 1;
 * @return {number}
 */
proto.dealer_service.GetHolidaysRequest.prototype.getYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.dealer_service.GetHolidaysRequest} returns this
 */
proto.dealer_service.GetHolidaysRequest.prototype.setYear = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 month = 2;
 * @return {number}
 */
proto.dealer_service.GetHolidaysRequest.prototype.getMonth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.dealer_service.GetHolidaysRequest} returns this
 */
proto.dealer_service.GetHolidaysRequest.prototype.setMonth = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer_service.GetHolidaysResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer_service.GetHolidaysResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer_service.GetHolidaysResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.GetHolidaysResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: jspb.Message.getFieldWithDefault(msg, 2, ""),
    calendar: (f = msg.getCalendar()) && proto.dealer_service.Calendar.toObject(includeInstance, f),
    errorCode: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer_service.GetHolidaysResponse}
 */
proto.dealer_service.GetHolidaysResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer_service.GetHolidaysResponse;
  return proto.dealer_service.GetHolidaysResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer_service.GetHolidaysResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer_service.GetHolidaysResponse}
 */
proto.dealer_service.GetHolidaysResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 3:
      var value = new proto.dealer_service.Calendar;
      reader.readMessage(value,proto.dealer_service.Calendar.deserializeBinaryFromReader);
      msg.setCalendar(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setErrorCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer_service.GetHolidaysResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer_service.GetHolidaysResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer_service.GetHolidaysResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.GetHolidaysResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCalendar();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.dealer_service.Calendar.serializeBinaryToWriter
    );
  }
  f = message.getErrorCode();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.dealer_service.GetHolidaysResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dealer_service.GetHolidaysResponse} returns this
 */
proto.dealer_service.GetHolidaysResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.dealer_service.GetHolidaysResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.GetHolidaysResponse} returns this
 */
proto.dealer_service.GetHolidaysResponse.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Calendar calendar = 3;
 * @return {?proto.dealer_service.Calendar}
 */
proto.dealer_service.GetHolidaysResponse.prototype.getCalendar = function() {
  return /** @type{?proto.dealer_service.Calendar} */ (
    jspb.Message.getWrapperField(this, proto.dealer_service.Calendar, 3));
};


/**
 * @param {?proto.dealer_service.Calendar|undefined} value
 * @return {!proto.dealer_service.GetHolidaysResponse} returns this
*/
proto.dealer_service.GetHolidaysResponse.prototype.setCalendar = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dealer_service.GetHolidaysResponse} returns this
 */
proto.dealer_service.GetHolidaysResponse.prototype.clearCalendar = function() {
  return this.setCalendar(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dealer_service.GetHolidaysResponse.prototype.hasCalendar = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int32 error_code = 4;
 * @return {number}
 */
proto.dealer_service.GetHolidaysResponse.prototype.getErrorCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.dealer_service.GetHolidaysResponse} returns this
 */
proto.dealer_service.GetHolidaysResponse.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer_service.OpenDayOfWeek.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer_service.OpenDayOfWeek.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer_service.OpenDayOfWeek} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.OpenDayOfWeek.toObject = function(includeInstance, msg) {
  var f, obj = {
    dayOfWeek: jspb.Message.getFieldWithDefault(msg, 1, 0),
    startTime: jspb.Message.getFieldWithDefault(msg, 2, ""),
    endTime: jspb.Message.getFieldWithDefault(msg, 3, ""),
    timezone: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer_service.OpenDayOfWeek}
 */
proto.dealer_service.OpenDayOfWeek.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer_service.OpenDayOfWeek;
  return proto.dealer_service.OpenDayOfWeek.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer_service.OpenDayOfWeek} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer_service.OpenDayOfWeek}
 */
proto.dealer_service.OpenDayOfWeek.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.dealer_service.OpenDayOfWeek.DayOfWeek} */ (reader.readEnum());
      msg.setDayOfWeek(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartTime(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndTime(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimezone(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer_service.OpenDayOfWeek.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer_service.OpenDayOfWeek.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer_service.OpenDayOfWeek} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.OpenDayOfWeek.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDayOfWeek();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getStartTime();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEndTime();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTimezone();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.dealer_service.OpenDayOfWeek.DayOfWeek = {
  DAY_OF_WEEK_UNSPECIFIED: 0,
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
  SUNDAY: 7
};

/**
 * optional DayOfWeek day_of_week = 1;
 * @return {!proto.dealer_service.OpenDayOfWeek.DayOfWeek}
 */
proto.dealer_service.OpenDayOfWeek.prototype.getDayOfWeek = function() {
  return /** @type {!proto.dealer_service.OpenDayOfWeek.DayOfWeek} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.dealer_service.OpenDayOfWeek.DayOfWeek} value
 * @return {!proto.dealer_service.OpenDayOfWeek} returns this
 */
proto.dealer_service.OpenDayOfWeek.prototype.setDayOfWeek = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string start_time = 2;
 * @return {string}
 */
proto.dealer_service.OpenDayOfWeek.prototype.getStartTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.OpenDayOfWeek} returns this
 */
proto.dealer_service.OpenDayOfWeek.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string end_time = 3;
 * @return {string}
 */
proto.dealer_service.OpenDayOfWeek.prototype.getEndTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.OpenDayOfWeek} returns this
 */
proto.dealer_service.OpenDayOfWeek.prototype.setEndTime = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string timezone = 4;
 * @return {string}
 */
proto.dealer_service.OpenDayOfWeek.prototype.getTimezone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.OpenDayOfWeek} returns this
 */
proto.dealer_service.OpenDayOfWeek.prototype.setTimezone = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dealer_service.UpdateAvailabilityRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer_service.UpdateAvailabilityRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer_service.UpdateAvailabilityRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer_service.UpdateAvailabilityRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.UpdateAvailabilityRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealershipId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    openDaysOfWeekList: jspb.Message.toObjectList(msg.getOpenDaysOfWeekList(),
    proto.dealer_service.OpenDayOfWeek.toObject, includeInstance),
    userId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer_service.UpdateAvailabilityRequest}
 */
proto.dealer_service.UpdateAvailabilityRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer_service.UpdateAvailabilityRequest;
  return proto.dealer_service.UpdateAvailabilityRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer_service.UpdateAvailabilityRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer_service.UpdateAvailabilityRequest}
 */
proto.dealer_service.UpdateAvailabilityRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealershipId(value);
      break;
    case 2:
      var value = new proto.dealer_service.OpenDayOfWeek;
      reader.readMessage(value,proto.dealer_service.OpenDayOfWeek.deserializeBinaryFromReader);
      msg.addOpenDaysOfWeek(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer_service.UpdateAvailabilityRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer_service.UpdateAvailabilityRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer_service.UpdateAvailabilityRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.UpdateAvailabilityRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealershipId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOpenDaysOfWeekList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.dealer_service.OpenDayOfWeek.serializeBinaryToWriter
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string dealership_id = 1;
 * @return {string}
 */
proto.dealer_service.UpdateAvailabilityRequest.prototype.getDealershipId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.UpdateAvailabilityRequest} returns this
 */
proto.dealer_service.UpdateAvailabilityRequest.prototype.setDealershipId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated OpenDayOfWeek open_days_of_week = 2;
 * @return {!Array<!proto.dealer_service.OpenDayOfWeek>}
 */
proto.dealer_service.UpdateAvailabilityRequest.prototype.getOpenDaysOfWeekList = function() {
  return /** @type{!Array<!proto.dealer_service.OpenDayOfWeek>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.dealer_service.OpenDayOfWeek, 2));
};


/**
 * @param {!Array<!proto.dealer_service.OpenDayOfWeek>} value
 * @return {!proto.dealer_service.UpdateAvailabilityRequest} returns this
*/
proto.dealer_service.UpdateAvailabilityRequest.prototype.setOpenDaysOfWeekList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.dealer_service.OpenDayOfWeek=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dealer_service.OpenDayOfWeek}
 */
proto.dealer_service.UpdateAvailabilityRequest.prototype.addOpenDaysOfWeek = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.dealer_service.OpenDayOfWeek, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dealer_service.UpdateAvailabilityRequest} returns this
 */
proto.dealer_service.UpdateAvailabilityRequest.prototype.clearOpenDaysOfWeekList = function() {
  return this.setOpenDaysOfWeekList([]);
};


/**
 * optional string user_id = 3;
 * @return {string}
 */
proto.dealer_service.UpdateAvailabilityRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.UpdateAvailabilityRequest} returns this
 */
proto.dealer_service.UpdateAvailabilityRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer_service.UpdateAvailabilityResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer_service.UpdateAvailabilityResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer_service.UpdateAvailabilityResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.UpdateAvailabilityResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errorCode: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer_service.UpdateAvailabilityResponse}
 */
proto.dealer_service.UpdateAvailabilityResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer_service.UpdateAvailabilityResponse;
  return proto.dealer_service.UpdateAvailabilityResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer_service.UpdateAvailabilityResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer_service.UpdateAvailabilityResponse}
 */
proto.dealer_service.UpdateAvailabilityResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setErrorCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer_service.UpdateAvailabilityResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer_service.UpdateAvailabilityResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer_service.UpdateAvailabilityResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.UpdateAvailabilityResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrorCode();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.dealer_service.UpdateAvailabilityResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dealer_service.UpdateAvailabilityResponse} returns this
 */
proto.dealer_service.UpdateAvailabilityResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.dealer_service.UpdateAvailabilityResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.UpdateAvailabilityResponse} returns this
 */
proto.dealer_service.UpdateAvailabilityResponse.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 error_code = 3;
 * @return {number}
 */
proto.dealer_service.UpdateAvailabilityResponse.prototype.getErrorCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.dealer_service.UpdateAvailabilityResponse} returns this
 */
proto.dealer_service.UpdateAvailabilityResponse.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer_service.ClosedDateWithReason.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer_service.ClosedDateWithReason.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer_service.ClosedDateWithReason} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.ClosedDateWithReason.toObject = function(includeInstance, msg) {
  var f, obj = {
    date: jspb.Message.getFieldWithDefault(msg, 1, ""),
    reason: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer_service.ClosedDateWithReason}
 */
proto.dealer_service.ClosedDateWithReason.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer_service.ClosedDateWithReason;
  return proto.dealer_service.ClosedDateWithReason.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer_service.ClosedDateWithReason} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer_service.ClosedDateWithReason}
 */
proto.dealer_service.ClosedDateWithReason.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDate(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setReason(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer_service.ClosedDateWithReason.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer_service.ClosedDateWithReason.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer_service.ClosedDateWithReason} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.ClosedDateWithReason.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDate();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getReason();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string date = 1;
 * @return {string}
 */
proto.dealer_service.ClosedDateWithReason.prototype.getDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.ClosedDateWithReason} returns this
 */
proto.dealer_service.ClosedDateWithReason.prototype.setDate = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string reason = 2;
 * @return {string}
 */
proto.dealer_service.ClosedDateWithReason.prototype.getReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.ClosedDateWithReason} returns this
 */
proto.dealer_service.ClosedDateWithReason.prototype.setReason = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dealer_service.UpdateClosedDatesRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer_service.UpdateClosedDatesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer_service.UpdateClosedDatesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer_service.UpdateClosedDatesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.UpdateClosedDatesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealershipId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    closedDatesList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer_service.UpdateClosedDatesRequest}
 */
proto.dealer_service.UpdateClosedDatesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer_service.UpdateClosedDatesRequest;
  return proto.dealer_service.UpdateClosedDatesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer_service.UpdateClosedDatesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer_service.UpdateClosedDatesRequest}
 */
proto.dealer_service.UpdateClosedDatesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealershipId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addClosedDates(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer_service.UpdateClosedDatesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer_service.UpdateClosedDatesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer_service.UpdateClosedDatesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.UpdateClosedDatesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealershipId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getClosedDatesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional string dealership_id = 1;
 * @return {string}
 */
proto.dealer_service.UpdateClosedDatesRequest.prototype.getDealershipId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.UpdateClosedDatesRequest} returns this
 */
proto.dealer_service.UpdateClosedDatesRequest.prototype.setDealershipId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string closed_dates = 2;
 * @return {!Array<string>}
 */
proto.dealer_service.UpdateClosedDatesRequest.prototype.getClosedDatesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.dealer_service.UpdateClosedDatesRequest} returns this
 */
proto.dealer_service.UpdateClosedDatesRequest.prototype.setClosedDatesList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.dealer_service.UpdateClosedDatesRequest} returns this
 */
proto.dealer_service.UpdateClosedDatesRequest.prototype.addClosedDates = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dealer_service.UpdateClosedDatesRequest} returns this
 */
proto.dealer_service.UpdateClosedDatesRequest.prototype.clearClosedDatesList = function() {
  return this.setClosedDatesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer_service.UpdateClosedDatesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer_service.UpdateClosedDatesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer_service.UpdateClosedDatesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.UpdateClosedDatesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errorCode: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer_service.UpdateClosedDatesResponse}
 */
proto.dealer_service.UpdateClosedDatesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer_service.UpdateClosedDatesResponse;
  return proto.dealer_service.UpdateClosedDatesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer_service.UpdateClosedDatesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer_service.UpdateClosedDatesResponse}
 */
proto.dealer_service.UpdateClosedDatesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setErrorCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer_service.UpdateClosedDatesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer_service.UpdateClosedDatesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer_service.UpdateClosedDatesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.UpdateClosedDatesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrorCode();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.dealer_service.UpdateClosedDatesResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dealer_service.UpdateClosedDatesResponse} returns this
 */
proto.dealer_service.UpdateClosedDatesResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.dealer_service.UpdateClosedDatesResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.UpdateClosedDatesResponse} returns this
 */
proto.dealer_service.UpdateClosedDatesResponse.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 error_code = 3;
 * @return {number}
 */
proto.dealer_service.UpdateClosedDatesResponse.prototype.getErrorCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.dealer_service.UpdateClosedDatesResponse} returns this
 */
proto.dealer_service.UpdateClosedDatesResponse.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dealer_service.SetClosedDatesRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer_service.SetClosedDatesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer_service.SetClosedDatesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer_service.SetClosedDatesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.SetClosedDatesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealershipId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    closedDatesList: jspb.Message.toObjectList(msg.getClosedDatesList(),
    proto.dealer_service.ClosedDateWithReason.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer_service.SetClosedDatesRequest}
 */
proto.dealer_service.SetClosedDatesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer_service.SetClosedDatesRequest;
  return proto.dealer_service.SetClosedDatesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer_service.SetClosedDatesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer_service.SetClosedDatesRequest}
 */
proto.dealer_service.SetClosedDatesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealershipId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 3:
      var value = new proto.dealer_service.ClosedDateWithReason;
      reader.readMessage(value,proto.dealer_service.ClosedDateWithReason.deserializeBinaryFromReader);
      msg.addClosedDates(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer_service.SetClosedDatesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer_service.SetClosedDatesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer_service.SetClosedDatesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.SetClosedDatesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealershipId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getClosedDatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.dealer_service.ClosedDateWithReason.serializeBinaryToWriter
    );
  }
};


/**
 * optional string dealership_id = 1;
 * @return {string}
 */
proto.dealer_service.SetClosedDatesRequest.prototype.getDealershipId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.SetClosedDatesRequest} returns this
 */
proto.dealer_service.SetClosedDatesRequest.prototype.setDealershipId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_id = 2;
 * @return {string}
 */
proto.dealer_service.SetClosedDatesRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.SetClosedDatesRequest} returns this
 */
proto.dealer_service.SetClosedDatesRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated ClosedDateWithReason closed_dates = 3;
 * @return {!Array<!proto.dealer_service.ClosedDateWithReason>}
 */
proto.dealer_service.SetClosedDatesRequest.prototype.getClosedDatesList = function() {
  return /** @type{!Array<!proto.dealer_service.ClosedDateWithReason>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.dealer_service.ClosedDateWithReason, 3));
};


/**
 * @param {!Array<!proto.dealer_service.ClosedDateWithReason>} value
 * @return {!proto.dealer_service.SetClosedDatesRequest} returns this
*/
proto.dealer_service.SetClosedDatesRequest.prototype.setClosedDatesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.dealer_service.ClosedDateWithReason=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dealer_service.ClosedDateWithReason}
 */
proto.dealer_service.SetClosedDatesRequest.prototype.addClosedDates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.dealer_service.ClosedDateWithReason, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dealer_service.SetClosedDatesRequest} returns this
 */
proto.dealer_service.SetClosedDatesRequest.prototype.clearClosedDatesList = function() {
  return this.setClosedDatesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer_service.SetClosedDatesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer_service.SetClosedDatesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer_service.SetClosedDatesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.SetClosedDatesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errorCode: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer_service.SetClosedDatesResponse}
 */
proto.dealer_service.SetClosedDatesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer_service.SetClosedDatesResponse;
  return proto.dealer_service.SetClosedDatesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer_service.SetClosedDatesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer_service.SetClosedDatesResponse}
 */
proto.dealer_service.SetClosedDatesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setErrorCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer_service.SetClosedDatesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer_service.SetClosedDatesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer_service.SetClosedDatesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.SetClosedDatesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrorCode();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.dealer_service.SetClosedDatesResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dealer_service.SetClosedDatesResponse} returns this
 */
proto.dealer_service.SetClosedDatesResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.dealer_service.SetClosedDatesResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.SetClosedDatesResponse} returns this
 */
proto.dealer_service.SetClosedDatesResponse.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 error_code = 3;
 * @return {number}
 */
proto.dealer_service.SetClosedDatesResponse.prototype.getErrorCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.dealer_service.SetClosedDatesResponse} returns this
 */
proto.dealer_service.SetClosedDatesResponse.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer_service.GetAvailabilityRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer_service.GetAvailabilityRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer_service.GetAvailabilityRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.GetAvailabilityRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealershipId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer_service.GetAvailabilityRequest}
 */
proto.dealer_service.GetAvailabilityRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer_service.GetAvailabilityRequest;
  return proto.dealer_service.GetAvailabilityRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer_service.GetAvailabilityRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer_service.GetAvailabilityRequest}
 */
proto.dealer_service.GetAvailabilityRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealershipId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer_service.GetAvailabilityRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer_service.GetAvailabilityRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer_service.GetAvailabilityRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.GetAvailabilityRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealershipId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string dealership_id = 1;
 * @return {string}
 */
proto.dealer_service.GetAvailabilityRequest.prototype.getDealershipId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.GetAvailabilityRequest} returns this
 */
proto.dealer_service.GetAvailabilityRequest.prototype.setDealershipId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dealer_service.GetAvailabilityResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer_service.GetAvailabilityResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer_service.GetAvailabilityResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer_service.GetAvailabilityResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.GetAvailabilityResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    dealershipId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    openDaysOfWeekList: jspb.Message.toObjectList(msg.getOpenDaysOfWeekList(),
    proto.dealer_service.OpenDayOfWeek.toObject, includeInstance),
    error: jspb.Message.getFieldWithDefault(msg, 4, ""),
    errorCode: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer_service.GetAvailabilityResponse}
 */
proto.dealer_service.GetAvailabilityResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer_service.GetAvailabilityResponse;
  return proto.dealer_service.GetAvailabilityResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer_service.GetAvailabilityResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer_service.GetAvailabilityResponse}
 */
proto.dealer_service.GetAvailabilityResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealershipId(value);
      break;
    case 3:
      var value = new proto.dealer_service.OpenDayOfWeek;
      reader.readMessage(value,proto.dealer_service.OpenDayOfWeek.deserializeBinaryFromReader);
      msg.addOpenDaysOfWeek(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setErrorCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer_service.GetAvailabilityResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer_service.GetAvailabilityResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer_service.GetAvailabilityResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.GetAvailabilityResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getDealershipId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOpenDaysOfWeekList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.dealer_service.OpenDayOfWeek.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getErrorCode();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.dealer_service.GetAvailabilityResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dealer_service.GetAvailabilityResponse} returns this
 */
proto.dealer_service.GetAvailabilityResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string dealership_id = 2;
 * @return {string}
 */
proto.dealer_service.GetAvailabilityResponse.prototype.getDealershipId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.GetAvailabilityResponse} returns this
 */
proto.dealer_service.GetAvailabilityResponse.prototype.setDealershipId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated OpenDayOfWeek open_days_of_week = 3;
 * @return {!Array<!proto.dealer_service.OpenDayOfWeek>}
 */
proto.dealer_service.GetAvailabilityResponse.prototype.getOpenDaysOfWeekList = function() {
  return /** @type{!Array<!proto.dealer_service.OpenDayOfWeek>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.dealer_service.OpenDayOfWeek, 3));
};


/**
 * @param {!Array<!proto.dealer_service.OpenDayOfWeek>} value
 * @return {!proto.dealer_service.GetAvailabilityResponse} returns this
*/
proto.dealer_service.GetAvailabilityResponse.prototype.setOpenDaysOfWeekList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.dealer_service.OpenDayOfWeek=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dealer_service.OpenDayOfWeek}
 */
proto.dealer_service.GetAvailabilityResponse.prototype.addOpenDaysOfWeek = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.dealer_service.OpenDayOfWeek, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dealer_service.GetAvailabilityResponse} returns this
 */
proto.dealer_service.GetAvailabilityResponse.prototype.clearOpenDaysOfWeekList = function() {
  return this.setOpenDaysOfWeekList([]);
};


/**
 * optional string error = 4;
 * @return {string}
 */
proto.dealer_service.GetAvailabilityResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.GetAvailabilityResponse} returns this
 */
proto.dealer_service.GetAvailabilityResponse.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 error_code = 5;
 * @return {number}
 */
proto.dealer_service.GetAvailabilityResponse.prototype.getErrorCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.dealer_service.GetAvailabilityResponse} returns this
 */
proto.dealer_service.GetAvailabilityResponse.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer_service.GetClosedDatesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer_service.GetClosedDatesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer_service.GetClosedDatesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.GetClosedDatesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealershipId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer_service.GetClosedDatesRequest}
 */
proto.dealer_service.GetClosedDatesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer_service.GetClosedDatesRequest;
  return proto.dealer_service.GetClosedDatesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer_service.GetClosedDatesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer_service.GetClosedDatesRequest}
 */
proto.dealer_service.GetClosedDatesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealershipId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer_service.GetClosedDatesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer_service.GetClosedDatesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer_service.GetClosedDatesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.GetClosedDatesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealershipId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string dealership_id = 1;
 * @return {string}
 */
proto.dealer_service.GetClosedDatesRequest.prototype.getDealershipId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.GetClosedDatesRequest} returns this
 */
proto.dealer_service.GetClosedDatesRequest.prototype.setDealershipId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dealer_service.GetClosedDatesResponse.repeatedFields_ = [3,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer_service.GetClosedDatesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer_service.GetClosedDatesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer_service.GetClosedDatesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.GetClosedDatesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    dealershipId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    closedDatesList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    error: jspb.Message.getFieldWithDefault(msg, 4, ""),
    errorCode: jspb.Message.getFieldWithDefault(msg, 5, 0),
    closedDatesWithReasonsList: jspb.Message.toObjectList(msg.getClosedDatesWithReasonsList(),
    proto.dealer_service.ClosedDateWithReason.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer_service.GetClosedDatesResponse}
 */
proto.dealer_service.GetClosedDatesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer_service.GetClosedDatesResponse;
  return proto.dealer_service.GetClosedDatesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer_service.GetClosedDatesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer_service.GetClosedDatesResponse}
 */
proto.dealer_service.GetClosedDatesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealershipId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addClosedDates(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setErrorCode(value);
      break;
    case 6:
      var value = new proto.dealer_service.ClosedDateWithReason;
      reader.readMessage(value,proto.dealer_service.ClosedDateWithReason.deserializeBinaryFromReader);
      msg.addClosedDatesWithReasons(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer_service.GetClosedDatesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer_service.GetClosedDatesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer_service.GetClosedDatesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.GetClosedDatesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getDealershipId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getClosedDatesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getErrorCode();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getClosedDatesWithReasonsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.dealer_service.ClosedDateWithReason.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.dealer_service.GetClosedDatesResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dealer_service.GetClosedDatesResponse} returns this
 */
proto.dealer_service.GetClosedDatesResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string dealership_id = 2;
 * @return {string}
 */
proto.dealer_service.GetClosedDatesResponse.prototype.getDealershipId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.GetClosedDatesResponse} returns this
 */
proto.dealer_service.GetClosedDatesResponse.prototype.setDealershipId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string closed_dates = 3;
 * @return {!Array<string>}
 */
proto.dealer_service.GetClosedDatesResponse.prototype.getClosedDatesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.dealer_service.GetClosedDatesResponse} returns this
 */
proto.dealer_service.GetClosedDatesResponse.prototype.setClosedDatesList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.dealer_service.GetClosedDatesResponse} returns this
 */
proto.dealer_service.GetClosedDatesResponse.prototype.addClosedDates = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dealer_service.GetClosedDatesResponse} returns this
 */
proto.dealer_service.GetClosedDatesResponse.prototype.clearClosedDatesList = function() {
  return this.setClosedDatesList([]);
};


/**
 * optional string error = 4;
 * @return {string}
 */
proto.dealer_service.GetClosedDatesResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.GetClosedDatesResponse} returns this
 */
proto.dealer_service.GetClosedDatesResponse.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 error_code = 5;
 * @return {number}
 */
proto.dealer_service.GetClosedDatesResponse.prototype.getErrorCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.dealer_service.GetClosedDatesResponse} returns this
 */
proto.dealer_service.GetClosedDatesResponse.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * repeated ClosedDateWithReason closed_dates_with_reasons = 6;
 * @return {!Array<!proto.dealer_service.ClosedDateWithReason>}
 */
proto.dealer_service.GetClosedDatesResponse.prototype.getClosedDatesWithReasonsList = function() {
  return /** @type{!Array<!proto.dealer_service.ClosedDateWithReason>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.dealer_service.ClosedDateWithReason, 6));
};


/**
 * @param {!Array<!proto.dealer_service.ClosedDateWithReason>} value
 * @return {!proto.dealer_service.GetClosedDatesResponse} returns this
*/
proto.dealer_service.GetClosedDatesResponse.prototype.setClosedDatesWithReasonsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.dealer_service.ClosedDateWithReason=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dealer_service.ClosedDateWithReason}
 */
proto.dealer_service.GetClosedDatesResponse.prototype.addClosedDatesWithReasons = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.dealer_service.ClosedDateWithReason, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dealer_service.GetClosedDatesResponse} returns this
 */
proto.dealer_service.GetClosedDatesResponse.prototype.clearClosedDatesWithReasonsList = function() {
  return this.setClosedDatesWithReasonsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer_service.NotificationsTable.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer_service.NotificationsTable.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer_service.NotificationsTable} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.NotificationsTable.toObject = function(includeInstance, msg) {
  var f, obj = {
    notificationId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dealershipId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    type: jspb.Message.getFieldWithDefault(msg, 4, ""),
    title: jspb.Message.getFieldWithDefault(msg, 5, ""),
    body: jspb.Message.getFieldWithDefault(msg, 6, ""),
    createdTime: jspb.Message.getFieldWithDefault(msg, 7, ""),
    sentTime: jspb.Message.getFieldWithDefault(msg, 8, ""),
    readTime: jspb.Message.getFieldWithDefault(msg, 9, ""),
    appointmentId: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer_service.NotificationsTable}
 */
proto.dealer_service.NotificationsTable.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer_service.NotificationsTable;
  return proto.dealer_service.NotificationsTable.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer_service.NotificationsTable} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer_service.NotificationsTable}
 */
proto.dealer_service.NotificationsTable.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setNotificationId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealershipId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setBody(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedTime(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setSentTime(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setReadTime(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppointmentId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer_service.NotificationsTable.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer_service.NotificationsTable.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer_service.NotificationsTable} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.NotificationsTable.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNotificationId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDealershipId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getBody();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCreatedTime();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getSentTime();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getReadTime();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getAppointmentId();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional string notification_id = 1;
 * @return {string}
 */
proto.dealer_service.NotificationsTable.prototype.getNotificationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.NotificationsTable} returns this
 */
proto.dealer_service.NotificationsTable.prototype.setNotificationId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string dealership_id = 2;
 * @return {string}
 */
proto.dealer_service.NotificationsTable.prototype.getDealershipId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.NotificationsTable} returns this
 */
proto.dealer_service.NotificationsTable.prototype.setDealershipId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string user_id = 3;
 * @return {string}
 */
proto.dealer_service.NotificationsTable.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.NotificationsTable} returns this
 */
proto.dealer_service.NotificationsTable.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string type = 4;
 * @return {string}
 */
proto.dealer_service.NotificationsTable.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.NotificationsTable} returns this
 */
proto.dealer_service.NotificationsTable.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string title = 5;
 * @return {string}
 */
proto.dealer_service.NotificationsTable.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.NotificationsTable} returns this
 */
proto.dealer_service.NotificationsTable.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string body = 6;
 * @return {string}
 */
proto.dealer_service.NotificationsTable.prototype.getBody = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.NotificationsTable} returns this
 */
proto.dealer_service.NotificationsTable.prototype.setBody = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string created_time = 7;
 * @return {string}
 */
proto.dealer_service.NotificationsTable.prototype.getCreatedTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.NotificationsTable} returns this
 */
proto.dealer_service.NotificationsTable.prototype.setCreatedTime = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string sent_time = 8;
 * @return {string}
 */
proto.dealer_service.NotificationsTable.prototype.getSentTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.NotificationsTable} returns this
 */
proto.dealer_service.NotificationsTable.prototype.setSentTime = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string read_time = 9;
 * @return {string}
 */
proto.dealer_service.NotificationsTable.prototype.getReadTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.NotificationsTable} returns this
 */
proto.dealer_service.NotificationsTable.prototype.setReadTime = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string appointment_id = 10;
 * @return {string}
 */
proto.dealer_service.NotificationsTable.prototype.getAppointmentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.NotificationsTable} returns this
 */
proto.dealer_service.NotificationsTable.prototype.setAppointmentId = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer_service.GetNotificationsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer_service.GetNotificationsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer_service.GetNotificationsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.GetNotificationsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealershipId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer_service.GetNotificationsRequest}
 */
proto.dealer_service.GetNotificationsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer_service.GetNotificationsRequest;
  return proto.dealer_service.GetNotificationsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer_service.GetNotificationsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer_service.GetNotificationsRequest}
 */
proto.dealer_service.GetNotificationsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealershipId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer_service.GetNotificationsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer_service.GetNotificationsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer_service.GetNotificationsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.GetNotificationsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealershipId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string dealership_id = 1;
 * @return {string}
 */
proto.dealer_service.GetNotificationsRequest.prototype.getDealershipId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.GetNotificationsRequest} returns this
 */
proto.dealer_service.GetNotificationsRequest.prototype.setDealershipId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_id = 2;
 * @return {string}
 */
proto.dealer_service.GetNotificationsRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.GetNotificationsRequest} returns this
 */
proto.dealer_service.GetNotificationsRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dealer_service.GetNotificationsResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer_service.GetNotificationsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer_service.GetNotificationsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer_service.GetNotificationsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.GetNotificationsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errorCode: jspb.Message.getFieldWithDefault(msg, 3, 0),
    notificationsList: jspb.Message.toObjectList(msg.getNotificationsList(),
    proto.dealer_service.NotificationsTable.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer_service.GetNotificationsResponse}
 */
proto.dealer_service.GetNotificationsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer_service.GetNotificationsResponse;
  return proto.dealer_service.GetNotificationsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer_service.GetNotificationsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer_service.GetNotificationsResponse}
 */
proto.dealer_service.GetNotificationsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setErrorCode(value);
      break;
    case 4:
      var value = new proto.dealer_service.NotificationsTable;
      reader.readMessage(value,proto.dealer_service.NotificationsTable.deserializeBinaryFromReader);
      msg.addNotifications(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer_service.GetNotificationsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer_service.GetNotificationsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer_service.GetNotificationsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.GetNotificationsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrorCode();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getNotificationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.dealer_service.NotificationsTable.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.dealer_service.GetNotificationsResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dealer_service.GetNotificationsResponse} returns this
 */
proto.dealer_service.GetNotificationsResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.dealer_service.GetNotificationsResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.GetNotificationsResponse} returns this
 */
proto.dealer_service.GetNotificationsResponse.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 error_code = 3;
 * @return {number}
 */
proto.dealer_service.GetNotificationsResponse.prototype.getErrorCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.dealer_service.GetNotificationsResponse} returns this
 */
proto.dealer_service.GetNotificationsResponse.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated NotificationsTable notifications = 4;
 * @return {!Array<!proto.dealer_service.NotificationsTable>}
 */
proto.dealer_service.GetNotificationsResponse.prototype.getNotificationsList = function() {
  return /** @type{!Array<!proto.dealer_service.NotificationsTable>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.dealer_service.NotificationsTable, 4));
};


/**
 * @param {!Array<!proto.dealer_service.NotificationsTable>} value
 * @return {!proto.dealer_service.GetNotificationsResponse} returns this
*/
proto.dealer_service.GetNotificationsResponse.prototype.setNotificationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.dealer_service.NotificationsTable=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dealer_service.NotificationsTable}
 */
proto.dealer_service.GetNotificationsResponse.prototype.addNotifications = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.dealer_service.NotificationsTable, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dealer_service.GetNotificationsResponse} returns this
 */
proto.dealer_service.GetNotificationsResponse.prototype.clearNotificationsList = function() {
  return this.setNotificationsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer_service.GetAvailabilitySlotsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer_service.GetAvailabilitySlotsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer_service.GetAvailabilitySlotsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.GetAvailabilitySlotsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealershipId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    startDate: jspb.Message.getFieldWithDefault(msg, 2, ""),
    endDate: jspb.Message.getFieldWithDefault(msg, 3, ""),
    timezone: jspb.Message.getFieldWithDefault(msg, 4, ""),
    xResponseTimezone: jspb.Message.getFieldWithDefault(msg, 5, ""),
    xShouldDisplayWeekday: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer_service.GetAvailabilitySlotsRequest}
 */
proto.dealer_service.GetAvailabilitySlotsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer_service.GetAvailabilitySlotsRequest;
  return proto.dealer_service.GetAvailabilitySlotsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer_service.GetAvailabilitySlotsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer_service.GetAvailabilitySlotsRequest}
 */
proto.dealer_service.GetAvailabilitySlotsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealershipId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartDate(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndDate(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimezone(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setXResponseTimezone(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setXShouldDisplayWeekday(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer_service.GetAvailabilitySlotsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer_service.GetAvailabilitySlotsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer_service.GetAvailabilitySlotsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.GetAvailabilitySlotsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealershipId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStartDate();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEndDate();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTimezone();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getXResponseTimezone();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getXShouldDisplayWeekday();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional string dealership_id = 1;
 * @return {string}
 */
proto.dealer_service.GetAvailabilitySlotsRequest.prototype.getDealershipId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.GetAvailabilitySlotsRequest} returns this
 */
proto.dealer_service.GetAvailabilitySlotsRequest.prototype.setDealershipId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string start_date = 2;
 * @return {string}
 */
proto.dealer_service.GetAvailabilitySlotsRequest.prototype.getStartDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.GetAvailabilitySlotsRequest} returns this
 */
proto.dealer_service.GetAvailabilitySlotsRequest.prototype.setStartDate = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string end_date = 3;
 * @return {string}
 */
proto.dealer_service.GetAvailabilitySlotsRequest.prototype.getEndDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.GetAvailabilitySlotsRequest} returns this
 */
proto.dealer_service.GetAvailabilitySlotsRequest.prototype.setEndDate = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string timezone = 4;
 * @return {string}
 */
proto.dealer_service.GetAvailabilitySlotsRequest.prototype.getTimezone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.GetAvailabilitySlotsRequest} returns this
 */
proto.dealer_service.GetAvailabilitySlotsRequest.prototype.setTimezone = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string x_response_timezone = 5;
 * @return {string}
 */
proto.dealer_service.GetAvailabilitySlotsRequest.prototype.getXResponseTimezone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.GetAvailabilitySlotsRequest} returns this
 */
proto.dealer_service.GetAvailabilitySlotsRequest.prototype.setXResponseTimezone = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional bool x_should_display_weekday = 6;
 * @return {boolean}
 */
proto.dealer_service.GetAvailabilitySlotsRequest.prototype.getXShouldDisplayWeekday = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dealer_service.GetAvailabilitySlotsRequest} returns this
 */
proto.dealer_service.GetAvailabilitySlotsRequest.prototype.setXShouldDisplayWeekday = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dealer_service.GetAvailabilitySlotsResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer_service.GetAvailabilitySlotsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer_service.GetAvailabilitySlotsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer_service.GetAvailabilitySlotsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.GetAvailabilitySlotsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    dealershipId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    slotsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    error: jspb.Message.getFieldWithDefault(msg, 4, ""),
    errorCode: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer_service.GetAvailabilitySlotsResponse}
 */
proto.dealer_service.GetAvailabilitySlotsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer_service.GetAvailabilitySlotsResponse;
  return proto.dealer_service.GetAvailabilitySlotsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer_service.GetAvailabilitySlotsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer_service.GetAvailabilitySlotsResponse}
 */
proto.dealer_service.GetAvailabilitySlotsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealershipId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addSlots(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setErrorCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer_service.GetAvailabilitySlotsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer_service.GetAvailabilitySlotsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer_service.GetAvailabilitySlotsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.GetAvailabilitySlotsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getDealershipId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSlotsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getErrorCode();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.dealer_service.GetAvailabilitySlotsResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dealer_service.GetAvailabilitySlotsResponse} returns this
 */
proto.dealer_service.GetAvailabilitySlotsResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string dealership_id = 2;
 * @return {string}
 */
proto.dealer_service.GetAvailabilitySlotsResponse.prototype.getDealershipId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.GetAvailabilitySlotsResponse} returns this
 */
proto.dealer_service.GetAvailabilitySlotsResponse.prototype.setDealershipId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string slots = 3;
 * @return {!Array<string>}
 */
proto.dealer_service.GetAvailabilitySlotsResponse.prototype.getSlotsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.dealer_service.GetAvailabilitySlotsResponse} returns this
 */
proto.dealer_service.GetAvailabilitySlotsResponse.prototype.setSlotsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.dealer_service.GetAvailabilitySlotsResponse} returns this
 */
proto.dealer_service.GetAvailabilitySlotsResponse.prototype.addSlots = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dealer_service.GetAvailabilitySlotsResponse} returns this
 */
proto.dealer_service.GetAvailabilitySlotsResponse.prototype.clearSlotsList = function() {
  return this.setSlotsList([]);
};


/**
 * optional string error = 4;
 * @return {string}
 */
proto.dealer_service.GetAvailabilitySlotsResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.GetAvailabilitySlotsResponse} returns this
 */
proto.dealer_service.GetAvailabilitySlotsResponse.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 error_code = 5;
 * @return {number}
 */
proto.dealer_service.GetAvailabilitySlotsResponse.prototype.getErrorCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.dealer_service.GetAvailabilitySlotsResponse} returns this
 */
proto.dealer_service.GetAvailabilitySlotsResponse.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer_service.ReadNotificationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer_service.ReadNotificationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer_service.ReadNotificationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.ReadNotificationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    notificationId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer_service.ReadNotificationRequest}
 */
proto.dealer_service.ReadNotificationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer_service.ReadNotificationRequest;
  return proto.dealer_service.ReadNotificationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer_service.ReadNotificationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer_service.ReadNotificationRequest}
 */
proto.dealer_service.ReadNotificationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setNotificationId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer_service.ReadNotificationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer_service.ReadNotificationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer_service.ReadNotificationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.ReadNotificationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNotificationId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string notification_id = 1;
 * @return {string}
 */
proto.dealer_service.ReadNotificationRequest.prototype.getNotificationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.ReadNotificationRequest} returns this
 */
proto.dealer_service.ReadNotificationRequest.prototype.setNotificationId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_id = 2;
 * @return {string}
 */
proto.dealer_service.ReadNotificationRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.ReadNotificationRequest} returns this
 */
proto.dealer_service.ReadNotificationRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer_service.ReadNotificationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer_service.ReadNotificationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer_service.ReadNotificationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.ReadNotificationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errorCode: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer_service.ReadNotificationResponse}
 */
proto.dealer_service.ReadNotificationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer_service.ReadNotificationResponse;
  return proto.dealer_service.ReadNotificationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer_service.ReadNotificationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer_service.ReadNotificationResponse}
 */
proto.dealer_service.ReadNotificationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setErrorCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer_service.ReadNotificationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer_service.ReadNotificationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer_service.ReadNotificationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.ReadNotificationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrorCode();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.dealer_service.ReadNotificationResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dealer_service.ReadNotificationResponse} returns this
 */
proto.dealer_service.ReadNotificationResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.dealer_service.ReadNotificationResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.ReadNotificationResponse} returns this
 */
proto.dealer_service.ReadNotificationResponse.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 error_code = 3;
 * @return {number}
 */
proto.dealer_service.ReadNotificationResponse.prototype.getErrorCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.dealer_service.ReadNotificationResponse} returns this
 */
proto.dealer_service.ReadNotificationResponse.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dealer_service.DeleteNotificationsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer_service.DeleteNotificationsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer_service.DeleteNotificationsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer_service.DeleteNotificationsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.DeleteNotificationsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    notificationIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    userId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer_service.DeleteNotificationsRequest}
 */
proto.dealer_service.DeleteNotificationsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer_service.DeleteNotificationsRequest;
  return proto.dealer_service.DeleteNotificationsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer_service.DeleteNotificationsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer_service.DeleteNotificationsRequest}
 */
proto.dealer_service.DeleteNotificationsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addNotificationIds(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer_service.DeleteNotificationsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer_service.DeleteNotificationsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer_service.DeleteNotificationsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.DeleteNotificationsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNotificationIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated string notification_ids = 1;
 * @return {!Array<string>}
 */
proto.dealer_service.DeleteNotificationsRequest.prototype.getNotificationIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.dealer_service.DeleteNotificationsRequest} returns this
 */
proto.dealer_service.DeleteNotificationsRequest.prototype.setNotificationIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.dealer_service.DeleteNotificationsRequest} returns this
 */
proto.dealer_service.DeleteNotificationsRequest.prototype.addNotificationIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dealer_service.DeleteNotificationsRequest} returns this
 */
proto.dealer_service.DeleteNotificationsRequest.prototype.clearNotificationIdsList = function() {
  return this.setNotificationIdsList([]);
};


/**
 * optional string user_id = 2;
 * @return {string}
 */
proto.dealer_service.DeleteNotificationsRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.DeleteNotificationsRequest} returns this
 */
proto.dealer_service.DeleteNotificationsRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer_service.DeleteNotificationsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer_service.DeleteNotificationsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer_service.DeleteNotificationsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.DeleteNotificationsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errorCode: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer_service.DeleteNotificationsResponse}
 */
proto.dealer_service.DeleteNotificationsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer_service.DeleteNotificationsResponse;
  return proto.dealer_service.DeleteNotificationsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer_service.DeleteNotificationsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer_service.DeleteNotificationsResponse}
 */
proto.dealer_service.DeleteNotificationsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setErrorCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer_service.DeleteNotificationsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer_service.DeleteNotificationsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer_service.DeleteNotificationsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.DeleteNotificationsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrorCode();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.dealer_service.DeleteNotificationsResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dealer_service.DeleteNotificationsResponse} returns this
 */
proto.dealer_service.DeleteNotificationsResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.dealer_service.DeleteNotificationsResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.DeleteNotificationsResponse} returns this
 */
proto.dealer_service.DeleteNotificationsResponse.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 error_code = 3;
 * @return {number}
 */
proto.dealer_service.DeleteNotificationsResponse.prototype.getErrorCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.dealer_service.DeleteNotificationsResponse} returns this
 */
proto.dealer_service.DeleteNotificationsResponse.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer_service.SetAppointmentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer_service.SetAppointmentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer_service.SetAppointmentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.SetAppointmentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    pickupTime: jspb.Message.getFieldWithDefault(msg, 3, ""),
    timezone: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer_service.SetAppointmentRequest}
 */
proto.dealer_service.SetAppointmentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer_service.SetAppointmentRequest;
  return proto.dealer_service.SetAppointmentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer_service.SetAppointmentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer_service.SetAppointmentRequest}
 */
proto.dealer_service.SetAppointmentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPickupTime(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimezone(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer_service.SetAppointmentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer_service.SetAppointmentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer_service.SetAppointmentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.SetAppointmentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPickupTime();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTimezone();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.dealer_service.SetAppointmentRequest.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.SetAppointmentRequest} returns this
 */
proto.dealer_service.SetAppointmentRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string subscription_id = 2;
 * @return {string}
 */
proto.dealer_service.SetAppointmentRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.SetAppointmentRequest} returns this
 */
proto.dealer_service.SetAppointmentRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string pickup_time = 3;
 * @return {string}
 */
proto.dealer_service.SetAppointmentRequest.prototype.getPickupTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.SetAppointmentRequest} returns this
 */
proto.dealer_service.SetAppointmentRequest.prototype.setPickupTime = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string timezone = 4;
 * @return {string}
 */
proto.dealer_service.SetAppointmentRequest.prototype.getTimezone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.SetAppointmentRequest} returns this
 */
proto.dealer_service.SetAppointmentRequest.prototype.setTimezone = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer_service.SetAppointmentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer_service.SetAppointmentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer_service.SetAppointmentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.SetAppointmentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    successMessage: jspb.Message.getFieldWithDefault(msg, 2, ""),
    error: jspb.Message.getFieldWithDefault(msg, 3, ""),
    errorCode: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer_service.SetAppointmentResponse}
 */
proto.dealer_service.SetAppointmentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer_service.SetAppointmentResponse;
  return proto.dealer_service.SetAppointmentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer_service.SetAppointmentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer_service.SetAppointmentResponse}
 */
proto.dealer_service.SetAppointmentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSuccessMessage(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setErrorCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer_service.SetAppointmentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer_service.SetAppointmentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer_service.SetAppointmentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.SetAppointmentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getSuccessMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getErrorCode();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.dealer_service.SetAppointmentResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dealer_service.SetAppointmentResponse} returns this
 */
proto.dealer_service.SetAppointmentResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string success_message = 2;
 * @return {string}
 */
proto.dealer_service.SetAppointmentResponse.prototype.getSuccessMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.SetAppointmentResponse} returns this
 */
proto.dealer_service.SetAppointmentResponse.prototype.setSuccessMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string error = 3;
 * @return {string}
 */
proto.dealer_service.SetAppointmentResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.SetAppointmentResponse} returns this
 */
proto.dealer_service.SetAppointmentResponse.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 error_code = 4;
 * @return {number}
 */
proto.dealer_service.SetAppointmentResponse.prototype.getErrorCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.dealer_service.SetAppointmentResponse} returns this
 */
proto.dealer_service.SetAppointmentResponse.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer_service.KeyHandOffRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer_service.KeyHandOffRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer_service.KeyHandOffRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.KeyHandOffRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealershipId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    vehicleId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    keyQuantity: jspb.Message.getFieldWithDefault(msg, 4, 0),
    vehicleRegistration: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    vehicleOwner: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    floorMats: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    chargerCable: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    chargerAdapter: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    optAdapter: jspb.Message.getBooleanFieldWithDefault(msg, 10, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer_service.KeyHandOffRequest}
 */
proto.dealer_service.KeyHandOffRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer_service.KeyHandOffRequest;
  return proto.dealer_service.KeyHandOffRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer_service.KeyHandOffRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer_service.KeyHandOffRequest}
 */
proto.dealer_service.KeyHandOffRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealershipId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setKeyQuantity(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setVehicleRegistration(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setVehicleOwner(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFloorMats(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setChargerCable(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setChargerAdapter(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOptAdapter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer_service.KeyHandOffRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer_service.KeyHandOffRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer_service.KeyHandOffRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.KeyHandOffRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealershipId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getKeyQuantity();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getVehicleRegistration();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getVehicleOwner();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getFloorMats();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getChargerCable();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getChargerAdapter();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getOptAdapter();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
};


/**
 * optional string dealership_id = 1;
 * @return {string}
 */
proto.dealer_service.KeyHandOffRequest.prototype.getDealershipId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.KeyHandOffRequest} returns this
 */
proto.dealer_service.KeyHandOffRequest.prototype.setDealershipId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_id = 2;
 * @return {string}
 */
proto.dealer_service.KeyHandOffRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.KeyHandOffRequest} returns this
 */
proto.dealer_service.KeyHandOffRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string vehicle_id = 3;
 * @return {string}
 */
proto.dealer_service.KeyHandOffRequest.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.KeyHandOffRequest} returns this
 */
proto.dealer_service.KeyHandOffRequest.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 key_quantity = 4;
 * @return {number}
 */
proto.dealer_service.KeyHandOffRequest.prototype.getKeyQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.dealer_service.KeyHandOffRequest} returns this
 */
proto.dealer_service.KeyHandOffRequest.prototype.setKeyQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool vehicle_registration = 5;
 * @return {boolean}
 */
proto.dealer_service.KeyHandOffRequest.prototype.getVehicleRegistration = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dealer_service.KeyHandOffRequest} returns this
 */
proto.dealer_service.KeyHandOffRequest.prototype.setVehicleRegistration = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool vehicle_owner = 6;
 * @return {boolean}
 */
proto.dealer_service.KeyHandOffRequest.prototype.getVehicleOwner = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dealer_service.KeyHandOffRequest} returns this
 */
proto.dealer_service.KeyHandOffRequest.prototype.setVehicleOwner = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool floor_mats = 7;
 * @return {boolean}
 */
proto.dealer_service.KeyHandOffRequest.prototype.getFloorMats = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dealer_service.KeyHandOffRequest} returns this
 */
proto.dealer_service.KeyHandOffRequest.prototype.setFloorMats = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool charger_cable = 8;
 * @return {boolean}
 */
proto.dealer_service.KeyHandOffRequest.prototype.getChargerCable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dealer_service.KeyHandOffRequest} returns this
 */
proto.dealer_service.KeyHandOffRequest.prototype.setChargerCable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool charger_adapter = 9;
 * @return {boolean}
 */
proto.dealer_service.KeyHandOffRequest.prototype.getChargerAdapter = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dealer_service.KeyHandOffRequest} returns this
 */
proto.dealer_service.KeyHandOffRequest.prototype.setChargerAdapter = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool opt_adapter = 10;
 * @return {boolean}
 */
proto.dealer_service.KeyHandOffRequest.prototype.getOptAdapter = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dealer_service.KeyHandOffRequest} returns this
 */
proto.dealer_service.KeyHandOffRequest.prototype.setOptAdapter = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer_service.KeyHandOffResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer_service.KeyHandOffResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer_service.KeyHandOffResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.KeyHandOffResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errorCode: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer_service.KeyHandOffResponse}
 */
proto.dealer_service.KeyHandOffResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer_service.KeyHandOffResponse;
  return proto.dealer_service.KeyHandOffResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer_service.KeyHandOffResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer_service.KeyHandOffResponse}
 */
proto.dealer_service.KeyHandOffResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setErrorCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer_service.KeyHandOffResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer_service.KeyHandOffResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer_service.KeyHandOffResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.KeyHandOffResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrorCode();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.dealer_service.KeyHandOffResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dealer_service.KeyHandOffResponse} returns this
 */
proto.dealer_service.KeyHandOffResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.dealer_service.KeyHandOffResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.KeyHandOffResponse} returns this
 */
proto.dealer_service.KeyHandOffResponse.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 error_code = 3;
 * @return {number}
 */
proto.dealer_service.KeyHandOffResponse.prototype.getErrorCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.dealer_service.KeyHandOffResponse} returns this
 */
proto.dealer_service.KeyHandOffResponse.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer_service.AppointmentTypeConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer_service.AppointmentTypeConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer_service.AppointmentTypeConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.AppointmentTypeConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    durationMinutes: jspb.Message.getFieldWithDefault(msg, 2, 0),
    countPerSlot: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer_service.AppointmentTypeConfig}
 */
proto.dealer_service.AppointmentTypeConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer_service.AppointmentTypeConfig;
  return proto.dealer_service.AppointmentTypeConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer_service.AppointmentTypeConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer_service.AppointmentTypeConfig}
 */
proto.dealer_service.AppointmentTypeConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.domain.Appointment.Type} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDurationMinutes(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCountPerSlot(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer_service.AppointmentTypeConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer_service.AppointmentTypeConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer_service.AppointmentTypeConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.AppointmentTypeConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getDurationMinutes();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getCountPerSlot();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional domain.Appointment.Type type = 1;
 * @return {!proto.domain.Appointment.Type}
 */
proto.dealer_service.AppointmentTypeConfig.prototype.getType = function() {
  return /** @type {!proto.domain.Appointment.Type} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.domain.Appointment.Type} value
 * @return {!proto.dealer_service.AppointmentTypeConfig} returns this
 */
proto.dealer_service.AppointmentTypeConfig.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional int32 duration_minutes = 2;
 * @return {number}
 */
proto.dealer_service.AppointmentTypeConfig.prototype.getDurationMinutes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.dealer_service.AppointmentTypeConfig} returns this
 */
proto.dealer_service.AppointmentTypeConfig.prototype.setDurationMinutes = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 count_per_slot = 3;
 * @return {number}
 */
proto.dealer_service.AppointmentTypeConfig.prototype.getCountPerSlot = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.dealer_service.AppointmentTypeConfig} returns this
 */
proto.dealer_service.AppointmentTypeConfig.prototype.setCountPerSlot = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer_service.GetAppointmentTypeConfigsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer_service.GetAppointmentTypeConfigsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer_service.GetAppointmentTypeConfigsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.GetAppointmentTypeConfigsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer_service.GetAppointmentTypeConfigsRequest}
 */
proto.dealer_service.GetAppointmentTypeConfigsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer_service.GetAppointmentTypeConfigsRequest;
  return proto.dealer_service.GetAppointmentTypeConfigsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer_service.GetAppointmentTypeConfigsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer_service.GetAppointmentTypeConfigsRequest}
 */
proto.dealer_service.GetAppointmentTypeConfigsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer_service.GetAppointmentTypeConfigsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer_service.GetAppointmentTypeConfigsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer_service.GetAppointmentTypeConfigsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.GetAppointmentTypeConfigsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dealer_service.GetAppointmentTypeConfigsResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer_service.GetAppointmentTypeConfigsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer_service.GetAppointmentTypeConfigsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer_service.GetAppointmentTypeConfigsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.GetAppointmentTypeConfigsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errorCode: jspb.Message.getFieldWithDefault(msg, 3, 0),
    appointmentTypeConfigsList: jspb.Message.toObjectList(msg.getAppointmentTypeConfigsList(),
    proto.dealer_service.AppointmentTypeConfig.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer_service.GetAppointmentTypeConfigsResponse}
 */
proto.dealer_service.GetAppointmentTypeConfigsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer_service.GetAppointmentTypeConfigsResponse;
  return proto.dealer_service.GetAppointmentTypeConfigsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer_service.GetAppointmentTypeConfigsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer_service.GetAppointmentTypeConfigsResponse}
 */
proto.dealer_service.GetAppointmentTypeConfigsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setErrorCode(value);
      break;
    case 4:
      var value = new proto.dealer_service.AppointmentTypeConfig;
      reader.readMessage(value,proto.dealer_service.AppointmentTypeConfig.deserializeBinaryFromReader);
      msg.addAppointmentTypeConfigs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer_service.GetAppointmentTypeConfigsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer_service.GetAppointmentTypeConfigsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer_service.GetAppointmentTypeConfigsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.GetAppointmentTypeConfigsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrorCode();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getAppointmentTypeConfigsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.dealer_service.AppointmentTypeConfig.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.dealer_service.GetAppointmentTypeConfigsResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dealer_service.GetAppointmentTypeConfigsResponse} returns this
 */
proto.dealer_service.GetAppointmentTypeConfigsResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.dealer_service.GetAppointmentTypeConfigsResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.GetAppointmentTypeConfigsResponse} returns this
 */
proto.dealer_service.GetAppointmentTypeConfigsResponse.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 error_code = 3;
 * @return {number}
 */
proto.dealer_service.GetAppointmentTypeConfigsResponse.prototype.getErrorCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.dealer_service.GetAppointmentTypeConfigsResponse} returns this
 */
proto.dealer_service.GetAppointmentTypeConfigsResponse.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated AppointmentTypeConfig appointment_type_configs = 4;
 * @return {!Array<!proto.dealer_service.AppointmentTypeConfig>}
 */
proto.dealer_service.GetAppointmentTypeConfigsResponse.prototype.getAppointmentTypeConfigsList = function() {
  return /** @type{!Array<!proto.dealer_service.AppointmentTypeConfig>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.dealer_service.AppointmentTypeConfig, 4));
};


/**
 * @param {!Array<!proto.dealer_service.AppointmentTypeConfig>} value
 * @return {!proto.dealer_service.GetAppointmentTypeConfigsResponse} returns this
*/
proto.dealer_service.GetAppointmentTypeConfigsResponse.prototype.setAppointmentTypeConfigsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.dealer_service.AppointmentTypeConfig=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dealer_service.AppointmentTypeConfig}
 */
proto.dealer_service.GetAppointmentTypeConfigsResponse.prototype.addAppointmentTypeConfigs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.dealer_service.AppointmentTypeConfig, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dealer_service.GetAppointmentTypeConfigsResponse} returns this
 */
proto.dealer_service.GetAppointmentTypeConfigsResponse.prototype.clearAppointmentTypeConfigsList = function() {
  return this.setAppointmentTypeConfigsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dealer_service.SetAppointmentTypeConfigsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer_service.SetAppointmentTypeConfigsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer_service.SetAppointmentTypeConfigsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer_service.SetAppointmentTypeConfigsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.SetAppointmentTypeConfigsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    appointmentTypeConfigsList: jspb.Message.toObjectList(msg.getAppointmentTypeConfigsList(),
    proto.dealer_service.AppointmentTypeConfig.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer_service.SetAppointmentTypeConfigsRequest}
 */
proto.dealer_service.SetAppointmentTypeConfigsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer_service.SetAppointmentTypeConfigsRequest;
  return proto.dealer_service.SetAppointmentTypeConfigsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer_service.SetAppointmentTypeConfigsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer_service.SetAppointmentTypeConfigsRequest}
 */
proto.dealer_service.SetAppointmentTypeConfigsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.dealer_service.AppointmentTypeConfig;
      reader.readMessage(value,proto.dealer_service.AppointmentTypeConfig.deserializeBinaryFromReader);
      msg.addAppointmentTypeConfigs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer_service.SetAppointmentTypeConfigsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer_service.SetAppointmentTypeConfigsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer_service.SetAppointmentTypeConfigsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.SetAppointmentTypeConfigsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppointmentTypeConfigsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.dealer_service.AppointmentTypeConfig.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AppointmentTypeConfig appointment_type_configs = 1;
 * @return {!Array<!proto.dealer_service.AppointmentTypeConfig>}
 */
proto.dealer_service.SetAppointmentTypeConfigsRequest.prototype.getAppointmentTypeConfigsList = function() {
  return /** @type{!Array<!proto.dealer_service.AppointmentTypeConfig>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.dealer_service.AppointmentTypeConfig, 1));
};


/**
 * @param {!Array<!proto.dealer_service.AppointmentTypeConfig>} value
 * @return {!proto.dealer_service.SetAppointmentTypeConfigsRequest} returns this
*/
proto.dealer_service.SetAppointmentTypeConfigsRequest.prototype.setAppointmentTypeConfigsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.dealer_service.AppointmentTypeConfig=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dealer_service.AppointmentTypeConfig}
 */
proto.dealer_service.SetAppointmentTypeConfigsRequest.prototype.addAppointmentTypeConfigs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.dealer_service.AppointmentTypeConfig, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dealer_service.SetAppointmentTypeConfigsRequest} returns this
 */
proto.dealer_service.SetAppointmentTypeConfigsRequest.prototype.clearAppointmentTypeConfigsList = function() {
  return this.setAppointmentTypeConfigsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dealer_service.SetAppointmentTypeConfigsResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer_service.SetAppointmentTypeConfigsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer_service.SetAppointmentTypeConfigsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer_service.SetAppointmentTypeConfigsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.SetAppointmentTypeConfigsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errorCode: jspb.Message.getFieldWithDefault(msg, 3, 0),
    appointmentTypeConfigsList: jspb.Message.toObjectList(msg.getAppointmentTypeConfigsList(),
    proto.dealer_service.AppointmentTypeConfig.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer_service.SetAppointmentTypeConfigsResponse}
 */
proto.dealer_service.SetAppointmentTypeConfigsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer_service.SetAppointmentTypeConfigsResponse;
  return proto.dealer_service.SetAppointmentTypeConfigsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer_service.SetAppointmentTypeConfigsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer_service.SetAppointmentTypeConfigsResponse}
 */
proto.dealer_service.SetAppointmentTypeConfigsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setErrorCode(value);
      break;
    case 4:
      var value = new proto.dealer_service.AppointmentTypeConfig;
      reader.readMessage(value,proto.dealer_service.AppointmentTypeConfig.deserializeBinaryFromReader);
      msg.addAppointmentTypeConfigs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer_service.SetAppointmentTypeConfigsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer_service.SetAppointmentTypeConfigsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer_service.SetAppointmentTypeConfigsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.SetAppointmentTypeConfigsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrorCode();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getAppointmentTypeConfigsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.dealer_service.AppointmentTypeConfig.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.dealer_service.SetAppointmentTypeConfigsResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dealer_service.SetAppointmentTypeConfigsResponse} returns this
 */
proto.dealer_service.SetAppointmentTypeConfigsResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.dealer_service.SetAppointmentTypeConfigsResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.SetAppointmentTypeConfigsResponse} returns this
 */
proto.dealer_service.SetAppointmentTypeConfigsResponse.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 error_code = 3;
 * @return {number}
 */
proto.dealer_service.SetAppointmentTypeConfigsResponse.prototype.getErrorCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.dealer_service.SetAppointmentTypeConfigsResponse} returns this
 */
proto.dealer_service.SetAppointmentTypeConfigsResponse.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated AppointmentTypeConfig appointment_type_configs = 4;
 * @return {!Array<!proto.dealer_service.AppointmentTypeConfig>}
 */
proto.dealer_service.SetAppointmentTypeConfigsResponse.prototype.getAppointmentTypeConfigsList = function() {
  return /** @type{!Array<!proto.dealer_service.AppointmentTypeConfig>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.dealer_service.AppointmentTypeConfig, 4));
};


/**
 * @param {!Array<!proto.dealer_service.AppointmentTypeConfig>} value
 * @return {!proto.dealer_service.SetAppointmentTypeConfigsResponse} returns this
*/
proto.dealer_service.SetAppointmentTypeConfigsResponse.prototype.setAppointmentTypeConfigsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.dealer_service.AppointmentTypeConfig=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dealer_service.AppointmentTypeConfig}
 */
proto.dealer_service.SetAppointmentTypeConfigsResponse.prototype.addAppointmentTypeConfigs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.dealer_service.AppointmentTypeConfig, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dealer_service.SetAppointmentTypeConfigsResponse} returns this
 */
proto.dealer_service.SetAppointmentTypeConfigsResponse.prototype.clearAppointmentTypeConfigsList = function() {
  return this.setAppointmentTypeConfigsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer_service.SetAppointmentTypesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer_service.SetAppointmentTypesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer_service.SetAppointmentTypesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.SetAppointmentTypesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errorCode: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer_service.SetAppointmentTypesResponse}
 */
proto.dealer_service.SetAppointmentTypesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer_service.SetAppointmentTypesResponse;
  return proto.dealer_service.SetAppointmentTypesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer_service.SetAppointmentTypesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer_service.SetAppointmentTypesResponse}
 */
proto.dealer_service.SetAppointmentTypesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setErrorCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer_service.SetAppointmentTypesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer_service.SetAppointmentTypesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer_service.SetAppointmentTypesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.SetAppointmentTypesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrorCode();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.dealer_service.SetAppointmentTypesResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dealer_service.SetAppointmentTypesResponse} returns this
 */
proto.dealer_service.SetAppointmentTypesResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.dealer_service.SetAppointmentTypesResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.SetAppointmentTypesResponse} returns this
 */
proto.dealer_service.SetAppointmentTypesResponse.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 error_code = 3;
 * @return {number}
 */
proto.dealer_service.SetAppointmentTypesResponse.prototype.getErrorCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.dealer_service.SetAppointmentTypesResponse} returns this
 */
proto.dealer_service.SetAppointmentTypesResponse.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer_service.GetDealerUsersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer_service.GetDealerUsersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer_service.GetDealerUsersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.GetDealerUsersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealershipId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer_service.GetDealerUsersRequest}
 */
proto.dealer_service.GetDealerUsersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer_service.GetDealerUsersRequest;
  return proto.dealer_service.GetDealerUsersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer_service.GetDealerUsersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer_service.GetDealerUsersRequest}
 */
proto.dealer_service.GetDealerUsersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealershipId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer_service.GetDealerUsersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer_service.GetDealerUsersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer_service.GetDealerUsersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.GetDealerUsersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealershipId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string dealership_id = 1;
 * @return {string}
 */
proto.dealer_service.GetDealerUsersRequest.prototype.getDealershipId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer_service.GetDealerUsersRequest} returns this
 */
proto.dealer_service.GetDealerUsersRequest.prototype.setDealershipId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dealer_service.GetDealerUsersResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer_service.GetDealerUsersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer_service.GetDealerUsersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer_service.GetDealerUsersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.GetDealerUsersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealerUsersList: jspb.Message.toObjectList(msg.getDealerUsersList(),
    domain_pb.DealerUser.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer_service.GetDealerUsersResponse}
 */
proto.dealer_service.GetDealerUsersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer_service.GetDealerUsersResponse;
  return proto.dealer_service.GetDealerUsersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer_service.GetDealerUsersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer_service.GetDealerUsersResponse}
 */
proto.dealer_service.GetDealerUsersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new domain_pb.DealerUser;
      reader.readMessage(value,domain_pb.DealerUser.deserializeBinaryFromReader);
      msg.addDealerUsers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer_service.GetDealerUsersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer_service.GetDealerUsersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer_service.GetDealerUsersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer_service.GetDealerUsersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealerUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      domain_pb.DealerUser.serializeBinaryToWriter
    );
  }
};


/**
 * repeated domain.DealerUser dealer_users = 1;
 * @return {!Array<!proto.domain.DealerUser>}
 */
proto.dealer_service.GetDealerUsersResponse.prototype.getDealerUsersList = function() {
  return /** @type{!Array<!proto.domain.DealerUser>} */ (
    jspb.Message.getRepeatedWrapperField(this, domain_pb.DealerUser, 1));
};


/**
 * @param {!Array<!proto.domain.DealerUser>} value
 * @return {!proto.dealer_service.GetDealerUsersResponse} returns this
*/
proto.dealer_service.GetDealerUsersResponse.prototype.setDealerUsersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.domain.DealerUser=} opt_value
 * @param {number=} opt_index
 * @return {!proto.domain.DealerUser}
 */
proto.dealer_service.GetDealerUsersResponse.prototype.addDealerUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.domain.DealerUser, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dealer_service.GetDealerUsersResponse} returns this
 */
proto.dealer_service.GetDealerUsersResponse.prototype.clearDealerUsersList = function() {
  return this.setDealerUsersList([]);
};


goog.object.extend(exports, proto.dealer_service);
