// source: appointment_service.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var domain_pb = require('./domain_pb.js');
goog.object.extend(proto, domain_pb);
var appointment_pb = require('./appointment_pb.js');
goog.object.extend(proto, appointment_pb);
goog.exportSymbol('proto.appointment_service.AppointmentKeyHandOffRequest', null, global);
goog.exportSymbol('proto.appointment_service.AppointmentKeyHandOffResponse', null, global);
goog.exportSymbol('proto.appointment_service.Area', null, global);
goog.exportSymbol('proto.appointment_service.Calendar', null, global);
goog.exportSymbol('proto.appointment_service.CancelAppointmentRequest', null, global);
goog.exportSymbol('proto.appointment_service.CancelAppointmentResponse', null, global);
goog.exportSymbol('proto.appointment_service.CreateAppointmentRequest', null, global);
goog.exportSymbol('proto.appointment_service.CreateAppointmentResponse', null, global);
goog.exportSymbol('proto.appointment_service.CreateAvailabilityRequest', null, global);
goog.exportSymbol('proto.appointment_service.CreateAvailabilityResponse', null, global);
goog.exportSymbol('proto.appointment_service.DeleteAvailabilityRequest', null, global);
goog.exportSymbol('proto.appointment_service.DeleteAvailabilityResponse', null, global);
goog.exportSymbol('proto.appointment_service.GetAppointmentCheckoutStatusRequest', null, global);
goog.exportSymbol('proto.appointment_service.GetAppointmentCheckoutStatusResponse', null, global);
goog.exportSymbol('proto.appointment_service.GetAppointmentKeyHandOffRequest', null, global);
goog.exportSymbol('proto.appointment_service.GetAppointmentKeyHandOffResponse', null, global);
goog.exportSymbol('proto.appointment_service.GetAppointmentPickupDateRequest', null, global);
goog.exportSymbol('proto.appointment_service.GetAppointmentPickupDateResponse', null, global);
goog.exportSymbol('proto.appointment_service.GetAppointmentRequest', null, global);
goog.exportSymbol('proto.appointment_service.GetAppointmentResponse', null, global);
goog.exportSymbol('proto.appointment_service.GetAvailabilityRequest', null, global);
goog.exportSymbol('proto.appointment_service.GetAvailabilityResponse', null, global);
goog.exportSymbol('proto.appointment_service.GetAvailabilitySlotsRequest', null, global);
goog.exportSymbol('proto.appointment_service.GetAvailabilitySlotsResponse', null, global);
goog.exportSymbol('proto.appointment_service.GetPreAppointmentCheckoutStatusRequest', null, global);
goog.exportSymbol('proto.appointment_service.GetPreAppointmentCheckoutStatusResponse', null, global);
goog.exportSymbol('proto.appointment_service.ListAppointmentTypeConfigsRequest', null, global);
goog.exportSymbol('proto.appointment_service.ListAppointmentTypeConfigsResponse', null, global);
goog.exportSymbol('proto.appointment_service.ListAppointmentsRequest', null, global);
goog.exportSymbol('proto.appointment_service.ListAppointmentsResponse', null, global);
goog.exportSymbol('proto.appointment_service.ListCompletedAppointmentsRequest', null, global);
goog.exportSymbol('proto.appointment_service.ListCompletedAppointmentsResponse', null, global);
goog.exportSymbol('proto.appointment_service.Location', null, global);
goog.exportSymbol('proto.appointment_service.SetAppointmentTypeConfigsRequest', null, global);
goog.exportSymbol('proto.appointment_service.SetAppointmentTypeConfigsResponse', null, global);
goog.exportSymbol('proto.appointment_service.UpdateAppointmentRequest', null, global);
goog.exportSymbol('proto.appointment_service.UpdateAppointmentResponse', null, global);
goog.exportSymbol('proto.appointment_service.UpdateAvailabilityRequest', null, global);
goog.exportSymbol('proto.appointment_service.UpdateAvailabilityResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointment_service.CreateAvailabilityRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointment_service.CreateAvailabilityRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointment_service.CreateAvailabilityRequest.displayName = 'proto.appointment_service.CreateAvailabilityRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointment_service.CreateAvailabilityResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointment_service.CreateAvailabilityResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointment_service.CreateAvailabilityResponse.displayName = 'proto.appointment_service.CreateAvailabilityResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointment_service.UpdateAvailabilityRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointment_service.UpdateAvailabilityRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointment_service.UpdateAvailabilityRequest.displayName = 'proto.appointment_service.UpdateAvailabilityRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointment_service.UpdateAvailabilityResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointment_service.UpdateAvailabilityResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointment_service.UpdateAvailabilityResponse.displayName = 'proto.appointment_service.UpdateAvailabilityResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointment_service.DeleteAvailabilityRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointment_service.DeleteAvailabilityRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointment_service.DeleteAvailabilityRequest.displayName = 'proto.appointment_service.DeleteAvailabilityRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointment_service.DeleteAvailabilityResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointment_service.DeleteAvailabilityResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointment_service.DeleteAvailabilityResponse.displayName = 'proto.appointment_service.DeleteAvailabilityResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointment_service.GetAvailabilitySlotsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointment_service.GetAvailabilitySlotsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointment_service.GetAvailabilitySlotsRequest.displayName = 'proto.appointment_service.GetAvailabilitySlotsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointment_service.GetAvailabilitySlotsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.appointment_service.GetAvailabilitySlotsResponse.repeatedFields_, null);
};
goog.inherits(proto.appointment_service.GetAvailabilitySlotsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointment_service.GetAvailabilitySlotsResponse.displayName = 'proto.appointment_service.GetAvailabilitySlotsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointment_service.GetAvailabilityRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointment_service.GetAvailabilityRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointment_service.GetAvailabilityRequest.displayName = 'proto.appointment_service.GetAvailabilityRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointment_service.GetAvailabilityResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.appointment_service.GetAvailabilityResponse.repeatedFields_, null);
};
goog.inherits(proto.appointment_service.GetAvailabilityResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointment_service.GetAvailabilityResponse.displayName = 'proto.appointment_service.GetAvailabilityResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointment_service.CreateAppointmentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointment_service.CreateAppointmentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointment_service.CreateAppointmentRequest.displayName = 'proto.appointment_service.CreateAppointmentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointment_service.CreateAppointmentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointment_service.CreateAppointmentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointment_service.CreateAppointmentResponse.displayName = 'proto.appointment_service.CreateAppointmentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointment_service.GetAppointmentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointment_service.GetAppointmentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointment_service.GetAppointmentRequest.displayName = 'proto.appointment_service.GetAppointmentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointment_service.GetAppointmentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointment_service.GetAppointmentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointment_service.GetAppointmentResponse.displayName = 'proto.appointment_service.GetAppointmentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointment_service.ListAppointmentsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointment_service.ListAppointmentsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointment_service.ListAppointmentsRequest.displayName = 'proto.appointment_service.ListAppointmentsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointment_service.ListAppointmentsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.appointment_service.ListAppointmentsResponse.repeatedFields_, null);
};
goog.inherits(proto.appointment_service.ListAppointmentsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointment_service.ListAppointmentsResponse.displayName = 'proto.appointment_service.ListAppointmentsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointment_service.UpdateAppointmentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointment_service.UpdateAppointmentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointment_service.UpdateAppointmentRequest.displayName = 'proto.appointment_service.UpdateAppointmentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointment_service.UpdateAppointmentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointment_service.UpdateAppointmentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointment_service.UpdateAppointmentResponse.displayName = 'proto.appointment_service.UpdateAppointmentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointment_service.CancelAppointmentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointment_service.CancelAppointmentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointment_service.CancelAppointmentRequest.displayName = 'proto.appointment_service.CancelAppointmentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointment_service.CancelAppointmentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointment_service.CancelAppointmentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointment_service.CancelAppointmentResponse.displayName = 'proto.appointment_service.CancelAppointmentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointment_service.Area = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointment_service.Area, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointment_service.Area.displayName = 'proto.appointment_service.Area';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointment_service.Location = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointment_service.Location, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointment_service.Location.displayName = 'proto.appointment_service.Location';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointment_service.Calendar = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.appointment_service.Calendar.repeatedFields_, null);
};
goog.inherits(proto.appointment_service.Calendar, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointment_service.Calendar.displayName = 'proto.appointment_service.Calendar';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointment_service.ListAppointmentTypeConfigsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointment_service.ListAppointmentTypeConfigsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointment_service.ListAppointmentTypeConfigsRequest.displayName = 'proto.appointment_service.ListAppointmentTypeConfigsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointment_service.ListAppointmentTypeConfigsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.appointment_service.ListAppointmentTypeConfigsResponse.repeatedFields_, null);
};
goog.inherits(proto.appointment_service.ListAppointmentTypeConfigsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointment_service.ListAppointmentTypeConfigsResponse.displayName = 'proto.appointment_service.ListAppointmentTypeConfigsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointment_service.SetAppointmentTypeConfigsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.appointment_service.SetAppointmentTypeConfigsRequest.repeatedFields_, null);
};
goog.inherits(proto.appointment_service.SetAppointmentTypeConfigsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointment_service.SetAppointmentTypeConfigsRequest.displayName = 'proto.appointment_service.SetAppointmentTypeConfigsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointment_service.SetAppointmentTypeConfigsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.appointment_service.SetAppointmentTypeConfigsResponse.repeatedFields_, null);
};
goog.inherits(proto.appointment_service.SetAppointmentTypeConfigsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointment_service.SetAppointmentTypeConfigsResponse.displayName = 'proto.appointment_service.SetAppointmentTypeConfigsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointment_service.GetAppointmentCheckoutStatusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointment_service.GetAppointmentCheckoutStatusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointment_service.GetAppointmentCheckoutStatusRequest.displayName = 'proto.appointment_service.GetAppointmentCheckoutStatusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointment_service.GetAppointmentCheckoutStatusResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointment_service.GetAppointmentCheckoutStatusResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointment_service.GetAppointmentCheckoutStatusResponse.displayName = 'proto.appointment_service.GetAppointmentCheckoutStatusResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointment_service.AppointmentKeyHandOffRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointment_service.AppointmentKeyHandOffRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointment_service.AppointmentKeyHandOffRequest.displayName = 'proto.appointment_service.AppointmentKeyHandOffRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointment_service.AppointmentKeyHandOffResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointment_service.AppointmentKeyHandOffResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointment_service.AppointmentKeyHandOffResponse.displayName = 'proto.appointment_service.AppointmentKeyHandOffResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointment_service.GetAppointmentKeyHandOffRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointment_service.GetAppointmentKeyHandOffRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointment_service.GetAppointmentKeyHandOffRequest.displayName = 'proto.appointment_service.GetAppointmentKeyHandOffRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointment_service.GetAppointmentKeyHandOffResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointment_service.GetAppointmentKeyHandOffResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointment_service.GetAppointmentKeyHandOffResponse.displayName = 'proto.appointment_service.GetAppointmentKeyHandOffResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointment_service.GetAppointmentPickupDateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointment_service.GetAppointmentPickupDateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointment_service.GetAppointmentPickupDateRequest.displayName = 'proto.appointment_service.GetAppointmentPickupDateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointment_service.GetAppointmentPickupDateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointment_service.GetAppointmentPickupDateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointment_service.GetAppointmentPickupDateResponse.displayName = 'proto.appointment_service.GetAppointmentPickupDateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointment_service.ListCompletedAppointmentsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointment_service.ListCompletedAppointmentsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointment_service.ListCompletedAppointmentsRequest.displayName = 'proto.appointment_service.ListCompletedAppointmentsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointment_service.ListCompletedAppointmentsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.appointment_service.ListCompletedAppointmentsResponse.repeatedFields_, null);
};
goog.inherits(proto.appointment_service.ListCompletedAppointmentsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointment_service.ListCompletedAppointmentsResponse.displayName = 'proto.appointment_service.ListCompletedAppointmentsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointment_service.GetPreAppointmentCheckoutStatusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointment_service.GetPreAppointmentCheckoutStatusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointment_service.GetPreAppointmentCheckoutStatusRequest.displayName = 'proto.appointment_service.GetPreAppointmentCheckoutStatusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointment_service.GetPreAppointmentCheckoutStatusResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointment_service.GetPreAppointmentCheckoutStatusResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointment_service.GetPreAppointmentCheckoutStatusResponse.displayName = 'proto.appointment_service.GetPreAppointmentCheckoutStatusResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointment_service.CreateAvailabilityRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.appointment_service.CreateAvailabilityRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointment_service.CreateAvailabilityRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment_service.CreateAvailabilityRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    availableTime: (f = msg.getAvailableTime()) && appointment_pb.AvailableTime.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointment_service.CreateAvailabilityRequest}
 */
proto.appointment_service.CreateAvailabilityRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointment_service.CreateAvailabilityRequest;
  return proto.appointment_service.CreateAvailabilityRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointment_service.CreateAvailabilityRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointment_service.CreateAvailabilityRequest}
 */
proto.appointment_service.CreateAvailabilityRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new appointment_pb.AvailableTime;
      reader.readMessage(value,appointment_pb.AvailableTime.deserializeBinaryFromReader);
      msg.setAvailableTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointment_service.CreateAvailabilityRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointment_service.CreateAvailabilityRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointment_service.CreateAvailabilityRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment_service.CreateAvailabilityRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAvailableTime();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      appointment_pb.AvailableTime.serializeBinaryToWriter
    );
  }
};


/**
 * optional appointment.AvailableTime available_time = 1;
 * @return {?proto.appointment.AvailableTime}
 */
proto.appointment_service.CreateAvailabilityRequest.prototype.getAvailableTime = function() {
  return /** @type{?proto.appointment.AvailableTime} */ (
    jspb.Message.getWrapperField(this, appointment_pb.AvailableTime, 1));
};


/**
 * @param {?proto.appointment.AvailableTime|undefined} value
 * @return {!proto.appointment_service.CreateAvailabilityRequest} returns this
*/
proto.appointment_service.CreateAvailabilityRequest.prototype.setAvailableTime = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.appointment_service.CreateAvailabilityRequest} returns this
 */
proto.appointment_service.CreateAvailabilityRequest.prototype.clearAvailableTime = function() {
  return this.setAvailableTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.appointment_service.CreateAvailabilityRequest.prototype.hasAvailableTime = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointment_service.CreateAvailabilityResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.appointment_service.CreateAvailabilityResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointment_service.CreateAvailabilityResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment_service.CreateAvailabilityResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    availableTime: (f = msg.getAvailableTime()) && appointment_pb.AvailableTime.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointment_service.CreateAvailabilityResponse}
 */
proto.appointment_service.CreateAvailabilityResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointment_service.CreateAvailabilityResponse;
  return proto.appointment_service.CreateAvailabilityResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointment_service.CreateAvailabilityResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointment_service.CreateAvailabilityResponse}
 */
proto.appointment_service.CreateAvailabilityResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new appointment_pb.AvailableTime;
      reader.readMessage(value,appointment_pb.AvailableTime.deserializeBinaryFromReader);
      msg.setAvailableTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointment_service.CreateAvailabilityResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointment_service.CreateAvailabilityResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointment_service.CreateAvailabilityResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment_service.CreateAvailabilityResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAvailableTime();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      appointment_pb.AvailableTime.serializeBinaryToWriter
    );
  }
};


/**
 * optional appointment.AvailableTime available_time = 1;
 * @return {?proto.appointment.AvailableTime}
 */
proto.appointment_service.CreateAvailabilityResponse.prototype.getAvailableTime = function() {
  return /** @type{?proto.appointment.AvailableTime} */ (
    jspb.Message.getWrapperField(this, appointment_pb.AvailableTime, 1));
};


/**
 * @param {?proto.appointment.AvailableTime|undefined} value
 * @return {!proto.appointment_service.CreateAvailabilityResponse} returns this
*/
proto.appointment_service.CreateAvailabilityResponse.prototype.setAvailableTime = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.appointment_service.CreateAvailabilityResponse} returns this
 */
proto.appointment_service.CreateAvailabilityResponse.prototype.clearAvailableTime = function() {
  return this.setAvailableTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.appointment_service.CreateAvailabilityResponse.prototype.hasAvailableTime = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointment_service.UpdateAvailabilityRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.appointment_service.UpdateAvailabilityRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointment_service.UpdateAvailabilityRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment_service.UpdateAvailabilityRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointment_service.UpdateAvailabilityRequest}
 */
proto.appointment_service.UpdateAvailabilityRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointment_service.UpdateAvailabilityRequest;
  return proto.appointment_service.UpdateAvailabilityRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointment_service.UpdateAvailabilityRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointment_service.UpdateAvailabilityRequest}
 */
proto.appointment_service.UpdateAvailabilityRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointment_service.UpdateAvailabilityRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointment_service.UpdateAvailabilityRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointment_service.UpdateAvailabilityRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment_service.UpdateAvailabilityRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointment_service.UpdateAvailabilityResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.appointment_service.UpdateAvailabilityResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointment_service.UpdateAvailabilityResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment_service.UpdateAvailabilityResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointment_service.UpdateAvailabilityResponse}
 */
proto.appointment_service.UpdateAvailabilityResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointment_service.UpdateAvailabilityResponse;
  return proto.appointment_service.UpdateAvailabilityResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointment_service.UpdateAvailabilityResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointment_service.UpdateAvailabilityResponse}
 */
proto.appointment_service.UpdateAvailabilityResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointment_service.UpdateAvailabilityResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointment_service.UpdateAvailabilityResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointment_service.UpdateAvailabilityResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment_service.UpdateAvailabilityResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointment_service.DeleteAvailabilityRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.appointment_service.DeleteAvailabilityRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointment_service.DeleteAvailabilityRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment_service.DeleteAvailabilityRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointment_service.DeleteAvailabilityRequest}
 */
proto.appointment_service.DeleteAvailabilityRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointment_service.DeleteAvailabilityRequest;
  return proto.appointment_service.DeleteAvailabilityRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointment_service.DeleteAvailabilityRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointment_service.DeleteAvailabilityRequest}
 */
proto.appointment_service.DeleteAvailabilityRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointment_service.DeleteAvailabilityRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointment_service.DeleteAvailabilityRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointment_service.DeleteAvailabilityRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment_service.DeleteAvailabilityRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointment_service.DeleteAvailabilityResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.appointment_service.DeleteAvailabilityResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointment_service.DeleteAvailabilityResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment_service.DeleteAvailabilityResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointment_service.DeleteAvailabilityResponse}
 */
proto.appointment_service.DeleteAvailabilityResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointment_service.DeleteAvailabilityResponse;
  return proto.appointment_service.DeleteAvailabilityResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointment_service.DeleteAvailabilityResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointment_service.DeleteAvailabilityResponse}
 */
proto.appointment_service.DeleteAvailabilityResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointment_service.DeleteAvailabilityResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointment_service.DeleteAvailabilityResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointment_service.DeleteAvailabilityResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment_service.DeleteAvailabilityResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointment_service.GetAvailabilitySlotsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.appointment_service.GetAvailabilitySlotsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointment_service.GetAvailabilitySlotsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment_service.GetAvailabilitySlotsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    startTime: jspb.Message.getFieldWithDefault(msg, 2, 0),
    endTime: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointment_service.GetAvailabilitySlotsRequest}
 */
proto.appointment_service.GetAvailabilitySlotsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointment_service.GetAvailabilitySlotsRequest;
  return proto.appointment_service.GetAvailabilitySlotsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointment_service.GetAvailabilitySlotsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointment_service.GetAvailabilitySlotsRequest}
 */
proto.appointment_service.GetAvailabilitySlotsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealerId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartTime(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointment_service.GetAvailabilitySlotsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointment_service.GetAvailabilitySlotsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointment_service.GetAvailabilitySlotsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment_service.GetAvailabilitySlotsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStartTime();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getEndTime();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional string dealer_id = 1;
 * @return {string}
 */
proto.appointment_service.GetAvailabilitySlotsRequest.prototype.getDealerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointment_service.GetAvailabilitySlotsRequest} returns this
 */
proto.appointment_service.GetAvailabilitySlotsRequest.prototype.setDealerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 start_time = 2;
 * @return {number}
 */
proto.appointment_service.GetAvailabilitySlotsRequest.prototype.getStartTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.appointment_service.GetAvailabilitySlotsRequest} returns this
 */
proto.appointment_service.GetAvailabilitySlotsRequest.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 end_time = 3;
 * @return {number}
 */
proto.appointment_service.GetAvailabilitySlotsRequest.prototype.getEndTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.appointment_service.GetAvailabilitySlotsRequest} returns this
 */
proto.appointment_service.GetAvailabilitySlotsRequest.prototype.setEndTime = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.appointment_service.GetAvailabilitySlotsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointment_service.GetAvailabilitySlotsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.appointment_service.GetAvailabilitySlotsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointment_service.GetAvailabilitySlotsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment_service.GetAvailabilitySlotsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    availabilityList: jspb.Message.toObjectList(msg.getAvailabilityList(),
    appointment_pb.Availability.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointment_service.GetAvailabilitySlotsResponse}
 */
proto.appointment_service.GetAvailabilitySlotsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointment_service.GetAvailabilitySlotsResponse;
  return proto.appointment_service.GetAvailabilitySlotsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointment_service.GetAvailabilitySlotsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointment_service.GetAvailabilitySlotsResponse}
 */
proto.appointment_service.GetAvailabilitySlotsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new appointment_pb.Availability;
      reader.readMessage(value,appointment_pb.Availability.deserializeBinaryFromReader);
      msg.addAvailability(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointment_service.GetAvailabilitySlotsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointment_service.GetAvailabilitySlotsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointment_service.GetAvailabilitySlotsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment_service.GetAvailabilitySlotsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAvailabilityList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      appointment_pb.Availability.serializeBinaryToWriter
    );
  }
};


/**
 * repeated appointment.Availability availability = 1;
 * @return {!Array<!proto.appointment.Availability>}
 */
proto.appointment_service.GetAvailabilitySlotsResponse.prototype.getAvailabilityList = function() {
  return /** @type{!Array<!proto.appointment.Availability>} */ (
    jspb.Message.getRepeatedWrapperField(this, appointment_pb.Availability, 1));
};


/**
 * @param {!Array<!proto.appointment.Availability>} value
 * @return {!proto.appointment_service.GetAvailabilitySlotsResponse} returns this
*/
proto.appointment_service.GetAvailabilitySlotsResponse.prototype.setAvailabilityList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.appointment.Availability=} opt_value
 * @param {number=} opt_index
 * @return {!proto.appointment.Availability}
 */
proto.appointment_service.GetAvailabilitySlotsResponse.prototype.addAvailability = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.appointment.Availability, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.appointment_service.GetAvailabilitySlotsResponse} returns this
 */
proto.appointment_service.GetAvailabilitySlotsResponse.prototype.clearAvailabilityList = function() {
  return this.setAvailabilityList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointment_service.GetAvailabilityRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.appointment_service.GetAvailabilityRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointment_service.GetAvailabilityRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment_service.GetAvailabilityRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealerId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointment_service.GetAvailabilityRequest}
 */
proto.appointment_service.GetAvailabilityRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointment_service.GetAvailabilityRequest;
  return proto.appointment_service.GetAvailabilityRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointment_service.GetAvailabilityRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointment_service.GetAvailabilityRequest}
 */
proto.appointment_service.GetAvailabilityRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointment_service.GetAvailabilityRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointment_service.GetAvailabilityRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointment_service.GetAvailabilityRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment_service.GetAvailabilityRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string dealer_id = 1;
 * @return {string}
 */
proto.appointment_service.GetAvailabilityRequest.prototype.getDealerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointment_service.GetAvailabilityRequest} returns this
 */
proto.appointment_service.GetAvailabilityRequest.prototype.setDealerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.appointment_service.GetAvailabilityResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointment_service.GetAvailabilityResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.appointment_service.GetAvailabilityResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointment_service.GetAvailabilityResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment_service.GetAvailabilityResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    availableTimesList: jspb.Message.toObjectList(msg.getAvailableTimesList(),
    appointment_pb.AvailableTime.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointment_service.GetAvailabilityResponse}
 */
proto.appointment_service.GetAvailabilityResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointment_service.GetAvailabilityResponse;
  return proto.appointment_service.GetAvailabilityResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointment_service.GetAvailabilityResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointment_service.GetAvailabilityResponse}
 */
proto.appointment_service.GetAvailabilityResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new appointment_pb.AvailableTime;
      reader.readMessage(value,appointment_pb.AvailableTime.deserializeBinaryFromReader);
      msg.addAvailableTimes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointment_service.GetAvailabilityResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointment_service.GetAvailabilityResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointment_service.GetAvailabilityResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment_service.GetAvailabilityResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAvailableTimesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      appointment_pb.AvailableTime.serializeBinaryToWriter
    );
  }
};


/**
 * repeated appointment.AvailableTime available_times = 1;
 * @return {!Array<!proto.appointment.AvailableTime>}
 */
proto.appointment_service.GetAvailabilityResponse.prototype.getAvailableTimesList = function() {
  return /** @type{!Array<!proto.appointment.AvailableTime>} */ (
    jspb.Message.getRepeatedWrapperField(this, appointment_pb.AvailableTime, 1));
};


/**
 * @param {!Array<!proto.appointment.AvailableTime>} value
 * @return {!proto.appointment_service.GetAvailabilityResponse} returns this
*/
proto.appointment_service.GetAvailabilityResponse.prototype.setAvailableTimesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.appointment.AvailableTime=} opt_value
 * @param {number=} opt_index
 * @return {!proto.appointment.AvailableTime}
 */
proto.appointment_service.GetAvailabilityResponse.prototype.addAvailableTimes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.appointment.AvailableTime, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.appointment_service.GetAvailabilityResponse} returns this
 */
proto.appointment_service.GetAvailabilityResponse.prototype.clearAvailableTimesList = function() {
  return this.setAvailableTimesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointment_service.CreateAppointmentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.appointment_service.CreateAppointmentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointment_service.CreateAppointmentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment_service.CreateAppointmentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    employeeId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    customerId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    startTime: jspb.Message.getFieldWithDefault(msg, 4, 0),
    duration: jspb.Message.getFieldWithDefault(msg, 5, 0),
    appointmentType: jspb.Message.getFieldWithDefault(msg, 6, 0),
    timeZone: jspb.Message.getFieldWithDefault(msg, 7, ""),
    note: jspb.Message.getFieldWithDefault(msg, 8, ""),
    noteAuthor: jspb.Message.getFieldWithDefault(msg, 9, ""),
    reservationId: jspb.Message.getFieldWithDefault(msg, 10, ""),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 11, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointment_service.CreateAppointmentRequest}
 */
proto.appointment_service.CreateAppointmentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointment_service.CreateAppointmentRequest;
  return proto.appointment_service.CreateAppointmentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointment_service.CreateAppointmentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointment_service.CreateAppointmentRequest}
 */
proto.appointment_service.CreateAppointmentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmployeeId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartTime(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDuration(value);
      break;
    case 6:
      var value = /** @type {!proto.appointment.AppointmentType} */ (reader.readEnum());
      msg.setAppointmentType(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimeZone(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setNoteAuthor(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setReservationId(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointment_service.CreateAppointmentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointment_service.CreateAppointmentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointment_service.CreateAppointmentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment_service.CreateAppointmentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEmployeeId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStartTime();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getDuration();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getAppointmentType();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getTimeZone();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getNote();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getNoteAuthor();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getReservationId();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
};


/**
 * optional string dealer_id = 1;
 * @return {string}
 */
proto.appointment_service.CreateAppointmentRequest.prototype.getDealerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointment_service.CreateAppointmentRequest} returns this
 */
proto.appointment_service.CreateAppointmentRequest.prototype.setDealerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string employee_id = 2;
 * @return {string}
 */
proto.appointment_service.CreateAppointmentRequest.prototype.getEmployeeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointment_service.CreateAppointmentRequest} returns this
 */
proto.appointment_service.CreateAppointmentRequest.prototype.setEmployeeId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string customer_id = 3;
 * @return {string}
 */
proto.appointment_service.CreateAppointmentRequest.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointment_service.CreateAppointmentRequest} returns this
 */
proto.appointment_service.CreateAppointmentRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 start_time = 4;
 * @return {number}
 */
proto.appointment_service.CreateAppointmentRequest.prototype.getStartTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.appointment_service.CreateAppointmentRequest} returns this
 */
proto.appointment_service.CreateAppointmentRequest.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 duration = 5;
 * @return {number}
 */
proto.appointment_service.CreateAppointmentRequest.prototype.getDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.appointment_service.CreateAppointmentRequest} returns this
 */
proto.appointment_service.CreateAppointmentRequest.prototype.setDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional appointment.AppointmentType appointment_type = 6;
 * @return {!proto.appointment.AppointmentType}
 */
proto.appointment_service.CreateAppointmentRequest.prototype.getAppointmentType = function() {
  return /** @type {!proto.appointment.AppointmentType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.appointment.AppointmentType} value
 * @return {!proto.appointment_service.CreateAppointmentRequest} returns this
 */
proto.appointment_service.CreateAppointmentRequest.prototype.setAppointmentType = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional string time_zone = 7;
 * @return {string}
 */
proto.appointment_service.CreateAppointmentRequest.prototype.getTimeZone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointment_service.CreateAppointmentRequest} returns this
 */
proto.appointment_service.CreateAppointmentRequest.prototype.setTimeZone = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string note = 8;
 * @return {string}
 */
proto.appointment_service.CreateAppointmentRequest.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointment_service.CreateAppointmentRequest} returns this
 */
proto.appointment_service.CreateAppointmentRequest.prototype.setNote = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string note_author = 9;
 * @return {string}
 */
proto.appointment_service.CreateAppointmentRequest.prototype.getNoteAuthor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointment_service.CreateAppointmentRequest} returns this
 */
proto.appointment_service.CreateAppointmentRequest.prototype.setNoteAuthor = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string reservation_id = 10;
 * @return {string}
 */
proto.appointment_service.CreateAppointmentRequest.prototype.getReservationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointment_service.CreateAppointmentRequest} returns this
 */
proto.appointment_service.CreateAppointmentRequest.prototype.setReservationId = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string subscription_id = 11;
 * @return {string}
 */
proto.appointment_service.CreateAppointmentRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointment_service.CreateAppointmentRequest} returns this
 */
proto.appointment_service.CreateAppointmentRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointment_service.CreateAppointmentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.appointment_service.CreateAppointmentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointment_service.CreateAppointmentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment_service.CreateAppointmentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    appointmentId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    errorMessage: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointment_service.CreateAppointmentResponse}
 */
proto.appointment_service.CreateAppointmentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointment_service.CreateAppointmentResponse;
  return proto.appointment_service.CreateAppointmentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointment_service.CreateAppointmentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointment_service.CreateAppointmentResponse}
 */
proto.appointment_service.CreateAppointmentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppointmentId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointment_service.CreateAppointmentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointment_service.CreateAppointmentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointment_service.CreateAppointmentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment_service.CreateAppointmentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppointmentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getErrorMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string appointment_id = 1;
 * @return {string}
 */
proto.appointment_service.CreateAppointmentResponse.prototype.getAppointmentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointment_service.CreateAppointmentResponse} returns this
 */
proto.appointment_service.CreateAppointmentResponse.prototype.setAppointmentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string error_message = 2;
 * @return {string}
 */
proto.appointment_service.CreateAppointmentResponse.prototype.getErrorMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointment_service.CreateAppointmentResponse} returns this
 */
proto.appointment_service.CreateAppointmentResponse.prototype.setErrorMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointment_service.GetAppointmentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.appointment_service.GetAppointmentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointment_service.GetAppointmentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment_service.GetAppointmentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    appointmentId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointment_service.GetAppointmentRequest}
 */
proto.appointment_service.GetAppointmentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointment_service.GetAppointmentRequest;
  return proto.appointment_service.GetAppointmentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointment_service.GetAppointmentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointment_service.GetAppointmentRequest}
 */
proto.appointment_service.GetAppointmentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppointmentId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointment_service.GetAppointmentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointment_service.GetAppointmentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointment_service.GetAppointmentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment_service.GetAppointmentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppointmentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string appointment_id = 1;
 * @return {string}
 */
proto.appointment_service.GetAppointmentRequest.prototype.getAppointmentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointment_service.GetAppointmentRequest} returns this
 */
proto.appointment_service.GetAppointmentRequest.prototype.setAppointmentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointment_service.GetAppointmentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.appointment_service.GetAppointmentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointment_service.GetAppointmentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment_service.GetAppointmentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    appointment: (f = msg.getAppointment()) && appointment_pb.Appointment.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointment_service.GetAppointmentResponse}
 */
proto.appointment_service.GetAppointmentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointment_service.GetAppointmentResponse;
  return proto.appointment_service.GetAppointmentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointment_service.GetAppointmentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointment_service.GetAppointmentResponse}
 */
proto.appointment_service.GetAppointmentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new appointment_pb.Appointment;
      reader.readMessage(value,appointment_pb.Appointment.deserializeBinaryFromReader);
      msg.setAppointment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointment_service.GetAppointmentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointment_service.GetAppointmentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointment_service.GetAppointmentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment_service.GetAppointmentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppointment();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      appointment_pb.Appointment.serializeBinaryToWriter
    );
  }
};


/**
 * optional appointment.Appointment appointment = 1;
 * @return {?proto.appointment.Appointment}
 */
proto.appointment_service.GetAppointmentResponse.prototype.getAppointment = function() {
  return /** @type{?proto.appointment.Appointment} */ (
    jspb.Message.getWrapperField(this, appointment_pb.Appointment, 1));
};


/**
 * @param {?proto.appointment.Appointment|undefined} value
 * @return {!proto.appointment_service.GetAppointmentResponse} returns this
*/
proto.appointment_service.GetAppointmentResponse.prototype.setAppointment = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.appointment_service.GetAppointmentResponse} returns this
 */
proto.appointment_service.GetAppointmentResponse.prototype.clearAppointment = function() {
  return this.setAppointment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.appointment_service.GetAppointmentResponse.prototype.hasAppointment = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointment_service.ListAppointmentsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.appointment_service.ListAppointmentsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointment_service.ListAppointmentsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment_service.ListAppointmentsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    daysOut: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointment_service.ListAppointmentsRequest}
 */
proto.appointment_service.ListAppointmentsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointment_service.ListAppointmentsRequest;
  return proto.appointment_service.ListAppointmentsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointment_service.ListAppointmentsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointment_service.ListAppointmentsRequest}
 */
proto.appointment_service.ListAppointmentsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealerId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDaysOut(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointment_service.ListAppointmentsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointment_service.ListAppointmentsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointment_service.ListAppointmentsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment_service.ListAppointmentsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDaysOut();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string dealer_id = 1;
 * @return {string}
 */
proto.appointment_service.ListAppointmentsRequest.prototype.getDealerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointment_service.ListAppointmentsRequest} returns this
 */
proto.appointment_service.ListAppointmentsRequest.prototype.setDealerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 days_out = 2;
 * @return {number}
 */
proto.appointment_service.ListAppointmentsRequest.prototype.getDaysOut = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.appointment_service.ListAppointmentsRequest} returns this
 */
proto.appointment_service.ListAppointmentsRequest.prototype.setDaysOut = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.appointment_service.ListAppointmentsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointment_service.ListAppointmentsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.appointment_service.ListAppointmentsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointment_service.ListAppointmentsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment_service.ListAppointmentsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    appointmentsList: jspb.Message.toObjectList(msg.getAppointmentsList(),
    appointment_pb.Appointment.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointment_service.ListAppointmentsResponse}
 */
proto.appointment_service.ListAppointmentsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointment_service.ListAppointmentsResponse;
  return proto.appointment_service.ListAppointmentsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointment_service.ListAppointmentsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointment_service.ListAppointmentsResponse}
 */
proto.appointment_service.ListAppointmentsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new appointment_pb.Appointment;
      reader.readMessage(value,appointment_pb.Appointment.deserializeBinaryFromReader);
      msg.addAppointments(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointment_service.ListAppointmentsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointment_service.ListAppointmentsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointment_service.ListAppointmentsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment_service.ListAppointmentsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppointmentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      appointment_pb.Appointment.serializeBinaryToWriter
    );
  }
};


/**
 * repeated appointment.Appointment appointments = 1;
 * @return {!Array<!proto.appointment.Appointment>}
 */
proto.appointment_service.ListAppointmentsResponse.prototype.getAppointmentsList = function() {
  return /** @type{!Array<!proto.appointment.Appointment>} */ (
    jspb.Message.getRepeatedWrapperField(this, appointment_pb.Appointment, 1));
};


/**
 * @param {!Array<!proto.appointment.Appointment>} value
 * @return {!proto.appointment_service.ListAppointmentsResponse} returns this
*/
proto.appointment_service.ListAppointmentsResponse.prototype.setAppointmentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.appointment.Appointment=} opt_value
 * @param {number=} opt_index
 * @return {!proto.appointment.Appointment}
 */
proto.appointment_service.ListAppointmentsResponse.prototype.addAppointments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.appointment.Appointment, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.appointment_service.ListAppointmentsResponse} returns this
 */
proto.appointment_service.ListAppointmentsResponse.prototype.clearAppointmentsList = function() {
  return this.setAppointmentsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointment_service.UpdateAppointmentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.appointment_service.UpdateAppointmentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointment_service.UpdateAppointmentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment_service.UpdateAppointmentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    appointment: (f = msg.getAppointment()) && appointment_pb.Appointment.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointment_service.UpdateAppointmentRequest}
 */
proto.appointment_service.UpdateAppointmentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointment_service.UpdateAppointmentRequest;
  return proto.appointment_service.UpdateAppointmentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointment_service.UpdateAppointmentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointment_service.UpdateAppointmentRequest}
 */
proto.appointment_service.UpdateAppointmentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new appointment_pb.Appointment;
      reader.readMessage(value,appointment_pb.Appointment.deserializeBinaryFromReader);
      msg.setAppointment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointment_service.UpdateAppointmentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointment_service.UpdateAppointmentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointment_service.UpdateAppointmentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment_service.UpdateAppointmentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppointment();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      appointment_pb.Appointment.serializeBinaryToWriter
    );
  }
};


/**
 * optional appointment.Appointment appointment = 1;
 * @return {?proto.appointment.Appointment}
 */
proto.appointment_service.UpdateAppointmentRequest.prototype.getAppointment = function() {
  return /** @type{?proto.appointment.Appointment} */ (
    jspb.Message.getWrapperField(this, appointment_pb.Appointment, 1));
};


/**
 * @param {?proto.appointment.Appointment|undefined} value
 * @return {!proto.appointment_service.UpdateAppointmentRequest} returns this
*/
proto.appointment_service.UpdateAppointmentRequest.prototype.setAppointment = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.appointment_service.UpdateAppointmentRequest} returns this
 */
proto.appointment_service.UpdateAppointmentRequest.prototype.clearAppointment = function() {
  return this.setAppointment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.appointment_service.UpdateAppointmentRequest.prototype.hasAppointment = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointment_service.UpdateAppointmentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.appointment_service.UpdateAppointmentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointment_service.UpdateAppointmentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment_service.UpdateAppointmentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    appointmentId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointment_service.UpdateAppointmentResponse}
 */
proto.appointment_service.UpdateAppointmentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointment_service.UpdateAppointmentResponse;
  return proto.appointment_service.UpdateAppointmentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointment_service.UpdateAppointmentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointment_service.UpdateAppointmentResponse}
 */
proto.appointment_service.UpdateAppointmentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppointmentId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointment_service.UpdateAppointmentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointment_service.UpdateAppointmentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointment_service.UpdateAppointmentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment_service.UpdateAppointmentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppointmentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string appointment_id = 1;
 * @return {string}
 */
proto.appointment_service.UpdateAppointmentResponse.prototype.getAppointmentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointment_service.UpdateAppointmentResponse} returns this
 */
proto.appointment_service.UpdateAppointmentResponse.prototype.setAppointmentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointment_service.CancelAppointmentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.appointment_service.CancelAppointmentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointment_service.CancelAppointmentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment_service.CancelAppointmentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    appointmentId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointment_service.CancelAppointmentRequest}
 */
proto.appointment_service.CancelAppointmentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointment_service.CancelAppointmentRequest;
  return proto.appointment_service.CancelAppointmentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointment_service.CancelAppointmentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointment_service.CancelAppointmentRequest}
 */
proto.appointment_service.CancelAppointmentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppointmentId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointment_service.CancelAppointmentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointment_service.CancelAppointmentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointment_service.CancelAppointmentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment_service.CancelAppointmentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppointmentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string appointment_id = 1;
 * @return {string}
 */
proto.appointment_service.CancelAppointmentRequest.prototype.getAppointmentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointment_service.CancelAppointmentRequest} returns this
 */
proto.appointment_service.CancelAppointmentRequest.prototype.setAppointmentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointment_service.CancelAppointmentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.appointment_service.CancelAppointmentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointment_service.CancelAppointmentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment_service.CancelAppointmentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointment_service.CancelAppointmentResponse}
 */
proto.appointment_service.CancelAppointmentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointment_service.CancelAppointmentResponse;
  return proto.appointment_service.CancelAppointmentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointment_service.CancelAppointmentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointment_service.CancelAppointmentResponse}
 */
proto.appointment_service.CancelAppointmentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointment_service.CancelAppointmentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointment_service.CancelAppointmentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointment_service.CancelAppointmentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment_service.CancelAppointmentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointment_service.Area.prototype.toObject = function(opt_includeInstance) {
  return proto.appointment_service.Area.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointment_service.Area} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment_service.Area.toObject = function(includeInstance, msg) {
  var f, obj = {
    zipCode: jspb.Message.getFieldWithDefault(msg, 1, 0),
    radius: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointment_service.Area}
 */
proto.appointment_service.Area.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointment_service.Area;
  return proto.appointment_service.Area.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointment_service.Area} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointment_service.Area}
 */
proto.appointment_service.Area.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setZipCode(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setRadius(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointment_service.Area.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointment_service.Area.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointment_service.Area} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment_service.Area.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getZipCode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getRadius();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
};


/**
 * optional int32 zip_code = 1;
 * @return {number}
 */
proto.appointment_service.Area.prototype.getZipCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.appointment_service.Area} returns this
 */
proto.appointment_service.Area.prototype.setZipCode = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional float radius = 2;
 * @return {number}
 */
proto.appointment_service.Area.prototype.getRadius = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.appointment_service.Area} returns this
 */
proto.appointment_service.Area.prototype.setRadius = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointment_service.Location.prototype.toObject = function(opt_includeInstance) {
  return proto.appointment_service.Location.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointment_service.Location} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment_service.Location.toObject = function(includeInstance, msg) {
  var f, obj = {
    address: (f = msg.getAddress()) && domain_pb.Address.toObject(includeInstance, f),
    lat: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    pb_long: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointment_service.Location}
 */
proto.appointment_service.Location.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointment_service.Location;
  return proto.appointment_service.Location.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointment_service.Location} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointment_service.Location}
 */
proto.appointment_service.Location.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new domain_pb.Address;
      reader.readMessage(value,domain_pb.Address.deserializeBinaryFromReader);
      msg.setAddress(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLat(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLong(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointment_service.Location.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointment_service.Location.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointment_service.Location} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment_service.Location.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddress();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      domain_pb.Address.serializeBinaryToWriter
    );
  }
  f = message.getLat();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getLong();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
};


/**
 * optional domain.Address address = 1;
 * @return {?proto.domain.Address}
 */
proto.appointment_service.Location.prototype.getAddress = function() {
  return /** @type{?proto.domain.Address} */ (
    jspb.Message.getWrapperField(this, domain_pb.Address, 1));
};


/**
 * @param {?proto.domain.Address|undefined} value
 * @return {!proto.appointment_service.Location} returns this
*/
proto.appointment_service.Location.prototype.setAddress = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.appointment_service.Location} returns this
 */
proto.appointment_service.Location.prototype.clearAddress = function() {
  return this.setAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.appointment_service.Location.prototype.hasAddress = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional float lat = 2;
 * @return {number}
 */
proto.appointment_service.Location.prototype.getLat = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.appointment_service.Location} returns this
 */
proto.appointment_service.Location.prototype.setLat = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float long = 3;
 * @return {number}
 */
proto.appointment_service.Location.prototype.getLong = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.appointment_service.Location} returns this
 */
proto.appointment_service.Location.prototype.setLong = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.appointment_service.Calendar.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointment_service.Calendar.prototype.toObject = function(opt_includeInstance) {
  return proto.appointment_service.Calendar.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointment_service.Calendar} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment_service.Calendar.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    availabilityList: jspb.Message.toObjectList(msg.getAvailabilityList(),
    appointment_pb.Availability.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointment_service.Calendar}
 */
proto.appointment_service.Calendar.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointment_service.Calendar;
  return proto.appointment_service.Calendar.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointment_service.Calendar} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointment_service.Calendar}
 */
proto.appointment_service.Calendar.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new appointment_pb.Availability;
      reader.readMessage(value,appointment_pb.Availability.deserializeBinaryFromReader);
      msg.addAvailability(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointment_service.Calendar.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointment_service.Calendar.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointment_service.Calendar} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment_service.Calendar.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAvailabilityList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      appointment_pb.Availability.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.appointment_service.Calendar.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointment_service.Calendar} returns this
 */
proto.appointment_service.Calendar.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated appointment.Availability availability = 2;
 * @return {!Array<!proto.appointment.Availability>}
 */
proto.appointment_service.Calendar.prototype.getAvailabilityList = function() {
  return /** @type{!Array<!proto.appointment.Availability>} */ (
    jspb.Message.getRepeatedWrapperField(this, appointment_pb.Availability, 2));
};


/**
 * @param {!Array<!proto.appointment.Availability>} value
 * @return {!proto.appointment_service.Calendar} returns this
*/
proto.appointment_service.Calendar.prototype.setAvailabilityList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.appointment.Availability=} opt_value
 * @param {number=} opt_index
 * @return {!proto.appointment.Availability}
 */
proto.appointment_service.Calendar.prototype.addAvailability = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.appointment.Availability, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.appointment_service.Calendar} returns this
 */
proto.appointment_service.Calendar.prototype.clearAvailabilityList = function() {
  return this.setAvailabilityList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointment_service.ListAppointmentTypeConfigsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.appointment_service.ListAppointmentTypeConfigsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointment_service.ListAppointmentTypeConfigsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment_service.ListAppointmentTypeConfigsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointment_service.ListAppointmentTypeConfigsRequest}
 */
proto.appointment_service.ListAppointmentTypeConfigsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointment_service.ListAppointmentTypeConfigsRequest;
  return proto.appointment_service.ListAppointmentTypeConfigsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointment_service.ListAppointmentTypeConfigsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointment_service.ListAppointmentTypeConfigsRequest}
 */
proto.appointment_service.ListAppointmentTypeConfigsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointment_service.ListAppointmentTypeConfigsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointment_service.ListAppointmentTypeConfigsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointment_service.ListAppointmentTypeConfigsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment_service.ListAppointmentTypeConfigsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.appointment_service.ListAppointmentTypeConfigsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointment_service.ListAppointmentTypeConfigsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.appointment_service.ListAppointmentTypeConfigsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointment_service.ListAppointmentTypeConfigsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment_service.ListAppointmentTypeConfigsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    appointmentTypeConfigsList: jspb.Message.toObjectList(msg.getAppointmentTypeConfigsList(),
    appointment_pb.AppointmentTypeConfig.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointment_service.ListAppointmentTypeConfigsResponse}
 */
proto.appointment_service.ListAppointmentTypeConfigsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointment_service.ListAppointmentTypeConfigsResponse;
  return proto.appointment_service.ListAppointmentTypeConfigsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointment_service.ListAppointmentTypeConfigsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointment_service.ListAppointmentTypeConfigsResponse}
 */
proto.appointment_service.ListAppointmentTypeConfigsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new appointment_pb.AppointmentTypeConfig;
      reader.readMessage(value,appointment_pb.AppointmentTypeConfig.deserializeBinaryFromReader);
      msg.addAppointmentTypeConfigs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointment_service.ListAppointmentTypeConfigsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointment_service.ListAppointmentTypeConfigsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointment_service.ListAppointmentTypeConfigsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment_service.ListAppointmentTypeConfigsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppointmentTypeConfigsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      appointment_pb.AppointmentTypeConfig.serializeBinaryToWriter
    );
  }
};


/**
 * repeated appointment.AppointmentTypeConfig appointment_type_configs = 1;
 * @return {!Array<!proto.appointment.AppointmentTypeConfig>}
 */
proto.appointment_service.ListAppointmentTypeConfigsResponse.prototype.getAppointmentTypeConfigsList = function() {
  return /** @type{!Array<!proto.appointment.AppointmentTypeConfig>} */ (
    jspb.Message.getRepeatedWrapperField(this, appointment_pb.AppointmentTypeConfig, 1));
};


/**
 * @param {!Array<!proto.appointment.AppointmentTypeConfig>} value
 * @return {!proto.appointment_service.ListAppointmentTypeConfigsResponse} returns this
*/
proto.appointment_service.ListAppointmentTypeConfigsResponse.prototype.setAppointmentTypeConfigsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.appointment.AppointmentTypeConfig=} opt_value
 * @param {number=} opt_index
 * @return {!proto.appointment.AppointmentTypeConfig}
 */
proto.appointment_service.ListAppointmentTypeConfigsResponse.prototype.addAppointmentTypeConfigs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.appointment.AppointmentTypeConfig, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.appointment_service.ListAppointmentTypeConfigsResponse} returns this
 */
proto.appointment_service.ListAppointmentTypeConfigsResponse.prototype.clearAppointmentTypeConfigsList = function() {
  return this.setAppointmentTypeConfigsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.appointment_service.SetAppointmentTypeConfigsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointment_service.SetAppointmentTypeConfigsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.appointment_service.SetAppointmentTypeConfigsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointment_service.SetAppointmentTypeConfigsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment_service.SetAppointmentTypeConfigsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    appointmentTypeConfigsList: jspb.Message.toObjectList(msg.getAppointmentTypeConfigsList(),
    appointment_pb.AppointmentTypeConfig.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointment_service.SetAppointmentTypeConfigsRequest}
 */
proto.appointment_service.SetAppointmentTypeConfigsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointment_service.SetAppointmentTypeConfigsRequest;
  return proto.appointment_service.SetAppointmentTypeConfigsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointment_service.SetAppointmentTypeConfigsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointment_service.SetAppointmentTypeConfigsRequest}
 */
proto.appointment_service.SetAppointmentTypeConfigsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new appointment_pb.AppointmentTypeConfig;
      reader.readMessage(value,appointment_pb.AppointmentTypeConfig.deserializeBinaryFromReader);
      msg.addAppointmentTypeConfigs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointment_service.SetAppointmentTypeConfigsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointment_service.SetAppointmentTypeConfigsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointment_service.SetAppointmentTypeConfigsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment_service.SetAppointmentTypeConfigsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppointmentTypeConfigsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      appointment_pb.AppointmentTypeConfig.serializeBinaryToWriter
    );
  }
};


/**
 * repeated appointment.AppointmentTypeConfig appointment_type_configs = 1;
 * @return {!Array<!proto.appointment.AppointmentTypeConfig>}
 */
proto.appointment_service.SetAppointmentTypeConfigsRequest.prototype.getAppointmentTypeConfigsList = function() {
  return /** @type{!Array<!proto.appointment.AppointmentTypeConfig>} */ (
    jspb.Message.getRepeatedWrapperField(this, appointment_pb.AppointmentTypeConfig, 1));
};


/**
 * @param {!Array<!proto.appointment.AppointmentTypeConfig>} value
 * @return {!proto.appointment_service.SetAppointmentTypeConfigsRequest} returns this
*/
proto.appointment_service.SetAppointmentTypeConfigsRequest.prototype.setAppointmentTypeConfigsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.appointment.AppointmentTypeConfig=} opt_value
 * @param {number=} opt_index
 * @return {!proto.appointment.AppointmentTypeConfig}
 */
proto.appointment_service.SetAppointmentTypeConfigsRequest.prototype.addAppointmentTypeConfigs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.appointment.AppointmentTypeConfig, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.appointment_service.SetAppointmentTypeConfigsRequest} returns this
 */
proto.appointment_service.SetAppointmentTypeConfigsRequest.prototype.clearAppointmentTypeConfigsList = function() {
  return this.setAppointmentTypeConfigsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.appointment_service.SetAppointmentTypeConfigsResponse.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointment_service.SetAppointmentTypeConfigsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.appointment_service.SetAppointmentTypeConfigsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointment_service.SetAppointmentTypeConfigsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment_service.SetAppointmentTypeConfigsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    updatedAppointmentTypeConfigsList: jspb.Message.toObjectList(msg.getUpdatedAppointmentTypeConfigsList(),
    appointment_pb.AppointmentTypeConfig.toObject, includeInstance),
    failedAppointmentTypesList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointment_service.SetAppointmentTypeConfigsResponse}
 */
proto.appointment_service.SetAppointmentTypeConfigsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointment_service.SetAppointmentTypeConfigsResponse;
  return proto.appointment_service.SetAppointmentTypeConfigsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointment_service.SetAppointmentTypeConfigsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointment_service.SetAppointmentTypeConfigsResponse}
 */
proto.appointment_service.SetAppointmentTypeConfigsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new appointment_pb.AppointmentTypeConfig;
      reader.readMessage(value,appointment_pb.AppointmentTypeConfig.deserializeBinaryFromReader);
      msg.addUpdatedAppointmentTypeConfigs(value);
      break;
    case 2:
      var values = /** @type {!Array<!proto.appointment.AppointmentType>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addFailedAppointmentTypes(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointment_service.SetAppointmentTypeConfigsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointment_service.SetAppointmentTypeConfigsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointment_service.SetAppointmentTypeConfigsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment_service.SetAppointmentTypeConfigsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdatedAppointmentTypeConfigsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      appointment_pb.AppointmentTypeConfig.serializeBinaryToWriter
    );
  }
  f = message.getFailedAppointmentTypesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      2,
      f
    );
  }
};


/**
 * repeated appointment.AppointmentTypeConfig updated_appointment_type_configs = 1;
 * @return {!Array<!proto.appointment.AppointmentTypeConfig>}
 */
proto.appointment_service.SetAppointmentTypeConfigsResponse.prototype.getUpdatedAppointmentTypeConfigsList = function() {
  return /** @type{!Array<!proto.appointment.AppointmentTypeConfig>} */ (
    jspb.Message.getRepeatedWrapperField(this, appointment_pb.AppointmentTypeConfig, 1));
};


/**
 * @param {!Array<!proto.appointment.AppointmentTypeConfig>} value
 * @return {!proto.appointment_service.SetAppointmentTypeConfigsResponse} returns this
*/
proto.appointment_service.SetAppointmentTypeConfigsResponse.prototype.setUpdatedAppointmentTypeConfigsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.appointment.AppointmentTypeConfig=} opt_value
 * @param {number=} opt_index
 * @return {!proto.appointment.AppointmentTypeConfig}
 */
proto.appointment_service.SetAppointmentTypeConfigsResponse.prototype.addUpdatedAppointmentTypeConfigs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.appointment.AppointmentTypeConfig, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.appointment_service.SetAppointmentTypeConfigsResponse} returns this
 */
proto.appointment_service.SetAppointmentTypeConfigsResponse.prototype.clearUpdatedAppointmentTypeConfigsList = function() {
  return this.setUpdatedAppointmentTypeConfigsList([]);
};


/**
 * repeated appointment.AppointmentType failed_appointment_types = 2;
 * @return {!Array<!proto.appointment.AppointmentType>}
 */
proto.appointment_service.SetAppointmentTypeConfigsResponse.prototype.getFailedAppointmentTypesList = function() {
  return /** @type {!Array<!proto.appointment.AppointmentType>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<!proto.appointment.AppointmentType>} value
 * @return {!proto.appointment_service.SetAppointmentTypeConfigsResponse} returns this
 */
proto.appointment_service.SetAppointmentTypeConfigsResponse.prototype.setFailedAppointmentTypesList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {!proto.appointment.AppointmentType} value
 * @param {number=} opt_index
 * @return {!proto.appointment_service.SetAppointmentTypeConfigsResponse} returns this
 */
proto.appointment_service.SetAppointmentTypeConfigsResponse.prototype.addFailedAppointmentTypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.appointment_service.SetAppointmentTypeConfigsResponse} returns this
 */
proto.appointment_service.SetAppointmentTypeConfigsResponse.prototype.clearFailedAppointmentTypesList = function() {
  return this.setFailedAppointmentTypesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointment_service.GetAppointmentCheckoutStatusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.appointment_service.GetAppointmentCheckoutStatusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointment_service.GetAppointmentCheckoutStatusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment_service.GetAppointmentCheckoutStatusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    appointmentId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointment_service.GetAppointmentCheckoutStatusRequest}
 */
proto.appointment_service.GetAppointmentCheckoutStatusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointment_service.GetAppointmentCheckoutStatusRequest;
  return proto.appointment_service.GetAppointmentCheckoutStatusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointment_service.GetAppointmentCheckoutStatusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointment_service.GetAppointmentCheckoutStatusRequest}
 */
proto.appointment_service.GetAppointmentCheckoutStatusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppointmentId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointment_service.GetAppointmentCheckoutStatusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointment_service.GetAppointmentCheckoutStatusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointment_service.GetAppointmentCheckoutStatusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment_service.GetAppointmentCheckoutStatusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppointmentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string appointment_id = 1;
 * @return {string}
 */
proto.appointment_service.GetAppointmentCheckoutStatusRequest.prototype.getAppointmentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointment_service.GetAppointmentCheckoutStatusRequest} returns this
 */
proto.appointment_service.GetAppointmentCheckoutStatusRequest.prototype.setAppointmentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointment_service.GetAppointmentCheckoutStatusResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.appointment_service.GetAppointmentCheckoutStatusResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointment_service.GetAppointmentCheckoutStatusResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment_service.GetAppointmentCheckoutStatusResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    appointmentCheckoutStatus: (f = msg.getAppointmentCheckoutStatus()) && appointment_pb.AppointmentCheckoutStatus.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointment_service.GetAppointmentCheckoutStatusResponse}
 */
proto.appointment_service.GetAppointmentCheckoutStatusResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointment_service.GetAppointmentCheckoutStatusResponse;
  return proto.appointment_service.GetAppointmentCheckoutStatusResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointment_service.GetAppointmentCheckoutStatusResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointment_service.GetAppointmentCheckoutStatusResponse}
 */
proto.appointment_service.GetAppointmentCheckoutStatusResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new appointment_pb.AppointmentCheckoutStatus;
      reader.readMessage(value,appointment_pb.AppointmentCheckoutStatus.deserializeBinaryFromReader);
      msg.setAppointmentCheckoutStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointment_service.GetAppointmentCheckoutStatusResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointment_service.GetAppointmentCheckoutStatusResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointment_service.GetAppointmentCheckoutStatusResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment_service.GetAppointmentCheckoutStatusResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppointmentCheckoutStatus();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      appointment_pb.AppointmentCheckoutStatus.serializeBinaryToWriter
    );
  }
};


/**
 * optional appointment.AppointmentCheckoutStatus appointment_checkout_status = 1;
 * @return {?proto.appointment.AppointmentCheckoutStatus}
 */
proto.appointment_service.GetAppointmentCheckoutStatusResponse.prototype.getAppointmentCheckoutStatus = function() {
  return /** @type{?proto.appointment.AppointmentCheckoutStatus} */ (
    jspb.Message.getWrapperField(this, appointment_pb.AppointmentCheckoutStatus, 1));
};


/**
 * @param {?proto.appointment.AppointmentCheckoutStatus|undefined} value
 * @return {!proto.appointment_service.GetAppointmentCheckoutStatusResponse} returns this
*/
proto.appointment_service.GetAppointmentCheckoutStatusResponse.prototype.setAppointmentCheckoutStatus = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.appointment_service.GetAppointmentCheckoutStatusResponse} returns this
 */
proto.appointment_service.GetAppointmentCheckoutStatusResponse.prototype.clearAppointmentCheckoutStatus = function() {
  return this.setAppointmentCheckoutStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.appointment_service.GetAppointmentCheckoutStatusResponse.prototype.hasAppointmentCheckoutStatus = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointment_service.AppointmentKeyHandOffRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.appointment_service.AppointmentKeyHandOffRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointment_service.AppointmentKeyHandOffRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment_service.AppointmentKeyHandOffRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    appointmentKeyHandOff: (f = msg.getAppointmentKeyHandOff()) && appointment_pb.AppointmentKeyHandOff.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointment_service.AppointmentKeyHandOffRequest}
 */
proto.appointment_service.AppointmentKeyHandOffRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointment_service.AppointmentKeyHandOffRequest;
  return proto.appointment_service.AppointmentKeyHandOffRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointment_service.AppointmentKeyHandOffRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointment_service.AppointmentKeyHandOffRequest}
 */
proto.appointment_service.AppointmentKeyHandOffRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new appointment_pb.AppointmentKeyHandOff;
      reader.readMessage(value,appointment_pb.AppointmentKeyHandOff.deserializeBinaryFromReader);
      msg.setAppointmentKeyHandOff(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointment_service.AppointmentKeyHandOffRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointment_service.AppointmentKeyHandOffRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointment_service.AppointmentKeyHandOffRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment_service.AppointmentKeyHandOffRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppointmentKeyHandOff();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      appointment_pb.AppointmentKeyHandOff.serializeBinaryToWriter
    );
  }
};


/**
 * optional appointment.AppointmentKeyHandOff appointment_key_hand_off = 1;
 * @return {?proto.appointment.AppointmentKeyHandOff}
 */
proto.appointment_service.AppointmentKeyHandOffRequest.prototype.getAppointmentKeyHandOff = function() {
  return /** @type{?proto.appointment.AppointmentKeyHandOff} */ (
    jspb.Message.getWrapperField(this, appointment_pb.AppointmentKeyHandOff, 1));
};


/**
 * @param {?proto.appointment.AppointmentKeyHandOff|undefined} value
 * @return {!proto.appointment_service.AppointmentKeyHandOffRequest} returns this
*/
proto.appointment_service.AppointmentKeyHandOffRequest.prototype.setAppointmentKeyHandOff = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.appointment_service.AppointmentKeyHandOffRequest} returns this
 */
proto.appointment_service.AppointmentKeyHandOffRequest.prototype.clearAppointmentKeyHandOff = function() {
  return this.setAppointmentKeyHandOff(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.appointment_service.AppointmentKeyHandOffRequest.prototype.hasAppointmentKeyHandOff = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointment_service.AppointmentKeyHandOffResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.appointment_service.AppointmentKeyHandOffResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointment_service.AppointmentKeyHandOffResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment_service.AppointmentKeyHandOffResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    appointmentId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointment_service.AppointmentKeyHandOffResponse}
 */
proto.appointment_service.AppointmentKeyHandOffResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointment_service.AppointmentKeyHandOffResponse;
  return proto.appointment_service.AppointmentKeyHandOffResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointment_service.AppointmentKeyHandOffResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointment_service.AppointmentKeyHandOffResponse}
 */
proto.appointment_service.AppointmentKeyHandOffResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppointmentId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointment_service.AppointmentKeyHandOffResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointment_service.AppointmentKeyHandOffResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointment_service.AppointmentKeyHandOffResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment_service.AppointmentKeyHandOffResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppointmentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string appointment_id = 1;
 * @return {string}
 */
proto.appointment_service.AppointmentKeyHandOffResponse.prototype.getAppointmentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointment_service.AppointmentKeyHandOffResponse} returns this
 */
proto.appointment_service.AppointmentKeyHandOffResponse.prototype.setAppointmentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointment_service.GetAppointmentKeyHandOffRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.appointment_service.GetAppointmentKeyHandOffRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointment_service.GetAppointmentKeyHandOffRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment_service.GetAppointmentKeyHandOffRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    appointmentId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointment_service.GetAppointmentKeyHandOffRequest}
 */
proto.appointment_service.GetAppointmentKeyHandOffRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointment_service.GetAppointmentKeyHandOffRequest;
  return proto.appointment_service.GetAppointmentKeyHandOffRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointment_service.GetAppointmentKeyHandOffRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointment_service.GetAppointmentKeyHandOffRequest}
 */
proto.appointment_service.GetAppointmentKeyHandOffRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppointmentId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointment_service.GetAppointmentKeyHandOffRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointment_service.GetAppointmentKeyHandOffRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointment_service.GetAppointmentKeyHandOffRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment_service.GetAppointmentKeyHandOffRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppointmentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string appointment_id = 1;
 * @return {string}
 */
proto.appointment_service.GetAppointmentKeyHandOffRequest.prototype.getAppointmentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointment_service.GetAppointmentKeyHandOffRequest} returns this
 */
proto.appointment_service.GetAppointmentKeyHandOffRequest.prototype.setAppointmentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointment_service.GetAppointmentKeyHandOffResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.appointment_service.GetAppointmentKeyHandOffResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointment_service.GetAppointmentKeyHandOffResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment_service.GetAppointmentKeyHandOffResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    appointmentKeyHandOff: (f = msg.getAppointmentKeyHandOff()) && appointment_pb.AppointmentKeyHandOff.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointment_service.GetAppointmentKeyHandOffResponse}
 */
proto.appointment_service.GetAppointmentKeyHandOffResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointment_service.GetAppointmentKeyHandOffResponse;
  return proto.appointment_service.GetAppointmentKeyHandOffResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointment_service.GetAppointmentKeyHandOffResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointment_service.GetAppointmentKeyHandOffResponse}
 */
proto.appointment_service.GetAppointmentKeyHandOffResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new appointment_pb.AppointmentKeyHandOff;
      reader.readMessage(value,appointment_pb.AppointmentKeyHandOff.deserializeBinaryFromReader);
      msg.setAppointmentKeyHandOff(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointment_service.GetAppointmentKeyHandOffResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointment_service.GetAppointmentKeyHandOffResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointment_service.GetAppointmentKeyHandOffResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment_service.GetAppointmentKeyHandOffResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppointmentKeyHandOff();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      appointment_pb.AppointmentKeyHandOff.serializeBinaryToWriter
    );
  }
};


/**
 * optional appointment.AppointmentKeyHandOff appointment_key_hand_off = 1;
 * @return {?proto.appointment.AppointmentKeyHandOff}
 */
proto.appointment_service.GetAppointmentKeyHandOffResponse.prototype.getAppointmentKeyHandOff = function() {
  return /** @type{?proto.appointment.AppointmentKeyHandOff} */ (
    jspb.Message.getWrapperField(this, appointment_pb.AppointmentKeyHandOff, 1));
};


/**
 * @param {?proto.appointment.AppointmentKeyHandOff|undefined} value
 * @return {!proto.appointment_service.GetAppointmentKeyHandOffResponse} returns this
*/
proto.appointment_service.GetAppointmentKeyHandOffResponse.prototype.setAppointmentKeyHandOff = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.appointment_service.GetAppointmentKeyHandOffResponse} returns this
 */
proto.appointment_service.GetAppointmentKeyHandOffResponse.prototype.clearAppointmentKeyHandOff = function() {
  return this.setAppointmentKeyHandOff(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.appointment_service.GetAppointmentKeyHandOffResponse.prototype.hasAppointmentKeyHandOff = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointment_service.GetAppointmentPickupDateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.appointment_service.GetAppointmentPickupDateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointment_service.GetAppointmentPickupDateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment_service.GetAppointmentPickupDateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointment_service.GetAppointmentPickupDateRequest}
 */
proto.appointment_service.GetAppointmentPickupDateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointment_service.GetAppointmentPickupDateRequest;
  return proto.appointment_service.GetAppointmentPickupDateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointment_service.GetAppointmentPickupDateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointment_service.GetAppointmentPickupDateRequest}
 */
proto.appointment_service.GetAppointmentPickupDateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointment_service.GetAppointmentPickupDateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointment_service.GetAppointmentPickupDateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointment_service.GetAppointmentPickupDateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment_service.GetAppointmentPickupDateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.appointment_service.GetAppointmentPickupDateRequest.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointment_service.GetAppointmentPickupDateRequest} returns this
 */
proto.appointment_service.GetAppointmentPickupDateRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointment_service.GetAppointmentPickupDateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.appointment_service.GetAppointmentPickupDateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointment_service.GetAppointmentPickupDateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment_service.GetAppointmentPickupDateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    appointmentPickupDate: (f = msg.getAppointmentPickupDate()) && appointment_pb.AppointmentPickupDate.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointment_service.GetAppointmentPickupDateResponse}
 */
proto.appointment_service.GetAppointmentPickupDateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointment_service.GetAppointmentPickupDateResponse;
  return proto.appointment_service.GetAppointmentPickupDateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointment_service.GetAppointmentPickupDateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointment_service.GetAppointmentPickupDateResponse}
 */
proto.appointment_service.GetAppointmentPickupDateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new appointment_pb.AppointmentPickupDate;
      reader.readMessage(value,appointment_pb.AppointmentPickupDate.deserializeBinaryFromReader);
      msg.setAppointmentPickupDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointment_service.GetAppointmentPickupDateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointment_service.GetAppointmentPickupDateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointment_service.GetAppointmentPickupDateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment_service.GetAppointmentPickupDateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppointmentPickupDate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      appointment_pb.AppointmentPickupDate.serializeBinaryToWriter
    );
  }
};


/**
 * optional appointment.AppointmentPickupDate appointment_pickup_date = 1;
 * @return {?proto.appointment.AppointmentPickupDate}
 */
proto.appointment_service.GetAppointmentPickupDateResponse.prototype.getAppointmentPickupDate = function() {
  return /** @type{?proto.appointment.AppointmentPickupDate} */ (
    jspb.Message.getWrapperField(this, appointment_pb.AppointmentPickupDate, 1));
};


/**
 * @param {?proto.appointment.AppointmentPickupDate|undefined} value
 * @return {!proto.appointment_service.GetAppointmentPickupDateResponse} returns this
*/
proto.appointment_service.GetAppointmentPickupDateResponse.prototype.setAppointmentPickupDate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.appointment_service.GetAppointmentPickupDateResponse} returns this
 */
proto.appointment_service.GetAppointmentPickupDateResponse.prototype.clearAppointmentPickupDate = function() {
  return this.setAppointmentPickupDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.appointment_service.GetAppointmentPickupDateResponse.prototype.hasAppointmentPickupDate = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointment_service.ListCompletedAppointmentsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.appointment_service.ListCompletedAppointmentsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointment_service.ListCompletedAppointmentsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment_service.ListCompletedAppointmentsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dealerUserId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointment_service.ListCompletedAppointmentsRequest}
 */
proto.appointment_service.ListCompletedAppointmentsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointment_service.ListCompletedAppointmentsRequest;
  return proto.appointment_service.ListCompletedAppointmentsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointment_service.ListCompletedAppointmentsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointment_service.ListCompletedAppointmentsRequest}
 */
proto.appointment_service.ListCompletedAppointmentsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealerUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointment_service.ListCompletedAppointmentsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointment_service.ListCompletedAppointmentsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointment_service.ListCompletedAppointmentsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment_service.ListCompletedAppointmentsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDealerUserId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string dealer_id = 1;
 * @return {string}
 */
proto.appointment_service.ListCompletedAppointmentsRequest.prototype.getDealerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointment_service.ListCompletedAppointmentsRequest} returns this
 */
proto.appointment_service.ListCompletedAppointmentsRequest.prototype.setDealerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string dealer_user_id = 2;
 * @return {string}
 */
proto.appointment_service.ListCompletedAppointmentsRequest.prototype.getDealerUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointment_service.ListCompletedAppointmentsRequest} returns this
 */
proto.appointment_service.ListCompletedAppointmentsRequest.prototype.setDealerUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.appointment_service.ListCompletedAppointmentsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointment_service.ListCompletedAppointmentsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.appointment_service.ListCompletedAppointmentsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointment_service.ListCompletedAppointmentsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment_service.ListCompletedAppointmentsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    appointmentsList: jspb.Message.toObjectList(msg.getAppointmentsList(),
    appointment_pb.Appointment.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointment_service.ListCompletedAppointmentsResponse}
 */
proto.appointment_service.ListCompletedAppointmentsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointment_service.ListCompletedAppointmentsResponse;
  return proto.appointment_service.ListCompletedAppointmentsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointment_service.ListCompletedAppointmentsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointment_service.ListCompletedAppointmentsResponse}
 */
proto.appointment_service.ListCompletedAppointmentsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new appointment_pb.Appointment;
      reader.readMessage(value,appointment_pb.Appointment.deserializeBinaryFromReader);
      msg.addAppointments(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointment_service.ListCompletedAppointmentsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointment_service.ListCompletedAppointmentsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointment_service.ListCompletedAppointmentsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment_service.ListCompletedAppointmentsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppointmentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      appointment_pb.Appointment.serializeBinaryToWriter
    );
  }
};


/**
 * repeated appointment.Appointment appointments = 1;
 * @return {!Array<!proto.appointment.Appointment>}
 */
proto.appointment_service.ListCompletedAppointmentsResponse.prototype.getAppointmentsList = function() {
  return /** @type{!Array<!proto.appointment.Appointment>} */ (
    jspb.Message.getRepeatedWrapperField(this, appointment_pb.Appointment, 1));
};


/**
 * @param {!Array<!proto.appointment.Appointment>} value
 * @return {!proto.appointment_service.ListCompletedAppointmentsResponse} returns this
*/
proto.appointment_service.ListCompletedAppointmentsResponse.prototype.setAppointmentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.appointment.Appointment=} opt_value
 * @param {number=} opt_index
 * @return {!proto.appointment.Appointment}
 */
proto.appointment_service.ListCompletedAppointmentsResponse.prototype.addAppointments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.appointment.Appointment, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.appointment_service.ListCompletedAppointmentsResponse} returns this
 */
proto.appointment_service.ListCompletedAppointmentsResponse.prototype.clearAppointmentsList = function() {
  return this.setAppointmentsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointment_service.GetPreAppointmentCheckoutStatusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.appointment_service.GetPreAppointmentCheckoutStatusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointment_service.GetPreAppointmentCheckoutStatusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment_service.GetPreAppointmentCheckoutStatusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointment_service.GetPreAppointmentCheckoutStatusRequest}
 */
proto.appointment_service.GetPreAppointmentCheckoutStatusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointment_service.GetPreAppointmentCheckoutStatusRequest;
  return proto.appointment_service.GetPreAppointmentCheckoutStatusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointment_service.GetPreAppointmentCheckoutStatusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointment_service.GetPreAppointmentCheckoutStatusRequest}
 */
proto.appointment_service.GetPreAppointmentCheckoutStatusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointment_service.GetPreAppointmentCheckoutStatusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointment_service.GetPreAppointmentCheckoutStatusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointment_service.GetPreAppointmentCheckoutStatusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment_service.GetPreAppointmentCheckoutStatusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string subscription_id = 1;
 * @return {string}
 */
proto.appointment_service.GetPreAppointmentCheckoutStatusRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointment_service.GetPreAppointmentCheckoutStatusRequest} returns this
 */
proto.appointment_service.GetPreAppointmentCheckoutStatusRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointment_service.GetPreAppointmentCheckoutStatusResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.appointment_service.GetPreAppointmentCheckoutStatusResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointment_service.GetPreAppointmentCheckoutStatusResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment_service.GetPreAppointmentCheckoutStatusResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    preAppointmentCheckoutStatus: (f = msg.getPreAppointmentCheckoutStatus()) && appointment_pb.AppointmentCheckoutStatus.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointment_service.GetPreAppointmentCheckoutStatusResponse}
 */
proto.appointment_service.GetPreAppointmentCheckoutStatusResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointment_service.GetPreAppointmentCheckoutStatusResponse;
  return proto.appointment_service.GetPreAppointmentCheckoutStatusResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointment_service.GetPreAppointmentCheckoutStatusResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointment_service.GetPreAppointmentCheckoutStatusResponse}
 */
proto.appointment_service.GetPreAppointmentCheckoutStatusResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new appointment_pb.AppointmentCheckoutStatus;
      reader.readMessage(value,appointment_pb.AppointmentCheckoutStatus.deserializeBinaryFromReader);
      msg.setPreAppointmentCheckoutStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointment_service.GetPreAppointmentCheckoutStatusResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointment_service.GetPreAppointmentCheckoutStatusResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointment_service.GetPreAppointmentCheckoutStatusResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment_service.GetPreAppointmentCheckoutStatusResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPreAppointmentCheckoutStatus();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      appointment_pb.AppointmentCheckoutStatus.serializeBinaryToWriter
    );
  }
};


/**
 * optional appointment.AppointmentCheckoutStatus pre_appointment_checkout_status = 1;
 * @return {?proto.appointment.AppointmentCheckoutStatus}
 */
proto.appointment_service.GetPreAppointmentCheckoutStatusResponse.prototype.getPreAppointmentCheckoutStatus = function() {
  return /** @type{?proto.appointment.AppointmentCheckoutStatus} */ (
    jspb.Message.getWrapperField(this, appointment_pb.AppointmentCheckoutStatus, 1));
};


/**
 * @param {?proto.appointment.AppointmentCheckoutStatus|undefined} value
 * @return {!proto.appointment_service.GetPreAppointmentCheckoutStatusResponse} returns this
*/
proto.appointment_service.GetPreAppointmentCheckoutStatusResponse.prototype.setPreAppointmentCheckoutStatus = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.appointment_service.GetPreAppointmentCheckoutStatusResponse} returns this
 */
proto.appointment_service.GetPreAppointmentCheckoutStatusResponse.prototype.clearPreAppointmentCheckoutStatus = function() {
  return this.setPreAppointmentCheckoutStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.appointment_service.GetPreAppointmentCheckoutStatusResponse.prototype.hasPreAppointmentCheckoutStatus = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.appointment_service);
