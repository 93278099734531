import { find } from 'lodash'

const token = process.env.MAPBOX_TOKEN

export const MapBoxAPI = {
  reverseGeoCode,
  getAddressFromPoint,
}

type Point = {
  lat: number
  long: number
}

async function reverseGeoCode(
  point: Point
): Promise<GeoJSON.FeatureCollection<GeoJSON.Geometry>> {
  return fetch(
    `https://api.mapbox.com/geocoding/v5/mapbox.places/${point.long},${point.lat}.json?access_token=${token}`
  ).then((res) => {
    return res.json()
  })
}


async function getAddressFromPoint(
  point: Point
): Promise<GeoJSON.Feature<GeoJSON.Geometry> & { place_name: string }> {
  return reverseGeoCode(point).then((res) => {
    return find(res.features, {
      place_type: ['address'],
    }) as GeoJSON.Feature<GeoJSON.Geometry> & { place_name: string }
  })
}