import React from 'react'
import { Grid, Typography } from '@mui/material'

interface ReadOnlyFieldProps {
  label: string
  value?: string
}

export default function ReadOnlyField({ label, value }: ReadOnlyFieldProps) {
  return (
    <Grid item xs={12} sm={6} md={3}>
      <Typography variant="caption">{label}</Typography>
      <div>{value || 'N/A'}</div>
    </Grid>
  )
}
