import { Button, CircularProgress } from '@mui/material'
import { resetLoginAttempts } from 'global-apis/nexus-service-cqe'
import React, { useState } from 'react'
import { useCustomerContext } from '../CustomerContext'

export function ResetLoginAttemptsButton() {
  const {
    customerId,
    customerInfo: { accountNumber, phone },
  } = useCustomerContext()
  const [loading, setLoading] = useState(false)
  const handleOnClick = async () => {
    setLoading(true)

    try {
      await resetLoginAttempts({
        accountNumber,
        customerId,
        phoneNumber: `+1${phone.replace(/\D/g, '')}`,
      })
    } catch (error) {
      console.error('Error:', error)
    }

    setLoading(false)
  }

  return (
    <div>
      <Button
        color="error"
        disabled={loading}
        onClick={handleOnClick}
        type="button"
        variant="contained"
      >
        Reset Login Attempts{' '}
        {loading && <CircularProgress sx={{ marginLeft: 1 }} size={20} />}
      </Button>
    </div>
  )
}
