// source: telemetric.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.telemetric.CollisionEvent', null, global);
goog.exportSymbol('proto.telemetric.CollisionReceived', null, global);
goog.exportSymbol('proto.telemetric.DailyDrivingBehavior', null, global);
goog.exportSymbol('proto.telemetric.DrivingBehavior', null, global);
goog.exportSymbol('proto.telemetric.EventRating', null, global);
goog.exportSymbol('proto.telemetric.Location', null, global);
goog.exportSymbol('proto.telemetric.RoutePosition', null, global);
goog.exportSymbol('proto.telemetric.TripEvent', null, global);
goog.exportSymbol('proto.telemetric.TripReceived', null, global);
goog.exportSymbol('proto.telemetric.VehicleScore', null, global);
goog.exportSymbol('proto.telemetric.VehicleScore.Vehicles', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.telemetric.TripReceived = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.telemetric.TripReceived.repeatedFields_, null);
};
goog.inherits(proto.telemetric.TripReceived, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.telemetric.TripReceived.displayName = 'proto.telemetric.TripReceived';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.telemetric.CollisionReceived = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.telemetric.CollisionReceived.repeatedFields_, null);
};
goog.inherits(proto.telemetric.CollisionReceived, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.telemetric.CollisionReceived.displayName = 'proto.telemetric.CollisionReceived';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.telemetric.CollisionEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.telemetric.CollisionEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.telemetric.CollisionEvent.displayName = 'proto.telemetric.CollisionEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.telemetric.RoutePosition = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.telemetric.RoutePosition, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.telemetric.RoutePosition.displayName = 'proto.telemetric.RoutePosition';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.telemetric.Location = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.telemetric.Location, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.telemetric.Location.displayName = 'proto.telemetric.Location';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.telemetric.TripEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.telemetric.TripEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.telemetric.TripEvent.displayName = 'proto.telemetric.TripEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.telemetric.DailyDrivingBehavior = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.telemetric.DailyDrivingBehavior, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.telemetric.DailyDrivingBehavior.displayName = 'proto.telemetric.DailyDrivingBehavior';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.telemetric.DrivingBehavior = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.telemetric.DrivingBehavior, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.telemetric.DrivingBehavior.displayName = 'proto.telemetric.DrivingBehavior';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.telemetric.VehicleScore = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.telemetric.VehicleScore, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.telemetric.VehicleScore.displayName = 'proto.telemetric.VehicleScore';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.telemetric.VehicleScore.Vehicles = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.telemetric.VehicleScore.Vehicles, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.telemetric.VehicleScore.Vehicles.displayName = 'proto.telemetric.VehicleScore.Vehicles';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.telemetric.EventRating = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.telemetric.EventRating, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.telemetric.EventRating.displayName = 'proto.telemetric.EventRating';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.telemetric.TripReceived.repeatedFields_ = [21];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.telemetric.TripReceived.prototype.toObject = function(opt_includeInstance) {
  return proto.telemetric.TripReceived.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.telemetric.TripReceived} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.telemetric.TripReceived.toObject = function(includeInstance, msg) {
  var f, obj = {
    driverid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    vehicleid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    tripid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    starttime: jspb.Message.getFieldWithDefault(msg, 4, ""),
    endtime: jspb.Message.getFieldWithDefault(msg, 5, ""),
    tripmaxspeed: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    tripduration: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    distance: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    sessionid: jspb.Message.getFieldWithDefault(msg, 9, ""),
    trackingid: jspb.Message.getFieldWithDefault(msg, 10, ""),
    insuranceperiod: jspb.Message.getFieldWithDefault(msg, 11, 0),
    usermode: jspb.Message.getFieldWithDefault(msg, 12, ""),
    drivetype: jspb.Message.getFieldWithDefault(msg, 13, ""),
    vehicletype: jspb.Message.getFieldWithDefault(msg, 14, ""),
    hardbrakerating: jspb.Message.getFieldWithDefault(msg, 15, 0),
    hardturnrating: jspb.Message.getFieldWithDefault(msg, 16, 0),
    rapidaccelerationrating: jspb.Message.getFieldWithDefault(msg, 17, 0),
    phoneuserating: jspb.Message.getFieldWithDefault(msg, 18, 0),
    overspeedingrating: jspb.Message.getFieldWithDefault(msg, 19, 0),
    drivingscore: jspb.Message.getFieldWithDefault(msg, 20, 0),
    tripeventsList: jspb.Message.toObjectList(msg.getTripeventsList(),
    proto.telemetric.TripEvent.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.telemetric.TripReceived}
 */
proto.telemetric.TripReceived.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.telemetric.TripReceived;
  return proto.telemetric.TripReceived.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.telemetric.TripReceived} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.telemetric.TripReceived}
 */
proto.telemetric.TripReceived.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDriverid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTripid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStarttime(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndtime(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTripmaxspeed(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTripduration(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDistance(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionid(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrackingid(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setInsuranceperiod(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsermode(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setDrivetype(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicletype(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHardbrakerating(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHardturnrating(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRapidaccelerationrating(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPhoneuserating(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOverspeedingrating(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDrivingscore(value);
      break;
    case 21:
      var value = new proto.telemetric.TripEvent;
      reader.readMessage(value,proto.telemetric.TripEvent.deserializeBinaryFromReader);
      msg.addTripevents(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.telemetric.TripReceived.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.telemetric.TripReceived.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.telemetric.TripReceived} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.telemetric.TripReceived.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDriverid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVehicleid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTripid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStarttime();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEndtime();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getTripmaxspeed();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getTripduration();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getDistance();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getSessionid();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getTrackingid();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getInsuranceperiod();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getUsermode();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getDrivetype();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getVehicletype();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getHardbrakerating();
  if (f !== 0) {
    writer.writeInt32(
      15,
      f
    );
  }
  f = message.getHardturnrating();
  if (f !== 0) {
    writer.writeInt32(
      16,
      f
    );
  }
  f = message.getRapidaccelerationrating();
  if (f !== 0) {
    writer.writeInt32(
      17,
      f
    );
  }
  f = message.getPhoneuserating();
  if (f !== 0) {
    writer.writeInt32(
      18,
      f
    );
  }
  f = message.getOverspeedingrating();
  if (f !== 0) {
    writer.writeInt32(
      19,
      f
    );
  }
  f = message.getDrivingscore();
  if (f !== 0) {
    writer.writeInt32(
      20,
      f
    );
  }
  f = message.getTripeventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      21,
      f,
      proto.telemetric.TripEvent.serializeBinaryToWriter
    );
  }
};


/**
 * optional string DriverId = 1;
 * @return {string}
 */
proto.telemetric.TripReceived.prototype.getDriverid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.telemetric.TripReceived} returns this
 */
proto.telemetric.TripReceived.prototype.setDriverid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string VehicleId = 2;
 * @return {string}
 */
proto.telemetric.TripReceived.prototype.getVehicleid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.telemetric.TripReceived} returns this
 */
proto.telemetric.TripReceived.prototype.setVehicleid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string TripId = 3;
 * @return {string}
 */
proto.telemetric.TripReceived.prototype.getTripid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.telemetric.TripReceived} returns this
 */
proto.telemetric.TripReceived.prototype.setTripid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string StartTime = 4;
 * @return {string}
 */
proto.telemetric.TripReceived.prototype.getStarttime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.telemetric.TripReceived} returns this
 */
proto.telemetric.TripReceived.prototype.setStarttime = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string EndTime = 5;
 * @return {string}
 */
proto.telemetric.TripReceived.prototype.getEndtime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.telemetric.TripReceived} returns this
 */
proto.telemetric.TripReceived.prototype.setEndtime = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional float TripMaxSpeed = 6;
 * @return {number}
 */
proto.telemetric.TripReceived.prototype.getTripmaxspeed = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.telemetric.TripReceived} returns this
 */
proto.telemetric.TripReceived.prototype.setTripmaxspeed = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float TripDuration = 7;
 * @return {number}
 */
proto.telemetric.TripReceived.prototype.getTripduration = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.telemetric.TripReceived} returns this
 */
proto.telemetric.TripReceived.prototype.setTripduration = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional float Distance = 8;
 * @return {number}
 */
proto.telemetric.TripReceived.prototype.getDistance = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.telemetric.TripReceived} returns this
 */
proto.telemetric.TripReceived.prototype.setDistance = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional string SessionId = 9;
 * @return {string}
 */
proto.telemetric.TripReceived.prototype.getSessionid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.telemetric.TripReceived} returns this
 */
proto.telemetric.TripReceived.prototype.setSessionid = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string TrackingId = 10;
 * @return {string}
 */
proto.telemetric.TripReceived.prototype.getTrackingid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.telemetric.TripReceived} returns this
 */
proto.telemetric.TripReceived.prototype.setTrackingid = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional int64 InsurancePeriod = 11;
 * @return {number}
 */
proto.telemetric.TripReceived.prototype.getInsuranceperiod = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.telemetric.TripReceived} returns this
 */
proto.telemetric.TripReceived.prototype.setInsuranceperiod = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional string UserMode = 12;
 * @return {string}
 */
proto.telemetric.TripReceived.prototype.getUsermode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.telemetric.TripReceived} returns this
 */
proto.telemetric.TripReceived.prototype.setUsermode = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string DriveType = 13;
 * @return {string}
 */
proto.telemetric.TripReceived.prototype.getDrivetype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.telemetric.TripReceived} returns this
 */
proto.telemetric.TripReceived.prototype.setDrivetype = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string VehicleType = 14;
 * @return {string}
 */
proto.telemetric.TripReceived.prototype.getVehicletype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.telemetric.TripReceived} returns this
 */
proto.telemetric.TripReceived.prototype.setVehicletype = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional int32 HardBrakeRating = 15;
 * @return {number}
 */
proto.telemetric.TripReceived.prototype.getHardbrakerating = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.telemetric.TripReceived} returns this
 */
proto.telemetric.TripReceived.prototype.setHardbrakerating = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional int32 HardTurnRating = 16;
 * @return {number}
 */
proto.telemetric.TripReceived.prototype.getHardturnrating = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.telemetric.TripReceived} returns this
 */
proto.telemetric.TripReceived.prototype.setHardturnrating = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional int32 RapidAccelerationRating = 17;
 * @return {number}
 */
proto.telemetric.TripReceived.prototype.getRapidaccelerationrating = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.telemetric.TripReceived} returns this
 */
proto.telemetric.TripReceived.prototype.setRapidaccelerationrating = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional int32 PhoneUseRating = 18;
 * @return {number}
 */
proto.telemetric.TripReceived.prototype.getPhoneuserating = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.telemetric.TripReceived} returns this
 */
proto.telemetric.TripReceived.prototype.setPhoneuserating = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * optional int32 OverspeedingRating = 19;
 * @return {number}
 */
proto.telemetric.TripReceived.prototype.getOverspeedingrating = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {number} value
 * @return {!proto.telemetric.TripReceived} returns this
 */
proto.telemetric.TripReceived.prototype.setOverspeedingrating = function(value) {
  return jspb.Message.setProto3IntField(this, 19, value);
};


/**
 * optional int32 DrivingScore = 20;
 * @return {number}
 */
proto.telemetric.TripReceived.prototype.getDrivingscore = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.telemetric.TripReceived} returns this
 */
proto.telemetric.TripReceived.prototype.setDrivingscore = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * repeated TripEvent TripEvents = 21;
 * @return {!Array<!proto.telemetric.TripEvent>}
 */
proto.telemetric.TripReceived.prototype.getTripeventsList = function() {
  return /** @type{!Array<!proto.telemetric.TripEvent>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.telemetric.TripEvent, 21));
};


/**
 * @param {!Array<!proto.telemetric.TripEvent>} value
 * @return {!proto.telemetric.TripReceived} returns this
*/
proto.telemetric.TripReceived.prototype.setTripeventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 21, value);
};


/**
 * @param {!proto.telemetric.TripEvent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.telemetric.TripEvent}
 */
proto.telemetric.TripReceived.prototype.addTripevents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 21, opt_value, proto.telemetric.TripEvent, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.telemetric.TripReceived} returns this
 */
proto.telemetric.TripReceived.prototype.clearTripeventsList = function() {
  return this.setTripeventsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.telemetric.CollisionReceived.repeatedFields_ = [26];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.telemetric.CollisionReceived.prototype.toObject = function(opt_includeInstance) {
  return proto.telemetric.CollisionReceived.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.telemetric.CollisionReceived} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.telemetric.CollisionReceived.toObject = function(includeInstance, msg) {
  var f, obj = {
    driverid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    vehicleid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    collisionid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    time: jspb.Message.getFieldWithDefault(msg, 4, ""),
    roadtype: jspb.Message.getFieldWithDefault(msg, 5, ""),
    roadspeedlimit: jspb.Message.getFieldWithDefault(msg, 6, 0),
    currentspeed: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    gforce: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    headingdegrees: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    brakedistance: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    decelerationratemilesperhourpersecond: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    airbagdeployed: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    confidencenumber: jspb.Message.getFieldWithDefault(msg, 13, 0),
    confidence: jspb.Message.getFieldWithDefault(msg, 14, ""),
    tripmaxspeedkmph: jspb.Message.getFloatingPointFieldWithDefault(msg, 15, 0.0),
    durationseconds: jspb.Message.getFloatingPointFieldWithDefault(msg, 16, 0.0),
    distance: jspb.Message.getFloatingPointFieldWithDefault(msg, 17, 0.0),
    sessionid: jspb.Message.getFieldWithDefault(msg, 18, ""),
    trackingid: jspb.Message.getFieldWithDefault(msg, 19, ""),
    insuranceperiod: jspb.Message.getFieldWithDefault(msg, 20, 0),
    usermode: jspb.Message.getFieldWithDefault(msg, 21, ""),
    drivetype: jspb.Message.getFieldWithDefault(msg, 22, ""),
    message: jspb.Message.getFieldWithDefault(msg, 23, ""),
    latitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 24, 0.0),
    longitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 25, 0.0),
    collisioneventsList: jspb.Message.toObjectList(msg.getCollisioneventsList(),
    proto.telemetric.CollisionEvent.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.telemetric.CollisionReceived}
 */
proto.telemetric.CollisionReceived.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.telemetric.CollisionReceived;
  return proto.telemetric.CollisionReceived.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.telemetric.CollisionReceived} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.telemetric.CollisionReceived}
 */
proto.telemetric.CollisionReceived.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDriverid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCollisionid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTime(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoadtype(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRoadspeedlimit(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setCurrentspeed(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setGforce(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setHeadingdegrees(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setBrakedistance(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDecelerationratemilesperhourpersecond(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAirbagdeployed(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setConfidencenumber(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setConfidence(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTripmaxspeedkmph(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDurationseconds(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDistance(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionid(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrackingid(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setInsuranceperiod(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsermode(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setDrivetype(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLatitude(value);
      break;
    case 25:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLongitude(value);
      break;
    case 26:
      var value = new proto.telemetric.CollisionEvent;
      reader.readMessage(value,proto.telemetric.CollisionEvent.deserializeBinaryFromReader);
      msg.addCollisionevents(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.telemetric.CollisionReceived.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.telemetric.CollisionReceived.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.telemetric.CollisionReceived} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.telemetric.CollisionReceived.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDriverid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVehicleid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCollisionid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTime();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getRoadtype();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getRoadspeedlimit();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getCurrentspeed();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getGforce();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getHeadingdegrees();
  if (f !== 0.0) {
    writer.writeFloat(
      9,
      f
    );
  }
  f = message.getBrakedistance();
  if (f !== 0.0) {
    writer.writeFloat(
      10,
      f
    );
  }
  f = message.getDecelerationratemilesperhourpersecond();
  if (f !== 0.0) {
    writer.writeFloat(
      11,
      f
    );
  }
  f = message.getAirbagdeployed();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getConfidencenumber();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
  f = message.getConfidence();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getTripmaxspeedkmph();
  if (f !== 0.0) {
    writer.writeFloat(
      15,
      f
    );
  }
  f = message.getDurationseconds();
  if (f !== 0.0) {
    writer.writeFloat(
      16,
      f
    );
  }
  f = message.getDistance();
  if (f !== 0.0) {
    writer.writeFloat(
      17,
      f
    );
  }
  f = message.getSessionid();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getTrackingid();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getInsuranceperiod();
  if (f !== 0) {
    writer.writeInt32(
      20,
      f
    );
  }
  f = message.getUsermode();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getDrivetype();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getLatitude();
  if (f !== 0.0) {
    writer.writeFloat(
      24,
      f
    );
  }
  f = message.getLongitude();
  if (f !== 0.0) {
    writer.writeFloat(
      25,
      f
    );
  }
  f = message.getCollisioneventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      26,
      f,
      proto.telemetric.CollisionEvent.serializeBinaryToWriter
    );
  }
};


/**
 * optional string DriverId = 1;
 * @return {string}
 */
proto.telemetric.CollisionReceived.prototype.getDriverid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.telemetric.CollisionReceived} returns this
 */
proto.telemetric.CollisionReceived.prototype.setDriverid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string VehicleId = 2;
 * @return {string}
 */
proto.telemetric.CollisionReceived.prototype.getVehicleid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.telemetric.CollisionReceived} returns this
 */
proto.telemetric.CollisionReceived.prototype.setVehicleid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string CollisionId = 3;
 * @return {string}
 */
proto.telemetric.CollisionReceived.prototype.getCollisionid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.telemetric.CollisionReceived} returns this
 */
proto.telemetric.CollisionReceived.prototype.setCollisionid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string Time = 4;
 * @return {string}
 */
proto.telemetric.CollisionReceived.prototype.getTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.telemetric.CollisionReceived} returns this
 */
proto.telemetric.CollisionReceived.prototype.setTime = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string RoadType = 5;
 * @return {string}
 */
proto.telemetric.CollisionReceived.prototype.getRoadtype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.telemetric.CollisionReceived} returns this
 */
proto.telemetric.CollisionReceived.prototype.setRoadtype = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int32 RoadSpeedLimit = 6;
 * @return {number}
 */
proto.telemetric.CollisionReceived.prototype.getRoadspeedlimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.telemetric.CollisionReceived} returns this
 */
proto.telemetric.CollisionReceived.prototype.setRoadspeedlimit = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional float CurrentSpeed = 7;
 * @return {number}
 */
proto.telemetric.CollisionReceived.prototype.getCurrentspeed = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.telemetric.CollisionReceived} returns this
 */
proto.telemetric.CollisionReceived.prototype.setCurrentspeed = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional float Gforce = 8;
 * @return {number}
 */
proto.telemetric.CollisionReceived.prototype.getGforce = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.telemetric.CollisionReceived} returns this
 */
proto.telemetric.CollisionReceived.prototype.setGforce = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional float HeadingDegrees = 9;
 * @return {number}
 */
proto.telemetric.CollisionReceived.prototype.getHeadingdegrees = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.telemetric.CollisionReceived} returns this
 */
proto.telemetric.CollisionReceived.prototype.setHeadingdegrees = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional float BrakeDistance = 10;
 * @return {number}
 */
proto.telemetric.CollisionReceived.prototype.getBrakedistance = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.telemetric.CollisionReceived} returns this
 */
proto.telemetric.CollisionReceived.prototype.setBrakedistance = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional float DecelerationRateMilesPerHourPerSecond = 11;
 * @return {number}
 */
proto.telemetric.CollisionReceived.prototype.getDecelerationratemilesperhourpersecond = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.telemetric.CollisionReceived} returns this
 */
proto.telemetric.CollisionReceived.prototype.setDecelerationratemilesperhourpersecond = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional bool AirbagDeployed = 12;
 * @return {boolean}
 */
proto.telemetric.CollisionReceived.prototype.getAirbagdeployed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.telemetric.CollisionReceived} returns this
 */
proto.telemetric.CollisionReceived.prototype.setAirbagdeployed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional int32 ConfidenceNumber = 13;
 * @return {number}
 */
proto.telemetric.CollisionReceived.prototype.getConfidencenumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.telemetric.CollisionReceived} returns this
 */
proto.telemetric.CollisionReceived.prototype.setConfidencenumber = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional string Confidence = 14;
 * @return {string}
 */
proto.telemetric.CollisionReceived.prototype.getConfidence = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.telemetric.CollisionReceived} returns this
 */
proto.telemetric.CollisionReceived.prototype.setConfidence = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional float TripMaxSpeedKmph = 15;
 * @return {number}
 */
proto.telemetric.CollisionReceived.prototype.getTripmaxspeedkmph = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 15, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.telemetric.CollisionReceived} returns this
 */
proto.telemetric.CollisionReceived.prototype.setTripmaxspeedkmph = function(value) {
  return jspb.Message.setProto3FloatField(this, 15, value);
};


/**
 * optional float DurationSeconds = 16;
 * @return {number}
 */
proto.telemetric.CollisionReceived.prototype.getDurationseconds = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 16, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.telemetric.CollisionReceived} returns this
 */
proto.telemetric.CollisionReceived.prototype.setDurationseconds = function(value) {
  return jspb.Message.setProto3FloatField(this, 16, value);
};


/**
 * optional float Distance = 17;
 * @return {number}
 */
proto.telemetric.CollisionReceived.prototype.getDistance = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 17, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.telemetric.CollisionReceived} returns this
 */
proto.telemetric.CollisionReceived.prototype.setDistance = function(value) {
  return jspb.Message.setProto3FloatField(this, 17, value);
};


/**
 * optional string SessionId = 18;
 * @return {string}
 */
proto.telemetric.CollisionReceived.prototype.getSessionid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.telemetric.CollisionReceived} returns this
 */
proto.telemetric.CollisionReceived.prototype.setSessionid = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string TrackingId = 19;
 * @return {string}
 */
proto.telemetric.CollisionReceived.prototype.getTrackingid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.telemetric.CollisionReceived} returns this
 */
proto.telemetric.CollisionReceived.prototype.setTrackingid = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional int32 InsurancePeriod = 20;
 * @return {number}
 */
proto.telemetric.CollisionReceived.prototype.getInsuranceperiod = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.telemetric.CollisionReceived} returns this
 */
proto.telemetric.CollisionReceived.prototype.setInsuranceperiod = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * optional string UserMode = 21;
 * @return {string}
 */
proto.telemetric.CollisionReceived.prototype.getUsermode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.telemetric.CollisionReceived} returns this
 */
proto.telemetric.CollisionReceived.prototype.setUsermode = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string DriveType = 22;
 * @return {string}
 */
proto.telemetric.CollisionReceived.prototype.getDrivetype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.telemetric.CollisionReceived} returns this
 */
proto.telemetric.CollisionReceived.prototype.setDrivetype = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional string Message = 23;
 * @return {string}
 */
proto.telemetric.CollisionReceived.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.telemetric.CollisionReceived} returns this
 */
proto.telemetric.CollisionReceived.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional float Latitude = 24;
 * @return {number}
 */
proto.telemetric.CollisionReceived.prototype.getLatitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 24, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.telemetric.CollisionReceived} returns this
 */
proto.telemetric.CollisionReceived.prototype.setLatitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 24, value);
};


/**
 * optional float Longitude = 25;
 * @return {number}
 */
proto.telemetric.CollisionReceived.prototype.getLongitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 25, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.telemetric.CollisionReceived} returns this
 */
proto.telemetric.CollisionReceived.prototype.setLongitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 25, value);
};


/**
 * repeated CollisionEvent CollisionEvents = 26;
 * @return {!Array<!proto.telemetric.CollisionEvent>}
 */
proto.telemetric.CollisionReceived.prototype.getCollisioneventsList = function() {
  return /** @type{!Array<!proto.telemetric.CollisionEvent>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.telemetric.CollisionEvent, 26));
};


/**
 * @param {!Array<!proto.telemetric.CollisionEvent>} value
 * @return {!proto.telemetric.CollisionReceived} returns this
*/
proto.telemetric.CollisionReceived.prototype.setCollisioneventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 26, value);
};


/**
 * @param {!proto.telemetric.CollisionEvent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.telemetric.CollisionEvent}
 */
proto.telemetric.CollisionReceived.prototype.addCollisionevents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 26, opt_value, proto.telemetric.CollisionEvent, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.telemetric.CollisionReceived} returns this
 */
proto.telemetric.CollisionReceived.prototype.clearCollisioneventsList = function() {
  return this.setCollisioneventsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.telemetric.CollisionEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.telemetric.CollisionEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.telemetric.CollisionEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.telemetric.CollisionEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventtype: jspb.Message.getFieldWithDefault(msg, 1, 0),
    eventtypename: jspb.Message.getFieldWithDefault(msg, 2, ""),
    latitudeend: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    longitudeend: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    longitudestart: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    latitudestart: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    averagedriverspeedmph: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    maxdriverspeedmph: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    endtime: jspb.Message.getFieldWithDefault(msg, 9, ""),
    postedspeedlimitmph: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    starttime: jspb.Message.getFieldWithDefault(msg, 11, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.telemetric.CollisionEvent}
 */
proto.telemetric.CollisionEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.telemetric.CollisionEvent;
  return proto.telemetric.CollisionEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.telemetric.CollisionEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.telemetric.CollisionEvent}
 */
proto.telemetric.CollisionEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEventtype(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventtypename(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLatitudeend(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLongitudeend(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLongitudestart(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLatitudestart(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAveragedriverspeedmph(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMaxdriverspeedmph(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndtime(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setPostedspeedlimitmph(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setStarttime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.telemetric.CollisionEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.telemetric.CollisionEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.telemetric.CollisionEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.telemetric.CollisionEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventtype();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getEventtypename();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLatitudeend();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getLongitudeend();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getLongitudestart();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getLatitudestart();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getAveragedriverspeedmph();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getMaxdriverspeedmph();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getEndtime();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getPostedspeedlimitmph();
  if (f !== 0.0) {
    writer.writeFloat(
      10,
      f
    );
  }
  f = message.getStarttime();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
};


/**
 * optional int32 EventType = 1;
 * @return {number}
 */
proto.telemetric.CollisionEvent.prototype.getEventtype = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.telemetric.CollisionEvent} returns this
 */
proto.telemetric.CollisionEvent.prototype.setEventtype = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string EventTypeName = 2;
 * @return {string}
 */
proto.telemetric.CollisionEvent.prototype.getEventtypename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.telemetric.CollisionEvent} returns this
 */
proto.telemetric.CollisionEvent.prototype.setEventtypename = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional float LatitudeEnd = 3;
 * @return {number}
 */
proto.telemetric.CollisionEvent.prototype.getLatitudeend = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.telemetric.CollisionEvent} returns this
 */
proto.telemetric.CollisionEvent.prototype.setLatitudeend = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float LongitudeEnd = 4;
 * @return {number}
 */
proto.telemetric.CollisionEvent.prototype.getLongitudeend = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.telemetric.CollisionEvent} returns this
 */
proto.telemetric.CollisionEvent.prototype.setLongitudeend = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float LongitudeStart = 5;
 * @return {number}
 */
proto.telemetric.CollisionEvent.prototype.getLongitudestart = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.telemetric.CollisionEvent} returns this
 */
proto.telemetric.CollisionEvent.prototype.setLongitudestart = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float LatitudeStart = 6;
 * @return {number}
 */
proto.telemetric.CollisionEvent.prototype.getLatitudestart = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.telemetric.CollisionEvent} returns this
 */
proto.telemetric.CollisionEvent.prototype.setLatitudestart = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float AverageDriverSpeedMph = 7;
 * @return {number}
 */
proto.telemetric.CollisionEvent.prototype.getAveragedriverspeedmph = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.telemetric.CollisionEvent} returns this
 */
proto.telemetric.CollisionEvent.prototype.setAveragedriverspeedmph = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional float MaxDriverSpeedMph = 8;
 * @return {number}
 */
proto.telemetric.CollisionEvent.prototype.getMaxdriverspeedmph = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.telemetric.CollisionEvent} returns this
 */
proto.telemetric.CollisionEvent.prototype.setMaxdriverspeedmph = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional string EndTime = 9;
 * @return {string}
 */
proto.telemetric.CollisionEvent.prototype.getEndtime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.telemetric.CollisionEvent} returns this
 */
proto.telemetric.CollisionEvent.prototype.setEndtime = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional float PostedSpeedLimitMph = 10;
 * @return {number}
 */
proto.telemetric.CollisionEvent.prototype.getPostedspeedlimitmph = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.telemetric.CollisionEvent} returns this
 */
proto.telemetric.CollisionEvent.prototype.setPostedspeedlimitmph = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional string StartTime = 11;
 * @return {string}
 */
proto.telemetric.CollisionEvent.prototype.getStarttime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.telemetric.CollisionEvent} returns this
 */
proto.telemetric.CollisionEvent.prototype.setStarttime = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.telemetric.RoutePosition.prototype.toObject = function(opt_includeInstance) {
  return proto.telemetric.RoutePosition.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.telemetric.RoutePosition} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.telemetric.RoutePosition.toObject = function(includeInstance, msg) {
  var f, obj = {
    timestamp: jspb.Message.getFieldWithDefault(msg, 1, ""),
    location: (f = msg.getLocation()) && proto.telemetric.Location.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.telemetric.RoutePosition}
 */
proto.telemetric.RoutePosition.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.telemetric.RoutePosition;
  return proto.telemetric.RoutePosition.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.telemetric.RoutePosition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.telemetric.RoutePosition}
 */
proto.telemetric.RoutePosition.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimestamp(value);
      break;
    case 2:
      var value = new proto.telemetric.Location;
      reader.readMessage(value,proto.telemetric.Location.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.telemetric.RoutePosition.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.telemetric.RoutePosition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.telemetric.RoutePosition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.telemetric.RoutePosition.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimestamp();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.telemetric.Location.serializeBinaryToWriter
    );
  }
};


/**
 * optional string timestamp = 1;
 * @return {string}
 */
proto.telemetric.RoutePosition.prototype.getTimestamp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.telemetric.RoutePosition} returns this
 */
proto.telemetric.RoutePosition.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Location location = 2;
 * @return {?proto.telemetric.Location}
 */
proto.telemetric.RoutePosition.prototype.getLocation = function() {
  return /** @type{?proto.telemetric.Location} */ (
    jspb.Message.getWrapperField(this, proto.telemetric.Location, 2));
};


/**
 * @param {?proto.telemetric.Location|undefined} value
 * @return {!proto.telemetric.RoutePosition} returns this
*/
proto.telemetric.RoutePosition.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.telemetric.RoutePosition} returns this
 */
proto.telemetric.RoutePosition.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.telemetric.RoutePosition.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.telemetric.Location.prototype.toObject = function(opt_includeInstance) {
  return proto.telemetric.Location.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.telemetric.Location} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.telemetric.Location.toObject = function(includeInstance, msg) {
  var f, obj = {
    latitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    longitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    physicalAddress: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.telemetric.Location}
 */
proto.telemetric.Location.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.telemetric.Location;
  return proto.telemetric.Location.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.telemetric.Location} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.telemetric.Location}
 */
proto.telemetric.Location.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLatitude(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLongitude(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhysicalAddress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.telemetric.Location.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.telemetric.Location.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.telemetric.Location} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.telemetric.Location.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLatitude();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getLongitude();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getPhysicalAddress();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional float latitude = 1;
 * @return {number}
 */
proto.telemetric.Location.prototype.getLatitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.telemetric.Location} returns this
 */
proto.telemetric.Location.prototype.setLatitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional float longitude = 2;
 * @return {number}
 */
proto.telemetric.Location.prototype.getLongitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.telemetric.Location} returns this
 */
proto.telemetric.Location.prototype.setLongitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional string physical_address = 3;
 * @return {string}
 */
proto.telemetric.Location.prototype.getPhysicalAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.telemetric.Location} returns this
 */
proto.telemetric.Location.prototype.setPhysicalAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.telemetric.TripEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.telemetric.TripEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.telemetric.TripEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.telemetric.TripEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventtype: jspb.Message.getFieldWithDefault(msg, 1, 0),
    eventtypename: jspb.Message.getFieldWithDefault(msg, 2, ""),
    latitudestart: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    longitudestart: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    latitudeend: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    longitudeend: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    averagedriverspeed: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    maxdriverspeed: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    postedspeedlimit: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    starttime: jspb.Message.getFieldWithDefault(msg, 10, ""),
    endtime: jspb.Message.getFieldWithDefault(msg, 11, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.telemetric.TripEvent}
 */
proto.telemetric.TripEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.telemetric.TripEvent;
  return proto.telemetric.TripEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.telemetric.TripEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.telemetric.TripEvent}
 */
proto.telemetric.TripEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEventtype(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventtypename(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLatitudestart(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLongitudestart(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLatitudeend(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLongitudeend(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAveragedriverspeed(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMaxdriverspeed(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setPostedspeedlimit(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setStarttime(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndtime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.telemetric.TripEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.telemetric.TripEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.telemetric.TripEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.telemetric.TripEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventtype();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getEventtypename();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLatitudestart();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getLongitudestart();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getLatitudeend();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getLongitudeend();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getAveragedriverspeed();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getMaxdriverspeed();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getPostedspeedlimit();
  if (f !== 0.0) {
    writer.writeFloat(
      9,
      f
    );
  }
  f = message.getStarttime();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getEndtime();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
};


/**
 * optional int32 EventType = 1;
 * @return {number}
 */
proto.telemetric.TripEvent.prototype.getEventtype = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.telemetric.TripEvent} returns this
 */
proto.telemetric.TripEvent.prototype.setEventtype = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string EventTypeName = 2;
 * @return {string}
 */
proto.telemetric.TripEvent.prototype.getEventtypename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.telemetric.TripEvent} returns this
 */
proto.telemetric.TripEvent.prototype.setEventtypename = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional float LatitudeStart = 3;
 * @return {number}
 */
proto.telemetric.TripEvent.prototype.getLatitudestart = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.telemetric.TripEvent} returns this
 */
proto.telemetric.TripEvent.prototype.setLatitudestart = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float LongitudeStart = 4;
 * @return {number}
 */
proto.telemetric.TripEvent.prototype.getLongitudestart = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.telemetric.TripEvent} returns this
 */
proto.telemetric.TripEvent.prototype.setLongitudestart = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float LatitudeEnd = 5;
 * @return {number}
 */
proto.telemetric.TripEvent.prototype.getLatitudeend = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.telemetric.TripEvent} returns this
 */
proto.telemetric.TripEvent.prototype.setLatitudeend = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float LongitudeEnd = 6;
 * @return {number}
 */
proto.telemetric.TripEvent.prototype.getLongitudeend = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.telemetric.TripEvent} returns this
 */
proto.telemetric.TripEvent.prototype.setLongitudeend = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float AverageDriverSpeed = 7;
 * @return {number}
 */
proto.telemetric.TripEvent.prototype.getAveragedriverspeed = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.telemetric.TripEvent} returns this
 */
proto.telemetric.TripEvent.prototype.setAveragedriverspeed = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional float MaxDriverSpeed = 8;
 * @return {number}
 */
proto.telemetric.TripEvent.prototype.getMaxdriverspeed = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.telemetric.TripEvent} returns this
 */
proto.telemetric.TripEvent.prototype.setMaxdriverspeed = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional float PostedSpeedLimit = 9;
 * @return {number}
 */
proto.telemetric.TripEvent.prototype.getPostedspeedlimit = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.telemetric.TripEvent} returns this
 */
proto.telemetric.TripEvent.prototype.setPostedspeedlimit = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional string StartTime = 10;
 * @return {string}
 */
proto.telemetric.TripEvent.prototype.getStarttime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.telemetric.TripEvent} returns this
 */
proto.telemetric.TripEvent.prototype.setStarttime = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string EndTime = 11;
 * @return {string}
 */
proto.telemetric.TripEvent.prototype.getEndtime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.telemetric.TripEvent} returns this
 */
proto.telemetric.TripEvent.prototype.setEndtime = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.telemetric.DailyDrivingBehavior.prototype.toObject = function(opt_includeInstance) {
  return proto.telemetric.DailyDrivingBehavior.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.telemetric.DailyDrivingBehavior} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.telemetric.DailyDrivingBehavior.toObject = function(includeInstance, msg) {
  var f, obj = {
    date: jspb.Message.getFieldWithDefault(msg, 1, ""),
    score: (f = msg.getScore()) && proto.telemetric.VehicleScore.toObject(includeInstance, f),
    eventRating: (f = msg.getEventRating()) && proto.telemetric.EventRating.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.telemetric.DailyDrivingBehavior}
 */
proto.telemetric.DailyDrivingBehavior.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.telemetric.DailyDrivingBehavior;
  return proto.telemetric.DailyDrivingBehavior.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.telemetric.DailyDrivingBehavior} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.telemetric.DailyDrivingBehavior}
 */
proto.telemetric.DailyDrivingBehavior.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDate(value);
      break;
    case 2:
      var value = new proto.telemetric.VehicleScore;
      reader.readMessage(value,proto.telemetric.VehicleScore.deserializeBinaryFromReader);
      msg.setScore(value);
      break;
    case 3:
      var value = new proto.telemetric.EventRating;
      reader.readMessage(value,proto.telemetric.EventRating.deserializeBinaryFromReader);
      msg.setEventRating(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.telemetric.DailyDrivingBehavior.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.telemetric.DailyDrivingBehavior.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.telemetric.DailyDrivingBehavior} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.telemetric.DailyDrivingBehavior.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDate();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getScore();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.telemetric.VehicleScore.serializeBinaryToWriter
    );
  }
  f = message.getEventRating();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.telemetric.EventRating.serializeBinaryToWriter
    );
  }
};


/**
 * optional string date = 1;
 * @return {string}
 */
proto.telemetric.DailyDrivingBehavior.prototype.getDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.telemetric.DailyDrivingBehavior} returns this
 */
proto.telemetric.DailyDrivingBehavior.prototype.setDate = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional VehicleScore score = 2;
 * @return {?proto.telemetric.VehicleScore}
 */
proto.telemetric.DailyDrivingBehavior.prototype.getScore = function() {
  return /** @type{?proto.telemetric.VehicleScore} */ (
    jspb.Message.getWrapperField(this, proto.telemetric.VehicleScore, 2));
};


/**
 * @param {?proto.telemetric.VehicleScore|undefined} value
 * @return {!proto.telemetric.DailyDrivingBehavior} returns this
*/
proto.telemetric.DailyDrivingBehavior.prototype.setScore = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.telemetric.DailyDrivingBehavior} returns this
 */
proto.telemetric.DailyDrivingBehavior.prototype.clearScore = function() {
  return this.setScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.telemetric.DailyDrivingBehavior.prototype.hasScore = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional EventRating event_rating = 3;
 * @return {?proto.telemetric.EventRating}
 */
proto.telemetric.DailyDrivingBehavior.prototype.getEventRating = function() {
  return /** @type{?proto.telemetric.EventRating} */ (
    jspb.Message.getWrapperField(this, proto.telemetric.EventRating, 3));
};


/**
 * @param {?proto.telemetric.EventRating|undefined} value
 * @return {!proto.telemetric.DailyDrivingBehavior} returns this
*/
proto.telemetric.DailyDrivingBehavior.prototype.setEventRating = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.telemetric.DailyDrivingBehavior} returns this
 */
proto.telemetric.DailyDrivingBehavior.prototype.clearEventRating = function() {
  return this.setEventRating(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.telemetric.DailyDrivingBehavior.prototype.hasEventRating = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.telemetric.DrivingBehavior.prototype.toObject = function(opt_includeInstance) {
  return proto.telemetric.DrivingBehavior.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.telemetric.DrivingBehavior} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.telemetric.DrivingBehavior.toObject = function(includeInstance, msg) {
  var f, obj = {
    score: (f = msg.getScore()) && proto.telemetric.VehicleScore.toObject(includeInstance, f),
    eventRating: (f = msg.getEventRating()) && proto.telemetric.EventRating.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.telemetric.DrivingBehavior}
 */
proto.telemetric.DrivingBehavior.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.telemetric.DrivingBehavior;
  return proto.telemetric.DrivingBehavior.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.telemetric.DrivingBehavior} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.telemetric.DrivingBehavior}
 */
proto.telemetric.DrivingBehavior.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.telemetric.VehicleScore;
      reader.readMessage(value,proto.telemetric.VehicleScore.deserializeBinaryFromReader);
      msg.setScore(value);
      break;
    case 2:
      var value = new proto.telemetric.EventRating;
      reader.readMessage(value,proto.telemetric.EventRating.deserializeBinaryFromReader);
      msg.setEventRating(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.telemetric.DrivingBehavior.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.telemetric.DrivingBehavior.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.telemetric.DrivingBehavior} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.telemetric.DrivingBehavior.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScore();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.telemetric.VehicleScore.serializeBinaryToWriter
    );
  }
  f = message.getEventRating();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.telemetric.EventRating.serializeBinaryToWriter
    );
  }
};


/**
 * optional VehicleScore score = 1;
 * @return {?proto.telemetric.VehicleScore}
 */
proto.telemetric.DrivingBehavior.prototype.getScore = function() {
  return /** @type{?proto.telemetric.VehicleScore} */ (
    jspb.Message.getWrapperField(this, proto.telemetric.VehicleScore, 1));
};


/**
 * @param {?proto.telemetric.VehicleScore|undefined} value
 * @return {!proto.telemetric.DrivingBehavior} returns this
*/
proto.telemetric.DrivingBehavior.prototype.setScore = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.telemetric.DrivingBehavior} returns this
 */
proto.telemetric.DrivingBehavior.prototype.clearScore = function() {
  return this.setScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.telemetric.DrivingBehavior.prototype.hasScore = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional EventRating event_rating = 2;
 * @return {?proto.telemetric.EventRating}
 */
proto.telemetric.DrivingBehavior.prototype.getEventRating = function() {
  return /** @type{?proto.telemetric.EventRating} */ (
    jspb.Message.getWrapperField(this, proto.telemetric.EventRating, 2));
};


/**
 * @param {?proto.telemetric.EventRating|undefined} value
 * @return {!proto.telemetric.DrivingBehavior} returns this
*/
proto.telemetric.DrivingBehavior.prototype.setEventRating = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.telemetric.DrivingBehavior} returns this
 */
proto.telemetric.DrivingBehavior.prototype.clearEventRating = function() {
  return this.setEventRating(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.telemetric.DrivingBehavior.prototype.hasEventRating = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.telemetric.VehicleScore.prototype.toObject = function(opt_includeInstance) {
  return proto.telemetric.VehicleScore.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.telemetric.VehicleScore} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.telemetric.VehicleScore.toObject = function(includeInstance, msg) {
  var f, obj = {
    zendriveScore: jspb.Message.getFieldWithDefault(msg, 1, 0),
    vehicles: (f = msg.getVehicles()) && proto.telemetric.VehicleScore.Vehicles.toObject(includeInstance, f),
    unknownVehicleScore: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.telemetric.VehicleScore}
 */
proto.telemetric.VehicleScore.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.telemetric.VehicleScore;
  return proto.telemetric.VehicleScore.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.telemetric.VehicleScore} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.telemetric.VehicleScore}
 */
proto.telemetric.VehicleScore.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setZendriveScore(value);
      break;
    case 2:
      var value = new proto.telemetric.VehicleScore.Vehicles;
      reader.readMessage(value,proto.telemetric.VehicleScore.Vehicles.deserializeBinaryFromReader);
      msg.setVehicles(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUnknownVehicleScore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.telemetric.VehicleScore.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.telemetric.VehicleScore.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.telemetric.VehicleScore} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.telemetric.VehicleScore.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getZendriveScore();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getVehicles();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.telemetric.VehicleScore.Vehicles.serializeBinaryToWriter
    );
  }
  f = message.getUnknownVehicleScore();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.telemetric.VehicleScore.Vehicles.prototype.toObject = function(opt_includeInstance) {
  return proto.telemetric.VehicleScore.Vehicles.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.telemetric.VehicleScore.Vehicles} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.telemetric.VehicleScore.Vehicles.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleId1: jspb.Message.getFieldWithDefault(msg, 1, 0),
    vehicleId2: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.telemetric.VehicleScore.Vehicles}
 */
proto.telemetric.VehicleScore.Vehicles.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.telemetric.VehicleScore.Vehicles;
  return proto.telemetric.VehicleScore.Vehicles.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.telemetric.VehicleScore.Vehicles} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.telemetric.VehicleScore.Vehicles}
 */
proto.telemetric.VehicleScore.Vehicles.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVehicleId1(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVehicleId2(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.telemetric.VehicleScore.Vehicles.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.telemetric.VehicleScore.Vehicles.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.telemetric.VehicleScore.Vehicles} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.telemetric.VehicleScore.Vehicles.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleId1();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getVehicleId2();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 vehicle_id_1 = 1;
 * @return {number}
 */
proto.telemetric.VehicleScore.Vehicles.prototype.getVehicleId1 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.telemetric.VehicleScore.Vehicles} returns this
 */
proto.telemetric.VehicleScore.Vehicles.prototype.setVehicleId1 = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 vehicle_id_2 = 2;
 * @return {number}
 */
proto.telemetric.VehicleScore.Vehicles.prototype.getVehicleId2 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.telemetric.VehicleScore.Vehicles} returns this
 */
proto.telemetric.VehicleScore.Vehicles.prototype.setVehicleId2 = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 zendrive_score = 1;
 * @return {number}
 */
proto.telemetric.VehicleScore.prototype.getZendriveScore = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.telemetric.VehicleScore} returns this
 */
proto.telemetric.VehicleScore.prototype.setZendriveScore = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional Vehicles vehicles = 2;
 * @return {?proto.telemetric.VehicleScore.Vehicles}
 */
proto.telemetric.VehicleScore.prototype.getVehicles = function() {
  return /** @type{?proto.telemetric.VehicleScore.Vehicles} */ (
    jspb.Message.getWrapperField(this, proto.telemetric.VehicleScore.Vehicles, 2));
};


/**
 * @param {?proto.telemetric.VehicleScore.Vehicles|undefined} value
 * @return {!proto.telemetric.VehicleScore} returns this
*/
proto.telemetric.VehicleScore.prototype.setVehicles = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.telemetric.VehicleScore} returns this
 */
proto.telemetric.VehicleScore.prototype.clearVehicles = function() {
  return this.setVehicles(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.telemetric.VehicleScore.prototype.hasVehicles = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 unknown_vehicle_score = 3;
 * @return {number}
 */
proto.telemetric.VehicleScore.prototype.getUnknownVehicleScore = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.telemetric.VehicleScore} returns this
 */
proto.telemetric.VehicleScore.prototype.setUnknownVehicleScore = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.telemetric.EventRating.prototype.toObject = function(opt_includeInstance) {
  return proto.telemetric.EventRating.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.telemetric.EventRating} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.telemetric.EventRating.toObject = function(includeInstance, msg) {
  var f, obj = {
    hardBrakeRating: jspb.Message.getFieldWithDefault(msg, 1, 0),
    hardTurnRating: jspb.Message.getFieldWithDefault(msg, 2, 0),
    rapidAccelerationRating: jspb.Message.getFieldWithDefault(msg, 3, 0),
    phoneUseRating: jspb.Message.getFieldWithDefault(msg, 4, 0),
    overspeedingRating: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.telemetric.EventRating}
 */
proto.telemetric.EventRating.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.telemetric.EventRating;
  return proto.telemetric.EventRating.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.telemetric.EventRating} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.telemetric.EventRating}
 */
proto.telemetric.EventRating.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHardBrakeRating(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHardTurnRating(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRapidAccelerationRating(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPhoneUseRating(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOverspeedingRating(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.telemetric.EventRating.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.telemetric.EventRating.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.telemetric.EventRating} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.telemetric.EventRating.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHardBrakeRating();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getHardTurnRating();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getRapidAccelerationRating();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getPhoneUseRating();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getOverspeedingRating();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional int32 hard_brake_rating = 1;
 * @return {number}
 */
proto.telemetric.EventRating.prototype.getHardBrakeRating = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.telemetric.EventRating} returns this
 */
proto.telemetric.EventRating.prototype.setHardBrakeRating = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 hard_turn_rating = 2;
 * @return {number}
 */
proto.telemetric.EventRating.prototype.getHardTurnRating = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.telemetric.EventRating} returns this
 */
proto.telemetric.EventRating.prototype.setHardTurnRating = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 rapid_acceleration_rating = 3;
 * @return {number}
 */
proto.telemetric.EventRating.prototype.getRapidAccelerationRating = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.telemetric.EventRating} returns this
 */
proto.telemetric.EventRating.prototype.setRapidAccelerationRating = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 phone_use_rating = 4;
 * @return {number}
 */
proto.telemetric.EventRating.prototype.getPhoneUseRating = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.telemetric.EventRating} returns this
 */
proto.telemetric.EventRating.prototype.setPhoneUseRating = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 overspeeding_rating = 5;
 * @return {number}
 */
proto.telemetric.EventRating.prototype.getOverspeedingRating = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.telemetric.EventRating} returns this
 */
proto.telemetric.EventRating.prototype.setOverspeedingRating = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


goog.object.extend(exports, proto.telemetric);
