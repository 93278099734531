import React, { FC } from 'react'
import { Typography, Box } from '@mui/material'
import { autonomyColor } from '../../global-styles/autonomyColor'

export const Vestapoint: FC<{
  label: string
  value: string
}> = ({ label, value }) => {
  return (
    <Box>
      <Typography
        sx={{
          color: autonomyColor.cyan,
          textAlign: 'center',
          textTransform: 'uppercase'
        }}
      >
        {label}
      </Typography>
      <Typography
        sx={{
          textAlign: 'center',
          fontSize: '2em'
        }}
      >
        {value}
      </Typography>
    </Box>
  )
}
