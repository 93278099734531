import {
  createEnumParam,
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params'

import { NexusVehicle } from '@nxcr-org/web-api-interface/lib/fleet_service_pb'

import {
  State as VehicleState,
  Vehicle,
} from '@nxcr-org/web-api-interface/lib/vehicle_pb'
import { useState } from 'react'
import { find, keys, map, toLower } from 'lodash'
import { useQuery, useMutation } from 'react-query'
import { FleetService } from '../../api/fleet-service'
import { formattedNexusVehicleStatuses } from '../../api/reservation.formatted-enums'
import { State } from '@nxcr-org/web-api-interface/lib/vehicle_pb'
import Bluebird from 'bluebird'
import { getMileage } from '../../utils'
import { NexusService } from '../../../global-APIs/nexus-service'
import { formatDealerAddress } from '../../../../utils/formatDealerAddress'

export const AVAILABLE_MAKES = ['tesla', 'vinfast']

export function useVehiclesTable() {
  const [query, setQuery] = useQueryParams({
    make: withDefault(StringParam, AVAILABLE_MAKES[0]),
    status: withDefault(
      createEnumParam(map(keys(NexusVehicle.Status), toLower)),
      'eligible'
    ),
    page: withDefault(NumberParam, 0),
    rowsPerPage: withDefault(NumberParam, 100),
  })

  const [vehicleToList, setVehicleToList] = useState<Vehicle.AsObject>(
    {} as Vehicle.AsObject
  )
  const [fetchListVehiclesError, setFetchListVehiclesError] = useState(false)

  const selectTab = (selectedTab: string) => {
    setQuery({ status: selectedTab.toLowerCase() })
  }

  /**
   * Toggles a vehicle between IN_LISTING and IN_PROCESS states.
   * Throws an error when the vehicle is not in one of these states.
   *
   * @returns {Promise} - A promise that resolves to the updated vehicle
   */
  const listDelistMutation = useMutation(() =>
    {
      // Toggle between IN_LISTING and IN_PROCESS states
      if (vehicleToList.state === State.IN_LISTING) {
        return FleetService.updateStateToInProcess(vehicleToList.id)
      }

      if (vehicleToList.state === State.IN_PROCESS) {
        return FleetService.updateStateToInListing(vehicleToList.id)
      }
      throw new Error('Invalid state')
    }
  )

  const listVehicle = (vehicle: Vehicle.AsObject) => {
    setVehicleToList(vehicle)
  }

  const onListVehicleClose = () => {
    setVehicleToList({} as Vehicle.AsObject)
  }

  const setPage = (nextPage: number) => {
    setQuery({ page: nextPage })
  }

  const setRowsPerPage = (nextRowsPerPage: number) => {
    setQuery({ rowsPerPage: nextRowsPerPage })
  }

  const vehicleQuery = useQuery(
    ['getVehicles', query],
    () => {
      return FleetService.getVehicles({
        criteriaList: [
          {
            property: 'status',
            query: query.status,
          },
          {
            property: 'make',
            query: query.make,
          },
        ],
        listByStatus: false,
        trimPropertiesList: [],
        page: query.page,
        resultsPerPage: query.rowsPerPage,
        customerId: '',
      })
        .then(async (res) => {
          return {
            total: res.total,
            vehiclesList: await createVehicleRows(res.vehiclesList),
          }
        })
        .catch((error) => {
          setFetchListVehiclesError(true)
          console.log('error', error)
          return {
            total: 0,
            vehiclesList: [],
          }
        })
    },
    {
      placeholderData: {
        vehiclesList: [],
        total: 0,
      },
    }
  )

  return {
    total: vehicleQuery?.data?.total,
    vehicles: vehicleQuery?.data?.vehiclesList,
    vehicleQuery,
    activeTab: query.status,
    selectTab,
    listVehicle,
    vehicleToList,
    onListVehicleClose,
    tabs: formattedNexusVehicleStatuses,
    query,
    setQuery,
    fetchListVehiclesError,
    listDelistMutation,
    setPage,
    setRowsPerPage,
  }
}

export async function createVehicleRows(vehicles: NexusVehicle.AsObject[]) {
  return Bluebird.map(vehicles, async ({ vehicle, status }) => {
    const latestCustody = await NexusService.getLatestCustody({
      vehicleId: vehicle.id,
    })

    const isEligible = vehicle.state === VehicleState.IN_LISTING

    return {
      isEligible,
      id: vehicle.id,
      make: vehicle.make,
      model: vehicle.model,
      trim: vehicle.trim,
      vin: vehicle.vin,
      color: vehicle?.exteriorColorsList[0]?.title || 'N/A',
      miles: getMileage(vehicle),
      status: getVehicleStatus(status),
      dealerAddress: formatDealerAddress(latestCustody?.dealership?.address),
      dealerName: latestCustody?.dealership?.name,
      bodyType: vehicle.profilesList?.[0]?.mediaListList?.[1]?.data,
      dateUpdated:
        latestCustody?.dealership?.updatedTime &&
        new Date(latestCustody?.dealership?.updatedTime).toLocaleDateString(),
      list: {
        vehicle,
        isEligible,
      },
    }
  })
}

export function getVehicleStatus(status: NexusVehicle.AsObject['status']) {
  const state = find(formattedNexusVehicleStatuses, (s) => {
    return s.value === status
  })

  if (!state) {
    return 'Unknown Status'
  }

  return state.label
}
