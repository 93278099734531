import {
  PromotionPartner,
} from '@nxcr-org/web-api-interface/lib/billing_service_pb'
import { useQuery } from 'react-query'
import {
  NumberParam,
  useQueryParams,
  withDefault,
} from 'use-query-params'
import { NexusService } from '../global-APIs/nexus-service'
import { formattedPromotionSource } from '../fleet-management/api/reservation.formatted-enums'

export const usePromotions = () => {

  const { data: promos } = useQuery(
    ['/promotions'],
    () => {
      return NexusService.listPromosRequest().then((res) => {
        return { promotions: res.resultsList }
      })
    },
    {
      placeholderData: {
        promotions: [],
      },
    }
  )

  const [{ page, rowsPerPage, }, setQueryParams] =
    useQueryParams({
      page: withDefault(NumberParam, 0),
      rowsPerPage: withDefault(NumberParam, 10),
    })

  const setPage = (nextPage: number) => {
    setQueryParams({ page: nextPage })
  }

  const setRowsPerPage = (nextRowsPerPage: number) => {
    setQueryParams({ rowsPerPage: nextRowsPerPage })
  }

  return {
    promos,
    getPromotionSourceFromEnum,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    getPromoPartnerName,
  }
}

export function getPromotionSourceFromEnum(promoSourceType: number): string {
  if (promoSourceType === formattedPromotionSource.CUSTOMER.value) {
    return formattedPromotionSource.CUSTOMER.label
  }
  return formattedPromotionSource.PARTNER.label
}

export function getPromoPartnerName(promoSourceId: string, promoPartners: PromotionPartner.AsObject[]): string {
  return promoPartners.find(
    (partnerObj) => promoSourceId === partnerObj.id
  )?.name
}
