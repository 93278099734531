import {
  GetInsuranceQuoteResponse,
  GetLatestQuoteGroupResponse,
} from '@nxcr-org/web-api-interface/lib/insurance_service_pb'
import {
  getInsuranceQuote,
  getLatestQuoteGroup,
} from 'global-apis/nexus-service-insurance'
import { useQuery } from 'react-query'

const useInsuranaceQuoteBlock = () => {
  function useInsuranceQuote({ subscriptionId, customerId }) {
    const { data: insuranceQuote, error: insuranceQuoteError } = useQuery(
      ['/insuranceQuote'],
      () =>
        getInsuranceQuote({ subscriptionId, customerId }).then(
          (res: GetInsuranceQuoteResponse.AsObject) => res
        ),
      {
        // placeholderData: {},

        onError() {
          console.log('Error: ', insuranceQuoteError)
        },
      }
    )

    return {
      insuranceQuote,
    }
  }
  function useLatestQuoteGroup({ subscriptionId }) {
    const { data: latestQuoteGroup, error: latestQuoteGroupError } = useQuery(
      ['/latestQuoteGroup'],
      () =>
        getLatestQuoteGroup({ subscriptionId }).then(
          (res: GetLatestQuoteGroupResponse.AsObject) =>
            res.latestquoteresponseList
        ),
      {
        placeholderData: [],
        onError() {
          console.log('Error: ', latestQuoteGroupError)
        },
      }
    )
    return {
      latestQuoteGroup,
    }
  }
  return {
    useInsuranceQuote,
    useLatestQuoteGroup,
  }
}
export default useInsuranaceQuoteBlock
