import React from 'react'
import { Grid } from '@mui/material'
import { useQuery } from 'react-query'
import { getInsuranceStatus } from 'global-apis/nexus-service-insurance'
import ReadOnlyField from './ReadOnlyField'
import { format } from 'date-fns'
import { formatCents } from '../../../../../../utils/currency-formatter'
import { PolicyStatus } from '@nxcr-org/web-api-interface/lib/insurance_pb'
import { getLabelFromEnum } from '../../../../../../utils/formatEnum'

interface InsuranceBlockProps {
  subscriptionId?: string
  customerId?: string
}

function getPolicyStatusFromEnum(num: number): string {
  return getLabelFromEnum(PolicyStatus, num)
}

export function InsuranceBlock({
  customerId: customerId,
  subscriptionId: subscriptionId,
}: InsuranceBlockProps) {
  const { data: insuranceStatus } = useQuery(
    ['insurance-status', subscriptionId],
    () => getInsuranceStatus(customerId, subscriptionId),
    {
      enabled: !!subscriptionId,
    }
  )

  const {
    isQualified,
    policyId,
    effectiveDate,
    expirationDate,
    monthlyRate,
    excessMileageRate,
    status,
  } = insuranceStatus || {}

  return (
    <Grid container spacing={2} direction="column">
      <Grid item>
        <>
          <Grid container spacing={5} mb={5} direction="row">
            <ReadOnlyField
              label="Insurance Type"
              value={isQualified ? 'Integrated' : 'BYOI'}
            />
            <ReadOnlyField
              label="Status"
              value={getPolicyStatusFromEnum(status)}
            />
            <ReadOnlyField
              label="Effective Date"
              value={
                effectiveDate > 0
                  ? format(new Date(effectiveDate), 'MM/dd/yyyy')
                  : ''
              }
            />
            <ReadOnlyField
              label="Expiration Date"
              value={
                expirationDate > 0
                  ? format(new Date(expirationDate), 'MM/dd/yyyy')
                  : ''
              }
            />
          </Grid>
          <Grid container spacing={5} direction="row">
            <ReadOnlyField label="Policy Number" value={policyId} />
            <ReadOnlyField
              label="Premium Monthly / Daily"
              value={`${formatCents(monthlyRate)} / Month`}
            />
            <ReadOnlyField
              label="Mileage Overage Premium"
              value={`${formatCents(excessMileageRate)} / Mile`}
            />
          </Grid>
        </>
      </Grid>
    </Grid>
  )
}
