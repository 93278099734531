import { Box, Typography } from '@mui/material'
import * as React from 'react'
import { FormattedReservationResponse } from '../useReservation'

type CustomerBlockProps = FormattedReservationResponse

export const CustomerBlock: React.FC<CustomerBlockProps> = ({
  customerInfo,
  reservation,
  customer,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        columnGap: '3rem',
        rowGap: '1.5rem',
      }}
    >
      <div>
        <Typography variant="caption">Customer Name</Typography>
        <div>{customerInfo.fullName || 'N/A'}</div>
      </div>
      <div>
        <Typography variant="caption">Email</Typography>
        <div>{customerInfo.email || 'N/A'}</div>
      </div>
      <div>
        <Typography variant="caption">Phone Number</Typography>
        <div>{customerInfo.phone || 'N/A'}</div>
      </div>
      <div>
        <Typography variant="caption">Reservation Created Date</Typography>
        <div>{reservation.formattedCreated || 'N/A'}</div>
      </div>
      <div>
        <Typography variant="caption">Account Number</Typography>
        <div>{customer.accountNumber || 'N/A'}</div>
      </div>
      <div>
        <Typography variant="caption">Dealer</Typography>
        <div>{customerInfo.dealer || 'N/A'}</div>
      </div>
      <div>
        <Typography variant="caption">Zip Code</Typography>
        <div>{customerInfo.zipcode || 'N/A'}</div>
      </div>
      <div>
        <Typography variant="caption">Reservation Number</Typography>
        <div>{customerInfo.reservationNumber || 'N/A'}</div>
      </div>
      <div>
        <Typography variant="caption">Garaging Address</Typography>
        {customerInfo?.garagingAddress?.street ? (
          <div>
            <Typography>{customerInfo.garagingAddress.street}</Typography>
            {customerInfo.garagingAddress.street2 && (
              <Typography>{customerInfo.garagingAddress.street2}</Typography>
            )}
            <Typography>
              {customerInfo.garagingAddress.city},{' '}
              {customerInfo.garagingAddress.state}{' '}
              {customerInfo.garagingAddress.postal}
            </Typography>
          </div>
        ) : (
          'N/A'
        )}
      </div>
      <div>
        <Typography variant="caption">License Address</Typography>
        {customerInfo?.licenseAddress?.street ? (
          <div>
            <Typography>{customerInfo.licenseAddress.street}</Typography>
            {customerInfo.licenseAddress.street2 && (
              <Typography>{customerInfo.licenseAddress.street2}</Typography>
            )}
            <Typography>
              {customerInfo.licenseAddress.city},{' '}
              {customerInfo.licenseAddress.state}{' '}
              {customerInfo.licenseAddress.postal}
            </Typography>
          </div>
        ) : (
          'N/A'
        )}
      </div>
      <div>
        <Typography variant="caption">Customer Address</Typography>
        {customerInfo?.contactAddress?.street ? (
          <div>
            <Typography>{customerInfo.contactAddress.street}</Typography>
            {customerInfo.contactAddress.street2 && (
              <Typography>{customerInfo.contactAddress.street2}</Typography>
            )}
            <Typography>
              {customerInfo.contactAddress.city},{' '}
              {customerInfo.contactAddress.state}{' '}
              {customerInfo.contactAddress.postal}
            </Typography>
          </div>
        ) : (
          'N/A'
        )}
      </div>
    </Box>
  )
}
