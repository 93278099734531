// source: telematics_service.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var vehicle_pb = require('./vehicle_pb.js');
goog.object.extend(proto, vehicle_pb);
goog.exportSymbol('proto.telematics_service.ClusterState', null, global);
goog.exportSymbol('proto.telematics_service.CollisionEvent', null, global);
goog.exportSymbol('proto.telematics_service.CollisionReceived', null, global);
goog.exportSymbol('proto.telematics_service.CommandVehicleRequest', null, global);
goog.exportSymbol('proto.telematics_service.CommandVehicleResponse', null, global);
goog.exportSymbol('proto.telematics_service.DailyDrivingBehavior', null, global);
goog.exportSymbol('proto.telematics_service.DrivingBehavior', null, global);
goog.exportSymbol('proto.telematics_service.EventRating', null, global);
goog.exportSymbol('proto.telematics_service.GetTelematicsRequest', null, global);
goog.exportSymbol('proto.telematics_service.GetTelematicsResponse', null, global);
goog.exportSymbol('proto.telematics_service.Instance', null, global);
goog.exportSymbol('proto.telematics_service.Location', null, global);
goog.exportSymbol('proto.telematics_service.Param', null, global);
goog.exportSymbol('proto.telematics_service.RoutePosition', null, global);
goog.exportSymbol('proto.telematics_service.StreamTelematicsRequest', null, global);
goog.exportSymbol('proto.telematics_service.Telematics', null, global);
goog.exportSymbol('proto.telematics_service.Telematics.ConnectionState', null, global);
goog.exportSymbol('proto.telematics_service.TripEvent', null, global);
goog.exportSymbol('proto.telematics_service.TripReceived', null, global);
goog.exportSymbol('proto.telematics_service.VehicleCommand', null, global);
goog.exportSymbol('proto.telematics_service.VehicleCommand.CommandCase', null, global);
goog.exportSymbol('proto.telematics_service.VehicleScore', null, global);
goog.exportSymbol('proto.telematics_service.VehicleScore.Vehicles', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.telematics_service.GetTelematicsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.telematics_service.GetTelematicsRequest.repeatedFields_, null);
};
goog.inherits(proto.telematics_service.GetTelematicsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.telematics_service.GetTelematicsRequest.displayName = 'proto.telematics_service.GetTelematicsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.telematics_service.Param = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.telematics_service.Param, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.telematics_service.Param.displayName = 'proto.telematics_service.Param';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.telematics_service.GetTelematicsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.telematics_service.GetTelematicsResponse.repeatedFields_, null);
};
goog.inherits(proto.telematics_service.GetTelematicsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.telematics_service.GetTelematicsResponse.displayName = 'proto.telematics_service.GetTelematicsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.telematics_service.StreamTelematicsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.telematics_service.StreamTelematicsRequest.repeatedFields_, null);
};
goog.inherits(proto.telematics_service.StreamTelematicsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.telematics_service.StreamTelematicsRequest.displayName = 'proto.telematics_service.StreamTelematicsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.telematics_service.CommandVehicleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.telematics_service.CommandVehicleRequest.repeatedFields_, null);
};
goog.inherits(proto.telematics_service.CommandVehicleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.telematics_service.CommandVehicleRequest.displayName = 'proto.telematics_service.CommandVehicleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.telematics_service.VehicleCommand = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.telematics_service.VehicleCommand.oneofGroups_);
};
goog.inherits(proto.telematics_service.VehicleCommand, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.telematics_service.VehicleCommand.displayName = 'proto.telematics_service.VehicleCommand';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.telematics_service.CommandVehicleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.telematics_service.CommandVehicleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.telematics_service.CommandVehicleResponse.displayName = 'proto.telematics_service.CommandVehicleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.telematics_service.TripReceived = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.telematics_service.TripReceived.repeatedFields_, null);
};
goog.inherits(proto.telematics_service.TripReceived, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.telematics_service.TripReceived.displayName = 'proto.telematics_service.TripReceived';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.telematics_service.CollisionReceived = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.telematics_service.CollisionReceived.repeatedFields_, null);
};
goog.inherits(proto.telematics_service.CollisionReceived, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.telematics_service.CollisionReceived.displayName = 'proto.telematics_service.CollisionReceived';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.telematics_service.CollisionEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.telematics_service.CollisionEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.telematics_service.CollisionEvent.displayName = 'proto.telematics_service.CollisionEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.telematics_service.Telematics = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.telematics_service.Telematics, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.telematics_service.Telematics.displayName = 'proto.telematics_service.Telematics';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.telematics_service.ClusterState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.telematics_service.ClusterState.repeatedFields_, null);
};
goog.inherits(proto.telematics_service.ClusterState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.telematics_service.ClusterState.displayName = 'proto.telematics_service.ClusterState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.telematics_service.Instance = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.telematics_service.Instance, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.telematics_service.Instance.displayName = 'proto.telematics_service.Instance';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.telematics_service.RoutePosition = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.telematics_service.RoutePosition, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.telematics_service.RoutePosition.displayName = 'proto.telematics_service.RoutePosition';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.telematics_service.Location = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.telematics_service.Location, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.telematics_service.Location.displayName = 'proto.telematics_service.Location';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.telematics_service.TripEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.telematics_service.TripEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.telematics_service.TripEvent.displayName = 'proto.telematics_service.TripEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.telematics_service.DailyDrivingBehavior = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.telematics_service.DailyDrivingBehavior, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.telematics_service.DailyDrivingBehavior.displayName = 'proto.telematics_service.DailyDrivingBehavior';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.telematics_service.DrivingBehavior = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.telematics_service.DrivingBehavior, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.telematics_service.DrivingBehavior.displayName = 'proto.telematics_service.DrivingBehavior';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.telematics_service.VehicleScore = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.telematics_service.VehicleScore, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.telematics_service.VehicleScore.displayName = 'proto.telematics_service.VehicleScore';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.telematics_service.VehicleScore.Vehicles = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.telematics_service.VehicleScore.Vehicles, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.telematics_service.VehicleScore.Vehicles.displayName = 'proto.telematics_service.VehicleScore.Vehicles';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.telematics_service.EventRating = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.telematics_service.EventRating, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.telematics_service.EventRating.displayName = 'proto.telematics_service.EventRating';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.telematics_service.GetTelematicsRequest.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.telematics_service.GetTelematicsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.telematics_service.GetTelematicsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.telematics_service.GetTelematicsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.telematics_service.GetTelematicsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    paramsList: jspb.Message.toObjectList(msg.getParamsList(),
    proto.telematics_service.Param.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.telematics_service.GetTelematicsRequest}
 */
proto.telematics_service.GetTelematicsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.telematics_service.GetTelematicsRequest;
  return proto.telematics_service.GetTelematicsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.telematics_service.GetTelematicsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.telematics_service.GetTelematicsRequest}
 */
proto.telematics_service.GetTelematicsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addVehicleIds(value);
      break;
    case 2:
      var value = new proto.telematics_service.Param;
      reader.readMessage(value,proto.telematics_service.Param.deserializeBinaryFromReader);
      msg.addParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.telematics_service.GetTelematicsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.telematics_service.GetTelematicsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.telematics_service.GetTelematicsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.telematics_service.GetTelematicsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getParamsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.telematics_service.Param.serializeBinaryToWriter
    );
  }
};


/**
 * repeated string vehicle_ids = 1;
 * @return {!Array<string>}
 */
proto.telematics_service.GetTelematicsRequest.prototype.getVehicleIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.telematics_service.GetTelematicsRequest} returns this
 */
proto.telematics_service.GetTelematicsRequest.prototype.setVehicleIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.telematics_service.GetTelematicsRequest} returns this
 */
proto.telematics_service.GetTelematicsRequest.prototype.addVehicleIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.telematics_service.GetTelematicsRequest} returns this
 */
proto.telematics_service.GetTelematicsRequest.prototype.clearVehicleIdsList = function() {
  return this.setVehicleIdsList([]);
};


/**
 * repeated Param params = 2;
 * @return {!Array<!proto.telematics_service.Param>}
 */
proto.telematics_service.GetTelematicsRequest.prototype.getParamsList = function() {
  return /** @type{!Array<!proto.telematics_service.Param>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.telematics_service.Param, 2));
};


/**
 * @param {!Array<!proto.telematics_service.Param>} value
 * @return {!proto.telematics_service.GetTelematicsRequest} returns this
*/
proto.telematics_service.GetTelematicsRequest.prototype.setParamsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.telematics_service.Param=} opt_value
 * @param {number=} opt_index
 * @return {!proto.telematics_service.Param}
 */
proto.telematics_service.GetTelematicsRequest.prototype.addParams = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.telematics_service.Param, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.telematics_service.GetTelematicsRequest} returns this
 */
proto.telematics_service.GetTelematicsRequest.prototype.clearParamsList = function() {
  return this.setParamsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.telematics_service.Param.prototype.toObject = function(opt_includeInstance) {
  return proto.telematics_service.Param.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.telematics_service.Param} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.telematics_service.Param.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.telematics_service.Param}
 */
proto.telematics_service.Param.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.telematics_service.Param;
  return proto.telematics_service.Param.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.telematics_service.Param} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.telematics_service.Param}
 */
proto.telematics_service.Param.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.telematics_service.Param.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.telematics_service.Param.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.telematics_service.Param} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.telematics_service.Param.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.telematics_service.Param.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.telematics_service.Param} returns this
 */
proto.telematics_service.Param.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.telematics_service.Param.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.telematics_service.Param} returns this
 */
proto.telematics_service.Param.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.telematics_service.GetTelematicsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.telematics_service.GetTelematicsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.telematics_service.GetTelematicsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.telematics_service.GetTelematicsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.telematics_service.GetTelematicsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    telematicsList: jspb.Message.toObjectList(msg.getTelematicsList(),
    proto.telematics_service.Telematics.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.telematics_service.GetTelematicsResponse}
 */
proto.telematics_service.GetTelematicsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.telematics_service.GetTelematicsResponse;
  return proto.telematics_service.GetTelematicsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.telematics_service.GetTelematicsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.telematics_service.GetTelematicsResponse}
 */
proto.telematics_service.GetTelematicsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.telematics_service.Telematics;
      reader.readMessage(value,proto.telematics_service.Telematics.deserializeBinaryFromReader);
      msg.addTelematics(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.telematics_service.GetTelematicsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.telematics_service.GetTelematicsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.telematics_service.GetTelematicsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.telematics_service.GetTelematicsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTelematicsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.telematics_service.Telematics.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Telematics telematics = 1;
 * @return {!Array<!proto.telematics_service.Telematics>}
 */
proto.telematics_service.GetTelematicsResponse.prototype.getTelematicsList = function() {
  return /** @type{!Array<!proto.telematics_service.Telematics>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.telematics_service.Telematics, 1));
};


/**
 * @param {!Array<!proto.telematics_service.Telematics>} value
 * @return {!proto.telematics_service.GetTelematicsResponse} returns this
*/
proto.telematics_service.GetTelematicsResponse.prototype.setTelematicsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.telematics_service.Telematics=} opt_value
 * @param {number=} opt_index
 * @return {!proto.telematics_service.Telematics}
 */
proto.telematics_service.GetTelematicsResponse.prototype.addTelematics = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.telematics_service.Telematics, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.telematics_service.GetTelematicsResponse} returns this
 */
proto.telematics_service.GetTelematicsResponse.prototype.clearTelematicsList = function() {
  return this.setTelematicsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.telematics_service.StreamTelematicsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.telematics_service.StreamTelematicsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.telematics_service.StreamTelematicsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.telematics_service.StreamTelematicsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.telematics_service.StreamTelematicsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.telematics_service.StreamTelematicsRequest}
 */
proto.telematics_service.StreamTelematicsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.telematics_service.StreamTelematicsRequest;
  return proto.telematics_service.StreamTelematicsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.telematics_service.StreamTelematicsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.telematics_service.StreamTelematicsRequest}
 */
proto.telematics_service.StreamTelematicsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addVehicleIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.telematics_service.StreamTelematicsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.telematics_service.StreamTelematicsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.telematics_service.StreamTelematicsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.telematics_service.StreamTelematicsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string vehicle_ids = 1;
 * @return {!Array<string>}
 */
proto.telematics_service.StreamTelematicsRequest.prototype.getVehicleIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.telematics_service.StreamTelematicsRequest} returns this
 */
proto.telematics_service.StreamTelematicsRequest.prototype.setVehicleIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.telematics_service.StreamTelematicsRequest} returns this
 */
proto.telematics_service.StreamTelematicsRequest.prototype.addVehicleIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.telematics_service.StreamTelematicsRequest} returns this
 */
proto.telematics_service.StreamTelematicsRequest.prototype.clearVehicleIdsList = function() {
  return this.setVehicleIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.telematics_service.CommandVehicleRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.telematics_service.CommandVehicleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.telematics_service.CommandVehicleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.telematics_service.CommandVehicleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.telematics_service.CommandVehicleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    commandsList: jspb.Message.toObjectList(msg.getCommandsList(),
    proto.telematics_service.VehicleCommand.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.telematics_service.CommandVehicleRequest}
 */
proto.telematics_service.CommandVehicleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.telematics_service.CommandVehicleRequest;
  return proto.telematics_service.CommandVehicleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.telematics_service.CommandVehicleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.telematics_service.CommandVehicleRequest}
 */
proto.telematics_service.CommandVehicleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 2:
      var value = new proto.telematics_service.VehicleCommand;
      reader.readMessage(value,proto.telematics_service.VehicleCommand.deserializeBinaryFromReader);
      msg.addCommands(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.telematics_service.CommandVehicleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.telematics_service.CommandVehicleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.telematics_service.CommandVehicleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.telematics_service.CommandVehicleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCommandsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.telematics_service.VehicleCommand.serializeBinaryToWriter
    );
  }
};


/**
 * optional string vehicle_id = 1;
 * @return {string}
 */
proto.telematics_service.CommandVehicleRequest.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.telematics_service.CommandVehicleRequest} returns this
 */
proto.telematics_service.CommandVehicleRequest.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated VehicleCommand commands = 2;
 * @return {!Array<!proto.telematics_service.VehicleCommand>}
 */
proto.telematics_service.CommandVehicleRequest.prototype.getCommandsList = function() {
  return /** @type{!Array<!proto.telematics_service.VehicleCommand>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.telematics_service.VehicleCommand, 2));
};


/**
 * @param {!Array<!proto.telematics_service.VehicleCommand>} value
 * @return {!proto.telematics_service.CommandVehicleRequest} returns this
*/
proto.telematics_service.CommandVehicleRequest.prototype.setCommandsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.telematics_service.VehicleCommand=} opt_value
 * @param {number=} opt_index
 * @return {!proto.telematics_service.VehicleCommand}
 */
proto.telematics_service.CommandVehicleRequest.prototype.addCommands = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.telematics_service.VehicleCommand, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.telematics_service.CommandVehicleRequest} returns this
 */
proto.telematics_service.CommandVehicleRequest.prototype.clearCommandsList = function() {
  return this.setCommandsList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.telematics_service.VehicleCommand.oneofGroups_ = [[2,3,4,5,6]];

/**
 * @enum {number}
 */
proto.telematics_service.VehicleCommand.CommandCase = {
  COMMAND_NOT_SET: 0,
  HEAD_LIGHTS_ON: 2,
  HEAD_LIGHTS_OFF: 3,
  HONK_HORN: 4,
  UNLOCK_DOORS: 5,
  LOCK_DOORS: 6
};

/**
 * @return {proto.telematics_service.VehicleCommand.CommandCase}
 */
proto.telematics_service.VehicleCommand.prototype.getCommandCase = function() {
  return /** @type {proto.telematics_service.VehicleCommand.CommandCase} */(jspb.Message.computeOneofCase(this, proto.telematics_service.VehicleCommand.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.telematics_service.VehicleCommand.prototype.toObject = function(opt_includeInstance) {
  return proto.telematics_service.VehicleCommand.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.telematics_service.VehicleCommand} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.telematics_service.VehicleCommand.toObject = function(includeInstance, msg) {
  var f, obj = {
    headLightsOn: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    headLightsOff: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    honkHorn: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    unlockDoors: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    lockDoors: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.telematics_service.VehicleCommand}
 */
proto.telematics_service.VehicleCommand.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.telematics_service.VehicleCommand;
  return proto.telematics_service.VehicleCommand.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.telematics_service.VehicleCommand} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.telematics_service.VehicleCommand}
 */
proto.telematics_service.VehicleCommand.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHeadLightsOn(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHeadLightsOff(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHonkHorn(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUnlockDoors(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLockDoors(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.telematics_service.VehicleCommand.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.telematics_service.VehicleCommand.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.telematics_service.VehicleCommand} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.telematics_service.VehicleCommand.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {boolean} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBool(
      2,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeBool(
      3,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeBool(
      4,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeBool(
      5,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional bool head_lights_on = 2;
 * @return {boolean}
 */
proto.telematics_service.VehicleCommand.prototype.getHeadLightsOn = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.telematics_service.VehicleCommand} returns this
 */
proto.telematics_service.VehicleCommand.prototype.setHeadLightsOn = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.telematics_service.VehicleCommand.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.telematics_service.VehicleCommand} returns this
 */
proto.telematics_service.VehicleCommand.prototype.clearHeadLightsOn = function() {
  return jspb.Message.setOneofField(this, 2, proto.telematics_service.VehicleCommand.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.telematics_service.VehicleCommand.prototype.hasHeadLightsOn = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool head_lights_off = 3;
 * @return {boolean}
 */
proto.telematics_service.VehicleCommand.prototype.getHeadLightsOff = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.telematics_service.VehicleCommand} returns this
 */
proto.telematics_service.VehicleCommand.prototype.setHeadLightsOff = function(value) {
  return jspb.Message.setOneofField(this, 3, proto.telematics_service.VehicleCommand.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.telematics_service.VehicleCommand} returns this
 */
proto.telematics_service.VehicleCommand.prototype.clearHeadLightsOff = function() {
  return jspb.Message.setOneofField(this, 3, proto.telematics_service.VehicleCommand.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.telematics_service.VehicleCommand.prototype.hasHeadLightsOff = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool honk_horn = 4;
 * @return {boolean}
 */
proto.telematics_service.VehicleCommand.prototype.getHonkHorn = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.telematics_service.VehicleCommand} returns this
 */
proto.telematics_service.VehicleCommand.prototype.setHonkHorn = function(value) {
  return jspb.Message.setOneofField(this, 4, proto.telematics_service.VehicleCommand.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.telematics_service.VehicleCommand} returns this
 */
proto.telematics_service.VehicleCommand.prototype.clearHonkHorn = function() {
  return jspb.Message.setOneofField(this, 4, proto.telematics_service.VehicleCommand.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.telematics_service.VehicleCommand.prototype.hasHonkHorn = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool unlock_doors = 5;
 * @return {boolean}
 */
proto.telematics_service.VehicleCommand.prototype.getUnlockDoors = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.telematics_service.VehicleCommand} returns this
 */
proto.telematics_service.VehicleCommand.prototype.setUnlockDoors = function(value) {
  return jspb.Message.setOneofField(this, 5, proto.telematics_service.VehicleCommand.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.telematics_service.VehicleCommand} returns this
 */
proto.telematics_service.VehicleCommand.prototype.clearUnlockDoors = function() {
  return jspb.Message.setOneofField(this, 5, proto.telematics_service.VehicleCommand.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.telematics_service.VehicleCommand.prototype.hasUnlockDoors = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bool lock_doors = 6;
 * @return {boolean}
 */
proto.telematics_service.VehicleCommand.prototype.getLockDoors = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.telematics_service.VehicleCommand} returns this
 */
proto.telematics_service.VehicleCommand.prototype.setLockDoors = function(value) {
  return jspb.Message.setOneofField(this, 6, proto.telematics_service.VehicleCommand.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.telematics_service.VehicleCommand} returns this
 */
proto.telematics_service.VehicleCommand.prototype.clearLockDoors = function() {
  return jspb.Message.setOneofField(this, 6, proto.telematics_service.VehicleCommand.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.telematics_service.VehicleCommand.prototype.hasLockDoors = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.telematics_service.CommandVehicleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.telematics_service.CommandVehicleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.telematics_service.CommandVehicleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.telematics_service.CommandVehicleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    msg: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.telematics_service.CommandVehicleResponse}
 */
proto.telematics_service.CommandVehicleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.telematics_service.CommandVehicleResponse;
  return proto.telematics_service.CommandVehicleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.telematics_service.CommandVehicleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.telematics_service.CommandVehicleResponse}
 */
proto.telematics_service.CommandVehicleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMsg(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.telematics_service.CommandVehicleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.telematics_service.CommandVehicleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.telematics_service.CommandVehicleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.telematics_service.CommandVehicleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMsg();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string msg = 1;
 * @return {string}
 */
proto.telematics_service.CommandVehicleResponse.prototype.getMsg = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.telematics_service.CommandVehicleResponse} returns this
 */
proto.telematics_service.CommandVehicleResponse.prototype.setMsg = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.telematics_service.TripReceived.repeatedFields_ = [21];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.telematics_service.TripReceived.prototype.toObject = function(opt_includeInstance) {
  return proto.telematics_service.TripReceived.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.telematics_service.TripReceived} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.telematics_service.TripReceived.toObject = function(includeInstance, msg) {
  var f, obj = {
    driverid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    vehicleid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    tripid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    starttime: jspb.Message.getFieldWithDefault(msg, 4, ""),
    endtime: jspb.Message.getFieldWithDefault(msg, 5, ""),
    tripmaxspeed: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    tripduration: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    distance: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    sessionid: jspb.Message.getFieldWithDefault(msg, 9, ""),
    trackingid: jspb.Message.getFieldWithDefault(msg, 10, ""),
    insuranceperiod: jspb.Message.getFieldWithDefault(msg, 11, 0),
    usermode: jspb.Message.getFieldWithDefault(msg, 12, ""),
    drivetype: jspb.Message.getFieldWithDefault(msg, 13, ""),
    vehicletype: jspb.Message.getFieldWithDefault(msg, 14, ""),
    hardbrakerating: jspb.Message.getFieldWithDefault(msg, 15, 0),
    hardturnrating: jspb.Message.getFieldWithDefault(msg, 16, 0),
    rapidaccelerationrating: jspb.Message.getFieldWithDefault(msg, 17, 0),
    phoneuserating: jspb.Message.getFieldWithDefault(msg, 18, 0),
    overspeedingrating: jspb.Message.getFieldWithDefault(msg, 19, 0),
    drivingscore: jspb.Message.getFieldWithDefault(msg, 20, 0),
    tripeventsList: jspb.Message.toObjectList(msg.getTripeventsList(),
    proto.telematics_service.TripEvent.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.telematics_service.TripReceived}
 */
proto.telematics_service.TripReceived.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.telematics_service.TripReceived;
  return proto.telematics_service.TripReceived.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.telematics_service.TripReceived} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.telematics_service.TripReceived}
 */
proto.telematics_service.TripReceived.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDriverid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTripid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStarttime(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndtime(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTripmaxspeed(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTripduration(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDistance(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionid(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrackingid(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setInsuranceperiod(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsermode(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setDrivetype(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicletype(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHardbrakerating(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHardturnrating(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRapidaccelerationrating(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPhoneuserating(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOverspeedingrating(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDrivingscore(value);
      break;
    case 21:
      var value = new proto.telematics_service.TripEvent;
      reader.readMessage(value,proto.telematics_service.TripEvent.deserializeBinaryFromReader);
      msg.addTripevents(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.telematics_service.TripReceived.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.telematics_service.TripReceived.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.telematics_service.TripReceived} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.telematics_service.TripReceived.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDriverid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVehicleid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTripid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStarttime();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEndtime();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getTripmaxspeed();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getTripduration();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getDistance();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getSessionid();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getTrackingid();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getInsuranceperiod();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getUsermode();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getDrivetype();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getVehicletype();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getHardbrakerating();
  if (f !== 0) {
    writer.writeInt32(
      15,
      f
    );
  }
  f = message.getHardturnrating();
  if (f !== 0) {
    writer.writeInt32(
      16,
      f
    );
  }
  f = message.getRapidaccelerationrating();
  if (f !== 0) {
    writer.writeInt32(
      17,
      f
    );
  }
  f = message.getPhoneuserating();
  if (f !== 0) {
    writer.writeInt32(
      18,
      f
    );
  }
  f = message.getOverspeedingrating();
  if (f !== 0) {
    writer.writeInt32(
      19,
      f
    );
  }
  f = message.getDrivingscore();
  if (f !== 0) {
    writer.writeInt32(
      20,
      f
    );
  }
  f = message.getTripeventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      21,
      f,
      proto.telematics_service.TripEvent.serializeBinaryToWriter
    );
  }
};


/**
 * optional string DriverId = 1;
 * @return {string}
 */
proto.telematics_service.TripReceived.prototype.getDriverid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.telematics_service.TripReceived} returns this
 */
proto.telematics_service.TripReceived.prototype.setDriverid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string VehicleId = 2;
 * @return {string}
 */
proto.telematics_service.TripReceived.prototype.getVehicleid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.telematics_service.TripReceived} returns this
 */
proto.telematics_service.TripReceived.prototype.setVehicleid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string TripId = 3;
 * @return {string}
 */
proto.telematics_service.TripReceived.prototype.getTripid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.telematics_service.TripReceived} returns this
 */
proto.telematics_service.TripReceived.prototype.setTripid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string StartTime = 4;
 * @return {string}
 */
proto.telematics_service.TripReceived.prototype.getStarttime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.telematics_service.TripReceived} returns this
 */
proto.telematics_service.TripReceived.prototype.setStarttime = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string EndTime = 5;
 * @return {string}
 */
proto.telematics_service.TripReceived.prototype.getEndtime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.telematics_service.TripReceived} returns this
 */
proto.telematics_service.TripReceived.prototype.setEndtime = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional float TripMaxSpeed = 6;
 * @return {number}
 */
proto.telematics_service.TripReceived.prototype.getTripmaxspeed = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.telematics_service.TripReceived} returns this
 */
proto.telematics_service.TripReceived.prototype.setTripmaxspeed = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float TripDuration = 7;
 * @return {number}
 */
proto.telematics_service.TripReceived.prototype.getTripduration = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.telematics_service.TripReceived} returns this
 */
proto.telematics_service.TripReceived.prototype.setTripduration = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional float Distance = 8;
 * @return {number}
 */
proto.telematics_service.TripReceived.prototype.getDistance = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.telematics_service.TripReceived} returns this
 */
proto.telematics_service.TripReceived.prototype.setDistance = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional string SessionId = 9;
 * @return {string}
 */
proto.telematics_service.TripReceived.prototype.getSessionid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.telematics_service.TripReceived} returns this
 */
proto.telematics_service.TripReceived.prototype.setSessionid = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string TrackingId = 10;
 * @return {string}
 */
proto.telematics_service.TripReceived.prototype.getTrackingid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.telematics_service.TripReceived} returns this
 */
proto.telematics_service.TripReceived.prototype.setTrackingid = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional int64 InsurancePeriod = 11;
 * @return {number}
 */
proto.telematics_service.TripReceived.prototype.getInsuranceperiod = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.telematics_service.TripReceived} returns this
 */
proto.telematics_service.TripReceived.prototype.setInsuranceperiod = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional string UserMode = 12;
 * @return {string}
 */
proto.telematics_service.TripReceived.prototype.getUsermode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.telematics_service.TripReceived} returns this
 */
proto.telematics_service.TripReceived.prototype.setUsermode = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string DriveType = 13;
 * @return {string}
 */
proto.telematics_service.TripReceived.prototype.getDrivetype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.telematics_service.TripReceived} returns this
 */
proto.telematics_service.TripReceived.prototype.setDrivetype = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string VehicleType = 14;
 * @return {string}
 */
proto.telematics_service.TripReceived.prototype.getVehicletype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.telematics_service.TripReceived} returns this
 */
proto.telematics_service.TripReceived.prototype.setVehicletype = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional int32 HardBrakeRating = 15;
 * @return {number}
 */
proto.telematics_service.TripReceived.prototype.getHardbrakerating = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.telematics_service.TripReceived} returns this
 */
proto.telematics_service.TripReceived.prototype.setHardbrakerating = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional int32 HardTurnRating = 16;
 * @return {number}
 */
proto.telematics_service.TripReceived.prototype.getHardturnrating = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.telematics_service.TripReceived} returns this
 */
proto.telematics_service.TripReceived.prototype.setHardturnrating = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional int32 RapidAccelerationRating = 17;
 * @return {number}
 */
proto.telematics_service.TripReceived.prototype.getRapidaccelerationrating = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.telematics_service.TripReceived} returns this
 */
proto.telematics_service.TripReceived.prototype.setRapidaccelerationrating = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional int32 PhoneUseRating = 18;
 * @return {number}
 */
proto.telematics_service.TripReceived.prototype.getPhoneuserating = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.telematics_service.TripReceived} returns this
 */
proto.telematics_service.TripReceived.prototype.setPhoneuserating = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * optional int32 OverspeedingRating = 19;
 * @return {number}
 */
proto.telematics_service.TripReceived.prototype.getOverspeedingrating = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {number} value
 * @return {!proto.telematics_service.TripReceived} returns this
 */
proto.telematics_service.TripReceived.prototype.setOverspeedingrating = function(value) {
  return jspb.Message.setProto3IntField(this, 19, value);
};


/**
 * optional int32 DrivingScore = 20;
 * @return {number}
 */
proto.telematics_service.TripReceived.prototype.getDrivingscore = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.telematics_service.TripReceived} returns this
 */
proto.telematics_service.TripReceived.prototype.setDrivingscore = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * repeated TripEvent TripEvents = 21;
 * @return {!Array<!proto.telematics_service.TripEvent>}
 */
proto.telematics_service.TripReceived.prototype.getTripeventsList = function() {
  return /** @type{!Array<!proto.telematics_service.TripEvent>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.telematics_service.TripEvent, 21));
};


/**
 * @param {!Array<!proto.telematics_service.TripEvent>} value
 * @return {!proto.telematics_service.TripReceived} returns this
*/
proto.telematics_service.TripReceived.prototype.setTripeventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 21, value);
};


/**
 * @param {!proto.telematics_service.TripEvent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.telematics_service.TripEvent}
 */
proto.telematics_service.TripReceived.prototype.addTripevents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 21, opt_value, proto.telematics_service.TripEvent, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.telematics_service.TripReceived} returns this
 */
proto.telematics_service.TripReceived.prototype.clearTripeventsList = function() {
  return this.setTripeventsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.telematics_service.CollisionReceived.repeatedFields_ = [26];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.telematics_service.CollisionReceived.prototype.toObject = function(opt_includeInstance) {
  return proto.telematics_service.CollisionReceived.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.telematics_service.CollisionReceived} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.telematics_service.CollisionReceived.toObject = function(includeInstance, msg) {
  var f, obj = {
    driverid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    vehicleid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    collisionid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    time: jspb.Message.getFieldWithDefault(msg, 4, ""),
    roadtype: jspb.Message.getFieldWithDefault(msg, 5, ""),
    roadspeedlimit: jspb.Message.getFieldWithDefault(msg, 6, 0),
    currentspeed: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    gforce: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    headingdegrees: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    brakedistance: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    decelerationratemilesperhourpersecond: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    airbagdeployed: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    confidencenumber: jspb.Message.getFieldWithDefault(msg, 13, 0),
    confidence: jspb.Message.getFieldWithDefault(msg, 14, ""),
    tripmaxspeedkmph: jspb.Message.getFloatingPointFieldWithDefault(msg, 15, 0.0),
    durationseconds: jspb.Message.getFloatingPointFieldWithDefault(msg, 16, 0.0),
    distance: jspb.Message.getFloatingPointFieldWithDefault(msg, 17, 0.0),
    sessionid: jspb.Message.getFieldWithDefault(msg, 18, ""),
    trackingid: jspb.Message.getFieldWithDefault(msg, 19, ""),
    insuranceperiod: jspb.Message.getFieldWithDefault(msg, 20, 0),
    usermode: jspb.Message.getFieldWithDefault(msg, 21, ""),
    drivetype: jspb.Message.getFieldWithDefault(msg, 22, ""),
    message: jspb.Message.getFieldWithDefault(msg, 23, ""),
    latitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 24, 0.0),
    longitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 25, 0.0),
    collisioneventsList: jspb.Message.toObjectList(msg.getCollisioneventsList(),
    proto.telematics_service.CollisionEvent.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.telematics_service.CollisionReceived}
 */
proto.telematics_service.CollisionReceived.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.telematics_service.CollisionReceived;
  return proto.telematics_service.CollisionReceived.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.telematics_service.CollisionReceived} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.telematics_service.CollisionReceived}
 */
proto.telematics_service.CollisionReceived.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDriverid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCollisionid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTime(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoadtype(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRoadspeedlimit(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setCurrentspeed(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setGforce(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setHeadingdegrees(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setBrakedistance(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDecelerationratemilesperhourpersecond(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAirbagdeployed(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setConfidencenumber(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setConfidence(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTripmaxspeedkmph(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDurationseconds(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDistance(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionid(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrackingid(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setInsuranceperiod(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsermode(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setDrivetype(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLatitude(value);
      break;
    case 25:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLongitude(value);
      break;
    case 26:
      var value = new proto.telematics_service.CollisionEvent;
      reader.readMessage(value,proto.telematics_service.CollisionEvent.deserializeBinaryFromReader);
      msg.addCollisionevents(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.telematics_service.CollisionReceived.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.telematics_service.CollisionReceived.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.telematics_service.CollisionReceived} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.telematics_service.CollisionReceived.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDriverid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVehicleid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCollisionid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTime();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getRoadtype();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getRoadspeedlimit();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getCurrentspeed();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getGforce();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getHeadingdegrees();
  if (f !== 0.0) {
    writer.writeFloat(
      9,
      f
    );
  }
  f = message.getBrakedistance();
  if (f !== 0.0) {
    writer.writeFloat(
      10,
      f
    );
  }
  f = message.getDecelerationratemilesperhourpersecond();
  if (f !== 0.0) {
    writer.writeFloat(
      11,
      f
    );
  }
  f = message.getAirbagdeployed();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getConfidencenumber();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
  f = message.getConfidence();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getTripmaxspeedkmph();
  if (f !== 0.0) {
    writer.writeFloat(
      15,
      f
    );
  }
  f = message.getDurationseconds();
  if (f !== 0.0) {
    writer.writeFloat(
      16,
      f
    );
  }
  f = message.getDistance();
  if (f !== 0.0) {
    writer.writeFloat(
      17,
      f
    );
  }
  f = message.getSessionid();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getTrackingid();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getInsuranceperiod();
  if (f !== 0) {
    writer.writeInt32(
      20,
      f
    );
  }
  f = message.getUsermode();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getDrivetype();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getLatitude();
  if (f !== 0.0) {
    writer.writeFloat(
      24,
      f
    );
  }
  f = message.getLongitude();
  if (f !== 0.0) {
    writer.writeFloat(
      25,
      f
    );
  }
  f = message.getCollisioneventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      26,
      f,
      proto.telematics_service.CollisionEvent.serializeBinaryToWriter
    );
  }
};


/**
 * optional string DriverId = 1;
 * @return {string}
 */
proto.telematics_service.CollisionReceived.prototype.getDriverid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.telematics_service.CollisionReceived} returns this
 */
proto.telematics_service.CollisionReceived.prototype.setDriverid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string VehicleId = 2;
 * @return {string}
 */
proto.telematics_service.CollisionReceived.prototype.getVehicleid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.telematics_service.CollisionReceived} returns this
 */
proto.telematics_service.CollisionReceived.prototype.setVehicleid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string CollisionId = 3;
 * @return {string}
 */
proto.telematics_service.CollisionReceived.prototype.getCollisionid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.telematics_service.CollisionReceived} returns this
 */
proto.telematics_service.CollisionReceived.prototype.setCollisionid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string Time = 4;
 * @return {string}
 */
proto.telematics_service.CollisionReceived.prototype.getTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.telematics_service.CollisionReceived} returns this
 */
proto.telematics_service.CollisionReceived.prototype.setTime = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string RoadType = 5;
 * @return {string}
 */
proto.telematics_service.CollisionReceived.prototype.getRoadtype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.telematics_service.CollisionReceived} returns this
 */
proto.telematics_service.CollisionReceived.prototype.setRoadtype = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int32 RoadSpeedLimit = 6;
 * @return {number}
 */
proto.telematics_service.CollisionReceived.prototype.getRoadspeedlimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.telematics_service.CollisionReceived} returns this
 */
proto.telematics_service.CollisionReceived.prototype.setRoadspeedlimit = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional float CurrentSpeed = 7;
 * @return {number}
 */
proto.telematics_service.CollisionReceived.prototype.getCurrentspeed = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.telematics_service.CollisionReceived} returns this
 */
proto.telematics_service.CollisionReceived.prototype.setCurrentspeed = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional float Gforce = 8;
 * @return {number}
 */
proto.telematics_service.CollisionReceived.prototype.getGforce = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.telematics_service.CollisionReceived} returns this
 */
proto.telematics_service.CollisionReceived.prototype.setGforce = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional float HeadingDegrees = 9;
 * @return {number}
 */
proto.telematics_service.CollisionReceived.prototype.getHeadingdegrees = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.telematics_service.CollisionReceived} returns this
 */
proto.telematics_service.CollisionReceived.prototype.setHeadingdegrees = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional float BrakeDistance = 10;
 * @return {number}
 */
proto.telematics_service.CollisionReceived.prototype.getBrakedistance = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.telematics_service.CollisionReceived} returns this
 */
proto.telematics_service.CollisionReceived.prototype.setBrakedistance = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional float DecelerationRateMilesPerHourPerSecond = 11;
 * @return {number}
 */
proto.telematics_service.CollisionReceived.prototype.getDecelerationratemilesperhourpersecond = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.telematics_service.CollisionReceived} returns this
 */
proto.telematics_service.CollisionReceived.prototype.setDecelerationratemilesperhourpersecond = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional bool AirbagDeployed = 12;
 * @return {boolean}
 */
proto.telematics_service.CollisionReceived.prototype.getAirbagdeployed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.telematics_service.CollisionReceived} returns this
 */
proto.telematics_service.CollisionReceived.prototype.setAirbagdeployed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional int32 ConfidenceNumber = 13;
 * @return {number}
 */
proto.telematics_service.CollisionReceived.prototype.getConfidencenumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.telematics_service.CollisionReceived} returns this
 */
proto.telematics_service.CollisionReceived.prototype.setConfidencenumber = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional string Confidence = 14;
 * @return {string}
 */
proto.telematics_service.CollisionReceived.prototype.getConfidence = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.telematics_service.CollisionReceived} returns this
 */
proto.telematics_service.CollisionReceived.prototype.setConfidence = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional float TripMaxSpeedKmph = 15;
 * @return {number}
 */
proto.telematics_service.CollisionReceived.prototype.getTripmaxspeedkmph = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 15, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.telematics_service.CollisionReceived} returns this
 */
proto.telematics_service.CollisionReceived.prototype.setTripmaxspeedkmph = function(value) {
  return jspb.Message.setProto3FloatField(this, 15, value);
};


/**
 * optional float DurationSeconds = 16;
 * @return {number}
 */
proto.telematics_service.CollisionReceived.prototype.getDurationseconds = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 16, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.telematics_service.CollisionReceived} returns this
 */
proto.telematics_service.CollisionReceived.prototype.setDurationseconds = function(value) {
  return jspb.Message.setProto3FloatField(this, 16, value);
};


/**
 * optional float Distance = 17;
 * @return {number}
 */
proto.telematics_service.CollisionReceived.prototype.getDistance = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 17, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.telematics_service.CollisionReceived} returns this
 */
proto.telematics_service.CollisionReceived.prototype.setDistance = function(value) {
  return jspb.Message.setProto3FloatField(this, 17, value);
};


/**
 * optional string SessionId = 18;
 * @return {string}
 */
proto.telematics_service.CollisionReceived.prototype.getSessionid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.telematics_service.CollisionReceived} returns this
 */
proto.telematics_service.CollisionReceived.prototype.setSessionid = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string TrackingId = 19;
 * @return {string}
 */
proto.telematics_service.CollisionReceived.prototype.getTrackingid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.telematics_service.CollisionReceived} returns this
 */
proto.telematics_service.CollisionReceived.prototype.setTrackingid = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional int32 InsurancePeriod = 20;
 * @return {number}
 */
proto.telematics_service.CollisionReceived.prototype.getInsuranceperiod = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.telematics_service.CollisionReceived} returns this
 */
proto.telematics_service.CollisionReceived.prototype.setInsuranceperiod = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * optional string UserMode = 21;
 * @return {string}
 */
proto.telematics_service.CollisionReceived.prototype.getUsermode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.telematics_service.CollisionReceived} returns this
 */
proto.telematics_service.CollisionReceived.prototype.setUsermode = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string DriveType = 22;
 * @return {string}
 */
proto.telematics_service.CollisionReceived.prototype.getDrivetype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.telematics_service.CollisionReceived} returns this
 */
proto.telematics_service.CollisionReceived.prototype.setDrivetype = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional string Message = 23;
 * @return {string}
 */
proto.telematics_service.CollisionReceived.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.telematics_service.CollisionReceived} returns this
 */
proto.telematics_service.CollisionReceived.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional float Latitude = 24;
 * @return {number}
 */
proto.telematics_service.CollisionReceived.prototype.getLatitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 24, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.telematics_service.CollisionReceived} returns this
 */
proto.telematics_service.CollisionReceived.prototype.setLatitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 24, value);
};


/**
 * optional float Longitude = 25;
 * @return {number}
 */
proto.telematics_service.CollisionReceived.prototype.getLongitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 25, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.telematics_service.CollisionReceived} returns this
 */
proto.telematics_service.CollisionReceived.prototype.setLongitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 25, value);
};


/**
 * repeated CollisionEvent CollisionEvents = 26;
 * @return {!Array<!proto.telematics_service.CollisionEvent>}
 */
proto.telematics_service.CollisionReceived.prototype.getCollisioneventsList = function() {
  return /** @type{!Array<!proto.telematics_service.CollisionEvent>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.telematics_service.CollisionEvent, 26));
};


/**
 * @param {!Array<!proto.telematics_service.CollisionEvent>} value
 * @return {!proto.telematics_service.CollisionReceived} returns this
*/
proto.telematics_service.CollisionReceived.prototype.setCollisioneventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 26, value);
};


/**
 * @param {!proto.telematics_service.CollisionEvent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.telematics_service.CollisionEvent}
 */
proto.telematics_service.CollisionReceived.prototype.addCollisionevents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 26, opt_value, proto.telematics_service.CollisionEvent, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.telematics_service.CollisionReceived} returns this
 */
proto.telematics_service.CollisionReceived.prototype.clearCollisioneventsList = function() {
  return this.setCollisioneventsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.telematics_service.CollisionEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.telematics_service.CollisionEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.telematics_service.CollisionEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.telematics_service.CollisionEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventtype: jspb.Message.getFieldWithDefault(msg, 1, 0),
    eventtypename: jspb.Message.getFieldWithDefault(msg, 2, ""),
    latitudeend: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    longitudeend: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    longitudestart: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    latitudestart: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    averagedriverspeedmph: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    maxdriverspeedmph: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    endtime: jspb.Message.getFieldWithDefault(msg, 9, ""),
    postedspeedlimitmph: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    starttime: jspb.Message.getFieldWithDefault(msg, 11, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.telematics_service.CollisionEvent}
 */
proto.telematics_service.CollisionEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.telematics_service.CollisionEvent;
  return proto.telematics_service.CollisionEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.telematics_service.CollisionEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.telematics_service.CollisionEvent}
 */
proto.telematics_service.CollisionEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEventtype(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventtypename(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLatitudeend(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLongitudeend(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLongitudestart(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLatitudestart(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAveragedriverspeedmph(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMaxdriverspeedmph(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndtime(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setPostedspeedlimitmph(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setStarttime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.telematics_service.CollisionEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.telematics_service.CollisionEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.telematics_service.CollisionEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.telematics_service.CollisionEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventtype();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getEventtypename();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLatitudeend();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getLongitudeend();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getLongitudestart();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getLatitudestart();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getAveragedriverspeedmph();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getMaxdriverspeedmph();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getEndtime();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getPostedspeedlimitmph();
  if (f !== 0.0) {
    writer.writeFloat(
      10,
      f
    );
  }
  f = message.getStarttime();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
};


/**
 * optional int32 EventType = 1;
 * @return {number}
 */
proto.telematics_service.CollisionEvent.prototype.getEventtype = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.telematics_service.CollisionEvent} returns this
 */
proto.telematics_service.CollisionEvent.prototype.setEventtype = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string EventTypeName = 2;
 * @return {string}
 */
proto.telematics_service.CollisionEvent.prototype.getEventtypename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.telematics_service.CollisionEvent} returns this
 */
proto.telematics_service.CollisionEvent.prototype.setEventtypename = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional float LatitudeEnd = 3;
 * @return {number}
 */
proto.telematics_service.CollisionEvent.prototype.getLatitudeend = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.telematics_service.CollisionEvent} returns this
 */
proto.telematics_service.CollisionEvent.prototype.setLatitudeend = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float LongitudeEnd = 4;
 * @return {number}
 */
proto.telematics_service.CollisionEvent.prototype.getLongitudeend = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.telematics_service.CollisionEvent} returns this
 */
proto.telematics_service.CollisionEvent.prototype.setLongitudeend = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float LongitudeStart = 5;
 * @return {number}
 */
proto.telematics_service.CollisionEvent.prototype.getLongitudestart = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.telematics_service.CollisionEvent} returns this
 */
proto.telematics_service.CollisionEvent.prototype.setLongitudestart = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float LatitudeStart = 6;
 * @return {number}
 */
proto.telematics_service.CollisionEvent.prototype.getLatitudestart = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.telematics_service.CollisionEvent} returns this
 */
proto.telematics_service.CollisionEvent.prototype.setLatitudestart = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float AverageDriverSpeedMph = 7;
 * @return {number}
 */
proto.telematics_service.CollisionEvent.prototype.getAveragedriverspeedmph = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.telematics_service.CollisionEvent} returns this
 */
proto.telematics_service.CollisionEvent.prototype.setAveragedriverspeedmph = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional float MaxDriverSpeedMph = 8;
 * @return {number}
 */
proto.telematics_service.CollisionEvent.prototype.getMaxdriverspeedmph = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.telematics_service.CollisionEvent} returns this
 */
proto.telematics_service.CollisionEvent.prototype.setMaxdriverspeedmph = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional string EndTime = 9;
 * @return {string}
 */
proto.telematics_service.CollisionEvent.prototype.getEndtime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.telematics_service.CollisionEvent} returns this
 */
proto.telematics_service.CollisionEvent.prototype.setEndtime = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional float PostedSpeedLimitMph = 10;
 * @return {number}
 */
proto.telematics_service.CollisionEvent.prototype.getPostedspeedlimitmph = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.telematics_service.CollisionEvent} returns this
 */
proto.telematics_service.CollisionEvent.prototype.setPostedspeedlimitmph = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional string StartTime = 11;
 * @return {string}
 */
proto.telematics_service.CollisionEvent.prototype.getStarttime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.telematics_service.CollisionEvent} returns this
 */
proto.telematics_service.CollisionEvent.prototype.setStarttime = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.telematics_service.Telematics.prototype.toObject = function(opt_includeInstance) {
  return proto.telematics_service.Telematics.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.telematics_service.Telematics} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.telematics_service.Telematics.toObject = function(includeInstance, msg) {
  var f, obj = {
    internalId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    externalId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    vin: jspb.Message.getFieldWithDefault(msg, 3, ""),
    location: (f = msg.getLocation()) && proto.telematics_service.Location.toObject(includeInstance, f),
    odometer: (f = msg.getOdometer()) && vehicle_pb.Odometer.toObject(includeInstance, f),
    metadataMap: (f = msg.getMetadataMap()) ? f.toObject(includeInstance, undefined) : [],
    timestamp: jspb.Message.getFieldWithDefault(msg, 7, 0),
    make: jspb.Message.getFieldWithDefault(msg, 8, ""),
    model: jspb.Message.getFieldWithDefault(msg, 9, ""),
    year: jspb.Message.getFieldWithDefault(msg, 10, 0),
    lastConnectionTime: jspb.Message.getFieldWithDefault(msg, 11, 0),
    oemId: jspb.Message.getFieldWithDefault(msg, 12, ""),
    currentConnectionAttempts: jspb.Message.getFieldWithDefault(msg, 13, 0),
    connectionState: jspb.Message.getFieldWithDefault(msg, 14, 0),
    custodianId: jspb.Message.getFieldWithDefault(msg, 15, ""),
    custodianType: jspb.Message.getFieldWithDefault(msg, 16, 0),
    chargeState: jspb.Message.getFieldWithDefault(msg, 17, 0),
    locked: jspb.Message.getBooleanFieldWithDefault(msg, 18, false),
    fdWindow: jspb.Message.getFieldWithDefault(msg, 19, 0),
    fpWindow: jspb.Message.getFieldWithDefault(msg, 20, 0),
    dashcamState: jspb.Message.getFieldWithDefault(msg, 21, ""),
    batteryLevel: jspb.Message.getFieldWithDefault(msg, 22, 0),
    batteryRange: jspb.Message.getFloatingPointFieldWithDefault(msg, 23, 0.0),
    rdWindow: jspb.Message.getFieldWithDefault(msg, 24, 0),
    rpWindow: jspb.Message.getFieldWithDefault(msg, 25, 0),
    sentryMode: jspb.Message.getBooleanFieldWithDefault(msg, 26, false),
    tpmsPressureFl: jspb.Message.getFloatingPointFieldWithDefault(msg, 27, 0.0),
    tpmsPressureFr: jspb.Message.getFloatingPointFieldWithDefault(msg, 28, 0.0),
    tpmsPressureRl: jspb.Message.getFloatingPointFieldWithDefault(msg, 29, 0.0),
    tpmsPressureRr: jspb.Message.getFloatingPointFieldWithDefault(msg, 30, 0.0),
    payload: jspb.Message.getFieldWithDefault(msg, 31, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.telematics_service.Telematics}
 */
proto.telematics_service.Telematics.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.telematics_service.Telematics;
  return proto.telematics_service.Telematics.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.telematics_service.Telematics} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.telematics_service.Telematics}
 */
proto.telematics_service.Telematics.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInternalId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setVin(value);
      break;
    case 4:
      var value = new proto.telematics_service.Location;
      reader.readMessage(value,proto.telematics_service.Location.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 5:
      var value = new vehicle_pb.Odometer;
      reader.readMessage(value,vehicle_pb.Odometer.deserializeBinaryFromReader);
      msg.setOdometer(value);
      break;
    case 6:
      var value = msg.getMetadataMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestamp(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setMake(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setModel(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setYear(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastConnectionTime(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setOemId(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCurrentConnectionAttempts(value);
      break;
    case 14:
      var value = /** @type {!proto.telematics_service.Telematics.ConnectionState} */ (reader.readEnum());
      msg.setConnectionState(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustodianId(value);
      break;
    case 16:
      var value = /** @type {!proto.vehicle.CustodianType} */ (reader.readEnum());
      msg.setCustodianType(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setChargeState(value);
      break;
    case 18:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLocked(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFdWindow(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFpWindow(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setDashcamState(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBatteryLevel(value);
      break;
    case 23:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setBatteryRange(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRdWindow(value);
      break;
    case 25:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRpWindow(value);
      break;
    case 26:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSentryMode(value);
      break;
    case 27:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTpmsPressureFl(value);
      break;
    case 28:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTpmsPressureFr(value);
      break;
    case 29:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTpmsPressureRl(value);
      break;
    case 30:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTpmsPressureRr(value);
      break;
    case 31:
      var value = /** @type {string} */ (reader.readString());
      msg.setPayload(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.telematics_service.Telematics.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.telematics_service.Telematics.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.telematics_service.Telematics} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.telematics_service.Telematics.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInternalId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExternalId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getVin();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.telematics_service.Location.serializeBinaryToWriter
    );
  }
  f = message.getOdometer();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      vehicle_pb.Odometer.serializeBinaryToWriter
    );
  }
  f = message.getMetadataMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(6, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getMake();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getModel();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getYear();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getLastConnectionTime();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getOemId();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getCurrentConnectionAttempts();
  if (f !== 0) {
    writer.writeInt64(
      13,
      f
    );
  }
  f = message.getConnectionState();
  if (f !== 0.0) {
    writer.writeEnum(
      14,
      f
    );
  }
  f = message.getCustodianId();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getCustodianType();
  if (f !== 0.0) {
    writer.writeEnum(
      16,
      f
    );
  }
  f = message.getChargeState();
  if (f !== 0) {
    writer.writeInt64(
      17,
      f
    );
  }
  f = message.getLocked();
  if (f) {
    writer.writeBool(
      18,
      f
    );
  }
  f = message.getFdWindow();
  if (f !== 0) {
    writer.writeInt64(
      19,
      f
    );
  }
  f = message.getFpWindow();
  if (f !== 0) {
    writer.writeInt64(
      20,
      f
    );
  }
  f = message.getDashcamState();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getBatteryLevel();
  if (f !== 0) {
    writer.writeInt64(
      22,
      f
    );
  }
  f = message.getBatteryRange();
  if (f !== 0.0) {
    writer.writeFloat(
      23,
      f
    );
  }
  f = message.getRdWindow();
  if (f !== 0) {
    writer.writeInt64(
      24,
      f
    );
  }
  f = message.getRpWindow();
  if (f !== 0) {
    writer.writeInt64(
      25,
      f
    );
  }
  f = message.getSentryMode();
  if (f) {
    writer.writeBool(
      26,
      f
    );
  }
  f = message.getTpmsPressureFl();
  if (f !== 0.0) {
    writer.writeFloat(
      27,
      f
    );
  }
  f = message.getTpmsPressureFr();
  if (f !== 0.0) {
    writer.writeFloat(
      28,
      f
    );
  }
  f = message.getTpmsPressureRl();
  if (f !== 0.0) {
    writer.writeFloat(
      29,
      f
    );
  }
  f = message.getTpmsPressureRr();
  if (f !== 0.0) {
    writer.writeFloat(
      30,
      f
    );
  }
  f = message.getPayload();
  if (f.length > 0) {
    writer.writeString(
      31,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.telematics_service.Telematics.ConnectionState = {
  CONNECTION_STATE_UNSPECIFIED: 0,
  CONNECTION_STATE_UNKNOWN: 1,
  CONNECTION_STATE_OFFLINE: 2,
  CONNECTION_STATE_ASLEEP: 3,
  CONNECTION_STATE_ONLINE: 4
};

/**
 * optional string internal_id = 1;
 * @return {string}
 */
proto.telematics_service.Telematics.prototype.getInternalId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.telematics_service.Telematics} returns this
 */
proto.telematics_service.Telematics.prototype.setInternalId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string external_id = 2;
 * @return {string}
 */
proto.telematics_service.Telematics.prototype.getExternalId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.telematics_service.Telematics} returns this
 */
proto.telematics_service.Telematics.prototype.setExternalId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string vin = 3;
 * @return {string}
 */
proto.telematics_service.Telematics.prototype.getVin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.telematics_service.Telematics} returns this
 */
proto.telematics_service.Telematics.prototype.setVin = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional Location location = 4;
 * @return {?proto.telematics_service.Location}
 */
proto.telematics_service.Telematics.prototype.getLocation = function() {
  return /** @type{?proto.telematics_service.Location} */ (
    jspb.Message.getWrapperField(this, proto.telematics_service.Location, 4));
};


/**
 * @param {?proto.telematics_service.Location|undefined} value
 * @return {!proto.telematics_service.Telematics} returns this
*/
proto.telematics_service.Telematics.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.telematics_service.Telematics} returns this
 */
proto.telematics_service.Telematics.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.telematics_service.Telematics.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional vehicle.Odometer odometer = 5;
 * @return {?proto.vehicle.Odometer}
 */
proto.telematics_service.Telematics.prototype.getOdometer = function() {
  return /** @type{?proto.vehicle.Odometer} */ (
    jspb.Message.getWrapperField(this, vehicle_pb.Odometer, 5));
};


/**
 * @param {?proto.vehicle.Odometer|undefined} value
 * @return {!proto.telematics_service.Telematics} returns this
*/
proto.telematics_service.Telematics.prototype.setOdometer = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.telematics_service.Telematics} returns this
 */
proto.telematics_service.Telematics.prototype.clearOdometer = function() {
  return this.setOdometer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.telematics_service.Telematics.prototype.hasOdometer = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * map<string, string> metadata = 6;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.telematics_service.Telematics.prototype.getMetadataMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 6, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.telematics_service.Telematics} returns this
 */
proto.telematics_service.Telematics.prototype.clearMetadataMap = function() {
  this.getMetadataMap().clear();
  return this;};


/**
 * optional int64 timestamp = 7;
 * @return {number}
 */
proto.telematics_service.Telematics.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.telematics_service.Telematics} returns this
 */
proto.telematics_service.Telematics.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string make = 8;
 * @return {string}
 */
proto.telematics_service.Telematics.prototype.getMake = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.telematics_service.Telematics} returns this
 */
proto.telematics_service.Telematics.prototype.setMake = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string model = 9;
 * @return {string}
 */
proto.telematics_service.Telematics.prototype.getModel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.telematics_service.Telematics} returns this
 */
proto.telematics_service.Telematics.prototype.setModel = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional int32 year = 10;
 * @return {number}
 */
proto.telematics_service.Telematics.prototype.getYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.telematics_service.Telematics} returns this
 */
proto.telematics_service.Telematics.prototype.setYear = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int64 last_connection_time = 11;
 * @return {number}
 */
proto.telematics_service.Telematics.prototype.getLastConnectionTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.telematics_service.Telematics} returns this
 */
proto.telematics_service.Telematics.prototype.setLastConnectionTime = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional string oem_id = 12;
 * @return {string}
 */
proto.telematics_service.Telematics.prototype.getOemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.telematics_service.Telematics} returns this
 */
proto.telematics_service.Telematics.prototype.setOemId = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional int64 current_connection_attempts = 13;
 * @return {number}
 */
proto.telematics_service.Telematics.prototype.getCurrentConnectionAttempts = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.telematics_service.Telematics} returns this
 */
proto.telematics_service.Telematics.prototype.setCurrentConnectionAttempts = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional ConnectionState connection_state = 14;
 * @return {!proto.telematics_service.Telematics.ConnectionState}
 */
proto.telematics_service.Telematics.prototype.getConnectionState = function() {
  return /** @type {!proto.telematics_service.Telematics.ConnectionState} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {!proto.telematics_service.Telematics.ConnectionState} value
 * @return {!proto.telematics_service.Telematics} returns this
 */
proto.telematics_service.Telematics.prototype.setConnectionState = function(value) {
  return jspb.Message.setProto3EnumField(this, 14, value);
};


/**
 * optional string custodian_id = 15;
 * @return {string}
 */
proto.telematics_service.Telematics.prototype.getCustodianId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.telematics_service.Telematics} returns this
 */
proto.telematics_service.Telematics.prototype.setCustodianId = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional vehicle.CustodianType custodian_type = 16;
 * @return {!proto.vehicle.CustodianType}
 */
proto.telematics_service.Telematics.prototype.getCustodianType = function() {
  return /** @type {!proto.vehicle.CustodianType} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {!proto.vehicle.CustodianType} value
 * @return {!proto.telematics_service.Telematics} returns this
 */
proto.telematics_service.Telematics.prototype.setCustodianType = function(value) {
  return jspb.Message.setProto3EnumField(this, 16, value);
};


/**
 * optional int64 charge_state = 17;
 * @return {number}
 */
proto.telematics_service.Telematics.prototype.getChargeState = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.telematics_service.Telematics} returns this
 */
proto.telematics_service.Telematics.prototype.setChargeState = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional bool locked = 18;
 * @return {boolean}
 */
proto.telematics_service.Telematics.prototype.getLocked = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 18, false));
};


/**
 * @param {boolean} value
 * @return {!proto.telematics_service.Telematics} returns this
 */
proto.telematics_service.Telematics.prototype.setLocked = function(value) {
  return jspb.Message.setProto3BooleanField(this, 18, value);
};


/**
 * optional int64 fd_window = 19;
 * @return {number}
 */
proto.telematics_service.Telematics.prototype.getFdWindow = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {number} value
 * @return {!proto.telematics_service.Telematics} returns this
 */
proto.telematics_service.Telematics.prototype.setFdWindow = function(value) {
  return jspb.Message.setProto3IntField(this, 19, value);
};


/**
 * optional int64 fp_window = 20;
 * @return {number}
 */
proto.telematics_service.Telematics.prototype.getFpWindow = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.telematics_service.Telematics} returns this
 */
proto.telematics_service.Telematics.prototype.setFpWindow = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * optional string dashcam_state = 21;
 * @return {string}
 */
proto.telematics_service.Telematics.prototype.getDashcamState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.telematics_service.Telematics} returns this
 */
proto.telematics_service.Telematics.prototype.setDashcamState = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional int64 battery_level = 22;
 * @return {number}
 */
proto.telematics_service.Telematics.prototype.getBatteryLevel = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/**
 * @param {number} value
 * @return {!proto.telematics_service.Telematics} returns this
 */
proto.telematics_service.Telematics.prototype.setBatteryLevel = function(value) {
  return jspb.Message.setProto3IntField(this, 22, value);
};


/**
 * optional float battery_range = 23;
 * @return {number}
 */
proto.telematics_service.Telematics.prototype.getBatteryRange = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 23, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.telematics_service.Telematics} returns this
 */
proto.telematics_service.Telematics.prototype.setBatteryRange = function(value) {
  return jspb.Message.setProto3FloatField(this, 23, value);
};


/**
 * optional int64 rd_window = 24;
 * @return {number}
 */
proto.telematics_service.Telematics.prototype.getRdWindow = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 24, 0));
};


/**
 * @param {number} value
 * @return {!proto.telematics_service.Telematics} returns this
 */
proto.telematics_service.Telematics.prototype.setRdWindow = function(value) {
  return jspb.Message.setProto3IntField(this, 24, value);
};


/**
 * optional int64 rp_window = 25;
 * @return {number}
 */
proto.telematics_service.Telematics.prototype.getRpWindow = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
};


/**
 * @param {number} value
 * @return {!proto.telematics_service.Telematics} returns this
 */
proto.telematics_service.Telematics.prototype.setRpWindow = function(value) {
  return jspb.Message.setProto3IntField(this, 25, value);
};


/**
 * optional bool sentry_mode = 26;
 * @return {boolean}
 */
proto.telematics_service.Telematics.prototype.getSentryMode = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 26, false));
};


/**
 * @param {boolean} value
 * @return {!proto.telematics_service.Telematics} returns this
 */
proto.telematics_service.Telematics.prototype.setSentryMode = function(value) {
  return jspb.Message.setProto3BooleanField(this, 26, value);
};


/**
 * optional float tpms_pressure_fl = 27;
 * @return {number}
 */
proto.telematics_service.Telematics.prototype.getTpmsPressureFl = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 27, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.telematics_service.Telematics} returns this
 */
proto.telematics_service.Telematics.prototype.setTpmsPressureFl = function(value) {
  return jspb.Message.setProto3FloatField(this, 27, value);
};


/**
 * optional float tpms_pressure_fr = 28;
 * @return {number}
 */
proto.telematics_service.Telematics.prototype.getTpmsPressureFr = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 28, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.telematics_service.Telematics} returns this
 */
proto.telematics_service.Telematics.prototype.setTpmsPressureFr = function(value) {
  return jspb.Message.setProto3FloatField(this, 28, value);
};


/**
 * optional float tpms_pressure_rl = 29;
 * @return {number}
 */
proto.telematics_service.Telematics.prototype.getTpmsPressureRl = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 29, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.telematics_service.Telematics} returns this
 */
proto.telematics_service.Telematics.prototype.setTpmsPressureRl = function(value) {
  return jspb.Message.setProto3FloatField(this, 29, value);
};


/**
 * optional float tpms_pressure_rr = 30;
 * @return {number}
 */
proto.telematics_service.Telematics.prototype.getTpmsPressureRr = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 30, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.telematics_service.Telematics} returns this
 */
proto.telematics_service.Telematics.prototype.setTpmsPressureRr = function(value) {
  return jspb.Message.setProto3FloatField(this, 30, value);
};


/**
 * optional string payload = 31;
 * @return {string}
 */
proto.telematics_service.Telematics.prototype.getPayload = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 31, ""));
};


/**
 * @param {string} value
 * @return {!proto.telematics_service.Telematics} returns this
 */
proto.telematics_service.Telematics.prototype.setPayload = function(value) {
  return jspb.Message.setProto3StringField(this, 31, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.telematics_service.ClusterState.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.telematics_service.ClusterState.prototype.toObject = function(opt_includeInstance) {
  return proto.telematics_service.ClusterState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.telematics_service.ClusterState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.telematics_service.ClusterState.toObject = function(includeInstance, msg) {
  var f, obj = {
    instancesList: jspb.Message.toObjectList(msg.getInstancesList(),
    proto.telematics_service.Instance.toObject, includeInstance),
    leaderId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    groupName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    vehicleCount: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.telematics_service.ClusterState}
 */
proto.telematics_service.ClusterState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.telematics_service.ClusterState;
  return proto.telematics_service.ClusterState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.telematics_service.ClusterState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.telematics_service.ClusterState}
 */
proto.telematics_service.ClusterState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.telematics_service.Instance;
      reader.readMessage(value,proto.telematics_service.Instance.deserializeBinaryFromReader);
      msg.addInstances(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLeaderId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupName(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setVehicleCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.telematics_service.ClusterState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.telematics_service.ClusterState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.telematics_service.ClusterState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.telematics_service.ClusterState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstancesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.telematics_service.Instance.serializeBinaryToWriter
    );
  }
  f = message.getLeaderId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getGroupName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getVehicleCount();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
};


/**
 * repeated Instance instances = 1;
 * @return {!Array<!proto.telematics_service.Instance>}
 */
proto.telematics_service.ClusterState.prototype.getInstancesList = function() {
  return /** @type{!Array<!proto.telematics_service.Instance>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.telematics_service.Instance, 1));
};


/**
 * @param {!Array<!proto.telematics_service.Instance>} value
 * @return {!proto.telematics_service.ClusterState} returns this
*/
proto.telematics_service.ClusterState.prototype.setInstancesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.telematics_service.Instance=} opt_value
 * @param {number=} opt_index
 * @return {!proto.telematics_service.Instance}
 */
proto.telematics_service.ClusterState.prototype.addInstances = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.telematics_service.Instance, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.telematics_service.ClusterState} returns this
 */
proto.telematics_service.ClusterState.prototype.clearInstancesList = function() {
  return this.setInstancesList([]);
};


/**
 * optional string leader_id = 2;
 * @return {string}
 */
proto.telematics_service.ClusterState.prototype.getLeaderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.telematics_service.ClusterState} returns this
 */
proto.telematics_service.ClusterState.prototype.setLeaderId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string group_name = 5;
 * @return {string}
 */
proto.telematics_service.ClusterState.prototype.getGroupName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.telematics_service.ClusterState} returns this
 */
proto.telematics_service.ClusterState.prototype.setGroupName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int64 vehicle_count = 6;
 * @return {number}
 */
proto.telematics_service.ClusterState.prototype.getVehicleCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.telematics_service.ClusterState} returns this
 */
proto.telematics_service.ClusterState.prototype.setVehicleCount = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.telematics_service.Instance.prototype.toObject = function(opt_includeInstance) {
  return proto.telematics_service.Instance.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.telematics_service.Instance} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.telematics_service.Instance.toObject = function(includeInstance, msg) {
  var f, obj = {
    instanceName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    isLeader: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    vehiclesTrackingMap: (f = msg.getVehiclesTrackingMap()) ? f.toObject(includeInstance, undefined) : [],
    lastCheckin: jspb.Message.getFieldWithDefault(msg, 4, 0),
    trackingMapMap: (f = msg.getTrackingMapMap()) ? f.toObject(includeInstance, proto.telematics_service.Telematics.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.telematics_service.Instance}
 */
proto.telematics_service.Instance.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.telematics_service.Instance;
  return proto.telematics_service.Instance.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.telematics_service.Instance} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.telematics_service.Instance}
 */
proto.telematics_service.Instance.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstanceName(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsLeader(value);
      break;
    case 3:
      var value = msg.getVehiclesTrackingMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastCheckin(value);
      break;
    case 5:
      var value = msg.getTrackingMapMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.telematics_service.Telematics.deserializeBinaryFromReader, "", new proto.telematics_service.Telematics());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.telematics_service.Instance.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.telematics_service.Instance.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.telematics_service.Instance} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.telematics_service.Instance.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstanceName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIsLeader();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getVehiclesTrackingMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(3, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getLastCheckin();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getTrackingMapMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(5, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.telematics_service.Telematics.serializeBinaryToWriter);
  }
};


/**
 * optional string instance_name = 1;
 * @return {string}
 */
proto.telematics_service.Instance.prototype.getInstanceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.telematics_service.Instance} returns this
 */
proto.telematics_service.Instance.prototype.setInstanceName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool is_leader = 2;
 * @return {boolean}
 */
proto.telematics_service.Instance.prototype.getIsLeader = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.telematics_service.Instance} returns this
 */
proto.telematics_service.Instance.prototype.setIsLeader = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * map<string, string> vehicles_tracking = 3;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.telematics_service.Instance.prototype.getVehiclesTrackingMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 3, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.telematics_service.Instance} returns this
 */
proto.telematics_service.Instance.prototype.clearVehiclesTrackingMap = function() {
  this.getVehiclesTrackingMap().clear();
  return this;};


/**
 * optional int64 last_checkin = 4;
 * @return {number}
 */
proto.telematics_service.Instance.prototype.getLastCheckin = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.telematics_service.Instance} returns this
 */
proto.telematics_service.Instance.prototype.setLastCheckin = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * map<string, Telematics> tracking_map = 5;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.telematics_service.Telematics>}
 */
proto.telematics_service.Instance.prototype.getTrackingMapMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.telematics_service.Telematics>} */ (
      jspb.Message.getMapField(this, 5, opt_noLazyCreate,
      proto.telematics_service.Telematics));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.telematics_service.Instance} returns this
 */
proto.telematics_service.Instance.prototype.clearTrackingMapMap = function() {
  this.getTrackingMapMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.telematics_service.RoutePosition.prototype.toObject = function(opt_includeInstance) {
  return proto.telematics_service.RoutePosition.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.telematics_service.RoutePosition} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.telematics_service.RoutePosition.toObject = function(includeInstance, msg) {
  var f, obj = {
    timestamp: jspb.Message.getFieldWithDefault(msg, 1, ""),
    location: (f = msg.getLocation()) && proto.telematics_service.Location.toObject(includeInstance, f),
    odometer: (f = msg.getOdometer()) && vehicle_pb.Odometer.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.telematics_service.RoutePosition}
 */
proto.telematics_service.RoutePosition.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.telematics_service.RoutePosition;
  return proto.telematics_service.RoutePosition.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.telematics_service.RoutePosition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.telematics_service.RoutePosition}
 */
proto.telematics_service.RoutePosition.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimestamp(value);
      break;
    case 2:
      var value = new proto.telematics_service.Location;
      reader.readMessage(value,proto.telematics_service.Location.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 3:
      var value = new vehicle_pb.Odometer;
      reader.readMessage(value,vehicle_pb.Odometer.deserializeBinaryFromReader);
      msg.setOdometer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.telematics_service.RoutePosition.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.telematics_service.RoutePosition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.telematics_service.RoutePosition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.telematics_service.RoutePosition.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimestamp();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.telematics_service.Location.serializeBinaryToWriter
    );
  }
  f = message.getOdometer();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      vehicle_pb.Odometer.serializeBinaryToWriter
    );
  }
};


/**
 * optional string timestamp = 1;
 * @return {string}
 */
proto.telematics_service.RoutePosition.prototype.getTimestamp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.telematics_service.RoutePosition} returns this
 */
proto.telematics_service.RoutePosition.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Location location = 2;
 * @return {?proto.telematics_service.Location}
 */
proto.telematics_service.RoutePosition.prototype.getLocation = function() {
  return /** @type{?proto.telematics_service.Location} */ (
    jspb.Message.getWrapperField(this, proto.telematics_service.Location, 2));
};


/**
 * @param {?proto.telematics_service.Location|undefined} value
 * @return {!proto.telematics_service.RoutePosition} returns this
*/
proto.telematics_service.RoutePosition.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.telematics_service.RoutePosition} returns this
 */
proto.telematics_service.RoutePosition.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.telematics_service.RoutePosition.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional vehicle.Odometer odometer = 3;
 * @return {?proto.vehicle.Odometer}
 */
proto.telematics_service.RoutePosition.prototype.getOdometer = function() {
  return /** @type{?proto.vehicle.Odometer} */ (
    jspb.Message.getWrapperField(this, vehicle_pb.Odometer, 3));
};


/**
 * @param {?proto.vehicle.Odometer|undefined} value
 * @return {!proto.telematics_service.RoutePosition} returns this
*/
proto.telematics_service.RoutePosition.prototype.setOdometer = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.telematics_service.RoutePosition} returns this
 */
proto.telematics_service.RoutePosition.prototype.clearOdometer = function() {
  return this.setOdometer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.telematics_service.RoutePosition.prototype.hasOdometer = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.telematics_service.Location.prototype.toObject = function(opt_includeInstance) {
  return proto.telematics_service.Location.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.telematics_service.Location} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.telematics_service.Location.toObject = function(includeInstance, msg) {
  var f, obj = {
    latitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    longitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    altitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    physicalAddress: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.telematics_service.Location}
 */
proto.telematics_service.Location.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.telematics_service.Location;
  return proto.telematics_service.Location.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.telematics_service.Location} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.telematics_service.Location}
 */
proto.telematics_service.Location.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLatitude(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLongitude(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAltitude(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhysicalAddress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.telematics_service.Location.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.telematics_service.Location.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.telematics_service.Location} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.telematics_service.Location.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLatitude();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getLongitude();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getAltitude();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getPhysicalAddress();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional float latitude = 1;
 * @return {number}
 */
proto.telematics_service.Location.prototype.getLatitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.telematics_service.Location} returns this
 */
proto.telematics_service.Location.prototype.setLatitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional float longitude = 2;
 * @return {number}
 */
proto.telematics_service.Location.prototype.getLongitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.telematics_service.Location} returns this
 */
proto.telematics_service.Location.prototype.setLongitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float altitude = 3;
 * @return {number}
 */
proto.telematics_service.Location.prototype.getAltitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.telematics_service.Location} returns this
 */
proto.telematics_service.Location.prototype.setAltitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional string physical_address = 5;
 * @return {string}
 */
proto.telematics_service.Location.prototype.getPhysicalAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.telematics_service.Location} returns this
 */
proto.telematics_service.Location.prototype.setPhysicalAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.telematics_service.TripEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.telematics_service.TripEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.telematics_service.TripEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.telematics_service.TripEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventtype: jspb.Message.getFieldWithDefault(msg, 1, 0),
    eventtypename: jspb.Message.getFieldWithDefault(msg, 2, ""),
    latitudestart: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    longitudestart: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    latitudeend: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    longitudeend: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    averagedriverspeed: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    maxdriverspeed: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    postedspeedlimit: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    starttime: jspb.Message.getFieldWithDefault(msg, 10, ""),
    endtime: jspb.Message.getFieldWithDefault(msg, 11, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.telematics_service.TripEvent}
 */
proto.telematics_service.TripEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.telematics_service.TripEvent;
  return proto.telematics_service.TripEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.telematics_service.TripEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.telematics_service.TripEvent}
 */
proto.telematics_service.TripEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEventtype(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventtypename(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLatitudestart(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLongitudestart(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLatitudeend(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLongitudeend(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAveragedriverspeed(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMaxdriverspeed(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setPostedspeedlimit(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setStarttime(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndtime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.telematics_service.TripEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.telematics_service.TripEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.telematics_service.TripEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.telematics_service.TripEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventtype();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getEventtypename();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLatitudestart();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getLongitudestart();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getLatitudeend();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getLongitudeend();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getAveragedriverspeed();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getMaxdriverspeed();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getPostedspeedlimit();
  if (f !== 0.0) {
    writer.writeFloat(
      9,
      f
    );
  }
  f = message.getStarttime();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getEndtime();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
};


/**
 * optional int32 EventType = 1;
 * @return {number}
 */
proto.telematics_service.TripEvent.prototype.getEventtype = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.telematics_service.TripEvent} returns this
 */
proto.telematics_service.TripEvent.prototype.setEventtype = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string EventTypeName = 2;
 * @return {string}
 */
proto.telematics_service.TripEvent.prototype.getEventtypename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.telematics_service.TripEvent} returns this
 */
proto.telematics_service.TripEvent.prototype.setEventtypename = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional float LatitudeStart = 3;
 * @return {number}
 */
proto.telematics_service.TripEvent.prototype.getLatitudestart = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.telematics_service.TripEvent} returns this
 */
proto.telematics_service.TripEvent.prototype.setLatitudestart = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float LongitudeStart = 4;
 * @return {number}
 */
proto.telematics_service.TripEvent.prototype.getLongitudestart = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.telematics_service.TripEvent} returns this
 */
proto.telematics_service.TripEvent.prototype.setLongitudestart = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float LatitudeEnd = 5;
 * @return {number}
 */
proto.telematics_service.TripEvent.prototype.getLatitudeend = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.telematics_service.TripEvent} returns this
 */
proto.telematics_service.TripEvent.prototype.setLatitudeend = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float LongitudeEnd = 6;
 * @return {number}
 */
proto.telematics_service.TripEvent.prototype.getLongitudeend = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.telematics_service.TripEvent} returns this
 */
proto.telematics_service.TripEvent.prototype.setLongitudeend = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float AverageDriverSpeed = 7;
 * @return {number}
 */
proto.telematics_service.TripEvent.prototype.getAveragedriverspeed = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.telematics_service.TripEvent} returns this
 */
proto.telematics_service.TripEvent.prototype.setAveragedriverspeed = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional float MaxDriverSpeed = 8;
 * @return {number}
 */
proto.telematics_service.TripEvent.prototype.getMaxdriverspeed = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.telematics_service.TripEvent} returns this
 */
proto.telematics_service.TripEvent.prototype.setMaxdriverspeed = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional float PostedSpeedLimit = 9;
 * @return {number}
 */
proto.telematics_service.TripEvent.prototype.getPostedspeedlimit = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.telematics_service.TripEvent} returns this
 */
proto.telematics_service.TripEvent.prototype.setPostedspeedlimit = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional string StartTime = 10;
 * @return {string}
 */
proto.telematics_service.TripEvent.prototype.getStarttime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.telematics_service.TripEvent} returns this
 */
proto.telematics_service.TripEvent.prototype.setStarttime = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string EndTime = 11;
 * @return {string}
 */
proto.telematics_service.TripEvent.prototype.getEndtime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.telematics_service.TripEvent} returns this
 */
proto.telematics_service.TripEvent.prototype.setEndtime = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.telematics_service.DailyDrivingBehavior.prototype.toObject = function(opt_includeInstance) {
  return proto.telematics_service.DailyDrivingBehavior.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.telematics_service.DailyDrivingBehavior} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.telematics_service.DailyDrivingBehavior.toObject = function(includeInstance, msg) {
  var f, obj = {
    date: jspb.Message.getFieldWithDefault(msg, 1, ""),
    score: (f = msg.getScore()) && proto.telematics_service.VehicleScore.toObject(includeInstance, f),
    eventRating: (f = msg.getEventRating()) && proto.telematics_service.EventRating.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.telematics_service.DailyDrivingBehavior}
 */
proto.telematics_service.DailyDrivingBehavior.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.telematics_service.DailyDrivingBehavior;
  return proto.telematics_service.DailyDrivingBehavior.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.telematics_service.DailyDrivingBehavior} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.telematics_service.DailyDrivingBehavior}
 */
proto.telematics_service.DailyDrivingBehavior.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDate(value);
      break;
    case 2:
      var value = new proto.telematics_service.VehicleScore;
      reader.readMessage(value,proto.telematics_service.VehicleScore.deserializeBinaryFromReader);
      msg.setScore(value);
      break;
    case 3:
      var value = new proto.telematics_service.EventRating;
      reader.readMessage(value,proto.telematics_service.EventRating.deserializeBinaryFromReader);
      msg.setEventRating(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.telematics_service.DailyDrivingBehavior.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.telematics_service.DailyDrivingBehavior.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.telematics_service.DailyDrivingBehavior} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.telematics_service.DailyDrivingBehavior.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDate();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getScore();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.telematics_service.VehicleScore.serializeBinaryToWriter
    );
  }
  f = message.getEventRating();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.telematics_service.EventRating.serializeBinaryToWriter
    );
  }
};


/**
 * optional string date = 1;
 * @return {string}
 */
proto.telematics_service.DailyDrivingBehavior.prototype.getDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.telematics_service.DailyDrivingBehavior} returns this
 */
proto.telematics_service.DailyDrivingBehavior.prototype.setDate = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional VehicleScore score = 2;
 * @return {?proto.telematics_service.VehicleScore}
 */
proto.telematics_service.DailyDrivingBehavior.prototype.getScore = function() {
  return /** @type{?proto.telematics_service.VehicleScore} */ (
    jspb.Message.getWrapperField(this, proto.telematics_service.VehicleScore, 2));
};


/**
 * @param {?proto.telematics_service.VehicleScore|undefined} value
 * @return {!proto.telematics_service.DailyDrivingBehavior} returns this
*/
proto.telematics_service.DailyDrivingBehavior.prototype.setScore = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.telematics_service.DailyDrivingBehavior} returns this
 */
proto.telematics_service.DailyDrivingBehavior.prototype.clearScore = function() {
  return this.setScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.telematics_service.DailyDrivingBehavior.prototype.hasScore = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional EventRating event_rating = 3;
 * @return {?proto.telematics_service.EventRating}
 */
proto.telematics_service.DailyDrivingBehavior.prototype.getEventRating = function() {
  return /** @type{?proto.telematics_service.EventRating} */ (
    jspb.Message.getWrapperField(this, proto.telematics_service.EventRating, 3));
};


/**
 * @param {?proto.telematics_service.EventRating|undefined} value
 * @return {!proto.telematics_service.DailyDrivingBehavior} returns this
*/
proto.telematics_service.DailyDrivingBehavior.prototype.setEventRating = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.telematics_service.DailyDrivingBehavior} returns this
 */
proto.telematics_service.DailyDrivingBehavior.prototype.clearEventRating = function() {
  return this.setEventRating(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.telematics_service.DailyDrivingBehavior.prototype.hasEventRating = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.telematics_service.DrivingBehavior.prototype.toObject = function(opt_includeInstance) {
  return proto.telematics_service.DrivingBehavior.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.telematics_service.DrivingBehavior} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.telematics_service.DrivingBehavior.toObject = function(includeInstance, msg) {
  var f, obj = {
    score: (f = msg.getScore()) && proto.telematics_service.VehicleScore.toObject(includeInstance, f),
    eventRating: (f = msg.getEventRating()) && proto.telematics_service.EventRating.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.telematics_service.DrivingBehavior}
 */
proto.telematics_service.DrivingBehavior.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.telematics_service.DrivingBehavior;
  return proto.telematics_service.DrivingBehavior.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.telematics_service.DrivingBehavior} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.telematics_service.DrivingBehavior}
 */
proto.telematics_service.DrivingBehavior.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.telematics_service.VehicleScore;
      reader.readMessage(value,proto.telematics_service.VehicleScore.deserializeBinaryFromReader);
      msg.setScore(value);
      break;
    case 2:
      var value = new proto.telematics_service.EventRating;
      reader.readMessage(value,proto.telematics_service.EventRating.deserializeBinaryFromReader);
      msg.setEventRating(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.telematics_service.DrivingBehavior.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.telematics_service.DrivingBehavior.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.telematics_service.DrivingBehavior} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.telematics_service.DrivingBehavior.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScore();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.telematics_service.VehicleScore.serializeBinaryToWriter
    );
  }
  f = message.getEventRating();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.telematics_service.EventRating.serializeBinaryToWriter
    );
  }
};


/**
 * optional VehicleScore score = 1;
 * @return {?proto.telematics_service.VehicleScore}
 */
proto.telematics_service.DrivingBehavior.prototype.getScore = function() {
  return /** @type{?proto.telematics_service.VehicleScore} */ (
    jspb.Message.getWrapperField(this, proto.telematics_service.VehicleScore, 1));
};


/**
 * @param {?proto.telematics_service.VehicleScore|undefined} value
 * @return {!proto.telematics_service.DrivingBehavior} returns this
*/
proto.telematics_service.DrivingBehavior.prototype.setScore = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.telematics_service.DrivingBehavior} returns this
 */
proto.telematics_service.DrivingBehavior.prototype.clearScore = function() {
  return this.setScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.telematics_service.DrivingBehavior.prototype.hasScore = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional EventRating event_rating = 2;
 * @return {?proto.telematics_service.EventRating}
 */
proto.telematics_service.DrivingBehavior.prototype.getEventRating = function() {
  return /** @type{?proto.telematics_service.EventRating} */ (
    jspb.Message.getWrapperField(this, proto.telematics_service.EventRating, 2));
};


/**
 * @param {?proto.telematics_service.EventRating|undefined} value
 * @return {!proto.telematics_service.DrivingBehavior} returns this
*/
proto.telematics_service.DrivingBehavior.prototype.setEventRating = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.telematics_service.DrivingBehavior} returns this
 */
proto.telematics_service.DrivingBehavior.prototype.clearEventRating = function() {
  return this.setEventRating(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.telematics_service.DrivingBehavior.prototype.hasEventRating = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.telematics_service.VehicleScore.prototype.toObject = function(opt_includeInstance) {
  return proto.telematics_service.VehicleScore.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.telematics_service.VehicleScore} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.telematics_service.VehicleScore.toObject = function(includeInstance, msg) {
  var f, obj = {
    zendriveScore: jspb.Message.getFieldWithDefault(msg, 1, 0),
    vehicles: (f = msg.getVehicles()) && proto.telematics_service.VehicleScore.Vehicles.toObject(includeInstance, f),
    unknownVehicleScore: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.telematics_service.VehicleScore}
 */
proto.telematics_service.VehicleScore.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.telematics_service.VehicleScore;
  return proto.telematics_service.VehicleScore.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.telematics_service.VehicleScore} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.telematics_service.VehicleScore}
 */
proto.telematics_service.VehicleScore.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setZendriveScore(value);
      break;
    case 2:
      var value = new proto.telematics_service.VehicleScore.Vehicles;
      reader.readMessage(value,proto.telematics_service.VehicleScore.Vehicles.deserializeBinaryFromReader);
      msg.setVehicles(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUnknownVehicleScore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.telematics_service.VehicleScore.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.telematics_service.VehicleScore.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.telematics_service.VehicleScore} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.telematics_service.VehicleScore.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getZendriveScore();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getVehicles();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.telematics_service.VehicleScore.Vehicles.serializeBinaryToWriter
    );
  }
  f = message.getUnknownVehicleScore();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.telematics_service.VehicleScore.Vehicles.prototype.toObject = function(opt_includeInstance) {
  return proto.telematics_service.VehicleScore.Vehicles.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.telematics_service.VehicleScore.Vehicles} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.telematics_service.VehicleScore.Vehicles.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleId1: jspb.Message.getFieldWithDefault(msg, 1, 0),
    vehicleId2: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.telematics_service.VehicleScore.Vehicles}
 */
proto.telematics_service.VehicleScore.Vehicles.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.telematics_service.VehicleScore.Vehicles;
  return proto.telematics_service.VehicleScore.Vehicles.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.telematics_service.VehicleScore.Vehicles} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.telematics_service.VehicleScore.Vehicles}
 */
proto.telematics_service.VehicleScore.Vehicles.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVehicleId1(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVehicleId2(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.telematics_service.VehicleScore.Vehicles.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.telematics_service.VehicleScore.Vehicles.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.telematics_service.VehicleScore.Vehicles} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.telematics_service.VehicleScore.Vehicles.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleId1();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getVehicleId2();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 vehicle_id_1 = 1;
 * @return {number}
 */
proto.telematics_service.VehicleScore.Vehicles.prototype.getVehicleId1 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.telematics_service.VehicleScore.Vehicles} returns this
 */
proto.telematics_service.VehicleScore.Vehicles.prototype.setVehicleId1 = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 vehicle_id_2 = 2;
 * @return {number}
 */
proto.telematics_service.VehicleScore.Vehicles.prototype.getVehicleId2 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.telematics_service.VehicleScore.Vehicles} returns this
 */
proto.telematics_service.VehicleScore.Vehicles.prototype.setVehicleId2 = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 zendrive_score = 1;
 * @return {number}
 */
proto.telematics_service.VehicleScore.prototype.getZendriveScore = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.telematics_service.VehicleScore} returns this
 */
proto.telematics_service.VehicleScore.prototype.setZendriveScore = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional Vehicles vehicles = 2;
 * @return {?proto.telematics_service.VehicleScore.Vehicles}
 */
proto.telematics_service.VehicleScore.prototype.getVehicles = function() {
  return /** @type{?proto.telematics_service.VehicleScore.Vehicles} */ (
    jspb.Message.getWrapperField(this, proto.telematics_service.VehicleScore.Vehicles, 2));
};


/**
 * @param {?proto.telematics_service.VehicleScore.Vehicles|undefined} value
 * @return {!proto.telematics_service.VehicleScore} returns this
*/
proto.telematics_service.VehicleScore.prototype.setVehicles = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.telematics_service.VehicleScore} returns this
 */
proto.telematics_service.VehicleScore.prototype.clearVehicles = function() {
  return this.setVehicles(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.telematics_service.VehicleScore.prototype.hasVehicles = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 unknown_vehicle_score = 3;
 * @return {number}
 */
proto.telematics_service.VehicleScore.prototype.getUnknownVehicleScore = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.telematics_service.VehicleScore} returns this
 */
proto.telematics_service.VehicleScore.prototype.setUnknownVehicleScore = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.telematics_service.EventRating.prototype.toObject = function(opt_includeInstance) {
  return proto.telematics_service.EventRating.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.telematics_service.EventRating} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.telematics_service.EventRating.toObject = function(includeInstance, msg) {
  var f, obj = {
    hardBrakeRating: jspb.Message.getFieldWithDefault(msg, 1, 0),
    hardTurnRating: jspb.Message.getFieldWithDefault(msg, 2, 0),
    rapidAccelerationRating: jspb.Message.getFieldWithDefault(msg, 3, 0),
    phoneUseRating: jspb.Message.getFieldWithDefault(msg, 4, 0),
    overspeedingRating: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.telematics_service.EventRating}
 */
proto.telematics_service.EventRating.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.telematics_service.EventRating;
  return proto.telematics_service.EventRating.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.telematics_service.EventRating} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.telematics_service.EventRating}
 */
proto.telematics_service.EventRating.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHardBrakeRating(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHardTurnRating(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRapidAccelerationRating(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPhoneUseRating(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOverspeedingRating(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.telematics_service.EventRating.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.telematics_service.EventRating.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.telematics_service.EventRating} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.telematics_service.EventRating.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHardBrakeRating();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getHardTurnRating();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getRapidAccelerationRating();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getPhoneUseRating();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getOverspeedingRating();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional int32 hard_brake_rating = 1;
 * @return {number}
 */
proto.telematics_service.EventRating.prototype.getHardBrakeRating = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.telematics_service.EventRating} returns this
 */
proto.telematics_service.EventRating.prototype.setHardBrakeRating = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 hard_turn_rating = 2;
 * @return {number}
 */
proto.telematics_service.EventRating.prototype.getHardTurnRating = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.telematics_service.EventRating} returns this
 */
proto.telematics_service.EventRating.prototype.setHardTurnRating = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 rapid_acceleration_rating = 3;
 * @return {number}
 */
proto.telematics_service.EventRating.prototype.getRapidAccelerationRating = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.telematics_service.EventRating} returns this
 */
proto.telematics_service.EventRating.prototype.setRapidAccelerationRating = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 phone_use_rating = 4;
 * @return {number}
 */
proto.telematics_service.EventRating.prototype.getPhoneUseRating = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.telematics_service.EventRating} returns this
 */
proto.telematics_service.EventRating.prototype.setPhoneUseRating = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 overspeeding_rating = 5;
 * @return {number}
 */
proto.telematics_service.EventRating.prototype.getOverspeedingRating = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.telematics_service.EventRating} returns this
 */
proto.telematics_service.EventRating.prototype.setOverspeedingRating = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


goog.object.extend(exports, proto.telematics_service);
