import { useState } from 'react'
import { useMutation} from 'react-query'
import { ReservationService } from '../../../../../fleet-management/api/reservation-service'

export function useSearchBar() {
  const [searchQuery, setSearchQuery] = useState('')
  const [searchResults, setSearchResults] = useState([])

  const searchReservation = useMutation(
    (query: string) =>
      ReservationService.getReservations({
        pageState: '0',
        pageSize: query.length > 1 ? 1 : 0,
        programId: 'all',
        query,
      }),
    {
      onSuccess(data) {
        const searchResults = [...data.reservationsList].map((listing) => {
          return {
            ...listing,
            label: getVehicleLabel(listing),
          }
        })
        setSearchResults(searchResults)
      },
    },
  )

  function resetSearch() {
    setSearchResults([])
    setSearchQuery('')
  }

  return {
    searchQuery,
    setSearchQuery,
    isLoading: searchReservation.isLoading,
    searchReservation,
    searchResults,
    resetSearch,
  }
}

export function getVehicleLabel(listing) {
  const { year, make, model, vin, accountNumber } = listing
  return `${year} ${make} ${model} ${vin} ${accountNumber}`
}


