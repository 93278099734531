import { NexusService } from 'global-apis/nexus-service'
import { useQuery } from 'react-query'
import {getAppointmentsFromCurrentDate, getFormattedAppointmentDays} from 'scheduling/tasks/utils'
import {useState} from 'react'
import {formattedAppointmentStatus} from '../../../../api/reservation.formatted-enums'

export const useAppointmentBlock = (accountNumber) => {
  const [createAppointmentModalIsVisible, setCreateAppointmentModalIsVisible] = useState(false)

  const {
    data: listAppointments,
    isLoading
  } = useQuery(
    [
      '/listAppointments',
      '/listAppointmentsByAccountNumber',
      accountNumber,
    ],
    () => {
      return NexusService.listAppointmentsByAccountNumber(accountNumber)
        .then((response) => {
          return getAppointmentsFromCurrentDate(
            getFormattedAppointmentDays(response.appointmentDaysList)
          )
        })
    }
  )

  function handleClose() {
    setCreateAppointmentModalIsVisible(false)
  }

  // check if all listed appointments are cancelled
  const noOpenAppointments = listAppointments?.every(
    listAppointments => listAppointments.appointments?.every(appointment => appointment.status === formattedAppointmentStatus.CANCELLED.value )
  )
  const noListedAppointments = !(listAppointments && listAppointments.length > 0)

  return {
    listAppointments,
    noOpenAppointments,
    noListedAppointments,
    isLoading,
    createAppointmentModalIsVisible,
    setCreateAppointmentModalIsVisible,
    handleClose
  }
}
