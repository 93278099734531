import React from 'react'
import {Section} from 'shared/Section'
import {AppointmentForm} from './appointment-form/AppointmentForm'
import {Grid, Typography, Card} from '@mui/material'
import {IAppointmentListItem} from '../utils'

interface AppointmentModalBodyProps {
  appointment: IAppointmentListItem
  isEditing: boolean
  setAppointment: (appointment: IAppointmentListItem) => void
  isDriveOffPaid: boolean
}

export const AppointmentModalBody: React.FC<AppointmentModalBodyProps> = ({
  appointment,
  isEditing,
  setAppointment,
  isDriveOffPaid,
}) => {
  return (
    <Card>
      <Section heading="General information">
        <AppointmentForm
          setAppointment={setAppointment}
          appointment={appointment}
          isEditing={isEditing}
        />
      </Section>
      <Section heading="Customer and vehicle information">
        <Grid
          container
          item
          direction="row"
          justifyContent="space-between"
          spacing={4}
          xs={8}
        >
          <Grid item>
            <Grid container item direction="column">
              <Typography variant="caption">Customer name</Typography>
              <Typography>{appointment?.customerName || 'N/A'}</Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container item direction="column">
              <Typography variant="caption">Account #</Typography>
              <Typography>
                {appointment?.customerAccountNumber || 'N/A'}
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container item direction="column">
              <Typography variant="caption">Color</Typography>
              <Typography>{appointment?.color || 'N/A'}</Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container item direction="column">
              <Typography variant="caption">Vin #</Typography>
              <Typography>{appointment?.vin || 'N/A'}</Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container item direction="column">
              <Typography variant="caption">Make</Typography>
              <Typography>{appointment?.make || 'N/A'}</Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container item direction="column">
              <Typography variant="caption">Model</Typography>
              <Typography>{appointment?.model || 'N/A'}</Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container item direction="column">
              <Typography variant="caption">Year</Typography>
              <Typography>{appointment?.year || 'N/A'}</Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container item direction="column">
              <Typography variant="caption">Drive Off Paid</Typography>
              {isDriveOffPaid !== undefined ? (
                <Typography>{isDriveOffPaid ? 'Yes' : 'No'}</Typography>
              ) : (
                <Typography>N/A</Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Section>
    </Card>
  )
}
