import React from 'react'
import { Grid, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { isString } from 'lodash'
import { autonomyColor } from '../../global-styles/autonomyColor'

export const useStyles = makeStyles()((theme) => {
  return {
    base: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(6),
      color: theme.palette.text.primary,
      borderRadius: 8,
    },
    heading: {
      paddingBottom: theme.spacing(2),
    },
    header: {
      marginBottom: theme.spacing(2),
    },
    borderedHeader: {
      width: '100%',
      borderBottom: '1px solid ' + `${autonomyColor.mutedBlue}`,
      marginBottom: theme.spacing(3),
    },
  }
})

interface ArticleProps {
  heading: string | React.ReactNode
  headerHasBottomBorder: boolean
  children: React.ReactNode
  actionBlock?: React.ReactNode
}

export const Article: React.FC<ArticleProps> = ({
  heading,
  headerHasBottomBorder,
  children,
  actionBlock,
}) => {
  const { classes } = useStyles()

  const header = isString(heading) ? (
    <Typography variant="h1" className={classes.heading}>
      {heading}
    </Typography>
  ) : (
    <div className={classes.heading}>{heading}</div>
  )

  return (
    <Grid className={classes.base}>
      <Grid
        container
        justifyContent="space-between"
        className={
          headerHasBottomBorder ? classes.borderedHeader : classes.header
        }
      >
        <Grid item>{header}</Grid>
        {actionBlock && <Grid item>{actionBlock}</Grid>}
      </Grid>
      <Grid container>{children}</Grid>
    </Grid>
  )
}
