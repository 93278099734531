import { Link } from 'react-router-dom'
import { Button } from '@mui/material'
import React from 'react'

const VehicleDetailCell = (props) => {
  const {
    id,
    from = 'listings',
  } = props

  return (
    <Link to={`/view/${from}/${id}`} style={{ textDecoration: 'none' }}>
      <Button
        color='secondary'
        variant='contained'
      >
        View
      </Button>
    </Link>
  )
}

export default VehicleDetailCell
