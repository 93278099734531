import React from 'react'
import {
  Box,
  Button,
  Typography,
  Card,
  CardContent,
  Drawer,
} from '@mui/material'


export const DrawerComponent = ({
  isDrawerOpen,
  setIsDrawerOpen,
  header,
  childComponent,
}) => {

  return (
    <Drawer
      anchor="right"
      open={isDrawerOpen}
      onClose={() => setIsDrawerOpen(false)}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
    >
      <Card sx={{ marginTop: '50px', width: '600px' }}>
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: 400,
            p: 4,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div>
              <Typography variant="h5">{header}</Typography>
            </div>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
              }}
            >
              <Box sx={{ flexGrow: 1 }} />
              <Button onClick={() => setIsDrawerOpen(false)}>Close</Button>
            </Box>
          </Box>
          {childComponent()}
        </CardContent>
      </Card>
    </Drawer>
  )
}
