import { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { FleetService } from '../../api/fleet-service'
import { State } from '@nxcr-org/web-api-interface/lib/vehicle_pb'

export default function useSearchBar(){

  const [open, setOpen] = useState(false)
  const [query, setQuery] = useState('')
  const [results, setResults] = useState([])
  const history = useHistory()
  const location = useLocation()

  const loading = open && results.length === 0 && query.length === 0

  const reset = () => {
    setResults([])
    setQuery('')
  }

  const searchVehicles = async () => {
    const criteria = [
      {
        property: 'vin',
        query,
      },
    ]

    const response = await FleetService.getVehicles({
      page: 0,
      resultsPerPage: 10,
      criteriaList: criteria,
    })

    const resultOptions = [...response.vehiclesList].map(
      listing => {
        // if subscribe redirect to fleet path otherwise listings
        const path = listing.vehicle.state === State.ON_SUBSCRIPTION ? 'fleet' : 'listings'
        return {
          ...listing,
          label: getVehicleLabel(listing),
          navigateTo(){
            history.push(`/view/${path}/${listing.vehicle.id}`)
          }
        }
      }
    )

    setResults(resultOptions)

  }

  useEffect(() => {
    reset()
  }, [location])

  useEffect(() => {
    if(query.length === 0){
      return
    }

    searchVehicles()
  }, [query])

  return {
    results,
    open,
    loading,
    query,
    reset,
    setQuery,
    setOpen
  }
}

/**
 * 
 * @param {*} vehicle 
 * @returns 
 */
export function getVehicleLabel({ vehicle }){
  return `${vehicle.year} ${vehicle.make} ${vehicle.model} ${vehicle.vin}`
}
