import { isString } from 'lodash'

/**
 * This is required to convert the file into an array buffer to send to the backend for file upload, the native File.arrayBuffer() method isn't available in JSDom and in some browsers.
 * @param file
 * @returns
 */
export function fileToArrayBuffer(file: File): Promise<ArrayBuffer> {
  return new Promise(function (resolve, reject) {
    const reader = new FileReader()

    reader.onerror = function onerror(ev) {
      reject(ev.target.error)
    }

    reader.onload = function onload(ev) {
      if (isString(ev.target.result)) {
        return reject('Received string not buffer')
      }

      resolve(ev.target.result)
    }

    reader.readAsArrayBuffer(file)
  })
}
