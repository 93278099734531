import { Grid, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import React, {ReactNode} from 'react'
import {autonomyColor} from '../../global-styles/autonomyColor'

export const useStyles = makeStyles()( theme => {
  return {
    base: {
      margin: theme.spacing( 2, 0, 4, 0 ),
      color: theme.palette.text.primary,
      width: '100%',
    },
    body: {
      padding: theme.spacing(3),
      marginTop: theme.spacing(2),
      backgroundColor: theme.palette.primary.dark,
      borderRadius: 8,
    },
    wrapper: {
      width: '100%',
      marginTop: theme.spacing(2),
    },
    borderedHeader: {
      width: '100%',
      borderBottom: '1px solid ' + `${autonomyColor.mutedBlue}`,
      marginBottom: theme.spacing(3),
    },
  }
})

interface SectionProps {
  children: ReactNode
  headerButtons?: ReactNode
  headerHasBottomBorder?: boolean
  isAdmin?: boolean
  subSection?: boolean
  wrapperSection?: boolean // @TODO: need a different name
  heading?: string
}

export const Section: React.FC<SectionProps> = ({
  heading,
  headerButtons,
  headerHasBottomBorder,
  children,
  wrapperSection,
  subSection
}) => {
  const { classes } = useStyles()

  return (
    <Grid item className={!wrapperSection ? classes.base : classes.wrapper }>
      {!subSection &&
        <Grid
          container
          justifyContent="space-between"
          className={
            headerHasBottomBorder ? classes.borderedHeader : ''
          }>
          <Grid item>
            <Typography variant="h3">
              {heading}
            </Typography>
          </Grid>
          { headerButtons }
        </Grid>
      }
      <Grid className={!wrapperSection ? classes.body : classes.wrapper }>
        { subSection && heading &&
          <Typography variant="h4" style={{ marginBottom: 20 }}>
            {heading}
          </Typography>
        }
        { children }
      </Grid>
    </Grid>
  )
}

