import makeStyles from '@mui/styles/makeStyles'
import React from 'react'

const useStyles = makeStyles({
  arrowSvg: {
    marginTop: 7,
  },
})

export default function BackButtonArrow() {
  const classes = useStyles()
  return (
    <>
      <svg
        className={classes.arrowSvg}
        width="27"
        height="12"
        viewBox="0 0 27 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6 11L1 6L6 1"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M26 6H1"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  )
}
