import React from 'react'
import {
  Box,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { useInvoiceDetails } from '../useInvoiceDetails'
import { StatusPill } from 'shared/StatusPill'
import { InvoiceUI } from '../useListCustomerInvoices'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import Dialog from '@mui/material/Dialog'

type InvoiceDetailsProps = {
  invoices: InvoiceUI[],
  subscriptionId: string,
  refetchInvoices: () => void
}

export const InvoiceDetails: React.FC<InvoiceDetailsProps> = ({
  invoices,
  refetchInvoices,
  subscriptionId,
}) => {
  const { invoiceId, payInvoiceByInvoiceId } = useInvoiceDetails()
  const invoiceDetails = invoices.find((invoice) => invoice.id === invoiceId)
  const { doPayInvoiceById } = payInvoiceByInvoiceId(invoiceDetails?.documentNumber, subscriptionId, Number(invoiceDetails?.balance.replace(/\D/g,'')))
  const [open, setOpen] = React.useState(false)
  const [hidePayInvoiceButton, setHidePayInvoiceButton] = React.useState(false)
  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  function onClickPayInvoice() {
    doPayInvoiceById().then(()=>setHidePayInvoiceButton(true)).then(()=>refetchInvoices())
  }

  return (
    <Paper>
      <Box
        sx={{ my: 4 }}
      >
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="subtitle1">
              Invoice Status
            </Typography>
            <StatusPill color={'issued'}>
              {invoiceDetails?.status}
            </StatusPill>
          </Grid>
          <Grid item sx={{width: 150 }}>
            <Typography sx={{ textAlign: 'left'}} variant="subtitle1">
              Invoice ID
            </Typography>
            <Typography variant="body2">
              {invoiceDetails?.id}
            </Typography>
          </Grid>
          <Grid item>
            <Typography sx={{ textAlign: 'left' }} variant="subtitle1">
              Amount
            </Typography>
            <Typography variant="body2">
              {invoiceDetails?.amount}
            </Typography>
          </Grid>
        </Grid>
        <Box sx={{ my: 4 }}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography gutterBottom variant="subtitle2">
                Date of issue
              </Typography>
              <Typography variant="body2">
                {invoiceDetails?.date}
              </Typography>
            </Grid>
            <Grid item>
              <Typography gutterBottom variant="subtitle2">
                Due date
              </Typography>
              <Typography variant="body2">
                {invoiceDetails?.dueDate}
              </Typography>
            </Grid>

            <Grid item>
              <Typography gutterBottom variant="subtitle2">
                Document Number
              </Typography>
              <Typography variant="body2">{invoiceDetails?.documentNumber}</Typography>
            </Grid>
          </Grid>
        </Box>
        <Table className={'invoice-details'}>
          <TableHead>
            <TableRow>
              <TableCell>Description</TableCell>
              <TableCell />
              <TableCell align="right">Unit Price</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(invoiceDetails?.lineItems || []).map((lineItem) => (
              <TableRow key={lineItem.id}>
                <TableCell>
                  {lineItem.description}
                </TableCell>
                <TableCell />
                <TableCell align="right">
                  {lineItem.amount}
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell className={'invoice-details-footer-data'}/>
              <TableCell className={'invoice-details-footer-data'}>
                <Typography gutterBottom variant="subtitle2">
                  Subtotal
                </Typography>
              </TableCell>
              <TableCell className={'invoice-details-footer-data'} align="right">{invoiceDetails?.subtotalAmount}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={'invoice-details-footer-data'}/>
              <TableCell className={'invoice-details-footer-data'}>
                <Typography gutterBottom variant="subtitle2">
                  Taxes
                </Typography>
              </TableCell>
              <TableCell className={'invoice-details-footer-data'} align="right">{invoiceDetails?.taxAmount}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={'invoice-details-footer-data'}/>
              <TableCell className={'invoice-details-footer-data'}>
                <Typography gutterBottom variant="subtitle2">
                  Total
                </Typography>
              </TableCell>
              <TableCell className={'invoice-details-footer-data'} align="right">{invoiceDetails?.amount}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={'invoice-details-footer-data'}/>
              <TableCell className={'invoice-details-footer-data'}>
                <Typography className={'invoice-details-footer-data'} gutterBottom variant="subtitle2">
                  Balance
                </Typography>
              </TableCell>
              <TableCell className={'invoice-details-footer-data'} align="right">{invoiceDetails?.balance}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Box sx={{ mt: 2 }}>
          <Typography gutterBottom variant="h6">
            Notes
          </Typography>
          <Typography color="textSecondary" variant="body2">
            Notes section
          </Typography>
        </Box>
        {!hidePayInvoiceButton && invoiceDetails?.status && invoiceDetails?.status != 'Paid In Full' && invoiceDetails?.status != 'Voided'  && (
          <Box sx={{ mt: 2 }}>
            <Button variant="contained" onClick={() => handleClickOpen()}>Pay Invoice</Button>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {'Pay Invoice'}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  This will pay the following invoice and charge the customer the amount of {invoiceDetails?.balance}, are you sure you want to continue?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Stop</Button>
                <Button onClick={onClickPayInvoice} autoFocus>
                  Continue
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        )}
      </Box>
    </Paper>
  )
}
