import {
  CreatePromotionRequest,
  PromotionConstraint,
} from '@nxcr-org/web-api-interface/lib/billing_service_pb'
import { useMutation, useQueryClient } from 'react-query'
import { NexusService } from '../global-APIs/nexus-service'
import { formattedPromotionSource } from '../fleet-management/api/reservation.formatted-enums'
import toast from 'react-hot-toast'
import { convertDollarToCents } from '../../utils/utils'
import { usePromoPartners } from './usePromoPartners'
import { useCreatePromoPartner } from './useCreatePromoPartner'
import { useEffect, useState } from 'react'

export const useCreatePromos = () => {
  const queryClient = useQueryClient()
  const {
    promoPartners: { promoPartners },
  } = usePromoPartners()
  const [isCreatePromoModalOpen, setIsCreatePromoModalOpen] = useState<boolean>(false)
  const [formInput, setFormInput] = useState({} as PromoInput)
  const { createPromoPartner, newPartnerId } = useCreatePromoPartner()

  useEffect(() => {
    if (newPartnerId) {
      const formattedRequest = parsePromoRequest(formInput, newPartnerId)
      createPromo(formattedRequest)

    }
  }, [newPartnerId])

  const { mutate: createPromo, error: createPromoError } = useMutation(
    ({
      promoCode,
      rebateValue,
      rebatePercent,
      target,
      source,
      sourceId,
      validFrom,
      validUntil,
      status,
      constraintsList,
    }: {
      promoCode: string
      rebateValue: number
      rebatePercent: number
      target: number
      source: number
      sourceId: string
      validFrom: number
      validUntil: number
      status: boolean
      constraintsList: PromotionConstraint.AsObject[]
    }) => {
      return NexusService.createPromoCode({
        promoCode,
        rebateValue,
        rebatePercent,
        // @ts-ignore
        target,
        // @ts-ignore
        source,
        sourceId,
        validFrom,
        validUntil,
        status,
        constraintsList,
      }).catch((err) => {
        console.error({
          message: 'Error',
          err,
          params: {
            promoCode,
            rebateValue,
            rebatePercent,
            target,
            source,
            sourceId,
            validFrom,
            validUntil,
            status,
            constraintsList,
          },
        })

        // ! Allow UI to handle but log error so that datadog picks up additional data
        throw err
      })
    },
    {
      onSuccess() {
        setIsCreatePromoModalOpen(false)
        toast.success('Promo Created!')
        queryClient.invalidateQueries(['/promotions'])
      },
      onError() {
        toast.error('Something went wrong or Promo code already exists!')
        console.log('Error: ', createPromoError)
      },
    }
  )


  function getPartner(formData) {
    return promoPartners.find(
      (partnerObj) => formData.promoPartner === partnerObj.name
    )
  }

  function saveNewPromo(formData: PromoInput) {
    setFormInput(formData)
    const partner = getPartner(formData)
    if (partner) {
      const formattedPromoRequest = parsePromoRequest(formData, partner.id)
      createPromo(formattedPromoRequest)

    } else {
      createPromoPartner({ name: formData.promoPartner })
    }
  }

  return {
    saveNewPromo,
    isCreatePromoModalOpen, 
    setIsCreatePromoModalOpen,
  }
}

export function getPromotionSourceFromEnum(promoSourceType: number): string {
  if (promoSourceType === formattedPromotionSource.CUSTOMER.value) {
    return formattedPromotionSource.CUSTOMER.label
  }
  return formattedPromotionSource.PARTNER.label
}

export function parsePromoRequest(
  formData: PromoInput,
  partnerId: string
): CreatePromotionRequest.AsObject {
  return {
    promoCode: formData.promoCode,
    rebateValue: convertDollarToCents(formData.rebateValue),
    rebatePercent: 0,
    validFrom: formData.validFrom.getTime(),
    validUntil: formData.validUntil.getTime(),
    target: 0,
    source: 0,
    status: true,
    constraintsList: [] as PromotionConstraint.AsObject[],
    sourceId: partnerId
  }
}

export type PromoInput = {
  promoCode: string
  rebateValue: number
  validFrom: Date
  validUntil: Date
  promoPartner: string
}
