// source: notifications.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var domain_pb = require('./domain_pb.js');
goog.object.extend(proto, domain_pb);
goog.exportSymbol('proto.notifications.Attribute', null, global);
goog.exportSymbol('proto.notifications.Content', null, global);
goog.exportSymbol('proto.notifications.ContentType', null, global);
goog.exportSymbol('proto.notifications.DeliveryMethod', null, global);
goog.exportSymbol('proto.notifications.Encoding', null, global);
goog.exportSymbol('proto.notifications.Notification', null, global);
goog.exportSymbol('proto.notifications.NotificationReceived', null, global);
goog.exportSymbol('proto.notifications.NotificationStatusUpdated', null, global);
goog.exportSymbol('proto.notifications.Recipient', null, global);
goog.exportSymbol('proto.notifications.SendNotifications', null, global);
goog.exportSymbol('proto.notifications.Status', null, global);
goog.exportSymbol('proto.notifications.Time', null, global);
goog.exportSymbol('proto.notifications.VendorData', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.notifications.SendNotifications = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.notifications.SendNotifications.repeatedFields_, null);
};
goog.inherits(proto.notifications.SendNotifications, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.notifications.SendNotifications.displayName = 'proto.notifications.SendNotifications';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.notifications.NotificationReceived = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.notifications.NotificationReceived, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.notifications.NotificationReceived.displayName = 'proto.notifications.NotificationReceived';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.notifications.NotificationStatusUpdated = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.notifications.NotificationStatusUpdated.repeatedFields_, null);
};
goog.inherits(proto.notifications.NotificationStatusUpdated, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.notifications.NotificationStatusUpdated.displayName = 'proto.notifications.NotificationStatusUpdated';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.notifications.Notification = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.notifications.Notification.repeatedFields_, null);
};
goog.inherits(proto.notifications.Notification, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.notifications.Notification.displayName = 'proto.notifications.Notification';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.notifications.Time = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.notifications.Time, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.notifications.Time.displayName = 'proto.notifications.Time';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.notifications.Recipient = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.notifications.Recipient.repeatedFields_, null);
};
goog.inherits(proto.notifications.Recipient, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.notifications.Recipient.displayName = 'proto.notifications.Recipient';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.notifications.Content = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.notifications.Content, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.notifications.Content.displayName = 'proto.notifications.Content';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.notifications.Attribute = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.notifications.Attribute, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.notifications.Attribute.displayName = 'proto.notifications.Attribute';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.notifications.VendorData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.notifications.VendorData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.notifications.VendorData.displayName = 'proto.notifications.VendorData';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.notifications.SendNotifications.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.notifications.SendNotifications.prototype.toObject = function(opt_includeInstance) {
  return proto.notifications.SendNotifications.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.notifications.SendNotifications} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.notifications.SendNotifications.toObject = function(includeInstance, msg) {
  var f, obj = {
    domain: jspb.Message.getFieldWithDefault(msg, 1, 0),
    requestToken: jspb.Message.getFieldWithDefault(msg, 2, ""),
    notificationsList: jspb.Message.toObjectList(msg.getNotificationsList(),
    proto.notifications.Notification.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.notifications.SendNotifications}
 */
proto.notifications.SendNotifications.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.notifications.SendNotifications;
  return proto.notifications.SendNotifications.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.notifications.SendNotifications} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.notifications.SendNotifications}
 */
proto.notifications.SendNotifications.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.domain.Domain} */ (reader.readEnum());
      msg.setDomain(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestToken(value);
      break;
    case 3:
      var value = new proto.notifications.Notification;
      reader.readMessage(value,proto.notifications.Notification.deserializeBinaryFromReader);
      msg.addNotifications(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.notifications.SendNotifications.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.notifications.SendNotifications.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.notifications.SendNotifications} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.notifications.SendNotifications.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDomain();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getRequestToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getNotificationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.notifications.Notification.serializeBinaryToWriter
    );
  }
};


/**
 * optional domain.Domain domain = 1;
 * @return {!proto.domain.Domain}
 */
proto.notifications.SendNotifications.prototype.getDomain = function() {
  return /** @type {!proto.domain.Domain} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.domain.Domain} value
 * @return {!proto.notifications.SendNotifications} returns this
 */
proto.notifications.SendNotifications.prototype.setDomain = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string request_token = 2;
 * @return {string}
 */
proto.notifications.SendNotifications.prototype.getRequestToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.notifications.SendNotifications} returns this
 */
proto.notifications.SendNotifications.prototype.setRequestToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Notification notifications = 3;
 * @return {!Array<!proto.notifications.Notification>}
 */
proto.notifications.SendNotifications.prototype.getNotificationsList = function() {
  return /** @type{!Array<!proto.notifications.Notification>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.notifications.Notification, 3));
};


/**
 * @param {!Array<!proto.notifications.Notification>} value
 * @return {!proto.notifications.SendNotifications} returns this
*/
proto.notifications.SendNotifications.prototype.setNotificationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.notifications.Notification=} opt_value
 * @param {number=} opt_index
 * @return {!proto.notifications.Notification}
 */
proto.notifications.SendNotifications.prototype.addNotifications = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.notifications.Notification, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.notifications.SendNotifications} returns this
 */
proto.notifications.SendNotifications.prototype.clearNotificationsList = function() {
  return this.setNotificationsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.notifications.NotificationReceived.prototype.toObject = function(opt_includeInstance) {
  return proto.notifications.NotificationReceived.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.notifications.NotificationReceived} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.notifications.NotificationReceived.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestToken: jspb.Message.getFieldWithDefault(msg, 1, ""),
    domain: jspb.Message.getFieldWithDefault(msg, 2, 0),
    sender: (f = msg.getSender()) && domain_pb.AutonomyUser.toObject(includeInstance, f),
    notification: (f = msg.getNotification()) && proto.notifications.Notification.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.notifications.NotificationReceived}
 */
proto.notifications.NotificationReceived.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.notifications.NotificationReceived;
  return proto.notifications.NotificationReceived.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.notifications.NotificationReceived} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.notifications.NotificationReceived}
 */
proto.notifications.NotificationReceived.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestToken(value);
      break;
    case 2:
      var value = /** @type {!proto.domain.Domain} */ (reader.readEnum());
      msg.setDomain(value);
      break;
    case 3:
      var value = new domain_pb.AutonomyUser;
      reader.readMessage(value,domain_pb.AutonomyUser.deserializeBinaryFromReader);
      msg.setSender(value);
      break;
    case 4:
      var value = new proto.notifications.Notification;
      reader.readMessage(value,proto.notifications.Notification.deserializeBinaryFromReader);
      msg.setNotification(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.notifications.NotificationReceived.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.notifications.NotificationReceived.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.notifications.NotificationReceived} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.notifications.NotificationReceived.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDomain();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getSender();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      domain_pb.AutonomyUser.serializeBinaryToWriter
    );
  }
  f = message.getNotification();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.notifications.Notification.serializeBinaryToWriter
    );
  }
};


/**
 * optional string request_token = 1;
 * @return {string}
 */
proto.notifications.NotificationReceived.prototype.getRequestToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.notifications.NotificationReceived} returns this
 */
proto.notifications.NotificationReceived.prototype.setRequestToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional domain.Domain domain = 2;
 * @return {!proto.domain.Domain}
 */
proto.notifications.NotificationReceived.prototype.getDomain = function() {
  return /** @type {!proto.domain.Domain} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.domain.Domain} value
 * @return {!proto.notifications.NotificationReceived} returns this
 */
proto.notifications.NotificationReceived.prototype.setDomain = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional domain.AutonomyUser sender = 3;
 * @return {?proto.domain.AutonomyUser}
 */
proto.notifications.NotificationReceived.prototype.getSender = function() {
  return /** @type{?proto.domain.AutonomyUser} */ (
    jspb.Message.getWrapperField(this, domain_pb.AutonomyUser, 3));
};


/**
 * @param {?proto.domain.AutonomyUser|undefined} value
 * @return {!proto.notifications.NotificationReceived} returns this
*/
proto.notifications.NotificationReceived.prototype.setSender = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.notifications.NotificationReceived} returns this
 */
proto.notifications.NotificationReceived.prototype.clearSender = function() {
  return this.setSender(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.notifications.NotificationReceived.prototype.hasSender = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Notification notification = 4;
 * @return {?proto.notifications.Notification}
 */
proto.notifications.NotificationReceived.prototype.getNotification = function() {
  return /** @type{?proto.notifications.Notification} */ (
    jspb.Message.getWrapperField(this, proto.notifications.Notification, 4));
};


/**
 * @param {?proto.notifications.Notification|undefined} value
 * @return {!proto.notifications.NotificationReceived} returns this
*/
proto.notifications.NotificationReceived.prototype.setNotification = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.notifications.NotificationReceived} returns this
 */
proto.notifications.NotificationReceived.prototype.clearNotification = function() {
  return this.setNotification(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.notifications.NotificationReceived.prototype.hasNotification = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.notifications.NotificationStatusUpdated.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.notifications.NotificationStatusUpdated.prototype.toObject = function(opt_includeInstance) {
  return proto.notifications.NotificationStatusUpdated.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.notifications.NotificationStatusUpdated} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.notifications.NotificationStatusUpdated.toObject = function(includeInstance, msg) {
  var f, obj = {
    notificationId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    domain: jspb.Message.getFieldWithDefault(msg, 2, 0),
    requestToken: jspb.Message.getFieldWithDefault(msg, 3, ""),
    status: jspb.Message.getFieldWithDefault(msg, 4, 0),
    vendorDataList: jspb.Message.toObjectList(msg.getVendorDataList(),
    proto.notifications.VendorData.toObject, includeInstance),
    deliveryMethod: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.notifications.NotificationStatusUpdated}
 */
proto.notifications.NotificationStatusUpdated.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.notifications.NotificationStatusUpdated;
  return proto.notifications.NotificationStatusUpdated.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.notifications.NotificationStatusUpdated} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.notifications.NotificationStatusUpdated}
 */
proto.notifications.NotificationStatusUpdated.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setNotificationId(value);
      break;
    case 2:
      var value = /** @type {!proto.domain.Domain} */ (reader.readEnum());
      msg.setDomain(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestToken(value);
      break;
    case 4:
      var value = /** @type {!proto.notifications.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 5:
      var value = new proto.notifications.VendorData;
      reader.readMessage(value,proto.notifications.VendorData.deserializeBinaryFromReader);
      msg.addVendorData(value);
      break;
    case 6:
      var value = /** @type {!proto.notifications.DeliveryMethod} */ (reader.readEnum());
      msg.setDeliveryMethod(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.notifications.NotificationStatusUpdated.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.notifications.NotificationStatusUpdated.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.notifications.NotificationStatusUpdated} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.notifications.NotificationStatusUpdated.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNotificationId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDomain();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getRequestToken();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getVendorDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.notifications.VendorData.serializeBinaryToWriter
    );
  }
  f = message.getDeliveryMethod();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
};


/**
 * optional string notification_id = 1;
 * @return {string}
 */
proto.notifications.NotificationStatusUpdated.prototype.getNotificationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.notifications.NotificationStatusUpdated} returns this
 */
proto.notifications.NotificationStatusUpdated.prototype.setNotificationId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional domain.Domain domain = 2;
 * @return {!proto.domain.Domain}
 */
proto.notifications.NotificationStatusUpdated.prototype.getDomain = function() {
  return /** @type {!proto.domain.Domain} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.domain.Domain} value
 * @return {!proto.notifications.NotificationStatusUpdated} returns this
 */
proto.notifications.NotificationStatusUpdated.prototype.setDomain = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string request_token = 3;
 * @return {string}
 */
proto.notifications.NotificationStatusUpdated.prototype.getRequestToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.notifications.NotificationStatusUpdated} returns this
 */
proto.notifications.NotificationStatusUpdated.prototype.setRequestToken = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional Status status = 4;
 * @return {!proto.notifications.Status}
 */
proto.notifications.NotificationStatusUpdated.prototype.getStatus = function() {
  return /** @type {!proto.notifications.Status} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.notifications.Status} value
 * @return {!proto.notifications.NotificationStatusUpdated} returns this
 */
proto.notifications.NotificationStatusUpdated.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * repeated VendorData vendor_data = 5;
 * @return {!Array<!proto.notifications.VendorData>}
 */
proto.notifications.NotificationStatusUpdated.prototype.getVendorDataList = function() {
  return /** @type{!Array<!proto.notifications.VendorData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.notifications.VendorData, 5));
};


/**
 * @param {!Array<!proto.notifications.VendorData>} value
 * @return {!proto.notifications.NotificationStatusUpdated} returns this
*/
proto.notifications.NotificationStatusUpdated.prototype.setVendorDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.notifications.VendorData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.notifications.VendorData}
 */
proto.notifications.NotificationStatusUpdated.prototype.addVendorData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.notifications.VendorData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.notifications.NotificationStatusUpdated} returns this
 */
proto.notifications.NotificationStatusUpdated.prototype.clearVendorDataList = function() {
  return this.setVendorDataList([]);
};


/**
 * optional DeliveryMethod delivery_method = 6;
 * @return {!proto.notifications.DeliveryMethod}
 */
proto.notifications.NotificationStatusUpdated.prototype.getDeliveryMethod = function() {
  return /** @type {!proto.notifications.DeliveryMethod} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.notifications.DeliveryMethod} value
 * @return {!proto.notifications.NotificationStatusUpdated} returns this
 */
proto.notifications.NotificationStatusUpdated.prototype.setDeliveryMethod = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.notifications.Notification.repeatedFields_ = [3,4,5,8,9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.notifications.Notification.prototype.toObject = function(opt_includeInstance) {
  return proto.notifications.Notification.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.notifications.Notification} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.notifications.Notification.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sender: (f = msg.getSender()) && domain_pb.AutonomyUser.toObject(includeInstance, f),
    recipientsList: jspb.Message.toObjectList(msg.getRecipientsList(),
    proto.notifications.Recipient.toObject, includeInstance),
    contentList: jspb.Message.toObjectList(msg.getContentList(),
    proto.notifications.Content.toObject, includeInstance),
    scheduledForTimesList: jspb.Message.toObjectList(msg.getScheduledForTimesList(),
    proto.notifications.Time.toObject, includeInstance),
    status: jspb.Message.getFieldWithDefault(msg, 6, 0),
    readAt: jspb.Message.getFieldWithDefault(msg, 7, 0),
    vendorDataList: jspb.Message.toObjectList(msg.getVendorDataList(),
    proto.notifications.VendorData.toObject, includeInstance),
    attributesList: jspb.Message.toObjectList(msg.getAttributesList(),
    proto.notifications.Attribute.toObject, includeInstance),
    createdAt: jspb.Message.getFieldWithDefault(msg, 10, 0),
    sentAt: jspb.Message.getFieldWithDefault(msg, 11, 0),
    deletedAt: jspb.Message.getFieldWithDefault(msg, 12, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.notifications.Notification}
 */
proto.notifications.Notification.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.notifications.Notification;
  return proto.notifications.Notification.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.notifications.Notification} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.notifications.Notification}
 */
proto.notifications.Notification.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new domain_pb.AutonomyUser;
      reader.readMessage(value,domain_pb.AutonomyUser.deserializeBinaryFromReader);
      msg.setSender(value);
      break;
    case 3:
      var value = new proto.notifications.Recipient;
      reader.readMessage(value,proto.notifications.Recipient.deserializeBinaryFromReader);
      msg.addRecipients(value);
      break;
    case 4:
      var value = new proto.notifications.Content;
      reader.readMessage(value,proto.notifications.Content.deserializeBinaryFromReader);
      msg.addContent(value);
      break;
    case 5:
      var value = new proto.notifications.Time;
      reader.readMessage(value,proto.notifications.Time.deserializeBinaryFromReader);
      msg.addScheduledForTimes(value);
      break;
    case 6:
      var value = /** @type {!proto.notifications.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setReadAt(value);
      break;
    case 8:
      var value = new proto.notifications.VendorData;
      reader.readMessage(value,proto.notifications.VendorData.deserializeBinaryFromReader);
      msg.addVendorData(value);
      break;
    case 9:
      var value = new proto.notifications.Attribute;
      reader.readMessage(value,proto.notifications.Attribute.deserializeBinaryFromReader);
      msg.addAttributes(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSentAt(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDeletedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.notifications.Notification.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.notifications.Notification.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.notifications.Notification} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.notifications.Notification.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSender();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      domain_pb.AutonomyUser.serializeBinaryToWriter
    );
  }
  f = message.getRecipientsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.notifications.Recipient.serializeBinaryToWriter
    );
  }
  f = message.getContentList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.notifications.Content.serializeBinaryToWriter
    );
  }
  f = message.getScheduledForTimesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.notifications.Time.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getReadAt();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getVendorDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.notifications.VendorData.serializeBinaryToWriter
    );
  }
  f = message.getAttributesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.notifications.Attribute.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getSentAt();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getDeletedAt();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.notifications.Notification.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.notifications.Notification} returns this
 */
proto.notifications.Notification.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional domain.AutonomyUser sender = 2;
 * @return {?proto.domain.AutonomyUser}
 */
proto.notifications.Notification.prototype.getSender = function() {
  return /** @type{?proto.domain.AutonomyUser} */ (
    jspb.Message.getWrapperField(this, domain_pb.AutonomyUser, 2));
};


/**
 * @param {?proto.domain.AutonomyUser|undefined} value
 * @return {!proto.notifications.Notification} returns this
*/
proto.notifications.Notification.prototype.setSender = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.notifications.Notification} returns this
 */
proto.notifications.Notification.prototype.clearSender = function() {
  return this.setSender(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.notifications.Notification.prototype.hasSender = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated Recipient recipients = 3;
 * @return {!Array<!proto.notifications.Recipient>}
 */
proto.notifications.Notification.prototype.getRecipientsList = function() {
  return /** @type{!Array<!proto.notifications.Recipient>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.notifications.Recipient, 3));
};


/**
 * @param {!Array<!proto.notifications.Recipient>} value
 * @return {!proto.notifications.Notification} returns this
*/
proto.notifications.Notification.prototype.setRecipientsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.notifications.Recipient=} opt_value
 * @param {number=} opt_index
 * @return {!proto.notifications.Recipient}
 */
proto.notifications.Notification.prototype.addRecipients = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.notifications.Recipient, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.notifications.Notification} returns this
 */
proto.notifications.Notification.prototype.clearRecipientsList = function() {
  return this.setRecipientsList([]);
};


/**
 * repeated Content content = 4;
 * @return {!Array<!proto.notifications.Content>}
 */
proto.notifications.Notification.prototype.getContentList = function() {
  return /** @type{!Array<!proto.notifications.Content>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.notifications.Content, 4));
};


/**
 * @param {!Array<!proto.notifications.Content>} value
 * @return {!proto.notifications.Notification} returns this
*/
proto.notifications.Notification.prototype.setContentList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.notifications.Content=} opt_value
 * @param {number=} opt_index
 * @return {!proto.notifications.Content}
 */
proto.notifications.Notification.prototype.addContent = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.notifications.Content, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.notifications.Notification} returns this
 */
proto.notifications.Notification.prototype.clearContentList = function() {
  return this.setContentList([]);
};


/**
 * repeated Time scheduled_for_times = 5;
 * @return {!Array<!proto.notifications.Time>}
 */
proto.notifications.Notification.prototype.getScheduledForTimesList = function() {
  return /** @type{!Array<!proto.notifications.Time>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.notifications.Time, 5));
};


/**
 * @param {!Array<!proto.notifications.Time>} value
 * @return {!proto.notifications.Notification} returns this
*/
proto.notifications.Notification.prototype.setScheduledForTimesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.notifications.Time=} opt_value
 * @param {number=} opt_index
 * @return {!proto.notifications.Time}
 */
proto.notifications.Notification.prototype.addScheduledForTimes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.notifications.Time, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.notifications.Notification} returns this
 */
proto.notifications.Notification.prototype.clearScheduledForTimesList = function() {
  return this.setScheduledForTimesList([]);
};


/**
 * optional Status status = 6;
 * @return {!proto.notifications.Status}
 */
proto.notifications.Notification.prototype.getStatus = function() {
  return /** @type {!proto.notifications.Status} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.notifications.Status} value
 * @return {!proto.notifications.Notification} returns this
 */
proto.notifications.Notification.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional int64 read_at = 7;
 * @return {number}
 */
proto.notifications.Notification.prototype.getReadAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.notifications.Notification} returns this
 */
proto.notifications.Notification.prototype.setReadAt = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * repeated VendorData vendor_data = 8;
 * @return {!Array<!proto.notifications.VendorData>}
 */
proto.notifications.Notification.prototype.getVendorDataList = function() {
  return /** @type{!Array<!proto.notifications.VendorData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.notifications.VendorData, 8));
};


/**
 * @param {!Array<!proto.notifications.VendorData>} value
 * @return {!proto.notifications.Notification} returns this
*/
proto.notifications.Notification.prototype.setVendorDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.notifications.VendorData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.notifications.VendorData}
 */
proto.notifications.Notification.prototype.addVendorData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.notifications.VendorData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.notifications.Notification} returns this
 */
proto.notifications.Notification.prototype.clearVendorDataList = function() {
  return this.setVendorDataList([]);
};


/**
 * repeated Attribute attributes = 9;
 * @return {!Array<!proto.notifications.Attribute>}
 */
proto.notifications.Notification.prototype.getAttributesList = function() {
  return /** @type{!Array<!proto.notifications.Attribute>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.notifications.Attribute, 9));
};


/**
 * @param {!Array<!proto.notifications.Attribute>} value
 * @return {!proto.notifications.Notification} returns this
*/
proto.notifications.Notification.prototype.setAttributesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.notifications.Attribute=} opt_value
 * @param {number=} opt_index
 * @return {!proto.notifications.Attribute}
 */
proto.notifications.Notification.prototype.addAttributes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.notifications.Attribute, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.notifications.Notification} returns this
 */
proto.notifications.Notification.prototype.clearAttributesList = function() {
  return this.setAttributesList([]);
};


/**
 * optional int64 created_at = 10;
 * @return {number}
 */
proto.notifications.Notification.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.notifications.Notification} returns this
 */
proto.notifications.Notification.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int64 sent_at = 11;
 * @return {number}
 */
proto.notifications.Notification.prototype.getSentAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.notifications.Notification} returns this
 */
proto.notifications.Notification.prototype.setSentAt = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int64 deleted_at = 12;
 * @return {number}
 */
proto.notifications.Notification.prototype.getDeletedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.notifications.Notification} returns this
 */
proto.notifications.Notification.prototype.setDeletedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.notifications.Time.prototype.toObject = function(opt_includeInstance) {
  return proto.notifications.Time.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.notifications.Time} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.notifications.Time.toObject = function(includeInstance, msg) {
  var f, obj = {
    epochMilli: jspb.Message.getFieldWithDefault(msg, 1, 0),
    timezone: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.notifications.Time}
 */
proto.notifications.Time.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.notifications.Time;
  return proto.notifications.Time.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.notifications.Time} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.notifications.Time}
 */
proto.notifications.Time.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEpochMilli(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimezone(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.notifications.Time.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.notifications.Time.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.notifications.Time} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.notifications.Time.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEpochMilli();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTimezone();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int64 epoch_milli = 1;
 * @return {number}
 */
proto.notifications.Time.prototype.getEpochMilli = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.notifications.Time} returns this
 */
proto.notifications.Time.prototype.setEpochMilli = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string timezone = 2;
 * @return {string}
 */
proto.notifications.Time.prototype.getTimezone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.notifications.Time} returns this
 */
proto.notifications.Time.prototype.setTimezone = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.notifications.Recipient.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.notifications.Recipient.prototype.toObject = function(opt_includeInstance) {
  return proto.notifications.Recipient.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.notifications.Recipient} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.notifications.Recipient.toObject = function(includeInstance, msg) {
  var f, obj = {
    userData: (f = msg.getUserData()) && domain_pb.AutonomyUser.toObject(includeInstance, f),
    deliveryMethodsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.notifications.Recipient}
 */
proto.notifications.Recipient.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.notifications.Recipient;
  return proto.notifications.Recipient.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.notifications.Recipient} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.notifications.Recipient}
 */
proto.notifications.Recipient.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new domain_pb.AutonomyUser;
      reader.readMessage(value,domain_pb.AutonomyUser.deserializeBinaryFromReader);
      msg.setUserData(value);
      break;
    case 2:
      var values = /** @type {!Array<!proto.notifications.DeliveryMethod>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addDeliveryMethods(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.notifications.Recipient.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.notifications.Recipient.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.notifications.Recipient} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.notifications.Recipient.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserData();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      domain_pb.AutonomyUser.serializeBinaryToWriter
    );
  }
  f = message.getDeliveryMethodsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      2,
      f
    );
  }
};


/**
 * optional domain.AutonomyUser user_data = 1;
 * @return {?proto.domain.AutonomyUser}
 */
proto.notifications.Recipient.prototype.getUserData = function() {
  return /** @type{?proto.domain.AutonomyUser} */ (
    jspb.Message.getWrapperField(this, domain_pb.AutonomyUser, 1));
};


/**
 * @param {?proto.domain.AutonomyUser|undefined} value
 * @return {!proto.notifications.Recipient} returns this
*/
proto.notifications.Recipient.prototype.setUserData = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.notifications.Recipient} returns this
 */
proto.notifications.Recipient.prototype.clearUserData = function() {
  return this.setUserData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.notifications.Recipient.prototype.hasUserData = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated DeliveryMethod delivery_methods = 2;
 * @return {!Array<!proto.notifications.DeliveryMethod>}
 */
proto.notifications.Recipient.prototype.getDeliveryMethodsList = function() {
  return /** @type {!Array<!proto.notifications.DeliveryMethod>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<!proto.notifications.DeliveryMethod>} value
 * @return {!proto.notifications.Recipient} returns this
 */
proto.notifications.Recipient.prototype.setDeliveryMethodsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {!proto.notifications.DeliveryMethod} value
 * @param {number=} opt_index
 * @return {!proto.notifications.Recipient} returns this
 */
proto.notifications.Recipient.prototype.addDeliveryMethods = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.notifications.Recipient} returns this
 */
proto.notifications.Recipient.prototype.clearDeliveryMethodsList = function() {
  return this.setDeliveryMethodsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.notifications.Content.prototype.toObject = function(opt_includeInstance) {
  return proto.notifications.Content.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.notifications.Content} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.notifications.Content.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    encoding: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.notifications.Content}
 */
proto.notifications.Content.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.notifications.Content;
  return proto.notifications.Content.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.notifications.Content} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.notifications.Content}
 */
proto.notifications.Content.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.notifications.ContentType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {!proto.notifications.Encoding} */ (reader.readEnum());
      msg.setEncoding(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.notifications.Content.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.notifications.Content.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.notifications.Content} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.notifications.Content.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEncoding();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional ContentType type = 1;
 * @return {!proto.notifications.ContentType}
 */
proto.notifications.Content.prototype.getType = function() {
  return /** @type {!proto.notifications.ContentType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.notifications.ContentType} value
 * @return {!proto.notifications.Content} returns this
 */
proto.notifications.Content.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.notifications.Content.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.notifications.Content} returns this
 */
proto.notifications.Content.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Encoding encoding = 3;
 * @return {!proto.notifications.Encoding}
 */
proto.notifications.Content.prototype.getEncoding = function() {
  return /** @type {!proto.notifications.Encoding} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.notifications.Encoding} value
 * @return {!proto.notifications.Content} returns this
 */
proto.notifications.Content.prototype.setEncoding = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.notifications.Attribute.prototype.toObject = function(opt_includeInstance) {
  return proto.notifications.Attribute.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.notifications.Attribute} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.notifications.Attribute.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.notifications.Attribute}
 */
proto.notifications.Attribute.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.notifications.Attribute;
  return proto.notifications.Attribute.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.notifications.Attribute} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.notifications.Attribute}
 */
proto.notifications.Attribute.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.notifications.Attribute.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.notifications.Attribute.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.notifications.Attribute} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.notifications.Attribute.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.notifications.Attribute.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.notifications.Attribute} returns this
 */
proto.notifications.Attribute.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.notifications.Attribute.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.notifications.Attribute} returns this
 */
proto.notifications.Attribute.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.notifications.VendorData.prototype.toObject = function(opt_includeInstance) {
  return proto.notifications.VendorData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.notifications.VendorData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.notifications.VendorData.toObject = function(includeInstance, msg) {
  var f, obj = {
    property: jspb.Message.getFieldWithDefault(msg, 1, ""),
    data: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.notifications.VendorData}
 */
proto.notifications.VendorData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.notifications.VendorData;
  return proto.notifications.VendorData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.notifications.VendorData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.notifications.VendorData}
 */
proto.notifications.VendorData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProperty(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.notifications.VendorData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.notifications.VendorData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.notifications.VendorData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.notifications.VendorData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProperty();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string property = 1;
 * @return {string}
 */
proto.notifications.VendorData.prototype.getProperty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.notifications.VendorData} returns this
 */
proto.notifications.VendorData.prototype.setProperty = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.notifications.VendorData.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.notifications.VendorData} returns this
 */
proto.notifications.VendorData.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * @enum {number}
 */
proto.notifications.Status = {
  SCHEDULED: 0,
  SENT: 1,
  DELIVERED: 2
};

/**
 * @enum {number}
 */
proto.notifications.DeliveryMethod = {
  SMS: 0,
  EMAIL: 1,
  IN_APP: 2
};

/**
 * @enum {number}
 */
proto.notifications.ContentType = {
  SUBJECT: 0,
  BODY: 1
};

/**
 * @enum {number}
 */
proto.notifications.Encoding = {
  PLAIN_TEXT: 0,
  JSON_STRING: 1,
  BASE_64: 2,
  BINARY: 3
};

goog.object.extend(exports, proto.notifications);
