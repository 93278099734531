import {
  OverrideQualificationRequest,
  RecalculateCustomerScoresRequest,
  GetCQEScoresRequest,
  ResetLoginAttemptsRequest,
} from '@nxcr-org/web-api-interface/lib/customer_service_pb'
import { NexusServicePromiseClient } from '@nxcr-org/web-api-interface/lib/nexus_service_grpc_web_pb'
import {
  AddCustomerNoteRequest,
  GetCustomerNotesRequest,
} from '@nxcr-org/web-api-interface/lib/nexus_service_pb'

import { oktaAuth, OktaAuthInterceptor } from '../../okta/config'
import { spyOnResponse, spyOnError } from '../../utils/spyOnResponse'
import { Env } from '../fleet-management/api/env'
import { CustomerServicePromiseClient } from '@nxcr-org/web-api-interface/lib/gateway_service_grpc_web_pb'

export const CQEService = {
  addCustomerNote,
  overrideCustomerQualification,
  recalculateCustomerQualification,
  getCustomerNotes,
  getCustomerCQEScores,
}

function customerServiceClient() {
  const idToken = oktaAuth.getIdToken()
  const target = Env.endpoints.fleet

  return new CustomerServicePromiseClient(target, null, {
    unaryInterceptors: [new OktaAuthInterceptor(idToken)],
  })
}

function nexusClient(): NexusServicePromiseClient {
  const idToken = oktaAuth.getIdToken()
  const target = Env.endpoints.fleet

  return new NexusServicePromiseClient(target, null, {
    unaryInterceptors: [new OktaAuthInterceptor(idToken)],
  })
}

function overrideCustomerQualification(
  params: OverrideQualificationRequest.AsObject
) {
  const client = nexusClient()

  const request = new OverrideQualificationRequest()

  request.setCustomerId(params.customerId)
  request.setTier(params.tier)
  request.setDisqualifiedScoreId(params.disqualifiedScoreId)

  return client
    .overrideCustomerQualification(request)
    .catch(spyOnError('overrideCustomerQualification'))
}

function getCustomerNotes(params: GetCustomerNotesRequest.AsObject) {
  const { customerId } = params

  const client = nexusClient()
  const request = new GetCustomerNotesRequest()

  request.setCustomerId(customerId)

  return client.getCustomerNotes(request).then((res) => res.toObject())
}

export function buildAddNoteRequest(
  params: Partial<AddCustomerNoteRequest.AsObject>
) {
  const request = new AddCustomerNoteRequest()

  request.setCustomerId(params.customerId)
  request.setNoteText(params.noteText)

  return request
}

function addCustomerNote(params: Partial<AddCustomerNoteRequest.AsObject>) {
  const client = nexusClient()
  const request = buildAddNoteRequest(params)

  return client
    .addCustomerNote(request)
    .then(spyOnResponse('addCustomerNote'))
    .catch(spyOnError('addCustomerNote'))
}

export function buildRecalcaulateScoresRequest(params) {
  if (!params.customerId) {
    throw new Error('Request requires customer ID')
  }

  const request = new RecalculateCustomerScoresRequest()
  request.setCustomerId(params.customerId)
  request.setScoreNamesList(params.scoreNamesList)

  return request
}

async function recalculateCustomerQualification(
  params: RecalculateCustomerScoresRequest.AsObject
) {
  const client = nexusClient()
  const request = buildRecalcaulateScoresRequest(params)

  return client.recalculateCustomerQualification(request)
}

async function getCustomerCQEScores(params: GetCQEScoresRequest.AsObject) {
  const client = nexusClient()
  const request = new GetCQEScoresRequest()

  request.setCustomerId(params.customerId)

  return client.getCustomerCQEScores(request).then((res) => res.toObject())
}

export function resetLoginAttempts(data: ResetLoginAttemptsRequest.AsObject) {
  const client = customerServiceClient()
  const request = new ResetLoginAttemptsRequest()

  request.setAccountNumber(data.accountNumber)
  request.setCustomerId(data.customerId)
  request.setPhoneNumber(data.phoneNumber)

  return client.resetLoginAttempts(request)
}
