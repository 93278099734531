import React from 'react'
import {
  TextField,
  Typography,
  Grid,
  CircularProgress,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
} from '@mui/material'
import {IAppointmentTypeConfig} from '../../../utils'
import {AppointmentTypeConfig} from '@nxcr-org/web-api-interface/lib/appointment_pb'

interface EventTypeBlockProps {
  isLoading: boolean
  isEditing: boolean
  listEventTypes?: IAppointmentTypeConfig[]
  setEventTypes?: (eventTypes: AppointmentTypeConfig.AsObject[]) => void,
}

/*
* Note: Event types refer to AppointmentTypeConfigs in this context
* ( Appointment.AppointmentTypeConfig )
* */
export const EventTypeBlock: React.FC<EventTypeBlockProps> = ({
  isLoading,
  isEditing,
  listEventTypes,
  setEventTypes,
}) => {

  return isLoading ? (
    <Box sx={{ width: '100%', textAlign: 'center' }}>
      <CircularProgress />
    </Box>
  ) : (
    <Grid container direction="column" spacing={6}>
      <Grid item>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="caption">Event type</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="caption">Duration (Minutes)</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="caption">Count per slot</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {listEventTypes?.map((row) => (
              <TableRow key={row.id}>
                <TableCell width="15%">
                  <Typography>{row.typeLabel}</Typography>
                </TableCell>
                <TableCell width="15%">
                  {isEditing ? (
                    <TextField
                      className="compactTextField roundedTextField xs"
                      placeholder="Duration"
                      type="number"
                      value={row.durationMinutes}
                      variant="outlined"
                      onChange={(e) => {
                        listEventTypes[
                          listEventTypes.findIndex(
                            (eventType) => eventType.id === row.id
                          )
                        ].durationMinutes = parseInt(e.target.value, 10)
                        setEventTypes(listEventTypes)
                      }}
                    />
                  ) : (
                    <Typography>{row.durationMinutes}</Typography>
                  )}
                </TableCell>
                <TableCell width="70%">
                  {isEditing ? (
                    <TextField
                      className="compactTextField roundedTextField xs"
                      placeholder="Count per slot"
                      type="number"
                      value={row.countPerSlot}
                      variant="outlined"
                      onChange={(e) => {
                        listEventTypes[
                          listEventTypes.findIndex(
                            (eventType) => eventType.id === row.id
                          )
                        ].countPerSlot = parseInt(e.target.value, 10)
                        setEventTypes(listEventTypes)
                      }}
                    />
                  ) : (
                    <Typography>{row.countPerSlot}</Typography>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  )
}
