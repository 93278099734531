import React from 'react'
import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material'
import { useQueryClient } from 'react-query'
import makeStyles from '@mui/styles/makeStyles'
import { useOktaAuth } from '@okta/okta-react'
import Transition from 'shared/Transition'

const useStyles = makeStyles((theme) => ({
  paper: {
    minWidth: '20vw',
    padding: '15px 20px 20px 0',
  },
  dealerName: {
    textTransform: 'capitalize',
  },
  delistButton: {
    backgroundColor: theme.palette.error.main,
  },
}))

interface ListDelistModalProps {
  open: boolean
  onClose: () => void
  listDelistMutation: any
}

export const ListDelistModal: React.FC<ListDelistModalProps> = ({
  open,
  onClose,
  listDelistMutation,
}) => {
  const { authState } = useOktaAuth()
  const classes = useStyles()

  const queryClient = useQueryClient()
  const onClickListDelist = async () => {
    try {
      await listDelistMutation.mutateAsync()
    } catch (error) {
      console.log('error', error)
    } finally {
      onClose()
      queryClient.invalidateQueries('getVehicles')
    }
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      classes={{ paper: classes.paper }}
    >
      <DialogTitle id="dialog-title">
        Would you like to list/delist this vehicle?
      </DialogTitle>

      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        {authState.isAuthenticated && (
          <Button
            onClick={() => onClickListDelist()}
            autoFocus
            variant="contained"
            color="primary"
          >
            yes
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}
