import { NexusServicePromiseClient } from '@nxcr-org/web-api-interface/lib/nexus_service_grpc_web_pb'
import { oktaAuth, OktaAuthInterceptor } from '../../okta/config'
import { Env } from '../fleet-management/api/env'

import {
  ListAppointmentTypeConfigsRequest,
  ListDealershipsRequest,
  ListDealershipUsersRequest,
  SetAppointmentTypeConfigsRequest
} from '@nxcr-org/web-api-interface/lib/nexus_service_pb'
import {AppointmentTypeConfig} from '@nxcr-org/web-api-interface/lib/appointment_pb'
import { spyOnResponse } from '../../utils/spyOnResponse'

function getNexusService(): NexusServicePromiseClient {
  const idToken = oktaAuth.getIdToken()
  const target = Env.endpoints.fleet

  return new NexusServicePromiseClient(target, null, {
    unaryInterceptors: [new OktaAuthInterceptor(idToken)],
    'grpc-node.max_session_memory': 1000,
    'grpc-node.max_send_message_length': 1024 * 1024 * 100,
    'grpc-node.max_receive_message_length': 1024 * 1024 * 100,
  })
}

export async function listDealerships(){
  const client = getNexusService()
  const request = new ListDealershipsRequest()

  return client
    .listDealerships(request)
    .then(spyOnResponse('listDealerships'))
    .then((res) => {
      return res.toObject()
    })
}

export async function listAppointmentTypeConfigs(){
  const client = getNexusService()
  const request = new ListAppointmentTypeConfigsRequest()

  return client
    .listAppointmentTypeConfigs(request)
    .then(spyOnResponse('list appointment type configs'))
    .then((res) => {
      return res.toObject()
    })
}

export async function setAppointmentTypeConfigs(
  appointmentTypeConfigsList: AppointmentTypeConfig[]
){
  const client = getNexusService()
  const request = buildSetAppointmentTypeConfigsRequest(appointmentTypeConfigsList)

  return client
    .setAppointmentTypeConfigs(request)
    .then(spyOnResponse('set appointment type configs'))
    .then((res) => {
      return res
    })
}

export function buildSetAppointmentTypeConfigsRequest(
  appointmentTypeConfigsList: AppointmentTypeConfig[]
){
  const request = new SetAppointmentTypeConfigsRequest()

  request.setAppointmentTypeConfigsList(appointmentTypeConfigsList)

  return request
}

export async function listDealershipUsers(dealershipId: string){
  const client = getNexusService()
  const request = buildListDealershipUsersRequest(dealershipId)

  return client
    .listDealershipUsers(request)
    .then(spyOnResponse('list dealership users'))
    .then((res) => {
      return res.toObject()
    })
}

export function buildListDealershipUsersRequest(dealershipId: string){
  const request = new ListDealershipUsersRequest()

  request.setDealershipId(dealershipId)

  return request
}
