// source: appointment.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var domain_pb = require('./domain_pb.js');
goog.object.extend(proto, domain_pb);
goog.exportSymbol('proto.appointment.Appointment', null, global);
goog.exportSymbol('proto.appointment.AppointmentCancelled', null, global);
goog.exportSymbol('proto.appointment.AppointmentCheckoutStatus', null, global);
goog.exportSymbol('proto.appointment.AppointmentCreated', null, global);
goog.exportSymbol('proto.appointment.AppointmentKeyHandOff', null, global);
goog.exportSymbol('proto.appointment.AppointmentPickupDate', null, global);
goog.exportSymbol('proto.appointment.AppointmentStatus', null, global);
goog.exportSymbol('proto.appointment.AppointmentStatusUpdated', null, global);
goog.exportSymbol('proto.appointment.AppointmentType', null, global);
goog.exportSymbol('proto.appointment.AppointmentTypeConfig', null, global);
goog.exportSymbol('proto.appointment.AppointmentTypeUpdated', null, global);
goog.exportSymbol('proto.appointment.AppointmentUpdated', null, global);
goog.exportSymbol('proto.appointment.AppointmentVehicleUpdated', null, global);
goog.exportSymbol('proto.appointment.Availability', null, global);
goog.exportSymbol('proto.appointment.AvailabilityCreated', null, global);
goog.exportSymbol('proto.appointment.AvailableTime', null, global);
goog.exportSymbol('proto.appointment.AvailableTime.DayOfWeek', null, global);
goog.exportSymbol('proto.appointment.CustomerInfo', null, global);
goog.exportSymbol('proto.appointment.DealershipInfo', null, global);
goog.exportSymbol('proto.appointment.Note', null, global);
goog.exportSymbol('proto.appointment.VehicleInfo', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointment.Availability = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.appointment.Availability.repeatedFields_, null);
};
goog.inherits(proto.appointment.Availability, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointment.Availability.displayName = 'proto.appointment.Availability';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointment.AvailableTime = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointment.AvailableTime, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointment.AvailableTime.displayName = 'proto.appointment.AvailableTime';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointment.Appointment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.appointment.Appointment.repeatedFields_, null);
};
goog.inherits(proto.appointment.Appointment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointment.Appointment.displayName = 'proto.appointment.Appointment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointment.DealershipInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointment.DealershipInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointment.DealershipInfo.displayName = 'proto.appointment.DealershipInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointment.CustomerInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointment.CustomerInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointment.CustomerInfo.displayName = 'proto.appointment.CustomerInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointment.VehicleInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointment.VehicleInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointment.VehicleInfo.displayName = 'proto.appointment.VehicleInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointment.Note = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointment.Note, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointment.Note.displayName = 'proto.appointment.Note';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointment.AppointmentTypeConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointment.AppointmentTypeConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointment.AppointmentTypeConfig.displayName = 'proto.appointment.AppointmentTypeConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointment.AppointmentCreated = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointment.AppointmentCreated, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointment.AppointmentCreated.displayName = 'proto.appointment.AppointmentCreated';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointment.AppointmentCancelled = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointment.AppointmentCancelled, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointment.AppointmentCancelled.displayName = 'proto.appointment.AppointmentCancelled';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointment.AppointmentTypeUpdated = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointment.AppointmentTypeUpdated, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointment.AppointmentTypeUpdated.displayName = 'proto.appointment.AppointmentTypeUpdated';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointment.AppointmentStatusUpdated = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointment.AppointmentStatusUpdated, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointment.AppointmentStatusUpdated.displayName = 'proto.appointment.AppointmentStatusUpdated';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointment.AppointmentUpdated = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointment.AppointmentUpdated, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointment.AppointmentUpdated.displayName = 'proto.appointment.AppointmentUpdated';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointment.AppointmentVehicleUpdated = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointment.AppointmentVehicleUpdated, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointment.AppointmentVehicleUpdated.displayName = 'proto.appointment.AppointmentVehicleUpdated';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointment.AvailabilityCreated = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointment.AvailabilityCreated, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointment.AvailabilityCreated.displayName = 'proto.appointment.AvailabilityCreated';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointment.AppointmentCheckoutStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointment.AppointmentCheckoutStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointment.AppointmentCheckoutStatus.displayName = 'proto.appointment.AppointmentCheckoutStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointment.AppointmentKeyHandOff = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointment.AppointmentKeyHandOff, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointment.AppointmentKeyHandOff.displayName = 'proto.appointment.AppointmentKeyHandOff';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.appointment.AppointmentPickupDate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.appointment.AppointmentPickupDate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.appointment.AppointmentPickupDate.displayName = 'proto.appointment.AppointmentPickupDate';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.appointment.Availability.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointment.Availability.prototype.toObject = function(opt_includeInstance) {
  return proto.appointment.Availability.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointment.Availability} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment.Availability.toObject = function(includeInstance, msg) {
  var f, obj = {
    slotsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    timezone: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointment.Availability}
 */
proto.appointment.Availability.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointment.Availability;
  return proto.appointment.Availability.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointment.Availability} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointment.Availability}
 */
proto.appointment.Availability.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addSlots(values[i]);
      }
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimezone(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointment.Availability.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointment.Availability.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointment.Availability} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment.Availability.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSlotsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      1,
      f
    );
  }
  f = message.getTimezone();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated int64 slots = 1;
 * @return {!Array<number>}
 */
proto.appointment.Availability.prototype.getSlotsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.appointment.Availability} returns this
 */
proto.appointment.Availability.prototype.setSlotsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.appointment.Availability} returns this
 */
proto.appointment.Availability.prototype.addSlots = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.appointment.Availability} returns this
 */
proto.appointment.Availability.prototype.clearSlotsList = function() {
  return this.setSlotsList([]);
};


/**
 * optional string timezone = 2;
 * @return {string}
 */
proto.appointment.Availability.prototype.getTimezone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointment.Availability} returns this
 */
proto.appointment.Availability.prototype.setTimezone = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointment.AvailableTime.prototype.toObject = function(opt_includeInstance) {
  return proto.appointment.AvailableTime.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointment.AvailableTime} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment.AvailableTime.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dayOfWeek: jspb.Message.getFieldWithDefault(msg, 2, 0),
    startTime: jspb.Message.getFieldWithDefault(msg, 3, ""),
    endTime: jspb.Message.getFieldWithDefault(msg, 4, ""),
    timezone: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointment.AvailableTime}
 */
proto.appointment.AvailableTime.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointment.AvailableTime;
  return proto.appointment.AvailableTime.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointment.AvailableTime} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointment.AvailableTime}
 */
proto.appointment.AvailableTime.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealerId(value);
      break;
    case 2:
      var value = /** @type {!proto.appointment.AvailableTime.DayOfWeek} */ (reader.readEnum());
      msg.setDayOfWeek(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartTime(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndTime(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimezone(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointment.AvailableTime.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointment.AvailableTime.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointment.AvailableTime} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment.AvailableTime.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDayOfWeek();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getStartTime();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEndTime();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTimezone();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.appointment.AvailableTime.DayOfWeek = {
  DAY_OF_WEEK_UNSPECIFIED: 0,
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
  SUNDAY: 7
};

/**
 * optional string dealer_id = 1;
 * @return {string}
 */
proto.appointment.AvailableTime.prototype.getDealerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointment.AvailableTime} returns this
 */
proto.appointment.AvailableTime.prototype.setDealerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional DayOfWeek day_of_week = 2;
 * @return {!proto.appointment.AvailableTime.DayOfWeek}
 */
proto.appointment.AvailableTime.prototype.getDayOfWeek = function() {
  return /** @type {!proto.appointment.AvailableTime.DayOfWeek} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.appointment.AvailableTime.DayOfWeek} value
 * @return {!proto.appointment.AvailableTime} returns this
 */
proto.appointment.AvailableTime.prototype.setDayOfWeek = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string start_time = 3;
 * @return {string}
 */
proto.appointment.AvailableTime.prototype.getStartTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointment.AvailableTime} returns this
 */
proto.appointment.AvailableTime.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string end_time = 4;
 * @return {string}
 */
proto.appointment.AvailableTime.prototype.getEndTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointment.AvailableTime} returns this
 */
proto.appointment.AvailableTime.prototype.setEndTime = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string timeZone = 5;
 * @return {string}
 */
proto.appointment.AvailableTime.prototype.getTimezone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointment.AvailableTime} returns this
 */
proto.appointment.AvailableTime.prototype.setTimezone = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.appointment.Appointment.repeatedFields_ = [9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointment.Appointment.prototype.toObject = function(opt_includeInstance) {
  return proto.appointment.Appointment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointment.Appointment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment.Appointment.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    startTime: jspb.Message.getFieldWithDefault(msg, 2, 0),
    appointmentType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    appointmentStatus: jspb.Message.getFieldWithDefault(msg, 4, 0),
    dealershipInfo: (f = msg.getDealershipInfo()) && proto.appointment.DealershipInfo.toObject(includeInstance, f),
    customerInfo: (f = msg.getCustomerInfo()) && proto.appointment.CustomerInfo.toObject(includeInstance, f),
    vehicleInfo: (f = msg.getVehicleInfo()) && proto.appointment.VehicleInfo.toObject(includeInstance, f),
    timeZone: jspb.Message.getFieldWithDefault(msg, 8, ""),
    notesList: jspb.Message.toObjectList(msg.getNotesList(),
    proto.appointment.Note.toObject, includeInstance),
    lastUpdate: jspb.Message.getFieldWithDefault(msg, 10, 0),
    version: jspb.Message.getFieldWithDefault(msg, 11, 0),
    duration: jspb.Message.getFieldWithDefault(msg, 12, 0),
    insuranceLinked: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    licenseScanned: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 15, ""),
    appointmentKeyHandOff: (f = msg.getAppointmentKeyHandOff()) && proto.appointment.AppointmentKeyHandOff.toObject(includeInstance, f),
    appointmentCheckoutStatus: (f = msg.getAppointmentCheckoutStatus()) && proto.appointment.AppointmentCheckoutStatus.toObject(includeInstance, f),
    reservationId: jspb.Message.getFieldWithDefault(msg, 18, ""),
    completedTime: jspb.Message.getFieldWithDefault(msg, 19, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointment.Appointment}
 */
proto.appointment.Appointment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointment.Appointment;
  return proto.appointment.Appointment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointment.Appointment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointment.Appointment}
 */
proto.appointment.Appointment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartTime(value);
      break;
    case 3:
      var value = /** @type {!proto.appointment.AppointmentType} */ (reader.readEnum());
      msg.setAppointmentType(value);
      break;
    case 4:
      var value = /** @type {!proto.appointment.AppointmentStatus} */ (reader.readEnum());
      msg.setAppointmentStatus(value);
      break;
    case 5:
      var value = new proto.appointment.DealershipInfo;
      reader.readMessage(value,proto.appointment.DealershipInfo.deserializeBinaryFromReader);
      msg.setDealershipInfo(value);
      break;
    case 6:
      var value = new proto.appointment.CustomerInfo;
      reader.readMessage(value,proto.appointment.CustomerInfo.deserializeBinaryFromReader);
      msg.setCustomerInfo(value);
      break;
    case 7:
      var value = new proto.appointment.VehicleInfo;
      reader.readMessage(value,proto.appointment.VehicleInfo.deserializeBinaryFromReader);
      msg.setVehicleInfo(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimeZone(value);
      break;
    case 9:
      var value = new proto.appointment.Note;
      reader.readMessage(value,proto.appointment.Note.deserializeBinaryFromReader);
      msg.addNotes(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastUpdate(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setVersion(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDuration(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInsuranceLinked(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLicenseScanned(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 16:
      var value = new proto.appointment.AppointmentKeyHandOff;
      reader.readMessage(value,proto.appointment.AppointmentKeyHandOff.deserializeBinaryFromReader);
      msg.setAppointmentKeyHandOff(value);
      break;
    case 17:
      var value = new proto.appointment.AppointmentCheckoutStatus;
      reader.readMessage(value,proto.appointment.AppointmentCheckoutStatus.deserializeBinaryFromReader);
      msg.setAppointmentCheckoutStatus(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setReservationId(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCompletedTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointment.Appointment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointment.Appointment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointment.Appointment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment.Appointment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStartTime();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getAppointmentType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getAppointmentStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getDealershipInfo();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.appointment.DealershipInfo.serializeBinaryToWriter
    );
  }
  f = message.getCustomerInfo();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.appointment.CustomerInfo.serializeBinaryToWriter
    );
  }
  f = message.getVehicleInfo();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.appointment.VehicleInfo.serializeBinaryToWriter
    );
  }
  f = message.getTimeZone();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getNotesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.appointment.Note.serializeBinaryToWriter
    );
  }
  f = message.getLastUpdate();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getVersion();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getDuration();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getInsuranceLinked();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getLicenseScanned();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getAppointmentKeyHandOff();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.appointment.AppointmentKeyHandOff.serializeBinaryToWriter
    );
  }
  f = message.getAppointmentCheckoutStatus();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.appointment.AppointmentCheckoutStatus.serializeBinaryToWriter
    );
  }
  f = message.getReservationId();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getCompletedTime();
  if (f !== 0) {
    writer.writeInt64(
      19,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.appointment.Appointment.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointment.Appointment} returns this
 */
proto.appointment.Appointment.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 start_time = 2;
 * @return {number}
 */
proto.appointment.Appointment.prototype.getStartTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.appointment.Appointment} returns this
 */
proto.appointment.Appointment.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional AppointmentType appointment_type = 3;
 * @return {!proto.appointment.AppointmentType}
 */
proto.appointment.Appointment.prototype.getAppointmentType = function() {
  return /** @type {!proto.appointment.AppointmentType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.appointment.AppointmentType} value
 * @return {!proto.appointment.Appointment} returns this
 */
proto.appointment.Appointment.prototype.setAppointmentType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional AppointmentStatus appointment_status = 4;
 * @return {!proto.appointment.AppointmentStatus}
 */
proto.appointment.Appointment.prototype.getAppointmentStatus = function() {
  return /** @type {!proto.appointment.AppointmentStatus} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.appointment.AppointmentStatus} value
 * @return {!proto.appointment.Appointment} returns this
 */
proto.appointment.Appointment.prototype.setAppointmentStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional DealershipInfo dealership_info = 5;
 * @return {?proto.appointment.DealershipInfo}
 */
proto.appointment.Appointment.prototype.getDealershipInfo = function() {
  return /** @type{?proto.appointment.DealershipInfo} */ (
    jspb.Message.getWrapperField(this, proto.appointment.DealershipInfo, 5));
};


/**
 * @param {?proto.appointment.DealershipInfo|undefined} value
 * @return {!proto.appointment.Appointment} returns this
*/
proto.appointment.Appointment.prototype.setDealershipInfo = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.appointment.Appointment} returns this
 */
proto.appointment.Appointment.prototype.clearDealershipInfo = function() {
  return this.setDealershipInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.appointment.Appointment.prototype.hasDealershipInfo = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional CustomerInfo customer_info = 6;
 * @return {?proto.appointment.CustomerInfo}
 */
proto.appointment.Appointment.prototype.getCustomerInfo = function() {
  return /** @type{?proto.appointment.CustomerInfo} */ (
    jspb.Message.getWrapperField(this, proto.appointment.CustomerInfo, 6));
};


/**
 * @param {?proto.appointment.CustomerInfo|undefined} value
 * @return {!proto.appointment.Appointment} returns this
*/
proto.appointment.Appointment.prototype.setCustomerInfo = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.appointment.Appointment} returns this
 */
proto.appointment.Appointment.prototype.clearCustomerInfo = function() {
  return this.setCustomerInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.appointment.Appointment.prototype.hasCustomerInfo = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional VehicleInfo vehicle_info = 7;
 * @return {?proto.appointment.VehicleInfo}
 */
proto.appointment.Appointment.prototype.getVehicleInfo = function() {
  return /** @type{?proto.appointment.VehicleInfo} */ (
    jspb.Message.getWrapperField(this, proto.appointment.VehicleInfo, 7));
};


/**
 * @param {?proto.appointment.VehicleInfo|undefined} value
 * @return {!proto.appointment.Appointment} returns this
*/
proto.appointment.Appointment.prototype.setVehicleInfo = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.appointment.Appointment} returns this
 */
proto.appointment.Appointment.prototype.clearVehicleInfo = function() {
  return this.setVehicleInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.appointment.Appointment.prototype.hasVehicleInfo = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string time_zone = 8;
 * @return {string}
 */
proto.appointment.Appointment.prototype.getTimeZone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointment.Appointment} returns this
 */
proto.appointment.Appointment.prototype.setTimeZone = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * repeated Note notes = 9;
 * @return {!Array<!proto.appointment.Note>}
 */
proto.appointment.Appointment.prototype.getNotesList = function() {
  return /** @type{!Array<!proto.appointment.Note>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.appointment.Note, 9));
};


/**
 * @param {!Array<!proto.appointment.Note>} value
 * @return {!proto.appointment.Appointment} returns this
*/
proto.appointment.Appointment.prototype.setNotesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.appointment.Note=} opt_value
 * @param {number=} opt_index
 * @return {!proto.appointment.Note}
 */
proto.appointment.Appointment.prototype.addNotes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.appointment.Note, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.appointment.Appointment} returns this
 */
proto.appointment.Appointment.prototype.clearNotesList = function() {
  return this.setNotesList([]);
};


/**
 * optional int64 last_update = 10;
 * @return {number}
 */
proto.appointment.Appointment.prototype.getLastUpdate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.appointment.Appointment} returns this
 */
proto.appointment.Appointment.prototype.setLastUpdate = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int64 version = 11;
 * @return {number}
 */
proto.appointment.Appointment.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.appointment.Appointment} returns this
 */
proto.appointment.Appointment.prototype.setVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int32 duration = 12;
 * @return {number}
 */
proto.appointment.Appointment.prototype.getDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.appointment.Appointment} returns this
 */
proto.appointment.Appointment.prototype.setDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional bool insurance_linked = 13;
 * @return {boolean}
 */
proto.appointment.Appointment.prototype.getInsuranceLinked = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.appointment.Appointment} returns this
 */
proto.appointment.Appointment.prototype.setInsuranceLinked = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional bool license_scanned = 14;
 * @return {boolean}
 */
proto.appointment.Appointment.prototype.getLicenseScanned = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.appointment.Appointment} returns this
 */
proto.appointment.Appointment.prototype.setLicenseScanned = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional string subscription_id = 15;
 * @return {string}
 */
proto.appointment.Appointment.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointment.Appointment} returns this
 */
proto.appointment.Appointment.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional AppointmentKeyHandOff appointment_key_hand_off = 16;
 * @return {?proto.appointment.AppointmentKeyHandOff}
 */
proto.appointment.Appointment.prototype.getAppointmentKeyHandOff = function() {
  return /** @type{?proto.appointment.AppointmentKeyHandOff} */ (
    jspb.Message.getWrapperField(this, proto.appointment.AppointmentKeyHandOff, 16));
};


/**
 * @param {?proto.appointment.AppointmentKeyHandOff|undefined} value
 * @return {!proto.appointment.Appointment} returns this
*/
proto.appointment.Appointment.prototype.setAppointmentKeyHandOff = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.appointment.Appointment} returns this
 */
proto.appointment.Appointment.prototype.clearAppointmentKeyHandOff = function() {
  return this.setAppointmentKeyHandOff(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.appointment.Appointment.prototype.hasAppointmentKeyHandOff = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional AppointmentCheckoutStatus appointment_checkout_status = 17;
 * @return {?proto.appointment.AppointmentCheckoutStatus}
 */
proto.appointment.Appointment.prototype.getAppointmentCheckoutStatus = function() {
  return /** @type{?proto.appointment.AppointmentCheckoutStatus} */ (
    jspb.Message.getWrapperField(this, proto.appointment.AppointmentCheckoutStatus, 17));
};


/**
 * @param {?proto.appointment.AppointmentCheckoutStatus|undefined} value
 * @return {!proto.appointment.Appointment} returns this
*/
proto.appointment.Appointment.prototype.setAppointmentCheckoutStatus = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.appointment.Appointment} returns this
 */
proto.appointment.Appointment.prototype.clearAppointmentCheckoutStatus = function() {
  return this.setAppointmentCheckoutStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.appointment.Appointment.prototype.hasAppointmentCheckoutStatus = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional string reservation_id = 18;
 * @return {string}
 */
proto.appointment.Appointment.prototype.getReservationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointment.Appointment} returns this
 */
proto.appointment.Appointment.prototype.setReservationId = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional int64 completed_time = 19;
 * @return {number}
 */
proto.appointment.Appointment.prototype.getCompletedTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {number} value
 * @return {!proto.appointment.Appointment} returns this
 */
proto.appointment.Appointment.prototype.setCompletedTime = function(value) {
  return jspb.Message.setProto3IntField(this, 19, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointment.DealershipInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.appointment.DealershipInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointment.DealershipInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment.DealershipInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    assignee: jspb.Message.getFieldWithDefault(msg, 3, ""),
    assigneeId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    address: (f = msg.getAddress()) && domain_pb.Address.toObject(includeInstance, f),
    timeZone: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointment.DealershipInfo}
 */
proto.appointment.DealershipInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointment.DealershipInfo;
  return proto.appointment.DealershipInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointment.DealershipInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointment.DealershipInfo}
 */
proto.appointment.DealershipInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssignee(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssigneeId(value);
      break;
    case 5:
      var value = new domain_pb.Address;
      reader.readMessage(value,domain_pb.Address.deserializeBinaryFromReader);
      msg.setAddress(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimeZone(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointment.DealershipInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointment.DealershipInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointment.DealershipInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment.DealershipInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAssignee();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAssigneeId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAddress();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      domain_pb.Address.serializeBinaryToWriter
    );
  }
  f = message.getTimeZone();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.appointment.DealershipInfo.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointment.DealershipInfo} returns this
 */
proto.appointment.DealershipInfo.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.appointment.DealershipInfo.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointment.DealershipInfo} returns this
 */
proto.appointment.DealershipInfo.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string assignee = 3;
 * @return {string}
 */
proto.appointment.DealershipInfo.prototype.getAssignee = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointment.DealershipInfo} returns this
 */
proto.appointment.DealershipInfo.prototype.setAssignee = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string assignee_id = 4;
 * @return {string}
 */
proto.appointment.DealershipInfo.prototype.getAssigneeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointment.DealershipInfo} returns this
 */
proto.appointment.DealershipInfo.prototype.setAssigneeId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional domain.Address address = 5;
 * @return {?proto.domain.Address}
 */
proto.appointment.DealershipInfo.prototype.getAddress = function() {
  return /** @type{?proto.domain.Address} */ (
    jspb.Message.getWrapperField(this, domain_pb.Address, 5));
};


/**
 * @param {?proto.domain.Address|undefined} value
 * @return {!proto.appointment.DealershipInfo} returns this
*/
proto.appointment.DealershipInfo.prototype.setAddress = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.appointment.DealershipInfo} returns this
 */
proto.appointment.DealershipInfo.prototype.clearAddress = function() {
  return this.setAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.appointment.DealershipInfo.prototype.hasAddress = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string time_zone = 6;
 * @return {string}
 */
proto.appointment.DealershipInfo.prototype.getTimeZone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointment.DealershipInfo} returns this
 */
proto.appointment.DealershipInfo.prototype.setTimeZone = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointment.CustomerInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.appointment.CustomerInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointment.CustomerInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment.CustomerInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fullName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    accountNum: jspb.Message.getFieldWithDefault(msg, 3, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointment.CustomerInfo}
 */
proto.appointment.CustomerInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointment.CustomerInfo;
  return proto.appointment.CustomerInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointment.CustomerInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointment.CustomerInfo}
 */
proto.appointment.CustomerInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFullName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNum(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointment.CustomerInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointment.CustomerInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointment.CustomerInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment.CustomerInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFullName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAccountNum();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.appointment.CustomerInfo.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointment.CustomerInfo} returns this
 */
proto.appointment.CustomerInfo.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string full_name = 2;
 * @return {string}
 */
proto.appointment.CustomerInfo.prototype.getFullName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointment.CustomerInfo} returns this
 */
proto.appointment.CustomerInfo.prototype.setFullName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string account_num = 3;
 * @return {string}
 */
proto.appointment.CustomerInfo.prototype.getAccountNum = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointment.CustomerInfo} returns this
 */
proto.appointment.CustomerInfo.prototype.setAccountNum = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string phone = 4;
 * @return {string}
 */
proto.appointment.CustomerInfo.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointment.CustomerInfo} returns this
 */
proto.appointment.CustomerInfo.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointment.VehicleInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.appointment.VehicleInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointment.VehicleInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment.VehicleInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    vin: jspb.Message.getFieldWithDefault(msg, 2, ""),
    make: jspb.Message.getFieldWithDefault(msg, 3, ""),
    model: jspb.Message.getFieldWithDefault(msg, 4, ""),
    year: jspb.Message.getFieldWithDefault(msg, 5, ""),
    color: jspb.Message.getFieldWithDefault(msg, 6, ""),
    drivetrain: jspb.Message.getFieldWithDefault(msg, 7, ""),
    odometer: jspb.Message.getFieldWithDefault(msg, 8, 0),
    wheelSize: jspb.Message.getFieldWithDefault(msg, 9, ""),
    interiorColor: jspb.Message.getFieldWithDefault(msg, 10, ""),
    trim: jspb.Message.getFieldWithDefault(msg, 11, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointment.VehicleInfo}
 */
proto.appointment.VehicleInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointment.VehicleInfo;
  return proto.appointment.VehicleInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointment.VehicleInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointment.VehicleInfo}
 */
proto.appointment.VehicleInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVin(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMake(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setModel(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setYear(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setColor(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDrivetrain(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOdometer(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setWheelSize(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setInteriorColor(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrim(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointment.VehicleInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointment.VehicleInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointment.VehicleInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment.VehicleInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVin();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMake();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getModel();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getYear();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getColor();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDrivetrain();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getOdometer();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getWheelSize();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getInteriorColor();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getTrim();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.appointment.VehicleInfo.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointment.VehicleInfo} returns this
 */
proto.appointment.VehicleInfo.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string vin = 2;
 * @return {string}
 */
proto.appointment.VehicleInfo.prototype.getVin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointment.VehicleInfo} returns this
 */
proto.appointment.VehicleInfo.prototype.setVin = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string make = 3;
 * @return {string}
 */
proto.appointment.VehicleInfo.prototype.getMake = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointment.VehicleInfo} returns this
 */
proto.appointment.VehicleInfo.prototype.setMake = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string model = 4;
 * @return {string}
 */
proto.appointment.VehicleInfo.prototype.getModel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointment.VehicleInfo} returns this
 */
proto.appointment.VehicleInfo.prototype.setModel = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string year = 5;
 * @return {string}
 */
proto.appointment.VehicleInfo.prototype.getYear = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointment.VehicleInfo} returns this
 */
proto.appointment.VehicleInfo.prototype.setYear = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string color = 6;
 * @return {string}
 */
proto.appointment.VehicleInfo.prototype.getColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointment.VehicleInfo} returns this
 */
proto.appointment.VehicleInfo.prototype.setColor = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string drivetrain = 7;
 * @return {string}
 */
proto.appointment.VehicleInfo.prototype.getDrivetrain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointment.VehicleInfo} returns this
 */
proto.appointment.VehicleInfo.prototype.setDrivetrain = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int32 odometer = 8;
 * @return {number}
 */
proto.appointment.VehicleInfo.prototype.getOdometer = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.appointment.VehicleInfo} returns this
 */
proto.appointment.VehicleInfo.prototype.setOdometer = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string wheel_size = 9;
 * @return {string}
 */
proto.appointment.VehicleInfo.prototype.getWheelSize = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointment.VehicleInfo} returns this
 */
proto.appointment.VehicleInfo.prototype.setWheelSize = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string interior_color = 10;
 * @return {string}
 */
proto.appointment.VehicleInfo.prototype.getInteriorColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointment.VehicleInfo} returns this
 */
proto.appointment.VehicleInfo.prototype.setInteriorColor = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string trim = 11;
 * @return {string}
 */
proto.appointment.VehicleInfo.prototype.getTrim = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointment.VehicleInfo} returns this
 */
proto.appointment.VehicleInfo.prototype.setTrim = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointment.Note.prototype.toObject = function(opt_includeInstance) {
  return proto.appointment.Note.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointment.Note} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment.Note.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    created: jspb.Message.getFieldWithDefault(msg, 2, 0),
    author: jspb.Message.getFieldWithDefault(msg, 3, ""),
    content: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointment.Note}
 */
proto.appointment.Note.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointment.Note;
  return proto.appointment.Note.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointment.Note} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointment.Note}
 */
proto.appointment.Note.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreated(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthor(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setContent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointment.Note.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointment.Note.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointment.Note} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment.Note.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreated();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getAuthor();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getContent();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.appointment.Note.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointment.Note} returns this
 */
proto.appointment.Note.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 created = 2;
 * @return {number}
 */
proto.appointment.Note.prototype.getCreated = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.appointment.Note} returns this
 */
proto.appointment.Note.prototype.setCreated = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string author = 3;
 * @return {string}
 */
proto.appointment.Note.prototype.getAuthor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointment.Note} returns this
 */
proto.appointment.Note.prototype.setAuthor = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string content = 4;
 * @return {string}
 */
proto.appointment.Note.prototype.getContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointment.Note} returns this
 */
proto.appointment.Note.prototype.setContent = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointment.AppointmentTypeConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.appointment.AppointmentTypeConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointment.AppointmentTypeConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment.AppointmentTypeConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    durationMinutes: jspb.Message.getFieldWithDefault(msg, 3, 0),
    countPerSlot: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointment.AppointmentTypeConfig}
 */
proto.appointment.AppointmentTypeConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointment.AppointmentTypeConfig;
  return proto.appointment.AppointmentTypeConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointment.AppointmentTypeConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointment.AppointmentTypeConfig}
 */
proto.appointment.AppointmentTypeConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.appointment.AppointmentType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDurationMinutes(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCountPerSlot(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointment.AppointmentTypeConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointment.AppointmentTypeConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointment.AppointmentTypeConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment.AppointmentTypeConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getDurationMinutes();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getCountPerSlot();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.appointment.AppointmentTypeConfig.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointment.AppointmentTypeConfig} returns this
 */
proto.appointment.AppointmentTypeConfig.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional AppointmentType type = 2;
 * @return {!proto.appointment.AppointmentType}
 */
proto.appointment.AppointmentTypeConfig.prototype.getType = function() {
  return /** @type {!proto.appointment.AppointmentType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.appointment.AppointmentType} value
 * @return {!proto.appointment.AppointmentTypeConfig} returns this
 */
proto.appointment.AppointmentTypeConfig.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional int32 duration_minutes = 3;
 * @return {number}
 */
proto.appointment.AppointmentTypeConfig.prototype.getDurationMinutes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.appointment.AppointmentTypeConfig} returns this
 */
proto.appointment.AppointmentTypeConfig.prototype.setDurationMinutes = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 count_per_slot = 4;
 * @return {number}
 */
proto.appointment.AppointmentTypeConfig.prototype.getCountPerSlot = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.appointment.AppointmentTypeConfig} returns this
 */
proto.appointment.AppointmentTypeConfig.prototype.setCountPerSlot = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointment.AppointmentCreated.prototype.toObject = function(opt_includeInstance) {
  return proto.appointment.AppointmentCreated.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointment.AppointmentCreated} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment.AppointmentCreated.toObject = function(includeInstance, msg) {
  var f, obj = {
    appointment: (f = msg.getAppointment()) && proto.appointment.Appointment.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointment.AppointmentCreated}
 */
proto.appointment.AppointmentCreated.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointment.AppointmentCreated;
  return proto.appointment.AppointmentCreated.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointment.AppointmentCreated} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointment.AppointmentCreated}
 */
proto.appointment.AppointmentCreated.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.appointment.Appointment;
      reader.readMessage(value,proto.appointment.Appointment.deserializeBinaryFromReader);
      msg.setAppointment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointment.AppointmentCreated.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointment.AppointmentCreated.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointment.AppointmentCreated} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment.AppointmentCreated.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppointment();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.appointment.Appointment.serializeBinaryToWriter
    );
  }
};


/**
 * optional Appointment appointment = 1;
 * @return {?proto.appointment.Appointment}
 */
proto.appointment.AppointmentCreated.prototype.getAppointment = function() {
  return /** @type{?proto.appointment.Appointment} */ (
    jspb.Message.getWrapperField(this, proto.appointment.Appointment, 1));
};


/**
 * @param {?proto.appointment.Appointment|undefined} value
 * @return {!proto.appointment.AppointmentCreated} returns this
*/
proto.appointment.AppointmentCreated.prototype.setAppointment = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.appointment.AppointmentCreated} returns this
 */
proto.appointment.AppointmentCreated.prototype.clearAppointment = function() {
  return this.setAppointment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.appointment.AppointmentCreated.prototype.hasAppointment = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointment.AppointmentCancelled.prototype.toObject = function(opt_includeInstance) {
  return proto.appointment.AppointmentCancelled.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointment.AppointmentCancelled} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment.AppointmentCancelled.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointment.AppointmentCancelled}
 */
proto.appointment.AppointmentCancelled.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointment.AppointmentCancelled;
  return proto.appointment.AppointmentCancelled.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointment.AppointmentCancelled} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointment.AppointmentCancelled}
 */
proto.appointment.AppointmentCancelled.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointment.AppointmentCancelled.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointment.AppointmentCancelled.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointment.AppointmentCancelled} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment.AppointmentCancelled.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.appointment.AppointmentCancelled.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointment.AppointmentCancelled} returns this
 */
proto.appointment.AppointmentCancelled.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointment.AppointmentTypeUpdated.prototype.toObject = function(opt_includeInstance) {
  return proto.appointment.AppointmentTypeUpdated.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointment.AppointmentTypeUpdated} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment.AppointmentTypeUpdated.toObject = function(includeInstance, msg) {
  var f, obj = {
    appointmentId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    date: jspb.Message.getFieldWithDefault(msg, 2, 0),
    type: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointment.AppointmentTypeUpdated}
 */
proto.appointment.AppointmentTypeUpdated.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointment.AppointmentTypeUpdated;
  return proto.appointment.AppointmentTypeUpdated.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointment.AppointmentTypeUpdated} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointment.AppointmentTypeUpdated}
 */
proto.appointment.AppointmentTypeUpdated.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppointmentId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDate(value);
      break;
    case 3:
      var value = /** @type {!proto.appointment.AppointmentType} */ (reader.readEnum());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointment.AppointmentTypeUpdated.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointment.AppointmentTypeUpdated.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointment.AppointmentTypeUpdated} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment.AppointmentTypeUpdated.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppointmentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDate();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional string appointment_id = 1;
 * @return {string}
 */
proto.appointment.AppointmentTypeUpdated.prototype.getAppointmentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointment.AppointmentTypeUpdated} returns this
 */
proto.appointment.AppointmentTypeUpdated.prototype.setAppointmentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 date = 2;
 * @return {number}
 */
proto.appointment.AppointmentTypeUpdated.prototype.getDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.appointment.AppointmentTypeUpdated} returns this
 */
proto.appointment.AppointmentTypeUpdated.prototype.setDate = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional AppointmentType type = 3;
 * @return {!proto.appointment.AppointmentType}
 */
proto.appointment.AppointmentTypeUpdated.prototype.getType = function() {
  return /** @type {!proto.appointment.AppointmentType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.appointment.AppointmentType} value
 * @return {!proto.appointment.AppointmentTypeUpdated} returns this
 */
proto.appointment.AppointmentTypeUpdated.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointment.AppointmentStatusUpdated.prototype.toObject = function(opt_includeInstance) {
  return proto.appointment.AppointmentStatusUpdated.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointment.AppointmentStatusUpdated} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment.AppointmentStatusUpdated.toObject = function(includeInstance, msg) {
  var f, obj = {
    appointmentId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    date: jspb.Message.getFieldWithDefault(msg, 2, 0),
    status: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointment.AppointmentStatusUpdated}
 */
proto.appointment.AppointmentStatusUpdated.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointment.AppointmentStatusUpdated;
  return proto.appointment.AppointmentStatusUpdated.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointment.AppointmentStatusUpdated} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointment.AppointmentStatusUpdated}
 */
proto.appointment.AppointmentStatusUpdated.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppointmentId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDate(value);
      break;
    case 3:
      var value = /** @type {!proto.appointment.AppointmentStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointment.AppointmentStatusUpdated.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointment.AppointmentStatusUpdated.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointment.AppointmentStatusUpdated} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment.AppointmentStatusUpdated.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppointmentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDate();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional string appointment_id = 1;
 * @return {string}
 */
proto.appointment.AppointmentStatusUpdated.prototype.getAppointmentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointment.AppointmentStatusUpdated} returns this
 */
proto.appointment.AppointmentStatusUpdated.prototype.setAppointmentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 date = 2;
 * @return {number}
 */
proto.appointment.AppointmentStatusUpdated.prototype.getDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.appointment.AppointmentStatusUpdated} returns this
 */
proto.appointment.AppointmentStatusUpdated.prototype.setDate = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional AppointmentStatus status = 3;
 * @return {!proto.appointment.AppointmentStatus}
 */
proto.appointment.AppointmentStatusUpdated.prototype.getStatus = function() {
  return /** @type {!proto.appointment.AppointmentStatus} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.appointment.AppointmentStatus} value
 * @return {!proto.appointment.AppointmentStatusUpdated} returns this
 */
proto.appointment.AppointmentStatusUpdated.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointment.AppointmentUpdated.prototype.toObject = function(opt_includeInstance) {
  return proto.appointment.AppointmentUpdated.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointment.AppointmentUpdated} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment.AppointmentUpdated.toObject = function(includeInstance, msg) {
  var f, obj = {
    appointmentId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    date: jspb.Message.getFieldWithDefault(msg, 2, 0),
    appointment: (f = msg.getAppointment()) && proto.appointment.Appointment.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointment.AppointmentUpdated}
 */
proto.appointment.AppointmentUpdated.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointment.AppointmentUpdated;
  return proto.appointment.AppointmentUpdated.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointment.AppointmentUpdated} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointment.AppointmentUpdated}
 */
proto.appointment.AppointmentUpdated.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppointmentId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDate(value);
      break;
    case 3:
      var value = new proto.appointment.Appointment;
      reader.readMessage(value,proto.appointment.Appointment.deserializeBinaryFromReader);
      msg.setAppointment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointment.AppointmentUpdated.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointment.AppointmentUpdated.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointment.AppointmentUpdated} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment.AppointmentUpdated.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppointmentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDate();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getAppointment();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.appointment.Appointment.serializeBinaryToWriter
    );
  }
};


/**
 * optional string appointment_id = 1;
 * @return {string}
 */
proto.appointment.AppointmentUpdated.prototype.getAppointmentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointment.AppointmentUpdated} returns this
 */
proto.appointment.AppointmentUpdated.prototype.setAppointmentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 date = 2;
 * @return {number}
 */
proto.appointment.AppointmentUpdated.prototype.getDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.appointment.AppointmentUpdated} returns this
 */
proto.appointment.AppointmentUpdated.prototype.setDate = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional Appointment appointment = 3;
 * @return {?proto.appointment.Appointment}
 */
proto.appointment.AppointmentUpdated.prototype.getAppointment = function() {
  return /** @type{?proto.appointment.Appointment} */ (
    jspb.Message.getWrapperField(this, proto.appointment.Appointment, 3));
};


/**
 * @param {?proto.appointment.Appointment|undefined} value
 * @return {!proto.appointment.AppointmentUpdated} returns this
*/
proto.appointment.AppointmentUpdated.prototype.setAppointment = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.appointment.AppointmentUpdated} returns this
 */
proto.appointment.AppointmentUpdated.prototype.clearAppointment = function() {
  return this.setAppointment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.appointment.AppointmentUpdated.prototype.hasAppointment = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointment.AppointmentVehicleUpdated.prototype.toObject = function(opt_includeInstance) {
  return proto.appointment.AppointmentVehicleUpdated.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointment.AppointmentVehicleUpdated} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment.AppointmentVehicleUpdated.toObject = function(includeInstance, msg) {
  var f, obj = {
    appointmentId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    customerId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    vehicleInfo: (f = msg.getVehicleInfo()) && proto.appointment.VehicleInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointment.AppointmentVehicleUpdated}
 */
proto.appointment.AppointmentVehicleUpdated.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointment.AppointmentVehicleUpdated;
  return proto.appointment.AppointmentVehicleUpdated.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointment.AppointmentVehicleUpdated} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointment.AppointmentVehicleUpdated}
 */
proto.appointment.AppointmentVehicleUpdated.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppointmentId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 3:
      var value = new proto.appointment.VehicleInfo;
      reader.readMessage(value,proto.appointment.VehicleInfo.deserializeBinaryFromReader);
      msg.setVehicleInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointment.AppointmentVehicleUpdated.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointment.AppointmentVehicleUpdated.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointment.AppointmentVehicleUpdated} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment.AppointmentVehicleUpdated.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppointmentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getVehicleInfo();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.appointment.VehicleInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional string appointment_id = 1;
 * @return {string}
 */
proto.appointment.AppointmentVehicleUpdated.prototype.getAppointmentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointment.AppointmentVehicleUpdated} returns this
 */
proto.appointment.AppointmentVehicleUpdated.prototype.setAppointmentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string customer_id = 2;
 * @return {string}
 */
proto.appointment.AppointmentVehicleUpdated.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointment.AppointmentVehicleUpdated} returns this
 */
proto.appointment.AppointmentVehicleUpdated.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional VehicleInfo vehicle_info = 3;
 * @return {?proto.appointment.VehicleInfo}
 */
proto.appointment.AppointmentVehicleUpdated.prototype.getVehicleInfo = function() {
  return /** @type{?proto.appointment.VehicleInfo} */ (
    jspb.Message.getWrapperField(this, proto.appointment.VehicleInfo, 3));
};


/**
 * @param {?proto.appointment.VehicleInfo|undefined} value
 * @return {!proto.appointment.AppointmentVehicleUpdated} returns this
*/
proto.appointment.AppointmentVehicleUpdated.prototype.setVehicleInfo = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.appointment.AppointmentVehicleUpdated} returns this
 */
proto.appointment.AppointmentVehicleUpdated.prototype.clearVehicleInfo = function() {
  return this.setVehicleInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.appointment.AppointmentVehicleUpdated.prototype.hasVehicleInfo = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointment.AvailabilityCreated.prototype.toObject = function(opt_includeInstance) {
  return proto.appointment.AvailabilityCreated.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointment.AvailabilityCreated} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment.AvailabilityCreated.toObject = function(includeInstance, msg) {
  var f, obj = {
    availability: (f = msg.getAvailability()) && proto.appointment.Availability.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointment.AvailabilityCreated}
 */
proto.appointment.AvailabilityCreated.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointment.AvailabilityCreated;
  return proto.appointment.AvailabilityCreated.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointment.AvailabilityCreated} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointment.AvailabilityCreated}
 */
proto.appointment.AvailabilityCreated.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.appointment.Availability;
      reader.readMessage(value,proto.appointment.Availability.deserializeBinaryFromReader);
      msg.setAvailability(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointment.AvailabilityCreated.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointment.AvailabilityCreated.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointment.AvailabilityCreated} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment.AvailabilityCreated.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAvailability();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.appointment.Availability.serializeBinaryToWriter
    );
  }
};


/**
 * optional Availability availability = 1;
 * @return {?proto.appointment.Availability}
 */
proto.appointment.AvailabilityCreated.prototype.getAvailability = function() {
  return /** @type{?proto.appointment.Availability} */ (
    jspb.Message.getWrapperField(this, proto.appointment.Availability, 1));
};


/**
 * @param {?proto.appointment.Availability|undefined} value
 * @return {!proto.appointment.AvailabilityCreated} returns this
*/
proto.appointment.AvailabilityCreated.prototype.setAvailability = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.appointment.AvailabilityCreated} returns this
 */
proto.appointment.AvailabilityCreated.prototype.clearAvailability = function() {
  return this.setAvailability(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.appointment.AvailabilityCreated.prototype.hasAvailability = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointment.AppointmentCheckoutStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.appointment.AppointmentCheckoutStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointment.AppointmentCheckoutStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment.AppointmentCheckoutStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    inspectionComplete: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    contractSignedSubmitted: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    subscriptionActivated: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    insuranceLinked: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    licenseScanned: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    paymentComplete: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    keyHandOff: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    appointmentId: jspb.Message.getFieldWithDefault(msg, 9, ""),
    reservationId: jspb.Message.getFieldWithDefault(msg, 10, ""),
    customerId: jspb.Message.getFieldWithDefault(msg, 11, ""),
    lastUpdate: jspb.Message.getFieldWithDefault(msg, 12, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointment.AppointmentCheckoutStatus}
 */
proto.appointment.AppointmentCheckoutStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointment.AppointmentCheckoutStatus;
  return proto.appointment.AppointmentCheckoutStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointment.AppointmentCheckoutStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointment.AppointmentCheckoutStatus}
 */
proto.appointment.AppointmentCheckoutStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInspectionComplete(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setContractSignedSubmitted(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSubscriptionActivated(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInsuranceLinked(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLicenseScanned(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPaymentComplete(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setKeyHandOff(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppointmentId(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setReservationId(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastUpdate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointment.AppointmentCheckoutStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointment.AppointmentCheckoutStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointment.AppointmentCheckoutStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment.AppointmentCheckoutStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInspectionComplete();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getContractSignedSubmitted();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getSubscriptionActivated();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getInsuranceLinked();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getLicenseScanned();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getPaymentComplete();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getKeyHandOff();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getAppointmentId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getReservationId();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getLastUpdate();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
};


/**
 * optional string subscription_id = 1;
 * @return {string}
 */
proto.appointment.AppointmentCheckoutStatus.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointment.AppointmentCheckoutStatus} returns this
 */
proto.appointment.AppointmentCheckoutStatus.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool inspection_complete = 2;
 * @return {boolean}
 */
proto.appointment.AppointmentCheckoutStatus.prototype.getInspectionComplete = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.appointment.AppointmentCheckoutStatus} returns this
 */
proto.appointment.AppointmentCheckoutStatus.prototype.setInspectionComplete = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool contract_signed_submitted = 3;
 * @return {boolean}
 */
proto.appointment.AppointmentCheckoutStatus.prototype.getContractSignedSubmitted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.appointment.AppointmentCheckoutStatus} returns this
 */
proto.appointment.AppointmentCheckoutStatus.prototype.setContractSignedSubmitted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool subscription_activated = 4;
 * @return {boolean}
 */
proto.appointment.AppointmentCheckoutStatus.prototype.getSubscriptionActivated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.appointment.AppointmentCheckoutStatus} returns this
 */
proto.appointment.AppointmentCheckoutStatus.prototype.setSubscriptionActivated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool insurance_linked = 5;
 * @return {boolean}
 */
proto.appointment.AppointmentCheckoutStatus.prototype.getInsuranceLinked = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.appointment.AppointmentCheckoutStatus} returns this
 */
proto.appointment.AppointmentCheckoutStatus.prototype.setInsuranceLinked = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool license_scanned = 6;
 * @return {boolean}
 */
proto.appointment.AppointmentCheckoutStatus.prototype.getLicenseScanned = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.appointment.AppointmentCheckoutStatus} returns this
 */
proto.appointment.AppointmentCheckoutStatus.prototype.setLicenseScanned = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool payment_complete = 7;
 * @return {boolean}
 */
proto.appointment.AppointmentCheckoutStatus.prototype.getPaymentComplete = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.appointment.AppointmentCheckoutStatus} returns this
 */
proto.appointment.AppointmentCheckoutStatus.prototype.setPaymentComplete = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool key_hand_off = 8;
 * @return {boolean}
 */
proto.appointment.AppointmentCheckoutStatus.prototype.getKeyHandOff = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.appointment.AppointmentCheckoutStatus} returns this
 */
proto.appointment.AppointmentCheckoutStatus.prototype.setKeyHandOff = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional string appointment_id = 9;
 * @return {string}
 */
proto.appointment.AppointmentCheckoutStatus.prototype.getAppointmentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointment.AppointmentCheckoutStatus} returns this
 */
proto.appointment.AppointmentCheckoutStatus.prototype.setAppointmentId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string reservation_id = 10;
 * @return {string}
 */
proto.appointment.AppointmentCheckoutStatus.prototype.getReservationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointment.AppointmentCheckoutStatus} returns this
 */
proto.appointment.AppointmentCheckoutStatus.prototype.setReservationId = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string customer_id = 11;
 * @return {string}
 */
proto.appointment.AppointmentCheckoutStatus.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointment.AppointmentCheckoutStatus} returns this
 */
proto.appointment.AppointmentCheckoutStatus.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional int64 last_update = 12;
 * @return {number}
 */
proto.appointment.AppointmentCheckoutStatus.prototype.getLastUpdate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.appointment.AppointmentCheckoutStatus} returns this
 */
proto.appointment.AppointmentCheckoutStatus.prototype.setLastUpdate = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointment.AppointmentKeyHandOff.prototype.toObject = function(opt_includeInstance) {
  return proto.appointment.AppointmentKeyHandOff.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointment.AppointmentKeyHandOff} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment.AppointmentKeyHandOff.toObject = function(includeInstance, msg) {
  var f, obj = {
    appointmentId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    keyQuantity: jspb.Message.getFieldWithDefault(msg, 3, 0),
    vehicleRegistration: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    ownersManual: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    floorMats: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    chargerCable: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    chargerAdapter: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    optAdapter: jspb.Message.getBooleanFieldWithDefault(msg, 9, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointment.AppointmentKeyHandOff}
 */
proto.appointment.AppointmentKeyHandOff.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointment.AppointmentKeyHandOff;
  return proto.appointment.AppointmentKeyHandOff.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointment.AppointmentKeyHandOff} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointment.AppointmentKeyHandOff}
 */
proto.appointment.AppointmentKeyHandOff.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppointmentId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setKeyQuantity(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setVehicleRegistration(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOwnersManual(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFloorMats(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setChargerCable(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setChargerAdapter(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOptAdapter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointment.AppointmentKeyHandOff.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointment.AppointmentKeyHandOff.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointment.AppointmentKeyHandOff} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment.AppointmentKeyHandOff.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppointmentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getKeyQuantity();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getVehicleRegistration();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getOwnersManual();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getFloorMats();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getChargerCable();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getChargerAdapter();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getOptAdapter();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
};


/**
 * optional string appointment_id = 1;
 * @return {string}
 */
proto.appointment.AppointmentKeyHandOff.prototype.getAppointmentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointment.AppointmentKeyHandOff} returns this
 */
proto.appointment.AppointmentKeyHandOff.prototype.setAppointmentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_id = 2;
 * @return {string}
 */
proto.appointment.AppointmentKeyHandOff.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointment.AppointmentKeyHandOff} returns this
 */
proto.appointment.AppointmentKeyHandOff.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 key_quantity = 3;
 * @return {number}
 */
proto.appointment.AppointmentKeyHandOff.prototype.getKeyQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.appointment.AppointmentKeyHandOff} returns this
 */
proto.appointment.AppointmentKeyHandOff.prototype.setKeyQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bool vehicle_registration = 4;
 * @return {boolean}
 */
proto.appointment.AppointmentKeyHandOff.prototype.getVehicleRegistration = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.appointment.AppointmentKeyHandOff} returns this
 */
proto.appointment.AppointmentKeyHandOff.prototype.setVehicleRegistration = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool owners_manual = 5;
 * @return {boolean}
 */
proto.appointment.AppointmentKeyHandOff.prototype.getOwnersManual = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.appointment.AppointmentKeyHandOff} returns this
 */
proto.appointment.AppointmentKeyHandOff.prototype.setOwnersManual = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool floor_mats = 6;
 * @return {boolean}
 */
proto.appointment.AppointmentKeyHandOff.prototype.getFloorMats = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.appointment.AppointmentKeyHandOff} returns this
 */
proto.appointment.AppointmentKeyHandOff.prototype.setFloorMats = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool charger_cable = 7;
 * @return {boolean}
 */
proto.appointment.AppointmentKeyHandOff.prototype.getChargerCable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.appointment.AppointmentKeyHandOff} returns this
 */
proto.appointment.AppointmentKeyHandOff.prototype.setChargerCable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool charger_adapter = 8;
 * @return {boolean}
 */
proto.appointment.AppointmentKeyHandOff.prototype.getChargerAdapter = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.appointment.AppointmentKeyHandOff} returns this
 */
proto.appointment.AppointmentKeyHandOff.prototype.setChargerAdapter = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool opt_adapter = 9;
 * @return {boolean}
 */
proto.appointment.AppointmentKeyHandOff.prototype.getOptAdapter = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.appointment.AppointmentKeyHandOff} returns this
 */
proto.appointment.AppointmentKeyHandOff.prototype.setOptAdapter = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.appointment.AppointmentPickupDate.prototype.toObject = function(opt_includeInstance) {
  return proto.appointment.AppointmentPickupDate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.appointment.AppointmentPickupDate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment.AppointmentPickupDate.toObject = function(includeInstance, msg) {
  var f, obj = {
    startTime: jspb.Message.getFieldWithDefault(msg, 1, 0),
    dealershipInfo: (f = msg.getDealershipInfo()) && proto.appointment.DealershipInfo.toObject(includeInstance, f),
    timeZone: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.appointment.AppointmentPickupDate}
 */
proto.appointment.AppointmentPickupDate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.appointment.AppointmentPickupDate;
  return proto.appointment.AppointmentPickupDate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.appointment.AppointmentPickupDate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.appointment.AppointmentPickupDate}
 */
proto.appointment.AppointmentPickupDate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartTime(value);
      break;
    case 2:
      var value = new proto.appointment.DealershipInfo;
      reader.readMessage(value,proto.appointment.DealershipInfo.deserializeBinaryFromReader);
      msg.setDealershipInfo(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimeZone(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.appointment.AppointmentPickupDate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.appointment.AppointmentPickupDate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.appointment.AppointmentPickupDate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.appointment.AppointmentPickupDate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStartTime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getDealershipInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.appointment.DealershipInfo.serializeBinaryToWriter
    );
  }
  f = message.getTimeZone();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int64 start_time = 1;
 * @return {number}
 */
proto.appointment.AppointmentPickupDate.prototype.getStartTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.appointment.AppointmentPickupDate} returns this
 */
proto.appointment.AppointmentPickupDate.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional DealershipInfo dealership_info = 2;
 * @return {?proto.appointment.DealershipInfo}
 */
proto.appointment.AppointmentPickupDate.prototype.getDealershipInfo = function() {
  return /** @type{?proto.appointment.DealershipInfo} */ (
    jspb.Message.getWrapperField(this, proto.appointment.DealershipInfo, 2));
};


/**
 * @param {?proto.appointment.DealershipInfo|undefined} value
 * @return {!proto.appointment.AppointmentPickupDate} returns this
*/
proto.appointment.AppointmentPickupDate.prototype.setDealershipInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.appointment.AppointmentPickupDate} returns this
 */
proto.appointment.AppointmentPickupDate.prototype.clearDealershipInfo = function() {
  return this.setDealershipInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.appointment.AppointmentPickupDate.prototype.hasDealershipInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string time_zone = 3;
 * @return {string}
 */
proto.appointment.AppointmentPickupDate.prototype.getTimeZone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.appointment.AppointmentPickupDate} returns this
 */
proto.appointment.AppointmentPickupDate.prototype.setTimeZone = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * @enum {number}
 */
proto.appointment.AppointmentType = {
  PICKUP: 0,
  TRANSPORT: 1,
  DIRECT: 2,
  RETURN: 3,
  ANY: 4,
  PICKUP_SWAP: 5,
  TRANSPORT_SWAP: 6
};

/**
 * @enum {number}
 */
proto.appointment.AppointmentStatus = {
  SCHEDULED: 0,
  RESCHEDULED: 1,
  CANCELLED: 2,
  DONE: 3
};

goog.object.extend(exports, proto.appointment);
