import React from 'react'
import { Box, Avatar, Typography } from '@mui/material'
import { alpha } from '@mui/material/styles'
import WarningIcon from '@mui/icons-material/WarningOutlined'

type FormErrorProps = {
  text: string
}

export const FormError: React.FC<FormErrorProps> = ({ text }) => {
  return (
    <Box
      sx={{
        display: 'flex',

      }}
    >
      <Avatar
        sx={{
          backgroundColor: (theme) =>
            alpha(theme.palette.error.main, 0.08),
          color: 'error.main',
          mr: 2,
        }}
      >
        <WarningIcon fontSize="small" />
      </Avatar>
      <Typography
        color="textSecondary"
        sx={{ mt: 1 }}
        variant="body2"
      >
        {text}
      </Typography>
    </Box>
  )
}
