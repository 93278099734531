import {QualificationTier, QualificationTierMap} from '@nxcr-org/web-api-interface/lib/domain_pb'
import {ProcessOptions} from '../../useReservation'

export const getStartingDecision = (
  currentTier: QualificationTierMap[keyof QualificationTierMap]
) => {
  switch (currentTier) {
  case QualificationTier.TIER_1:
  case QualificationTier.TIER_2:
  case QualificationTier.TIER_3: {
    return ProcessOptions.approved
  }
  case QualificationTier.DISQUALIFIED: {
    return ProcessOptions.rejected
  }
  default: {
    return ProcessOptions.unchanged
  }
  }
}

