import { useState } from 'react'
import { getIsAdmin } from '../../../utils/getIsAdmin'
import { useOktaAuth } from '@okta/okta-react'
import {IDealership} from './utils'

export const useSettings = () => {
  const { authState } = useOktaAuth()
  const userGroups = authState?.idToken?.claims?.groups
  const isAdmin = getIsAdmin(userGroups)
  const [dealership, setDealership] = useState<IDealership>()


  return {
    isAdmin,
    dealership,
    setDealership,
  }
}
