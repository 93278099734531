import makeStyles from '@mui/styles/makeStyles'
import React from 'react'

const useStyles = makeStyles({
  logoSvg: {
    height: '100%',
    verticalAlign: 'top',
    width: 'auto',
  },
})

export default function AutonomyLogo(props) {
  const classes = useStyles()
  return (
    <svg className={classes.logoSvg} height="37" viewBox="0 0 34 37" fill="none" {...props}>
      <g clipPath="url(#a)">
        <path d="M7.57829 13.1879H0V36.19H7.57829V13.1879Z" fill="#FF3148"/>
        <path d="M22.7349 20.8552H7.57837V28.5226H22.7349V20.8552Z" fill="#00415C"/>
        <path d="M22.7349 5.52051H7.57837V13.1879H22.7349V5.52051Z" fill="#00C100"/>
        <path d="M30.3131 13.1879H22.7349V36.19H30.3131V13.1879Z" fill="#00DAFF"/>
        <path d="M41.4198 22.7874H45.8122L43.6054 16.4174L41.4198 22.7874ZM36.5303 28.5226L42.0685 13.1879H45.1847L50.7229 28.5226H47.7795L46.5669 24.9772H40.662L39.4282 28.5226H36.5303Z" fill="white"/>
        <path d="M54.991 28.7864C53.6512 28.7864 52.6166 28.3631 51.8869 27.5167C51.1576 26.6702 50.7938 25.4291 50.7957 23.7934V17.6595H53.542V23.5327C53.542 24.465 53.73 25.1807 54.1059 25.6796C54.4817 26.1784 55.0728 26.4269 55.8792 26.4248C56.236 26.4342 56.59 26.3647 56.9174 26.2211C57.2445 26.0776 57.537 25.8634 57.7737 25.5937C58.2709 25.0396 58.5194 24.2657 58.5194 23.272V17.6656H61.287V28.5226H58.8438L58.6255 26.6825C58.2991 27.3204 57.801 27.8519 57.1887 28.2159C56.5218 28.6065 55.7616 28.8039 54.991 28.7864Z" fill="white"/>
        <path d="M67.6286 28.5226C66.501 28.5226 65.6007 28.2466 64.9217 27.6915C64.2426 27.1363 63.9062 26.158 63.9062 24.7349V20.0087H62.0662V17.6656H63.9062L64.2305 14.7551H66.6889V17.6656H69.5868V20.0087H66.6889V24.7564C66.6889 25.2839 66.798 25.6427 67.0224 25.8421C67.3619 26.0763 67.7708 26.1847 68.1803 26.1488H69.5232V28.5226H67.6286Z" fill="white"/>
        <path d="M75.3494 26.3543C75.702 26.3562 76.0512 26.2845 76.3752 26.1436C76.699 26.0027 76.9909 25.7956 77.2319 25.5354C77.7654 24.9854 78.0322 24.1675 78.0322 23.0818C78.0322 21.9961 77.7654 21.1783 77.2319 20.6283C76.9909 20.3697 76.6999 20.1637 76.3774 20.0228C76.0545 19.882 75.7068 19.8094 75.3555 19.8094C75.0042 19.8094 74.6565 19.882 74.3337 20.0228C74.0111 20.1637 73.7204 20.3697 73.4791 20.6283C72.9517 21.1773 72.691 21.99 72.691 23.0818C72.691 24.1737 72.9517 24.9741 73.4791 25.5354C73.7177 25.7959 74.0069 26.0032 74.3291 26.1442C74.651 26.2852 74.9987 26.3567 75.3494 26.3543ZM75.3494 28.7864C74.3688 28.8005 73.4024 28.5508 72.5485 28.0626C71.7267 27.5903 71.0468 26.9013 70.5812 26.069C70.0822 25.1636 69.8312 24.1398 69.8537 23.1033C69.8322 22.0658 70.0865 21.0415 70.5903 20.1376C71.0626 19.304 71.7458 18.6127 72.5697 18.1348C73.4255 17.66 74.3858 17.411 75.3616 17.411C76.3377 17.411 77.2977 17.66 78.1534 18.1348C78.9758 18.6106 79.6557 19.3026 80.1207 20.1376C80.6167 21.0442 80.8664 22.0674 80.8452 23.1033C80.8667 24.1393 80.617 25.1625 80.1207 26.069C79.6536 26.9066 78.9691 27.5991 78.1413 28.0718C77.2901 28.5577 76.3267 28.8074 75.3494 28.7956" fill="white"/>
        <path d="M81.8848 28.5226V17.6656H84.328L84.5463 19.5058C84.8721 18.8675 85.3705 18.3358 85.9831 17.9723C86.6597 17.5784 87.4305 17.3811 88.2111 17.4019C89.5388 17.4019 90.5695 17.8251 91.303 18.6716C92.0366 19.5181 92.4013 20.7571 92.3973 22.3887V28.5226H89.6298V22.6556C89.6298 21.7232 89.4418 21.0076 89.0659 20.5087C88.6901 20.0098 88.102 19.7624 87.3017 19.7665C86.9422 19.7561 86.5851 19.825 86.2547 19.9686C85.9243 20.1121 85.629 20.3268 85.389 20.5976C84.8879 21.1538 84.6381 21.9277 84.6402 22.9193V28.5226H81.8848Z" fill="white"/>
        <path d="M98.8391 26.3543C99.1917 26.3562 99.5409 26.2845 99.8649 26.1436C100.189 26.0027 100.48 25.7957 100.722 25.5354C101.255 24.9854 101.522 24.1676 101.522 23.0819C101.522 21.9962 101.255 21.1783 100.722 20.6283C100.481 20.3697 100.19 20.1637 99.867 20.0228C99.5442 19.882 99.1965 19.8094 98.8452 19.8094C98.4938 19.8094 98.1462 19.882 97.8233 20.0228C97.5008 20.1637 97.2101 20.3697 96.9688 20.6283C96.4413 21.1783 96.1776 21.9962 96.1776 23.0819C96.1776 24.1676 96.4413 24.9854 96.9688 25.5354C97.2071 25.7961 97.4965 26.0035 97.8185 26.1445C98.1407 26.2855 98.4881 26.357 98.8391 26.3543ZM98.8391 28.7864C97.8585 28.8013 96.8918 28.5515 96.0382 28.0626C95.2149 27.587 94.534 26.895 94.0678 26.0599C93.5716 25.1534 93.3218 24.1301 93.3433 23.0941C93.3215 22.0566 93.5761 21.0323 94.0799 20.1284C94.5513 19.2941 95.2349 18.6026 96.0594 18.1257C96.9145 17.6508 97.8742 17.4018 98.8497 17.4018C99.8252 17.4018 100.785 17.6508 101.64 18.1257C102.463 18.6013 103.144 19.2933 103.61 20.1284C104.106 21.035 104.356 22.0582 104.335 23.0941C104.358 24.1332 104.108 25.1599 103.61 26.0691C103.142 26.906 102.458 27.5983 101.631 28.0718C100.78 28.5577 99.8164 28.8074 98.8391 28.7956" fill="white"/>
        <path d="M105.156 28.5226V17.6656H107.612L107.848 19.1316C108.196 18.6043 108.668 18.1723 109.221 17.8742C109.831 17.5506 110.512 17.3882 111.201 17.4019C112.858 17.4019 114.034 18.0521 114.729 19.3524C115.123 18.75 115.662 18.26 116.296 17.9294C116.96 17.5768 117.699 17.3956 118.449 17.4019C119.849 17.4019 120.924 17.8251 121.674 18.6716C122.424 19.5181 122.795 20.7571 122.789 22.3887V28.5226H120.019V22.6555C120.019 21.7232 119.843 21.0076 119.491 20.5087C119.302 20.2569 119.053 20.0568 118.768 19.9267C118.483 19.7966 118.17 19.7405 117.857 19.7634C117.512 19.7498 117.167 19.8179 116.851 19.9621C116.536 20.1064 116.258 20.3229 116.039 20.5946C115.584 21.1507 115.357 21.9246 115.357 22.9162V28.5226H112.586V22.6555C112.586 21.7232 112.406 21.0076 112.046 20.5087C111.851 20.2552 111.596 20.0545 111.305 19.9245C111.015 19.7945 110.696 19.7392 110.379 19.7634C110.038 19.7539 109.7 19.824 109.391 19.9681C109.081 20.1122 108.808 20.3266 108.594 20.5946C108.141 21.1527 107.915 21.9277 107.915 22.9193V28.5226H105.156Z" fill="white"/>
        <path d="M124.672 33.3378L127.158 27.8019H126.509L122.335 17.6656H125.342L128.349 25.3054L131.486 17.6656H134.43L127.612 33.3378H124.672Z" fill="white"/>
      </g>
    </svg>
  )
}
