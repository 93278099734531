import React from 'react'
import {
  Box,
  Button,
  Typography,
  Card,
  CardContent,
  Drawer,
} from '@mui/material'
import { useForm } from 'react-hook-form'

export const FormDrawerComponent = ({
  isDrawerOpen,
  setIsDrawerOpen,
  header,
  handleSave,
  form,
  defaultValues = {},
}) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    defaultValues,
  })

  return (
    <Drawer
      anchor="right"
      open={isDrawerOpen}
      onClose={() => {
        reset(defaultValues)
        setIsDrawerOpen(false)
      }}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
    >
      <Card sx={{ marginTop: '50px', width: '600px' }}>
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: 400,
            p: 4,
          }}
        >
          <form onSubmit={handleSubmit((data) => handleSave(data))}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <div>
                <Typography variant="h5">{header}</Typography>
              </div>
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                }}
              >
                <Box sx={{ flexGrow: 1 }} />
                <Button
                  onClick={() => {
                    reset(defaultValues)
                    setIsDrawerOpen(false)
                  }}
                >
                  Cancel
                </Button>
                <Button sx={{ ml: 1 }} type="submit" variant="contained">
                  Save
                </Button>
              </Box>
            </Box>
            {form({ errors, control })}
          </form>
        </CardContent>
      </Card>
    </Drawer>
  )
}
