import React from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import SearchIcon from '@mui/icons-material/Search'
import InputAdornment from '@mui/material/InputAdornment'

import useSearchBar from './useSearchBar'

const SearchBar = () => {
  const { results, open, setOpen, loading, query, setQuery, reset } =
    useSearchBar()

  return (
    <Autocomplete
      id="fleet-search"
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      loading={loading}
      options={results}
      blurOnSelect={true}
      clearOnBlur={true}
      inputValue={query}
      getOptionLabel={(option) => {
        return option.label
      }}
      isOptionEqualToValue={(option) => {
        return option.vehicle.vin
      }}
      onChange={(evt, option) => {
        option.navigateTo()
        reset()
      }}
      sx={
        {
          display: {
            xs: 'none',
            sm: 'flex'
          }
        }
      }
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            variant="outlined"
            value={query}
            placeholder="Search by VIN"
            onChange={(evt) => {
              setQuery(evt.target.value)
            }}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <>
                  <SearchIcon style={{ color: 'cyan' }} />
                </>
              ),
              endAdornment: (
                <InputAdornment position="end" sx={
                  {
                    display: {
                      xs: 'none',
                      md: 'flex'
                    }
                  }}>
                  Search
                </InputAdornment>
              ),
            }}
          />
        )
      }}
    />
  )
}

export default SearchBar
