import { useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useParams } from 'react-router'
import { useOktaAuth } from '@okta/okta-react'

import { getDisqualificationVendor, ProcessCustomerArgs, ProcessOptions } from '../../fleet-management/components/reservation/useReservation'
import { CQEService } from '../../global-APIs/nexus-service-cqe'
import { getIsAdmin } from '../../../utils/getIsAdmin'

export function useCQETab(){
  const queryClient = useQueryClient()
  const { authState } = useOktaAuth()
  const [currentNote, setCurrentNote] = useState('')
  const [decision, setDecision] = useState()
  const [overrideDisqualificationVendor, setOverrideDisqualificationVendor] =
    useState()
  const [feedbackMessage, setFeedbackMessage] = useState()

  const userGroups = authState?.idToken?.claims?.groups
  const isAdmin = getIsAdmin(userGroups)
  const showOverrideRejectionDropdown = decision === ProcessOptions.rejected

  const {
    customerId
  } = useParams<{ customerId: string }>()
  

  const { data: cqeScores } = useQuery(
    ['scores', customerId],
    () => {
      return CQEService.getCustomerCQEScores({
        customerId: customerId,
      })
        .then((scores) => {
          return {
            ...scores,
            disqualificationVendor: getDisqualificationVendor(
              scores?.scoresList
            ),
          }
        })
        .catch(() => {
          return {
            scoresList: [],
            disqualificationVendor: undefined,
          }
        })
    },
    {
      placeholderData: {
        scoresList: [],
        disqualificationVendor: undefined,
      },
      enabled: !!customerId,
    }
  )

  const { data: mostRecentNote } = useQuery(
    ['notes', customerId],
    () => {
      return CQEService.getCustomerNotes({
        customerId: customerId,
      })
        .catch((err) => {
          console.error('CQE error', err)
          return {
            notesList: [{ noteText: 'CQE SERVICE ERROR ON NOTE FETCH' }],
          }
        })
        .then((notesResponse) => {
          const { notesList } = notesResponse
          const lastestNote = notesList[0]?.noteText || ''
          setCurrentNote(lastestNote)

          return lastestNote
        })
    },
    {
      enabled: !!customerId,
    }
  )

  const {
    mutateAsync: overrideQualification,
    isSuccess: overrideQualificationSuccess,
  } = useMutation(
    (result: ProcessCustomerArgs) => {
      if (result.decision === ProcessOptions.approved) {
        return CQEService.overrideCustomerQualification({
          customerId,
          tier: result.decision,
          disqualifiedScoreId: '',
        })
      }

      if (!overrideDisqualificationVendor) {
        throw new Error('No score set for disqualification')
      }

      return CQEService.overrideCustomerQualification({
        customerId,
        tier: result.decision,
        // @ts-ignore
        disqualifiedScoreId: overrideDisqualificationVendor.id,
      })
    },
    {
      onSuccess() {
        queryClient.invalidateQueries(['scores', customerId])
      },
    }
  )

  const { mutate: addNote } = useMutation(
    () => {
      const data = {
        customerId: customerId,
        noteText: currentNote,
      }

      return CQEService.addCustomerNote(data)
    },
    {
      onSuccess() {
        queryClient.invalidateQueries(['notes', customerId])
      },
    }
  )

  const {
    mutate: recalculateCustomerScores,
    error: cqeError,
    isSuccess: recalculateCustomerScoresSuccess,
  } = useMutation(
    () => {
      return CQEService.recalculateCustomerQualification({
        customerId: customerId,
        scoreNamesList: [0, 1, 2, 3],
        skipQualification: false,
      })
    },
    {
      onSuccess() {
        queryClient.invalidateQueries(['scores', customerId])
      },
    }
  )

  return {
    cqeScores,
    customerId,
    overrideQualification,
    overrideQualificationSuccess,
    currentNote,
    setCurrentNote,
    isAdmin,
    addNote,
    recalculateCustomerScores,
    cqeError,
    recalculateCustomerScoresSuccess,
    decision,
    setDecision,
    overrideDisqualificationVendor,
    setOverrideDisqualificationVendor,
    showOverrideRejectionDropdown,
    feedbackMessage,
    setFeedbackMessage,
    mostRecentNote,
  }
}