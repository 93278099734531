import { UploadVehicleDocumentRequest } from '@nxcr-org/web-api-interface/lib/document_service_pb'
import { DocumentServicePromiseClient } from '@nxcr-org/web-api-interface/lib/gateway_service_grpc_web_pb'
import { oktaAuth, OktaAuthInterceptor } from '../../../okta/config'
import { fileToArrayBuffer } from '../../../utils/fileToArrayBuffer'
import { Env } from './env'

export const DocumentService = {
  uploadDocument,
}

function getDocumentService(): DocumentServicePromiseClient {
  const idToken = oktaAuth.getIdToken()
  const target = Env.endpoints.fleet

  return new DocumentServicePromiseClient(target, null, {
    unaryInterceptors: [new OktaAuthInterceptor(idToken)],
    'grpc-node.max_session_memory': 1000,
    'grpc-node.max_send_message_length': 1024 * 1024 * 100,
    'grpc-node.max_receive_message_length': 1024 * 1024 * 100,
  })
}

export async function buildUploadDocumentRequest(
  params: UploadDocumentRequestParams
) {
  const documentBuffer = await fileToArrayBuffer(params.document)
  const documentPayload = new Uint8Array(documentBuffer)

  const request = new UploadVehicleDocumentRequest()

  request.setVin(params.vin)
  request.setDocumentPayload(documentPayload)

  return request
}

async function uploadDocument(params: UploadDocumentRequestParams) {
  const client = getDocumentService()

  const request = await buildUploadDocumentRequest(params)

  return client.uploadVehicleDocument(request)
}

type UploadDocumentRequestParams = {
  vin: string
  document: File
}
