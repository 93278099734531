import React from 'react'
import { MapProvider } from 'react-map-gl'
import Map from './Map'

/**
 * Will hold additional panels, but currently only holds map.
 * @returns 
 */
export const MapContainer = () => {
  return (
    <MapProvider>
      <Map  />
    </MapProvider> 
  )
}
