import { useQuery } from 'react-query'

import { useParams } from 'react-router-dom'
import { getInsuredDrivers } from 'global-apis/nexus-service-insurance'
import { GetInsuredDriversResponse } from '@nxcr-org/web-api-interface/lib/insurance_service_pb'
import { Driver } from '@nxcr-org/web-api-interface/lib/insurance_pb'

const useInsurance = () => {
  const { customerId } = useParams<{ customerId: string }>()
  function useInsuredDrivers(subscriptionId: string) {
    const { data: insuredDrivers, error: insuredDriversError } = useQuery(
      ['/insuredDrivers'],
      () =>
        getInsuredDrivers(customerId, subscriptionId).then(
          (res: GetInsuredDriversResponse.AsObject) =>
            res.driversList as Driver.AsObject[]
        ),
      {
        placeholderData: [],
        onError() {
          console.log('Error: ', insuredDriversError)
        },
      }
    )

    return {
      insuredDrivers,
    }
  }
  return {
    useInsuredDrivers,
  }
}

export default useInsurance
