import React from 'react'
import 'simplebar/dist/simplebar.min.css'
import SimpleBar from 'simplebar-react'
import { styled } from '@mui/material/styles'

const ScrollbarRoot = styled(SimpleBar)``

export default function Scrollbar(props, ref) {
  return (
    <ScrollbarRoot
      // @ts-ignore
      ref={ref}
      {...props}
    />
  )
}
