import React from 'react'
import { Article } from 'shared/Article'
import { TeamSettings } from './blocks/TeamSettings/TeamSettings'
import { TaskSettings } from './blocks/TaskSettings/TaskSettings'
import { useSettings } from './useSettings'

export const Settings: React.FC = () => {
  const { isAdmin, dealership, setDealership } = useSettings()

  return (
    <Article heading="Settings" headerHasBottomBorder={false}>
      <TeamSettings
        dealership={dealership}
        setDealership={setDealership}
        isAdmin={isAdmin}
      />
      <TaskSettings dealership={dealership} isAdmin={isAdmin} />
    </Article>
  )
}
